import React from 'react';
import {observer} from 'mobx-react-lite';
import FlashMessageList from './FlashMessageList';
import {useRoot} from '../Root/hooks';

export default observer(function FlashMessage() {
  const {flashMessages} = useRoot();
  return (
    <FlashMessageList
      getMessages={flashMessages.getMessages}
      onRemoveMessage={flashMessages.removeMessage}
    />
  );
});
