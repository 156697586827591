import {GetOrderResult} from '../ApiStore/entities/GetOrderResult';
import {Network} from '../Web3Payment';

export type MetamaskInfo = {
  network: Network;
  contractAddress: string | undefined;
  recommendedFee: string | undefined;
};

export default function translateResponseToMetamaskInfo(
  response: GetOrderResult,
): MetamaskInfo | undefined {
  const payment = response.order.payments.find(
    (_) => _.type === 'web3' && _.wallet === 'metamask',
  );
  if (payment?.type !== 'web3') {
    return undefined;
  }
  const network = payment.network;
  return {
    network: {
      chainId: '0x' + Number(network.chain_id).toString(16),
      chainName: network.chain_name,
      nativeCurrency: {
        decimals: network.native_currency.decimals,
        name: network.native_currency.name,
        symbol: network.native_currency.symbol,
      },
      rpcUrls: network.rpc_urls,
      blockExplorerUrls: network.block_explorer_urls,
    },
    contractAddress: payment.contract_address || undefined,
    recommendedFee: payment.recommended_gas || undefined,
  };
}
