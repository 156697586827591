import {TextProps, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStrings} from '../Root/hooks';
import {variance} from '../styling';
import {Typography} from '../components';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type SlotsDescriptionProps = TextProps & {
  miners: number;
  isMd?: boolean;
};

export default observer((props: SlotsDescriptionProps) => {
  const {miners, isMd, ...rest} = props;
  const strings = useStrings();

  return (
    <SlotDescriptionView isMd={isMd}>
      <Typography type={'paragraph'} size={'large'} {...rest}>
        {strings['accountSwitch.miners']}:{' '}
        <Typography type={'paragraph'} size={'large'} weight={'700'}>
          {miners.toFixed()}
        </Typography>
      </Typography>
    </SlotDescriptionView>
  );
});

const SlotDescriptionView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        flex: 1,
        justifyContent: 'center',
        textAlign: 'center',
      },
    }),
  },
  isMd: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
}));
