import {Platform} from 'react-native';
import AndroidDeferredDeeplinkService from './AndroidDeferredDeeplinkService';
import SubDeferredDeeplinkService from './SubDeferredDeeplinkService';
import {Auth} from '../Auth';
import {ConnectedClient} from '../ContextClient';

export default class DeferredDeeplinkFactory {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
    },
  ) {}
  create() {
    if (Platform.OS === 'android') {
      return new AndroidDeferredDeeplinkService(this._root);
    }
    return new SubDeferredDeeplinkService();
  }
}
