import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {Body as TypoBody} from '../../index';
import {useTheme, variance} from '../../../styling';
import {sized} from '../../../Svg';
import MinerSvg from '../../../assets/svg/grayish/miner.svg';
import MinerDarkSvg from '../../../assets/svg/grayish/minerDark.svg';
import getWorkerDescription from '../../../DashboardScreen/getters/getWorkerDescription';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import useWorkerStatuses from '../../../DashboardScreen/hooks/useWorkerStatuses';
import {expr} from 'mobx-utils';

export type WorkerDescriptionProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function WorkerDescription({
  worker,
}: WorkerDescriptionProps) {
  const theme = useTheme();
  const DescIcon = theme.select(MinerIcon, MinerDarkIcon);
  const {needActivate, expired, inactive} = useWorkerStatuses(worker);
  const isGrey = expr(() => needActivate || expired || inactive);

  return (
    <View style={styles.root}>
      <View style={styles.icon}>
        <DescIcon />
      </View>
      <View style={styles.content}>
        <BodyText numberOfLines={1} type="body" disabled={isGrey} weight="500">
          {getWorkerDescription(worker)}
        </BodyText>
      </View>
    </View>
  );
});

const MinerIcon = sized(MinerSvg, 19);
const MinerDarkIcon = sized(MinerDarkSvg, 19);

const BodyText = variance(TypoBody)((theme) => ({
  root: {
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: -0.2,
  },
  disabled: {
    color: theme.colors.uiAdditional4,
  },
}));

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    marginRight: 12.5,
  },
  content: {
    flex: 1,
  },
});
