import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {View} from 'react-native';
import {QuickStartListItemType} from './types';
import QuickStartListItem from './QuickStartListItem';

export type QuickStartListProps = {
  items: QuickStartListItemType[];
  selectItem: (val: number) => void;
  selectedItemIndex: number | undefined;
};

export default observer(function QuickStartList({
  items,
  selectItem,
  selectedItemIndex,
}: QuickStartListProps) {
  return (
    <List>
      {items.map((item, index) => (
        <QuickStartListItem
          key={index}
          onPress={() => selectItem(index)}
          selected={selectedItemIndex === index}
          {...item}
        />
      ))}
    </List>
  );
});

const List = variance(View)(() => ({
  root: {
    marginTop: 24,
  },
}));
