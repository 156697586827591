import {COLORS} from './constant';

export default function getClosestColor(speed: number): string {
  const color = COLORS.get(speed);
  if (color) {
    return color;
  }

  const closestColorIndex = speed - (speed % 5);
  const closestColor = COLORS.get(closestColorIndex);
  if (closestColor) {
    return closestColor;
  }
  return COLORS.get(0)!;
}
