import React, {ForwardedRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  BottomSheetSnapPointsProps,
  ModalWidth,
  Representation,
} from '../../templates';
import {
  FlatListProps,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import useSafePaddingBottom from '../../templates/AdaptiveModal/useSafePaddingBottom';
import {variance} from '../../../styling';
import useRepresentation from '../../templates/AdaptiveModal/useRepresentation';
import {AdaptiveFlatList} from '../../templates/AdaptiveModal';
import {
  AdaptiveModalHeader,
  AdaptiveModalHeaderProps,
} from './AdaptiveModalHeader';
import {Divider} from '../../atoms';

export const ADAPTIVE_FLAT_LIST_PICKER_BREAKPOINT = 'sm';

export type AdaptiveFlatListPickerProps<ItemT> = FlatListProps<ItemT> &
  BottomSheetSnapPointsProps &
  AdaptiveModalHeaderProps & {
    modalContentStyle?: StyleProp<ViewStyle>;
    bottomSheetStyle?: StyleProp<ViewStyle>;
    actions: React.ReactNode[];
    onModalClosed: () => void;
    modalRef?: ForwardedRef<AdaptiveModalRef>;
    extraHeader?: React.ReactNode;
  };

export default observer(function AdaptiveFlatListPicker<ItemT extends unknown>(
  props: AdaptiveFlatListPickerProps<ItemT>,
) {
  const {modalRef, modalContentStyle, ...rest} = props;
  return (
    <AdaptiveModal
      ref={modalRef}
      adaptiveBreakpoint={ADAPTIVE_FLAT_LIST_PICKER_BREAKPOINT}
      modalWidth={ModalWidth.Small}
      modalContentStyle={[styles.modalContent, modalContentStyle]}
      {...rest}>
      <ContentView<ItemT> {...rest} />
    </AdaptiveModal>
  );
});

const ContentView = observer(
  <ItemT extends unknown>(props: AdaptiveFlatListPickerProps<ItemT>) => {
    const {
      IconSvg,
      IconSvgModalRep,
      title,
      description,
      actions,
      extraHeader,
      ...rest
    } = props;
    const paddingBottom = useSafePaddingBottom(16, 20);
    const representation = useRepresentation();
    const isModal = representation === Representation.Modal;
    return (
      <RootView style={{paddingBottom}} modal={isModal}>
        <HeaderView>
          <AdaptiveModalHeader
            IconSvg={IconSvg}
            IconSvgModalRep={IconSvgModalRep}
            title={title}
            description={description}
          />
          {extraHeader}
        </HeaderView>
        <Divider opacity={0.6} />
        <AdaptiveFlatList<ItemT> {...rest} />
        <Divider opacity={0.6} />
        <ActionListView modal={isModal}>
          {actions.map((action, index) => (
            <View
              style={[index + 1 < actions.length && styles.actionOffset]}
              key={index}>
              {action}
            </View>
          ))}
        </ActionListView>
      </RootView>
    );
  },
);

const styles = StyleSheet.create({
  modalContent: {
    flex: 1,
  },
  actionOffset: {
    marginBottom: 12,
  },
});

const RootView = variance(View)(() => ({
  root: {
    flex: 1,
    padding: 16,
    paddingTop: 39,
  },
  modal: {
    padding: 20,
    paddingTop: 48,
  },
}));

const HeaderView = variance(View)(() => ({
  root: {
    paddingBottom: 10,
  },
  modal: {
    paddingBottom: 10,
  },
}));

const ActionListView = variance(View)(() => ({
  root: {
    alignSelf: 'stretch',
    marginTop: 24,
  },
  modal: {
    marginTop: 36,
  },
}));
