import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {View, ViewProps} from 'react-native';
import {PanelView} from '../components';
import ChartView, {ChartViewRef, ChartViewScope} from './ChartView';
import {useRoot} from '../Root/hooks';
import {Millisecond} from '../Time';
import {Graph} from './Graph';
import TitleView from './TitleView';
import FilterView from './FilterView';
import ButtonGroupView from './ButtonGroupView';
import {ReadonlyDeep} from 'type-fest';
import {CryptoLoader} from '../components/atoms/CryptoLoader';
import NoData from '../components/NoData';
import {createStylesHook, variance} from '../styling';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type GraphPanelProps = ViewProps & {
  title: string;
  fractionDigits: number;
  tooltipHelperText?: string;
  titleRight?: React.ReactNode;
  filterRight?: React.ReactNode;
  onFromChange?: (from: Millisecond) => void;
  from: Millisecond;
  to: Millisecond;
  onScopeChange?: (scope: ChartViewScope) => void;
  scope: ChartViewScope;
  isLoading?: boolean;
  graph?: ReadonlyDeep<Graph>;
  primaryTitle?: string;
  secondaryAxisShown?: boolean;
  secondaryTitle?: string;
  secondaryAxisRatio?: number;
  secondaryFractionDigits?: number;
};

export default observer(
  ({
    title,
    fractionDigits,
    tooltipHelperText,
    titleRight,
    onFromChange,
    from,
    to,
    onScopeChange,
    scope,
    isLoading,
    graph,
    primaryTitle = title,
    secondaryAxisShown,
    secondaryTitle,
    secondaryAxisRatio,
    secondaryFractionDigits,
    filterRight,
    ...rest
  }: GraphPanelProps) => {
    const styles = useStyles();
    const {navigationContainer} = useRoot();
    const graphRef = useRef<ChartViewRef>(null);

    const setDayScope = useCallback(() => {
      graphRef.current?.reset();
      onScopeChange?.(ChartViewScope.Day);
    }, [onScopeChange]);
    const setMonthScope = useCallback(() => {
      graphRef.current?.reset();
      onScopeChange?.(ChartViewScope.Month);
    }, [onScopeChange]);

    const setWeekScope = useCallback(() => {
      graphRef.current?.reset();
      onScopeChange?.(ChartViewScope.Week);
    }, [onScopeChange]);

    const toggleModal = useCallback(() => {
      navigationContainer.ref?.navigate('Calendar', {
        value: from,
        onChange: onFromChange,
      });
    }, [navigationContainer, from, onFromChange]);

    const secondary = secondaryAxisShown
      ? {secondaryTitle, secondaryAxisRatio, secondaryFractionDigits}
      : {};

    return (
      <PanelView style={styles.panel} {...rest}>
        <TitleView style={styles.title} title={title} titleRight={titleRight} />
        <Header>
          {filterRight}
          <FilterView
            modalShown={!!isLoading}
            from={from}
            to={to}
            onPress={toggleModal}
          />
        </Header>
        <ChartContainer>
          {isLoading ? (
            <View style={[styles.chartView, styles.chartViewCenter]}>
              <CryptoLoader />
            </View>
          ) : graph && graph.series.length >= 3 ? (
            <ChartView
              ref={graphRef}
              style={styles.chartView}
              series={graph.series}
              from={graph.from}
              to={graph.to}
              scope={scope}
              title={primaryTitle}
              fractionDigits={fractionDigits}
              tooltipHelperText={tooltipHelperText}
              {...secondary}
            />
          ) : (
            <View style={[styles.chartView, styles.chartViewCenter]}>
              <NoData />
            </View>
          )}
        </ChartContainer>
        <ButtonGroupView
          scope={scope}
          onDayPress={setDayScope}
          onMonthPress={setMonthScope}
          onWeakPress={setWeekScope}
        />
      </PanelView>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  title: {
    margin: 15,
  },
  panel: {
    marginBottom: 20,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        borderTopRightRadius: 12,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
      },
    }),
  },
  chartView: {
    flexGrow: 1,
    flexShrink: 1,
    height: 300,
    ...theme.mediaQuery({
      1000: {
        height: 335,
      },
    }),
  },
  chartViewCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  picker: {
    position: 'absolute',
    top: 54,
    left: -1,
  },
  filterViewWrapper: {
    position: 'relative',
    zIndex: 2,
  },
}));

const Header = variance(View)((theme) => ({
  root: {
    position: 'relative',
    paddingHorizontal: 16,
    zIndex: 2,
    backgroundColor: theme.colors.uiMain,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
}));

const ChartContainer = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    backgroundColor: theme.colors.uiMain,
    paddingLeft: 15,
  },
}));
