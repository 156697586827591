import {Platform} from 'react-native';
import {useOpenLink} from '../Links';
import {useRoot} from '../Root/hooks';

export default function useOpenStoreManageSubscription() {
  const {configuration} = useRoot();
  const {
    values: {appStoreSubscriptionsUrl, playStoreSubscriptionsUrl},
  } = configuration;
  const manageSubscriptionUrl =
    Platform.OS === 'ios'
      ? appStoreSubscriptionsUrl
      : playStoreSubscriptionsUrl;
  return useOpenLink(manageSubscriptionUrl);
}
