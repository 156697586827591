const staticColors = {
  primarySuccess: '#4BB34B',
  primaryActive: '#4478FF',
  primaryError: '#FF3347',
  primaryAttractive: '#F7C61A',
  primaryNew: '#F7931A',
  primaryUIDirtyBlue: '#8B98B1',
  primaryWhite: '#FFFFFF',
  primaryBlack: '#09183F',
  gradientBtnYellow: ['#F7C61A', '#F7931A'],
  gradientBtnGreen: ['#B5F71A', '#4BB34B'],
  gradientCard: ['#1E222D', '#232B40'],
  gradientGraph: ['rgba(247, 198, 26, 0.1)', 'rgba(255, 255, 255, 0)'],
  gradientWorkerBodyExpired: [
    'rgba(255, 51, 71, 0.05)',
    'rgba(255, 51, 71, 0.05)',
  ],
  gradientWorkerBodyInactive: [
    'rgba(247, 198, 26, 0.05)',
    'rgba(247, 198, 26, 0.05) 100%)',
  ],
  gradientWorkerHeaderExpired: ['#FF3347', '#D22A3A'],
  gradientWorkerHeaderInactive: ['#F7C61A', '#F4A30B'],
  systemGray: '#99A2AD',
};
// background: linear-gradient(90deg, #F7C61A 0%, #F4A30B 100%), #F7C61A;
export default staticColors;
