import React, {useCallback, useMemo} from 'react';
import {
  FlatList,
  ListRenderItem,
  Platform,
  View,
  ViewProps,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import {useStrings, useRoot} from '../Root/hooks';
import {Subscription} from '../farmApi';
import {EmptyFarmView, SubscriptionView} from '../SubscriptionView';
import {AuthStatus} from '../Auth';
import SwitchFarmHeader from '../components/SwitchFarmScreenHeader';
import {FarmId} from '../ApiStore';
import {useStyles, variance} from '../styling';
import {PressableOpacity} from '../components';
import EmailLabel from '../SubscriptionView/EmailLabel';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {SafeAreaView} from 'react-native-safe-area-context';

export type AccountSwitchScreenProps = {
  goToDashboard: () => void;
};

export default observer((props: AccountSwitchScreenProps) => {
  const {goToDashboard} = props;
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          paddingVertical: 10,
        },
      }),
    },
    item: {
      marginBottom: 10,
    },
    itemLast: {
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          marginBottom: 0,
        },
      }),
    },
    itemSelected: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: theme.colors.primaryAttractive,
    },
    list: {
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          width: 820,
          maxWidth: '100%',
          marginTop: Platform.OS === 'web' ? 'auto' : 0,
          marginBottom: Platform.OS === 'web' ? 'auto' : 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: 12,
          borderWidth: 1,
          borderColor: theme.colors.uiAdditional1,
          overflow: 'hidden',
        },
      }),
    },
  }));
  const {auth} = useRoot();
  const refresh = useCallback(() => auth.authorize(), [auth]);

  const keyExtractor = useCallback((item: Farm) => String(item.accountId), []);
  const subscriptions = expr(() => {
    if (
      auth.state &&
      (auth.state.kind === 'Authorized' ||
        auth.state.kind === 'Connected' ||
        auth.state.kind === 'ConnectionFailed')
    ) {
      const {state} = auth;
      return [...auth.state.accountIds.values()].map<Farm>((accountId) => {
        const subscription = state.subscriptionMap.get(accountId);
        return subscription
          ? {empty: false, email: auth.email, ...subscription}
          : {empty: true, accountId};
      });
    }
    return undefined;
  });

  const renderItem = useCallback<ListRenderItem<Farm>>(
    ({item, index}) => {
      const label = <EmailLabel email={auth.email!} />;
      const isLast = subscriptions && index === subscriptions.length - 1;
      const isActive =
        auth.state?.kind === 'Connected' &&
        auth.state.accountId === item.accountId &&
        subscriptions &&
        subscriptions.length !== 1;

      return (
        <Item
          style={[
            styles.item,
            isLast && styles.itemLast,
            isActive && styles.itemSelected,
          ]}
          isActive={isActive}
          isLast={isLast}
          farm={item}
          label={label}
          goToDashboard={goToDashboard}
        />
      );
    },
    [auth, goToDashboard, styles, subscriptions],
  );

  const SubscriptionListHeader = useMemo(() => {
    return subscriptions ? (
      <ListHeader accountAmount={subscriptions.length} />
    ) : null;
  }, [subscriptions]);

  return (
    <SafeAreaView edges={['bottom']} style={styles.root}>
      <FlatList
        ListHeaderComponent={SubscriptionListHeader}
        data={subscriptions}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        refreshing={auth.status !== AuthStatus.Idle}
        onRefresh={refresh}
        contentContainerStyle={styles.list}
      />
    </SafeAreaView>
  );
});

const ListHeader = observer(({accountAmount}: {accountAmount: number}) => {
  const strings = useStrings();
  const {auth} = useRoot();
  const description = auth.email
    ? strings['accountSwitch.emailDescription'].replace('{email}', auth.email)
    : strings['accountSwitch.description'];
  return (
    <ContainerView>
      <SwitchFarmHeader
        description={description}
        accountAmount={accountAmount}
      />
    </ContainerView>
  );
});

export type ItemProps = ViewProps & {
  farm: Farm;
  goToDashboard: () => void;
  label?: React.ReactNode;
  isLast?: boolean;
  isActive?: boolean;
};

export type Farm = EmptyFarm | SufficientFarm;

export type SufficientFarm = Subscription & {
  empty: false;
  email?: string;
};

export type EmptyFarm = {
  empty: true;
  accountId: FarmId;
};

export const Item = observer((props: ItemProps) => {
  const {farm, goToDashboard, ...rest} = props;
  const {auth} = useRoot();
  const {accountId} = farm;
  const onPress = useCallback(async () => {
    await auth.selectAccount(accountId);
    goToDashboard();
  }, [auth, accountId, goToDashboard]);
  return (
    <ContainerView>
      <PressableOpacity onPress={onPress}>
        {farm.empty ? (
          <EmptyFarmView accountId={farm.accountId} />
        ) : (
          <SubscriptionView forceRow {...farm} {...rest} />
        )}
      </PressableOpacity>
    </ContainerView>
  );
});

const ContainerView = variance(View)(() => ({
  root: {
    maxWidth: 1000,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
