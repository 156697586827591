import React from 'react';
import {observer} from 'mobx-react-lite';
import {FlatListProps, Platform} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {useForcedContext} from '../../../context';
import {AdaptiveModalContext} from './AdaptiveModal';
import {BottomSheetFlatList} from '@gorhom/bottom-sheet';
import {Representation} from './types';

export type AdaptiveFlatListProps<ItemT> = FlatListProps<ItemT> & {
  dataSet?: object;
};

export default observer(function AdaptiveFlatList<ItemT extends unknown>({
  ...rest
}: AdaptiveFlatListProps<ItemT>) {
  const ctx = useForcedContext(AdaptiveModalContext);
  if (ctx.representation === Representation.Modal || Platform.OS === 'web') {
    return <FlatList<ItemT> {...rest} />;
  }
  //Doesn't work on web
  return <BottomSheetFlatList<ItemT> {...rest} />;
});
