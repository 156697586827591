import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {DisconnectWalletModal} from '../../DisconnectWalletModal';
import {AdaptiveModalRef} from '../../components/templates';

export type DisconnectWalletBindingProps =
  RootStackBindingProps<'DisconnectWallet'>;

export default observer(
  function DisconnectWalletBinding({}: DisconnectWalletBindingProps) {
    const safelyGoBack = useSafelyGoBack();
    const modalRef = useRef<AdaptiveModalRef>(null);
    const close = useCallback(() => modalRef.current?.close(), []);
    const onCancel = useCallback(async () => {
      await close();
      safelyGoBack();
    }, [close, safelyGoBack]);
    return (
      <DisconnectWalletModal
        ref={modalRef}
        onModalClosed={safelyGoBack}
        onCancel={onCancel}
        onDisconnectWallet={onCancel}
      />
    );
  },
);
