import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import AdvertSplashClose from './AdvertSplashClose';
import {
  Image,
  ImageProps,
  ImageStyle,
  Platform,
  StyleProp,
  StyleSheet,
  View,
} from 'react-native';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStyles, variance} from '../styling';
import {Body as TypographyBody, getCommissionerFontStyles} from '../components';
import {useRoot} from '../Root/hooks';
import {AdInfoAction, AdInfoItem} from '../Advert';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {SvgProps, SvgUri} from 'react-native-svg';
import {Uri} from '../units';
import Markdown from '../components/molecules/Markdown';

export type AdvertSplashModalViewProps = {
  onButtonActionPress: (action: AdInfoAction) => void;
  onCrossClose: () => void;
  seconds: number;
  ad: AdInfoItem;
  isDesktop: boolean;
};

export default observer(function AdvertSplashModalView({
  onButtonActionPress,
  onCrossClose,
  seconds,
  ad,
  isDesktop,
}: AdvertSplashModalViewProps) {
  const {advertHelper} = useRoot();
  const title = advertHelper.translate(ad.title);
  const body = advertHelper.translate(ad.body);
  const _styles = useStyles((theme) => ({
    paragraph: {
      ...getCommissionerFontStyles('400'),
      textAlign: 'center',
      color: theme.colors.uiSecondary,
      marginTop: 0,
      marginBottom: 28,
      fontSize: 16,
      lineHeight: 24,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          fontSize: 18,
          lineHeight: 28,
        },
      }),
    },
  }));
  const actionList = useMemo(
    () =>
      ad.actions.map((action, index) => (
        <ModalButton
          isDesktop={isDesktop}
          key={action.link}
          Icon={
            action.icon
              ? (_) => <ButtonIcon {..._} uri={action.icon as Uri} />
              : undefined
          }
          variant={
            index % 2 === 0 ? ButtonVariant.Primary : ButtonVariant.Default
          }
          onPress={() => onButtonActionPress(action)}>
          {advertHelper.translate(action.title) ?? ''}
        </ModalButton>
      )),
    [ad, advertHelper, isDesktop, onButtonActionPress],
  );
  return (
    <View>
      <AdvertSplashClose onClose={onCrossClose} seconds={seconds} />
      {ad.image !== undefined && (
        <Image style={styles.img} source={{uri: ad.image}} resizeMode="cover" />
      )}
      <Body isDesktop={isDesktop}>
        <Title type="body" weight="700">
          {title}
        </Title>
        {body && (
          <Markdown styles={{paragraph: _styles.paragraph}}>{body}</Markdown>
        )}
        <FooterView isDesktop={isDesktop}>{actionList}</FooterView>
      </Body>
    </View>
  );
});

// TODO: SHOULD BE MIGRATED TO EXPO-IMAGE
const ButtonIcon = (
  props: SvgProps & {
    uri: Uri;
  },
) => {
  const adoptSvgProps = (_: SvgProps): ImageProps => ({
    style: StyleSheet.flatten([
      _.style as StyleProp<ImageStyle>,
      styles.buttonIcon,
    ]),
  });
  if (Platform.OS === 'web') {
    return <Image {...adoptSvgProps(props)} source={{uri: props.uri}} />;
  }
  return <SvgUri {...props} style={[props.style, styles.buttonIcon]} />;
};

const styles = StyleSheet.create({
  img: {
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 252,
  },
  buttonIcon: {
    width: 20,
    height: 20,
  },
});

const FooterView = variance(View)(() => ({
  root: {
    flexDirection: 'column',
    marginVertical: -5,
  },
  isDesktop: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: -10,
  },
}));

const ModalButton = variance(AppButton)(() => ({
  root: {
    minWidth: 200,
    marginVertical: 5,
  },
  isDesktop: {
    marginHorizontal: 10,
  },
}));

const Body = variance(View)(() => ({
  root: {
    paddingTop: 24,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  isDesktop: {
    paddingHorizontal: 32,
    paddingBottom: 24,
  },
}));

const Title = variance(TypographyBody)((theme) => ({
  root: {
    fontSize: 24,
    lineHeight: 32,
    textAlign: 'center',
    marginBottom: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 32,
        lineHeight: 40,
        marginBottom: 16,
      },
    }),
  },
}));
