import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {BarcodeScanningResult, PermissionResponse} from 'expo-camera';
import {SafeAreaView} from 'react-native-safe-area-context';
import BackgroundLayer from './BackgroundLayer';
import ContentLayer from './ContentLayer';
import {useMeasure} from '../ReactNativeUtil';

export type QRCodeScannerScreenProps = {
  goBack: () => void;
  getIsTransitioning: () => boolean;
  onBarCodeScanned: (scanningResult: BarcodeScanningResult) => void;
  cameraPermissions: PermissionResponse | undefined;
  requestPermission: () => Promise<void>;
};

export default observer(
  ({
    goBack,
    onBarCodeScanned,
    requestPermission,
    getIsTransitioning,
    cameraPermissions,
  }: QRCodeScannerScreenProps) => {
    const rootRef = useRef(null);
    const [getResult, onLayout] = useMeasure(rootRef, false);
    const result = getResult();
    const getWidth = useCallback(() => result?.width ?? 0, [result]);
    const getHeight = useCallback(() => result?.height ?? 0, [result]);
    const visibleCamera =
      (!getIsTransitioning() && cameraPermissions?.granted) || false;
    return (
      <View onLayout={onLayout} ref={rootRef} style={styles.root}>
        {result !== undefined && (
          <SafeAreaView style={styles.content}>
            <BackgroundLayer
              cameraPermissions={cameraPermissions}
              requestPermission={requestPermission}
              onBarCodeScanned={onBarCodeScanned}
              visibleCamera={visibleCamera}
              isGranted={cameraPermissions?.granted || false}
              canAskAgain={cameraPermissions?.canAskAgain || false}
              getWidth={getWidth}
              getHeight={getHeight}
            />
            <ContentLayer visibleCamera={visibleCamera} goBack={goBack} />
          </SafeAreaView>
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#000',
  },
  content: {
    flex: 1,
  },
});
