import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useResetToDashboard, useSafelyGoBack} from '../hooks';
import {LinkWorkerConfirmModal} from '../../LinkWorkerConfirmModal';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {observable, runInAction} from 'mobx';
import {
  GetWorkerInfoResult,
  LinkResult,
} from '../../LinkWorkerConfirmModal/types';
import {useOpenLink} from '../../Links';

export type LinkWorkerConfirmBindingProps =
  RootStackBindingProps<'LinkWorkerConfirm'> & {};

export default observer(function LinkWorkerConfirmBinding(
  props: LinkWorkerConfirmBindingProps,
) {
  const {route, navigation} = props;
  const linkKey = route.params?.linkKey;

  const safelyGoBack = useSafelyGoBack();
  const {connectedClient, auth, configuration} = useRoot();
  const resetToDashboard = useResetToDashboard();

  const [linkResultBox] = useState(() =>
    observable.box<LinkResult | undefined>(),
  );
  const getLinkResult = useCallback(() => linkResultBox.get(), [linkResultBox]);
  const [isLoadingBox] = useState(() => observable.box<boolean>(false));
  const getIsLoading = useCallback(() => isLoadingBox.get(), [isLoadingBox]);
  const [isDisabledLinkButtonBox] = useState(() =>
    observable.box<boolean>(false),
  );
  const getIsDisabledLinkButtonBox = useCallback(
    () => isDisabledLinkButtonBox.get(),
    [isDisabledLinkButtonBox],
  );
  const [workerInfoBox] = useState(() =>
    observable.box<GetWorkerInfoResult | undefined>(),
  );
  const getWorkerInfo = useCallback(() => workerInfoBox.get(), [workerInfoBox]);
  const modalRef = useRef<AdaptiveModalRef>(null);

  useEffect(() => {
    async function prepare() {
      if (!linkKey) {
        console.warn('Link key not passed');
        return safelyGoBack();
      }
      const response = await connectedClient.call('get_worker_by_link_key', {
        link_key: linkKey,
      });
      runInAction(() => workerInfoBox.set(response));
    }

    if (auth.state?.kind === 'Connected') {
      // noinspection JSIgnoredPromiseFromCall
      prepare();
    }
  });

  const close = useCallback(() => modalRef.current?.close(), []);

  const onSwitchFarm = useCallback(async () => {
    await close();
    navigation.navigate('AccountSwitch');
  }, [close, navigation]);

  const onSuccessSubmit = useCallback(async () => {
    await close();
    resetToDashboard();
  }, [close, resetToDashboard]);

  const onErrorSubmit = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);

  const onCancel = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);

  const onSupportPress = useOpenLink(
    `${configuration.values.aboutUrl}/feedback/`,
  );

  const onConfirm = useCallback(async () => {
    if (!linkKey) {
      return onCancel();
    }
    runInAction(() => isDisabledLinkButtonBox.set(true));
    const response = await connectedClient.call('link_worker', {
      link_key: linkKey,
    });
    runInAction(() => isDisabledLinkButtonBox.set(false));
    runInAction(() => linkResultBox.set(response));
  }, [
    linkKey,
    connectedClient,
    onCancel,
    isDisabledLinkButtonBox,
    linkResultBox,
  ]);

  const goToAuth = useCallback(async () => {
    await close();
    navigation.navigate('Auth');
  }, [close, navigation]);

  return (
    <LinkWorkerConfirmModal
      ref={modalRef}
      goToAuth={goToAuth}
      onModalClosed={safelyGoBack}
      onCancelPress={onCancel}
      onConfirmPress={onConfirm}
      onSwitchFarmPress={onSwitchFarm}
      getIsLoading={getIsLoading}
      getLinkResult={getLinkResult}
      getWorkerInfo={getWorkerInfo}
      onSuccessLinkPress={onSuccessSubmit}
      onErrorLinkPress={onErrorSubmit}
      onSupportPress={onSupportPress}
      getIsDisabledLinkButtonBox={getIsDisabledLinkButtonBox}
    />
  );
});
