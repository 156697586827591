import BaseMinerProposalsStateImpl from './BaseMinerProposalsStateImpl';
import {
  ManagedProposalSubscription,
  Proposal,
  SubscriptionCandidateKind,
} from './ProposalsState';
import {InAppOffers, MinerSubscriptionOffer} from '../InAppOffersService';
import {CurrentSubscriptionOffer} from '../CurrentSubscriptionOfferService';

export default class IosMinerProposalsStateImpl extends BaseMinerProposalsStateImpl {
  constructor(
    readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly currentSubscriptionOfferService: CurrentSubscriptionOffer;
    },
  ) {
    super(_root);
  }

  protected _getManagedProposalSubscription(
    proposal: Proposal<MinerSubscriptionOffer>,
  ): ManagedProposalSubscription<MinerSubscriptionOffer> | undefined {
    const hashrate = proposal.offer.poolMinerConfig.hash_rate;
    const currentHashrate =
      this._root.currentSubscriptionOfferService.currentHashrate;
    const kind = !proposal.available
      ? SubscriptionCandidateKind.Unavailable
      : currentHashrate && hashrate < currentHashrate
      ? SubscriptionCandidateKind.CanBeManaged
      : currentHashrate && hashrate >= currentHashrate
      ? SubscriptionCandidateKind.CanBeImproved
      : SubscriptionCandidateKind.Available;
    return {
      ...proposal,
      kind,
    };
  }
}
