import {TrialPeriodUnit} from './OfferManager';
import {DurationUnitType} from 'dayjs/plugin/duration';

export default (_: TrialPeriodUnit): DurationUnitType => {
  switch (_) {
    case 'D':
      return 'day';
    case 'M':
      return 'month';
    case 'W':
      return 'week';
    case 'Y':
      return 'year';
  }
};
