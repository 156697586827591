import {observer} from 'mobx-react-lite';
import Svg, {Defs, LinearGradient, Path, Stop} from 'react-native-svg';
import React, {useRef} from 'react';
import {nanoid} from 'nanoid';
import {IconProps} from './IconProps';
import useColors from './useColors';

export default observer(function DebugIcon({active, ...rest}: IconProps) {
  const id = useRef(nanoid());
  const {accent} = useColors(`url(#${id.current})`, active);
  return (
    <Svg height="24" viewBox="0 0 24 24" width="24" fill="none" {...rest}>
      <Path
        d="M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5c-.49 0-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z"
        fill={accent}
      />
      <Defs>
        <LinearGradient
          id={id.current}
          x1="-20.1506"
          y1="7.53239"
          x2="5.0547"
          y2="32.9744"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F7C61A" />
          <Stop offset="1" stopColor="#F7931A" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
});
