import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {LinkWorkerAction, LinkWorkerModal} from '../../LinkWorkerModal';
import useShareSetupLink from '../../LinkWorkerModal/useShareSetupLink';
import useCopyInstallMinerUrl from '../../LinkWorkerModal/useCopyInstallMinerUrl';
import {useOpenLink} from '../../Links';
import {useRoot} from '../../Root/hooks';

export type LinkMinerBindingProps = RootStackBindingProps<'LinkWorker'>;

export default observer(function LinkMinerBinding({
  navigation,
}: LinkMinerBindingProps) {
  const safelyGoBack = useSafelyGoBack();
  const {configuration} = useRoot();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = () => modalRef.current?.close();
  const {shareLink} = useShareSetupLink();
  const {copyLink} = useCopyInstallMinerUrl();
  const goToInstruction = useOpenLink(configuration.values.instructionUrl);
  const onAction = useCallback(
    async (action: LinkWorkerAction) => {
      await close();
      switch (action) {
        case LinkWorkerAction.CopyId:
          safelyGoBack();
          navigation.navigate('CopyInstallId');
          return;
        case LinkWorkerAction.ShareUrl:
          safelyGoBack();
          // noinspection ES6MissingAwait
          shareLink();
          return;
        case LinkWorkerAction.GetUrl:
          safelyGoBack();
          // noinspection ES6MissingAwait
          copyLink();
          return;

        case LinkWorkerAction.Instruction:
          safelyGoBack();
          // noinspection ES6MissingAwait
          goToInstruction();
          return;
        default:
          return safelyGoBack();
      }
    },
    [goToInstruction, copyLink, navigation, safelyGoBack, shareLink],
  );
  return (
    <LinkWorkerModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onAction={onAction}
    />
  );
});
