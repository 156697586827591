import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {PickScheduleSpeedModal} from '../../PickScheduleSpeedModal';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';

export type PickScheduleSpeedBindingProps =
  RootStackBindingProps<'PickScheduleSpeed'> & {};

export default observer(function PickScheduleSpeedBinding({
  route,
  navigation,
}: PickScheduleSpeedBindingProps) {
  const modalRef = useRef<AdaptiveModalRef>(null);
  const nextParams = route.params?.nextParams;
  const safelyGoBack = useSafelyGoBack();

  const closeModal = useCallback(() => modalRef.current?.close(), []);

  const closeAndBack = useCallback(async () => {
    await closeModal();
    safelyGoBack();
  }, [closeModal, safelyGoBack]);

  const handleSelect = useCallback(
    async (speed: number) => {
      await closeModal();
      if (nextParams === undefined) {
        return safelyGoBack();
      }
      navigation.navigate('Schedule', {
        ...nextParams,
        pickedSpeed: speed,
      });
    },
    [closeModal, navigation, nextParams, safelyGoBack],
  );

  return (
    <PickScheduleSpeedModal
      ref={modalRef}
      onSelect={handleSelect}
      onModalClosed={closeAndBack}
      onClosePress={closeAndBack}
    />
  );
});
