import {variance} from '../../styling';
import {AppButton} from '../../components/AppButton';
import {View} from 'react-native';
import {Divider, Header} from '../../components';
import {LG_BREAKPOINT} from '../../WindowDimensions/useDimensions';
import {LocaleKeys} from '../../translation/LocaleStrings';
import React from 'react';

export const ChoiceButton = variance(AppButton)(() => ({
  root: {
    minWidth: 120,
  },
}));

export const LeftColView = variance(View)(() => ({
  root: {
    flex: 1,
    paddingRight: 10,
    zIndex: 2,
  },
}));

export const SinglePriceRow = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
}));

export const PerMonth = variance(Header)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
  },
}));

export const PriceRow = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 6,
      },
    }),
  },
}));

export const PriceRowContainer = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: undefined,
        flex: 6,
      },
    }),
  },
  subscription: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 4,
      },
    }),
  },
}));

export function getPluralDayLocalKey(period: number | undefined): LocaleKeys {
  switch (period) {
    case 1:
      return 'totalPrice.trial.day.one';
    case 2:
    case 3:
    case 4:
      return 'totalPrice.trial.day.few';
    default:
      return 'totalPrice.trial.day.many';
  }
}

export const VerticalDivider = () => (
  <View style={{height: '100%'}}>
    <Divider vertical style={{flex: 1}} />
  </View>
);
