import {useIsFocused} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {ChartViewScope} from '../../GraphPanel';
import {useRoot} from '../../Root/hooks';
import {WorkerStatisticsPanelState} from '../../StatisticsPanel';
import {WorkerStatisticsScreen} from '../../WorkerStatisticsScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {Millisecond} from '../../Time';

export type WorkerStatisticsBindingProps =
  RootStackBindingProps<'WorkerStatistics'>;

export default observer(function WorkerStatisticsBinding(
  props: WorkerStatisticsBindingProps,
) {
  const {route, navigation} = props;
  const _workerId = route.params?.workerId;
  const root = useRoot();
  const {dashboardStore} = root;
  const workerId = dashboardStore.dashboardRequest.value?.groups
    .flatMap((_) => _.workers)
    .find((_) => _.id === _workerId)?.id;
  const isFocused = useIsFocused();
  useLayoutEffect(() => {
    if (workerId === undefined) {
      navigation.goBack();
    }
  }, [navigation, workerId]);
  const [state] = useState(() => new WorkerStatisticsPanelState(root));
  const refresh = useCallback(() => {
    if (workerId !== undefined) {
      // noinspection JSIgnoredPromiseFromCall
      state.fetch(workerId, true);
    }
  }, [state, workerId]);

  useEffect(() => {
    if (isFocused && workerId !== undefined) {
      // noinspection JSIgnoredPromiseFromCall
      state.fetch(workerId, false);
    }
  }, [isFocused, state, workerId]);

  const goToPlan = useCallback(() => navigation.navigate('Plan'), [navigation]);
  const goToAddDemoMiner = useCallback(
    () => navigation.navigate('AddDemoWorker'),
    [navigation],
  );

  const group = dashboardStore.dashboardRequest.value?.groups.flatMap(
    (_) => _.workers,
  );

  const onPick = useCallback(
    (id?: number | string) => {
      if (typeof id === 'string') {
        navigation.setParams({workerId: id});
        return state.fetch(id);
      }
    },
    [navigation, state],
  );

  const onFromChange = useCallback(
    (from: Millisecond) => {
      state.onFromChange(from);
      return state.fetch(workerId || '');
    },
    [state, workerId],
  );
  const onScopeChange = useCallback(
    (scope: ChartViewScope) => {
      state.onScopeChange(scope);
      return state.fetch(workerId || '');
    },
    [state, workerId],
  );

  if (workerId === undefined) {
    return null;
  }

  return (
    <WorkerStatisticsScreen
      onPick={onPick}
      group={group}
      refresh={refresh}
      workerId={workerId}
      state={state}
      onFromChange={onFromChange}
      onScopeChange={onScopeChange}
      goToPlan={goToPlan}
      goToAddDemoMiner={goToAddDemoMiner}
    />
  );
});
