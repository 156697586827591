import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionListModal} from '../components/organisms';
import {AdaptiveModalRef} from '../components/templates';
import {sized} from '../Svg';
import CopySvg from '../assets/svg/colorless/copy.svg';
import ShareSvg from '../assets/svg/colorless/share.svg';
import LinkSvg from '../assets/svg/colorless/link.svg';
import PlaySvg from '../assets/svg/colorless/play.svg';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import ComputerSvg from '../assets/svg/twoTone/monoblock.svg';
import {useRoot, useStrings} from '../Root/hooks';

export enum LinkWorkerAction {
  CopyId,
  ShareUrl,
  GetUrl,
  Instruction,
}

export type LinkWorkerModalProps = {
  onModalClosed: () => void;
  onAction: (action: LinkWorkerAction) => void;
};

export default observer<LinkWorkerModalProps, AdaptiveModalRef>(
  forwardRef(function LinkWorkerModal({onAction, ...rest}, ref) {
    const {share} = useRoot();
    const strings = useStrings();
    const actionList = [
      {
        title: strings['modal.linkWorker.action.getUrl'],
        onPress: () => onAction(LinkWorkerAction.GetUrl),
        Icon: LinkIcon,
      },
      {
        title: strings['modal.linkWorker.action.copyId'],
        onPress: () => onAction(LinkWorkerAction.CopyId),
        Icon: CopyIcon,
      },
      {
        title: strings['modal.linkWorker.action.shareUrl'],
        onPress: () => onAction(LinkWorkerAction.ShareUrl),
        Icon: ShareIcon,
        disabled: !share.isSupported,
      },
      {
        title: strings['modal.linkWorker.action.instructions'],
        onPress: () => onAction(LinkWorkerAction.Instruction),
        Icon: PlayIcon,
      },
    ];
    return (
      <AdaptiveActionListModal
        IconSvg={ComputerIcon}
        IconSvgModalRep={ComputerIcon}
        title={strings['modal.linkWorker.title']}
        description={strings['modal.linkWorker.description']}
        actionList={actionList}
        {...rest}
        ref={ref}
      />
    );
  }),
);

export const ComputerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(ComputerSvg, 48, 48)} {...props} />
));
const CopyIcon = sized(CopySvg, 13, 13);
const ShareIcon = sized(ShareSvg, 12, 14);
const LinkIcon = sized(LinkSvg, 12);
const PlayIcon = sized(PlaySvg, 15, 15);
