import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {AdaptiveActionModal} from '../components/organisms';
import {Image, Text} from 'react-native';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings, useTemplates} from '../Root/hooks';
import Markdown, {MarkdownProps} from '../components/molecules/Markdown';
import {useStyles, variance} from '../styling';

export type PurchasePaidModalProps = {
  onPress: () => void;
  onModalClosed: () => void;
  tokenName: string;
};

export default observer<PurchasePaidModalProps, AdaptiveModalRef>(
  forwardRef(function PurchasePaidModal(props, ref) {
    const {onModalClosed, onPress, tokenName} = props;
    const strings = useStrings();
    const template = useTemplates();
    const description = template['modal.purchasePaid.description']({
      tokenName,
    });

    const styles = useStyles((theme) => ({
      paragraph: {
        color: theme.colors.uiSecondary,
      },
    }));
    return (
      <AdaptiveActionModal
        ref={ref}
        IconSvg={() => (
          <Image
            source={require('../assets/purchasePaid.png')}
            style={{width: 169, height: 161}}
          />
        )}
        title={strings['modal.purchasePaid.title']}
        description={() => (
          <Markdown rules={rules} styles={{paragraph: styles.paragraph}}>
            {description}
          </Markdown>
        )}
        onModalClosed={onModalClosed}
        actions={[
          <AppButton onPress={onPress} variant={ButtonVariant.Primary}>
            {strings['modal.purchasePaid.button']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const rules: MarkdownProps['rules'] = {
  paragraph: (number, children) => (
    <DescriptionText key={number}>{children}</DescriptionText>
  ),
};

const DescriptionText = variance(Text)(() => ({
  root: {
    textAlign: 'center',
  },
}));
