import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {PurchasePaidModal} from '../../PurchasePaidModal';
import {AdaptiveModalRef} from '../../components/templates';

export type PurchasePaidBindingProps = RootStackBindingProps<'PurchasePaid'>;

export default observer(
  function PurchasePaidBinding({}: PurchasePaidBindingProps) {
    const safelyGoBack = useSafelyGoBack();
    const modalRef = useRef<AdaptiveModalRef>(null);
    const close = useCallback(() => modalRef.current?.close(), []);
    const onModalClosed = useCallback(async () => {
      await close();
      safelyGoBack();
    }, [close, safelyGoBack]);
    return (
      <PurchasePaidModal
        tokenName="TOKEN NAME" // TODO Change to real data
        onModalClosed={safelyGoBack}
        onPress={onModalClosed}
      />
    );
  },
);
