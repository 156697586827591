import {computed, makeObservable} from 'mobx';
import {Locale, Preferences} from '../Preferences';
import de from '../translation/dictionaries/de.json';
import en from '../translation/dictionaries/en.json';
import es from '../translation/dictionaries/es.json';
import fr from '../translation/dictionaries/fr.json';
import it from '../translation/dictionaries/it.json';
import pt from '../translation/dictionaries/pt.json';
import ru from '../translation/dictionaries/ru.json';
import {TemplateExecutor, Translation} from './Translation';
import {isEmpty} from 'lodash';
import Localization from './Localization';
import {LocaleDict, LocaleKeys} from '../translation/LocaleStrings';

export default class TranslationService implements Translation {
  constructor(
    private readonly _root: {
      readonly preferences: Preferences;
      readonly localization: Localization;
    },
  ) {
    makeObservable(this);
  }

  @computed get locale(): Locale {
    const {locale: preferredLocale} = this._root.preferences;
    const {locale: systemLocale} = this._root.localization.state ?? {};
    return (
      preferredLocale ??
      (systemLocale ? translateLocale(systemLocale) : Locale.English)
    );
  }

  @computed get localeTag(): LocaleTag {
    return this.locale;
  }

  @computed get strings(): LocaleDict {
    const localeStrings = this.userLocaleStrings;
    const enStrings: LocaleDict = en;
    const localeEntries = Object.entries(localeStrings).map(([key, string]) => {
      const localeKey = key as LocaleKeys;
      return [localeKey, isEmpty(string) ? enStrings[localeKey] : string];
    });
    return Object.fromEntries(localeEntries);
  }

  @computed get userLocaleStrings(): Partial<LocaleDict> {
    switch (this.locale) {
      case Locale.English:
        return en;
      case Locale.French:
        return fr;
      case Locale.German:
        return de;
      case Locale.Italian:
        return it;
      case Locale.PortugueseBrazil:
        return pt;
      case Locale.Russian:
        return ru;
      case Locale.Spanish:
        return es;
      default:
        return en;
    }
  }

  @computed({keepAlive: true}) get templates() {
    return Object.fromEntries(
      Object.entries(this.strings).map(([key, string]) => [
        key,
        (substitution) => {
          return string.replaceAll(
            /{(.+?)}/g,
            (match: string, subKey: string) =>
              String(substitution[subKey] ?? ''),
          );
        },
      ]),
    ) as Record<keyof LocaleDict, TemplateExecutor>;
  }
}

export const translateLocale = (locale: string) => {
  switch (locale) {
    case 'pt-BR':
      return Locale.PortugueseBrazil;
  }
  const _locale = locale.slice(0, 2);
  switch (_locale) {
    case 'en':
      return Locale.English;
    case 'fr':
      return Locale.French;
    case 'de':
      return Locale.German;
    case 'it':
      return Locale.Italian;
    case 'pt':
      return Locale.PortugueseBrazil;
    case 'ru':
      return Locale.Russian;
    case 'es':
      return Locale.Spanish;
    default:
      return Locale.English;
  }
};

export type LocaleTag = 'de' | 'en' | 'es' | 'fr' | 'it' | 'pt' | 'ru';
