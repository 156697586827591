import {useCallback} from 'react';
import {Platform} from 'react-native';
import {useGoToAppStore, useGoToPlayStore} from '../Navigation/hooks';

export default () => {
  const goToAppStore = useGoToAppStore();
  const goToPlayStore = useGoToPlayStore();
  return useCallback(async () => {
    if (Platform.OS === 'ios' || Platform.OS === 'web') {
      return goToAppStore();
    } else {
      return goToPlayStore();
    }
  }, [goToAppStore, goToPlayStore]);
};
