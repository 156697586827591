import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';

import SliderView from './Slider/SliderView';
import Puzzle from './Puzzle';
import {useSharedValue} from 'react-native-reanimated';
import sleep from '../utils/sleep';
import {
  PercentPosition,
  PERMISSIBLE_DEVIATION,
  Quiz,
  SliderStatus,
} from './constants';
import {generateImageIdx, generateQuiz} from './PuzzleHelperStaticImpl';

const ZERO_PERCENT = 0 as PercentPosition;

export type PuzzleConfirmationProps = {
  onSuccess: () => Promise<void>;
  onEnd: () => void;
};

export default observer(function PuzzleConfirmation({
  onSuccess,
  onEnd,
}: PuzzleConfirmationProps) {
  const [quiz, setQuiz] = useState<Quiz>(generateQuiz());
  const sliderTranslateX = useSharedValue<PercentPosition>(ZERO_PERCENT);
  const [imageIndex, setImageIndex] = useState(generateImageIdx());
  const [sliderStatus, setSliderStatus] = useState(SliderStatus.Idle);
  const onRefreshPress = useCallback(() => {
    sliderTranslateX.value = ZERO_PERCENT;
    setQuiz(generateQuiz());
    setImageIndex(generateImageIdx());
    setSliderStatus(SliderStatus.Idle);
  }, [sliderTranslateX]);
  const onEndSwipe = useCallback(
    async (translationX: number) => {
      const diffPosition = quiz.target.x - quiz.start.x;
      const valid =
        Math.abs(translationX - diffPosition) <= PERMISSIBLE_DEVIATION;
      if (valid) {
        setSliderStatus(SliderStatus.Confirming);
        await Promise.all([onSuccess(), sleep(1000)]);
        setSliderStatus(SliderStatus.Success);
        await sleep(1000);
        onEnd();
      } else {
        setSliderStatus(SliderStatus.Error);
      }
    },
    [onEnd, onSuccess, quiz],
  );
  const {start, target} = quiz;
  return (
    <View>
      <Puzzle
        imageIndex={imageIndex}
        targetPosition={target}
        startPosition={start}
        sliderTranslateX={sliderTranslateX}
      />
      <SliderView
        onRefreshPress={onRefreshPress}
        onEndSwipe={onEndSwipe}
        sliderStatus={sliderStatus}
        sliderTranslateX={sliderTranslateX}
      />
    </View>
  );
});
