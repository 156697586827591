import React, {forwardRef, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {Divider, TwoToneIcon} from '../components/atoms';
import {SvgProps} from 'react-native-svg';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings} from '../Root/hooks';
import {AdaptiveFlatListPicker} from '../components/organisms';
import {ListRenderItem, StyleSheet, View} from 'react-native';
import Typography from '../components/Typography';
import FolderSvg from '../assets/svg/twoTone/folder.svg';
import {sized} from '../Svg';
import {variance} from '../styling';
import {WorkerGroupEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {PressableOpacity} from '../components';
import {GroupId} from '../ApiStore';

export type MoveWorkerToGroupModalProps = {
  onModalClosed: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  groups: WorkerGroupEntity[];
  onSelectGroup: (group: WorkerGroupEntity) => void;
  selectedGroupId: GroupId | undefined;
};

export default observer<MoveWorkerToGroupModalProps, AdaptiveModalRef>(
  forwardRef(function MoveWorkerToGroupModal(props, ref) {
    const {
      onConfirm,
      onCancel,
      onModalClosed,
      groups,
      onSelectGroup,
      selectedGroupId,
    } = props;
    const strings = useStrings();
    const renderItem: ListRenderItem<WorkerGroupEntity> = useCallback(
      ({item}) => {
        return (
          <ItemRootView activeOpacity={0.8} onPress={() => onSelectGroup(item)}>
            <ItemText
              selected={selectedGroupId === item.id}
              type="body"
              weight="500">
              {item.name}
            </ItemText>
          </ItemRootView>
        );
      },
      [onSelectGroup, selectedGroupId],
    );

    return (
      <AdaptiveFlatListPicker<WorkerGroupEntity>
        modalRef={ref}
        onModalClosed={onModalClosed}
        IconSvg={FolderIcon}
        IconSvgModalRep={FolderIconLarge}
        title={strings['modal.moveWorkerToGroup.title']}
        ItemSeparatorComponent={Divider}
        contentContainerStyle={styles.container}
        data={groups}
        ListEmptyComponent={ListEmpty}
        renderItem={renderItem}
        modalContentStyle={styles.modal}
        bottomSheetSnapPoints={['90%']}
        actions={[
          <AppButton onPress={onCancel}>{strings['action.cancel']}</AppButton>,
          <AppButton
            disabled={selectedGroupId === undefined}
            onPress={onConfirm}
            variant={ButtonVariant.Primary}>
            {strings['worker.move']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const ListEmpty = observer(() => {
  const strings = useStrings();
  return (
    <View style={styles.emptyRoot}>
      <Typography type="body" weight="500">
        {strings['modal.moveWorkerToGroup.emptyDescription']}
      </Typography>
    </View>
  );
});

const styles = StyleSheet.create({
  emptyRoot: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 36,
  },
  modal: {
    maxHeight: 520,
  },
  container: {
    flexGrow: 1,
  },
});

const ItemRootView = variance(PressableOpacity)(() => ({
  root: {
    minHeight: 46,
    paddingVertical: 5,
    justifyContent: 'center',
  },
}));

const ItemText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
  },
  selected: {
    color: theme.colors.primaryAttractive,
  },
}));

const FolderIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(FolderSvg, 25, 21)} {...props} />
));

const FolderIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(FolderSvg, 38, 31)} {...props} />
));
