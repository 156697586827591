import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerStateRegistry} from '../../../WorkerStateRegistry';
import {byAverageHashrate, byMinedTotal, byName, byStatus} from '../sort';
import {WorkerOrder} from '../../../Dashboard';

export type WorkerItem = {
  index: number;
  isLast: boolean;
  worker: ReadonlyDeep<WorkerEntity>;
  key: string;
};

export default function formItems(
  workers: readonly ReadonlyDeep<WorkerEntity>[] | undefined,
  getOrder: () => WorkerOrder,
  balance: (workerId: string) => number,
  registry: WorkerStateRegistry,
): WorkerItem[] {
  if (!workers) {
    return [];
  }
  const sortedWorkers = workers.slice();
  const workerOrder = getOrder();
  switch (workerOrder) {
    case WorkerOrder.AverageHashrate:
      sortedWorkers.sort((a, b) => byAverageHashrate(a, b, registry));
      break;
    case WorkerOrder.MinedTotal:
      sortedWorkers.sort((a, b) => byMinedTotal(a, b, balance));
      break;
    case WorkerOrder.Name:
      sortedWorkers.sort((a, b) => byName(a, b));
      break;
    case WorkerOrder.Status:
      sortedWorkers.sort((a, b) => byStatus(a, b, registry));
      break;
  }
  return sortedWorkers.map((_, index) => ({
    key: _.id,
    isLast: index === sortedWorkers.length - 1,
    index,
    worker: _,
  }));
}
