import {
  FinishPendingPurchasesResult,
  PendingPurchasesResolver,
} from './PendingPurchasesResolver';
import {
  AcknowledgedProduct,
  InAppPurchaseManager,
  OriginalPurchase,
  UnacknowledgedPurchase,
} from '../InAppPurchaseManager';
import {Either, error, success} from '../fp';
import {
  ApiStore,
  GooglePlayPurchase,
  GooglePlayPurchasesResult,
} from '../ApiStore';
import {Op} from '../Math';
import {Configuration} from '../Configuration';
import {InAppPurchase} from '../InAppPurchase';
import {ErrorRepository} from '../ErrorRepository';
import {GlobalError, SERVER_ERROR} from '../Error';
import {Log} from '../Log';

export default class AndroidPendingPurchasesResolverImpl
  implements PendingPurchasesResolver
{
  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly configuration: Configuration;
      readonly errorRepository: ErrorRepository;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly inAppPurchase: InAppPurchase;
      readonly log: Log;
    },
  ) {}

  async resolve(): Promise<FinishPendingPurchasesResult> {
    const availablePurchases =
      await this._root.inAppPurchaseManager.getOriginalAvailablePurchases();
    if (!availablePurchases.success) {
      return availablePurchases;
    }
    const unAcknowledgedPurchases = this._filterUnacknowledgedPurchases(
      availablePurchases.right,
    );

    const acknowledgedProducts = this._filterAcknowledgedProducts(
      availablePurchases.right,
    );

    if (acknowledgedProducts.length !== 0) {
      for (const product of acknowledgedProducts) {
        try {
          if (product.purchaseToken) {
            await this._root.inAppPurchase.finishTransaction(product, true);
          }
        } catch (ignore) {
          this._root.log.write({body: `Finish transaction error: ${ignore}`});
        }
      }
    }
    const purchases = [
      acknowledgedProducts.map((_) => ({
        data: _.transactionReceipt,
        sign: _.signatureAndroid,
      })),
      unAcknowledgedPurchases.map((_) => ({
        data: _.transactionReceipt,
        sign: _.signatureAndroid,
      })),
    ].flat();

    if (purchases.length !== 0) {
      const response = await this._googlePlayPurchases(purchases);
      if (!response.success) {
        return error(
          this._root.errorRepository.create({
            kind: SERVER_ERROR,
            raw: response.left,
          }),
        );
      }
      return response;
    }
    return success(undefined);
  }

  private _filterAcknowledgedProducts(
    purchases: OriginalPurchase[],
  ): AcknowledgedProduct[] {
    return purchases.flatMap<OriginalPurchase>((_) => {
      if (
        _.isAcknowledgedAndroid &&
        _.purchaseToken &&
        _.autoRenewingAndroid === undefined &&
        _.signatureAndroid
      ) {
        return [_];
      }
      return [];
    }) as AcknowledgedProduct[];
  }

  private _filterUnacknowledgedPurchases(
    purchases: OriginalPurchase[],
  ): UnacknowledgedPurchase[] {
    return purchases.filter(
      (_) => !_.isAcknowledgedAndroid && _.signatureAndroid,
    ) as UnacknowledgedPurchase[];
  }

  private async _googlePlayPurchases(
    purchases: GooglePlayPurchase[],
  ): Promise<Either<GooglePlayPurchasesResult, GlobalError>> {
    const timeout = Op.multiply(this._root.configuration.values.bffTimeout, 4);
    const response = await this._root.apiStore.client.apply(
      'google_play_purchases',
      {purchases},
      {timeout},
    );
    if (!response.success) {
      return error(
        this._root.errorRepository.create({
          kind: SERVER_ERROR,
          raw: response.left,
        }),
      );
    }
    return response;
  }
}
