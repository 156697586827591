import React from 'react';
import {View} from 'react-native';
import ListItem from './ListItem';
import {useStyles, variance} from '../../styling';
import {
  LG_BREAKPOINT,
  MD_BREAKPOINT,
} from '../../WindowDimensions/useDimensions';
import {observer} from 'mobx-react-lite';
import {Product} from '../../Products/Product';
import Loader from '../../WorkerDetailsScreen/Loader';
import {useStrings} from '../../Root/hooks';
import {Paragraph} from '../../components';

const ListCards = observer((props: {data: Product[] | undefined}) => {
  const {data} = props;
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    container: {
      paddingHorizontal: 30,
      paddingVertical: 20,
      marginHorizontal: -20,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      rowGap: 20,
      alignItems: 'stretch',
    },
    item: {
      paddingHorizontal: 10,
      width: '100%',
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          width: '50%',
        },
        [LG_BREAKPOINT]: {
          width: '33.3%',
        },
      }),
    },
  }));
  if (data?.length === 0) {
    return (
      <View style={{marginTop: 20}}>
        <Loader />
      </View>
    );
  }
  if (data === undefined) {
    return (
      <ErrorText type="paragraph" size="large">
        {strings['serverEmptyOrError.description']}
      </ErrorText>
    );
  }
  return (
    <View style={styles.container}>
      {data?.map((item, index) => (
        <View style={styles.item} key={index}>
          <ListItem item={item} index={index} />
        </View>
      ))}
    </View>
  );
});
const ErrorText = variance(Paragraph)((theme) => ({
  root: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 17,
    color: theme.colors.primaryUIDirtyBlue,
    marginTop: 20,
  },
}));

export default ListCards;
