import {variance} from '../../../styling';
import {StyleProp, StyleSheet, View, ViewStyle, TextStyle} from 'react-native';
import React from 'react';
import {SvgProps} from 'react-native-svg';
import Typography, {Body} from '../../Typography';
import {observer} from 'mobx-react-lite';
import useRepresentation from '../../templates/AdaptiveModal/useRepresentation';
import {Representation} from '../../templates';

export type AdaptiveModalHeaderProps = {
  iconViewStyle?: StyleProp<ViewStyle>;
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  description?: string | (() => React.ReactNode);
  IconSvg?: React.ComponentType<SvgProps>;
  IconSvgModalRep?: React.ComponentType<SvgProps>;
  style?: StyleProp<ViewStyle>;
};
export const AdaptiveModalHeader = observer(
  ({
    iconViewStyle,
    IconSvg,
    IconSvgModalRep,
    title,
    titleStyle,
    description,
    style,
  }: AdaptiveModalHeaderProps) => {
    const isModal = useRepresentation() === Representation.Modal;
    const LargeIcon = IconSvgModalRep || IconSvg;
    const Icon = isModal ? LargeIcon : IconSvg;
    const hasIcon = Icon !== undefined;
    const hasTitle = title !== undefined;
    const hasDescription = description !== undefined;
    const hasSome = hasIcon || hasTitle || hasDescription;
    if (!hasSome) {
      return null;
    }
    return (
      <View style={style}>
        {hasIcon && (
          <IconView
            isModal={isModal}
            style={[iconViewStyle, !hasTitle && styles.noMarginBottom]}>
            {Icon && <Icon />}
          </IconView>
        )}
        {hasTitle && (
          <Typography
            style={[
              styles.title,
              titleStyle,
              !hasDescription && styles.noMarginBottom,
            ]}
            type="header"
            size="h3">
            {title}
          </Typography>
        )}
        {hasDescription && typeof description === 'function' ? (
          description()
        ) : (
          <DescriptionText type="body" weight="500">
            {description}
          </DescriptionText>
        )}
      </View>
    );
  },
);

const IconView = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    alignSelf: 'center',
  },
  isModal: {
    marginBottom: 12,
  },
}));

const DescriptionText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
  },
}));

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    lineHeight: 22,
    marginBottom: 8,
    textAlign: 'center',
  },
  noMarginBottom: {
    marginBottom: 0,
  },
});
