import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionModal} from '../components/organisms';
import TrashSvg from '../assets/svg/twoTone/trash.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {TwoToneIcon} from '../components/atoms';
import {SvgProps} from 'react-native-svg';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings} from '../Root/hooks';
import {sized} from '../Svg';

export type DeleteAccountConfirmModalProps = {
  onModalClosed: () => void;
  onConfirm: () => void;
  onCancel: () => void;
};

export default observer<DeleteAccountConfirmModalProps, AdaptiveModalRef>(
  forwardRef(function DeleteAccountConfirmModal(
    {onConfirm, onCancel, onModalClosed},
    ref,
  ) {
    const strings = useStrings();
    return (
      <AdaptiveActionModal
        ref={ref}
        onModalClosed={onModalClosed}
        IconSvg={TrashIcon}
        IconSvgModalRep={TrashIconLarge}
        title={strings['modal.removeAccount.title']}
        description={strings['modal.removeAccount.description']}
        actions={[
          <AppButton onPress={onCancel}>{strings['action.cancel']}</AppButton>,
          <AppButton onPress={onConfirm} variant={ButtonVariant.Primary}>
            {strings['action.delete']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const TrashIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(TrashSvg, 22, 28)} {...props} />
));

const TrashIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(TrashSvg, 33, 42)} {...props} />
));
