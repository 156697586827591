import {Interval, Matrix} from './types';
import {SchedulerEntity} from '../../universal/features/api/entity/schedule/SchedulerEntity';
import _ from 'lodash';
import pointHourUnitListToMinutes from './pointHourUnitListToMinutes';
import shiftMinuteListByMinutes from './shiftMinuteListByMinutes';

export default function matrixToIntervals(
  M_: Matrix,
  timezoneOffset: number,
): SchedulerEntity[] {
  const M = _.cloneDeep(M_);
  const minuteList: number[] = shiftMinuteListByMinutes(
    pointHourUnitListToMinutes(_.flattenDeep(M)),
    -timezoneOffset,
  );
  let intervals: Interval[] = [];
  let count = 0;
  minuteList.forEach((minute, index) => {
    const nextUnit = minuteList[index + 1];
    if (minute) {
      if (nextUnit === minute) {
        count++;
      } else {
        intervals.push({
          start_time: index - count,
          stop_time: index + 1,
          settings: {speed: minute},
        });
        count = 0;
      }
    }
  });
  return intervals;
}
