import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useSafelyGoBack} from '../hooks';

export default observer(function MockAddWorkerBinding() {
  const safelyGoBack = useSafelyGoBack();
  useEffect(() => {
    console.warn(
      'The screen is not available, it is used only as a stub and redefined in the custom bottom tab',
    );
    safelyGoBack();
  }, [safelyGoBack]);
  return null;
});
