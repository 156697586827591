import {observer} from 'mobx-react-lite';
import React, {forwardRef} from 'react';
import {View} from 'react-native';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {variance} from '../styling';
import YellowCheckSvg from '../assets/svg/circled/yellowCheck.svg';
import DarkCheckSvg from '../assets/svg/circled/darkCheck.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {Header} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type ThanksModalProps = ContentProps & {
  onModalClosed: () => void;
};

export default observer<ThanksModalProps, AdaptiveModalRef>(
  forwardRef(function ThanksModal({onModalClosed, goToDashboard}, ref) {
    return (
      <AdaptiveModal
        ref={ref}
        onModalClosed={onModalClosed}
        enableContentAutoHeight
        modalWidth={ModalWidth.Small}>
        <Content goToDashboard={goToDashboard} />
      </AdaptiveModal>
    );
  }),
);

type ContentProps = {
  goToDashboard: () => void;
};

const Content = observer(({goToDashboard}: ContentProps) => {
  const strings = useStrings();
  const {
    appearance: {isDark},
  } = useRoot();
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <Container style={{paddingBottom}}>
      <YellowCheckView>
        {isDark ? <YellowCheckIcon /> : <DarkCheckIcon />}
      </YellowCheckView>
      <Title type="header" size="h2">
        {strings['modal.thanks.title']}
      </Title>
      <AppButton onPress={goToDashboard} variant={ButtonVariant.Primary}>
        {strings['modal.thanks.button']}
      </AppButton>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 30,
  },
}));

const Title = variance(Header)(() => ({
  root: {
    textAlign: 'center',
    marginBottom: 24,
  },
}));

const YellowCheckView = variance(View)(() => ({
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 12,
  },
}));

const YellowCheckIcon = sized(YellowCheckSvg, 64, 64);
const DarkCheckIcon = sized(DarkCheckSvg, 64, 64);
