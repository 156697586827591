import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {Header as TypoHeader} from '../components';
import {Divider} from '../components/atoms';
import ArrowLeftSvg from '../assets/svg/colorless/arrowLeft.svg';
import {sized} from '../Svg';
import {useTheme, variance} from '../styling';
import {useStrings} from '../Root/hooks';
import {TouchableOpacity} from 'react-native-gesture-handler';

export type HeaderProps = {
  goBack: () => void;
};

export default observer(function Header({goBack}: HeaderProps) {
  const theme = useTheme();
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <View style={styles.content}>
        <TouchableOpacity
          onPress={goBack}
          activeOpacity={0.8}
          style={styles.backButton}>
          <ArrowLeftIcon color={theme.colors.primaryWhite} />
        </TouchableOpacity>
        <TitleText type="header" size="h3">
          {strings['screenTitle.qrCodeScanner']}
        </TitleText>
      </View>
      <Divider />
    </View>
  );
});

const ArrowLeftIcon = sized(ArrowLeftSvg, 18, 16);

const styles = StyleSheet.create({
  root: {},
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 21,
  },
  backButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    marginRight: 15,
  },
});

const TitleText = variance(TypoHeader)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
  },
}));
