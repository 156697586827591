import {BasicErrorModalState, VisibleParams} from './BasicErrorModalState';
import {action, observable, makeObservable} from 'mobx';

export default class BasicErrorModalStateImpl implements BasicErrorModalState {
  @observable private _isVisible = false;
  @observable private _description: string | undefined;
  @observable private _title: string | undefined;

  constructor() {
    makeObservable(this);
  }

  get isVisible() {
    return this._isVisible;
  }

  get description() {
    return this._description;
  }

  get title() {
    return this._title;
  }

  setVisible = action((params: VisibleParams) => {
    this._isVisible = true;
    this._description = params.description;
    this._title = params.title;
  });

  reset = action(() => {
    this._isVisible = false;
    this._description = '';
  });
}
