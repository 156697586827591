import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {CopyInstallIdModal} from '../../CopyInstallIdModal';
import useGetNewWorkerId from '../../CopyInstallIdModal/useGetNewWorkerId';
import {useRoot, useStrings} from '../../Root/hooks';
import {Target, useOpenLink} from '../../Links';

export default observer(function CopyInstallIdBinding() {
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const strings = useStrings();
  const {
    configuration: {values},
    flashMessages,
    clipboard,
  } = useRoot();
  const {getNewWorkerId} = useGetNewWorkerId();
  const [workerId, setWorkerId] = useState<string>();
  useEffect(() => {
    async function prepare() {
      const result = await getNewWorkerId();
      if (result?.workerConnectionId) {
        setWorkerId(result?.workerConnectionId);
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    prepare();
  }, [getNewWorkerId]);
  const link = workerId
    ? values.downloadFarmMinerUrl.replace('{id}', workerId)
    : values.downloadUrl;
  const openLink = useOpenLink(link, Target.Blank);
  const onCopyPress = useCallback(async () => {
    if (workerId === undefined) {
      return;
    }
    clipboard.setString(workerId);
    flashMessages.showMessage({
      title: strings['action.theValueHasBeenCopiedToClipboard'],
      variant: 'success',
    });
  }, [clipboard, flashMessages, strings, workerId]);
  return (
    <CopyInstallIdModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onGoToSitePress={openLink}
      onCopyPress={onCopyPress}
      workerId={workerId}
    />
  );
});
