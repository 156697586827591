import {useEffect} from 'react';
import {useNavigation} from '@react-navigation/native';
import ScheduleState from './ScheduleState';

export default function useBeforeRemoveWatcher(
  state: ScheduleState,
  onBeforeRemove: () => void,
) {
  const navigation = useNavigation();
  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (state.numberOfChangedPoints > 0 && e.data.action.type !== 'RESET') {
          e.preventDefault();
          onBeforeRemove();
        }
      }),
    [navigation, state, onBeforeRemove],
  );
}
