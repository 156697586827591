import React from 'react';
import {observer} from 'mobx-react-lite';
import {DebugScreen} from '../DebugScreen';
import {
  NoConnectionScreen,
  ServiceUnavailableScreen,
  useOnRetry,
  useOnSwitchEnvironment,
} from '../ErrorScreen';
import {useRoot} from '../Root/hooks';
import {useOpenLink} from '../Links';
import RNExitApp from '../ReactNativeExitApp';

const ON_CLOSE = () => RNExitApp.exitApp();
export default observer(function NoConnectionBinding() {
  const {debug, customDisconnect} = useRoot();
  return debug.debugEnabled ? (
    <DebugScreen />
  ) : customDisconnect.active ? (
    <ServiceUnavailableBinding />
  ) : (
    <DefaultBinding />
  );
});

const ServiceUnavailableBinding = observer(
  function ServiceUnavailableBinding() {
    const {configuration} = useRoot();
    const onRetry = useOnRetry();
    const onSwitchEnvironment = useOnSwitchEnvironment();
    const onRequestSupport = useOpenLink(
      `${configuration.values.aboutUrl}/feedback/`,
    );
    return (
      <ServiceUnavailableScreen
        onRetry={onRetry}
        closeApp={ON_CLOSE}
        onSwitchEnvironment={onSwitchEnvironment}
        onRequestSupport={onRequestSupport}
      />
    );
  },
);

const DefaultBinding = observer(function RegularBinding() {
  const onRetry = useOnRetry();
  const onSwitchEnvironment = useOnSwitchEnvironment();
  return (
    <NoConnectionScreen
      onRetry={onRetry}
      closeApp={ON_CLOSE}
      onSwitchEnvironment={onSwitchEnvironment}
    />
  );
});
