import {Service} from '../structure';
import {InteractiveTutorialState} from '../InteractiveTutorial';
import {NavigationContainer} from '../NavigationContainer';
import {Auth} from '../Auth';
import {observable, reaction, makeObservable, runInAction} from 'mobx';
import {define, TUTORIAL_PARAMS} from '../persistence';
import {FULLTUT_TAG, INTERTUT_TAG} from '../ApiStore';
import {TutorialManager, TutorialType} from './TutorialManager';
import {bind} from '../fp';
import {AuthFarmStateHelper} from '../AuthFarmStateHelper';

export default class TutorialManagerService
  implements TutorialManager, Service
{
  @observable
  private _shouldOpenTutorialModal: TutorialManager['shouldOpenTutorialModal'];
  @observable private _ready = false;

  constructor(
    private readonly _root: {
      readonly interactiveTutorial: InteractiveTutorialState;
      readonly navigationContainer: NavigationContainer;
      readonly auth: Auth;
      readonly authFarmStateHelper: AuthFarmStateHelper;
    },
  ) {
    makeObservable(this);
  }

  get ready() {
    return this._ready;
  }

  get shouldOpenTutorialModal() {
    return this._shouldOpenTutorialModal;
  }

  unflag = bind(() => {
    this._shouldOpenTutorialModal = undefined;
  }, this);

  private static async _markDone() {
    await setTutorialParams({done: true});
  }

  private _listenAccountChange() {
    return reaction(
      () => this._root.auth.state,
      async (authState, prevState, _) => {
        if (authState?.kind !== 'Connected') {
          return;
        }
        if (!this._root.authFarmStateHelper.isNewbie) {
          // noinspection ES6MissingAwait
          TutorialManagerService._markDone();
          _.dispose();
          runInAction(() => (this._ready = true));
          return;
        }
        const tutorialParams = await getTutorialParams();
        if (!tutorialParams.success || tutorialParams.right?.done) {
          runInAction(() => (this._ready = true));
          _.dispose();
          return;
        }
        const tags = authState.tags;
        if (tags.has(FULLTUT_TAG)) {
          await TutorialManagerService._markDone();
          runInAction(() => {
            this._shouldOpenTutorialModal = TutorialType.Splash;
            this._ready = true;
          });
          _.dispose();
        } else if (tags.has(INTERTUT_TAG)) {
          await TutorialManagerService._markDone();
          runInAction(() => {
            this._shouldOpenTutorialModal = TutorialType.Interactive;
            this._ready = true;
          });
          _.dispose();
        }
      },
    );
  }

  subscribe() {
    return this._listenAccountChange();
  }
}

type TutorialRecord = {
  done: true;
};

export const [getTutorialParams, setTutorialParams] =
  define<TutorialRecord>(TUTORIAL_PARAMS);
