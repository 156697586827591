import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {useRoot} from '../../Root/hooks';
import {AddNewWorkerGroupModal} from '../../AddNewWorkerGroupModal';
import {useErrorHandler} from '../../ExceptionHandler';

export default observer(function AddNewWorkerGroupBinding() {
  const {connectedClient} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const close = useCallback(() => modalRef.current?.close(), []);
  const errorHandler = useErrorHandler();
  const onCancel = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);
  const onConfirm = useCallback(
    async (groupName: string) => {
      await close();
      const result = await connectedClient.call('create_group', {
        name: groupName,
        worker_ids: [],
      });
      if (!result.success) {
        errorHandler.handle(result.left);
      }
      safelyGoBack();
    },
    [connectedClient, close, errorHandler, safelyGoBack],
  );
  const modalRef = useRef<AdaptiveModalRef>(null);
  return (
    <AddNewWorkerGroupModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
});
