import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {
  SlotStatus,
  WorkerEntity,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {FlatListProps, ListRenderItem, StyleSheet} from 'react-native';
import WorkersListItem from './WorkersListItem';
import {Divider} from '../components/atoms';
import {AdaptiveFlatList} from '../components/templates/AdaptiveModal';

export type WorkersListProps = {
  workers: WorkerEntity[];
  userTimezoneMinutes: number;
  selectedWorkerIdList: string[];
  onWorkerSelect: (workerId: string) => void;
};

const keyExtractor: ListProps['keyExtractor'] = (item) => item.id;

export default observer(function WorkersList(props: WorkersListProps) {
  const {workers, selectedWorkerIdList, onWorkerSelect, userTimezoneMinutes} =
    props;

  const renderItem: ListRenderItem<WorkerEntity> = useCallback(
    ({item}) => (
      <WorkersListItem
        worker={item}
        isDisabled={item.slot_status === SlotStatus.Inactive}
        isTimezoneDiffer={item.utc_offset !== userTimezoneMinutes}
        selected={selectedWorkerIdList.includes(item.id)}
        onSelect={onWorkerSelect}
      />
    ),
    [selectedWorkerIdList, onWorkerSelect, userTimezoneMinutes],
  );

  return (
    <AdaptiveFlatList
      data={workers}
      contentContainerStyle={styles.container}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={Divider}
    />
  );
});

type ListProps = FlatListProps<WorkerEntity>;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
});
