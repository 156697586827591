import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';
import {useLanguage} from '../hooks';
import {Paragraph, PressableOpacity} from '../components';

interface LanguageProps {
  hideLabel?: boolean;
  goToLanguage: () => void;
  style?: StyleProp<ViewStyle>;
  pickerStyle?: StyleProp<ViewStyle>;
}

/**
 * @deprecated
 */
export const Language = observer(
  ({hideLabel, goToLanguage, style, pickerStyle}: LanguageProps) => {
    const items = useLanguage();

    const {translation} = useRoot();
    const strings = useStrings();
    const pickedValue = items.find((item) => item.id === translation.locale);

    const styles = useStyles((theme) => ({
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      pickerSelectedView: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: 11,
        backgroundColor: theme.colors.uiMain,
        borderRadius: 8,
      },
      pickerSelectedText: {
        marginLeft: 8,
        marginRight: 6,
        color: theme.colors.uiSecondary,
      },

      dot: {
        width: 3,
        height: 3,
        borderRadius: 3,
        backgroundColor: theme.colors.primaryUIDirtyBlue,
      },

      dotFirst: {
        marginBottom: 2,
      },
    }));

    const Icon = pickedValue?.icon;

    return (
      <PressableOpacity
        activeOpacity={0.9}
        style={[styles.container, style]}
        onPress={goToLanguage}>
        {!hideLabel && (
          <SecondaryMenuItem disabled text={strings['menu.language']} />
        )}

        <View style={[styles.pickerSelectedView, pickerStyle]}>
          {Icon && <Icon width={18} height={18} />}
          <Paragraph
            type="paragraph"
            size="large"
            weight="500"
            style={[styles.pickerSelectedText]}>
            {pickedValue?.title ?? strings['menu.language']}
          </Paragraph>
          <View>
            <View style={[styles.dot, styles.dotFirst]} />
            <View style={styles.dot} />
          </View>
        </View>
      </PressableOpacity>
    );
  },
);
