import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {variance} from '../styling';
import {Qr, Typography} from '../components';
import {useTemplates} from '../Root/hooks';

export type QrViewProps = {
  value: string;
  fee: string | undefined;
};

export default observer(function QrView({value, fee}: QrViewProps) {
  const templates = useTemplates();
  return (
    <RootView>
      <QrContentView>
        <Qr text={value} />
      </QrContentView>
      {fee && (
        <RecommendedFeeTypography type="body" weight="500">
          {templates['payment.recommendedFee']({fee})}
        </RecommendedFeeTypography>
      )}
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {},
}));

const QrContentView = variance(View)((theme) => ({
  root: {
    maxWidth: '100%',
    padding: 20,
    width: 240,
    backgroundColor: theme.colors.primaryWhite,
    borderWidth: 1,
    borderColor: theme.chroma(theme.colors.uiSecondary).alpha(0.15).hex(),
    borderRadius: 24,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4,
    elevation: 2,
    shadowColor: theme.colors.uiSecondary,
    marginBottom: 8,
  },
}));

const RecommendedFeeTypography = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiAdditional4,
    textAlign: 'center',
    marginBottom: 24,
    fontSize: 13,
    lineHeight: 16,
  },
}));
