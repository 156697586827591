import React, {forwardRef, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {StyleSheet, View} from 'react-native';
import {useStrings} from '../Root/hooks';
import {createStylesHook} from '../styling';
import {getCommissionerFontStyles, Typography} from '../components';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {sized} from '../Svg';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import Markdown from '../components/molecules/Markdown';

export type LeaveScheduleUnsavedModalProps = {
  ownerName: string | undefined;
  onModalClosed: () => void;
  onClose: () => void;
  onConfirm: () => void;
};

export default observer<LeaveScheduleUnsavedModalProps, AdaptiveModalRef>(
  forwardRef(function LeaveScheduleUnsavedModal(props, ref) {
    const {onModalClosed} = props;
    return (
      <AdaptiveModal
        modalWidth={ModalWidth.Small}
        ref={ref}
        enableContentAutoHeight
        onModalClosed={onModalClosed}>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer((props: LeaveScheduleUnsavedModalProps) => {
  const themedStyles = useStyles();
  const {ownerName, onClose, onConfirm} = props;
  const strings = useStrings();

  const description = useMemo(() => {
    return ownerName
      ? strings['modal.discardUnsaved.descriptionWithName'].replace(
          '{name}',
          ownerName,
        )
      : strings['modal.discardUnsaved.description'];
  }, [ownerName, strings]);
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <View style={[{paddingBottom}]}>
      <View style={styles.top}>
        <Typography type={'header'} size={'h4'}>
          {strings['modal.discardUnsaved.title']}
        </Typography>
        <View style={styles.warningView}>
          <WarningIcon />
        </View>
      </View>
      <View style={styles.modalBody}>
        <Markdown
          styles={{
            paragraph: themedStyles.paragraph,
          }}>
          {description}
        </Markdown>
      </View>
      <View style={styles.modalFooter}>
        <AppButton style={styles.cancelButton} onPress={onClose}>
          {strings['action.cancel']}
        </AppButton>
        <AppButton variant={ButtonVariant.Primary} onPress={onConfirm}>
          {strings['action.confirm']}
        </AppButton>
      </View>
    </View>
  );
});

const WarningIcon = sized(WarningSvg, 64);

const useStyles = createStylesHook((theme) => ({
  paragraph: {
    ...getCommissionerFontStyles('500'),
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 20,
    color: theme.colors.primaryUIDirtyBlue,
  },
}));

const styles = StyleSheet.create({
  top: {
    paddingTop: 30,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  warningView: {
    marginTop: 16,
  },
  modalBody: {
    paddingHorizontal: 30,
    alignItems: 'center',
    marginBottom: 15,
  },
  modalFooter: {
    paddingHorizontal: 16,
  },
  cancelButton: {
    marginBottom: 10,
  },
});
