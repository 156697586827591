import React, {useCallback} from 'react';
import {
  ImageSourcePropType,
  LayoutRectangle,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import ColoredBanner, {
  ColoredBannerProps,
  DefaultButton,
  ORANGE_BACKGROUND,
  ShadowColor,
} from '../../../components/molecules/ColoredBanner';
import {AdInfoAction, AdInfoItem} from '../../../Advert';
import {useRoot} from '../../../Root/hooks';
import SwipeableBanner from '../../../components/molecules/SwipeableBanner';

export type AdvertBannerProps = Pick<ColoredBannerProps, 'style'> & {
  ad: AdInfoItem;
  swipeable?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  childrenContainerStyle?: StyleProp<ViewStyle>;
  background?: ImageSourcePropType;
  shadowColor?: ShadowColor;
};

export default observer(function AdvertBanner({
  ad,
  swipeable = false,
  containerStyle,
  childrenContainerStyle,
  background = ORANGE_BACKGROUND,
  shadowColor = ShadowColor.Orange,
  ...rest
}: AdvertBannerProps) {
  const {advertHelper, location} = useRoot();
  const title = advertHelper.translate(ad.title) || '';
  const body = advertHelper.translate(ad.body) || '';

  const descriptionVisible = useCallback(
    (layout: LayoutRectangle | undefined) => {
      return !(layout && layout?.width < 460);
    },
    [],
  );
  const iconVisible = useCallback((layout: LayoutRectangle | undefined) => {
    return !(layout && layout?.width < 310);
  }, []);
  const close = useCallback(
    (exclude: boolean) => advertHelper.close(ad.id, ad.spot, exclude),
    [ad, advertHelper],
  );
  const onButtonPress = useCallback(
    (action: AdInfoAction) => {
      if (action.link) {
        location.open(action.link);
      }
      close(true);
    },
    [close, location],
  );
  const content = (
    <ColoredBanner
      contrast
      title={title}
      text={body}
      shadowColor={shadowColor}
      onClose={ad.options.canClose ? () => close(false) : undefined}
      icon={ad.icon && {uri: ad.icon}}
      background={background}
      iconViewProps={{style: styles.iconView}}
      descriptionVisible={descriptionVisible}
      iconVisible={iconVisible}
      actions={ad.actions.map((action) => (
        <DefaultButton
          contrast
          style={styles.bannerButton}
          onPress={() => onButtonPress(action)}
          title={advertHelper.translate(action.title) || ''}
        />
      ))}
      {...rest}
    />
  );
  if (swipeable) {
    return (
      <SwipeableBanner
        containerStyle={containerStyle}
        childrenContainerStyle={childrenContainerStyle}
        enabled={ad.options.canClose}
        onDelete={() => close(false)}>
        {content}
      </SwipeableBanner>
    );
  }
  return content;
});

const styles = StyleSheet.create({
  bannerButton: {
    minWidth: 80,
  },
  iconView: {
    marginRight: -16,
    marginTop: -12,
    marginBottom: -10,
    width: 140 * 1.4,
  },
});
