import {Disposer, RouterImpl} from '../structure';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {MeasureResult} from '../ReactNativeUtil';
import {
  ELEMENT_MEASURE,
  FORCE_ELEMENT_MEASURE,
  FINISHED,
  InteractiveTutorialState,
  SCROLL_TO_ELEMENT,
  SCROLLED,
  StackElementKey,
  TUTORIAL_STACK,
} from './InteractiveTutorialState';

export default class InteractiveTutorialStateImpl
  implements InteractiveTutorialState
{
  private readonly _tutorialStack = TUTORIAL_STACK;
  @observable private _isShown = false;
  @observable private _activeStepIndex = 0;
  @observable private _scrollToElementInProgress = false;
  private _measureByKey = observable.map<StackElementKey, MeasureResult>();

  constructor() {
    makeObservable(this);
  }

  get isShown() {
    return this._isShown;
  }
  get scrollToElementInProgress() {
    return this._scrollToElementInProgress;
  }
  get tutorialStack() {
    return this._tutorialStack;
  }

  @computed
  get accentElement() {
    const {elementKey} = TUTORIAL_STACK[this._activeStepIndex];
    return {key: elementKey, measure: this._measureByKey.get(elementKey)};
  }

  @computed
  get activeStepIndex() {
    return this._activeStepIndex;
  }
  @computed
  get activeStep() {
    const index = this.activeStepIndex;
    return TUTORIAL_STACK[index];
  }

  private async _prev() {
    if (this._activeStepIndex === 0 || this._scrollToElementInProgress) {
      return;
    }

    const newIndex = this._activeStepIndex - 1;
    await this._goToIndex(newIndex);
    runInAction(() => (this._activeStepIndex = newIndex));
  }
  prev = () => this._prev();

  private async _next() {
    if (
      this._activeStepIndex === this.tutorialStack.length - 1 ||
      this._scrollToElementInProgress
    ) {
      return;
    }
    const newIndex = this._activeStepIndex + 1;
    await this._goToIndex(newIndex);
    runInAction(() => (this._activeStepIndex = newIndex));
  }
  next = () => this._next();

  private async _skip() {
    runInAction(() => (this._isShown = false));
    this.events.send(FINISHED, undefined);
  }
  skip = () => this._skip();

  async start() {
    if (this._isShown) {
      console.warn('Interactive tutorial already shown');
      return;
    }
    this._startListenMeasures();

    runInAction(() => {
      this._activeStepIndex = 0;
      this._isShown = true;
    });

    await this._goToIndex(0);
  }

  private _unsubscribeMeasureListener?: Disposer;
  private _startListenMeasures() {
    this._unsubscribeMeasureListener = this.events.listen(
      ELEMENT_MEASURE,
      action(({key, measure}) => this._measureByKey.set(key, measure)),
    );
  }

  private async _finish() {
    this._unsubscribeMeasureListener?.();
    runInAction(() => (this._isShown = false));
    this.events.send(FINISHED, undefined);
  }
  finish = () => this._finish();

  private async _goToIndex(index: number) {
    const {elementKey, inScrollFlow} = TUTORIAL_STACK[index];
    if (inScrollFlow) {
      runInAction(() => (this._scrollToElementInProgress = true));
      await this._scrollToElement(elementKey);
      this.events.send(FORCE_ELEMENT_MEASURE, elementKey);
      runInAction(() => (this._scrollToElementInProgress = false));
      return;
    }
    this.events.send(FORCE_ELEMENT_MEASURE, elementKey);
  }

  private async _scrollToElement(key: StackElementKey) {
    const listener = new Promise<boolean>((resolve) => {
      const unsubscribe = this.events.listen(SCROLLED, () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        unsubscribe();
        resolve(true);
      });
      // In case the page does not respond
      const timeout = setTimeout(() => {
        unsubscribe();
        resolve(false);
      }, 2000);
    });
    this.events.send(SCROLL_TO_ELEMENT, {key});

    return listener;
  }

  readonly events = new RouterImpl<{
    [ELEMENT_MEASURE]: {
      key: StackElementKey;
      measure: MeasureResult;
    };
    [FORCE_ELEMENT_MEASURE]: StackElementKey;
    [SCROLL_TO_ELEMENT]: {
      key: StackElementKey;
    };
    [SCROLLED]: void;
    [FINISHED]: void;
  }>();
}
