import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../../styling';
import GiftSvg from '../../../assets/svg/colorless/gift-double.svg';
import {Platform, View} from 'react-native';
import {sized} from '../../../Svg';
import {Body} from '../../Typography';
import {useStrings} from '../../../Root/hooks';

export default observer(function DoubleGiftSection() {
  const theme = useTheme();
  const strings = useStrings();
  const descriptionText =
    Platform.OS === 'web'
      ? strings['purchaseRow.doubleGift.web']
      : strings['purchaseRow.doubleGift'];

  return (
    <DoubleGiftContainer>
      <View>
        <GiftIcon color={theme.colors.primaryWhite} />
      </View>
      <Description type="body" weight="600">
        {descriptionText}
      </Description>
    </DoubleGiftContainer>
  );
});

const GiftIcon = sized(GiftSvg, 24, 24);

const DoubleGiftContainer = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryError,
    paddingHorizontal: 16,
    paddingBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 18,
    marginTop: -12,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
  },
}));

const Description = variance(Body)((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: 18,
    color: theme.colors.primaryWhite,
    marginLeft: 10,
    flex: 1,
  },
}));
