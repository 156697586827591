import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {PowerSlider} from '../../molecules/Slider';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';
import {StyleSheet, View} from 'react-native';
import {expr} from 'mobx-utils';
import useHugePagesColor from '../../../DashboardScreen/hooks/useHugePagesColor';
import {useWorkerState} from '../../../WorkerStateRegistry';
import {useTheme, variance} from '../../../styling';
import {useStrings} from '../../../Root/hooks';
import Typography, {Body} from '../../Typography';
import {SliderRef} from '../../molecules/Slider/Slider';
import {WorkerId} from '../../../ApiStore';
import useWorkerStatuses from '../../../DashboardScreen/hooks/useWorkerStatuses';
import useHugeText from '../../../DashboardScreen/hooks/useHugeText';
import clamp from '../../../utils/clamp';
import {useDebouncedCallback} from 'use-debounce';

export type WorkerPowerSliderProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  onValueChanged: (speedValue: number, workerId: WorkerId) => void;
  goToPoolMinerSchedulerWarning: (workerId: WorkerId) => void;
  visibleTitle: boolean;
};

export default observer(function WorkerPowerSlider(
  props: WorkerPowerSliderProps,
) {
  const {
    worker,
    goToPoolMinerSchedulerWarning: goToPoolMinerSchedulerWarning_,
    visibleTitle,
  } = props;
  const theme = useTheme();
  const state = useWorkerState(worker.id);
  const {readonlySpeedControl, disabled, needActivate, expired, inactive} =
    useWorkerStatuses(worker);
  const strings = useStrings();
  const hugePagesColor = useHugePagesColor(worker, theme);
  const sliderValue = expr(
    () => state?.current_settings.speed ?? worker.default_settings.speed,
  );
  const isGrey = expr(() => needActivate || expired || inactive);
  const text = useHugeText(worker);
  const HugePage = useCallback(() => {
    let color = hugePagesColor;
    if (isGrey) {
      color = theme.colors.uiAdditional4;
    }
    if (expired) {
      color = hugePagesColor;
    }
    return (
      <View style={styles.hugePage}>
        <View style={[styles.dot, {backgroundColor: hugePagesColor}]} />
        <Typography
          type="body"
          weight="500"
          disabled={isGrey}
          style={[
            styles.status,
            {
              color: color,
            },
          ]}>
          {text}
        </Typography>
      </View>
    );
  }, [hugePagesColor, text, isGrey, expired, theme]);
  const sliderRef = useRef<SliderRef>(null);
  const goToPoolMinerSchedulerWarning = useCallback(
    () => !disabled && goToPoolMinerSchedulerWarning_(worker.id),
    [disabled, goToPoolMinerSchedulerWarning_, worker.id],
  );
  const onValueChanged = useDebouncedCallback(
    (speed: number) => props.onValueChanged(speed, worker.id),
    1000,
  );
  const step = expr(() =>
    Math.round(
      clamp(100 / (worker.mining_options?.optimal_threads ?? 100), 1, 5),
    ),
  );
  return (
    <View style={styles.root}>
      {visibleTitle && (
        <TitleText type="body" disabled={isGrey} weight="500">
          {strings['worker.threadsTitle']}
        </TitleText>
      )}
      <View style={styles.hugePageContainer}>
        <HugePage />
      </View>
      <PowerSlider
        ref={sliderRef}
        value={sliderValue}
        disabled={disabled}
        disabledMaxButton={readonlySpeedControl}
        disabledMinButton={readonlySpeedControl}
        readonly={readonlySpeedControl}
        onReadonlyPress={goToPoolMinerSchedulerWarning}
        step={step}
        range={[0, 100]}
        onValueChanged={onValueChanged}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
  },
  hugePage: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hugePageContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
  },
  status: {
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
  },
  dot: {
    borderRadius: 3,
    width: 6,
    height: 6,
    marginRight: 4,
  },
});

const TitleText = variance(Body)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
    color: theme.colors.uiSecondary,
    marginBottom: 4,
  },
  disabled: {
    color: theme.colors.uiAdditional4,
  },
}));
