import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {View} from 'react-native';
import {sized} from '../Svg';
import {Header} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {
  ADAPTIVE_BREAKPOINT,
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import YellowCheckSvg from '../assets/svg/circled/yellowCheck.svg';
import DarkCheckSvg from '../assets/svg/circled/darkCheck.svg';

type PaymentCompletedScreenProps = {
  onClose: () => void;
};

export default observer<PaymentCompletedScreenProps, AdaptiveModalRef>(
  forwardRef(function PaymentCompletedScreen({onClose}, ref) {
    const {
      appearance: {isDark},
    } = useRoot();
    const strings = useStrings();
    return (
      <AdaptiveModal
        modalWidth={ModalWidth.Small}
        ref={ref}
        enableContentAutoHeight
        onModalClosed={onClose}
        adaptiveBreakpoint={ADAPTIVE_BREAKPOINT}>
        <RootView>
          <YellowCheckView>
            {isDark ? <YellowCheckIcon /> : <DarkCheckIcon />}
          </YellowCheckView>
          <Header type="header" size="h3">
            {strings['paymentCompleted.title']}
          </Header>
        </RootView>
      </AdaptiveModal>
    );
  }),
);

const RootView = variance(View)((theme) => ({
  root: {
    height: 200,
    backgroundColor: theme.colors.uiMain,
    maxWidth: 900,
    width: '100%',
    marginHorizontal: 'auto',
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const YellowCheckView = variance(View)(() => ({
  root: {
    marginBottom: 12,
  },
}));

const YellowCheckIcon = sized(YellowCheckSvg, 64, 64);
const DarkCheckIcon = sized(DarkCheckSvg, 64, 64);
