import React from 'react';
import {StyleSheet} from 'react-native';
import {observer} from 'mobx-react-lite';
import {LinearGradient} from 'expo-linear-gradient';
import {useTheme, variance} from '../../../styling';
import {TouchableOpacity} from 'react-native';
import ChevronLeftSvg from '../../../assets/svg/colorless/chevronLeft.svg';
import ChevronRightSvg from '../../../assets/svg/colorless/chevronRight.svg';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type ExpandButtonProps = {
  isExpanded: boolean;
  toggle: () => void;
};

export default observer(function ExpandButton({
  isExpanded,
  toggle,
}: ExpandButtonProps) {
  const theme = useTheme();
  const Icon = isExpanded ? ChevronLeftSvg : ChevronRightSvg;
  const insets = useSafeAreaInsets();
  return (
    <TouchableOpacity
      onPress={toggle}
      activeOpacity={0.9}
      style={[styles.root, {top: 29 + insets.top}]}>
      <ContentView
        colors={[
          theme.colors.gradientBtnYellow[1],
          theme.colors.gradientBtnYellow[0],
        ]}
        start={{x: 0.4, y: 0.45}}
        end={{x: 0.95, y: 1}}>
        <Icon color={theme.colors.primaryWhite} />
      </ContentView>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    right: -12,
    zIndex: 2,
  },
});

const ContentView = variance(LinearGradient)(() => ({
  root: {
    width: 24,
    height: 24,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
