import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {Typography, Header as TypoHeader} from '../components';
import React from 'react';
import {variance} from '../styling';

export type SectionHeaderProps = Partial<{
  left: string | number;
  right: string | number;
}>;

export default observer(function SectionHeader({
  left,
  right,
}: SectionHeaderProps) {
  return (
    <RootView>
      <View style={styles.left}>
        <TitleText
          numberOfLines={1}
          type="header"
          size="h3"
          weight="600"
          style={styles.title}>
          {left}
        </TitleText>
      </View>
      <View style={styles.right}>
        <Typography
          numberOfLines={1}
          ellipsizeMode="middle"
          type="header"
          size="h3"
          weight="600"
          style={styles.title}>
          {right}
        </Typography>
      </View>
    </RootView>
  );
});

const styles = StyleSheet.create({
  left: {
    paddingRight: 10,
  },
  right: {
    flex: 1,
    alignItems: 'flex-end',
  },
  title: {
    textTransform: 'uppercase',
    letterSpacing: 2,
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 10,
    backgroundColor: theme.colors.uiAdditional1,
  },
}));

const TitleText = variance(TypoHeader)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
  },
}));
