import React from 'react';
import {sized} from '../../Svg';
import {
  BnbSvg,
  BtcSvg,
  CtcSvg,
  EthSvg,
  EurSvg,
  PolSVG,
  SolSvg,
  TronSvg,
  UsdSvg,
  UsdtSvg,
  TonSvg,
} from '../../assets/svg/currency';
import {SvgProps} from 'react-native-svg';

const getIcon = (Icon: React.ComponentType<SvgProps>) => sized(Icon, 20, 20);

export const coins = [
  {
    id: 1,
    Icon: getIcon(BtcSvg),
    name: 'BTC',
  },
  {
    id: 2,
    Icon: getIcon(EthSvg),
    name: 'ETH',
  },
  {
    id: 3,
    Icon: getIcon(CtcSvg),
    name: 'CTC',
  },
  {
    id: 4,
    Icon: getIcon(PolSVG),
    name: 'POL',
  },
  {
    id: 5,
    Icon: getIcon(UsdtSvg),
    name: 'USDT',
  },
  {
    id: 7,
    Icon: getIcon(BnbSvg),
    name: 'BNB',
  },
  {
    id: 8,
    Icon: getIcon(SolSvg),
    name: 'SOL',
  },
  {
    id: 9,
    Icon: getIcon(UsdSvg),
    name: 'USD(CTC)',
  },
  {
    id: 11,
    Icon: getIcon(EurSvg),
    name: 'EUR(CTC)',
  },
  {
    id: 10,
    Icon: getIcon(TronSvg),
    name: 'TRX',
  },
  {
    id: 11,
    Icon: getIcon(TonSvg),
    name: 'TON',
  },
];
