import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Typography} from '../components';
import {UniversalColor} from '../color';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type SpeedToggleItemProps = {
  selected?: boolean;
  label?: string;
  color?: string;
  last?: boolean;
  keepUnselected?: boolean;
  onPress: () => void;
};

export default observer(function SpeedToggleItem(props: SpeedToggleItemProps) {
  const {label, selected, last, color, keepUnselected = false, onPress} = props;
  const theme = useTheme();

  const styles = useMemo(() => {
    const selectedBackground = selected ? color : 'transparent';
    const selectedBorderColor = selected
      ? color
      : theme
          .chroma(color as UniversalColor)
          .alpha(0.35)
          .hex();
    const labelColor = selected ? theme.colors.primaryWhite : color;

    return StyleSheet.create({
      root: {
        marginLeft: last ? 'auto' : 0,
        marginRight: last ? 0 : 10,
        borderColor: keepUnselected
          ? theme.colors.uiAdditional1
          : selectedBorderColor,
        backgroundColor: selectedBackground,
        ...theme.mediaQuery({
          [LG_BREAKPOINT]: {
            marginLeft: last ? 10 : 0,
          },
        }),
      },
      label: {
        color: keepUnselected ? theme.colors.primaryUIDirtyBlue : labelColor,
      },
    });
  }, [last, theme, selected, keepUnselected, color]);

  return (
    <SpeedItem
      accessibilityRole="button"
      activeOpacity={0.8}
      onPress={onPress}
      style={[styles.root]}>
      {label && (
        <Typography type={'paragraph'} weight={'500'} style={styles.label}>
          {label}
        </Typography>
      )}
    </SpeedItem>
  );
});

const SpeedItem = variance(TouchableOpacity)((theme) => ({
  root: {
    flexBasis: 50,
    minHeight: 30,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 6,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: theme.chroma(theme.colors.uiAdditional1).alpha(0.75).hex(),
  },
}));
