import {useRoot} from '../../Root/hooks';
import {AccountType} from '../../Auth';
import {ApiMode} from '../../farmApi';

export default (
  goToWithdraw: () => void,
  goToPlan: () => void,
  goToAuthAndThenToWithdraw: () => void,
) => {
  const {auth, apiStore} = useRoot();
  const {accountType} = auth;
  const {mode} = apiStore;
  return () => {
    if (accountType === AccountType.Permanent) {
      if (mode === ApiMode.Real) {
        goToWithdraw();
      } else {
        goToPlan();
      }
    } else {
      goToAuthAndThenToWithdraw();
    }
  };
};
