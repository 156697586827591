import {ReadonlyDeep} from 'type-fest';
import {
  WorkerEntity,
  WorkerType,
} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {Theme} from '../../styling';
import {useWorkerState} from '../../WorkerStateRegistry';
import useWorkerStatuses from './useWorkerStatuses';

export default function useHugePagesColor(
  worker: ReadonlyDeep<WorkerEntity>,
  theme: Theme,
): string {
  const {needActivate} = useWorkerStatuses(worker);
  const state = useWorkerState(worker.id);
  if (worker.worker_type === WorkerType.Pool && worker.expired) {
    return theme.colors.primaryError;
  } else if (needActivate) {
    return theme.colors.primaryAttractive;
  } else if (!worker.mining_options?.is_huge_pages_enabled) {
    return theme.colors.primaryError;
  } else if (state?.current_huge_pages === 0 || state?.max_huge_pages === 0) {
    return theme.colors.primaryNew;
  } else if (!state) {
    return theme.colors.primaryUIDirtyBlue;
  }
  return theme.colors.primarySuccess;
}
