import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef, useState} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {MoveWorkerToGroupModal} from '../../MoveWorkerToGroupModal';
import {useRoot} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {RootStackBindingProps} from './RootStackBindingProps';
import {WorkerGroupEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {GroupId} from '../../ApiStore';
import {useErrorHandler} from '../../ExceptionHandler';

export type MoveWorkerToGroupBindingProps =
  RootStackBindingProps<'MoveWorkerToGroup'>;

export default observer(function MoveWorkerToGroupBinding({
  route,
}: MoveWorkerToGroupBindingProps) {
  const safelyGoBack = useSafelyGoBack();
  const close = () => modalRef.current?.close();
  const {dashboardStore, connectedClient} = useRoot();
  const [selectedGroupId, setSelectedGroupId] = useState<GroupId>();
  const workerId = route.params?.workerId;
  const workerGroupList = dashboardStore.dashboardRequest.value?.groups ?? [];
  const groups = expr(() =>
    workerGroupList
      .filter((group) => !group.workers.some((_) => _.id === workerId))
      .sort((a, b) => a.id - b.id),
  );
  const select = useCallback(
    (group: WorkerGroupEntity) => setSelectedGroupId(group.id),
    [],
  );

  const onCancel = useCallback(async () => {
    close();
    safelyGoBack();
  }, [safelyGoBack]);

  const errorHandler = useErrorHandler();
  const onConfirm = useCallback(async () => {
    close();
    if (selectedGroupId === undefined || workerId === undefined) {
      safelyGoBack();
      return;
    }
    const selectedGroup = groups.find((g) => g.id === selectedGroupId);
    const selectedGroupWorkersIds = selectedGroupId
      ? (selectedGroup?.workers ?? []).map((worker) => worker.id)
      : [];
    const result = await connectedClient.call('set_workers_to_group', {
      group_id: selectedGroupId,
      worker_ids: [...selectedGroupWorkersIds, workerId],
    });
    if (!result.success) {
      errorHandler.handle(result.left);
    }
    safelyGoBack();
  }, [
    safelyGoBack,
    connectedClient,
    errorHandler,
    groups,
    selectedGroupId,
    workerId,
  ]);

  const modalRef = useRef<AdaptiveModalRef>(null);
  return (
    <MoveWorkerToGroupModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onSelectGroup={select}
      groups={groups}
      selectedGroupId={selectedGroupId}
    />
  );
});
