import {action, flow, observable, makeObservable} from 'mobx';
import {DAY, HOUR} from '../utils/time';
import {Millisecond} from '../Time';
import {ChartViewScope} from '../GraphPanel';
import {ReadonlyDeep} from 'type-fest';
import {Graph} from '../GraphPanel';
import {WorkerId} from '../ApiStore';
import graphPeriod from '../GraphPanel/graphPeriod';
import {GetMiningStatisticResponse} from '../../universal/features/api/entity/GetMiningStatisticResponse';
import miningStatisticGraph from '../helpers/miningStatisticGraph';
import {SocketApiService} from '../../universal/features/api/socket/SocketApiService';
import {CancellablePromise} from '../CancellablePromise';

export default class WorkerStatisticsPanelState {
  @observable private _from?: Millisecond;
  @observable private _scope = ChartViewScope.Day;
  @observable.ref protected _graph?: ReadonlyDeep<Graph>;
  @observable protected _isLoading = false;
  @observable protected _isRefreshing = false;

  constructor(
    private readonly _root: {
      readonly socketApi: SocketApiService;
    },
  ) {
    makeObservable(this);
  }

  get period() {
    return graphPeriod(this._from, this._scope);
  }

  get scope() {
    return this._scope;
  }

  get graph() {
    return this._graph;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  @action.bound onFromChange(from: Millisecond) {
    this._from = from;
  }

  @action.bound onScopeChange(scope: ChartViewScope) {
    this._scope = scope;
  }

  private _fetch = flow(function* (
    this: WorkerStatisticsPanelState,
    workerId: WorkerId,
    isRefresh?: boolean,
  ) {
    try {
      this._isLoading = true;
      if (isRefresh) {
        this._isRefreshing = true;
      }
      const {from, to} = this.period;
      const scope = this._scope;
      const result: GetMiningStatisticResponse =
        yield this._root.socketApi.getMiningStatistic({
          interval: scope === ChartViewScope.Day ? 'hourly' : 'daily',
          start_time: Math.floor(from / 1000),
          end_time: Math.floor(to / 1000),
          worker_ids: [workerId],
        });
      this._graph = miningStatisticGraph(
        result,
        scope === ChartViewScope.Day ? HOUR : DAY,
      );
    } catch (error) {
    } finally {
      this._isLoading = false;
      this._isRefreshing = false;
    }
  });

  private _flow?: CancellablePromise<void>;

  /**
   * @throws {never}
   */
  async fetch(...args: Parameters<WorkerStatisticsPanelState['_fetch']>) {
    this._flow?.cancel();
    try {
      this._flow = this._fetch(...args);
      await this._flow;
    } catch (ignore) {}
  }
}
