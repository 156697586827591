import React from 'react';
import {observer} from 'mobx-react-lite';
import {createStylesHook, useTheme} from '../../../styling';
import {ViewProps} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import useWorkerStatuses from '../../../DashboardScreen/hooks/useWorkerStatuses';
import {expr} from 'mobx-utils';
import {LinearGradient} from 'expo-linear-gradient';

export type WorkerStatusBackgroundProps = ViewProps & {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function WorkerStatusBackground({
  style,
  children,
  worker,
  ...rest
}: WorkerStatusBackgroundProps) {
  const {disabled, needActivate, expired} = useWorkerStatuses(worker);
  const styles = useStyles();
  const theme = useTheme();
  const backgroundColor = expr(() => {
    if (expired) {
      return theme.colors.gradientWorkerBodyExpired;
    } else if (needActivate) {
      return theme.colors.gradientWorkerBodyInactive;
    } else if (disabled) {
      return [theme.colors.uiAdditional1, theme.colors.uiAdditional1];
    } else {
      return [theme.colors.uiMain, theme.colors.uiMain];
    }
  });

  return (
    <LinearGradient
      colors={backgroundColor}
      start={{x: 0.5, y: 0}}
      end={{x: 0.5, y: 1}}
      style={[styles.background, style]}
      {...rest}>
      {children}
    </LinearGradient>
  );
});

const useStyles = createStylesHook((theme) => ({
  background: {
    flex: 1,
    backgroundColor: theme.colors.uiMain,
  },
}));
