import React from 'react';
import {observer} from 'mobx-react-lite';
import {sized} from '../../Svg';
import {StyleSheet, View} from 'react-native';
import DashedCircleSvg from '../../assets/svg/colorless/dashedCircle.svg';
import PlusSvg from '../../assets/svg/colorless/plus.svg';
import PressableOpacity, {PressableOpacityProps} from '../PressableOpacity';
import {useTheme, variance} from '../../styling';

export default observer(function PlusButton(props: PressableOpacityProps) {
  const theme = useTheme();
  return (
    <RootButton {...props}>
      <DashedCircleIcon color={theme.colors.uiAdditional3} />
      <View style={styles.plus}>
        <PlusIcon color={theme.colors.uiSecondary} />
      </View>
    </RootButton>
  );
});

const RootButton = variance(PressableOpacity)(() => ({
  root: {
    width: 22,
    height: 22,
    borderRadius: 11,
  },
}));

const styles = StyleSheet.create({
  plus: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const PlusIcon = sized(PlusSvg, 10);
const DashedCircleIcon = sized(DashedCircleSvg, 22);
