import React from 'react';
import {observer} from 'mobx-react-lite';
import {SvgProps} from 'react-native-svg';
import {useTheme} from '../../styling';
import {useRoot} from '../../Root/hooks';
import {StyleProp, ViewStyle} from 'react-native';

export type TwoToneIconProps = {
  IconSvg: React.ComponentType<SvgProps>;
  reversed?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default observer(function TwoToneIcon({
  IconSvg,
  reversed = false,
  style,
}: TwoToneIconProps) {
  const {colors} = useTheme();
  const {
    appearance: {isDark},
  } = useRoot();
  const color = isDark && !reversed ? colors.uiSecondary : colors.primaryBlack;
  return <IconSvg color={color} style={style} />;
});
