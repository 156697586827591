import {GestureResponderEvent, View, ViewProps} from 'react-native';
import {ChartViewScope} from './ChartView';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useRoot} from '../Root/hooks';
import {variance} from '../styling';
import {Paragraph, PressableOpacity} from '../components';

export type ButtonGroupViewProps = ViewProps & {
  onDayPress?: (event: GestureResponderEvent) => void;
  onMonthPress?: (event: GestureResponderEvent) => void;
  onWeakPress?: (event: GestureResponderEvent) => void;
  scope: ChartViewScope;
};

export default observer(
  ({
    onDayPress,
    onMonthPress,
    onWeakPress,
    scope,
    style,
    ...rest
  }: ButtonGroupViewProps) => {
    const {strings} = useRoot().translation;
    return (
      <Container style={style} {...rest}>
        <PressableOpacity onPress={onDayPress}>
          <Item active={scope === ChartViewScope.Day}>
            <ItemText
              active={scope === ChartViewScope.Day}
              type="paragraph"
              size="large"
              weight="500">
              {strings['common.day']}
            </ItemText>
          </Item>
        </PressableOpacity>
        <PressableOpacity onPress={onWeakPress}>
          <Item last active={scope === ChartViewScope.Week}>
            <ItemText
              active={scope === ChartViewScope.Week}
              type="paragraph"
              size="large"
              weight="500">
              {strings['common.weak']}
            </ItemText>
          </Item>
        </PressableOpacity>
        <PressableOpacity onPress={onMonthPress}>
          <Item last active={scope === ChartViewScope.Month}>
            <ItemText
              active={scope === ChartViewScope.Month}
              type="paragraph"
              size="large"
              weight="500">
              {strings['common.month']}
            </ItemText>
          </Item>
        </PressableOpacity>
      </Container>
    );
  },
);

const Container = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 15,
    backgroundColor: theme.colors.backgroundGround,
    borderRadius: 4,
    padding: 4,
    alignSelf: 'center',
  },
}));

const ItemText = variance(Paragraph)((theme) => ({
  root: {},
  active: {
    color: theme.colors.primaryBlack,
  },
}));

const Item = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 14,
    paddingVertical: 5,
    borderRadius: 4,
    marginRight: 12,
  },
  active: {
    borderRadius: 4,
    backgroundColor: theme.colors.primaryAttractive,
  },
  last: {
    marginRight: 0,
    borderRadius: 4,
  },
}));
