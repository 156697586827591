import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {sized} from '../Svg';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import PlusComputerSvg from '../assets/svg/twoTone/plusComputer.svg';
import ContentView, {ContentViewProps} from './ContentView';

export type QrCodeLinkWorkerResultModalProps = ContentViewProps & {
  onModalClosed: () => void;
  getIsDisabledLinkButtonBox: () => boolean;
};

export default observer<QrCodeLinkWorkerResultModalProps, AdaptiveModalRef>(
  forwardRef(function QrCodeLinkWorkerResultModal(
    {onModalClosed, ...rest},
    ref,
  ) {
    return (
      <AdaptiveModal
        ref={ref}
        onModalClosed={onModalClosed}
        adaptiveBreakpoint="sm"
        modalWidth={ModalWidth.Small}
        enableContentAutoHeight>
        <ContentView {...rest} />
      </AdaptiveModal>
    );
  }),
);

export const ComputerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(PlusComputerSvg, 20, 17)} {...props} />
));

export const ComputerLargeIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(PlusComputerSvg, 25, 22)} {...props} />
));
