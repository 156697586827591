import {LinkingOptions, ParamListBase} from '@react-navigation/native';
import {LocationSource} from '../Location';
import {Url} from '../units';
import {LinkingOptionsProvider} from './LinkingOptionsProvider';
import {SpecialLocation, UNKNOWN} from '../SpecialLocation';
import {when} from 'mobx';
import {NavigationContainer} from '../NavigationContainer';
import {PREFIXES} from './constant';
import {RootParamList} from '../Navigation';

export default class MobileLinkingOptionsProviderImpl
  implements LinkingOptionsProvider
{
  constructor(
    private readonly _root: {
      readonly navigationContainer: NavigationContainer;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
    },
  ) {}

  static readonly _CONFIG: LinkingOptions<RootParamList>['config'] = {
    initialRouteName: 'Root',
    screens: {
      Root: {
        initialRouteName: 'Dashboard',
        screens: {
          Dashboard: 'dashboard',
          Statistics: {
            path: 'statistics/:groupId?',
            parse: {
              groupId: (id) => {
                const _id = parseInt(id, 10);
                return isFinite(_id) ? _id : undefined;
              },
              activeTab: (_) => parseInt(_, 10),
            },
            stringify: {
              groupId: JSON.stringify,
              activeTab: JSON.stringify,
            },
          },
          Notifications: 'notifications',
          Withdraw: 'withdraw',

          Debug: 'debug',
        },
      },
      WorkerDetails: 'worker/:workerId',
      RenewAccess: 'renew/:workerId',
      WorkerStatistics: 'dashboard/statistics/:workerId',
      DashboardSettings: 'dashboard/settings',
      QRCodeScanner: 'qrCodeScanner',
      AccessRecovery: 'accessRecovery',
      NotificationsSettings: 'notificationsSettings',
      Auth: {
        path: 'auth',
        parse: {
          onSuccess: JSON.parse,
          farmId: (_) => parseInt(_, 10),
        },
        stringify: {
          onSuccess: JSON.stringify,
          farmId: (_: number) => _.toString(10),
        },
      },
      CheckAuth: 'checkauth',
      AccountSwitch: 'accountSwitch',
      PaymentSuccess: {
        path: 'paymentSuccess',
        parse: {amount: (id) => parseInt(id, 10)},
        stringify: {amount: (id: number) => id.toString(10)},
      },
      Schedule: {
        path: 'schedule',
        parse: {
          workerIds: JSON.parse,
          schedulerOwnerName: JSON.parse,
        },
        stringify: {
          workerIds: JSON.stringify,
          schedulerOwnerName: JSON.stringify,
        },
      },
      Plan: {
        path: 'subscriptions',
        parse: {
          onSuccess: JSON.parse,
          index: JSON.parse,
        },
        stringify: {
          onSuccess: JSON.stringify,
          index: JSON.stringify,
        },
      },
      Products: 'products',
      QuickStart: 'quickstart',
      FarmAction: 'farmAction',
      Menu: {
        path: 'menu',
        parse: {goToAffiliateToken: JSON.parse},
        stringify: {goToAffiliateToken: JSON.stringify},
      },
      PickLanguage: 'pickLanguage',
      Calendar: 'calendar',
      PickPurchaseConfiguration: 'pickPurchaseConfiguration',
      PickSubManagementConfiguration: 'pickSubManagementConfiguration',
      LogoutConfirm: 'logoutConfirm',
      Tutorial: 'tutorial',
      WithdrawalHistory: {
        path: 'withdrawalHistory',
        parse: {goToAffiliateToken: JSON.parse},
        stringify: {goToAffiliateToken: JSON.stringify},
      },
      DeleteWorkerGroupConfirm: {
        path: 'deleteWorkerGroupConfirm',
        parse: {
          groupId: (_) => parseInt(_, 10),
        },
        stringify: {
          groupId: (_: number) => _.toString(10),
        },
      },
      DeleteWorkerConfirm: {
        path: 'deleteWorkerConfirm',
        parse: {
          workerId: (_) => _,
        },
        stringify: {
          workerId: (_: string) => _,
        },
      },
      WorkerAction: 'workerAction',
      RenameWorker: 'renameWorker',
      DeleteAccountConfirm: 'deleteAccountConfirm',
      RenameWorkerGroup: 'renameWorkerGroup',
      AddNewWorkerGroup: 'addNewWorkerGroup',
      WorkerGroupAction: {
        path: 'workerGroupAction',
        parse: {
          groupId: (_) => parseInt(_, 10),
        },
        stringify: {
          groupId: (_: number) => _.toString(10),
        },
      },
      MoveWorkerToGroup: {
        path: 'moveWorkerToGroup',
        parse: {
          groupId: (_) => parseInt(_, 10),
        },
        stringify: {
          groupId: (_: number) => _.toString(10),
        },
      },
      PickDashboardSort: 'pickDashboardSort',
      ThanksForPurchase: 'thanksForPurchase',
      AddWorker: 'addWorker',
      IntroVideo: 'introVideo',
      LinkWorker: 'linkWorker',
      LinkDemoWorker: 'linkDemoWorker',
      CopyInstallId: 'copyInstallId',
      Rate: 'rate',
      PoolPuzzleConfirm: 'poolPuzzleConfirm',
      LinkWorkerConfirm: 'linkWorkerConfirm',
      LeaveScheduleUnsaved: 'leaveScheduleUnsaved',
      PoolMinerSchedulerWarning: 'poolMinerSchedulerWarning',
      CopyDiscountLink: 'copyDiscountLink',
      CreateNewFarmWarning: 'createNewFarmWarning',
      PickScheduleSpeed: {
        path: 'pickScheduleSpeed',
        parse: {
          nextParams: JSON.parse,
        },
        stringify: {
          nextParams: JSON.stringify,
        },
      },
      PickScheduleTimezone: {
        path: 'pickScheduleTimezone',
        parse: {
          nextParams: JSON.parse,
        },
        stringify: {
          nextParams: JSON.stringify,
        },
      },
      PickScheduleWorkers: {
        path: 'pickScheduleWorkers',
        parse: {
          nextParams: JSON.parse,
        },
        stringify: {
          nextParams: JSON.stringify,
        },
      },
      Payment: 'payment/:id',
      PaymentCompleted: 'paymentCompleted',
      DisconnectWallet: 'disconnectWallet',
      AdvertSplash: 'advertSplash',
      PurchasePaid: 'purchasePaid',
      PaymentConfirmPending: 'paymentConfirmPending',
      ConnectWallet: 'connectWallet',
      OAuthComplete: {
        path: 'oauth/complete#:anchor?',
        exact: false,
      },
      NotFound: 'Home',
    },
  };

  private _filterSupported(address: Url): Url | null {
    if (this._root.specialLocation.parse(address).kind === UNKNOWN) {
      return address;
    }
    return null;
  }

  async getInitial() {
    const url = await this._root.locationSource.getInitial();
    if (!url.success) {
      return null;
    }
    return this._filterSupported(url.right);
  }

  listen(listener: (url: string) => void) {
    this._root.locationSource.updates.listen(async (_address) => {
      await when(() => this._root.navigationContainer.isConfigured);
      const address = this._filterSupported(_address);
      if (address !== null) {
        listener(address);
      }
    });
  }

  readonly linkingOptions: LinkingOptions<ParamListBase> = {
    prefixes: PREFIXES,
    config: MobileLinkingOptionsProviderImpl._CONFIG,
    getInitialURL: () => this.getInitial(),
    subscribe: (listener) => this.listen(listener),
  };
}
