import React from 'react';
import IconSvg from '../../assets/svg/colored/giftBox.svg';
import {sized} from '../../Svg';
import {useTheme} from '../../styling';
import {View, StyleSheet} from 'react-native';
import {Paragraph} from '../../components';

export type BadgeProps = {
  text: string;
  type: 'blue' | 'green';
};

export default function Badge({text, type}: BadgeProps) {
  const theme = useTheme();
  const getTypeStyle = (type: string) => {
    switch (type) {
      case 'blue':
        return {
          backgroundColor: theme.colors.primaryActive,
        };
      case 'green':
        return {backgroundColor: theme.colors.primarySuccess};
      default:
        return {};
    }
  };
  const styles = StyleSheet.create({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 12,
      borderWidth: 1,
      paddingRight: 12,
      overflow: 'hidden',
      borderColor:
        type == 'blue'
          ? theme.colors.primaryActive
          : theme.colors.primarySuccess,
    },

    iconWrapper: {
      marginRight: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      width: 44,
      ...getTypeStyle(type),
    },
    text: {
      fontSize: 16,
      flexShrink: 1,
      flexWrap: 'wrap',
    },
  });

  return (
    <View style={[styles.root]}>
      <View style={[styles.iconWrapper, getTypeStyle(type)]}>
        <Icon />
      </View>
      <Paragraph weight={'600'} style={styles.text} type={'paragraph'}>
        {text}
      </Paragraph>
    </View>
  );
}

const Icon = sized(IconSvg, 20, 20);
