import React from 'react';
import {observer} from 'mobx-react-lite';
import Copyable from '../components/Copyable';
import {LogContent} from '../Log';
import {View, Text, StyleSheet} from 'react-native';
import {Millisecond} from '../Time';
import dayjs from 'dayjs';
import {useBoolean} from '../hooks';
import {variance} from '../styling';
import {getCommissionerFontStyles} from '../components';

export interface LogItemProps {
  content: LogContent;
  capturedAt: Millisecond;
}

export default observer((props: LogItemProps) => {
  const {content, capturedAt} = props;
  const [visible, show, hide] = useBoolean(false);
  const numberOfLines = visible ? undefined : 10;
  return (
    <View style={styles.root}>
      <Date>{dayjs(capturedAt).format()}</Date>
      <Copyable
        onLongPress={visible ? hide : show}
        numberOfLines={numberOfLines}>
        {content.body}
      </Copyable>
    </View>
  );
});

const Date = variance(Text)((theme) => ({
  root: {
    ...getCommissionerFontStyles('400'),
    fontSize: 13,
    color: theme.colors.uiSecondary,
  },
}));

const styles = StyleSheet.create({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 5,
  },
});
