import React, {useCallback} from 'react';
import {AppForceParamList} from '../AppForceParamList';
import {
  StackNavigationOptions,
  StackNavigationProp,
} from '@react-navigation/stack';
import {AppRouteName} from '../AppRouteName';
import {AppHeader} from '../../components/organisms';
import useSafelyGoBack from './useSafelyGoBack';

export type GetScreenOptionsHookParams = {
  routeName: AppRouteName;
  navigation: StackNavigationProp<AppForceParamList>;
  goBackPossible: boolean;
};

export default function useGetMobileScreenOptions() {
  const safelyGoBack = useSafelyGoBack();
  return useCallback(
    ({
      routeName,
      navigation,
      goBackPossible,
    }: GetScreenOptionsHookParams): StackNavigationOptions => {
      return {
        header: () => (
          <AppHeader
            navigation={navigation}
            onGoBackPress={safelyGoBack}
            activeRouteName={routeName}
            goBackPossible={goBackPossible}
          />
        ),
      };
    },
    [safelyGoBack],
  );
}
