import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';
import {Animated, StyleSheet, View, ViewProps} from 'react-native';
import {useStrings} from '../Root/hooks';
import {useStyles, variance} from '../styling';
import {MeasureResult, useMeasure} from '../ReactNativeUtil';
import {useTopOffset} from './useTopOffset';
import {Typography} from '../components';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {StackElementKey} from '../InteractiveTutorial';
import Svg, {Path} from 'react-native-svg';
import isBottomTabKey from './isBottomTabKey';
import {ARROW_HEIGHT, ARROW_WIDTH} from './constants';
import useGetIsLarge from '../DashboardScreen/hooks/useGetIsLarge';

export type CardProps = {
  accentElement:
    | {measure: MeasureResult | undefined; key: StackElementKey}
    | undefined;
  onNext: () => void;
  onPrev: () => void;
  onSkip: () => void;
  onFinish: () => void;
  count: number;
  activeIndex: number;
  activeStepKey: StackElementKey;
  onTranslateStart: () => void;
  onTranslateEnd: () => void;
  getTranslating: () => boolean;
};
export default observer(function Card({
  accentElement,
  onNext,
  onPrev,
  onSkip,
  onFinish,
  count,
  activeIndex,
  activeStepKey,
  onTranslateStart,
  onTranslateEnd,
  getTranslating,
}: CardProps) {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const translateAnim = useRef(new Animated.Value(0)).current;
  const strings = useStrings();
  const cardRef = useRef<View>(null);

  const themedStyles = useStyles((theme) => ({
    accentText: {
      color: theme.colors.primaryAttractive,
    },
    cardText: {
      fontSize: 16,
      lineHeight: 20,
      color: theme.colors.uiSecondary,
    },
  }));

  const [getResult, onLayout] = useMeasure(cardRef);

  const {offset, isTopAlignArrow} = useTopOffset(
    accentElement?.measure,
    activeStepKey,
    getResult()?.height ?? 0,
  );

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [fadeAnim]);

  useEffect(() => {
    onTranslateStart();
    Animated.timing(translateAnim, {
      toValue: offset,
      duration: 500,
      useNativeDriver: false,
    }).start((result) => {
      if (result.finished) {
        onTranslateEnd();
      }
    });
  }, [onTranslateEnd, onTranslateStart, offset, translateAnim]);

  const animatedStyle = {
    transform: [
      {
        translateY: translateAnim,
      },
    ],
    opacity: fadeAnim,
  };

  const shownFinish = activeIndex + 1 === count;
  const shownSkip = activeIndex === 0;

  const cardText = useCardText(activeStepKey);

  return (
    <RootView>
      {accentElement?.measure && (
        <>
          <BottomTabArrow
            activeElement={{
              measure: accentElement.measure,
              key: accentElement.key,
            }}
            activeStepKey={activeStepKey}
            isTopAlignArrow={isTopAlignArrow}
          />
          {!getTranslating() && (
            <ContentArrow
              activeElement={{
                measure: accentElement.measure,
                key: accentElement.key,
              }}
              activeStepKey={activeStepKey}
              isTopAlignArrow={isTopAlignArrow}
            />
          )}
        </>
      )}
      <CardContainerView style={animatedStyle}>
        <CardRootView ref={cardRef} onLayout={onLayout}>
          <CardContentView>
            <Typography
              style={themedStyles.cardText}
              type="paragraph"
              weight="500">
              {cardText}
            </Typography>
          </CardContentView>
          <CardFooterView>
            <FooterLeftView>
              <StepText type="body">
                <StepText type="body" weight="500">
                  {activeIndex + 1}
                </StepText>{' '}
                / {count}
              </StepText>
            </FooterLeftView>
            <FooterRightView>
              <FooterButtonGroupView>
                {shownSkip ? (
                  <AppButton
                    textStyle={styles.buttonText}
                    variant={ButtonVariant.Text}
                    onPress={onSkip}>
                    {strings['interactiveTutorial.skip']}
                  </AppButton>
                ) : (
                  <AppButton
                    textStyle={styles.buttonText}
                    variant={ButtonVariant.Text}
                    onPress={onPrev}>
                    {strings['interactiveTutorial.previous']}
                  </AppButton>
                )}

                {shownFinish ? (
                  <AppButton
                    textStyle={[styles.buttonText, themedStyles.accentText]}
                    variant={ButtonVariant.Text}
                    onPress={onFinish}>
                    {strings['interactiveTutorial.finish']}
                  </AppButton>
                ) : (
                  <AppButton
                    textStyle={[styles.buttonText, themedStyles.accentText]}
                    variant={ButtonVariant.Text}
                    onPress={onNext}>
                    {strings['interactiveTutorial.next']}
                  </AppButton>
                )}
              </FooterButtonGroupView>
            </FooterRightView>
          </CardFooterView>
        </CardRootView>
      </CardContainerView>
    </RootView>
  );
});

const styles = StyleSheet.create({
  buttonText: {
    textTransform: 'uppercase',
  },
});

type ArrowProps = {
  activeElement: {measure: MeasureResult; key: StackElementKey} | undefined;
  activeStepKey: StackElementKey;
  isTopAlignArrow: boolean;
};

const ContentArrow = observer(
  ({activeElement, activeStepKey, isTopAlignArrow}: ArrowProps) => {
    const getIsLarge = useGetIsLarge();
    const isLarge = getIsLarge();
    if (isLarge) {
      return null;
    }
    if (!activeElement || activeElement.key !== activeStepKey) {
      return null;
    }
    if (isBottomTabKey(activeStepKey)) {
      return null;
    }
    const measure = activeElement.measure;
    const translateY = isTopAlignArrow
      ? measure.pageY - ARROW_HEIGHT - 10
      : measure.pageY + measure.height + 10;
    const Arrow = isTopAlignArrow ? ArrowBottomSvg : ArrowTopSvg;
    return (
      <View
        style={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
          },
          {
            transform: [
              {
                translateX:
                  -ARROW_WIDTH / 2 + measure.pageX + measure.width / 2,
              },
            ],
          },
        ]}>
        <Arrow
          style={{
            transform: [
              {
                translateY: translateY,
              },
            ],
          }}
        />
      </View>
    );
  },
);

const BottomTabArrow = observer(
  ({activeElement, activeStepKey}: ArrowProps) => {
    const getIsLarge = useGetIsLarge();
    const isLarge = getIsLarge();
    if (isLarge) {
      return null;
    }
    if (!activeElement || activeElement.key !== activeStepKey) {
      return null;
    }
    if (!isBottomTabKey(activeStepKey)) {
      return null;
    }
    const measure = activeElement.measure;
    return (
      <View
        style={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
          },
          {
            transform: [
              {
                translateX:
                  -ARROW_WIDTH / 2 + measure.pageX + measure.width / 2,
              },
            ],
          },
        ]}>
        <ArrowBottomSvg
          style={{
            transform: [
              {
                translateY: measure.pageY - 70,
              },
            ],
          }}
        />
      </View>
    );
  },
);

const ArrowBottomSvg = observer(({style}: ViewProps) => {
  return (
    <View style={style}>
      <Svg width={ARROW_WIDTH} height={ARROW_HEIGHT} fill="none">
        <Path
          d="m2 46 7 8 7-8M9 2v52"
          stroke="#F7931A"
          strokeWidth={3}
          strokeLinecap="round"
        />
      </Svg>
    </View>
  );
});

const ArrowTopSvg = observer(({style}: ViewProps) => {
  return (
    <View style={style}>
      <Svg
        width={ARROW_WIDTH}
        height={ARROW_HEIGHT}
        viewBox="0 0 18 57"
        fill="none">
        <Path
          d="M2 11L9 3L16 11"
          stroke="#F7931A"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <Path
          d="M9 55V3"
          stroke="#F7931A"
          strokeWidth={3}
          strokeLinecap="round"
        />
      </Svg>
    </View>
  );
});

function useCardText(key: StackElementKey) {
  const strings = useStrings();
  switch (key) {
    case 'DASHBOARD_BALANCE':
      return strings['interactiveTutorial.balance_2'];
    case 'TAB_BAR_ADD_WORKER':
      return strings['interactiveTutorial.addWorker'];
    case 'TAB_BAR_NOTIFICATIONS':
      return strings['interactiveTutorial.notifications'];
    case 'TAB_BAR_STATISTICS':
      return strings['interactiveTutorial.statistics'];
    default:
      console.warn(`No translations found for ${key} element`);
      return '-';
  }
}

const RootView = variance(View)(() => ({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
}));

const CardContainerView = variance(Animated.View)(() => ({
  root: {
    paddingHorizontal: 15,
    maxWidth: 500,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const CardRootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
  },
}));

const CardContentView = variance(View)(() => ({
  root: {
    padding: 20,
    paddingBottom: 5,
  },
}));

const CardFooterView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 5,
    paddingBottom: 5,
    paddingTop: 0,
  },
}));

const FooterLeftView = variance(View)(() => ({
  root: {},
}));
const FooterRightView = variance(View)(() => ({
  root: {},
}));
const FooterButtonGroupView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const StepText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
  },
}));
