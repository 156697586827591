import React from 'react';
import {observer} from 'mobx-react-lite';
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native';
import {sized} from '../../../Svg';
import {CryptotabFarmLogo} from '../../CryptotabFarmLogo';
import {FanSquareLogo} from '../../FanSquareLogo';

export type AppButtonProps = TouchableOpacityProps & {
  isExpanded: boolean;
};

export default observer(function AppButton({
  isExpanded,
  ...rest
}: AppButtonProps) {
  return (
    <TouchableOpacity style={styles.mainLogoView} activeOpacity={0.9} {...rest}>
      {isExpanded ? <MainExpandedLogo /> : <SmallLogo />}
    </TouchableOpacity>
  );
});

const MainExpandedLogo = sized(CryptotabFarmLogo, 192, 44);
const SmallLogo = sized(FanSquareLogo, 44);

const styles = StyleSheet.create({
  mainLogoView: {
    paddingVertical: 18,
    paddingHorizontal: 7,
    alignItems: 'center',
    marginBottom: 20,
  },
});
