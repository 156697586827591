import React, {useMemo} from 'react';
import {
  View,
  ActivityIndicator,
  ActivityIndicatorProps,
  ViewStyle,
} from 'react-native';

import {useTheme} from '../../styling';

interface MoreDataActivityIndicator extends ActivityIndicatorProps {
  containerStyle?: ViewStyle;
}

export default ({containerStyle, ...rest}: MoreDataActivityIndicator) => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      ({
        root: {
          height: 60,
        },
      } as const),
    [],
  );

  return (
    <View style={[styles.root, containerStyle]}>
      <ActivityIndicator
        size="large"
        color={theme.colors.primaryActive}
        {...rest}
      />
    </View>
  );
};
