import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect} from 'react';
import {SubscriptionManagementScreen} from '../../SubscriptionManagementScreen';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {MinerOffer} from '../../InAppOffersService';
import {useGoToPoolMinerActivatedCallback} from '../hooks';
import {useUpdatePoolMinerSubscription} from '../../PurchaseRequester';
import {PAYMENT_METHOD} from '../../SubscriptionManagementScreen/SubscriptionManagementScreen';
import {OrderId} from '../../ApiStore/units/order';
import {expr} from 'mobx-utils';

export type SubscriptionManagementBindingProps =
  RootStackBindingProps<'SubscriptionManagement'>;

export default observer(function SubscriptionManagementBinding({
  navigation,
  route,
}: SubscriptionManagementBindingProps) {
  const {
    auth,
    subscriptionManagementScreenState,
    currentSubscriptionOfferService,
    inAppLoaderState,
  } = useRoot();

  const configuredOfferSku = route.params?.sku;
  if (!configuredOfferSku) {
    throw new Error('Sku is required to manage sub');
  }
  useEffect(() => {
    subscriptionManagementScreenState.configure(configuredOfferSku);
  }, [configuredOfferSku, subscriptionManagementScreenState]);
  const {currentSubscriptionOffer} = currentSubscriptionOfferService;
  const {
    selectedProposal,
    selectHashrate,
    selectedMinerConfig,
    proposals,
    minerConfigByHashrate,
  } = subscriptionManagementScreenState;

  const goToConfiguration = useCallback(
    () => navigation.navigate('PickSubManagementConfiguration'),
    [navigation],
  );
  const goToDashboard = useCallback(
    () => navigation.navigate('Root', {screen: 'Dashboard'}),
    [navigation],
  );
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) => {
    goToDashboard();
    navigation.navigate('PoolMinerActivated', params);
  });
  const updatePoolSubscription = useUpdatePoolMinerSubscription();
  const goToCryptoPayment = useCallback(
    (id: OrderId) => navigation.navigate('Payment', {id}),
    [navigation],
  );
  const purchase = useCallback(
    async (offer: MinerOffer) => {
      if (auth.accountId === undefined) {
        return;
      }
      if (subscriptionManagementScreenState.configuredOffer === undefined) {
        return;
      }

      inAppLoaderState.addLoading();
      await updatePoolSubscription({
        offer,
        goToPoolMinerActivated,
        payment: PAYMENT_METHOD,
        goToCryptoPayment,
        updatablePurchaseId:
          subscriptionManagementScreenState.configuredOffer.purchaseId,
      });
      inAppLoaderState.deleteLoading();
    },
    [
      auth,
      subscriptionManagementScreenState,
      inAppLoaderState,
      updatePoolSubscription,
      goToPoolMinerActivated,
      goToCryptoPayment,
    ],
  );
  const onUpdatePress = useCallback(async () => {
    if (auth.accountId === undefined || !selectedProposal?.available) {
      return;
    }
    await purchase(selectedProposal?.offer);
  }, [auth, selectedProposal, purchase]);
  const minerConfigs = expr(() =>
    minerConfigByHashrate ? [...minerConfigByHashrate.values()] : [],
  );
  if (!proposals || !selectedProposal) {
    return null;
  }

  return (
    <SubscriptionManagementScreen
      onUpdatePress={onUpdatePress}
      onChangeSlider={selectHashrate}
      selectedProposal={selectedProposal}
      goToConfiguration={goToConfiguration}
      currentSubscriptionOffer={currentSubscriptionOffer}
      minerConfigs={minerConfigs}
      selectedConfig={selectedMinerConfig}
    />
  );
});
