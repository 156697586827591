import {AuthResult, Farm, FarmId, PlatformName, SlotCount} from '../ApiStore';

export type Subscription = PlatformDictionary & {
  accountId: FarmId;
  poolMiners: SlotCount;
  maxSlots: SlotCount; // real_miners + subscriptions
  slots: SlotCount; // real_miners + pool_miners
  mode: ApiMode;
  hasPurchases: boolean;
};

export enum ApiMode {
  Demo,
  Real,
}

export type PlatformDictionary = Record<PlatformName, boolean>;

export type SubscriptionMap = Map<FarmId, Subscription>;

const getFarms = (result: AuthResult): Farm[] =>
  result.type === 'farm' ? [result] : result.farms;

export const translateAccount = (result: AuthResult): SubscriptionMap =>
  new Map(getFarms(result).map((_) => [_.id, translateFarm(_)]));

export const translateFarm = (_: Farm): Subscription => ({
  accountId: _.id,
  hasPurchases: _.info.has_purchases,
  poolMiners: _.info.pool_miners,
  slots: _.info.slots,
  maxSlots: _.info.max_slots,
  mode:
    _.info.has_purchases || _.info.max_slots > 0 || _.info.slots > 0
      ? ApiMode.Real
      : ApiMode.Demo,
  ...translatePlatforms(_.info.platforms),
});

export const translatePlatforms = (
  platforms: PlatformName[],
): PlatformDictionary => ({
  android: false,
  ios: false,
  web: false,
  ...Object.fromEntries(platforms.map((_) => [_, true])),
});
