import React, {PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {Pressable, View} from 'react-native';
import {Typography} from './index';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {useRoot, useStrings} from '../Root/hooks';
import {useOpenLink} from '../Links';

export default observer(function AppFooter() {
  const strings = useStrings();
  const {configuration} = useRoot();
  const date = new Date();

  return (
    <FooterContainer>
      <FooterInner>
        <FooterColumn>
          <FooterColumnTitle type="header">
            {strings['footer.title.general']}
          </FooterColumnTitle>
          <FooterLink url={configuration.values.overview}>
            {strings['footer.overviewFarm']}
          </FooterLink>
          <FooterLink url={configuration.values.pcUrl}>
            {strings['footer.overviewMiner']}
          </FooterLink>
          <FooterLink url={configuration.values.cryptotab}>
            {strings['footer.cryptoTab']}
          </FooterLink>
          <FooterLink url={configuration.values.affiliate}>
            {strings['footer.affiliateProgram']}
          </FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterColumnTitle type="header">
            {strings['footer.title.additional']}
          </FooterColumnTitle>
          <FooterLink url={configuration.values.terms}>
            {strings['footer.termsOfUse']}
          </FooterLink>
          <FooterLink url={configuration.values.termsAffiliate}>
            {strings['footer.affiliateTermsOfUse']}
          </FooterLink>
          <FooterLink url={configuration.values.privacy}>
            {strings['footer.privacyPolicy']}
          </FooterLink>
          <FooterLink url={configuration.values.cookie}>
            {strings['footer.cookiePolicy']}
          </FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterColumnTitle type="header">
            {strings['footer.title.ourProducts']}
          </FooterColumnTitle>
          <FooterLink url={configuration.values.playStoreWebUrl}>
            {strings['footer.ctFarmForAndroid']}
          </FooterLink>
          <FooterLink url={configuration.values.appStoreUrl}>
            {strings['footer.ctFarmForIOS']}
          </FooterLink>
          <FooterLink url={configuration.values.webAppUrl}>
            {strings['footer.ctFarmWebVersion']}
          </FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterColumnTitle type="header">
            {strings['footer.title.stayConnected']}
          </FooterColumnTitle>
          <FooterLink url={configuration.values.feedback} accent>
            {strings['footer.support']}
          </FooterLink>
          <Typography type="body">
            <>
              {strings['footer.other']}:{' '}
              <FooterLink url={`mailto:${configuration.values.mail}`} accent>
                {configuration.values.mail}
              </FooterLink>
            </>
          </Typography>
        </FooterColumn>
      </FooterInner>
      <FooterBottom>
        <FooterCopyrightText type="body">
          © {date.getFullYear()}. All rights reserved. CryptoTab OÜ, Rebase tn
          1, Tartu 50104, Estonia
        </FooterCopyrightText>
      </FooterBottom>
    </FooterContainer>
  );
});

const FooterLink = observer(
  ({
    url,
    accent,
    children,
  }: PropsWithChildren<{url: string; accent?: boolean}>) => {
    const handlePress = useOpenLink(url);

    return (
      <FooterLinkPressable onPress={handlePress}>
        <FooterLinkText type="body" accent={accent}>
          {children}
        </FooterLinkText>
      </FooterLinkPressable>
    );
  },
);

const FooterContainer = variance(View)((theme) => ({
  root: {
    minHeight: 100,
    paddingVertical: 30,
    paddingHorizontal: 16,
    borderTopColor: theme.colors.primaryAttractive,
    borderTopWidth: 5,
    backgroundColor: theme.colors.backgroundGround,
  },
}));

const FooterInner = variance(View)((theme) => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,

    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        width: '100%',
        maxWidth: 1100,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      940: {
        flexWrap: 'wrap',
      },
    }),
  },
}));

const FooterColumn = variance(View)((theme) => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 5,

    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        paddingVertical: 0,
        flex: 1,
      },
    }),
  },
}));

const FooterColumnTitle = variance(Typography)((theme) => ({
  root: {
    marginBottom: 5,
    color: theme.colors.uiAdditional4,
    fontSize: 16,
  },
}));

const FooterLinkPressable = variance(Pressable)(() => ({
  root: {
    paddingVertical: 6,
  },
}));

const FooterLinkText = variance(Typography)((theme) => ({
  root: {},
  accent: {
    color: theme.colors.primaryAttractive,
  },
}));

const FooterBottom = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,

    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        width: '100%',
        maxWidth: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const FooterCopyrightText = variance(Typography)(() => ({
  root: {
    fontSize: 12,
  },
}));
