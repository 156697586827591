import {AppTemplateMode, AppTemplateState} from './AppTemplateState';
import {WindowDimensionsState} from '../WindowDimensions';
import {LG_BREAKPOINT, XL_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {computed, observable, runInAction, makeObservable} from 'mobx';
import {bind} from '../fp';
import {
  ASIDE_BAR_EXPANDED_WIDTH,
  ASIDE_BAR_WIDTH,
} from '../components/organisms';

export default class AppTemplateStateImpl implements AppTemplateState {
  @observable private _expandedAsideBar = false;

  public static TABLET_BREAKPOINT = LG_BREAKPOINT;

  constructor(
    private readonly _root: {
      readonly windowDimensionsState: WindowDimensionsState;
    },
  ) {
    makeObservable(this);
  }

  @computed({keepAlive: true})
  get mode(): AppTemplateMode {
    const width = this._root.windowDimensionsState.window.width;
    const isMobile = width < AppTemplateStateImpl.TABLET_BREAKPOINT;
    return isMobile ? AppTemplateMode.Mobile : AppTemplateMode.Tablet;
  }

  @computed
  get isTablet() {
    return this.mode === AppTemplateMode.Tablet;
  }

  @computed
  get expandedAllowed() {
    const width = this._root.windowDimensionsState.window.width;
    if (LG_BREAKPOINT <= width && width < XL_BREAKPOINT) {
      return false;
    }
    return true;
  }

  @computed
  get expandedAsideBar() {
    if (!this.expandedAllowed) {
      return false;
    }

    return this._expandedAsideBar;
  }

  @computed
  get visibleAsideBar() {
    return this.mode === AppTemplateMode.Tablet;
  }

  toggleAsideBar = bind(() => {
    runInAction(() => (this._expandedAsideBar = !this._expandedAsideBar));
  }, this);

  @computed
  get content() {
    const width = this._root.windowDimensionsState.window.width;
    if (this.visibleAsideBar) {
      const asideWidth = this.expandedAsideBar
        ? ASIDE_BAR_EXPANDED_WIDTH
        : ASIDE_BAR_WIDTH;
      return width - asideWidth;
    }
    return width;
  }
}
