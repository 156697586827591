import React, {useCallback, useEffect, useState} from 'react';
import {Image, Platform, ScrollView, TextInput, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../Root/hooks';
import {LinearGradient} from 'expo-linear-gradient';
import {AppButton, ButtonVariant} from '../components/AppButton';
import WithdrawState from './WithdrawState';
import {useIsFocused} from '@react-navigation/native';
import {useTemplates} from '../Root/hooks';
import {Satoshi} from '../ApiStore';
import {createStylesHook, useTheme} from '../styling';
import {useHeaderHeight} from '../Navigation/hooks';
import TextLogoSvg from '../assets/svg/colored/textLogo.svg';
import PropellerSvg from '../assets/svg/colored/transparentPropeller.svg';
import {
  getCommissionerFontStyles,
  PressableOpacity,
  Typography,
} from '../components';
import {
  LG_BREAKPOINT,
  useGetDimensions,
} from '../WindowDimensions/useDimensions';
import RefreshControl from '../components/RefreshControl';
import Markdown from '../components/molecules/Markdown';
import {expr} from 'mobx-utils';
import toFixedTrunc from '../utils/toFixedTrunc';
import {AdInfoItem} from '../Advert';
import {AdvertBanner} from '../DashboardScreen/components/AdvertBanner';
import {BLUE_GRADIENT} from '../components/molecules/ColoredBanner';
import {KeyboardAvoidingView} from 'react-native-keyboard-controller';

export interface WithdrawScreenProps {
  goToPaymentSuccess: (amount: Satoshi) => void;
  getAdvertBanner(): AdInfoItem | undefined;
}

export default observer((props: WithdrawScreenProps) => {
  const {goToPaymentSuccess, getAdvertBanner} = props;
  const headerHeight = useHeaderHeight();
  const theme = useTheme();
  const styles = useStyles();
  const strings = useStrings();
  const templates = useTemplates();
  const root = useRoot();
  const [state] = useState(() => new WithdrawState(root));
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // noinspection JSIgnoredPromiseFromCall
      state.fetch();
    }
  }, [isFocused, state]);
  useEffect(() => {
    !state.amount.value && state.resetError();
  }, [state, state.amount.value]);

  const submit = useCallback(async () => {
    const amount = await state.onSubmit();
    if (amount !== undefined) {
      goToPaymentSuccess(amount);
    }
  }, [goToPaymentSuccess, state]);

  const handlePasteMaxValue = useCallback(() => {
    const handlers = state.amount.handlers;
    const onChangeText = handlers.onChangeText;
    const maxAmount = state.maxAmount;
    if (onChangeText && maxAmount) {
      onChangeText(toFixedTrunc(maxAmount, 8));
    }
  }, [state]);
  const getDimension = useGetDimensions();
  const isLg = expr(() => getDimension('lg'));
  const advertBanner = getAdvertBanner();

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={headerHeight}
      style={styles.root}
      behavior="padding">
      {isLg && (
        <Image
          source={require('../assets/fan_bg.png')}
          resizeMode={'cover'}
          style={styles.backgroundImage}
        />
      )}
      <ScrollView
        style={styles.scroll}
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps="handled"
        refreshControl={
          <RefreshControl
            refreshing={state.isRefreshing}
            onRefresh={state.refresh}
          />
        }>
        <View style={styles.contentContainer}>
          <View style={styles.panel}>
            <View>
              <LinearGradient
                style={styles.card}
                colors={[
                  theme.colors.gradientCard[0],
                  theme.colors.gradientCard[1],
                ]}
                start={{x: 1, y: 0.5}}
                end={{x: 0, y: 0.5}}>
                <PropellerSvg style={styles.propeller} />
                <TextLogoSvg />
                <View style={styles.balance}>
                  <Typography
                    type="mono"
                    style={styles.balanceNumber}
                    weight="500">
                    {state.maxAmount ? toFixedTrunc(state.maxAmount, 8) : '-'}
                  </Typography>
                  <Typography
                    type="mono"
                    style={styles.balanceBtc}
                    weight="500">
                    BTC
                  </Typography>
                </View>
                <Typography type="paragraph" weight="500" style={styles.approx}>
                  {strings['common.approxShort']} $
                  {root.dashboardStore.balance.approxUsd}
                </Typography>
                <Typography
                  type="mono"
                  style={styles.userId}
                  weight="500">{`ID: ${root.auth.accountId}`}</Typography>
              </LinearGradient>
              {state.errorText !== undefined && (
                <Typography
                  type="paragraph"
                  size="tiny"
                  style={[styles.helper, styles.error]}>
                  <Markdown
                    styles={{
                      link: styles.link,
                      paragraph: {...styles.paragraph, ...styles.error},
                    }}>
                    {strings[state.errorText]}
                  </Markdown>
                </Typography>
              )}
              <TextInput
                style={styles.textInput}
                placeholder={strings['withdraw.inputPlaceholder']}
                placeholderTextColor={theme.colors.primaryUIDirtyBlue}
                keyboardAppearance={theme.select('light', 'dark')}
                keyboardType="numeric"
                {...state.amount.handlers}
              />
              {state.maxAmount !== undefined ? (
                <PressableOpacity
                  style={styles.maxRow}
                  onPress={handlePasteMaxValue}>
                  <Typography
                    style={[
                      {color: theme.colors.primaryUIDirtyBlue},
                      styles.sendText,
                    ]}
                    type="body"
                    weight="500">
                    {strings['withdraw.sendAll']}
                  </Typography>
                  <Typography type="body" weight="500" style={styles.sendText}>
                    {strings['common.maxShort']}{' '}
                    {toFixedTrunc(state.maxAmount, 8)} BTC
                  </Typography>
                </PressableOpacity>
              ) : (
                <View style={styles.maxRow} />
              )}
            </View>
            <AppButton
              variant={ButtonVariant.Primary}
              onPress={submit}
              rootStyle={{borderRadius: 0}}>
              {strings['withdraw.sendButton']}
            </AppButton>
            <View
              style={{
                paddingHorizontal: 16,
                paddingTop: 32,
                paddingBottom: 20,
              }}>
              <Markdown
                styles={{
                  paragraph: styles.paragraph,
                  link: styles.link,
                }}>
                {templates['withdraw.description']({
                  email: root.auth.email || 'user@example.com',
                })}
              </Markdown>
            </View>
          </View>
          {advertBanner && (
            <View style={styles.bannerWrapper}>
              <AdvertBanner
                style={styles.banner}
                ad={advertBanner}
                background={BLUE_GRADIENT}
              />
            </View>
          )}
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
  card: {
    overflow: 'hidden',
    maxWidth: 320,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingHorizontal: 21,
    paddingVertical: 24,
    borderRadius: 12,
  },
  sendText: {
    fontSize: 14,
  },
  balance: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  balanceNumber: {
    fontSize: 23,
    lineHeight: 36,
    color: theme.colors.primaryWhite,
  },
  balanceBtc: {
    fontSize: 17,
    lineHeight: 24,
    color: theme.colors.primaryUIDirtyBlue,
    marginLeft: 5,
  },
  userId: {
    marginTop: 20,
    color: '#5D626D',
    fontSize: 12,
    lineHeight: 16,
  },
  propeller: {
    position: 'absolute',
    top: -47,
    right: -46,
  },
  maxRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 17,
    height: 60,
  },
  scroll: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  contentContainer: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 375,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
  panel: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.colors.uiMain,
        borderWidth: 1,
        borderColor: theme.colors.uiAdditional1,
        paddingTop: 32,
        borderRadius: 12,
      },
    }),
  },
  paragraph: {
    ...getCommissionerFontStyles('400'),
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.0628572,
    color: theme.colors.uiSecondary,
    marginTop: 0,
    marginBottom: 0,
  },
  link: {
    ...getCommissionerFontStyles('400'),
    color: theme.colors.primaryUIDirtyBlue,
    fontStyle: 'normal',
    textDecorationLine: 'none',
  },
  textInput: {
    ...getCommissionerFontStyles('500'),
    paddingStart: 0,
    paddingVertical: 15,
    textAlign: 'center',
    paddingEnd: 0,
    backgroundColor: theme.colors.uiAdditional1,
    marginTop: 18,
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.04,
    color: theme.colors.uiSecondary,
    ...Platform.select({
      web: {outline: 'none'},
      default: {},
    }),
  },
  helper: {
    marginTop: 12,
    color: theme.colors.uiSecondary,
    paddingHorizontal: 16,
    textAlign: 'center',
    justifyContent: 'center',
  },
  error: {
    color: theme.colors.primaryError,
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  approx: {
    color: theme.colors.primaryUIDirtyBlue,
  },
  bannerWrapper: {
    marginTop: 24,
    minHeight: 140,
  },
  banner: {
    flex: 1,
  },
}));
