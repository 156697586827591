import React, {useCallback} from 'react';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  NavigationHelpers,
  ParamListBase,
  StackActionHelpers,
  StackNavigationState,
  StackRouter,
  StackRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import {StackNavigationOptions, StackView} from '@react-navigation/stack';
import {StackNavigationEventMap} from '@react-navigation/stack/lib/typescript/src/types';
import {AppTemplate} from '../../components/templates';
import {
  // AppHeader,
  AsideBar,
} from '../../components/organisms';
import {getActiveRouteNameByNavigationState} from '../../ReactNavigationUtil';
import {AppRouteName} from '../AppRouteName';
import {AppForceParamList} from '../AppForceParamList';

export type RootStackNavigatorProps = DefaultNavigatorOptions<
  ParamListBase,
  StackNavigationState<ParamListBase>,
  StackNavigationOptions,
  StackNavigationEventMap
> &
  StackRouterOptions;

function HomeStackNavigator({
  initialRouteName,
  children,
  screenOptions,
  ...rest
}: RootStackNavigatorProps) {
  const {state, navigation, descriptors} = useNavigationBuilder<
    StackNavigationState<ParamListBase>,
    StackRouterOptions,
    StackActionHelpers<ParamListBase>,
    StackNavigationOptions,
    StackNavigationEventMap
  >(StackRouter, {
    children,
    screenOptions,
    initialRouteName,
  });
  const routeName: AppRouteName =
    getActiveRouteNameByNavigationState(state) ?? 'Dashboard';
  const activeRouteName = routeName === 'Root' ? 'Dashboard' : routeName;

  const renderAsideBar = useCallback(
    () => (
      <AsideBar
        navigation={navigation as NavigationHelpers<AppForceParamList>}
        activeRouteName={activeRouteName}
      />
    ),
    [activeRouteName, navigation],
  );

  return (
    <AppTemplate renderAsideBar={renderAsideBar}>
      <StackView
        {...rest}
        state={state}
        navigation={navigation}
        descriptors={descriptors}
      />
    </AppTemplate>
  );
}

export const createHomeStackNavigator = createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  StackNavigationOptions,
  StackNavigationEventMap,
  typeof HomeStackNavigator
>(HomeStackNavigator);
