import {Hashrate, Interval} from '../ApiStore';
import {
  Offer,
  MinerProductOffer,
  MinerSubscriptionOffer,
} from '../InAppOffersService';
import {Router} from '../structure';
import {ActivatorProposalsState} from './ActivatorProposalsState';
import {MinerProposalsState} from './MinerProposalsState';

export const SETTLED = Symbol();

export interface ProposalsState extends DerivedFields {
  readonly isLoadedIn: boolean;
  readonly events: Router<{
    [SETTLED]: void;
  }>;
  reset(): void;
}

interface DerivedFields
  extends Pick<
      ActivatorProposalsState,
      | 'activatorSubscriptionProposalList'
      | 'activatorSubscriptionProposalById'
      | 'activatorProposalMapByTime'
      | 'uniqActivatorTimeList'
      | 'uniqActivatorIntervalList'
    >,
    Pick<
      MinerProposalsState,
      | 'minerProductProposalList'
      | 'minerSubscriptionProposalList'
      | 'minerProductProposalById'
      | 'minerSubscriptionProposalById'
      | 'doubleProposalByIntervalByHashrate'
      | 'doubleProposalList'
      | 'minerProposalById'
      | 'minerProposalList'
      | 'minerConfigByHashrate'
      | 'minerConfigsByInterval'
      | 'minerConfigs'
      | 'uniqProductIntervalList'
      | 'uniqIntervalList'
      | 'uniqSubscriptionIntervalList'
    > {}

export type Proposal<O extends Offer = Offer> = {
  available: boolean;
  offer: O;
};

export type DoubleProposal = {
  interval: Interval;
  hashrate: Hashrate;
  product: Proposal<MinerProductOffer> | undefined;
  subscription: ManagedProposalSubscription<MinerSubscriptionOffer> | undefined;
};

export enum SubscriptionCandidateKind {
  Unavailable,
  CanBeImproved,
  CanBeManaged,
  Available,
}

export type ManagedProposalSubscription<O extends Offer> = Proposal<O> & {
  kind: SubscriptionCandidateKind;
};

export type ProposalByInterval<P> = ReadonlyMap<Interval, P>;
export type ProposalByHashrate<P> = ReadonlyMap<Hashrate, P>;
