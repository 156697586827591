import {batchDisposers, Service} from '../structure';
import {
  InAppOffers,
  OfferPurchaseType,
  PaymentMethod,
} from '../InAppOffersService';
import {
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {QuickStartOffer} from './QuickStartOffer';
import {define, LIMITED_OFFER} from '../persistence';
import {sortBy} from 'lodash';
import {Auth} from '../Auth';
import {AppLifecycle} from '../AppLifecycle';
import {AuthFarmStateHelper} from '../AuthFarmStateHelper';
import {
  FULLTUT_TAG,
  INTERTUT_TAG,
  NOQUICKADD_TAG,
  NOQUICKHOT_TAG,
  NOQUICKSTART_TAG,
  NOQUICKTUTORIAL_TAG,
} from '../ApiStore';

export default class QuickStartOfferService
  implements QuickStartOffer, Service
{
  private PAYMENT_METHOD = PaymentMethod.InApp;
  @observable private _manuallyOpenQuickStartPossible = false;
  @observable private _ready = false;
  @observable private _shouldShownModal = false;

  constructor(
    private readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly auth: Auth;
      readonly authFarmStateHelper: AuthFarmStateHelper;
      readonly appLifecycle: AppLifecycle;
    },
  ) {
    makeObservable(this);
  }

  get ready() {
    return this._ready;
  }

  @computed
  get isLoadedIn() {
    return (
      this._root.inAppOffers.isLoadedIn &&
      this._root.auth.state?.kind === 'Connected'
    );
  }

  @computed
  get trial() {
    if (this.offers.length === 0) {
      return null;
    }
    if (this.offers.every((_) => _.trial !== null)) {
      return this.offers[0].trial;
    }
    return null;
  }

  @computed
  get duration() {
    const offer = this.offers[0];
    if (!offer) {
      return undefined;
    }
    return {interval: offer.interval, interval_type: offer.interval_type};
  }

  @computed
  get offers() {
    const offers = this._root.inAppOffers.offers.flatMap((_) =>
      _.tariff === 'Trial' &&
      _.purchaseType === OfferPurchaseType.TrialMinerSubscription
        ? [_]
        : [],
    );
    return sortBy(offers, (_) => {
      const payment = _.payments.get(this.PAYMENT_METHOD);
      return payment?.price;
    });
  }

  @computed
  private get _isZeroPools() {
    const {poolMinersCount} = this._root.authFarmStateHelper;
    if (poolMinersCount === null) {
      return false;
    }
    return poolMinersCount === 0;
  }

  get manuallyOpenQuickStartPossible() {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return false;
    }
    return !authState.tags.has(NOQUICKHOT_TAG);
  }

  @computed
  get shouldAddMinerRedirectToQuickStart() {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return false;
    }
    if (authState.tags.has(NOQUICKADD_TAG)) {
      return false;
    }
    return this._isZeroPools;
  }

  get shouldShownModal() {
    return this._shouldShownModal;
  }

  async dismissModal(permanent: boolean) {
    runInAction(() => (this._shouldShownModal = false));
    if (permanent) {
      const get_ = await getLimitedOfferParams();
      if (!get_.success) {
        return;
      }
      await setLimitedOfferParams(
        Object.assign({}, get_.right, {hasBeenPermanentlyClosed: true}),
      );
    }
  }

  private async _checkIsShouldShowModal() {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return false;
    }
    const {tags} = authState;
    // do not show quickstart if it is the first start with a tutorial
    // and there is a noquicktutorial tag.
    if (
      this._root.appLifecycle.hasJustBeenInstalled &&
      (tags.has(INTERTUT_TAG) || tags.has(FULLTUT_TAG)) &&
      tags.has(NOQUICKTUTORIAL_TAG)
    ) {
      return false;
    }
    if (authState.tags.has(NOQUICKSTART_TAG)) {
      return false;
    }
    if (!this._isZeroPools) {
      return false;
    }
    const get_ = await getLimitedOfferParams();
    if (!get_.success) {
      return false;
    }
    const {hasBeenPermanentlyClosed = false} = get_.right || {};
    if (hasBeenPermanentlyClosed) {
      return false;
    }
    return true;
  }

  private async _processFlags() {
    if (!this._ready) {
      const check_ = await this._checkIsShouldShowModal();
      runInAction(() => {
        this._shouldShownModal = check_;
        this._ready = true;
      });
    }
  }

  private _processFlagsOnConnected() {
    return reaction(
      () => this._root.auth.state?.kind === 'Connected',
      (isConnected) => {
        if (isConnected) {
          // noinspection JSIgnoredPromiseFromCall
          this._processFlags();
        }
      },
    );
  }

  subscribe() {
    return batchDisposers(this._processFlagsOnConnected());
  }
}

export type ParamsRecord = {
  hasBeenPermanentlyClosed: boolean;
};

const [getLimitedOfferParams, setLimitedOfferParams] =
  define<ParamsRecord>(LIMITED_OFFER);
