import {TextProps, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStyles, useTheme, variance} from '../styling';
import {Typography} from '../components';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';
import ProfileFilledSvg from '../assets/svg/colorless/profile_filled.svg';
import {sized} from '../Svg';

export type IdLabelProps = TextProps & {
  accountId: number;
  isMd?: boolean;
  isActive?: boolean;
};

export default observer((props: IdLabelProps) => {
  const {accountId, isMd, isActive} = props;
  const styles = useStyles((theme) => ({
    id: {
      color: theme.colors.primaryUIDirtyBlue,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          textAlign: 'center',
        },
      }),
    },
    isMd: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'center',
    },
  }));
  const theme = useTheme();

  return (
    <View style={isMd && styles.isMd}>
      {isMd && (
        <IconWrapper>
          <IdIcon
            color={
              isActive
                ? theme.colors.primaryAttractive
                : theme.colors.primarySuccess
            }
            style={{marginRight: 5}}
          />
        </IconWrapper>
      )}
      <Typography type={'paragraph'} size={'regular'} style={[styles.id]}>
        ID:{accountId.toFixed()}
      </Typography>
    </View>
  );
});

const IconWrapper = variance(View)(() => ({
  root: {
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const IdIcon = sized(ProfileFilledSvg, 11, 14);
