import React from 'react';
import {observer} from 'mobx-react-lite';
import BannerList from '../../components/templates/BannerList';
import FarmInfoCard from '../../components/FarmInfoCard';
import {PromoBanner} from '../../components/organisms/banner/PromoBanner';
import DownloadMinerBanner from '../../components/organisms/banner/DownloadMinerBanner';
import {StyleSheet, View} from 'react-native';
import {variance} from '../../styling';
import {expr} from 'mobx-utils';
import {XL_BREAKPOINT} from '../../WindowDimensions/useDimensions';
import {useRoot} from '../../Root/hooks';

export type PurchaseBannerLineProps = {
  goToPlayStore: () => void;
  goToAppStore: () => void;
  goToLinkWorker: () => void;
  safelyGoToAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
};

export default observer(function PurchaseBannerLine({
  getAffiliateIsPending,
  safelyGoToAffiliate,
  goToLinkWorker,
}: PurchaseBannerLineProps) {
  const {
    windowDimensionsState: {window},
  } = useRoot();
  const visibleThirdBanner = expr(() => window.width > XL_BREAKPOINT);
  return (
    <TopView>
      <BannerList style={styles.bannerListLarge} largeBreakpoint="lg">
        <FarmInfoCard style={styles.banner} />
        <PromoBanner
          style={styles.banner}
          getAffiliateIsPending={getAffiliateIsPending}
          safelyGoToPromoAffiliate={safelyGoToAffiliate}
        />
        {visibleThirdBanner && (
          <DownloadMinerBanner
            onDownloadButtonPress={goToLinkWorker}
            style={styles.banner}
            titleProps={{numberOfLines: 1}}
          />
        )}
      </BannerList>
    </TopView>
  );
});

const styles = StyleSheet.create({
  banner: {
    height: 150,
  },
  bannerListLarge: {
    flex: 1,
  },
});

const TopView = variance(View)(() => ({
  root: {
    marginBottom: 18,
  },
}));
