import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import Typography from '../../Typography';
import {PlusButton} from '../../atoms';
import {sized} from '../../../Svg';
import MenuDotsSvg from '../../../assets/svg/colorless/menuDots.svg';
import {useTheme, variance} from '../../../styling';
import PressableOpacity from '../../PressableOpacity';
import {WorkerOrder} from '../../../Dashboard';
import useSortItems from '../../../DashboardScreen/hooks/useSortItems';
import ChangeModeSection from '../../../DashboardScreen/components/ChangeModeSection';
import useGetIsLarge from '../../../DashboardScreen/hooks/useGetIsLarge';
import {expr} from 'mobx-utils';

export type GroupHeaderMenu = {
  title: string;
  onPlusPress?: () => void;
  onSortPress: () => void;
  order: WorkerOrder;
};

export default observer(function GroupHeaderMenu({
  title,
  onPlusPress,
  onSortPress,
}: GroupHeaderMenu) {
  const theme = useTheme();
  const {selected} = useSortItems();
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  return (
    <RootView>
      <View style={styles.left}>
        <Typography type="body" weight="500">
          {title}
        </Typography>
        {onPlusPress !== undefined && (
          <View style={styles.plusButtonView}>
            <PlusButton onPress={onPlusPress} />
          </View>
        )}
      </View>
      {isLarge && <ChangeModeSection isLarge={isLarge} isSmall />}
      <View style={styles.right}>
        <SortButton onPress={onSortPress}>
          <Typography type="body" weight="500">
            {selected.title}
          </Typography>
          <View style={styles.sortButtonIcon}>
            <MenuDotsIcon color={theme.colors.primaryUIDirtyBlue} />
          </View>
        </SortButton>
      </View>
    </RootView>
  );
});

const styles = StyleSheet.create({
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  right: {},
  plusButtonView: {
    marginLeft: 6,
  },
  sortButtonIcon: {
    marginLeft: 5,
    marginTop: 3,
  },
});

const MenuDotsIcon = sized(MenuDotsSvg, 4, 10);

const RootView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
    paddingHorizontal: 16,
    paddingVertical: 5,
    backgroundColor: theme.colors.backgroundGround,
  },
}));

const SortButton = variance(PressableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    borderRadius: 8,
  },
}));
