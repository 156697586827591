import {PurchaseId} from '../units';
import {
  ManagedProposalSubscription,
  Proposal,
  ProposalByInterval,
  DoubleProposal,
} from './ProposalsState';
import {
  InAppOffers,
  MinerProductOffer,
  MinerSubscriptionOffer,
  Offer,
} from '../InAppOffersService';
import {computed, makeObservable, observable} from 'mobx';
import {Hashrate, Interval} from '../ApiStore';
import StaticProposalsHelper from './StaticProposalsHelper';
import {maxBy, sortBy, sortedUniq, uniq} from 'lodash';
import {MinerProposalsState} from './MinerProposalsState';
import makeIntervalAndHashrateKey from './makeIntervalAndHashrateKey';
import makeMinerConfigs from './makeMinerConfigs';
import makeMinerConfigByHashrate from './makeMinerConfigsByHashrate';
import makeMinerConfigsByInterval from './makeMinerConfigsByInterval';

export default abstract class BaseMinerProposalsStateImpl
  implements MinerProposalsState
{
  @observable.ref private _minerSubscriptionProposalById?: ReadonlyMap<
    PurchaseId,
    Proposal<MinerSubscriptionOffer>
  >;
  @observable.ref private _minerProductProposalById?: ReadonlyMap<
    PurchaseId,
    Proposal<MinerProductOffer>
  >;

  protected constructor(
    protected readonly _root: {
      readonly inAppOffers: InAppOffers;
    },
  ) {
    makeObservable(this);
  }

  protected abstract _getManagedProposalSubscription(
    proposal: Proposal<MinerSubscriptionOffer>,
  ): ManagedProposalSubscription<MinerSubscriptionOffer> | undefined;

  get minerSubscriptionProposalById() {
    return this._minerSubscriptionProposalById;
  }

  @computed
  get minerSubscriptionProposalList() {
    return [...(this.minerSubscriptionProposalById?.values() ?? [])];
  }

  get minerProductProposalById() {
    return this._minerProductProposalById;
  }

  @computed
  get minerProductProposalList() {
    return [...(this._minerProductProposalById?.values() ?? [])];
  }

  @computed
  get minerProductByIntervalAndHashrate() {
    return new Map(
      this.minerProductProposalList.map((_) => [
        makeIntervalAndHashrateKey(
          _.offer.poolMinerConfig.hash_rate,
          _.offer.interval,
        ),
        _,
      ]),
    );
  }

  @computed
  get minerSubscriptionByIntervalAndHashrate() {
    return new Map(
      this.minerSubscriptionProposalList.map((_) => [
        makeIntervalAndHashrateKey(
          _.offer.poolMinerConfig.hash_rate,
          _.offer.interval,
        ),
        _,
      ]),
    );
  }

  @computed
  get uniqProductIntervalList() {
    return sortBy(
      uniq(this.minerProductProposalList.map((_) => _.offer.interval)),
    );
  }
  @computed
  get uniqSubscriptionIntervalList() {
    return sortBy(
      uniq(this.minerSubscriptionProposalList.map((_) => _.offer.interval)),
    );
  }

  @computed
  get uniqIntervalList() {
    return sortBy(uniq(this.minerProposalList.map((_) => _.offer.interval)));
  }

  @computed
  get minerProposalById() {
    const list = [
      ...this.minerProductProposalList,
      ...this.minerSubscriptionProposalList,
    ];
    return new Map(list.map((_) => [_.offer.purchaseId, _]));
  }

  @computed
  get minerProposalList() {
    return [
      ...this.minerProductProposalList,
      ...this.minerSubscriptionProposalList,
    ];
  }

  // 1000 : {
  //   1: Proposal,
  //   3: Proposal,
  //   6: Proposal,
  //   12: Proposal
  // }
  // 2000: ...
  @computed
  get doubleProposalByIntervalByHashrate(): ReadonlyMap<
    Hashrate,
    ProposalByInterval<DoubleProposal>
  > {
    const uniqIntervalList = sortedUniq([
      ...this.minerProductProposalList.map((_) => _.offer.interval),
      ...this.minerSubscriptionProposalList.map((_) => _.offer.interval),
    ]);
    const uniqHashrateList = sortedUniq([
      ...this.minerProductProposalList.map(
        (_) => _.offer.poolMinerConfig.hash_rate,
      ),
      ...this.minerSubscriptionProposalList.map(
        (_) => _.offer.poolMinerConfig.hash_rate,
      ),
    ]);
    return new Map(
      uniqHashrateList.map((hashrate) => {
        return [
          hashrate,
          new Map(
            uniqIntervalList.flatMap((interval) => {
              const prod = this.minerProductByIntervalAndHashrate.get(
                makeIntervalAndHashrateKey(hashrate, interval),
              );
              const sub = this.minerSubscriptionByIntervalAndHashrate.get(
                makeIntervalAndHashrateKey(hashrate, interval),
              );
              if (!prod && !sub) {
                return [];
              }
              const managedSubscription =
                sub && this._getManagedProposalSubscription(sub);
              const proposal = (
                prod?.available || sub?.available
                  ? {
                      interval,
                      hashrate,
                      product: prod,
                      subscription: managedSubscription,
                    }
                  : {
                      interval,
                      hashrate,
                      product: prod,
                      subscription: managedSubscription,
                    }
              ) satisfies DoubleProposal;
              return [[interval, proposal]];
            }),
          ),
        ];
      }),
    );
  }

  @computed
  get doubleProposalList() {
    return [...this.doubleProposalByIntervalByHashrate.values()].flatMap(
      (_) => [..._.values()],
    );
  }

  @computed
  get minerConfigByHashrate() {
    return makeMinerConfigByHashrate(this.minerProposalList);
  }

  @computed
  get minerConfigsByInterval() {
    return makeMinerConfigsByInterval(
      this.minerProposalList,
      this.uniqIntervalList,
    );
  }

  @computed
  get minerConfigs() {
    return makeMinerConfigs(this.minerProposalList);
  }

  getMaxMinerProductHashrate(interval: Interval): Hashrate | undefined {
    const offers = this.minerProductProposalList.flatMap((_) =>
      _.available && _.offer.interval === interval ? [_.offer] : [],
    );
    const maxHashrateMiner = maxBy(offers, (o) => o.poolMinerConfig.hash_rate);
    if (!maxHashrateMiner) {
      return undefined;
    }
    return maxHashrateMiner.poolMinerConfig.hash_rate;
  }

  private _getIntervalMap<O extends Offer>(offers: O[]) {
    const offersByInterval = new Map<Interval, O[]>();
    for (const offer of offers) {
      const interval = offer.interval;
      if (offersByInterval.has(interval)) {
        offersByInterval.get(interval)?.push(offer);
      } else {
        offersByInterval.set(interval, [offer]);
      }
    }
    return offersByInterval;
  }

  private _prepareProducts() {
    const offersByInterval = this._getIntervalMap(
      this._root.inAppOffers.minerProductOffers,
    );

    const candidateList = [...offersByInterval.keys()].flatMap((interval) =>
      StaticProposalsHelper.discardUnprofitableMiners(
        offersByInterval.get(interval) ?? [],
      ),
    );

    const list = candidateList.map((candidate) => {
      return {
        available: candidate.available,
        offer: candidate.offer,
      };
    });
    this._minerProductProposalById = new Map(
      list.map((_) => [_.offer.purchaseId, _]),
    );
  }

  private _prepareSubscriptions() {
    const offersByInterval = this._getIntervalMap(
      this._root.inAppOffers.minerSubscriptionOffers,
    );

    const candidateList = [...offersByInterval.keys()].flatMap((interval) =>
      StaticProposalsHelper.discardUnprofitableMiners(
        offersByInterval.get(interval) ?? [],
      ),
    );
    const availableList = candidateList.map((candidate) => {
      return {
        offer: candidate.offer,
        available: candidate.available,
      };
    });

    this._minerSubscriptionProposalById = new Map(
      availableList.map((_) => [_.offer.purchaseId, _]),
    );
  }

  prepare() {
    this._prepareProducts();
    this._prepareSubscriptions();
  }

  reset() {
    this._minerSubscriptionProposalById = undefined;
  }
}
