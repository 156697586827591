import useRepresentation from './useRepresentation';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Representation} from '../index';
import { Platform } from 'react-native';

export default function useSafePaddingBottom(
  defaultBottom: number,
  modalPaddingBottom?: number,
) {
  const representation = useRepresentation();
  const insets = useSafeAreaInsets();
  return representation === Representation.BottomSheet
    ? Platform.select({
        android: insets.bottom + defaultBottom,
        default: Math.max(insets.bottom, defaultBottom),
      })
    : modalPaddingBottom ?? defaultBottom;
}
