import React, {useCallback, useEffect} from 'react';
import {Platform, View} from 'react-native';
import {observer} from 'mobx-react-lite';

import {Typography} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import AppStoreSvg from '../assets/svg/colored/appStore.svg';
import GooglePlaySvg from '../assets/svg/colored/googlePlay.svg';
import {sized} from '../Svg';
import {createStylesHook, useTheme} from '../styling';
import StarSvg from '../assets/svg/colorless/roundedStar.svg';

import {AppButton, ButtonVariant} from '../components/AppButton';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import * as StoreReview from 'expo-store-review';
import useOpenBrowserReview from './useOpenBrowserReview';

export type RateUsRedirectProps = {
  rating: number;
  onClose: () => void;
};

export default observer(function RateUsRedirect({
  rating,
  onClose,
}: RateUsRedirectProps) {
  const {rateApp} = useRoot();
  const strings = useStrings();
  const styles = useStyles();
  const theme = useTheme();
  const openBrowser = useOpenBrowserReview();
  const handleOpenBrowser = useCallback(async () => {
    await openBrowser();
    await rateApp.sendRating({rating});
  }, [openBrowser, rateApp, rating]);

  useEffect(() => {
    async function run() {
      if (await StoreReview.isAvailableAsync()) {
        await StoreReview.requestReview();
        await rateApp.sendRating({rating});
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    run();
  }, [rateApp, rating]);

  const marketName =
    Platform.OS === 'ios' || Platform.OS === 'web'
      ? strings['modal.rateUs.iosMarketName']
      : strings['modal.rateUs.androidMarketName'];

  const description = strings['modal.rateUs.description'].replace(
    '{marketName}',
    marketName,
  );

  const Icon = Platform.select({
    android: () => (
      <View style={styles.iconWrapper}>
        <GooglePlayIcon />
      </View>
    ),
    // ios web
    default: () => (
      <View style={styles.iconWrapper}>
        <AppStoreIcon />
      </View>
    ),
  });
  const paddingBottom = useSafePaddingBottom(16);

  return (
    <View style={[styles.root, {paddingBottom}]}>
      <View style={styles.headerView}>
        <Icon />
        <View style={styles.starList}>
          {new Array(5).fill('').map((_, i) => (
            <View key={i} style={styles.starView}>
              <StarIcon
                color={
                  i < rating
                    ? theme.colors.primaryAttractive
                    : theme.colors.uiAdditional1
                }
              />
            </View>
          ))}
        </View>
      </View>
      <Typography type="header" size="h2" style={styles.titleText}>
        {strings['modal.rateUs.title']}
      </Typography>
      <Typography type="paragraph" weight="500" style={styles.descText}>
        {description}
      </Typography>
      <View style={styles.footerView}>
        <AppButton
          onPress={handleOpenBrowser}
          style={styles.rateButtonContainerView}
          variant={ButtonVariant.Primary}>
          {strings['modal.rateUs.rateInStore']}
        </AppButton>
        <AppButton onPress={onClose}>{strings['action.close']}</AppButton>
      </View>
    </View>
  );
});

const StarIcon = sized(StarSvg, 24);
const AppStoreIcon = sized(AppStoreSvg, 30);
const GooglePlayIcon = sized(GooglePlaySvg, 40, 30);

const useStyles = createStylesHook((theme) => ({
  root: {
    padding: 16,
  },
  modalView: {
    maxWidth: 450,
  },
  headerView: {
    alignItems: 'center',
    marginBottom: 16,
    marginTop: 10,
  },
  starList: {
    flexDirection: 'row',
    marginTop: 6,
  },
  starView: {
    padding: 4,
  },
  titleText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  descText: {
    color: theme.colors.primaryUIDirtyBlue,
    marginBottom: 35,
    textAlign: 'center',
  },
  redirectTimerView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    height: 30,
  },
  redirectText: {
    fontSize: 11,
    lineHeight: 13,
    color: theme.colors.primaryUIDirtyBlue,
  },
  iconWrapper: {
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
  },
  rateButtonContainerView: {
    position: 'relative',
    marginBottom: 15,
  },
  footerView: {},
}));
