import React, {forwardRef} from 'react';
import PuzzleConfirmation from './PuzzleConfirmation';
import {StyleSheet, View} from 'react-native';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  Representation,
} from '../components/templates';
import {AdaptiveModalHeader} from '../components/organisms/modal/AdaptiveModalHeader';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import {sized} from '../Svg';
import SecureSvg from '../assets/svg/twoTone/secure.svg';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import useRepresentation from '../components/templates/AdaptiveModal/useRepresentation';
import {useStrings} from '../Root/hooks';
import ReactivationBanner from '../components/organisms/banner/ReactivationBanner';

export type PuzzleActivatorModalProps = {
  onModalClosed: () => void;
  onSuccess: () => Promise<void>;
  onBannerPress: () => void;
  onEnd: () => void;
  visibleActivatorBanner: boolean;
};

export default observer<PuzzleActivatorModalProps, AdaptiveModalRef>(
  forwardRef((props, ref) => {
    return (
      <AdaptiveModal
        ref={ref}
        onModalClosed={props.onModalClosed}
        adaptiveBreakpoint="sm"
        modalContentStyle={styles.modalContent}
        enableContentAutoHeight>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

export const SecureIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SecureSvg, 26)} {...props} />
));

export const SecureLargeIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SecureSvg, 33)} {...props} />
));

const ContentView = observer((props: PuzzleActivatorModalProps) => {
  const {onBannerPress, visibleActivatorBanner, ...rest} = props;
  const paddingBottom = useSafePaddingBottom(16, 16);
  const representation = useRepresentation();
  const isModal = representation === Representation.Modal;
  const strings = useStrings();
  return (
    <View style={[styles.root, {paddingBottom}]}>
      <HeaderView isModal={isModal}>
        <AdaptiveModalHeader
          title={strings['puzzle.securityCheck.title']}
          description={strings['puzzle.securityCheck.description']}
          IconSvg={SecureIcon}
          IconSvgModalRep={SecureLargeIcon}
        />
      </HeaderView>
      <PuzzleConfirmation {...rest} />
      {visibleActivatorBanner && (
        <View style={styles.bannerOffset}>
          <ReactivationBanner
            style={styles.banner}
            onButtonPress={onBannerPress}
          />
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    padding: 16,
    paddingTop: 32,
  },
  modalContent: {
    width: 450,
  },
  banner: {
    height: 104,
  },
  bannerOffset: {
    marginTop: 24,
  },
});

const HeaderView = variance(View)(() => ({
  root: {
    paddingBottom: 24,
  },
  isModal: {
    paddingBottom: 36,
  },
}));
