import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStyles, variance} from '../styling';
import {PressableOpacity, Typography} from '../components';
import ArrowSvg from '../assets/svg/colorless/chevronRight.svg';

export type TimezoneListItemProps = {
  timezone: {name: string; offset: number};
  onSelect: (offset: number) => void;
};

export default observer(function TimezoneListItem(
  props: TimezoneListItemProps,
) {
  const {timezone, onSelect} = props;
  const styles = useStyles((theme) => ({
    icon: {
      color: theme.colors.primaryUIDirtyBlue,
      marginLeft: 'auto',
    },
  }));

  return (
    <TimezoneListItemView onPress={() => onSelect(timezone.offset)}>
      <Typography type={'paragraph'} size={'large'} weight={'500'}>
        {timezone.name}
      </Typography>
      <ArrowSvg style={styles.icon} />
    </TimezoneListItemView>
  );
});

const TimezoneListItemView = variance(PressableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    paddingVertical: 15,
  },
}));
