import {Platform} from 'react-native';
import AndroidIapRequesterImpl from './AndroidIapRequesterImpl';
import {ApiStore} from '../../ApiStore';
import {InAppOffers} from '../../InAppOffersService';
import {InAppPurchaseManager} from '../../InAppPurchaseManager';
import {ErrorRepository} from '../../ErrorRepository';
import {IapPurchaseRequester} from '../PurchaseRequester';
import IosIapRequesterImpl from './IosIapRequesterImpl';
import {PendingPurchasesResolver} from '../../PendingPurchasesResolver';
import {Auth} from '../../Auth';
import {PurchaseDiscount} from '../../PurchasePromoService';
import {ConnectedClient} from '../../ContextClient';
import {Configuration} from '../../Configuration';
import SubIapRequesterImpl from './SubIapRequesterImpl';
import {GiftState} from '../../Gift/Gift';
import {UtmUrlListener} from '../../UtmUrlListener';

export default class IapRequesterFactoryImpl {
  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly auth: Auth;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly gift: GiftState;
      readonly utmUrl: UtmUrlListener;
    },
  ) {}

  create() {
    return Platform.select<IapPurchaseRequester>({
      android: new AndroidIapRequesterImpl(this._root),
      ios: new IosIapRequesterImpl(this._root),
      default: new SubIapRequesterImpl(this._root),
    });
  }
}
