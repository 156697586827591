import React, {PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {useGetIsDimensions} from '../../../../WindowDimensions/useDimensions';
import {Animated} from 'react-native';
import {Swipeable} from 'react-native-gesture-handler';

export type AffiliateBannerContainerProps = PropsWithChildren<{
  rightSwipe: (
    progress: Animated.AnimatedInterpolation<number>,
    dragX: Animated.AnimatedInterpolation<number>,
  ) => JSX.Element;
}>;

export default observer(function AffiliateBannerContainer({
  children,
  rightSwipe,
}: AffiliateBannerContainerProps) {
  const isLg = useGetIsDimensions('lg');

  if (isLg()) {
    return <>{children}</>;
  }
  return (
    <Swipeable
      rightThreshold={60}
      renderRightActions={rightSwipe}
      overshootLeft={false}
      overshootRight={false}>
      {children}
    </Swipeable>
  );
});
