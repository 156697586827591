import {observer} from 'mobx-react-lite';
import React from 'react';
import {RefreshControl} from 'react-native';
import {
  WorkerStatisticsPanelState,
  WorkerStatisticsPanel,
} from '../StatisticsPanel';
import {ScrollView} from 'react-native-gesture-handler';
import {Millisecond} from '../Time';
import {ChartViewScope} from '../GraphPanel';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {createStylesHook} from '../styling';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import BottomStatisticBannerContainer from '../StatisticsScreen/BottomStatisticBannerContainer';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type WorkerStatisticsScreenProps = {
  workerId: string;
  state: WorkerStatisticsPanelState;
  refresh: () => void;
  onPick: (id?: string | number | undefined) => void;
  onFromChange: (from: Millisecond) => void;
  onScopeChange: (scope: ChartViewScope) => void;
  group: WorkerEntity[] | undefined;
  goToPlan: () => void;
  goToAddDemoMiner: () => void;
};
const LARGE_BREAKPOINT = 'lg';

export default observer(function WorkerStatisticsScreen({
  workerId,
  group,
  state,
  refresh,
  goToPlan,
  goToAddDemoMiner,
  ...rest
}: WorkerStatisticsScreenProps) {
  const styles = useStyles();
  const insets = useSafeAreaInsets();
  return (
    <ScrollView
      refreshControl={
        <RefreshControl refreshing={state.isRefreshing} onRefresh={refresh} />
      }
      contentContainerStyle={[styles.contentContainer, {paddingBottom: insets.bottom + 20}]}>
      <WorkerStatisticsPanel
        group={group}
        style={styles.panel}
        workerId={workerId}
        state={state}
        {...rest}
      />
      <BottomStatisticBannerContainer
        goToPlan={goToPlan}
        goToAddDemoMiner={goToAddDemoMiner}
        largeBreakpoint={LARGE_BREAKPOINT}
      />
    </ScrollView>
  );
});

const useStyles = createStylesHook((theme) => ({
  contentContainer: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 20,
      },
    }),
  },
  panel: {
    marginTop: 20,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    marginBottom: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        borderRadius: 12,
      },
    }),
  },
}));
