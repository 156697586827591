import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {useStyles, variance} from '../styling';
import {useStrings} from '../Root/hooks';
import {Header, Paragraph} from '../components';
import {ADAPTIVE_BREAKPOINT_PX} from '../components/templates';
import {useWindowDimensions} from '../WindowDimensions';

export default observer(() => {
  const strings = useStrings();
  return (
    <View>
      <Title type="header" size="h4">
        {strings['paymentHistory.title']}
      </Title>
      <TableHeader />
    </View>
  );
});

const Title = variance(Header)(() => ({
  root: {
    padding: 22,
    textAlign: 'left',
  },
}));

const TableHeader = observer(() => {
  const window = useWindowDimensions();
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      backgroundColor: theme.colors.backgroundGround,
      paddingHorizontal: 22,
      paddingVertical: 8,
    },
    title: {
      textAlign: 'center',
    },
    first: {
      textAlign: 'left',
    },
    item: {
      flex: 1,
    },
  }));
  const strings = useStrings();
  if (window.width < ADAPTIVE_BREAKPOINT_PX) {
    return null;
  }
  return (
    <View>
      <View style={styles.root}>
        <View style={styles.item}>
          <Paragraph
            type="paragraph"
            size="large"
            weight="500"
            style={[styles.title, styles.first]}>
            {strings['withdrawalHistory.table.amount']}
          </Paragraph>
        </View>
        <View style={styles.item}>
          <Paragraph
            type="paragraph"
            size="large"
            weight="500"
            style={styles.title}>
            {strings['withdrawalHistory.table.date']}
          </Paragraph>
        </View>
        <View style={styles.item}>
          <Paragraph
            type="paragraph"
            size="large"
            weight="500"
            style={styles.title}>
            {strings['withdrawalHistory.walletId']}
          </Paragraph>
        </View>
        <View style={styles.item}>
          <Paragraph
            type="paragraph"
            size="large"
            weight="500"
            style={styles.title}>
            {strings['withdrawalHistory.table.status']}
          </Paragraph>
        </View>
      </View>
    </View>
  );
});
