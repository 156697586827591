import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings} from '../Root/hooks';
import {BaseWorkerGroupNameModal} from '../BaseWorkerGroupNameModal';

export type RenameWorkerGroupModalProps = {
  onModalClosed: () => void;
  onConfirm: (groupName: string) => void;
  onCancel: () => void;
  initialName: string;
};

export default observer<RenameWorkerGroupModalProps, AdaptiveModalRef>(
  forwardRef(function RenameWorkerGroupModal(
    {onConfirm, onCancel, onModalClosed, initialName},
    ref,
  ) {
    const strings = useStrings();
    return (
      <BaseWorkerGroupNameModal
        ref={ref}
        onModalClosed={onModalClosed}
        onConfirm={onConfirm}
        onCancel={onCancel}
        initialGroupName={initialName}
        title={strings['modal.renameWorkerGroup.title']}
        description={strings['modal.renameWorkerGroup.subtext']}
        inputPlaceholder={strings['modal.addMiningGroup.subtext']}
        buttonLabel={strings['action.rename']}
      />
    );
  }),
);
