import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {DeleteWorkerGroupConfirmModal} from '../../DeleteWorkerGroupConfirmModal';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {useErrorHandler} from '../../ExceptionHandler';

export type DeleteWorkerGroupConfirmBindingProps =
  RootStackBindingProps<'DeleteWorkerGroupConfirm'>;

export default observer(function DeleteWorkerGroupConfirmBinding({
  route,
}: DeleteWorkerGroupConfirmBindingProps) {
  const {dashboardStore, connectedClient} = useRoot();
  const groupId = route.params?.groupId;
  const group = expr(() =>
    groupId !== undefined ? dashboardStore.groups.get(groupId) : undefined,
  );
  const modalRef = useRef<AdaptiveModalRef>(null);
  const safelyGoBack = useSafelyGoBack();
  const close = useCallback(() => modalRef.current?.close(), []);
  const onCancel = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);
  const errorHandler = useErrorHandler();
  const onConfirm = useCallback(async () => {
    await close();
    safelyGoBack();
    if (!group) {
      return;
    }
    const result = await connectedClient.call('delete_group', {
      group_id: group.id,
    });
    if (!result.success) {
      errorHandler.handle(result.left);
    }
  }, [connectedClient, close, errorHandler, group, safelyGoBack]);
  return (
    <DeleteWorkerGroupConfirmModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onConfirm={onConfirm}
      onCancel={onCancel}
      group={group}
    />
  );
});
