import {Either} from '../fp';
import {NotificationData} from './NotificationData';
import {ActionId} from '../units';
import {MessageContext} from '../Messaging';
import {Opaque} from 'type-fest';

export interface HeadlessLocalNotifications {
  scheduleNotification(context: MessageContext): Promise<Either<void, unknown>>;
  cancelNotification(id: LocalNotificationId): Promise<Either<void, unknown>>;
  disposeNotification(
    id: LocalNotificationId,
    groupId: string,
  ): Promise<Either<void, unknown>>;
  hideNotificationDrawer(): Promise<Either<void, unknown>>;
  handle(context: LocalNotificationContext): Promise<Either<void, unknown>>;
}

export type LocalNotificationContext = {
  type: EventType;
  data: NotificationData;
  id?: LocalNotificationId;
  actionId?: ActionId;
};

export const LOCAL_NOTIFICATION_ID = Symbol();
export type LocalNotificationId = Opaque<string, typeof LOCAL_NOTIFICATION_ID>;

export enum EventType {
  Initial,
  Delivered,
  Press,
  Dismissed,
}
