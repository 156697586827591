import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {View} from 'react-native';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import PaymentScreenContext, {PaymentScreenProps} from './context';
import QrBinding from './QrBinding';
import FormBinding from './FormBinding';
import FormActionsBinding from './FormActionsBinding';
import HeaderBinding from './HeaderBinding';

export default observer(function PaymentScreen(props: PaymentScreenProps) {
  return (
    <PaymentScreenContext.Provider value={props}>
      <RootView>
        <HeaderContainerView>
          <HeaderBinding />
        </HeaderContainerView>
        <BodyView>
          <View>
            <QrContainerView>
              <QrBinding />
            </QrContainerView>
          </View>
          <FormContainerView>
            <FormBinding />
          </FormContainerView>
        </BodyView>
        <FormActionsBinding />
      </RootView>
    </PaymentScreenContext.Provider>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.colors.uiMain,
    maxWidth: 900,
    width: '100%',
    marginHorizontal: 'auto',
    background: theme.colors.uiMain,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingHorizontal: 16,
    paddingVertical: 24,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        paddingHorizontal: 20,
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
      },
    }),
  },
}));

const HeaderContainerView = variance(View)(() => ({
  root: {
    marginBottom: 24,
  },
}));

const BodyView = variance(View)((theme) => ({
  root: {
    marginBottom: 24,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    }),
  },
}));

const QrContainerView = variance(View)((theme) => ({
  root: {
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 44,
      },
    }),
  },
}));

const FormContainerView = variance(View)((theme) => ({
  root: {
    marginBottom: 24,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 420,
      },
    }),
  },
}));
