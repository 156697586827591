import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {View} from 'react-native';
import WalletListItem from './WalletListItem';
import {Wallet} from '../Navigation/RootStack/ConnectWalletBinding';

export type WalletListProps = {
  wallets: Wallet[];
  selected: Wallet;
  onPress: (wallet: Wallet) => void;
};

export default observer(function WalletList({
  wallets,
  selected,
  onPress,
}: WalletListProps) {
  return (
    <WalletListContainer>
      {wallets.map((wallet) => (
        <WalletListItem
          key={wallet.id}
          wallet={wallet}
          isActive={wallet.id === selected.id}
          onPress={() => onPress(wallet)}
        />
      ))}
    </WalletListContainer>
  );
});

const WalletListContainer = variance(View)(() => ({
  root: {
    marginTop: 40,
    marginHorizontal: -15,
  },
}));
