import {
  CommonError,
  Discount,
  GetTariffsParams,
  GetTariffsResult,
  Plan,
} from '../../ApiStore';
import {Either} from '../../fp';
import {DefaultError} from '../../JsonRpc';
import {Millisecond} from '../../Time';
import {ConnectedClient} from '../../ContextClient';
import {Configuration} from '../../Configuration';
import {OfferDiscount, OfferId} from './OfferManager';
import toFixedTrunc from '../../utils/toFixedTrunc';
import {first} from 'lodash';

export default class BaseOffersRequesterImpl {
  constructor(
    protected readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly configuration: Configuration;
    },
  ) {}

  protected _generateOfferId(_: Plan) {
    return `${_.id}_${_.id_on_platform}` as OfferId;
  }

  protected _calculateDiscountPrices(
    price: number,
    discounts: Discount[],
    n = 2,
  ) {
    const offerDiscounts = this._prepareDiscounts(price, discounts, n);
    const totalDiscountPercent = discounts.reduce(
      (total, discount) => total + discount.discount,
      0,
    );
    const oldPrice = totalDiscountPercent
      ? first(offerDiscounts)?.toPrice || undefined
      : undefined;
    const uiOldPrice = oldPrice ? toFixedTrunc(oldPrice, n) : undefined;
    const discountsDifferencePrice = oldPrice ? oldPrice - price : undefined;
    const uiDiscountsDifferencePrice = discountsDifferencePrice
      ? toFixedTrunc(discountsDifferencePrice, n)
      : undefined;
    return {
      oldPrice,
      uiOldPrice,
      discountsDifferencePrice,
      uiDiscountsDifferencePrice,
      offerDiscounts,
    };
  }

  protected _getUsdPrice(plan: Plan) {
    return plan.crypto_rates.find((_) => _.currency === 'USD')?.amount;
  }

  private _prepareDiscounts(
    price: number,
    discounts: Discount[],
    n = 2,
  ): OfferDiscount[] {
    const result = [];
    let lastPrice = price;
    for (const discount of [...discounts].reverse()) {
      const percent = discount.discount;
      if (percent === 0) {
        continue;
      }
      const title = discount.desc;
      const fromPrice = lastPrice;
      const uiFromPrice = toFixedTrunc(fromPrice, n);
      const toPrice = (lastPrice / (100 - percent)) * 100;
      const uiToPrice = toFixedTrunc(toPrice, n);
      const difference = lastPrice - toPrice;
      const uiDifference = toFixedTrunc(difference, n);
      result.push({
        percent,
        title,
        fromPrice,
        uiFromPrice,
        toPrice,
        uiToPrice,
        difference,
        uiDifference,
      });
      lastPrice = toPrice;
    }
    return result.reverse();
  }

  protected _fetchTariffs(
    params: GetTariffsParams,
  ): Promise<Either<GetTariffsResult, CommonError | DefaultError>> {
    return this._root.connectedClient.apply('get_tariffs', params, {
      timeout: (this._root.configuration.values.bffTimeout * 2) as Millisecond,
    });
  }
}
