import React from 'react';
import {StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';

import {PrimaryMenuItem} from './PrimaryMenuItem';
import {useStrings} from '../Root/hooks';
import {expr} from 'mobx-utils';
import BasketSvg from '../assets/svg/colorless/basket.svg';
import WalletSvg from '../assets/svg/colorless/wallet.svg';
import SwapSvg from '../assets/svg/colorless/swap.svg';
import QrScannerSvg from '../assets/svg/colorless/qrScaner.svg';
import PartnersSvg from '../assets/svg/colorless/partners.svg';
import PaymentSvg from '../assets/svg/colorless/payment.svg';
import LampSvg from '../assets/svg/colorless/lamp.svg';
import EyeSvg from '../assets/svg/colorless/eye.svg';
import LikeSvg from '../assets/svg/colorless/like.svg';

export type MenuListProps = {
  goToPlan: () => void;
  safelyGoToWithdraw: () => void;
  goToFarmSwitch: () => void;
  getAffiliateIsPending: () => boolean;
  safelyGoToAffiliate: () => void;
  goToWithdrawalHistory: () => void;
  goToTutorial: () => void;
  goToDescription: () => void;
  goToQRCodeScanner: () => void;
  openRate: () => void;
  qrCodeScannerDisabled?: boolean;
};

export default observer(function MenuList(props: MenuListProps) {
  const {
    goToPlan,
    safelyGoToWithdraw,
    goToFarmSwitch,
    getAffiliateIsPending,
    safelyGoToAffiliate,
    goToWithdrawalHistory,
    goToTutorial,
    goToDescription,
    openRate,
    goToQRCodeScanner,
  } = props;
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <PrimaryMenuItem
        Icon={BasketSvg}
        text={strings['menu.purchases']}
        onPress={goToPlan}
      />
      <PrimaryMenuItem
        Icon={WalletSvg}
        text={strings['menu.earnings']}
        onPress={safelyGoToWithdraw}
      />
      <PrimaryMenuItem
        Icon={SwapSvg}
        text={strings['menu.switchFarm']}
        onPress={goToFarmSwitch}
      />
      <PrimaryMenuItem
        Icon={QrScannerSvg}
        text={strings['menu.qrCodeScanner']}
        onPress={goToQRCodeScanner}
      />
      <AffiliateMenuItem
        getAffiliateIsPending={getAffiliateIsPending}
        safelyGoToAffiliate={safelyGoToAffiliate}
      />
      <PrimaryMenuItem
        Icon={PaymentSvg}
        onPress={goToWithdrawalHistory}
        text={strings['menu.paymentsHistory']}
      />
      <PrimaryMenuItem
        Icon={LampSvg}
        onPress={goToTutorial}
        text={strings['menu.tutorial']}
      />
      <PrimaryMenuItem
        Icon={EyeSvg}
        text={strings['menu.about']}
        onPress={goToDescription}
      />
      <PrimaryMenuItem
        Icon={LikeSvg}
        onPress={openRate}
        text={strings['menu.rate']}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
});

type AffiliateMenuItemProps = {
  getAffiliateIsPending: () => boolean;
  safelyGoToAffiliate: () => void;
};

const AffiliateMenuItem = observer((props: AffiliateMenuItemProps) => {
  const {getAffiliateIsPending, safelyGoToAffiliate} = props;
  const strings = useStrings();
  const isPending = expr(getAffiliateIsPending);
  return (
    <PrimaryMenuItem
      Icon={PartnersSvg}
      text={strings['menu.affiliate']}
      loading={isPending}
      disabled={isPending}
      onPress={safelyGoToAffiliate}
    />
  );
});
