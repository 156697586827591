import React, {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import AsideButton from './AsideButton';
import LogoutSvg from '../../../assets/svg/colorless/logout.svg';
import SignInSvg from '../../../assets/svg/colorless/signIn.svg';
import {sized} from '../../../Svg';
import {useTheme} from '../../../styling';
import {ThemeSwitch, ThemeSwitchRef} from '../../molecules';
import {useRoot, useStrings} from '../../../Root/hooks';
import {expr} from 'mobx-utils';
import {AccountType} from '../../../Auth';

export type BottomButtonsProps = {
  isExpanded: boolean;
  goToLogout: () => void;
  goToAuth: () => void;
};

export default observer(function BottomButtons({
  isExpanded,
  goToLogout,
  goToAuth,
}: BottomButtonsProps) {
  const {auth} = useRoot();
  const isTemporary = expr(() => auth.accountType === AccountType.Temporary);
  const theme = useTheme();
  const strings = useStrings();
  const themeSwitchRef = useRef<ThemeSwitchRef>(null);
  const toggle = () => themeSwitchRef.current?.toggle();
  return (
    <View style={styles.root}>
      {isTemporary ? (
        <AsideButton
          title={strings['menu.signUp']} // TODO move menu.signUp to common actions
          isExpanded={isExpanded}
          renderIcon={() => <SignInIcon color={theme.colors.uiAdditional4} />}
          onPress={goToAuth}
        />
      ) : (
        <AsideButton
          title={strings['action.logout']}
          isExpanded={isExpanded}
          renderIcon={() => <LogoutIcon color={theme.colors.uiAdditional4} />}
          onPress={goToLogout}
        />
      )}

      <AsideButton
        title={strings['action.changeTheme']}
        isExpanded={isExpanded}
        renderIcon={() => <ThemeSwitch ref={themeSwitchRef} />}
        onPress={toggle}
      />
    </View>
  );
});

const LogoutIcon = sized(LogoutSvg, 34);
const SignInIcon = sized(SignInSvg, 34);

const styles = StyleSheet.create({
  root: {
    marginTop: 'auto',
    paddingBottom: 0,
  },
});
