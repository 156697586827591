import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {Offer} from '../InAppOffersService';
import {GlobalError} from '../Error';
import {createNullableContext} from '../context';
import {RenewAccessScreenState} from './RenewAccessScreenState';

export type RenewAccessScreenProps = {
  worker: WorkerEntity;
  goToPurchase: () => void;
  onPurchasePress: (offer: Offer) => void;
  purchaseStoreError: GlobalError | undefined;
  state: RenewAccessScreenState;
};

const RenewAccessScreenContext =
  createNullableContext<RenewAccessScreenProps>();

export default RenewAccessScreenContext;
