import {Platform} from 'react-native';
import {DoubleProposal} from '../ProposalsState';
import {CurrentSubscriptionOffer} from '../CurrentSubscriptionOfferService';

export default abstract class StaticPurchaseScreenHelper {
  static filterDoublePoolProposal<O extends DoubleProposal = DoubleProposal>(
    candidate: O,
    currentSubscriptionOffer: CurrentSubscriptionOffer['currentSubscriptionOffer'],
  ) {
    switch (Platform.OS) {
      case 'ios': {
        const {product, subscription, ...rest} = candidate;
        if (currentSubscriptionOffer && product) {
          return {
            ...rest,
            subscription: undefined,
            product,
          };
        } else if (subscription) {
          return {
            ...rest,
            subscription,
            product: undefined,
          };
        }
        break;
      }
      case 'android': {
        const {subscription, product, ...rest} = candidate;
        const subOffer = subscription?.offer;
        const prodOffer = product?.offer;
        if (subOffer && !subOffer.bought) {
          return {
            ...rest,
            subscription,
            product: undefined,
          };
        } else if (subOffer?.bought) {
          return {
            ...rest,
            subscription: undefined,
            product: undefined,
          };
        } else if (!subOffer && prodOffer) {
          return {
            ...rest,
            subscription: undefined,
            product: product,
          };
        }
      }
    }

    return candidate;
  }
}
