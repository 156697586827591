import React from 'react';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../styling';
import WeekTable from './WeekTable';
import {View} from 'react-native';
import SpeedSwitch from './SpeedSwitch';
import ScheduleFooter from './ScheduleFooter';
import ScheduleState from './ScheduleState';
import './matrixToIntervals';
import './intervalsToMatrix';
import {ScheduleHeader} from './ScheduleHeader';
import {ScrollView} from 'react-native-gesture-handler';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type ScheduleScreenProps = {
  leave: () => void;
  onSpeedSelect: () => void;
  onTimezoneSelect: () => void;
  onWorkersSelect: () => void;
  state: ScheduleState;
};

export default observer(function ScheduleScreen(props: ScheduleScreenProps) {
  const {leave, onSpeedSelect, onTimezoneSelect, onWorkersSelect, state} =
    props;
  const styles = useStyles();

  const insets = useSafeAreaInsets();

  if (state.isLoading || !state.workers.length) {
    return null;
  }

  return (
    <ScrollView style={styles.screen} contentContainerStyle={{paddingBottom: insets.bottom}}>
      <View style={styles.root}>
        <ScheduleHeader state={state} onWorkersSelectPress={onWorkersSelect} />
        <WeekTable state={state} onTimezonePress={onTimezoneSelect} />
        <SpeedSwitch state={state} onSpeedSelect={onSpeedSelect} />
        <ScheduleFooter state={state} leave={leave} />
      </View>
    </ScrollView>
  );
});

const useStyles = createStylesHook((theme) => ({
  screen: {
    backgroundColor: theme.colors.uiMain,
    overflow: 'hidden',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexGrow: -1,
        marginHorizontal: 18,
        borderRadius: 12,
      },
    }),
  },
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderWidth: 1,
        borderRadius: 12,
        borderColor: theme.colors.uiAdditional1,
        overflow: 'hidden',
      },
    }),
  },
  header: {
    marginVertical: 20,
  },
  footer: {
    paddingHorizontal: 15,
  },
}));
