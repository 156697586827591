import {ConfigurationValues} from './ConfigurationValues';
import common from './common';

const values: ConfigurationValues = {
  ...common,
  mainSocketUrl: 'wss://dashapi.cryptotabfarm.com/ws/v1/',
  healthCheckUrl: 'https://dashapi.cryptotabfarm.com/status/',
  apiUrl: 'https://dashapi.cryptotabfarm.com/api/v1/',
  tokenUrl: 'https://api.cryptobrowser.site/api/v1/',
  siteUrl: 'https://cryptobrowser.site',
  oauthIssuer: 'https://cryptobrowser.site',
  oauthClientId: '7pKCyH2ujN6GsSmDzPqu63DCFSa01NxESKDtjJwC',
  oauthAuthorizationEndpoint: 'https://cryptobrowser.site/o/authorize/',
  oauthTokenEndpoint: 'https://cryptobrowser.site/o/token/',
  oauthRevocationEndpoint: 'https://cryptobrowser.site/o/revoke-token/',
  isDev: false,
};

export default values;
