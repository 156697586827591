import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {variance} from '../../../styling';

export type AdaptiveModalActionsProps = {
  actions: React.ReactNode[];
};

export default observer(function AdaptiveModalActions({
  actions,
}: AdaptiveModalActionsProps) {
  const actions_ = actions.filter((_) => !!_);
  return (
    <ActionListView>
      {actions_.map((action, index) => (
        <View
          style={[index + 1 < actions_.length && styles.actionOffset]}
          key={index}>
          {action}
        </View>
      ))}
    </ActionListView>
  );
});

const ActionListView = variance(View)(() => ({
  root: {
    alignSelf: 'stretch',
  },
}));

const styles = StyleSheet.create({
  actionOffset: {
    marginBottom: 12,
  },
});
