import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalHeaderProps} from '../components/organisms/modal/AdaptiveModalHeader';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import FinishQuickStartView, {
  FinishQuickStartViewProps,
} from './FinishQuickStartView';

export type FinishQuickStartProps = AdaptiveModalHeaderProps &
  FinishQuickStartViewProps & {
    onModalClosed: () => void;
  };

export default observer<FinishQuickStartProps, AdaptiveModalRef>(
  forwardRef(function FinishQuickStart(props, ref) {
    const {onModalClosed, ...rest} = props;
    return (
      <AdaptiveModal
        ref={ref}
        enableContentAutoHeight
        onModalClosed={onModalClosed}
        modalWidth={ModalWidth.Small}>
        <FinishQuickStartView {...rest} />
      </AdaptiveModal>
    );
  }),
);
