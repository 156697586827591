import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import TotalPriceView from './TotalPriceView';
import {
  DoubleProposal,
  Proposal,
  SubscriptionCandidateKind,
} from '../ProposalsState';
import {ActivatorSubscriptionOffer} from '../InAppOffersService';
import {useRoot, useStrings} from '../Root/hooks';
import {useForcedContext} from '../context';
import PurchaseScreenContext from './context';
import {processProductOffer} from './processProductOffer';
import {processMinerSubscriptionProposal} from './processMinerSubscriptionProposal';
import {PurchaseTabsKind} from './PurchaseScreenState';
import {processActivatorSubscriptionOffer} from './processActivatorSubscriptionOffer';

export default observer(function GuardTotalPriceBinding() {
  const {
    state: {selectedDoubleProposal, selectedActivatorProposal, selectedTab},
  } = useForcedContext(PurchaseScreenContext);
  const {
    inAppOffers: {isLoading, error},
  } = useRoot();
  if (error || isLoading) {
    return null;
  }
  if (selectedTab === PurchaseTabsKind.Worker && selectedDoubleProposal) {
    return <DoubleTotalPriceBinding proposal={selectedDoubleProposal} />;
  } else if (
    selectedTab === PurchaseTabsKind.Reactivate &&
    selectedActivatorProposal
  ) {
    return <ActivatorTotalPriceBinding proposal={selectedActivatorProposal} />;
  }
  return null;
});

type DoubleTotalPriceBindingProps = {
  proposal: DoubleProposal;
};

const DoubleTotalPriceBinding = observer(
  ({proposal}: DoubleTotalPriceBindingProps) => {
    const {
      onPurchasePress,
      onImprovePress,
      onManagePress,
      state: {selectedPaymentMethod},
    } = useForcedContext(PurchaseScreenContext);
    const {subscription, product} = proposal;
    const handleBuyPress = useCallback(() => {
      if (product) {
        onPurchasePress(product.offer);
      }
    }, [onPurchasePress, product]);
    const handleSubscribePress = useCallback(() => {
      if (subscription?.kind === SubscriptionCandidateKind.Available) {
        onPurchasePress(subscription.offer);
      }
    }, [onPurchasePress, subscription]);

    const productOffer = product?.offer
      ? processProductOffer(product.offer, selectedPaymentMethod)
      : undefined;
    const subscriptionOffer = subscription?.offer
      ? processMinerSubscriptionProposal(subscription, selectedPaymentMethod)
      : undefined;
    const handleImprovePress = useCallback(() => {
      if (subscriptionOffer?.kind === SubscriptionCandidateKind.CanBeImproved) {
        onImprovePress(subscriptionOffer.purchaseId);
      }
    }, [onImprovePress, subscriptionOffer]);
    const handleManagePress = useCallback(() => {
      if (subscriptionOffer?.kind === SubscriptionCandidateKind.CanBeManaged) {
        onManagePress(subscriptionOffer.purchaseId);
      }
    }, [onManagePress, subscriptionOffer]);
    return (
      <TotalPriceView
        product={productOffer}
        subscription={subscriptionOffer}
        onMainOfferPurchasePress={handleBuyPress}
        onAdditionalOfferPurchasePress={handleSubscribePress}
        onImprovePress={handleImprovePress}
        onManagePress={handleManagePress}
      />
    );
  },
);

type ActivatorTotalPriceBindingProps = {
  proposal: Proposal<ActivatorSubscriptionOffer>;
};

const ActivatorTotalPriceBinding = observer(
  ({proposal}: ActivatorTotalPriceBindingProps) => {
    const {
      onPurchasePress,
      state: {selectedPaymentMethod},
    } = useForcedContext(PurchaseScreenContext);
    const handleSubscribePress = useCallback(
      () => onPurchasePress(proposal.offer),
      [onPurchasePress, proposal],
    );
    const strings = useStrings();
    const {currentActivatorSubscription} = useRoot();
    const submitButtonText =
      currentActivatorSubscription.current !== undefined
        ? strings['action.update']
        : strings['action.subscribe'];

    const computedOffer = processActivatorSubscriptionOffer(
      proposal.offer,
      selectedPaymentMethod,
    );
    if (!computedOffer) {
      return null;
    }
    return (
      <TotalPriceView
        product={undefined}
        subscription={computedOffer}
        onMainOfferPurchasePress={() => {}}
        onAdditionalOfferPurchasePress={handleSubscribePress}
        submitSubscriptionText={submitButtonText}
        hideGift
      />
    );
  },
);
