import {Platform} from 'react-native';
import {PaymentData} from '../InAppOffersService';
import {LocaleDict} from '../translation/LocaleStrings';

export default (payment: PaymentData, strings: LocaleDict) => {
  const {uiPricePerMonth, uiPrice, currency} = payment;
  if (Platform.OS === 'ios') {
    return `${uiPrice} ${currency}`;
  }
  return `${uiPricePerMonth} ${currency} / ${strings['common.shortMonth']}`;
};
