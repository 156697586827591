import {observer} from 'mobx-react-lite';
import React from 'react';
import {Pressable, View} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {variance} from '../styling';
import ActiveCircleSvg from '../assets/svg/colored/activeCircle.svg';
import {Body} from '../components';

export type LanguageListItemProps = {
  Icon: React.ComponentType<SvgProps>;
  title: string;
  onPress: () => void;
  active?: boolean;
};

export default observer(function LanguageListItem({
  Icon,
  title,
  active,
  onPress,
}: LanguageListItemProps) {
  return (
    <Container onPress={onPress}>
      <Lang>
        <Icon />
        <LangTitle type="body" weight="500" active={active}>
          {title}
        </LangTitle>
      </Lang>
      {active && <ActiveCircleSvg />}
    </Container>
  );
});

const Container = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 15,
  },
}));

const Lang = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const LangTitle = variance(Body)((theme) => ({
  root: {
    marginLeft: 8,
    color: theme.colors.uiSecondary,
    fontSize: 14,
    lineHeight: 18,
  },
  active: {
    color: theme.colors.primaryAttractive,
  },
}));
