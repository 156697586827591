import React from 'react';
import {Pressable, ViewProps} from 'react-native';
import {createStylesHook, useTheme} from '../styling';
import {sized} from '../Svg';
import CaretDownSvg from '../assets/svg/colorless/caretDown.svg';
import {Typography} from '../components';
import {observer} from 'mobx-react-lite';

export type TimezonePickerProps = ViewProps & {
  value: string;
  onPress: () => void;
};

export default observer(function TimezonePicker(props: TimezonePickerProps) {
  const {onPress, value, ...rest} = props;
  const styles = useStyles();
  const theme = useTheme();

  const renderArrow = () => (
    <CaretDownIcon
      style={{pointerEvents: 'none'}}
      width={10}
      height={5}
      color={theme.colors.primaryUIDirtyBlue}
    />
  );

  return (
    <Pressable {...rest} style={styles.timezone} onPress={onPress}>
      <Typography
        type="paragraph"
        weight="500"
        size="large"
        style={styles.text}>
        {value}
      </Typography>
      {renderArrow()}
    </Pressable>
  );
});

const useStyles = createStylesHook(() => ({
  timezone: {
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginRight: 5,
  },
}));

const CaretDownIcon = sized(CaretDownSvg, 8);
