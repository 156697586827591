import {
  WorkerEntity,
  WorkerType,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';

export function selectWorkersOnUpdate(
  workers: WorkerEntity[],
  userTimezoneMinutes: number,
) {
  let workerIds = new Set<string>();
  if (workers.length === 1) {
    workerIds.add(workers[0].id);
  } else {
    workerIds = new Set(
      Array.from(workers)
        .filter(
          (_) =>
            _.utc_offset === userTimezoneMinutes ||
            _.worker_type === WorkerType.Pool,
        )
        .map((_) => _.id),
    );
  }
  return workerIds;
}
