import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {variance} from '../styling';
import {Header, Typography} from '../components';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {SvgProps} from 'react-native-svg';
import {useStrings, useTemplates} from '../Root/hooks';

export type HeaderViewProps = {
  value: string;
  Icon?: React.ComponentType<SvgProps>;
};

export default observer(function HeaderView({value, Icon}: HeaderViewProps) {
  const strings = useStrings();
  const templates = useTemplates();
  return (
    <RootView>
      {Icon && (
        <CoinView>
          <Icon height="100%" width="100%" />
        </CoinView>
      )}
      <TextsView>
        <TitleTypography type="header" size="h4">
          {templates['payment.header.title']({value})}
        </TitleTypography>
        <DescriptionTypography type="body">
          {strings['payment.header.subtitle']}
        </DescriptionTypography>
      </TextsView>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    padding: 1,
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));

const TextsView = variance(View)((theme) => ({
  root: {
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        alignItems: 'flex-start',
      },
    }),
  },
}));

const CoinView = variance(View)((theme) => ({
  root: {
    width: 32,
    height: 32,
    padding: 3,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    // backgroundColor: theme.chroma(theme.colors.primaryWhite).alpha(0.05).hex(),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 0,
        width: 46,
        height: 46,
        borderRadius: 23,
        padding: 6,
        marginRight: 12,
      },
    }),
  },
}));

const TitleTypography = variance(Header)(() => ({
  root: {
    marginBottom: 8,
  },
}));

const DescriptionTypography = variance(Typography)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.primaryUIDirtyBlue,
  },
}));
