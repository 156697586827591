import {InAppOffers, OfferId, PaymentMethod} from '../InAppOffersService';
import {ErrorRepository} from '../ErrorRepository';
import {PurchaseDiscount} from '../PurchasePromoService';
import {Either, error, success} from '../fp';
import {
  CryptoPurchaseRequester,
  CryptoResponse,
  PurchaseRequesterError,
  RequestParams,
} from './PurchaseRequester';
import {when} from 'mobx';
import {GlobalError, UNKNOWN_ERROR} from '../Error';
import {PlanId} from '../ApiStore';
import {
  CreateOrderParams,
  CryptoOrderMeta,
} from '../ApiStore/entities/CreateOrderParams';
import {Configuration} from '../Configuration';
import {ConnectedClient} from '../ContextClient';
import {Currency, PurchaseId, Url} from '../units';
import translateResponseToMetamaskInfo from '../PaymentScreenContainer/translateResponseToMetamaskInfo';
import {GiftState} from '../Gift/Gift';
import {UtmUrlListener} from '../UtmUrlListener';

export default class CryptoPurchaseRequesterImpl
  implements CryptoPurchaseRequester
{
  constructor(
    protected readonly _root: {
      readonly purchaseDiscount: PurchaseDiscount;
      readonly inAppOffers: InAppOffers;
      readonly errorRepository: ErrorRepository;
      readonly connectedClient: ConnectedClient;
      readonly configuration: Configuration;
      readonly gift: GiftState;
      readonly utmUrl: UtmUrlListener;
    },
  ) {}

  private _getOfferInfoById(
    id: OfferId,
    payment: PaymentMethod,
  ): Either<GetOfferResponse, GlobalError> {
    const offer = this._root.inAppOffers.offerById.get(id);
    if (!offer) {
      return error(this._root.errorRepository.create({kind: UNKNOWN_ERROR}));
    }
    const payment_ = offer.payments.get(payment);
    if (!payment_) {
      return error(this._root.errorRepository.create({kind: UNKNOWN_ERROR}));
    }
    const response = {
      price: payment_.price,
      planId: offer.id,
      currency: payment_.currency,
      purchaseId: offer.purchaseId,
    };
    return success(response);
  }

  private async _requestPurchase(
    params: RequestParams,
  ): Promise<Either<CryptoResponse, PurchaseRequesterError>> {
    const {payment, offerId} = params;
    await when(() => this._root.inAppOffers.isLoadedIn);
    const info = this._getOfferInfoById(offerId, payment);
    if (!info.success) {
      return info;
    }
    const {price, currency, planId, purchaseId} = info.right;
    const request: CreateOrderParams = {
      data: {
        amount: String(price),
        currency: currency as Currency,
        plan_id: planId,
        success_url:
          `${this._root.configuration.values.webAppUrl}paymentCompleted` as Url,
        meta: await this._getMeta(params, purchaseId),
      },
    };
    const createOrder_ = await this._root.connectedClient.apply(
      'create_order',
      request,
    );
    if (!createOrder_.success) {
      return createOrder_;
    }
    return success({
      orderId: createOrder_.right.order.id,
      redirectUrl: createOrder_.right.order.redirect_url,
      metamaskIsPresented:
        translateResponseToMetamaskInfo(createOrder_.right) !== undefined,
    });
  }

  async request(
    params: RequestParams,
  ): Promise<Either<CryptoResponse, PurchaseRequesterError>> {
    const requestPurchase_ = await this._requestPurchase(params);
    if (!requestPurchase_.success) {
      return requestPurchase_;
    }
    return success(requestPurchase_.right);
  }

  private async _getMeta(params: RequestParams, purchaseId: PurchaseId) {
    let meta: CryptoOrderMeta = {
      utm: await this._root.utmUrl.getUtms(),
      pool_miner_id: params.poolMinerIdForRenew,
    };
    const gift = await this._root.gift.queryGift(purchaseId);
    if (gift) {
      meta = {
        ...meta,
        purchase: {
          gift: {
            pool_miner_config_id: gift.pool_miner_config?.id,
          },
        },
      };
    }

    return meta;
  }
}

type GetOfferResponse = {
  price: number;
  planId: PlanId;
  currency: string;
  purchaseId: PurchaseId;
};
