import React, {useCallback} from 'react';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {observer} from 'mobx-react-lite';
import {WithdrawScreen} from '../../WithdrawScreen';
import {AdSpot, Satoshi, satoshiToBitcoin} from '../../ApiStore';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from '../RootStack';
import {expr} from 'mobx-utils';
import {first} from 'lodash';
import {useRoot} from '../../Root/hooks';

export type WithdrawBindingProps = BottomTabBindingProps<'Withdraw'>;

export default observer((props: WithdrawBindingProps) => {
  const {navigation} = props;
  const {advertHelper} = useRoot();
  const goToPaymentSuccess = useCallback(
    (amount: Satoshi) =>
      navigation.navigate('PaymentSuccess', {amount: satoshiToBitcoin(amount)}),
    [navigation],
  );
  const getAdvertBanner = useCallback(
    () =>
      expr(() =>
        first(advertHelper.spotByBannerList?.get(AdSpot.WithdrawalMobile)),
      ),
    [advertHelper],
  );
  return (
    <WithdrawScreen
      getAdvertBanner={getAdvertBanner}
      goToPaymentSuccess={goToPaymentSuccess}
    />
  );
});

export const createWithdrawState = (): PartialState<
  NavigationState<RootParamList>
> => {
  return {
    index: 0,
    routes: [
      {
        name: 'Root',
        state: {
          index: 0,
          routes: [{name: 'Withdraw'}],
        },
      },
    ],
  };
};
