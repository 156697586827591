import {useRoot} from '../Root/hooks';
import {useCallback} from 'react';
import {ISODateString} from '../Time';
import {MinerOffer, PaymentMethod} from '../InAppOffersService';
import {OrderId} from '../ApiStore/units/order';
import {useErrorHandler} from '../ExceptionHandler';
import {Platform} from 'react-native';
import {PurchaseId} from '../units';
import usePurchasePoolMiner, {
  PurchasePoolMinerParams,
} from './usePurchasePoolMiner';

export type UpdatePoolMinerSubscriptionParams = {
  offer: MinerOffer;
  updatablePurchaseId: PurchaseId;
  payment: PaymentMethod;
  goToPoolMinerActivated(
    poolExpiredDate: ISODateString,
    poolHashrate: number,
  ): void;
  goToCryptoPayment(id: OrderId): void;
};

export default function useUpdatePoolMinerSubscription() {
  const errorHandler = useErrorHandler();
  const {inAppPurchaseManager} = useRoot();
  const purchasePoolMiner = usePurchasePoolMiner({errorHandler});
  return useCallback(
    async (params: UpdatePoolMinerSubscriptionParams) => {
      const {updatablePurchaseId, ...rest} = params;
      const requestParams: PurchasePoolMinerParams = {
        ...rest,
      };
      if (Platform.OS === 'android') {
        const purchases = await inAppPurchaseManager
          .getOriginalAvailablePurchases()
          .then((_) => (_.success ? _.right : []));
        requestParams.purchaseTokenAndroid = purchases.find(
          (_) => _.productId === updatablePurchaseId,
        )?.purchaseToken;
      }
      return purchasePoolMiner(requestParams);
    },
    [purchasePoolMiner, inAppPurchaseManager],
  );
}
