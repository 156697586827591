import {ReadonlyDeep} from 'type-fest';
import {
  SlotStatus,
  WorkerEntity,
} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useWorkerState} from '../../WorkerStateRegistry';
import {expr} from 'mobx-utils';
import isWorkerDisabled from '../checkers/isWorkerDisabled';
import isWorkerHot from '../checkers/isWorkerHot';
import isWorkerOffline from '../checkers/isWorkerOffline';
import isPoolMiner from '../checkers/isPoolMiner';

export type WorkerStatuses = {
  hot: boolean;
  disabled: boolean; // TODO Maybe only not managed flag
  inactive: boolean;
  /**
   * Only for pool miners
   */
  needActivate: boolean;
  offline: boolean;
  /**
   * Only for pool miners
   */
  expired: boolean | undefined;
  readonlySpeedControl: boolean;
  poolMiner: boolean;
};

export default function useWorkerStatuses(
  worker: ReadonlyDeep<WorkerEntity>,
): WorkerStatuses {
  const state = useWorkerState(worker.id);
  const offline = expr(() => isWorkerOffline(state));
  const disabled = expr(() => isWorkerDisabled(worker) || offline);
  const hot = expr(() => isWorkerHot(state));
  const poolMiner = expr(() => isPoolMiner(worker));
  const inactive = expr(
    () => (poolMiner && !state) || worker.slot_status === SlotStatus.Inactive,
  );
  const needActivate = expr(
    () => poolMiner && worker.slot_status === SlotStatus.Inactive,
  );
  const expired = expr(() => (poolMiner && worker.expired) ?? undefined);
  const readonlySpeedControl = expr(() => {
    if (inactive || expired) {
      return true;
    }
    if (!worker) {
      return false;
    }
    if (poolMiner) {
      if ((worker.schedulers ?? []).length > 0) {
        return true;
      }
    }
    return false;
  });
  return {
    disabled,
    inactive,
    hot,
    offline,
    expired,
    readonlySpeedControl,
    needActivate,
    poolMiner,
  };
}
