import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {PaymentCompletedScreen} from '../../PaymentCompletedScreen';
import {RootStackBindingProps} from './RootStackBindingProps';

export default observer(function PaymentCompletedBinding({
  navigation,
}: RootStackBindingProps<'PaymentCompleted'>) {
  const close = useCallback(() => {
    navigation.reset({
      index: 0,
      routes: [{name: 'Root', params: {screen: 'Dashboard'}}],
    });
  }, [navigation]);
  return <PaymentCompletedScreen onClose={close} />;
});
