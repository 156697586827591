import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import Slider, {SliderProps, SliderRef} from './Slider';
import {StyleSheet, View} from 'react-native';
import {range} from 'lodash';
import {variance} from '../../../styling';

export type RangeSliderProps = SliderProps & {};

export default observer<RangeSliderProps, SliderRef>(
  forwardRef(function RangeSlider(props, ref) {
    return (
      <View>
        <View style={styles.sliderView}>
          <Slider {...props} ref={ref} />
        </View>
        <View style={styles.dotList}>
          {range(50).map((_) => (
            <LineView key={_} />
          ))}
        </View>
      </View>
    );
  }),
);

const styles = StyleSheet.create({
  sliderView: {
    zIndex: 2,
  },
  dotList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -2,
  },
});

const LineView = variance(View)((theme) => ({
  root: {
    height: 4,
    width: 1,
    backgroundColor: theme.colors.uiAdditional3,
  },
}));
