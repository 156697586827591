import React from 'react';
import {observer} from 'mobx-react-lite';
import Typography from '../../components/Typography';
import {sized} from '../../Svg';
import LockSvg from '../../assets/svg/colorless/lock.svg';
import ChevronSvg from '../../assets/svg/colorless/chevronRight.svg';
import {useTheme} from '../../styling';
import {expr} from 'mobx-utils';
import {StyleSheet, View} from 'react-native';
import {useStrings} from '../../Root/hooks';
import {PressableOpacity} from '../../components';
import {PressableOpacityProps} from '../../components/PressableOpacity';

export type DemoErrorCardProps = PressableOpacityProps & {};

export default observer(function DemoErrorCard({
  style,
  ...rest
}: DemoErrorCardProps) {
  const theme = useTheme();
  const color = expr(() =>
    theme.select(theme.colors.uiMain, theme.colors.primaryError),
  );
  const backgroundColor = expr(() =>
    theme.select(theme.colors.primaryError, '#371B27'),
  );
  const strings = useStrings();
  return (
    <PressableOpacity style={[styles.root, {backgroundColor}, style]} {...rest}>
      <View style={styles.lock}>
        <LockIcon color={color} />
      </View>
      <Typography type="body" weight="500" style={[styles.text, {color}]}>
        {strings['dashboard.demoErrorCard.title']}
      </Typography>
      <View style={styles.chevron}>
        <ChevronRightIcon color={color} />
      </View>
    </PressableOpacity>
  );
});
const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
  },
  lock: {
    marginRight: 4,
  },
  chevron: {
    marginLeft: 6,
  },
  text: {
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
  },
});

const LockIcon = sized(LockSvg, 14, 17);
const ChevronRightIcon = sized(ChevronSvg, 6, 9);
