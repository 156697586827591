import {useLayoutEffect} from 'react';
import {useStrings} from '../Root/hooks';
import ScheduleState from './ScheduleState';
import {useNavigation} from '@react-navigation/native';

export default function useUnsavedDataWatcher(state: ScheduleState) {
  const strings = useStrings();
  const title =
    state.workers.length > 1
      ? strings['scheduler.scheduleGroup']
      : strings['scheduler.scheduleWorker'];
  const navigation = useNavigation();
  useLayoutEffect(() => {
    navigation.setOptions({
      title,
    });
  }, [navigation, title]);
}
