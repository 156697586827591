import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {SwitchDemoModeScreen} from '../../SwitchDemoModeScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';

export type SwitchDemoModeBindingProps =
  RootStackBindingProps<'SwitchDemoMode'>;

export default observer(function SwitchDemoModeBinding(
  props: SwitchDemoModeBindingProps,
) {
  const {navigation} = props;
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const onModalClosed = useCallback(async () => {
    await modalRef.current?.close();
    safelyGoBack();
  }, [modalRef, safelyGoBack]);
  const goToPlan = useCallback(async () => {
    await onModalClosed();
    navigation.navigate('Plan');
  }, [navigation, onModalClosed]);

  const goToAddDemoWorker = useCallback(async () => {
    await onModalClosed();
    navigation.navigate('LinkDemoWorker');
  }, [navigation, onModalClosed]);
  return (
    <SwitchDemoModeScreen
      ref={modalRef}
      goToPlan={goToPlan}
      goToAddDemoWorker={goToAddDemoWorker}
      onModalClosed={onModalClosed}
    />
  );
});
