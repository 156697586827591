import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ListRenderItem, StyleSheet, FlatListProps} from 'react-native';
import {COLORS as colorsMap} from '../ScheduleScreen/constant';
import ColorListItem from './ColorListItem';
import {Divider} from '../components/atoms';
import {AdaptiveFlatList} from '../components/templates/AdaptiveModal';

export type ColorListProps = {
  colors: typeof colorsMap;
  onSelect: (speed: number) => void;
};

type Item = [number, string];

export default observer(function ColorList(props: ColorListProps) {
  const {colors, onSelect} = props;
  const colorsArray = useMemo(() => Array.from(colors).reverse(), [colors]);

  const renderItem: ListRenderItem<Item> = useCallback(
    ({item}) => (
      <ColorListItem color={item[1]} speed={item[0]} onSelect={onSelect} />
    ),
    [onSelect],
  );

  return (
    <AdaptiveFlatList
      data={colorsArray}
      contentContainerStyle={styles.container}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={Divider}
    />
  );
});

const keyExtractor: FlatListProps<Item>['keyExtractor'] = (item) =>
  String(item[0]);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
});
