import React, {useMemo} from 'react';
import {Pressable, TouchableOpacity, View} from 'react-native';

import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import Svg, {SvgProps, Path} from 'react-native-svg';

import TableSpeedCell from './TableSpeedCell';
import {Hoverable} from 'react-native-web-hooks';
import {createStylesHook, useTheme} from '../styling';
import {sized} from '../Svg';
import CircleCloseSvg from '../assets/svg/colorless/circleClose.svg';
import ScheduleState from './ScheduleState';
import {Typography} from '../components';
import WorkAllTimeCheckbox from './WorkAllTimeCheckbox';
import Timezone from './Timezone';
import {ScrollView} from 'react-native-gesture-handler';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';

const days = Array.from({length: 7}, (_, index) => index);
const hours = Array.from({length: 24}, (_, index) => index);

export type WeekTableProps = {
  state: ScheduleState;
  onTimezonePress: () => void;
};

export default observer(function WeekTable(props: WeekTableProps) {
  const {state, onTimezonePress} = props;
  const theme = useTheme();
  const strings = useStrings();
  const styles = useStyles();
  const daysList = useMemo(
    () => [
      strings['calendar.shortDay.monday'],
      strings['calendar.shortDay.tuesday'],
      strings['calendar.shortDay.wednesday'],
      strings['calendar.shortDay.thursdat'],
      strings['calendar.shortDay.friday'],
      strings['calendar.shortDay.saturday'],
      strings['calendar.shortDay.sunday'],
    ],
    [strings],
  );

  return (
    <View style={styles.background}>
      <View style={styles.weekTableHeader}>
        <Timezone state={state} onTimezonePress={onTimezonePress} />
        <WorkAllTimeCheckbox
          active={state.allTimeActive}
          onPress={state.forceAllTime}
        />
      </View>
      <ScrollView
        horizontal
        style={styles.scroll}
        contentContainerStyle={styles.screenInnerContainer}
        nestedScrollEnabled>
        <View style={styles.root}>
          <View style={styles.content}>
            <View style={[styles.row]}>
              <TouchableOpacity
                accessibilityRole="button"
                activeOpacity={0.8}
                onPress={() => state.clear()}
                style={[
                  styles.dayNameCell,
                  styles.clearTable,
                  styles.topStartCell,
                ]}>
                <CloseIcon color={theme.colors.uiSecondary} />
              </TouchableOpacity>
              {hours.map((h) => (
                <Pressable
                  style={styles.cellPressable}
                  key={h}
                  onPress={() => state.fillPointVertical(h)}>
                  <Hoverable>
                    {(isHovered) => (
                      <View style={[styles.cell, styles.headerHourCell]}>
                        <Typography
                          type={'paragraph'}
                          weight={'bold'}
                          style={[
                            styles.hour,
                            isHovered && styles.hoveredHour,
                          ]}>
                          {h}
                        </Typography>
                      </View>
                    )}
                  </Hoverable>
                </Pressable>
              ))}
              <Pressable
                style={[
                  styles.cell,
                  styles.lastRightCell,
                  styles.lastFirstRightCell,
                  styles.topEndCell,
                ]}
              />
            </View>
            {days.map((d) => (
              <View style={styles.row} key={d}>
                <Pressable
                  onPress={() => state.fillPointHorizontal(d)}
                  style={styles.daysPressable}>
                  <Hoverable>
                    {(isHovered) => (
                      <View
                        style={[
                          styles.dayNameCell,
                          d === days.length - 1 && styles.bottomStartCell,
                        ]}>
                        <Typography
                          type={'body'}
                          weight={'bold'}
                          style={[
                            styles.dayName,
                            isHovered && styles.hoveredDayName,
                          ]}>
                          {daysList[d]}
                        </Typography>
                      </View>
                    )}
                  </Hoverable>
                </Pressable>

                {hours.map((h) => {
                  const hour = state.matrix[d][h];
                  return (
                    <TableSpeedCell
                      style={[styles.cell]}
                      key={h}
                      onPress={() => state.fillPoint(d, h)}
                      point={hour}
                    />
                  );
                })}
                <Pressable
                  onPress={() => {
                    state.fillPointHorizontal(d);
                  }}>
                  <Hoverable>
                    {(isHovered) => (
                      <View
                        style={[
                          styles.cell,
                          styles.lastRightCell,
                          d === days.length - 1 && styles.bottomEndCell,
                        ]}>
                        <CheckIcon
                          stroke={
                            isHovered
                              ? theme.colors.primaryAttractive
                              : theme.colors.uiSecondary
                          }
                        />
                      </View>
                    )}
                  </Hoverable>
                </Pressable>
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  background: {
    backgroundColor: theme.colors.uiMain,
  },
  scroll: {
    backgroundColor: theme.colors.uiMain,
  },
  screenInnerContainer: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
  root: {
    paddingVertical: 8,
    paddingBottom: 12,
    paddingHorizontal: 16,
    flex: 1,
  },
  content: {
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
  },
  cellPressable: {
    minWidth: 34,
    height: 34,
    width: '100%',
    flex: 1,
  },
  daysPressable: {
    minWidth: 65,
    flex: 1,
  },
  cell: {
    height: 34,
    minWidth: 34,
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    backgroundColor: theme.colors.uiMain,
  },
  headerHourCell: {
    backgroundColor: theme.colors.uiAdditional1,
    flex: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  dayNameCell: {
    minWidth: 65,
    flex: 1,
    height: 34,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    backgroundColor: theme.colors.uiAdditional1,
  },
  lastRightCell: {
    minWidth: 54,
  },
  lastFirstRightCell: {
    borderRightWidth: 0,
    borderBottomWidth: 0,
    backgroundColor: theme.colors.uiAdditional1,
    maxWidth: 54,
  },
  dayName: {
    letterSpacing: 0.04,
    textAlign: 'center',
  },
  clearTable: {
    borderColor: theme.colors.uiAdditional2,
    backgroundColor: theme.colors.uiAdditional2,
  },
  hoveredDayName: {
    color: theme.colors.primaryActive,
  },
  hour: {},
  hoveredHour: {
    color: theme.colors.primaryActive,
  },
  weekTableHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  topStartCell: {
    borderTopStartRadius: 8,
  },
  topEndCell: {
    borderTopEndRadius: 8,
  },
  bottomStartCell: {
    borderBottomStartRadius: 8,
  },
  bottomEndCell: {
    borderBottomEndRadius: 8,
  },
}));

const CloseIcon = sized(CircleCloseSvg, 15);

const CheckIcon = ({stroke = '#757575', ...rest}: SvgProps) => {
  return (
    <Svg width="13" height="9" viewBox="0 0 13 9" fill="none" {...rest}>
      <Path
        d="M1 4L5 8L12 1"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
