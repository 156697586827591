import {observer} from 'mobx-react-lite';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Typography} from '../components';
import {useStrings} from '../Root/hooks';

export default observer(function NotFound() {
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <Typography type="header" size="h3">
        {strings['workerDetails.workerNotFound']}
      </Typography>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
