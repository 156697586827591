import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {View, Image} from 'react-native';
import {Body, Header} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {Trial} from '../InAppOffersService';
import getHumanizeDuration from '../utils/getHumanizeDuration';
import {Millisecond} from '../Time';
import dayjs from 'dayjs';
import trialPeriodUnitToDayjsUnit from '../InAppOffersService/OfferManager/trialPeriodUnitToDayjsUnit';

export type QuickStartDiscountProps = {
  trial: Trial | null;
  description: string | undefined;
};

export default observer(function QuickStartDiscount({
  trial,
  description,
}: QuickStartDiscountProps) {
  const {translation} = useRoot();
  const strings = useStrings();
  const trialHumanizeDuration = trial
    ? getHumanizeDuration(
        dayjs
          .duration(trial.value, trialPeriodUnitToDayjsUnit(trial.unit))
          .asMilliseconds() as Millisecond,
        translation.localeTag,
        ['d'],
      )
    : undefined;
  return (
    <View>
      <Ticket>
        <Bg source={require('../assets/discountTicket.png')} />
        <View>
          <BlueText type="header" size="h3" weight="700">
            {trialHumanizeDuration
              ? trialHumanizeDuration
              : strings['quickStart.discount.days']}
          </BlueText>
          <OrangeText type="header" size="h2" weight="700">
            {trial
              ? strings['quickStart.discount.free.trial']
              : strings['quickStart.discount.free']}
          </OrangeText>
        </View>
      </Ticket>
      {description !== undefined && (
        <Subtitle type="body">{description}</Subtitle>
      )}
    </View>
  );
});

const Ticket = variance(View)(() => ({
  root: {
    width: 275,
    height: 95,
    position: 'relative',
    overflow: 'visible',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Bg = variance(Image)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const Subtitle = variance(Body)((theme) => ({
  root: {
    fontSize: 10,
    lineHeight: 12,
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    color: theme.colors.primaryUIDirtyBlue,
    maxWidth: 205,
  },
}));

const BlueText = variance(Header)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.primaryBlack,
    textTransform: 'uppercase',
  },
}));

const OrangeText = variance(Header)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.primaryNew,
    textTransform: 'uppercase',
  },
}));
