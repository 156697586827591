import {Platform} from 'react-native';

import AndroidFileSystemImpl from './AndroidFileSystemImpl';
import {FileSystem} from './FileSystem';
import IosFileSystemImpl from './IosFileSystemImpl';
import WebFileSystemImpl from './WebFileSystemImpl';

export default class FileSystemFactoryImpl {
  create() {
    return Platform.select<FileSystem>({
      ios: new IosFileSystemImpl(),
      android: new AndroidFileSystemImpl(),
      default: new WebFileSystemImpl(),
    });
  }
}
