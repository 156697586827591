import React, {PropsWithChildren} from 'react';
import {View, ViewProps, StyleSheet} from 'react-native';
import AnimatableFan from '../components/AnimatableFan';

export type LoaderWrapperProps = ViewProps & {
  loading: boolean;
};

export const LoaderWrapper = ({
  children,
  loading,
  ...rest
}: PropsWithChildren<LoaderWrapperProps>) => {
  const opacity = loading ? 0 : 1;
  return (
    <View {...rest}>
      <View style={{opacity}}>{children}</View>
      {loading && (
        <View style={styles.loaderView}>
          <AnimatableFan />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loaderView: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});
