import {observer} from 'mobx-react-lite';
import React from 'react';
import {
  FETCH_OFFERS_ERROR,
  FETCH_OFFERS_NO_DATA_ERROR,
  FETCH_OFFERS_SERVER_ERROR,
  FETCH_OFFERS_STORE_ERROR,
  GlobalError,
} from '../Error';
import {
  ServerEmptyOrErrorCard,
  UnauthorizedStoreErrorCard,
} from '../components/organisms/purchase';
import {variance} from '../styling';
import {View} from 'react-native';

export type PurchaseErrorViewProps = {
  error: GlobalError;
};

export default observer(function PurchaseErrorView({
  error,
}: PurchaseErrorViewProps) {
  return error?.kind === FETCH_OFFERS_ERROR &&
    error.type === FETCH_OFFERS_STORE_ERROR ? (
    <RootView>
      <UnauthorizedStoreErrorCard />
    </RootView>
  ) : error?.kind === FETCH_OFFERS_ERROR &&
    (error.type === FETCH_OFFERS_SERVER_ERROR ||
      error.type === FETCH_OFFERS_NO_DATA_ERROR) ? (
    <RootView>
      <ServerEmptyOrErrorCard error={error} />
    </RootView>
  ) : null;
});

const RootView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
