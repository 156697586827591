import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, ViewProps} from 'react-native';
import ColoredBanner, {
  DefaultButton,
  LIGHT_BLUE_BACKGROUND,
  QUESTION_ICON,
  ShadowColor,
} from '../../molecules/ColoredBanner';
import {useRoot, useStrings} from '../../../Root/hooks';
import {useOpenLink} from '../../../Links';

export type LearnToUseFarmBannerProps = ViewProps & {};

export default observer(function LearnToUseFarmBanner({
  ...rest
}: LearnToUseFarmBannerProps) {
  const {
    configuration: {values},
  } = useRoot();
  const openFAQ = useOpenLink(values.faq);
  const strings = useStrings();
  return (
    <ColoredBanner
      title={strings['banner.lernToUseFarm.title']}
      text={strings['banner.lernToUseFarm.text']}
      background={LIGHT_BLUE_BACKGROUND}
      shadowColor={ShadowColor.Blue}
      icon={QUESTION_ICON}
      descriptionVisible={(layout) => (layout ? layout.width > 365 : false)}
      textStyle={styles.text}
      actions={[
        <DefaultButton
          onPress={openFAQ}
          title={strings['banner.lernToUseFarm.button']}
        />,
      ]}
      {...rest}
    />
  );
});

const styles = StyleSheet.create({
  text: {
    maxWidth: 320,
  },
});
