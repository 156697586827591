import BaseRenewAccessScreenStateImpl from './BaseRenewAccessScreenStateImpl';
import {InAppOffers, PaymentMethod} from '../InAppOffersService';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {ProposalsState} from '../ProposalsState';
import {makeObservable} from 'mobx';

export default class MobileRenewAccessScreenStateImpl extends BaseRenewAccessScreenStateImpl {
  constructor(
    protected readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly proposalsState: ProposalsState;
      readonly inAppOffers: InAppOffers;
    },
    readonly workerId: string,
  ) {
    super(_root, workerId);
    makeObservable(this);
  }

  selectPaymentMethod() {
    throw new Error('Not available on this platform');
  }

  get selectedPaymentMethod(): PaymentMethod {
    return PaymentMethod.InApp;
  }
}
