import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {ScrollView, View} from 'react-native';
import SadSvg from '../assets/svg/colored/sadFace.svg';
import {Header, Paragraph} from '../components';
import {sized} from '../Svg';
import {QuickStartListItemType} from '../QuickStartScreen/types';
import QuickStartListItem from '../QuickStartScreen/QuickStartListItem';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import {QuickStartOffer} from '../QuickStartService';

export type FinishQuickStartViewProps = {
  selectedItem: QuickStartListItemType | undefined;
  maxItem: QuickStartListItemType;
  onContinue: () => void;
  onBuyProfitablePress: () => void;
  trial: QuickStartOffer['trial'];
};

export default observer(function FinishQuickStartView(
  props: FinishQuickStartViewProps,
) {
  const {selectedItem, maxItem, onBuyProfitablePress, onContinue, trial} =
    props;
  const strings = useStrings();
  return (
    <Container contentContainerStyle={{flexGrow: 1}}>
      <SadIconContainer>
        <SadIcon />
      </SadIconContainer>
      <Title type="header" weight="700" size="h4">
        {strings['finishQuickStart.title']}
      </Title>
      <Text type="paragraph" weight="500">
        {trial
          ? strings['finishQuickStart.description.trial']
          : strings['finishQuickStart.description']}
      </Text>
      {selectedItem !== undefined && (
        <QuickStartListItem hideCheck {...selectedItem} />
      )}
      <Btn onPress={onContinue} offsetBottom>
        {strings['finishQuickStart.button.keep']}
      </Btn>
      <QuickStartListItem hideCheck {...maxItem} />
      <Btn variant={ButtonVariant.Primary} onPress={onBuyProfitablePress}>
        {strings['finishQuickStart.button.earn']}
      </Btn>
    </Container>
  );
});

const SadIcon = sized(SadSvg, 48, 48);

const SadIconContainer = variance(View)(() => ({
  root: {
    width: 48,
    height: 48,
    marginBottom: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Container = variance(ScrollView)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingBottom: 24,
    paddingTop: 28,
  },
}));

const Title = variance(Header)(() => ({
  root: {
    textAlign: 'center',
    marginBottom: 8,
  },
}));

const Text = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 36,
    maxWidth: 296,
  },
}));

const Btn = variance(AppButton)(() => ({
  root: {
    marginTop: -8,
  },
  offsetBottom: {
    marginBottom: 24,
  },
}));
