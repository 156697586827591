import {InAppPurchaseManager, PurchaseResult} from '../../InAppPurchaseManager';
import {ApiStore} from '../../ApiStore';
import {InAppOffers} from '../../InAppOffersService';
import {ErrorRepository} from '../../ErrorRepository';
import {PendingPurchasesResolver} from '../../PendingPurchasesResolver';
import {PurchaseDiscount} from '../../PurchasePromoService';
import BaseIapRequesterImpl from './BaseIapRequesterImpl';
import {GiftState} from '../../Gift/Gift';
import {UtmUrlListener} from '../../UtmUrlListener';

export default class SubIapRequesterImpl extends BaseIapRequesterImpl {
  constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly gift: GiftState;
      readonly utmUrl: UtmUrlListener;
    },
  ) {
    super(_root);
  }

  protected requestProduct(): Promise<PurchaseResult> {
    throw 'Not implemented on this platform';
  }

  protected requestSubscription(): Promise<PurchaseResult> {
    throw 'Not implemented on this platform';
  }
}
