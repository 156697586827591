import React from 'react';
import NoDataSvg from '../assets/svg/colorless/noData.svg';
import {View, ViewProps} from 'react-native';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {Header} from './Typography';

export type NoDataProps = ViewProps;

const NoData = observer(({style, ...rest}: NoDataProps) => {
  const strings = useStrings();
  return (
    <Container style={style} {...rest}>
      <NoDataSvg />
      <Title type="header" size="h3" weight="500">
        {strings['common.noData']}
      </Title>
    </Container>
  );
});

export default NoData;

export const ScreenFluidNoData = observer(() => {
  return (
    <Container fluid>
      <NoData />
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  fluid: {
    flex: 1,
  },
}));

const Title = variance(Header)(() => ({
  root: {
    marginTop: 12,
  },
}));
