import React from 'react';
import {View, Image, Pressable, StyleSheet} from 'react-native';
import {observer} from 'mobx-react-lite';
import Badge from './Badge';
import OpenLinkSvg from '../../assets/svg/colorless/openLink.svg';
import {AppButton, ButtonSize, ButtonVariant} from '../../components/AppButton';
import {useRoot, useStrings} from '../../Root/hooks';
import {Header} from '../../components/Typography';
import {useTheme, variance} from '../../styling';
import {Hoverable} from 'react-native-web-hooks';
import {Product} from '../../Products/Product';
import {sized} from '../../Svg';
import {Url} from '../../units';

export type ListItemProps = {
  item: Product;
  index: number;
};

const ListItem = observer(({item, index}: ListItemProps) => {
  const {translation} = useRoot();
  const theme = useTheme();
  const strings = useStrings();
  const {location} = useRoot();
  const openPressButton = () =>
    location.open(
      item.deepLink || item.googleLink || item.webLink || ('/' as Url),
    );
  const onPressHeader = () => location.open(item.link);
  return (
    <Root>
      <Hoverable>
        {(isHovered) => (
          <Pressable onPress={onPressHeader}>
            <TopComponent>
              <Image
                source={{uri: item.topIcon}}
                style={styles.headerItemIcon}
              />
              <Header
                type="header"
                size="h3"
                style={[
                  styles.headerItemTitle,
                  isHovered && {color: theme.colors.primaryAttractive},
                ]}>
                {item.title}
              </Header>
              <View style={styles.headerOpenLinkIconView}>
                <OpenLinkIcon
                  color={
                    isHovered
                      ? theme.colors.primaryAttractive
                      : theme.colors.uiSecondary
                  }
                />
              </View>
            </TopComponent>
          </Pressable>
        )}
      </Hoverable>
      <ContentBlock>
        <TextBlock type="header" size="h5" weight="500">
          {item.text[translation.localeTag]}
        </TextBlock>

        {item.badge && (
          <Badge
            text={item.badge.text[translation.localeTag]}
            type={index % 2 === 0 ? 'blue' : 'green'}
          />
        )}
      </ContentBlock>
      <BottomComponent>
        {(item.googleLink || item.deepLink) && (
          <>
            <AppButton
              size={ButtonSize.Default}
              onPress={openPressButton}
              variant={ButtonVariant.Primary}>
              {strings['productsScreen.learnMore']}
            </AppButton>
          </>
        )}
      </BottomComponent>
    </Root>
  );
});

const OpenLinkIcon = sized(OpenLinkSvg, 18);

const styles = StyleSheet.create({
  headerItemIcon: {
    width: 32,
    height: 32,
    marginRight: 12,
  },
  headerOpenLinkIconView: {
    marginLeft: 'auto',
    paddingLeft: 10,
  },
  headerItemTitle: {
    flex: 1,
  },
});

const Root = variance(View)((theme) => ({
  root: {
    borderColor: theme.colors.uiAdditional1,
    borderWidth: 1,
    borderRadius: 12,
    flexGrow: 1,
  },
}));
const TopComponent = variance(View)((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
    paddingRight: 18,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.uiAdditional1,
  },
}));
const ContentBlock = variance(View)(() => ({
  root: {
    padding: 12,
    paddingTop: 8,
    gap: 12,
    flexGrow: 1,
  },
}));
const TextBlock = variance(Header)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    lineHeight: 24,
    marginBottom: 'auto',
  },
}));
const BottomComponent = variance(View)((theme) => ({
  root: {
    padding: 12,
    marginTop: 'auto',
    borderTopWidth: 1,
    borderTopColor: theme.colors.uiAdditional1,
  },
}));

export default ListItem;
