import React from 'react';
import {observer} from 'mobx-react-lite';
import {sized} from '../../Svg';
import YellowFlash from '../../assets/svg/colored/yellowFlash.svg';
import QuestionSvg from '../../assets/svg/colorless/question.svg';
import {useTheme, variance} from '../../styling';
import {View} from 'react-native';
import {Header, Mono, PressableOpacity} from '../../components';
import {useStrings} from '../../Root/hooks';
import {Subscription} from '../../farmApi';

export type TotalPoolMinersSectionProps = {
  subscription: Subscription | undefined;
  onQuestionPress(): void;
};

export default observer(function TotalPoolMinersSection({
  subscription,
  onQuestionPress,
}: TotalPoolMinersSectionProps) {
  const totalPoolMinerWorkers = subscription?.poolMiners ?? 0;
  const strings = useStrings();
  const theme = useTheme();
  return (
    <RootView>
      <TopLeftView>
        <YellowFlashIcon />
        <TopTitle type="header" size="h4">
          {strings['purchase.poolMiners.active']}
        </TopTitle>
        <StyledPressableOpacity onPress={onQuestionPress}>
          <QuestionIcon color={theme.colors.primaryUIDirtyBlue} />
        </StyledPressableOpacity>
      </TopLeftView>
      <TopCountView>
        <DotView />
        <CountText type="mono">{totalPoolMinerWorkers}</CountText>
      </TopCountView>
    </RootView>
  );
});

const YellowFlashIcon = sized(YellowFlash, 24, 24);
const QuestionIcon = sized(QuestionSvg, 18);

const RootView = variance(View)((theme) => ({
  root: {
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    paddingHorizontal: 15,
    paddingVertical: 12,
    backgroundColor: theme.colors.uiMain,
    paddingBottom: 32,
    marginBottom: -20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const TopLeftView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TopTitle = variance(Header)(() => ({
  root: {
    marginLeft: 12,
    marginRight: 5,
    marginBottom: 2,
  },
}));

const StyledPressableOpacity = variance(PressableOpacity)(() => ({
  root: {
    padding: 5,
    borderRadius: 5,
  },
}));

const TopCountView = variance(View)((theme) => ({
  root: {
    borderRadius: 64,
    backgroundColor: theme.colors.backgroundGround,
    paddingHorizontal: 14,
    paddingVertical: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const DotView = variance(View)((theme) => ({
  root: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: theme.colors.primarySuccess,
    marginRight: 4,
  },
}));

const CountText = variance(Mono)(() => ({
  root: {
    fontSize: 18,
    lineHeight: 23,
  },
}));
