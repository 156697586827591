import {action, makeObservable, observable, runInAction} from 'mobx';
import _ from 'lodash';
import CheckboxTreeState from '../NotificationsSettingsScreen/CheckboxTreeState';
import {
  FarmLogVariant,
  GetFarmLogSettingsResponse,
  SetFarmLogSettingsRequest,
} from './entities';
import {
  NormalizeNodeList,
  Notification,
  NotificationSettingsState,
} from './NotificationSettingsState';
import {SettingsInitialState} from './SettingsInitialState';
import {CommonError, LogSettings} from '../ApiStore';
import {ConnectedClient} from '../ContextClient';
import {Either, success} from '../fp';
import {DefaultError} from '../JsonRpc';

export default class NotificationSettingsStateImpl
  implements NotificationSettingsState
{
  @observable private _isLoading: boolean = true;
  @observable private _isLoadedIn: boolean = false;
  @observable.ref private _state: NormalizeNodeList;

  constructor(
    private readonly _root: {
      readonly connectedClient: ConnectedClient;
    },
  ) {
    makeObservable(this);
    this._state = this._normalizeNodes(SettingsInitialState);
  }

  get isLoading() {
    return this._isLoading;
  }

  get isLoadedIn() {
    return this._isLoadedIn;
  }

  get state() {
    return this._state;
  }

  fetchLogSettings = async (): Promise<
    Either<void, CommonError | DefaultError>
  > => {
    if (!this._isLoadedIn) {
      runInAction(() => {
        this._isLoading = true;
        this._isLoadedIn = false;
      });
    }

    const response = await this._root.connectedClient.call('get_log_settings');
    if (!response.success) {
      return response;
    }
    if (response.success && response.right) {
      const logSettings = response.right as GetFarmLogSettingsResponse;
      const settings = logSettings.settings;
      const state = this._normalizeNodes(SettingsInitialState);
      let result = _.cloneDeep(state);
      if (settings.exclude) {
        result = this.tree.recursiveChangeDeepNode(0, result, false);
      } else {
        const groups = settings.groups;
        if (groups === undefined) {
          return success(undefined);
        }
        (Object.keys(groups) as FarmLogVariant[]).forEach((g) => {
          const group = groups[g];
          if (group !== undefined) {
            const groupExclude = group.exclude;
            if (groupExclude) {
              result = this.tree.recursiveChangeDeepNode(g, result, false);
            }
            const messages = group.exclude_messages ?? [];
            messages.forEach((m) => {
              result = this.tree.recursiveChangeDeepNode(m, result, false);
            });
          }
        });
      }
      runInAction(() => (this._state = result));
    }
    if (!this._isLoadedIn) {
      runInAction(() => {
        this._isLoading = false;
        this._isLoadedIn = true;
      });
    }
    return success(undefined);
  };

  tree = new CheckboxTreeState(this);

  @action.bound replaceState(s: NormalizeNodeList) {
    this._state = s;
  }

  private _normalizeNodes = (nodes: Notification[]): NormalizeNodeList => {
    return <NormalizeNodeList>_.keyBy(nodes, 'id');
  };

  updateOnServer = () => {
    let settings: SetFarmLogSettingsRequest['settings'] = {
      exclude: false,
      groups: {},
    };
    if (!this._state[FarmLogVariant.ShowAll].value) {
      settings = {
        exclude: true,
        groups: {},
      };
    } else {
      const nodeList = _.values(this._state).filter(
        (n) => n.id !== FarmLogVariant.ShowAll && !n.value,
      );
      const children = nodeList.filter((n) => n.childrenIdList === undefined);
      children.forEach((c) => {
        const parentId = c.parentId;
        if (parentId === undefined) {
          return;
        }
        const parentNode = this._state[parentId];
        if (parentNode.value) {
          const excludeMessages =
            settings.groups[parentId]?.exclude_messages ?? [];
          settings.groups[parentId] = {
            exclude: false,
            exclude_messages: [...excludeMessages, c.id],
          };
        } else {
          settings.groups[parentId] = {
            exclude: true,
            exclude_messages: [],
          };
        }
      });
    }
    return this._root.connectedClient.call('set_log_settings', {
      settings: settings as LogSettings,
    });
  };

  private _reset() {
    runInAction(() => {
      this._isLoadedIn = false;
      this._isLoading = false;
    });
  }

  reset = () => this._reset();
}
