import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useForcedContext} from '../context';
import {WorkerDetailsContext} from './context';
import {variance} from '../styling';
import WorkerCpusInfo from '../components/organisms/worker/WorkerCpusInfo';
import WorkerDescription from '../components/organisms/worker/WorkerDescription';
import useGetIsLarge from '../DashboardScreen/hooks/useGetIsLarge';
import {expr} from 'mobx-utils';

export type ConfigurationProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function Configuration({worker}: ConfigurationProps) {
  const {goToPoolActivator, goToRenew} = useForcedContext(WorkerDetailsContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  return (
    <RootView large={isLarge}>
      <DescriptionView>
        <WorkerDescription worker={worker} />
      </DescriptionView>
      <WorkerCpusInfo
        renewButtonVisible={false}
        worker={worker}
        onActivePress={goToPoolActivator}
        onRenewPress={goToRenew}
      />
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {},
  large: {
    maxWidth: 400,
    flex: 6,
  },
}));

const DescriptionView = variance(View)(() => ({
  root: {
    paddingVertical: 12,
  },
}));
