import {
  EventType,
  HeadlessLocalNotifications,
  LocalNotificationContext,
  LocalNotificationId,
} from './HeadlessLocalNotifications';
import {Json} from '../Json';
import {Analytics} from '../Analytics';
import {Location} from '../Location';
import BaseWebHeadlessLocalNotificationsImpl from './BaseWebHeadlessLocalNotificationsImpl';
import {HeadlessMessagingHelper, MessageContext} from '../Messaging';
import {Either, error, success} from '../fp';
import {LocalNotifications} from './LocalNotifications';
import {BusImpl, Service} from '../structure';
import NotificationDisplayMethodError from './NotificationDisplayMethodError';

export default class WebHeadlessLocalNotificationsImpl
  extends BaseWebHeadlessLocalNotificationsImpl
  implements HeadlessLocalNotifications, LocalNotifications, Service
{
  constructor(
    protected readonly _root: {
      readonly json: Json;
      readonly analytics: Analytics;
      readonly location: Location;
      readonly headlessMessagingHelper: HeadlessMessagingHelper;
    },
  ) {
    super(_root);
  }

  public income = new BusImpl<LocalNotificationContext>();

  async scheduleNotification(
    context: MessageContext,
  ): Promise<Either<void, unknown>> {
    const result_ = await super.scheduleNotification(context);
    if (
      result_.success ||
      !(result_.left instanceof NotificationDisplayMethodError)
    ) {
      return result_;
    }
    const data_ = await this._wrapContext(context);
    if (!data_.success) {
      return data_;
    }
    try {
      const args = this._getNotificationArguments(context, data_.right, false);
      const notification = new window.Notification(...args);
      notification.addEventListener('click', async (event) => {
        event.preventDefault();
        const _context: LocalNotificationContext = {
          id: notification.tag as LocalNotificationId | undefined,
          type: EventType.Press,
          data: data_.right,
        };
        this.income.send(_context);
        await this.handle(_context);
      });
      notification.addEventListener('close', async () => {
        const _context: LocalNotificationContext = {
          id: notification.tag as LocalNotificationId | undefined,
          type: EventType.Dismissed,
          data: data_.right,
        };
        this.income.send(_context);
        await this.handle(_context);
      });
      notification.addEventListener('show', async () => {
        const _context: LocalNotificationContext = {
          id: notification.tag as LocalNotificationId | undefined,
          type: EventType.Delivered,
          data: data_.right,
        };
        this.income.send(_context);
        await this.handle(_context);
      });
    } catch (raw) {
      return error(raw);
    }
    return success(undefined);
  }

  protected _getRegistration(): Promise<ServiceWorkerRegistration> {
    return window.navigator.serviceWorker.ready;
  }

  subscribe() {}
}
