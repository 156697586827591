import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useForcedContext} from '../context';
import {WorkerDetailsContext} from './context';
import {Divider} from '../components/atoms';
import WorkerPowerSlider from '../components/organisms/worker/WorkerPowerSlider';
import {variance} from '../styling';
import useGetIsLarge from '../DashboardScreen/hooks/useGetIsLarge';
import {expr} from 'mobx-utils';

export type PowerSliderProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function PowerSlider({worker}: PowerSliderProps) {
  const {goToPoolMinerSchedulerWarning, onWorkerSpeedChanged} =
    useForcedContext(WorkerDetailsContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  return (
    <RootView large={isLarge}>
      {isLarge && (
        <View>
          <Divider vertical style={styles.verticalDivider} />
        </View>
      )}
      <ContentView large={isLarge}>
        <WorkerPowerSlider
          visibleTitle
          onValueChanged={onWorkerSpeedChanged}
          worker={worker}
          goToPoolMinerSchedulerWarning={goToPoolMinerSchedulerWarning}
        />
      </ContentView>
      <View>
        <Divider
          vertical={isLarge}
          style={isLarge ? styles.verticalDivider : styles.horizontalDivider}
        />
      </View>
    </RootView>
  );
});

const styles = StyleSheet.create({
  verticalDivider: {
    flex: 1,
    height: '100%',
  },
  horizontalDivider: {
    width: '100%',
    flex: 1,
  },
});

const RootView = variance(View)(() => ({
  root: {},
  large: {
    flexDirection: 'row',
    flex: 5,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    flexGrow: 1,
    paddingVertical: 12,
  },
  large: {},
}));
