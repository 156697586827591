import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
  Representation,
} from '../../templates';
import useSafePaddingBottom from '../../templates/AdaptiveModal/useSafePaddingBottom';
import {useTheme, variance} from '../../../styling';
import {StyleSheet, View} from 'react-native';
import PressableOpacity from '../../PressableOpacity';
import {Typography} from '../../index';
import {Divider} from '../../atoms';
import {SvgProps} from 'react-native-svg';
import {
  AdaptiveModalHeader,
  AdaptiveModalHeaderProps,
} from './AdaptiveModalHeader';
import useRepresentation from '../../templates/AdaptiveModal/useRepresentation';

export const ADAPTIVE_ACTION_LIST_MODAL_BREAKPOINT = 'sm';

export enum ActionVariant {
  Default,
  Error,
}

export type Action = {
  title: string;
  text?: string;
  Icon?: React.ComponentType<SvgProps>;
  onPress: () => void;
  disabled?: boolean;
  variant?: ActionVariant;
  label?: string;
};

export type ActionList = Action[];

export type AdaptiveActionListModalProps = AdaptiveModalHeaderProps & {
  onModalClosed: () => void;
  actionList: ActionList;
};

export default observer<AdaptiveActionListModalProps, AdaptiveModalRef>(
  forwardRef(function AdaptiveActionListModal(props, ref) {
    return (
      <AdaptiveModal
        ref={ref}
        adaptiveBreakpoint={ADAPTIVE_ACTION_LIST_MODAL_BREAKPOINT}
        modalWidth={ModalWidth.Small}
        onModalClosed={props.onModalClosed}
        enableContentAutoHeight>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  ({
    actionList,
    IconSvg,
    IconSvgModalRep,
    title,
    description,
  }: AdaptiveActionListModalProps) => {
    const paddingBottom = useSafePaddingBottom(16);
    const {colors} = useTheme();
    const representation = useRepresentation();
    const isModal = representation === Representation.Modal;
    return (
      <RootView style={{paddingBottom}}>
        <AdaptiveModalHeader
          style={styles.header}
          iconViewStyle={[isModal && styles.modalIconView]}
          IconSvg={IconSvg}
          IconSvgModalRep={IconSvgModalRep}
          title={title}
          description={description}
        />
        <View>
          {actionList.map((_, index) => {
            const isErrorVariant = _.variant === ActionVariant.Error;
            const iconColor = isErrorVariant
              ? colors.primaryError
              : colors.uiSecondary;
            const ActionIcon = _.Icon;
            return (
              <View key={index}>
                <View style={_.disabled && styles.disabled}>
                  <PressableOpacity
                    disabled={_.disabled}
                    onPress={_.onPress}
                    activeOpacity={0.8}
                    style={styles.item}>
                    {ActionIcon && (
                      <View style={styles.iconView}>
                        <ActionIcon color={iconColor} />
                      </View>
                    )}
                    <TitleText type="body" weight="500" error={isErrorVariant}>
                      {_.title}
                    </TitleText>
                    {!!_.label && (
                      <Label type="body" weight="500">
                        {_.label}
                      </Label>
                    )}
                  </PressableOpacity>
                </View>
                {index + 1 < actionList.length && <Divider />}
              </View>
            );
          })}
        </View>
      </RootView>
    );
  },
);

const RootView = variance(View)(() => ({
  root: {
    padding: 16,
  },
  modal: {
    padding: 20,
  },
}));

const Label = variance(Typography)((theme) => ({
  root: {
    textTransform: 'uppercase',
    paddingVertical: 2,
    paddingHorizontal: 6,
    color: theme.colors.primaryWhite,
    backgroundColor: theme.colors.primaryActive,
    marginLeft: 12,
    fontSize: 10,
    lineHeight: 12,
    borderRadius: 4,
  },
}));

const TitleText = variance(Typography)((theme) => ({
  root: {
    fontSize: 16,
  },
  error: {
    color: theme.colors.primaryError,
  },
}));

const styles = StyleSheet.create({
  header: {
    paddingTop: 16,
    paddingBottom: 10,
  },
  disabled: {
    opacity: 0.3,
  },
  item: {
    flexDirection: 'row',
    paddingVertical: 5,
    minHeight: 44,
    alignItems: 'center',
  },
  iconView: {
    width: 18,
    height: 18,
    marginRight: 9,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalIconView: {
    marginBottom: 12,
  },
});
