import React, {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import Svg, {Defs, Path, Stop, RadialGradient} from 'react-native-svg';
import {nanoid} from 'nanoid';

export type TargetPuzzleProps = {
  targetX: number;
  targetY: number;
};

export default observer(({targetX, targetY}: TargetPuzzleProps) => {
  const id = useRef(nanoid());
  return (
    <View
      style={{
        position: 'absolute',
        left: targetX,
        top: targetY,
      }}>
      <Svg width="58" height="58" viewBox="0 0 58 58" fill="none">
        <Path
          d="M22.9302 0.5C27.1237 0.5 30.5233 3.89952 30.5233 8.09302C30.5233 8.8552 30.4083 9.58889 30.1968 10.281C30.0302 10.8266 30.1315 11.4048 30.4119 11.8486C30.6951 12.2967 31.1845 12.6395 31.8 12.6395H44.7815C45.1013 12.6395 45.3605 12.8987 45.3605 13.2185V26.2C45.3605 26.8155 45.7033 27.3049 46.1515 27.5881C46.5953 27.8684 47.1734 27.9698 47.719 27.8031C48.4111 27.5917 49.1448 27.4767 49.907 27.4767C54.1005 27.4767 57.5 30.8763 57.5 35.0698C57.5 39.2633 54.1005 42.6628 49.907 42.6628C49.1448 42.6628 48.4111 42.5478 47.719 42.3364C47.1734 42.1697 46.5952 42.2711 46.1514 42.5515C45.7033 42.8346 45.3605 43.324 45.3605 43.9395V56.9211C45.3605 57.2408 45.1013 57.5 44.7815 57.5H31.3726C31.1366 57.5 30.9155 57.3561 30.774 57.0829C30.6305 56.8056 30.6117 56.4661 30.7346 56.2003C31.2406 55.1062 31.5233 53.8881 31.5233 52.6046C31.5233 47.8589 27.676 44.0116 22.9302 44.0116C18.1844 44.0116 14.3372 47.8589 14.3372 52.6046C14.3372 53.8881 14.6199 55.1062 15.1259 56.2003C15.2488 56.4661 15.23 56.8056 15.0864 57.0829C14.945 57.3561 14.7238 57.5 14.4878 57.5H1.07895C0.759204 57.5 0.5 57.2408 0.5 56.9211V43.5122C0.5 43.2762 0.643886 43.055 0.917138 42.9136C1.1944 42.77 1.53389 42.7512 1.79967 42.8741C2.8938 43.3801 4.11194 43.6628 5.39535 43.6628C10.1411 43.6628 13.9884 39.8156 13.9884 35.0698C13.9884 30.324 10.1411 26.4767 5.39535 26.4767C4.11194 26.4767 2.8938 26.7594 1.79967 27.2654C1.53389 27.3883 1.1944 27.3695 0.917138 27.226C0.643886 27.0845 0.5 26.8634 0.5 26.6274V13.2185C0.5 12.8987 0.759204 12.6395 1.07895 12.6395H14.0605C14.676 12.6395 15.1654 12.2967 15.4485 11.8486C15.7289 11.4048 15.8303 10.8266 15.6636 10.281C15.4522 9.58889 15.3372 8.8552 15.3372 8.09302C15.3372 3.89952 18.7367 0.5 22.9302 0.5Z"
          fill="#6A6A6A"
          fillOpacity={0.3}
          stroke={`url(#${id.current})`}
        />
        <Defs>
          <RadialGradient
            id={id.current}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(1.83372 15.1351) rotate(36.7441) scale(98.9941)">
            <Stop stopColor="white" />
            <Stop offset="1" stopColor="white" stopOpacity="0" />
          </RadialGradient>
        </Defs>
      </Svg>
    </View>
  );
});
