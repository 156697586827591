import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {WorkerAction, WorkerActionModal} from '../../WorkerActionModal';
import {expr} from 'mobx-utils';
import isWorkerDisabled from '../../DashboardScreen/checkers/isWorkerDisabled';
import {useRoot} from '../../Root/hooks';
import {getWorkerName} from '../../DashboardScreen';
import isRenewPossible from '../../DashboardScreen/checkers/isRenewPossible';
import isExpired from '../../DashboardScreen/checkers/isExpired';
import isPoolMiner from '../../DashboardScreen/checkers/isPoolMiner';

export type WorkerActionBindingProps = RootStackBindingProps<'WorkerAction'>;

export default observer(function WorkerActionBinding({
  navigation,
  route,
}: WorkerActionBindingProps) {
  const {currentSubscriptionState, dashboardStore, proposalsState} = useRoot();
  const {workerId, hiddenOptions = []} = route.params ?? {};
  const worker = expr(() =>
    workerId !== undefined ? dashboardStore.workers.get(workerId) : undefined,
  );
  const {dashboardRequest} = dashboardStore;
  const isMoveButtonVisible = expr(() =>
    dashboardRequest.value?.groups
      ? dashboardRequest.value.groups.length > 1
      : false,
  );
  const {isPlatformSupported} = currentSubscriptionState;
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const visibleRenewButton = expr(() =>
    worker
      ? isRenewPossible(
          worker,
          proposalsState.minerProductProposalList.flatMap((_) =>
            _.available ? [_.offer] : [],
          ),
          proposalsState.minerSubscriptionProposalList.flatMap((_) =>
            _.available ? [_.offer] : [],
          ),
        )
      : false,
  );
  const close = useCallback(() => modalRef.current?.close(), []);
  const onAction = useCallback(
    async (action: WorkerAction) => {
      await close();
      navigation.pop(1);
      if (worker === undefined) {
        return;
      }
      switch (action) {
        case WorkerAction.Detail:
          return navigation.navigate('WorkerDetails', {
            workerId: worker.id,
          });
        case WorkerAction.Schedule:
          return navigation.navigate('Schedule', {
            ownerName: getWorkerName(worker),
            workerIds: [worker.id],
          });
        case WorkerAction.Statistics:
          return navigation.navigate('WorkerStatistics', {
            workerId: worker.id,
          });
        case WorkerAction.Renew:
          return navigation.navigate('RenewAccess', {
            workerId: worker.id,
          });
        case WorkerAction.Rename:
          return navigation.navigate('RenameWorker', {
            workerId: worker.id,
          });
        case WorkerAction.Move:
          return navigation.navigate('MoveWorkerToGroup', {
            workerId: worker.id,
          });
        case WorkerAction.Delete:
          return navigation.navigate('DeleteWorkerConfirm', {
            workerId: worker.id,
            withRenew: visibleRenewButton,
            forceNavigateRoot: true,
          });
      }
    },
    [close, navigation, worker, visibleRenewButton],
  );
  const disableManagement = expr(() =>
    worker ? isWorkerDisabled(worker) || !isPlatformSupported : true,
  );
  const disabledList = useMemo(() => {
    let list: WorkerAction[] = [];
    if (disableManagement) {
      list.push(WorkerAction.Schedule);
      list.push(WorkerAction.Move);
    }
    if (!isPlatformSupported) {
      list = [
        ...list,
        WorkerAction.Rename,
        WorkerAction.Statistics,
        WorkerAction.Delete,
      ];
    }
    return list;
  }, [isPlatformSupported, disableManagement]);
  const hiddenList = useMemo(() => {
    const list: WorkerAction[] = hiddenOptions;
    if (!worker) {
      return list;
    }
    if (!isExpired(worker) && isPoolMiner(worker)) {
      list.push(WorkerAction.Delete);
    }
    return list;
  }, [hiddenOptions, worker]);
  return (
    <WorkerActionModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onAction={onAction}
      disabledList={disabledList}
      hiddenList={hiddenList}
      visibleRenewButton={visibleRenewButton}
      isVisibleMoveButton={isMoveButtonVisible}
    />
  );
});
