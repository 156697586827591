import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import {AuthStatus} from '../Auth';
import {Modal} from 'react-native';
import {SplashScreen} from '../SplashScreen';
import {expr} from 'mobx-utils';
import {DebugScreen} from '../DebugScreen';

export default observer(() => {
  const {connection, auth, dashboardStore, debug} = useRoot();
  const visible = expr(
    () =>
      !connection.isInitialized ||
      (connection.isConnected && !auth.initialized) ||
      auth.status !== AuthStatus.Idle ||
      (dashboardStore.dashboardRequest.value === undefined &&
        dashboardStore.isLoading),
  );
  return (
    <Modal visible={visible} onRequestClose={noop}>
      {debug.debugEnabled ? <DebugScreen /> : <SplashScreen />}
    </Modal>
  );
});

const noop = () => {};
