import {
  InAppPurchaseManager,
  ProductStorePurchase,
  Purchase,
  PURCHASE_RESULT,
  PurchaseResult,
  SubscriptionStorePurchase,
} from './InAppPurchaseManager';
import {PurchaseId} from '../units';
import {Either, error, success} from '../fp';
import {RestoreResult} from '../ApiStore';
import {batchDisposers, Disposer, RouterImpl, Service} from '../structure';
import {flow, observable, when, makeObservable} from 'mobx';
import {EmitterSubscription} from 'react-native';
import {
  Purchase as OriginalInAppPurchase,
  PurchaseError,
  SubscriptionPurchase,
} from 'react-native-iap';
import {InAppPurchase, RequestPurchaseParams} from '../InAppPurchase';
import {Configuration} from '../Configuration';
import {ReadyState as ReadyState} from '../Connection';
import {ErrorRepository} from '../ErrorRepository';
import {ConnectedClient} from '../ContextClient';
import {PurchaseDiscount} from '../PurchasePromoService';
import {
  GlobalError,
  IAP_ERROR,
  IapError,
  UNKNOWN_ERROR,
  UnknownError,
} from '../Error';
import isPurchaseError from './isPurchaseError';

export abstract class BaseInAppPurchaseManagerImpl
  implements InAppPurchaseManager, Service
{
  @observable private _iapReadyState = ReadyState.Closed;

  protected constructor(
    protected readonly _root: {
      readonly purchaseDiscount: PurchaseDiscount;
      readonly inAppPurchase: InAppPurchase;
      readonly configuration: Configuration;
      readonly connectedClient: ConnectedClient;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    makeObservable(this);
  }

  get iapReadyState() {
    return this._iapReadyState;
  }

  protected createIapError(raw: PurchaseError | unknown): GlobalError {
    if (isPurchaseError(raw)) {
      return this._root.errorRepository.create<IapError>({
        kind: IAP_ERROR,
        raw,
        code: raw.code,
        message: raw.message,
      });
    }
    return this._root.errorRepository.create<UnknownError>({
      kind: UNKNOWN_ERROR,
      raw: raw instanceof Error ? raw.toString() : raw,
    });
  }

  private _updateSubscription?: EmitterSubscription;
  private _errorSubscription?: EmitterSubscription;

  private readonly _onPurchaseUpdate = (
    _: OriginalInAppPurchase,
  ) => {
    this.events.send(PURCHASE_RESULT, success(_));
  };

  private readonly _onPurchaseError = (raw: PurchaseError) => {
    this.events.send(PURCHASE_RESULT, error(this.createIapError(raw)));
  };

  private _connect = flow(function* (this: BaseInAppPurchaseManagerImpl) {
    if (
      this._iapReadyState === ReadyState.Open ||
      this._iapReadyState === ReadyState.Connecting
    ) {
      return;
    }
    if (this._iapReadyState === ReadyState.Closing) {
      yield when(() => this._iapReadyState === ReadyState.Closed);
    }
    try {
      this._iapReadyState = ReadyState.Connecting;
      const connected: boolean =
        yield this._root.inAppPurchase.initConnection();
      if (connected) {
        this._updateSubscription =
          this._updateSubscription ??
          this._root.inAppPurchase.purchaseUpdatedListener(
            this._onPurchaseUpdate,
          );
        this._errorSubscription =
          this._errorSubscription ??
          this._root.inAppPurchase.purchaseErrorListener(this._onPurchaseError);
        this._iapReadyState = ReadyState.Open;
      } else {
        this._iapReadyState = ReadyState.Closed;
      }
    } catch (ignore) {
      this._iapReadyState = ReadyState.Closed;
    }
  });

  private _disconnect = flow(function* (this: BaseInAppPurchaseManagerImpl) {
    if (
      this._iapReadyState === ReadyState.Closed ||
      this._iapReadyState === ReadyState.Closing
    ) {
      return;
    }
    if (this._iapReadyState === ReadyState.Connecting) {
      yield when(() => this._iapReadyState === ReadyState.Open);
    }
    try {
      this._iapReadyState = ReadyState.Closing;
      yield this._root.inAppPurchase.endConnection();
    } finally {
      this._iapReadyState = ReadyState.Closed;
    }
  });

  async reconnect() {
    await this._disconnect();
    await this._connect();
  }

  abstract getAvailablePurchases(): Promise<Either<Purchase[], GlobalError>>;
  abstract getSubscriptions(
    purchaseIds: PurchaseId[],
  ): Promise<Either<SubscriptionStorePurchase[], GlobalError>>;
  abstract getProducts(
    purchaseIds: PurchaseId[],
  ): Promise<Either<ProductStorePurchase[], GlobalError>>;

  async getOriginalAvailablePurchases(): Promise<
    Either<(OriginalInAppPurchase | SubscriptionPurchase)[], GlobalError>
  > {
    try {
      const response = await this._root.inAppPurchase.getAvailablePurchases();
      return success(response);
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }

  async requestSubscription(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    try {
      // noinspection ES6MissingAwait
      this._root.inAppPurchase.requestSubscription(params);
    } catch (ignore) {}
    return new Promise((resolve) => {
      this.events.once(PURCHASE_RESULT, (result) => {
        resolve(result);
      });
    });
  }

  async requestProduct(params: RequestPurchaseParams): Promise<PurchaseResult> {
    try {
      // noinspection ES6MissingAwait
      this._root.inAppPurchase.requestProduct(params);
    } catch (ignore) {}
    return new Promise((resolve) => {
      this.events.once(PURCHASE_RESULT, (result) => {
        resolve(result);
      });
    });
  }

  abstract authByPurchaseHistory(): Promise<
    Either<RestoreResult[], GlobalError>
  >;

  public readonly events = new RouterImpl<{
    [PURCHASE_RESULT]: PurchaseResult;
  }>();

  private _initConnection() {
    this._connect();
    return (() => {
      this._errorSubscription?.remove();
      this._updateSubscription?.remove();
      this._disconnect();
    }) as Disposer;
  }

  subscribe() {
    return batchDisposers(this._initConnection());
  }
}
