import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {Body as TypoBody} from '../components';
import {variance} from '../styling';
import InfoSvg from '../assets/svg/colored/info.svg';
import {sized} from '../Svg';
import {Divider} from '../components/atoms';
import {useStrings} from '../Root/hooks';

export default observer(function HelperBanner() {
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <Divider />
      <View style={styles.content}>
        <View style={styles.icon}>
          <InfoIcon />
        </View>
        <View style={styles.description}>
          <BodyText type="body" weight="500">
            {strings['modal.workerLinkInfo.helper']}
          </BodyText>
        </View>
      </View>
      <Divider />
    </View>
  );
});

const InfoIcon = sized(InfoSvg, 18);

const BodyText = variance(TypoBody)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    letterSpacing: -0.2,
    fontSize: 13,
    lineHeight: 16,
  },
}));

const styles = StyleSheet.create({
  root: {},
  content: {
    paddingHorizontal: 16,
    paddingTop: 14,
    paddingBottom: 18,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    marginRight: 12.5,
  },
  description: {
    flex: 1,
  },
});
