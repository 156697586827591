import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
  Representation,
} from '../../templates';
import {View} from 'react-native';
import useSafePaddingBottom from '../../templates/AdaptiveModal/useSafePaddingBottom';
import {variance} from '../../../styling';
import useRepresentation from '../../templates/AdaptiveModal/useRepresentation';
import {
  AdaptiveModalHeader,
  AdaptiveModalHeaderProps,
} from './AdaptiveModalHeader';
import AdaptiveModalActions from './AdaptiveModalActions';

export const ADAPTIVE_ACTION_MODAL_BREAKPOINT = 'sm';

export type AdaptiveActionModalProps = AdaptiveModalHeaderProps & {
  actions: React.ReactNode[];
  onModalClosed: () => void;
  children?: React.ReactNode;
};

export default observer<AdaptiveActionModalProps, AdaptiveModalRef>(
  forwardRef(function AdaptiveActionModal(props, ref) {
    return (
      <AdaptiveModal
        ref={ref}
        adaptiveBreakpoint={ADAPTIVE_ACTION_MODAL_BREAKPOINT}
        enableContentAutoHeight
        modalWidth={ModalWidth.Small}
        onModalClosed={props.onModalClosed}>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  ({
    IconSvg,
    IconSvgModalRep,
    title,
    description,
    actions,
    children,
    titleStyle,
  }: AdaptiveActionModalProps) => {
    const paddingBottom = useSafePaddingBottom(16, 20);
    const representation = useRepresentation();
    const isModal = representation === Representation.Modal;
    return (
      <RootView style={{paddingBottom}} modal={isModal}>
        <AdaptiveModalHeader
          IconSvg={IconSvg}
          IconSvgModalRep={IconSvgModalRep}
          title={title}
          titleStyle={titleStyle}
          description={description}
        />
        {children}
        <ActionListView modal={isModal}>
          <AdaptiveModalActions actions={actions} />
        </ActionListView>
      </RootView>
    );
  },
);

const ActionListView = variance(View)(() => ({
  root: {
    marginTop: 24,
  },
  modal: {
    marginTop: 36,
  },
}));

const RootView = variance(View)(() => ({
  root: {
    padding: 16,
    paddingTop: 39,
  },
  modal: {
    padding: 20,
    paddingTop: 48,
  },
}));
