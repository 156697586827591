import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import SpeedToggleList from './SpeedToggleList';
import {useStrings} from '../Root/hooks';
import {createStylesHook} from '../styling';
import ScheduleState from './ScheduleState';
import {Typography} from '../components';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type SpeedSwitchProps = {
  state: ScheduleState;
  onSpeedSelect: () => void;
};

export default observer(function SpeedSwitch(props: SpeedSwitchProps) {
  const {state, onSpeedSelect} = props;
  const styles = useStyles();
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <Typography type={'body'} weight={'500'} style={styles.title}>
          {strings['scheduler.setMiningSpeed']}:
        </Typography>
        <SpeedToggleList
          selectedSpeed={state.selectedSpeed}
          pickedCustomSpeed={state.pickedCustomSpeed}
          onSelectSpeedPress={onSpeedSelect}
          onChangeSpeed={state.changeSpeed}
        />
      </View>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    justifyContent: 'space-between',
    marginTop: 20,
    paddingHorizontal: 16,
    backgroundColor: theme.colors.uiMain,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        minWidth: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: 30,
      },
    }),
  },
  container: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
      },
    }),
  },
  title: {
    marginBottom: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginRight: 15,
        marginBottom: 0,
        textAlign: 'left',
      },
    }),
  },
}));
