import {MinerOffer, PaymentMethod} from '../InAppOffersService';
import {ProductOffer} from './TotalPriceView';

export function processProductOffer(
  offer: MinerOffer,
  method: PaymentMethod,
): ProductOffer | undefined {
  const payment = offer.payments.get(method);
  if (!payment) {
    return undefined;
  }
  const {
    uiPrice,
    currency,
    discountsDifferencePrice,
    uiDiscountsDifferencePrice,
    uiPricePerMonth,
    discounts,
    uiUsdPrice,
  } = payment;
  const price = method === PaymentMethod.InApp ? uiPrice : `~${uiPrice}`;
  const helperPrice = uiUsdPrice ? `${uiUsdPrice} USD` : undefined;
  return {
    uiPrice: price,
    questionMarkVisible: method !== PaymentMethod.InApp,
    helperPrice,
    currency: currency,
    interval: offer.interval,
    discountsDifferencePrice: discountsDifferencePrice,
    uiDiscountsDifferencePrice: uiDiscountsDifferencePrice,
    discounts: discounts ?? [],
    uiPricePerMonth: `~${uiPricePerMonth} ${currency} / 1`,
  };
}
