import {
  EventSubscription,
  NativeEventEmitter,
  NativeModules,
} from 'react-native';
import {EVENT, RetrievedResult} from './GoogleDeferredDeeplink';

const NativeGoogleDeferredDeeplink = NativeModules.GoogleDeferredDeeplink;

export default class GoogleDeferredDeeplinkImpl {
  private _eventEmitter = new NativeEventEmitter(NativeGoogleDeferredDeeplink);

  addListener(op: (result: RetrievedResult) => void): EventSubscription {
    return this._eventEmitter.addListener(EVENT, op);
  }

  removeListeners() {
    return NativeGoogleDeferredDeeplink.removeListeners();
  }

  async getDeferredDeepLink(): Promise<RetrievedResult> {
    return NativeGoogleDeferredDeeplink.getDeferredDeepLink();
  }
}
