import React from 'react';
import {StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import ShowSection from './ShowSection';
import StatusSection from './StatusSection';
import PurchaseSection from './PurchaseSection';
import {CryptoLoader} from '../components/atoms/CryptoLoader';
import {variance} from '../styling';
import {ScrollView} from 'react-native-gesture-handler';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {NormalizeNodeList} from '../Notifications/NotificationSettingsState';
import {CheckboxTree} from './CheckboxTree';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type NotificationsSettingsScreenProps = {
  isLoading: boolean;
  isLoadedIn: boolean;
  state: NormalizeNodeList;
  tree: CheckboxTree;
};

export default observer(function NotificationsSettingsScreen({
  state,
  tree,
  isLoading,
  isLoadedIn,
}: NotificationsSettingsScreenProps) {
  const insets = useSafeAreaInsets();
  return (
    <View style={styles.root}>
      {isLoading ? (
        <View style={styles.loaderView}>
          <CryptoLoader />
        </View>
      ) : isLoadedIn ? (
        <ScrollView contentContainerStyle={{paddingBottom: insets.bottom + 16}}>
          <ContainerView>
            <ShowSection state={state} onCheck={tree.onCheck} />
            <ContentView>
              <ColumnView>
                <StatusSection state={state} onCheck={tree.onCheck} />
              </ColumnView>
              <ColumnView>
                <PurchaseSection state={state} onCheck={tree.onCheck} />
              </ColumnView>
            </ContentView>
          </ContainerView>
        </ScrollView>
      ) : null}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  loaderView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ContainerView = variance(View)((theme) => ({
  root: {
    paddingVertical: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingLeft: 20,
      },
    }),
  },
}));

const ColumnView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 4,
        marginRight: 20,
        borderWidth: 1,
        borderColor: theme.colors.uiAdditional1,
        borderRadius: 12,
      },
    }),
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    }),
  },
}));
