import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStyles, variance} from '../styling';
import {View} from 'react-native';
import {PressableOpacity, Typography} from '../components';
import ArrowSvg from '../assets/svg/colorless/chevronRight.svg';

export type ColorListItemProps = {
  speed: number;
  color: string;
  onSelect: (speed: number) => void;
};

export default observer(function ColorListItem(props: ColorListItemProps) {
  const {speed, color, onSelect} = props;

  const selectedStyles = useStyles((theme) => ({
    circle: {
      backgroundColor: speed === 0 ? theme.colors.uiAdditional1 : color,
    },
    label: {
      color: speed === 0 ? theme.colors.uiAdditional1 : color,
    },
    icon: {
      color: theme.colors.primaryUIDirtyBlue,
      marginLeft: 'auto',
    },
  }));

  return (
    <ColorListItemView onPress={() => onSelect(speed)}>
      <ColorCircle style={selectedStyles.circle} />
      <Typography
        type={'paragraph'}
        size={'large'}
        weight={'500'}
        style={selectedStyles.label}>
        {speed}%
      </Typography>
      <ArrowSvg style={selectedStyles.icon} />
    </ColorListItemView>
  );
});

const ColorListItemView = variance(PressableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    paddingVertical: 15,
  },
}));

const ColorCircle = variance(View)((theme) => ({
  root: {
    width: 16,
    height: 16,
    marginRight: 12,
    borderRadius: 16,
    backgroundColor: theme.colors.uiAdditional1,
  },
}));
