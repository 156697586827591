import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import CameraLayer from './CameraLayer';
import {PermissionResponse, BarcodeScanningResult} from 'expo-camera';

export type BackgroundLayerProps = {
  requestPermission: () => void;
  onBarCodeScanned: (data: BarcodeScanningResult) => void;
  cameraPermissions: PermissionResponse | undefined;
  isGranted: boolean;
  canAskAgain: boolean;
  visibleCamera: boolean;
  getWidth: () => number;
  getHeight: () => number;
};

export default observer(function BackgroundLayer({
  onBarCodeScanned,
  requestPermission,
  cameraPermissions,
  isGranted,
  canAskAgain,
  visibleCamera,
  getWidth,
  getHeight,
}: BackgroundLayerProps) {
  return (
    <View style={styles.cameraLayer}>
      <View style={StyleSheet.absoluteFillObject}>
        <CameraLayer
          isGranted={isGranted}
          canAskAgain={canAskAgain}
          visibleCamera={visibleCamera}
          onBarCodeScanned={onBarCodeScanned}
          requestPermission={requestPermission}
          cameraPermissions={cameraPermissions}
          getWidth={getWidth}
          getHeight={getHeight}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  cameraLayer: {
    ...StyleSheet.absoluteFillObject,
  },
});
