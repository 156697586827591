import {Alert, GestureResponderEvent, Text, TextProps} from 'react-native';
import React, {forwardRef, useCallback} from 'react';
import {useStyles} from '../styling';
import {useRoot} from '../Root/hooks';
import {sized} from '../Svg';
import CopySvg from '../assets/svg/colorless/copy.svg';

interface CopyableProps extends TextProps {
  children?: string;
  withIcon?: boolean;
  copyValue?: string;
}

export default forwardRef<Text, CopyableProps>(
  ({children, withIcon, onPress: _onPress, style, copyValue, ...rest}, ref) => {
    const {clipboard} = useRoot();
    const onPress = useCallback(
      async (event: GestureResponderEvent) => {
        if (copyValue || children) {
          clipboard.setString(copyValue || children || '');
          Alert.alert(
            'The value has been copied to clipboard',
            copyValue || children,
            [{style: 'default', text: 'OK'}],
            {cancelable: true},
          );
        }
        if (_onPress) {
          _onPress(event);
        }
      },
      [_onPress, children, clipboard, copyValue],
    );
    const styles = useStyles((theme) => ({
      root: {
        color: theme.colors.uiSecondary,
      },
      copy: {
        color: theme.colors.primaryUIDirtyBlue,
        marginLeft: 6,
      },
    }));
    return (
      <Text style={[styles.root, style]} {...rest} onPress={onPress} ref={ref}>
        {children}
        {withIcon && <CopyIcon style={styles.copy} />}
      </Text>
    );
  },
);

const CopyIcon = sized(CopySvg, 14);
