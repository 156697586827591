import {SvgProps} from 'react-native-svg';
import React from 'react';
import {sized} from '../Svg';
import FanSquareSvg from '../assets/svg/colored/fanSquare.svg';
import FanSquareBlackSvg from '../assets/svg/colored/fanSquareBlack.svg';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';

const FanSquareIcon = sized(FanSquareSvg, 40);
const FanSquareBlackIcon = sized(FanSquareBlackSvg, 40);

export const FanSquareLogo = observer((props: SvgProps) => {
  const {appearance} = useRoot();
  return appearance.isDark ? (
    <FanSquareIcon {...props} />
  ) : (
    <FanSquareBlackIcon {...props} />
  );
});
