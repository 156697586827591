import {range} from 'lodash';
import {Matrix, UnsavedMatrix} from './types';
import {cloneDeep} from 'lodash';

export const days = range(0, 7);
export const hours = range(0, 24);

export const INITIAL_MATRIX: Matrix = cloneDeep(
  days.map(() => hours.map((_) => [{length: 60, speed: 0}])),
);

export const INITIAL_UNSAVED_MATRIX: UnsavedMatrix = cloneDeep(
  days.map(() => hours.map((_) => null)),
);

export const COLORS = new Map<number, string>([
  [0, '#ECEEF2'],
  [5, '#A5AFC2'],
  [10, '#8B98B1'],
  [15, '#9BD59B'],
  [20, '#73C473'],
  [25, '#4BB34B'],
  [30, '#80ECFB'],
  [35, '#4DE4F9'],
  [40, '#08CDF8'],
  [45, '#FBC380'],
  [50, '#F9AB4D'],
  [55, '#F7931A'],
  [60, '#97B4FF'],
  [65, '#6E96FF'],
  [70, '#4478FF'],
  [75, '#C28AFD'],
  [80, '#AA5CFD'],
  [85, '#922DFC'],
  [90, '#FF8E99'],
  [95, '#FF6070'],
  [100, '#FF3347'],
]);

export const timezones = [
  {name: 'UTC-12:00', offset: -12},
  {name: 'UTC-11:00', offset: -11},
  {name: 'UTC-10:00', offset: -10},
  {name: 'UTC-09:30', offset: -9.5},
  {name: 'UTC-09:00', offset: -9},
  {name: 'UTC-08:00', offset: -8},
  {name: 'UTC-07:00', offset: -7},
  {name: 'UTC-06:00', offset: -6},
  {name: 'UTC-05:00', offset: -5},
  {name: 'UTC-04:00', offset: -4},
  {name: 'UTC-03:30', offset: -3.5},
  {name: 'UTC-03:00', offset: -3},
  {name: 'UTC-02:00', offset: -2},
  {name: 'UTC-01:00', offset: -1},
  {name: 'UTC±00:00', offset: 0},
  {name: 'UTC+01:00', offset: 1},
  {name: 'UTC+02:00', offset: 2},
  {name: 'UTC+03:00', offset: 3},
  {name: 'UTC+03:30', offset: 3.5},
  {name: 'UTC+04:00', offset: 4},
  {name: 'UTC+04:30', offset: 4.5},
  {name: 'UTC+05:00', offset: 5},
  {name: 'UTC+05:30', offset: 5.5},
  {name: 'UTC+05:45', offset: 5.75},
  {name: 'UTC+06:00', offset: 6},
  {name: 'UTC+06:30', offset: 6.5},
  {name: 'UTC+07:00', offset: 7},
  {name: 'UTC+08:00', offset: 8},
  {name: 'UTC+08:45', offset: 8.75},
  {name: 'UTC+09:00', offset: 9},
  {name: 'UTC+09:30', offset: 9.5},
  {name: 'UTC+10:00', offset: 10},
  {name: 'UTC+10:30', offset: 10.5},
  {name: 'UTC+11:00', offset: 11},
  {name: 'UTC+12:00', offset: 12},
  {name: 'UTC+12:45', offset: 12.75},
  {name: 'UTC+13:00', offset: 13},
  {name: 'UTC+14:00', offset: 14},
];

export const USER_TIMEZONE_MINUTES = -new Date().getTimezoneOffset();
