import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {Pressable} from 'react-native';
import {Paragraph} from '../components';
import CloseSvg from '../assets/svg/colorless/close.svg';
import {sized} from '../Svg';
import {useStrings} from '../Root/hooks';

export type AdvertSplashCloseProps = {
  onClose: () => void;
  seconds: number;
};

export default observer(function AdvertSplashClose({
  onClose,
  seconds,
}: AdvertSplashCloseProps) {
  const theme = useTheme();
  const strings = useStrings();
  return (
    <Container onPress={onClose} finished={seconds === 0}>
      {seconds !== 0 && (
        <Seconds type="paragraph" size="large">
          {seconds} {strings['common.secShort'].toLowerCase()}
        </Seconds>
      )}
      <CloseIcon color={theme.colors.primaryUIDirtyBlue} />
    </Container>
  );
});

const CloseIcon = sized(CloseSvg, 10, 10);

const Container = variance(Pressable)((theme) => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 7,
    backgroundColor: theme.colors.backgroundBlackout,
    width: 95,
    borderRadius: 50,
    opacity: 0.7,
    position: 'absolute',
    elevation: 20,
    zIndex: 20,
    top: 16,
    right: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  finished: {
    width: 30,
    height: 30,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
}));

const Seconds = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    marginRight: 7,
    width: 45,
  },
}));
