import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import TryTheNewPoolMinersBanner from '../components/organisms/banner/TryTheNewPoolMinersBanner';
import BannerList from '../components/templates/BannerList';
import {
  LiteralBreakpoint,
  useGetIsDimensions,
} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {ApiMode} from '../farmApi';
import TryDemoMinerBanner from '../components/organisms/banner/TryDemoMinerBanner';
import LearnToUseFarmBanner from '../components/organisms/banner/LearnToUseFarmBanner';
import {useRoot} from '../Root/hooks';

export type BottomStatisticBannerContainerProps = {
  largeBreakpoint: LiteralBreakpoint;
  goToPlan: () => void;
  goToAddDemoMiner: () => void;
};

export default observer(function BottomStatisticBannerContainer({
  largeBreakpoint,
  goToPlan,
  goToAddDemoMiner,
}: BottomStatisticBannerContainerProps) {
  const {apiStore} = useRoot();
  const getIsDimensions = useGetIsDimensions(largeBreakpoint);
  const isLarge = expr(() => getIsDimensions());
  return (
    <View style={[styles.root, isLarge && styles.rootLarge]}>
      <BannerList largeBreakpoint={largeBreakpoint}>
        {isLarge ? (
          apiStore.mode === ApiMode.Demo ? (
            <TryDemoMinerBanner
              style={styles.banner}
              onButtonPress={goToAddDemoMiner}
            />
          ) : (
            <LearnToUseFarmBanner style={styles.banner} />
          )
        ) : null}
        <TryTheNewPoolMinersBanner
          onButtonPress={goToPlan}
          style={styles.banner}
        />
      </BannerList>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
  },
  rootLarge: {
    paddingHorizontal: 0,
  },
  banner: {
    height: 150,
  },
});
