import {observer} from 'mobx-react-lite';
import React from 'react';
import {Image} from 'react-native';
import {useStyles} from '../../styling';

export default observer(function UnknownIcon() {
  const styles = useStyles(() => ({
    root: {
      height: 127,
      width: 108,
      marginBottom: 36,
    },
  }));
  return (
    <Image style={styles.root} source={require('../../assets/unknown.png')} />
  );
});
