import React, {useCallback, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import {AdaptiveModalRef} from '../components/templates';
import {useOpenLinkCallback} from '../Links';
import {AdInfoAction} from '../Advert';
import {useTimer} from 'use-timer';
import AdvertSplashScreen from '../AdvertSplashScreen';
import {useGetIsPixels} from '../WindowDimensions/useDimensions';
import {ADVERT_SPLASH_DESKTOP_BREAKPOINT} from '../AdvertSplashScreen/AdvertSplashScreen';
import {Resolution} from '../ApiStore';

export default observer(function PushAdvertSplashContainer() {
  const {pushAdvert} = useRoot();

  const getIsDesktop = useGetIsPixels(ADVERT_SPLASH_DESKTOP_BREAKPOINT);

  const modalRef = useRef<AdaptiveModalRef>(null);
  const closeModal = () => modalRef.current?.close();
  const onClose = useCallback(async () => {
    await closeModal();
    pushAdvert.close();
  }, [pushAdvert]);
  const onModalClosed = useCallback(async () => {
    pushAdvert.close();
  }, [pushAdvert]);

  const openLink = useOpenLinkCallback();
  const onButtonActionPress = useCallback(
    async (action: AdInfoAction) => {
      if (action.link) {
        await openLink(action.link);
      }
      await onClose();
    },
    [onClose, openLink],
  );

  const {time, start} = useTimer({
    autostart: false,
    initialTime: 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: onClose,
  });

  const candidates = pushAdvert.adToShow
    ? pushAdvert.state?.get(pushAdvert.adToShow)
    : null;

  useEffect(() => {
    if (candidates) {
      start();
      pushAdvert.onAdShown();
    }
  }, [candidates, pushAdvert, start]);

  if (pushAdvert.adToShow === undefined || pushAdvert.state === undefined) {
    return null;
  }

  if (!candidates) {
    return null;
  }

  const mobileCandidate = candidates?.find((_) => _.type === Resolution.Mobile);
  const desktopCandidate = candidates?.find(
    (_) => _.type === Resolution.Desktop,
  );

  let banner =
    getIsDesktop() && desktopCandidate ? desktopCandidate : mobileCandidate;

  if (!banner) {
    return null;
  }

  return (
    <AdvertSplashScreen
      seconds={time}
      ref={modalRef}
      onButtonActionPress={onButtonActionPress}
      onCrossClose={onClose}
      onModalClosed={onModalClosed}
      ad={banner}
      isDesktop={false}
    />
  );
});
