import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionModal} from '../components/organisms';
import PoolMinerWarningSvg from '../assets/svg/twoTone/poolMinerWarning.svg';
import {sized} from '../Svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import {AdaptiveModalRef} from '../components/templates';

export type PoolMinerSchedulerWarningModalProps = {
  onModalClosed: () => void;
  onClearPress: () => void;
  onCancelPress: () => void;
};

export default observer<PoolMinerSchedulerWarningModalProps, AdaptiveModalRef>(
  forwardRef(function PoolMinerSchedulerWarningModal(
    {onModalClosed, onClearPress, onCancelPress},
    ref,
  ) {
    const strings = useStrings();
    return (
      <AdaptiveActionModal
        ref={ref}
        onModalClosed={onModalClosed}
        IconSvg={PoolMinerWarningIcon}
        title={strings['modal.poolMinerSchedulerWarning.title']}
        description={strings['modal.poolMinerSchedulerWarning.description']}
        actions={[
          <AppButton variant={ButtonVariant.Primary} onPress={onClearPress}>
            {strings['action.clear']}
          </AppButton>,
          <AppButton onPress={onCancelPress}>
            {strings['action.cancel']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const PoolMinerWarningIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(PoolMinerWarningSvg, 60)} {...props} />
));
