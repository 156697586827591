import BaseErrorHandlerImpl from './BaseErrorHandlerImpl';
import {FlashMessageService} from '../FlashMessageService';
import {Translation} from '../Localization';
import {NavigationContainer} from '../NavigationContainer';
import {BasicErrorModalState} from '../BasicErrorModalState';
import {NotAllowedModalState} from '../NotAllowedModalState';
import {OccurredErrorResult} from './ErrorHandler';

export default class ErrorHandlerImpl extends BaseErrorHandlerImpl {
  constructor(
    private readonly _root: {
      readonly flashMessages: FlashMessageService;
      readonly translation: Translation;
      readonly navigationContainer: NavigationContainer;
      readonly basicErrorModalState: BasicErrorModalState;
      readonly notAllowedModalState: NotAllowedModalState;
    },
  ) {
    super(_root.translation);
  }

  protected _onPriceChanged() {
    this._root.navigationContainer.ref?.navigate('PriceChanged');
  }
  protected _onOccurredError(error: unknown, result: OccurredErrorResult) {
    this._root.basicErrorModalState.setVisible({
      description: result.description,
      title: result.title,
    });
  }
  protected _onWorkerNotManaged() {
    this._root.flashMessages.showMessage({
      title: this._root.translation.strings['error.3007'],
      variant: 'danger',
    });
  }
  protected _onGroupNameIsAlreadyInUse() {
    this._root.flashMessages.showMessage({
      title: this._root.translation.strings['error.3008'],
      variant: 'danger',
    });
  }
  protected _onMethodNotAllowedForYourSubscription() {
    this._root.notAllowedModalState.setVisibleWithDescription(
      this._root.translation.strings['error.groupAction'],
    );
  }
}
