import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../styling';
import {View} from 'react-native';
import Typography from '../../Typography';
import Formatter from '../../../Formatter/Formatter';
import {useRoot} from '../../../Root/hooks';
import BtcQuoteChart from './BtcQuoteChart';

export default observer(function BtcQuote() {
  const {
    dashboardStore: {currentRateRequest},
  } = useRoot();
  const rate = useMemo(() => {
    const usd = currentRateRequest.value?.usd;
    const btc = currentRateRequest.value?.btc;
    if (!usd || !btc) {
      return null;
    }
    return usd / btc;
  }, [currentRateRequest.value]);

  return (
    <RootView>
      <LabelTypography type="body" weight="600">
        BTC / USD
      </LabelTypography>
      {rate && (
        <PriceTypography type="mono" weight="500">
          {Formatter.numberWithSpaces(rate.toFixed(2))}
        </PriceTypography>
      )}
      <BtcQuoteChart />
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
    height: 46,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
}));

const LabelTypography = variance(Typography)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 15,
    color: theme.colors.uiSecondary,
    letterSpacing: 2,
    marginRight: 10,
  },
}));

const PriceTypography = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiAdditional4,
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: -0.64,
  },
}));
