import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useForcedContext} from '../context';
import RenewAccessScreenContext from './context';
import TotalPriceView from '../PurchaseScreen/TotalPriceView';
import {processProductOffer} from '../PurchaseScreen/processProductOffer';
import {processMinerSubscriptionProposal} from '../PurchaseScreen/processMinerSubscriptionProposal';

export default observer(function TotalPriceBinding() {
  const {
    state: {selectedProposal, selectedPaymentMethod},
    purchaseStoreError,
    onPurchasePress,
  } = useForcedContext(RenewAccessScreenContext);
  const subscription = selectedProposal?.subscription;
  const product = selectedProposal?.product;
  const handleBuyPress = useCallback(() => {
    if (subscription?.offer) {
      onPurchasePress(subscription.offer);
    }
    if (product?.offer) {
      onPurchasePress(product.offer);
    }
  }, [onPurchasePress, subscription, product]);
  if (purchaseStoreError) {
    return null;
  }
  if (!selectedProposal) {
    return null;
  }

  let computedOfferProduct;
  let computedOfferSubscription;
  if (subscription) {
    computedOfferSubscription = processMinerSubscriptionProposal(
      subscription,
      selectedPaymentMethod,
    );
  } else if (product) {
    computedOfferProduct = processProductOffer(
      product.offer,
      selectedPaymentMethod,
    );
  }
  if (!computedOfferProduct && !computedOfferSubscription) {
    return null;
  }
  return (
    <TotalPriceView
      product={computedOfferProduct}
      subscription={computedOfferSubscription}
      onMainOfferPurchasePress={handleBuyPress}
      onAdditionalOfferPurchasePress={handleBuyPress}
      hideGift
    />
  );
});
