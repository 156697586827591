import React, {useState} from 'react';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {Pressable, View, StyleSheet} from 'react-native';
import {Header, Paragraph, Tooltip, UnitSlider} from '../components';
import {createStylesHook} from '../styling';
import Switch from '../components/Switch';

import {Mode} from '../DashboardMode';
import {ScrollView} from 'react-native-gesture-handler';
import HelpSvg from '../assets/svg/colored/help.svg';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import UnlockThePossibilitiesOfMiningBanner from '../components/organisms/banner/UnlockThePossibilitiesOfMiningBanner';
import BannerList from '../components/templates/BannerList';
import TryTheNewPoolMinersBanner from '../components/organisms/banner/TryTheNewPoolMinersBanner';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type DashboardSettingsScreenProps = {
  getPeriod: () => number;
  onSlidingComplete: (period: number) => void;
  onModeChange: (isSimple: boolean) => void;
  mode: Mode;
  goToPlan: () => void;
};

export default observer(function DashboardSettingsScreen({
  getPeriod,
  onSlidingComplete,
  onModeChange,
  mode,
  goToPlan,
}: DashboardSettingsScreenProps) {
  const strings = useStrings();
  const themedStyles = useStyles();
  const [tooltipsState, setTooltipsState] = useState({
    frequency: false,
    simple: false,
  });
  const hideAll = () => setTooltipsState({frequency: false, simple: false});
  const {
    appTemplateState: {isTablet},
  } = useRoot();
  const isLg = isTablet;
  const insets = useSafeAreaInsets();
  return (
    <ScrollView
      style={themedStyles.root}
      contentContainerStyle={[themedStyles.container, {paddingBottom: insets.bottom}]}>
      <View style={[styles.settingsItem, isLg && themedStyles.lgContainer]}>
        <View style={themedStyles.item}>
          {!isLg && tooltipsState.frequency && (
            <Tooltip
              style={themedStyles.tooltip}
              onPress={hideAll}
              text={strings['dashboardSettings.rate.description']}
            />
          )}
          <View style={themedStyles.itemHeader}>
            <View style={themedStyles.itemHeaderLeft}>
              <Header type="header" size="h4" weight="500">
                {strings['dashboardSettings.rate.title']}
              </Header>
              {!isLg ? (
                <Pressable
                  onPress={() =>
                    setTooltipsState({
                      simple: false,
                      frequency: !tooltipsState.frequency,
                    })
                  }
                  style={themedStyles.helpIcon}>
                  <HelpSvg />
                </Pressable>
              ) : (
                <Paragraph
                  style={themedStyles.firstParagraph}
                  type="paragraph"
                  size="regular">
                  {strings['dashboardSettings.rate.description']}
                </Paragraph>
              )}
            </View>
          </View>
          <UnitSlider
            unit={strings['common.secShort']}
            minimumValue={0}
            maximumValue={10}
            step={1}
            getValue={getPeriod}
            onSlidingComplete={onSlidingComplete}
          />
        </View>
        <View style={isLg && themedStyles.rowContainer}>
          <View style={[themedStyles.item, isLg && themedStyles.secondItem]}>
            {!isLg && tooltipsState.simple && (
              <Tooltip
                style={themedStyles.tooltip}
                onPress={hideAll}
                text={strings['dashboardSettings.simpleMode.description']}
              />
            )}
            <View style={themedStyles.itemHeader}>
              <View style={themedStyles.itemHeaderLeft}>
                <Header type="header" size="h4" weight="500">
                  {strings['dashboardSettings.simpleMode.title']}
                </Header>
                {!isLg ? (
                  <Pressable
                    onPress={() =>
                      setTooltipsState({
                        frequency: false,
                        simple: !tooltipsState.simple,
                      })
                    }
                    style={themedStyles.helpIcon}>
                    <HelpSvg />
                  </Pressable>
                ) : (
                  <Paragraph
                    type="paragraph"
                    size="regular"
                    style={themedStyles.secondParagraph}>
                    {strings['dashboardSettings.simpleMode.description']}
                  </Paragraph>
                )}
              </View>
              <View>
                <Switch
                  value={mode === Mode.Simple}
                  onValueChange={onModeChange}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.bannerList, isLg && styles.largeBannerList]}>
        <BannerList>
          <TryTheNewPoolMinersBanner
            onButtonPress={goToPlan}
            style={styles.banner}
          />
          <UnlockThePossibilitiesOfMiningBanner
            onButtonPress={goToPlan}
            style={styles.banner}
          />
        </BannerList>
      </View>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  bannerList: {
    paddingHorizontal: 16,
    marginTop: 18,
    zIndex: 10,
  },
  largeBannerList: {
    paddingHorizontal: 0,
    height: 160,
  },
  banner: {
    height: 150,
  },
  settingsItem: {
    zIndex: 15,
  },
});

const useStyles = createStylesHook((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    marginTop: 10,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: 'transparent',
        marginTop: 0,
        padding: 20,
      },
    }),
  },
  container: {
    flexGrow: 1,
  },
  rowContainer: {
    flexDirection: 'row',
    marginTop: 40,
  },
  lgContainer: {
    backgroundColor: theme.colors.uiMain,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    padding: 16,
  },
  tooltip: {
    left: 20,
    top: 50,
    zIndex: 2,
  },
  bannerView: {
    flexDirection: 'row',
    marginVertical: 18,
  },
  banner: {
    marginRight: 18,
    marginBottom: 0,
    marginLeft: 0,
    flexBasis: `${100 / 3}%`,
  },
  item: {
    paddingHorizontal: 20,
    paddingTop: 20,
    overflow: 'visible',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingTop: 0,
      },
    }),
  },
  secondItem: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 8,
    borderWidth: 1,
    flexBasis: '50%',
    borderColor: theme.colors.uiAdditional2,
  },
  firstParagraph: {
    color: theme.colors.primaryUIDirtyBlue,
    marginVertical: 18,
  },
  secondParagraph: {
    color: theme.colors.primaryUIDirtyBlue,
    marginTop: 10,
    maxWidth: 300,
    marginRight: 10,
  },
  itemHeaderLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    }),
  },
  helpIcon: {
    marginLeft: 5,
    zIndex: 10,
  },
  itemHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 0,
      },
    }),
  },
}));
