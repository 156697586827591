import {OrderId} from '../ApiStore/units/order';
import {makeObservable, observable, runInAction} from 'mobx';
import {GetOrderResult} from '../ApiStore/entities/GetOrderResult';
import {FULFILLED, PENDING, REJECTED} from '../AsyncAtom';
import {NETWORK_ERROR, NetworkError} from '../Error';
import {ConnectedClient} from '../ContextClient';
import {ErrorRepository} from '../ErrorRepository';
import {isMobileWeb} from './IsMobileWeb';
import {openLink} from '../Links';
import {Web3Payment} from '../Web3Payment';
import translateResponseToMetamaskInfo from './translateResponseToMetamaskInfo';
import {bind} from '../fp';
import {Order, OrderPaymentState} from './OrderPaymentState';
import {Url} from '../units';

export default class OrderPaymentStateImpl implements OrderPaymentState {
  @observable.ref private _order: OrderPaymentState['order'];

  constructor(
    private readonly _orderId: OrderId,
    private readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly errorRepository: ErrorRepository;
      readonly web3Payment: Web3Payment;
    },
  ) {
    makeObservable(this);
  }

  private _setState(newState: OrderPaymentState['order']) {
    runInAction(() => (this._order = newState));
  }

  get order() {
    return this._order;
  }

  private _translateResponse(response: GetOrderResult): Order {
    const url = this._generatePaymentUrl(response.order);
    return {
      toAddress: response.order.to_address,
      amount: response.order.amount,
      decimals: response.order.decimals,
      currency: response.order.currency,
      cryptoPaymentUrl: url,
      status: response.order.status,
      metamask: translateResponseToMetamaskInfo(response),
      networkName: response.order.network_name,
      ctPayUrl: (response.order.redirect_url as Url) || undefined,
    };
  }

  async fetch() {
    this._setState({status: PENDING});
    const response = await this._root.connectedClient.apply('get_order', {
      id: this._orderId,
    });

    const nextState: OrderPaymentState['order'] = response.success
      ? {
          status: FULFILLED,
          result: this._translateResponse(response.right),
        }
      : {
          status: REJECTED,
          error: this._root.errorRepository.create<NetworkError>({
            kind: NETWORK_ERROR,
            raw: response.left,
          }),
        };

    this._setState(nextState);

    return nextState;
  }

  private _generatePaymentUrl(order: GetOrderResult['order']) {
    const {paymentUrlGenerator} = this._root.web3Payment;
    return paymentUrlGenerator.generate({
      protocol: order.protocol || undefined,
      address: order.to_address,
      amount: order.amount,
      decimals: order.decimals,
    });
  }

  payWithMetamask = bind(async () => {
    if (this.order?.status !== FULFILLED) {
      return;
    }
    const order = this.order.result;
    if (isMobileWeb()) {
      if (order.cryptoPaymentUrl) {
        return openLink(order.cryptoPaymentUrl);
      }
      return;
    }
    if (!order.metamask) {
      return;
    }
    const {metamask} = this._root.web3Payment;
    await metamask.payment.sendToken(
      order.currency,
      order.metamask.network,
      order.toAddress,
      order.amount,
      order.decimals,
      order.metamask.contractAddress,
    );
  }, this);

  payWithCtPay = bind(async () => {
    if (
      this.order?.status !== FULFILLED ||
      this.order.result.ctPayUrl === undefined
    ) {
      return;
    }
    openLink(this.order.result.ctPayUrl);
  }, this);

  checkIsCompleted = bind(async () => {
    await this.fetch();
    return (
      this._order?.status === FULFILLED &&
      this._order.result.status === 'completed'
    );
  }, this);
}
