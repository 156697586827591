import {LinearGradient} from 'expo-linear-gradient';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Image, Pressable, View} from 'react-native';
import {Header, Paragraph} from '../components';
import {useGoToAppStore, useGoToPlayStore} from '../Navigation/hooks';
import {useStrings} from '../Root/hooks';
import {useStyles, useTheme, variance} from '../styling';

export default observer(function LgStoreBanner() {
  const theme = useTheme();
  const strings = useStrings();
  const goToPlayStore = useGoToPlayStore();
  const goToAppStore = useGoToAppStore();
  const styles = useStyles(() => ({
    offsetBottom: {
      marginBottom: 6,
    },
    transparent: {
      opacity: 0.8,
    },
    storeButton: {
      borderWidth: 0,
      maxWidth: 358,
    },
    textColor: {
      color: theme.colors.primaryBlack,
    },
  }));
  return (
    <Container
      colors={[
        theme.colors.gradientBtnYellow[1],
        theme.colors.gradientBtnYellow[0],
      ]}
      start={{x: 1, y: 1}}
      end={{x: 0, y: 0}}>
      <Content>
        <Header
          size="h5"
          style={[styles.offsetBottom, styles.textColor]}
          type="header"
          weight="700">
          {strings['lgStoreBanner.title']}
        </Header>
        <Paragraph
          size="large"
          type="paragraph"
          style={[styles.transparent, styles.textColor]}>
          {strings['lgStoreBanner.description']}
        </Paragraph>
      </Content>
      <Buttons>
        <StoreButtonContainer offsetRight onPress={goToPlayStore}>
          <StoreButton
            colors={[
              theme.colors.gradientCard[1],
              theme.colors.gradientCard[0],
            ]}>
            <Image
              source={require('../assets/getInGooglePlay.png')}
              style={{height: 38, width: 156}}
            />
          </StoreButton>
        </StoreButtonContainer>
        <StoreButtonContainer onPress={goToAppStore}>
          <StoreButton
            colors={[
              theme.colors.gradientCard[1],
              theme.colors.gradientCard[0],
            ]}>
            <Image
              source={require('../assets/getInAppStore.png')}
              style={{height: 38, width: 139}}
            />
          </StoreButton>
        </StoreButtonContainer>
      </Buttons>
    </Container>
  );
});

const Container = variance(LinearGradient)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 14,
    paddingLeft: 34,
    marginHorizontal: 9,
    borderRadius: 12,
  },
}));

const Content = variance(View)(() => ({
  root: {
    marginRight: 50,
    flex: 4,
  },
}));

const StoreButton = variance(LinearGradient)(() => ({
  root: {
    borderRadius: 8,
    padding: 17,
    alignItems: 'center',
  },
}));

const Buttons = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    flex: 8,
  },
}));

const StoreButtonContainer = variance(Pressable)(() => ({
  root: {
    flex: 1,
  },
  offsetRight: {
    marginRight: 18,
  },
  disabled: {
    opacity: 0.7,
  },
}));
