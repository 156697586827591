import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Action,
  ActionVariant,
  ADAPTIVE_ACTION_LIST_MODAL_BREAKPOINT,
} from './AdaptiveActionListModal';
import {View, StyleSheet, ViewProps} from 'react-native';
import PressableOpacity from '../../PressableOpacity';
import {useTheme, variance} from '../../../styling';
import {AdaptiveModalHeaderProps} from './AdaptiveModalHeader';
import {AdaptiveModal, AdaptiveModalRef, ModalWidth} from '../../templates';
import ChevronRight from '../../../assets/svg/colorless/chevronRight.svg';
import {Body, Header, Paragraph} from '../../Typography';
import {Hoverable} from 'react-native-web-hooks';

export type OptionActionListModalProps = AdaptiveModalHeaderProps & {
  onModalClosed: () => void;
  actionList: Action[];
};

export default observer<OptionActionListModalProps, AdaptiveModalRef>(
  forwardRef(function OptionActionListModal(props, ref) {
    const {actionList, onModalClosed} = props;
    return (
      <AdaptiveModal
        ref={ref}
        adaptiveBreakpoint={ADAPTIVE_ACTION_LIST_MODAL_BREAKPOINT}
        modalWidth={ModalWidth.Small}
        onModalClosed={onModalClosed}
        modalContentStyle={styles.modal}
        enableContentAutoHeight>
        <Container>
          {actionList.map((action, index) => {
            const isLast = actionList.length - 1 === index;
            return (
              <ActionOption
                style={{marginBottom: isLast ? 0 : 8}}
                action={action}
                key={index}
              />
            );
          })}
        </Container>
      </AdaptiveModal>
    );
  }),
);

const styles = StyleSheet.create({
  actionIcon: {
    marginRight: 12,
    width: 18,
    height: 18,
  },

  modal: {
    borderRadius: 12,
    paddingTop: 36,
  },
});

const ChevronRightIcon = variance(ChevronRight)(() => ({
  root: {
    width: 12,
    height: 12,
    marginLeft: 'auto',
  },
}));

const Container = variance(View)(() => ({
  root: {
    padding: 16,
    borderRadius: 12,
  },
}));

const Item = variance(PressableOpacity)((theme) => ({
  root: {
    padding: 12,
    borderRadius: 12,
    backgroundColor: theme.colors.uiAdditional1,
  },
  hover: {
    backgroundColor: theme
      .chroma(theme.colors.primaryAttractive)
      .alpha(0.2)
      .hex(),
  },
}));

const Top = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    color: 'red',
  },
}));

const Label = variance(Body)((theme) => ({
  root: {
    paddingVertical: 2,
    paddingHorizontal: 6,
    backgroundColor: theme.colors.primaryActive,
    marginLeft: 12,
    color: theme.colors.primaryWhite,
    fontSize: 10,
    textTransform: 'uppercase',
    borderRadius: 4,
  },
}));

const Description = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    marginTop: 8,
    fontSize: 14,
  },
}));

export type ActionOptionProps = ViewProps & {
  action: Action;
};

export const ActionOption = ({action, ...rest}: ActionOptionProps) => {
  const {colors} = useTheme();
  const isErrorVariant = action.variant === ActionVariant.Error;
  const iconColor = isErrorVariant ? colors.primaryError : colors.uiSecondary;
  const ActionIcon = action.Icon;

  return (
    <Hoverable>
      {(isHovered) => (
        <PressableOpacity {...rest}>
          <Item
            disabled={action.disabled}
            onPress={action.onPress}
            activeOpacity={0.8}
            hover={isHovered}
            {...rest}>
            <Top>
              {ActionIcon && (
                <ActionIcon style={styles.actionIcon} color={iconColor} />
              )}
              <Header type={'header'} size={'h4'} weight={'500'}>
                {action.title}
              </Header>
              {action.label && (
                <Label type="body" weight="500">
                  {action.label}
                </Label>
              )}
              <ChevronRightIcon
                color={
                  isHovered
                    ? colors.primaryAttractive
                    : colors.primaryUIDirtyBlue
                }
              />
            </Top>
            {action.text && (
              <Description type="paragraph" weight="500">
                {action.text}
              </Description>
            )}
          </Item>
        </PressableOpacity>
      )}
    </Hoverable>
  );
};
