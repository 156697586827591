import BaseIapRequesterImpl from './BaseIapRequesterImpl';
import {RequestPurchaseParams} from '../PurchaseRequester';
import {InAppPurchaseManager, PurchaseResult} from '../../InAppPurchaseManager';
import {ApiStore, FarmId, MetaItem} from '../../ApiStore';
import {Gift, InAppOffers} from '../../InAppOffersService';
import {ErrorRepository} from '../../ErrorRepository';
import {define, WAITED_TRANSACTIONS} from '../../persistence';
import {PendingPurchasesResolver} from '../../PendingPurchasesResolver';
import {Auth} from '../../Auth';
import {error} from '../../fp';
import {PurchaseDiscount} from '../../PurchasePromoService';
import {NOT_CONNECTED, NotConnectedError} from '../../Error';
import {GiftState} from '../../Gift/Gift';
import {UtmUrlListener} from '../../UtmUrlListener';

export default class IosIapRequesterImpl extends BaseIapRequesterImpl {
  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly apiStore: ApiStore;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly gift: GiftState;
      readonly utmUrl: UtmUrlListener;
    },
  ) {
    super(_root);
  }

  protected async requestProduct(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return error(
        this._root.errorRepository.create<NotConnectedError>({
          kind: NOT_CONNECTED,
        }),
      );
    }
    const farmId = authState.accountId;
    const product_ = await this._root.inAppPurchaseManager.requestProduct({
      sku: params.purchaseId,
      purchaseTokenAndroid: undefined,
      obfuscatedAccountIdAndroid: undefined,
      subscriptionOfferTokenAndroid: undefined,
      obfuscatedProfileIdAndroid: undefined,
    });
    await this._addToPendingFinishProductTransitionIos(
      product_,
      farmId,
      params,
    );
    return product_;
  }

  protected async requestSubscription(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return error(
        this._root.errorRepository.create<NotConnectedError>({
          kind: NOT_CONNECTED,
        }),
      );
    }
    const farmId = authState.accountId;
    const subscription_ =
      await this._root.inAppPurchaseManager.requestSubscription({
        sku: params.purchaseId,
        purchaseTokenAndroid: undefined,
        obfuscatedAccountIdAndroid: undefined,
        subscriptionOfferTokenAndroid: undefined,
        obfuscatedProfileIdAndroid: undefined,
      });
    await this._addToPendingFinishSubscriptionTransitionIos(
      subscription_,
      farmId,
      params.gift,
    );
    return subscription_;
  }

  private async _addToPendingFinishProductTransitionIos(
    purchase: PurchaseResult,
    farmId: FarmId,
    params: RequestPurchaseParams,
  ) {
    if (!purchase.success) {
      return;
    }
    const id = purchase.right.transactionId;
    if (!id) {
      return;
    }
    const waitedTransactions = await getWaitedTransactions();
    const newWaitedTransactions: WaitedTransactionsRecord =
      waitedTransactions.success ? waitedTransactions.right ?? {} : {};
    const utm = await this._root.utmUrl.getUtms();
    const meta: MetaItem['meta'] = {
      current_transaction_id: undefined,
      promo: this._code,
      farm_id: farmId,
      gift: {},
      utm,
    };
    if (params.gift) {
      meta.gift = this._getMetaGift(params.gift);
    }
    newWaitedTransactions[id] = {
      transaction_id: purchase.right.transactionId,
      meta: meta,
    };
    if (params.poolMinerIdForRenew) {
      meta.pool_miner_id = params.poolMinerIdForRenew;
    }
    await setWaitedTransactions(newWaitedTransactions);
  }

  private async _addToPendingFinishSubscriptionTransitionIos(
    purchase: PurchaseResult,
    farmId: FarmId,
    gift?: Gift,
  ) {
    if (!purchase.success) {
      return;
    }
    const id =
      purchase.right.originalTransactionIdentifierIOS ||
      purchase.right.transactionId;
    if (!id) {
      return;
    }
    const waitedTransactions = await getWaitedTransactions();
    const utm = await this._root.utmUrl.getUtms();
    const newWaitedTransactions: WaitedTransactionsRecord =
      waitedTransactions.success ? waitedTransactions.right ?? {} : {};
    newWaitedTransactions[id] = {
      transaction_id: purchase.right.originalTransactionIdentifierIOS,
      meta: {
        current_transaction_id: purchase.right.transactionId,
        farm_id: farmId,
        gift: this._getMetaGift(gift),
        utm,
      },
    };
    await setWaitedTransactions(newWaitedTransactions);
  }

  private _getMetaGift(gift?: Gift) {
    if (!gift) {
      return {};
    }
    const {plan, pool_miner_config} = gift;
    return {
      plan_id: plan?.id || null,
      pool_miner_config_id: pool_miner_config?.id || null,
    };
  }
}

type WaitedTransactionsRecord = {
  [id: string]: MetaItem;
};

export const [getWaitedTransactions, setWaitedTransactions] =
  define<WaitedTransactionsRecord>(WAITED_TRANSACTIONS);
