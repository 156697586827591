import {observer} from 'mobx-react-lite';
import React, {forwardRef} from 'react';
import {View, Image, Pressable} from 'react-native';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  Representation,
} from '../components/templates';
import AdvertSplashClose from '../AdvertSplashScreen/AdvertSplashClose';
import useRoot from '../Root/hooks/useRoot';
import {
  CheckBox,
  Header,
  Paragraph,
  PressableOpacity,
  Typography,
} from '../components';
import PlaySvg from '../assets/svg/colored/playVideo.svg';
import {sized} from '../Svg';
import {variance} from '../styling';
import {WIDTH_PREVIEW, MOB_PREVIEW} from './const';
import {useStrings} from '../Root/hooks';

export type PreviewModalProps = {
  navigateToVideoModalWithFlag: () => void;
  onClosedPreviewVideoWithFlag: () => void;
  seconds: number;
  notShownAgain: boolean;
  toggleNotShownAgain: () => void;
  onCrossClose: () => void;
};

export default observer<PreviewModalProps, AdaptiveModalRef>(
  forwardRef(function PreviewModal(props, ref) {
    const {windowDimensionsState} = useRoot();
    const {
      navigateToVideoModalWithFlag,
      onClosedPreviewVideoWithFlag,
      seconds,
      notShownAgain,
      toggleNotShownAgain,
      onCrossClose,
    } = props;
    const contentWidth = windowDimensionsState.window.width - 20;
    const width = contentWidth > WIDTH_PREVIEW ? WIDTH_PREVIEW : contentWidth;
    const strings = useStrings();
    return (
      <AdaptiveModal
        enableContentAutoHeight
        closeIconHidden
        modalContentStyle={{
          width: width,
          maxWidth: 600,
          backgroundColor: 'transparent',
        }}
        ref={ref}
        onModalClosed={onClosedPreviewVideoWithFlag}
        representation={Representation.Modal}>
        <View>
          <AdvertSplashClose onClose={onCrossClose} seconds={seconds} />
          <View>
            <PreviewBlock onPress={navigateToVideoModalWithFlag}>
              <Image
                style={{
                  width: '100%',
                  borderRadius: 10,
                  aspectRatio: 21.5 / 31,
                  height: 500,
                }}
                source={MOB_PREVIEW}
                resizeMode="cover"
              />

              <PlayIconContainer>
                <PlayIcon />
                <TitleText type="header" size="h2">
                  {strings['introVideo.video.title']}
                </TitleText>
                <DescText size="large" type={'paragraph'}>
                  {strings['introVideo.video.desc']}
                </DescText>
              </PlayIconContainer>
            </PreviewBlock>
            <CheckBoxContainer hitSlop={5} onPress={toggleNotShownAgain}>
              <CheckBox checked={notShownAgain} />
              <CheckBoxLabel type="paragraph" weight="400">
                {strings['introVideo.checkboxLabel']}
              </CheckBoxLabel>
            </CheckBoxContainer>
          </View>
        </View>
      </AdaptiveModal>
    );
  }),
);
const PreviewBlock = variance(Pressable)(() => ({
  root: {},
}));
const CheckBoxLabel = variance(Typography)((theme) => ({
  root: {color: theme.colors.primaryWhite},
}));
const CheckBoxContainer = variance(PressableOpacity)(() => ({
  root: {
    marginTop: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
    alignItems: 'center',
  },
}));
const PlayIconContainer = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 150,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const TitleText = variance(Header)((theme) => ({
  root: {
    marginTop: 24,
    color: theme.colors.primaryWhite,
  },
}));
const DescText = variance(Paragraph)(() => ({
  root: {
    marginTop: 12,
    fontSize: 16,
    lineHeight: 24,
    color: '#C7CDD9',
    textAlign: 'center',
  },
}));

const PlayIcon = sized(PlaySvg, 100);
