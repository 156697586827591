import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import WorkerMenuHeader from '../../../../components/organisms/worker/WorkerMenuHeader';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';

export type MenuHeaderProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  index: number;
  onHeaderPress?: () => void;
};

export default observer(function MenuHeader({
  worker,
  index,
  onHeaderPress,
}: MenuHeaderProps) {
  const {goToWorkerAction} = useForcedContext(DashboardContext);
  const onMenuPress = useCallback(() => {
    goToWorkerAction(worker.id);
  }, [goToWorkerAction, worker]);
  return (
    <WorkerMenuHeader
      mode="detail"
      onHeaderPress={onHeaderPress}
      onMenuPress={onMenuPress}
      index={index + 1}
      worker={worker}
    />
  );
});
