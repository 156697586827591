import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import {useGetIsDimensions} from '../WindowDimensions/useDimensions';
import RefreshControl from '../components/RefreshControl';
import TotalPoolMinersSection from './components/TotalPoolMinersSection';
import TotalPriceSection from './TotalPriceBinding';
import PurchaseScreenTemplate from './PurchaseScreenTemplate';
import PurchaseBannerLine from './components/PurchaseBannerLine';
import TabsBinding from './TabsBinding';
import TabContentSwitcherBinding from './TabContentSwitcherBinding';
import PurchaseScreenContext, {PurchaseScreenProps} from './context';
import PurchaseMethods from './components/PurchaseMethods';

export default observer(function PurchaseScreen(props: PurchaseScreenProps) {
  const {
    isRefreshing,
    onRefresh,
    subscription,
    visibleReactivationTab,
    onWorkerQuestionPress,
    ...rest
  } = props;
  const getIsLarge = useGetIsDimensions('lg');
  const renderHeader = useCallback(
    () =>
      getIsLarge() ? (
        <PurchaseBannerLine {...rest} />
      ) : (
        <TotalPoolMinersSection
          onQuestionPress={onWorkerQuestionPress}
          subscription={subscription}
        />
      ),
    [getIsLarge, onWorkerQuestionPress, rest, subscription],
  );
  const renderFooter = useCallback(() => <TotalPriceSection />, []);
  const renderAdditional = useCallback(() => <PurchaseMethods />, []);
  return (
    <PurchaseScreenContext.Provider value={props}>
      <View style={styles.root}>
        <PurchaseScreenTemplate
          renderHeader={renderHeader}
          refreshControl={
            <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
          }
          renderFooter={renderFooter}
          renderAdditional={renderAdditional}>
          {visibleReactivationTab && <TabsBinding />}
          <TabContentSwitcherBinding />
        </PurchaseScreenTemplate>
      </View>
    </PurchaseScreenContext.Provider>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  banner: {
    height: 100,
    marginBottom: 18,
    marginHorizontal: 16,
  },
});
