import {useCallback} from 'react';
import {CommonActions, useNavigation} from '@react-navigation/native';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {DASHBOARD} from '../RootStack/constants';

export default function useResetToDashboard(
  initialState?: PartialState<NavigationState> | NavigationState,
) {
  const navigation = useNavigation();
  return useCallback(
    () => navigation.dispatch(CommonActions.reset(initialState ?? DASHBOARD)),
    [initialState, navigation],
  );
}
