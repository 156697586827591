import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {expr} from 'mobx-utils';
import {RenameWorkerModal} from '../../RenameWorkerModal';
import {useErrorHandler} from '../../ExceptionHandler';

export type RenameWorkerBindingProps = RootStackBindingProps<'RenameWorker'>;

export default observer(function RenameWorkerBinding({
  route,
}: RenameWorkerBindingProps) {
  const {connectedClient} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const close = () => modalRef.current?.close();
  const {dashboardStore} = useRoot();
  const workerId = route.params?.workerId;
  const worker = expr(() =>
    workerId !== undefined ? dashboardStore.workers.get(workerId) : undefined,
  );
  const onCancel = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [safelyGoBack]);
  const errorHandler = useErrorHandler();
  const onConfirm = useCallback(
    async (workerName: string) => {
      await close();
      if (workerId === undefined || worker === undefined) {
        return;
      }
      const result = await connectedClient.call('set_worker_name', {
        worker_id: worker.id,
        name: workerName,
      });
      if (!result.success) {
        errorHandler.handle(result.left);
      }
      safelyGoBack();
    },
    [connectedClient, errorHandler, safelyGoBack, worker, workerId],
  );
  const modalRef = useRef<AdaptiveModalRef>(null);
  return (
    <RenameWorkerModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onConfirm={onConfirm}
      onCancel={onCancel}
      initialWorkerName={worker?.name ?? '-'}
    />
  );
});
