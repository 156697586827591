import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import RingSvg from '../assets/svg/colorless/ring.svg';
import {useTheme, variance, useStyles} from '../styling';
import {Header} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import TryDemoMinerBanner from '../components/organisms/banner/TryDemoMinerBanner';
import TryTheNewPoolMinersBanner from '../components/organisms/banner/TryTheNewPoolMinersBanner';
import {sized} from '../Svg';
import BannerList from '../components/templates/BannerList';
import {ApiMode} from '../farmApi';
import UnlockThePossibilitiesOfMiningBanner from '../components/organisms/banner/UnlockThePossibilitiesOfMiningBanner';
import {expr} from 'mobx-utils';
import {XL_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type NotificationEmptyListProps = {
  goToAddDemoMiner: () => void;
  goToPlan: () => void;
  goToScanQr: () => void;
  goToDownloadMiner: () => void;
};

export default observer(function NotificationEmptyList({
  goToAddDemoMiner,
  goToPlan,
}: NotificationEmptyListProps) {
  const {
    apiStore,
    appTemplateState: {isTablet},
    windowDimensionsState: {window},
  } = useRoot();
  const theme = useTheme();
  const strings = useStrings();
  const visibleFirstBanner = expr(() => apiStore.mode === ApiMode.Demo);
  const visibleThirdBanner = expr(() =>
    visibleFirstBanner ? window.width > XL_BREAKPOINT : true,
  );
  const themedStyles = useStyles(() => ({
    container: {
      flexGrow: 1,
      paddingHorizontal: 20,
    },
    largeContainer: {
      padding: 20,
      backgroundColor: theme.colors.uiMain,
      borderRadius: 12,
    },
  }));
  return (
    <ScrollView
      contentContainerStyle={[
        themedStyles.container,
        isTablet && themedStyles.largeContainer,
      ]}>
      <RingView large={isTablet}>
        <RingIcon color={theme.colors.uiSecondary} />
        <TitleText large={isTablet} type="header" size="h3" weight="500">
          {strings['emptyNotifications.title']}
        </TitleText>
      </RingView>
      <BannerList style={styles.bannerList}>
        {visibleFirstBanner && (
          <TryDemoMinerBanner
            style={styles.banner}
            onButtonPress={goToAddDemoMiner}
          />
        )}
        <UnlockThePossibilitiesOfMiningBanner
          style={styles.banner}
          onButtonPress={goToPlan}
        />
        {visibleThirdBanner && (
          <TryTheNewPoolMinersBanner
            style={styles.banner}
            onButtonPress={goToPlan}
          />
        )}
      </BannerList>
    </ScrollView>
  );
});

const RingIcon = sized(RingSvg, 72, 84);

const styles = StyleSheet.create({
  banner: {
    height: 150,
  },
  bannerList: {
    marginTop: 'auto',
    marginBottom: 15,
  },
});

const RingView = variance(View)(() => ({
  root: {
    flex: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  large: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 60,
  },
}));

const TitleText = variance(Header)(() => ({
  root: {
    marginTop: 12,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 22,
  },
  large: {
    marginTop: 0,
    marginLeft: 40,
  },
}));
