import React, {forwardRef, PropsWithChildren} from 'react';
import {View, ViewProps} from 'react-native';

import {variance} from '../styling';

export default forwardRef<View, PropsWithChildren<ViewProps>>(
  ({style, ...rest}, ref) => {
    return <Container ref={ref} style={style} {...rest} />;
  },
);

const Container = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
  },
}));
