import {GroupId} from '../ApiStore';
import {DashboardBannersState} from './DashboardBannersState';

export enum SlotMode {
  Detail,
  Simple,
}

export enum WorkerOrder {
  Default,
  AverageHashrate,
  MinedTotal,
  Name,
  Status,
}

export interface Dashboard {
  readonly bannersState: DashboardBannersState;
  readonly order: WorkerOrder;
  readonly collapsedGroups: GroupId[];
  getOrder: () => WorkerOrder;
  getCollapsedGroups: () => GroupId[];
  setOrder: (order: WorkerOrder) => void;
  collapseGroup: (group: GroupId) => void;
  expandGroup: (group: GroupId) => void;
  toggleCollapseGroup: (group: GroupId) => void;
}
