import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {createStylesHook} from '../../../../styling';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';
import WorkerPowerSlider from '../../../../components/organisms/worker/WorkerPowerSlider';

export type PowerSliderProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function PowerSlider({worker}: PowerSliderProps) {
  const styles = useStyles();
  const {goToPoolMinerSchedulerWarning, onWorkerSpeedChanged} =
    useForcedContext(DashboardContext);
  return (
    <View style={styles.root}>
      <WorkerPowerSlider
        visibleTitle={false}
        onValueChanged={onWorkerSpeedChanged}
        worker={worker}
        goToPoolMinerSchedulerWarning={goToPoolMinerSchedulerWarning}
      />
    </View>
  );
});

const useStyles = createStylesHook(() => ({
  root: {},
}));
