import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../styling';
import {View} from 'react-native';
import {Body, Divider, Header} from '../../components';
import {coins} from './coinsConfig';
import CardSvg from '../../assets/svg/colorless/card.svg';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {sized} from '../../Svg';
import {useStrings} from '../../Root/hooks';
import {useOpenLink} from '../../Links';

export default observer(function PurchaseMethods() {
  const theme = useTheme();
  const strings = useStrings();
  const openLink = useOpenLink('https://cryptotabcoin.net');
  return (
    <Container>
      <Top>
        <Header type="header" size="h4" weight="600">
          {strings['purchase.methods']}
        </Header>
        <Card onPress={openLink}>
          <CardIcon color={theme.colors.primaryUIDirtyBlue} />
          <CardText type="body" weight="500">
            {strings['purchase.methods.card']}
          </CardText>
        </Card>
      </Top>
      <Divider />
      <Coins>
        {coins.map(({id, Icon, name}) => (
          <Coin key={id}>
            <Icon />
            <CoinText type="header" size="h4" weight="700">
              {name}
            </CoinText>
          </Coin>
        ))}
      </Coins>
    </Container>
  );
});

const CardIcon = sized(CardSvg, 18, 18);

const Container = variance(View)((theme) => ({
  root: {
    marginTop: 18,
    backgroundColor: theme.colors.backgroundGround,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
}));

const Card = variance(TouchableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const CardText = variance(Body)((theme) => ({
  root: {
    fontSize: 14,
    color: theme.colors.primaryUIDirtyBlue,
    marginLeft: 8,
  },
}));

const Top = variance(View)(() => ({
  root: {
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const Coins = variance(View)(() => ({
  root: {
    paddingHorizontal: 3,
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

const Coin = variance(View)(() => ({
  root: {
    paddingVertical: 10,
    paddingHorizontal: 21,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const CoinText = variance(Header)(() => ({
  root: {
    marginLeft: 10,
  },
}));
