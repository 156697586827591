import {useRoot} from '../Root/hooks';
import {useNavigationGetIsFocused, useSafelyGoBack} from '../Navigation/hooks';
import {useEffect} from 'react';
import {autorun} from 'mobx';
import {REJECTED} from '../AsyncAtom';
import {OrderPaymentState} from './OrderPaymentState';
import {useErrorHandler} from '../ExceptionHandler';

export function useStateEffect(state: OrderPaymentState) {
  const {connectedClient, auth} = useRoot();
  const errorHandler = useErrorHandler();
  const getIsFocused = useNavigationGetIsFocused();
  const safelyGoBack = useSafelyGoBack();
  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused() && auth.isConnected) {
          state.fetch().then((res) => {
            if (!res) {
              safelyGoBack();
            }
            if (res?.status === REJECTED) {
              errorHandler.handle(res.error);
            }
          });
        }
      }),
    [auth, connectedClient, errorHandler, getIsFocused, safelyGoBack, state],
  );
}
