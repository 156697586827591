import React, {forwardRef, PropsWithChildren} from 'react';
import {StyleProp, TextProps, TextStyle, View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import {SvgProps} from 'react-native-svg';
import Typography from '../Typography';
import {
  ButtonSize,
  ButtonVariant,
  useGetGradientStyles,
  useButtonStyles,
} from './useButtonStyles';
import PressableOpacity, {PressableOpacityProps} from '../PressableOpacity';
import {LinearGradient} from 'expo-linear-gradient';
import {Hoverable} from 'react-native-web-hooks';

export type ButtonProps = PressableOpacityProps & {
  variant?: ButtonVariant;
  Icon?: React.ComponentType<SvgProps>;
  rootStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textProps?: TextProps;
  size?: ButtonSize;
  children?: string;
};

export default observer<PropsWithChildren<ButtonProps>, View>(
  forwardRef(function Button(props, ref) {
    const {
      variant = ButtonVariant.Default,
      Icon,
      children,
      textStyle,
      rootStyle,
      textProps,
      disabled = false,
      ...rest
    } = props;
    const styles = useButtonStyles(props);
    const gradient = useGetGradientStyles(variant);
    const content = (
      hoverColor?: StyleProp<TextStyle>,
      isHovered?: boolean,
    ) => (
      <>
        {Icon && <Icon style={[styles.icon, isHovered && hoverColor]} />}
        {children && (
          <Typography
            selectable={false}
            weight="600"
            type="body"
            {...textProps}
            style={[styles.text, textStyle, isHovered && hoverColor]}>
            {children}
          </Typography>
        )}
      </>
    );

    if (gradient) {
      return (
        <Hoverable>
          {(isHovered) => (
            <PressableOpacity disabled={disabled} {...rest} ref={ref}>
              <LinearGradient
                colors={gradient.getColor(isHovered)}
                start={gradient.start}
                end={gradient.end}
                style={[styles.root, rootStyle, textStyle]}>
                {content({color: gradient.hoveredTextColor}, isHovered)}
              </LinearGradient>
            </PressableOpacity>
          )}
        </Hoverable>
      );
    }

    return (
      <PressableOpacity disabled={disabled} {...rest} ref={ref}>
        <View style={[styles.root, rootStyle]}>{content()}</View>
      </PressableOpacity>
    );
  }),
);
