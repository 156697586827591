import React, {ReactNode, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {StyleProp, View, ViewStyle} from 'react-native';
import LightsSvg from '../assets/svg/colored/lights.svg';
import {Header, Paragraph} from '../components';
import QuickStartDiscount from './QuickStartDiscount';
import QuickStartList, {QuickStartListProps} from './QuickStartList';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {useRoot, useStrings, useTemplates} from '../Root/hooks';
import {CryptoLoader} from '../components/atoms/CryptoLoader';
import {Duration, QuickStartOffer} from '../QuickStartService';
import getHumanizeDuration from '../utils/getHumanizeDuration';
import {Millisecond} from '../Time';
import dayjs from 'dayjs';
import getDaysByUnitAndValue from '../InAppPurchaseManager/getDaysByUnitAndValue';
import intervalTypeToUnits from '../InAppPurchaseManager/intervalTypeToUnits';

export type QuickStartScreenViewProps = QuickStartListProps & {
  onSubscribe: () => void;
  onSetting: () => void;
  Check?: ReactNode;
  showSkip?: boolean;
  subscribeDisabled: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  isLoading: boolean;
  trial: QuickStartOffer['trial'];
  duration: Duration | undefined;
};

export default observer(function QuickStartScreenView({
  onSubscribe,
  onSetting,
  showSkip,
  Check,
  subscribeDisabled,
  containerStyle,
  isLoading,
  trial,
  duration,
  items,
  selectedItemIndex,
  ...rest
}: QuickStartScreenViewProps) {
  const {translation} = useRoot();
  const strings = useStrings();
  const templates = useTemplates();
  const humanizeDuration = duration
    ? getHumanizeDuration(
        dayjs
          .duration(duration.interval, duration.interval_type)
          .asMilliseconds() as Millisecond,
        translation.localeTag,
        intervalTypeToUnits(duration.interval_type),
      )
    : undefined;
  const description = trial
    ? humanizeDuration
      ? templates['quickStart.discount.subtitle.trial']({
          duration: humanizeDuration,
        })
      : undefined
    : strings['quickStart.discount.subtitle'];

  const label = useMemo(() => {
    if (!trial) {
      return strings['quickStart.description'];
    }
    const days = getDaysByUnitAndValue(trial.value, trial.unit);
    const dayjsDuration = dayjs
      .duration(days, 'day')
      .asMilliseconds() as Millisecond;
    const humanized = getHumanizeDuration(
      dayjsDuration,
      translation.localeTag,
      ['d'],
    );
    return templates['quickStart.description.trial']({value: humanized});
  }, [strings, templates, translation, trial]);
  const subscribeButton = useMemo(() => {
    if (!selectedItemIndex) {
      return strings['quickStart.button.subscribe'];
    }
    const selected = items[selectedItemIndex];
    if (!selected) {
      return strings['quickStart.button.subscribe'];
    }

    return (
      strings['action.subscribe'] +
      ' ' +
      selected.price +
      '/' +
      selected.interval +
      ' ' +
      strings['common.shortMonth']
    );
  }, [items, selectedItemIndex, strings]);
  return (
    <Container style={containerStyle}>
      <LgTop>
        <LgTitleContainer>
          <LightIcon>
            <LightsSvg />
          </LightIcon>
          <View>
            <Title type="header" weight="700" size="h4">
              {strings['quickStart.title']}
            </Title>
            <Description type="paragraph" weight="500">
              {label}
            </Description>
          </View>
        </LgTitleContainer>
        <QuickStartDiscount description={description} trial={trial} />
      </LgTop>
      {isLoading ? (
        <LoaderContainer>
          <CryptoLoader />
        </LoaderContainer>
      ) : (
        <QuickStartList
          selectedItemIndex={selectedItemIndex}
          items={items}
          {...rest}
        />
      )}
      <Footer>
        <Btn
          disabled={subscribeDisabled}
          offsetBottom
          variant={ButtonVariant.Primary}
          onPress={onSubscribe}>
          {subscribeButton}
        </Btn>
        <HelpText type="paragraph">
          {strings['quickStart.subscriptionHelper']}
        </HelpText>
        <Btn variant={ButtonVariant.Default} onPress={onSetting}>
          {showSkip
            ? strings['quickStart.button.skip']
            : strings['quickStart.button.set']}
        </Btn>
      </Footer>
      {Check}
    </Container>
  );
});

const LgTitleContainer = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
      },
    }),
  },
}));

const LoaderContainer = variance(View)(() => ({
  root: {
    paddingVertical: 100,
    alignItems: 'center',
  },
}));

const LgTop = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 30,
      },
    }),
  },
}));

const Container = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 16,
    flex: 1,
    paddingVertical: 24,
    backgroundColor: theme.colors.uiMain,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        maxWidth: 900,
        width: '100%',
        borderRadius: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingHorizontal: 20,
        paddingTop: 24,
      },
    }),
  },
}));

const Footer = variance(View)((theme) => ({
  root: {
    marginTop: 'auto',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row-reverse',
        marginTop: 28,
        justifyContent: 'space-between',
      },
    }),
  },
}));

const Btn = variance(AppButton)((theme) => ({
  root: {
    height: 44,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 328,
        width: '100%',
      },
    }),
  },
  offsetBottom: {
    marginBottom: 6,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 0,
      },
    }),
  },
}));

const Title = variance(Header)((theme) => ({
  root: {
    textAlign: 'center',
    marginBottom: 8,
    fontSize: 18,
    lineHeight: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },
}));

const Description = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
    marginBottom: 24,
    fontSize: 15,
    lineHeight: 18,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
        marginBottom: 0,
        maxWidth: 417,
      },
    }),
  },
}));

const HelpText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
    marginBottom: 12,
    fontSize: 13,
    lineHeight: 16,
  },
}));

const LightIcon = variance(View)((theme) => ({
  root: {
    width: 32,
    height: 32,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 8,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 64,
        height: 64,
        marginLeft: 0,
        marginRight: 12,
        marginBottom: 0,
      },
    }),
  },
}));
