import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {variance} from '../styling';
import CalendarCell from './CalendarCell';
import {State} from './CalendarModalReducer';
import {getCellCalendarInfo, getMonthInfo} from './CalendarModalSettings';

export type CalendarModalMonthViewProps = {
  state: State;
  setNewDay: (day: number, month: number) => void;
  onBack: () => void;
};

export default observer(function CalendarModalMonthView({
  setNewDay,
  state,
  onBack,
}: CalendarModalMonthViewProps) {
  const monthInfo = getMonthInfo(state.month, state.year, state.date);
  const cellCount = 42;
  const calendarData = getCellCalendarInfo(monthInfo, cellCount);
  const onCellPress = (index: number, day: number) => {
    return index < monthInfo.firstDate
      ? () => setNewDay(day, monthInfo.activeMonth - 1)
      : index >= monthInfo.firstDate + monthInfo.daysInMonth
      ? () => setNewDay(day, monthInfo.activeMonth + 1)
      : day === monthInfo.activeDay
      ? onBack
      : () => setNewDay(day, monthInfo.activeMonth);
  };

  return (
    <Container>
      {calendarData.map((day, index) => (
        <CalendarCell
          active={
            day === monthInfo.activeDay &&
            monthInfo.activeMonth === state.date.getMonth()
          }
          day={day}
          key={index}
          disabled={
            index < monthInfo.firstDate ||
            index >= monthInfo.firstDate + monthInfo.daysInMonth
          }
          last={index === calendarData.length - 1}
          first={index === 0}
          onPress={onCellPress(index, day)}
        />
      ))}
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));
