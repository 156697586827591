import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import CalendarModalScreen from '../../CalendarModalScreen';
import {Millisecond} from '../../Time';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {AdaptiveModalRef} from '../../components/templates';

export type CalendarBindingProps = RootStackBindingProps<'Calendar'>;

export default observer(function CalendarBinding({
  route,
}: CalendarBindingProps) {
  const onChange = route.params?.onChange;
  const value = route.params?.value;
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const handleCellPress = useCallback(
    (from: Millisecond) => onChange?.(from),
    [onChange],
  );
  const onClose = useCallback(async () => {
    await modalRef.current?.close();
    safelyGoBack();
  }, [safelyGoBack]);

  return (
    <CalendarModalScreen
      ref={modalRef}
      value={value && new Date(value)}
      onSubmit={handleCellPress}
      close={onClose}
      onModalClosed={safelyGoBack}
    />
  );
});
