import React, {useCallback, useEffect, useRef} from 'react';
import {Keyboard} from 'react-native';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {AdaptiveModalRef} from '../components/templates';
import {AdaptiveActionModal} from '../components/organisms';
import {AppButton, ButtonVariant} from '../components/AppButton';

export type NotAllowedModalProps = {
  description?: string;
  onClose: () => void;
};

export default observer((props: NotAllowedModalProps) => {
  const {description, onClose} = props;
  const {navigationContainer} = useRoot();
  const strings = useStrings();
  const modalRef = useRef<AdaptiveModalRef>(null);

  useEffect(() => {
    Keyboard.dismiss();
  }, []);

  const onDismiss = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    navigationContainer.ref?.navigate('Plan');
    onClose();
  }, [navigationContainer, onClose]);

  return (
    <AdaptiveActionModal
      ref={modalRef}
      onModalClosed={onDismiss}
      IconSvg={WarningSvg}
      title={strings['modal.notAllowed.title']}
      description={description}
      actions={[
        <AppButton onPress={onDismiss}>{strings['action.ok']}</AppButton>,
        <AppButton variant={ButtonVariant.Primary} onPress={onSubmit}>
          {strings['common.purchase']}
        </AppButton>,
      ]}
    />
  );
});
