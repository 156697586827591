import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {useStyles, useTheme} from '../styling';
import {CheckBox, PressableOpacity, Typography} from '../components';
import {StyleSheet} from 'react-native';

export type WorkAllTimeCheckboxProps = {
  active: boolean;
  onPress: () => void;
};

export default observer(function WorkAllTime(props: WorkAllTimeCheckboxProps) {
  const {active, onPress} = props;
  const strings = useStrings();
  const theme = useTheme();
  const themedStyles = useStyles(() => ({
    text: {
      color: theme.colors.uiSecondary,
      letterSpacing: 0.04,
      marginLeft: 10,
    },
  }));
  return (
    <PressableOpacity hitSlop={5} onPress={onPress} style={styles.root}>
      <CheckBox checked={active} />
      <Typography type="paragraph" weight="500" style={themedStyles.text}>
        {strings['scheduler.workAllTime']}
      </Typography>
    </PressableOpacity>
  );
});

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
