import React, {useCallback, useMemo} from 'react';
import {
  StyleSheet,
  FlatListProps,
  TouchableWithoutFeedback,
  View,
  ViewProps,
  FlatList,
  Platform,
  ListRenderItem,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {
  TemporarySubscriptionView,
  PermanentSubscriptionView,
} from '../SubscriptionView';
import SwitchFarmHeader from '../components/SwitchFarmScreenHeader';
import {RestoredSubscription} from '../farmApi';
import {useStyles, variance} from '../styling';
import {ReadonlyDeep} from 'type-fest';
import {createNullableContext, useForcedContext} from '../context';
import {expr} from 'mobx-utils';
import {AppButton, ButtonVariant} from '../components/AppButton';
import PlusCircledSvg from '../assets/svg/colorless/plus_circled.svg';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {Typography} from '../components';
import {SafeAreaView} from 'react-native-safe-area-context';

export type AccessRecoveryScreenProps = {
  getSubscriptions: () => ReadonlyDeep<RestoredSubscription[]> | undefined;
  getIsRefreshing: () => boolean;
  onRefresh: () => void;
  onRestore: (subscription: RestoredSubscription) => void;
  onRegister?: () => void;
  registerShown?: boolean;
};

const insets = StyleSheet.create({
  header: {
    marginBottom: 10,
  },
});

export default observer((props: AccessRecoveryScreenProps) => {
  const {getSubscriptions, getIsRefreshing, onRefresh, registerShown} = props;
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          paddingVertical: 10,
        },
      }),
    },
    item: {
      marginBottom: 10,
    },
    itemLast: {
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          marginBottom: 0,
        },
      }),
    },
    list: {
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          width: 820,
          maxWidth: '100%',
          marginTop: Platform.OS === 'web' ? 'auto' : 0,
          marginBottom: Platform.OS === 'web' ? 'auto' : 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: 12,
          borderWidth: 1,
          borderColor: theme.colors.uiAdditional1,
          overflow: 'hidden',
        },
      }),
    },
  }));
  const data = expr(getSubscriptions);
  const refreshing = expr(getIsRefreshing);
  const ListFooterComponent = registerShown ? ListFooter : undefined;
  const SubscriptionsListHeader = useMemo(() => {
    return data ? <ListHeader accountAmount={data.length} /> : null;
  }, [data]);
  const renderItem: ListRenderItem<RestoredSubscription> = useCallback(
    ({item}) => (
      <Item
        style={[styles.item, !registerShown && styles.itemLast]}
        item={item}
      />
    ),
    [registerShown, styles],
  );
  return (
    <AccessRecoveryContext.Provider value={props}>
      <SafeAreaView edges={['bottom']} style={styles.root}>
        <FlatList
          contentContainerStyle={styles.list}
          ListHeaderComponent={SubscriptionsListHeader}
          ListHeaderComponentStyle={insets.header}
          ListFooterComponent={ListFooterComponent}
          ListEmptyComponent={data && ListEmpty}
          data={data}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      </SafeAreaView>
    </AccessRecoveryContext.Provider>
  );
});

type ListProps = FlatListProps<RestoredSubscription>;

const keyExtractor: ListProps['keyExtractor'] = (item) =>
  String(item.accountId);

const ListHeader = observer(({accountAmount}: {accountAmount: number}) => {
  const strings = useStrings();
  return (
    <ContainerView>
      <SwitchFarmHeader
        description={strings['recoveryAccess.description']}
        accountAmount={accountAmount}
      />
    </ContainerView>
  );
});

export interface ItemProps extends ViewProps {
  item: RestoredSubscription;
}

export const Item = observer(({item, ...rest}: ItemProps) => {
  const {onRestore} = useForcedContext(AccessRecoveryContext);
  const onPress = useCallback(() => onRestore(item), [item, onRestore]);
  return (
    <ContainerView>
      <TouchableWithoutFeedback onPress={onPress}>
        {item.type === 'Temporary' ? (
          <TemporarySubscriptionView {...item} {...rest} />
        ) : (
          <PermanentSubscriptionView {...item} {...rest} />
        )}
      </TouchableWithoutFeedback>
    </ContainerView>
  );
});

const ListEmpty = observer(() => {
  const styles = useStyles((theme) => ({
    root: {
      minHeight: 200,
      justifyContent: 'center',
    },
    text: {
      letterSpacing: 0.0609524,
      color: theme.colors.uiAdditional4,
      textAlign: 'center',
    },
  }));
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <Typography type={'paragraph'} size={'large'} style={styles.text}>
        {strings['common.noData']}
      </Typography>
    </View>
  );
});

const ListFooter = observer(() => {
  const {onRegister} = useForcedContext(AccessRecoveryContext);
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    button: {
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          width: 330,
          maxWidth: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }),
    },
  }));
  return (
    <ContainerView>
      <ListFooterView>
        <AppButton
          variant={ButtonVariant.Primary}
          style={styles.button}
          Icon={PlusCircledSvg}
          onPress={onRegister}>
          {strings['signUp.newRegister']}
        </AppButton>
      </ListFooterView>
    </ContainerView>
  );
});

const ListFooterView = variance(View)(() => ({
  root: {
    paddingTop: 10,
    paddingBottom: 16,
  },
}));

const ContainerView = variance(View)(() => ({
  root: {
    maxWidth: 1000,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const AccessRecoveryContext =
  createNullableContext<AccessRecoveryScreenProps>();
