import {observer} from 'mobx-react-lite';
import React, {ForwardedRef, forwardRef} from 'react';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {AppButton} from '../components/AppButton';
import {AdaptiveActionModal} from '../components/organisms';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings} from '../Root/hooks';

export type PriceChangedModalProps = {
  onModalClosed: () => void;
  onButtonPress: () => void;
};

export default observer(
  forwardRef(function PriceChangedModal(
    {onModalClosed, onButtonPress}: PriceChangedModalProps,
    modalRef: ForwardedRef<AdaptiveModalRef>,
  ) {
    const strings = useStrings();
    return (
      <AdaptiveActionModal
        ref={modalRef}
        onModalClosed={onModalClosed}
        IconSvg={WarningSvg}
        title={strings['modal.priceOutdatedError.title']}
        description={strings['modal.priceOutdatedError.description']}
        actions={[
          <AppButton onPress={onButtonPress}>
            {strings['modal.priceOutdatedError.submit']}
          </AppButton>,
        ]}
      />
    );
  }),
);
