import {Matrix} from './types';
import _ from 'lodash';
import minuteListToMatrix from './minuteListToMatrix';
import pointHourUnitListToMinutes from './pointHourUnitListToMinutes';
import shiftMinuteListByMinutes from './shiftMinuteListByMinutes';

export default function shiftMatrixByTimezone(
  M: Matrix,
  timezoneOffset: number,
): Matrix {
  if (timezoneOffset === 0) {
    return M;
  }
  const minuteList: number[] = shiftMinuteListByMinutes(
    pointHourUnitListToMinutes(_.flattenDeep(M)),
    timezoneOffset,
  );

  return minuteListToMatrix(minuteList);
}
