import {
  CryptoPurchaseRequester,
  IapPurchaseRequester,
  PurchaseRequesterError,
  RequestParams,
} from './PurchaseRequester';
import CryptoPurchaseRequesterImpl from './CryptoPurchaseRequesterImpl';
import {PurchaseDiscount} from '../PurchasePromoService';
import {InAppOffers, PaymentMethod} from '../InAppOffersService';
import {ErrorRepository} from '../ErrorRepository';
import {ConnectedClient} from '../ContextClient';
import {Configuration} from '../Configuration';
import IapRequesterFactoryImpl from './IapRequester/IapRequesterFactoryImpl';
import {ApiStore} from '../ApiStore';
import {Auth} from '../Auth';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {Either, success} from '../fp';
import {PurchaseRequesterManager} from './PurchaseRequesterManager';
import {ContextPurchaseResponse, PaymentType} from './ContextPurchaseResponse';
import {GiftState} from '../Gift/Gift';
import {UtmUrlListener} from '../UtmUrlListener';

export default class PurchaseRequesterManagerImpl
  implements PurchaseRequesterManager
{
  private _cryptoRequester: CryptoPurchaseRequester;
  private _iapRequester: IapPurchaseRequester;

  constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly auth: Auth;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly gift: GiftState;
      readonly utmUrl: UtmUrlListener;
    },
  ) {
    this._cryptoRequester = new CryptoPurchaseRequesterImpl(_root);
    this._iapRequester = new IapRequesterFactoryImpl(_root).create();
  }

  async request(
    params: RequestParams,
  ): Promise<Either<ContextPurchaseResponse, PurchaseRequesterError>> {
    switch (params.payment) {
      case PaymentMethod.InApp:
        const iap_ = await this._iapRequester.request(params);
        if (iap_.success) {
          return success({...iap_.right, kind: PaymentType.InAppCompleted});
        }
        return iap_;
      default:
        const crypto_ = await this._cryptoRequester.request(params);
        if (crypto_.success) {
          return success({
            ...crypto_.right,
            kind: PaymentType.ExternalOrder,
          });
        }
        return crypto_;
    }
  }
}
