import {batchDisposers, RouterImpl, Service} from '../structure';
import {ProposalsState, SETTLED} from './ProposalsState';
import {makeObservable, observable, reaction} from 'mobx';
import {InAppOffers} from '../InAppOffersService';
import {PurchaseDiscount} from '../PurchasePromoService';
import {CurrentSubscriptionOffer} from '../CurrentSubscriptionOfferService';
import ActivatorProposalsStateImpl from './ActivatorProposalsStateImpl';
import MinerProposalsStateFactory from './MinerProposalsStateFactory';
import {MinerProposalsState} from './MinerProposalsState';
import {ActivatorProposalsState} from './ActivatorProposalsState';

export default class ProposalsStateImpl implements ProposalsState, Service {
  @observable private _isLoadedIn: boolean = false;

  private readonly _minerProposalsState: MinerProposalsState;
  private readonly _activatorProposalsState: ActivatorProposalsState;

  constructor(
    protected readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly currentSubscriptionOfferService: CurrentSubscriptionOffer;
    },
  ) {
    makeObservable(this);
    this._minerProposalsState = new MinerProposalsStateFactory(_root).create();
    this._activatorProposalsState = new ActivatorProposalsStateImpl(_root);
  }

  get isLoadedIn() {
    return this._isLoadedIn;
  }

  get minerProductProposalList() {
    return this._minerProposalsState.minerProductProposalList;
  }
  get minerProductProposalById() {
    return this._minerProposalsState.minerProductProposalById;
  }
  get doubleProposalByIntervalByHashrate() {
    return this._minerProposalsState.doubleProposalByIntervalByHashrate;
  }
  get doubleProposalList() {
    return this._minerProposalsState.doubleProposalList;
  }
  get minerProposalById() {
    return this._minerProposalsState.minerProposalById;
  }
  get minerProposalList() {
    return this._minerProposalsState.minerProposalList;
  }
  get minerConfigsByInterval() {
    return this._minerProposalsState.minerConfigsByInterval;
  }
  get minerConfigByHashrate() {
    return this._minerProposalsState.minerConfigByHashrate;
  }
  get minerConfigs() {
    return this._minerProposalsState.minerConfigs;
  }
  get minerSubscriptionProposalById() {
    return this._minerProposalsState.minerSubscriptionProposalById;
  }
  get minerSubscriptionProposalList() {
    return this._minerProposalsState.minerSubscriptionProposalList;
  }
  get uniqProductIntervalList() {
    return this._minerProposalsState.uniqProductIntervalList;
  }
  get uniqSubscriptionIntervalList() {
    return this._minerProposalsState.uniqSubscriptionIntervalList;
  }
  get uniqIntervalList() {
    return this._minerProposalsState.uniqIntervalList;
  }

  get activatorSubscriptionProposalById() {
    return this._activatorProposalsState.activatorSubscriptionProposalById;
  }
  get activatorSubscriptionProposalList() {
    return this._activatorProposalsState.activatorSubscriptionProposalList;
  }
  get activatorProposalMapByTime() {
    return this._activatorProposalsState.activatorProposalMapByTime;
  }
  get uniqActivatorIntervalList() {
    return this._activatorProposalsState.uniqActivatorIntervalList;
  }
  get uniqActivatorTimeList() {
    return this._activatorProposalsState.uniqActivatorTimeList;
  }

  private _prepareAfterLoadPurchases() {
    return reaction(
      () => [
        this._root.inAppOffers.isLoadedIn,
        this._root.currentSubscriptionOfferService.isLoadedIn,
      ],
      (loaders) => {
        if (!loaders[0] || !loaders[1]) {
          return;
        }
        this._isLoadedIn = false;
        this._minerProposalsState.prepare();
        this._activatorProposalsState.prepare();
        this._isLoadedIn = true;
        this.events.send(SETTLED, undefined);
      },
      {fireImmediately: true},
    );
  }

  subscribe() {
    return batchDisposers(this._prepareAfterLoadPurchases());
  }

  readonly events = new RouterImpl<{
    [SETTLED]: void;
  }>();

  reset() {
    this._minerProposalsState.reset();
    this._activatorProposalsState.reset();
  }
}
