import {SvgProps} from 'react-native-svg';
import {useRoot} from '../Root/hooks';
import {sized} from '../Svg';
import CryptotabFarmSvg from '../assets/svg/colored/cryptotabFarm.svg';
import CryptotabFarmBlackSvg from '../assets/svg/colored/cryptotabFarmBlack.svg';
import React from 'react';
import {observer} from 'mobx-react-lite';

export const CryptotabFarmLogo = observer((props: SvgProps) => {
  const {
    appearance: {isDark},
  } = useRoot();
  const Component = isDark ? CryptotabFarmBlackIcon : CryptotabFarmIcon;
  return <Component {...props} />;
});

const CryptotabFarmIcon = sized(CryptotabFarmSvg, 205, 40);
const CryptotabFarmBlackIcon = sized(CryptotabFarmBlackSvg, 205, 40);
