import {sortBy, uniqBy} from 'lodash';
import {Proposal} from './ProposalsState';
import {MinerOffer} from '../InAppOffersService';
import {MinerConfig} from './MinerProposalsState';
import makeMinerConfig from './makeMinerConfig';

export default (list: Proposal<MinerOffer>[]): MinerConfig[] => {
  const availableList = list.filter((_) => _.available);
  return sortBy(
    uniqBy(availableList, (_) => _.offer.poolMinerConfig.hash_rate).map((_) =>
      makeMinerConfig(_.offer),
    ),
    (_) => _.hashrate,
  );
};
