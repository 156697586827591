import {observer} from 'mobx-react-lite';
import React from 'react';
import {useForcedContext} from '../context';
import PaymentScreenContext from './context';
import HeaderView from './HeaderView';
import {EthSvg, BtcSvg, CtcSvg, MaticSVG, SolSvg} from '../assets/svg/currency';

export default observer(function HeaderBinding() {
  const {
    order: {currency, networkName},
  } = useForcedContext(PaymentScreenContext);
  const value = `${currency} (${networkName})`;
  return <HeaderView Icon={CURRENCY_BY_ICON.get(currency)} value={value} />;
});

const CURRENCY_BY_ICON = new Map([
  ['ETH', EthSvg],
  ['BTC', BtcSvg],
  ['CTC', CtcSvg],
  ['MATIC', MaticSVG],
  ['SOL', SolSvg],
]);
