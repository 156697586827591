import {observer} from 'mobx-react-lite';
import React, {forwardRef, useMemo} from 'react';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings} from '../Root/hooks';
import AdaptivePickerModal from '../components/organisms/modal/AdaptivePickerModal';
import {WorkerOrder} from '../Dashboard';

export type DashboardSortModalProps = {
  onModalClosed: () => void;
  onSubmit: (order: WorkerOrder | undefined) => void;
  initialValue: WorkerOrder;
};

export default observer<DashboardSortModalProps, AdaptiveModalRef>(
  forwardRef(function DashboardSortModal(
    {onModalClosed, onSubmit, initialValue},
    ref,
  ) {
    const strings = useStrings();
    const items = useMemo(
      () => [
        {
          title: strings['groupFilter.default'],
          value: WorkerOrder.Default,
          key: WorkerOrder.Default,
        },
        {
          title: strings['groupFilter.name'],
          value: WorkerOrder.Name,
          key: WorkerOrder.Name,
        },
        {
          title: strings['groupFilter.miningSpeed'],
          value: WorkerOrder.AverageHashrate,
          key: WorkerOrder.AverageHashrate,
        },
        {
          title: strings['groupFilter.earnings'],
          value: WorkerOrder.MinedTotal,
          key: WorkerOrder.MinedTotal,
        },
        {
          title: strings['groupFilter.status'],
          value: WorkerOrder.Status,
          key: WorkerOrder.Status,
        },
      ],
      [strings],
    );
    return (
      <AdaptivePickerModal
        modalRef={ref}
        items={items}
        title={strings['common.sortBy']}
        initialValue={initialValue}
        onSubmit={onSubmit}
        onModalClosed={onModalClosed}
      />
    );
  }),
);
