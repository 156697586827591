import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {
  FlatListProps,
  ScrollViewProps,
  StyleSheet,
  FlatList,
} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {useRoot} from '../../../Root/hooks';
import SimpleSlot from './SimpleSlot';
import formItems, {WorkerItem} from './formItems';
import {SimpleWorkerListContext} from './context';
import {useForcedContext} from '../../../context';
import {DashboardContext} from '../../context';
import SimpleListHeader from './SimpleListHeader';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import SimpleListFooter from './SimpleListFooter';

export type SimpleWorkerListProps = ScrollViewProps & {};

export default observer(function SimpleWorkerList(
  props: SimpleWorkerListProps,
) {
  const {dashboardStore, workerStateRegistry, dashboardService} = useRoot();
  const groups = expr(
    () => dashboardStore.dashboardRequest.value?.groups || [],
  );
  const workers = groups.map((g) => g.workers).flat();
  const {goToPickDashboardSort, goToPoolActivator} =
    useForcedContext(DashboardContext);
  const liveWorkers = formItems(
    workers,
    dashboardService.getOrder,
    dashboardStore.getWorkerBalanceById,
    workerStateRegistry,
  );
  const renderItem = useCallback(
    ({item}: {item: WorkerItem}) => (
      <SimpleSlot
        goToPoolActivator={goToPoolActivator}
        worker={item.worker}
        index={item.index}
        isLast={item.isLast}
      />
    ),
    [goToPoolActivator],
  );
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const base = expr(() => ({
    workerCount: workers.length,
    goToPickSort: goToPickDashboardSort,
  }));

  return (
    <SimpleWorkerListContext.Provider value={base}>
      <FlatList
        ListHeaderComponent={SimpleListHeader}
        ListFooterComponent={SimpleListFooter}
        ListFooterComponentStyle={styles.footer}
        contentContainerStyle={[
          styles.container,
          isLarge && styles.largeContainer,
        ]}
        keyboardShouldPersistTaps="handled"
        initialNumToRender={8}
        maxToRenderPerBatch={8}
        data={liveWorkers}
        renderItem={renderItem}
        removeClippedSubviews
        {...props}
      />
    </SimpleWorkerListContext.Provider>
  );
});

export type ListProps = FlatListProps<ReadonlyDeep<WorkerItem>>;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  footer: {
    marginTop: 'auto',
  },
  largeContainer: {
    paddingHorizontal: 16,
  },
});
