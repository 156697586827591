import React, {useCallback, useState} from 'react';
import {Keyboard, Platform, TextInputProps, View} from 'react-native';
import {useForm, Controller} from 'react-hook-form';
import {observer} from 'mobx-react-lite';

import {useRoot, useStrings} from '../Root/hooks';
import {Input} from '../components';
import sleep from '../utils/sleep';
import {LoaderWrapper} from './LoaderWrapper';
import {createStylesHook, variance} from '../styling';

import {AppButton, ButtonVariant} from '../components/AppButton';
import EmojiSvg from '../assets/svg/emoji/whatWrong.svg';
import {sized} from '../Svg';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import {AdaptiveModalHeader} from '../components/organisms/modal/AdaptiveModalHeader';
import {Representation} from '../components/templates';
import useRepresentation from '../components/templates/AdaptiveModal/useRepresentation';
import useOpenBrowserReview from './useOpenBrowserReview';

export type CommentProps = {
  rating: number;
  goToThanks: () => void;
  onClose: () => void;
  TextInputComponent?: React.ComponentType<TextInputProps>;
};

export type CommentFormData = {
  email: string;
  comment: string;
};

export default observer(function Comment({
  rating,
  goToThanks,
  onClose,
  TextInputComponent,
}: CommentProps) {
  const {rateApp, auth} = useRoot();
  const strings = useStrings();
  const styles = useStyles();
  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<CommentFormData>({
    defaultValues: {
      email: auth.email,
    },
  });
  const [sending, setSending] = useState<boolean>(false);
  const openBrowser = useOpenBrowserReview();
  const handleOpenBrowser = useCallback(async () => {
    const values = getValues();
    await openBrowser();
    await rateApp.sendRating({
      rating: rating,
      email: values.email,
      message: values.comment,
    });
  }, [getValues, openBrowser, rateApp, rating]);
  const submitForm = async (data: CommentFormData) => {
    setSending(true);
    Keyboard.dismiss();
    await Promise.all([
      sleep(1000),
      rateApp.setRatingReviewed(),
      rateApp.sendRating({
        rating: rating,
        email: data.email,
        message: data.comment,
      }),
    ]);
    setSending(false);
    goToThanks();
  };
  const paddingBottom = useSafePaddingBottom(16);
  const representation = useRepresentation();
  const isModal = representation === Representation.Modal;
  return (
    <View style={[styles.root, {paddingBottom}]}>
      <AdaptiveModalHeader
        IconSvg={EmojiIcon}
        title={strings['modal.rateComment.title']}
        description={strings['modal.rateComment.subTitle']}
      />

      <FormView isModal={isModal}>
        <Controller
          control={control}
          render={({field: {onChange, onBlur, value}}) => (
            <Input
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              hasError={'email' in errors}
              containerViewStyle={styles.emailInputView}
              placeholder={strings['modal.rateComment.emailPlaceholder']}
              keyboardType="email-address"
              textContentType="emailAddress"
              autoCapitalize="none"
              TextInputComponent={TextInputComponent}
            />
          )}
          name="email"
          rules={{
            required: true,
            pattern: {
              message: '',
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
          }}
          defaultValue=""
        />

        <Controller
          control={control}
          render={({field: {onChange, onBlur, value}}) => (
            <Input
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              hasError={'comment' in errors}
              containerViewStyle={styles.commentInputView}
              placeholder={strings['modal.rateComment.commentPlaceholder']}
              multiline
              TextInputComponent={TextInputComponent}
            />
          )}
          name="comment"
          rules={{
            required: true,
          }}
          defaultValue=""
        />
      </FormView>

      <LoaderWrapper style={styles.footerView} loading={sending}>
        <AppButton
          onPress={handleSubmit(submitForm)}
          style={styles.buttonOffset}
          variant={ButtonVariant.Primary}>
          {strings['action.sendFeedback']}
        </AppButton>
        {Platform.OS === 'ios' && (
          <AppButton style={styles.buttonOffset} onPress={handleOpenBrowser}>
            {strings['modal.rateComment.rateInAppStore']}
          </AppButton>
        )}
        <AppButton onPress={onClose}>{strings['action.close']}</AppButton>
      </LoaderWrapper>
    </View>
  );
});

const EmojiIcon = sized(EmojiSvg, 32);

const useStyles = createStylesHook(() => ({
  root: {
    padding: 16,
  },
  sendFeedbackButton: {
    marginBottom: 15,
  },
  rateInAppStoreButton: {
    marginTop: 15,
  },
  footerView: {},
  emailInputView: {
    marginBottom: 15,
  },
  commentInputView: {
    marginBottom: 30,
  },
  buttonOffset: {
    marginBottom: 12,
  },
}));

const FormView = variance(View)(() => ({
  root: {
    marginTop: 24,
  },
  isModal: {
    marginTop: 36,
  },
}));
