import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../styling';
import {View, Text, ViewProps, ViewStyle} from 'react-native';
import {useStrings} from '../Root/hooks';
import {
  AnimatableComponent,
  View as AnimatableView,
} from 'react-native-animatable';
import {autorun} from 'mobx';
import {useMeasure} from '../ReactNativeUtil';
import {Millisecond, now} from '../Time';
import {getCommissionerFontStyles} from '../components';

export interface ConnectingScreenProps {
  getAnimateUntil: () => Millisecond;
  onAnimationEnd?: () => void;
  getIsFocused: () => boolean;
}

export default observer((props: ConnectingScreenProps) => {
  const {getAnimateUntil, onAnimationEnd, getIsFocused} = props;
  const strings = useStrings();
  const styles = useStyles();
  const barRef = useRef<View>(null);
  const progressRef = useRef<AnimatableComponent<ViewProps, ViewStyle> & View>(
    null,
  );
  const [getResult, onLayout] = useMeasure(barRef, false);
  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused()) {
          const until = getAnimateUntil();
          const duration = until - now();
          const {width} = getResult() ?? {};
          if (width !== undefined && width !== 0 && duration > 0) {
            progressRef.current?.transition(
              {translateX: -width / 2, scaleX: 0},
              {translateX: 0, scaleX: 1},
              duration,
              'linear',
            );
          }
        }
      }),
    [getAnimateUntil, getIsFocused, getResult],
  );
  return (
    <View style={styles.root}>
      <Text style={styles.title}>{strings['connecting.title']}</Text>
      <View onLayout={onLayout} ref={barRef} style={styles.bar}>
        <AnimatableView
          ref={progressRef}
          onTransitionEnd={onAnimationEnd}
          style={styles.progress}
        />
      </View>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
    padding: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundGround,
  },
  title: {
    ...getCommissionerFontStyles('700'),
    fontSize: 16,
    lineHeight: 18,
    textAlign: 'center',
    letterSpacing: 0.045,
    color: theme.colors.uiSecondary,
    marginBottom: 25,
  },
  bar: {
    height: 6,
    borderRadius: 4,
    width: '100%',
    backgroundColor: theme.colors.uiAdditional1,
    overflow: 'hidden',
  },
  progress: {
    backgroundColor: theme.colors.primaryNew,
    height: 6,
    borderRadius: 4,
    scaleX: 0,
  },
}));
