import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {useLanguage} from '../hooks';
import LanguageListItem from './LanguageListItem';
import {Divider} from '../components/atoms';
import {Locale} from '../Preferences';

export type LanguageListProps = {
  onChange: (locale: Locale) => void;
  selectedLanguage: Locale;
};

export default observer(function LanguageList({
  onChange,
  selectedLanguage,
}: LanguageListProps) {
  const languages = useLanguage();
  return (
    <View>
      {languages.map((_, index) => {
        const isLast = index === languages.length - 1;
        return (
          <View key={_.id}>
            <LanguageListItem
              onPress={() => onChange(_.id)}
              Icon={_.icon}
              title={_.title}
              active={_.id === selectedLanguage}
            />
            {!isLast && <Divider />}
          </View>
        );
      })}
    </View>
  );
});
