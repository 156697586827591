import {
  action,
  autorun,
  computed,
  observable,
  reaction,
  makeObservable,
  runInAction,
} from 'mobx';
import {ActivatorStatus, PoolMinerActivator} from './PoolMinerActivator';
import {bind, Either, success} from '../fp';
import {batchDisposers, Service} from '../structure';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {WorkerStateRegistryService} from '../WorkerStateRegistry';
import {Auth} from '../Auth';
import {DashboardService, BannerType} from '../Dashboard';
import {Appearance} from '../Appearance';
import {CurrentSubscriptionState} from '../CurrentSubscription';
import {ConnectedClient} from '../ContextClient';
import {CommonError} from '../ApiStore';
import {DefaultError} from '../JsonRpc';
import isNeedToReactivate from './isNeedToReactivate';

export default class PoolMinerActivatorService
  implements Service, PoolMinerActivator
{
  @observable private _activatorStatus = ActivatorStatus.Idle;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly dashboardStore: DashboardStore;
      readonly connectedClient: ConnectedClient;
      readonly workerStateRegistry: WorkerStateRegistryService;
      readonly dashboardService: DashboardService;
      readonly currentSubscriptionState: CurrentSubscriptionState;
      readonly appearance: Appearance;
    },
  ) {
    makeObservable(this);
  }

  confirm = bind(
    async (): Promise<Either<void, CommonError | DefaultError>> => {
      const activate_ = await this._root.connectedClient.call(
        'activate_pool_miners',
      );
      if (!activate_.success) {
        runInAction(() => (this._activatorStatus = ActivatorStatus.Idle));
        return activate_;
      }
      await this._root.dashboardStore.fetch();
      this._activatorStatus = ActivatorStatus.Idle;
      return success(undefined);
    },
    this,
  );

  get activatorStatus() {
    return this._activatorStatus;
  }

  @computed
  get isVisible() {
    return this.activatorStatus === ActivatorStatus.NeedActivate;
  }

  @action
  dismiss = bind(() => {
    this._activatorStatus = ActivatorStatus.Dismissed;
  }, this);

  private _showActivePoolMinerOnWorkerUpdate = () =>
    autorun(() => {
      if (!this._root.auth.isConnected) {
        return;
      }
      const workers = [...this._root.dashboardStore.workers.values()];
      const needActive = workers.some((_) => isNeedToReactivate(_));
      runInAction(() => {
        if (needActive) {
          this._activatorStatus = ActivatorStatus.NeedActivate;
        } else {
          this._activatorStatus = ActivatorStatus.Idle;
        }
      });
    });

  private _showBannerOnChangeActivatorStatus = () =>
    autorun(() => {
      const status = this.activatorStatus;
      switch (status) {
        case ActivatorStatus.Dismissed:
        case ActivatorStatus.NeedActivate:
          return this._root.dashboardService.bannersState.add(
            BannerType.PoolMiner,
          );
        case ActivatorStatus.Idle:
        default:
          return this._root.dashboardService.bannersState.delete(
            BannerType.PoolMiner,
          );
      }
    });

  private _resetStatusOnReconnect = () =>
    reaction(
      () => this._root.auth.isConnected,
      (shouldReset) => {
        if (shouldReset) {
          runInAction(() => (this._activatorStatus = ActivatorStatus.Idle));
        }
      },
    );

  subscribe() {
    return batchDisposers(
      this._showActivePoolMinerOnWorkerUpdate(),
      this._showBannerOnChangeActivatorStatus(),
      this._resetStatusOnReconnect(),
    );
  }
}
