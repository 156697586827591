import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {useStyles, variance} from '../styling';
import Typography from './Typography';
import {useStrings} from '../Root/hooks';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type SwitchFarmHeaderProps = {
  description: string;
  accountAmount?: number;
};

export default observer(
  ({description, accountAmount}: SwitchFarmHeaderProps) => {
    const strings = useStrings();
    const styles = useStyles((theme) => ({
      accountAmount: {
        ...theme.mediaQuery({
          [MD_BREAKPOINT]: {
            fontSize: 18,
          },
        }),
      },
      headerText: {
        ...theme.mediaQuery({
          [MD_BREAKPOINT]: {
            maxWidth: 650,
          },
        }),
      },
    }));

    return (
      <View>
        {!!accountAmount && accountAmount > 1 && (
          <HeaderView>
            <Typography type="paragraph" size="large" style={styles.headerText}>
              {description || ''}
            </Typography>
          </HeaderView>
        )}
        <AccountAmountView>
          <Typography
            type="header"
            size="h3"
            weight="500"
            style={styles.accountAmount}>
            {strings['accountSwitch.accounts']} {accountAmount || ''}
          </Typography>
        </AccountAmountView>
      </View>
    );
  },
);

const HeaderView = variance(View)((theme) => ({
  root: {
    padding: 16,
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    backgroundColor: theme.colors.uiMain,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        borderTopRightRadius: 12,
        borderTopLeftRadius: 12,
      },
    }),
  },
}));

const AccountAmountView = variance(View)(() => ({
  root: {
    paddingVertical: 12,
    paddingHorizontal: 15,
  },
}));
