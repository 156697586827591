import {JsonString} from '../Json';
import {Opaque} from 'type-fest';
import {FarmId} from '../ApiStore';
import {ActionId, Url} from '../units';
import {Second} from '../Time';

export type StoredMessageDescription = {
  type: 'stored';
  url: RemoteStoredMessageUrl;
  event_url: AnalyticsEventUrl;
  meta: JsonString<MessageMeta>;
};

export const REMOTE_STORED_MESSAGE_URL = Symbol();
export type RemoteStoredMessageUrl = Opaque<
  Url,
  typeof REMOTE_STORED_MESSAGE_URL
>;

export const ANALYTICS_EVENT_URL = Symbol();
export type AnalyticsEventUrl = Opaque<Url, typeof ANALYTICS_EVENT_URL>;

export type InstantMessageDescription = {
  type: 'instant';
  data: JsonString<Message>;
  event_url: AnalyticsEventUrl;
  meta: JsonString<MessageMeta>;
};

export type MessageMeta = {
  timestamp: number;
  id: string;
  rid: number;
  task_id: number;
};

export type MessageDescription =
  | StoredMessageDescription
  | InstantMessageDescription;

export type BaseMessage = {
  name?: string;
  title: string;
  body: string;
  url?: Url;
  actions?: MessageAction[];
  tags?: MessageTag;
  icon?: Url;
  image?: Url;
  requireInteraction?: boolean;
  sound?: boolean;
  light?: boolean;
  vibrate?: boolean;
  ttl?: Second;
  group?: MessageGroup;
};

export type MessageGroup = {
  id: string;
  title: string;
  subtitle?: string;
  body?: string;
};

export const MESSAGE_TAG = Symbol();
export type MessageTag = Opaque<string, typeof MESSAGE_TAG>;

export type MessageAction = {
  action: ActionId;
  title: string;
  url: Url;
  icon?: Url;
};

export type NotificationMessage = {
  type: 'notification';
} & BaseMessage;

export type AuthPushMessage = {
  type: 'authpush';
  farm_id: FarmId;
} & BaseMessage;

export type Message = NotificationMessage | AuthPushMessage;
