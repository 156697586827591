import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {View} from 'react-native';
import {Message} from '../FlashMessageService';
import FlashMessageItem from './FlashMessageItem';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type FlashMessageListProps = {
  getMessages: () => Message[];
  onRemoveMessage: (id: string) => void;
};

export default observer(function FlashMessageList(
  props: FlashMessageListProps,
) {
  const {getMessages, onRemoveMessage} = props;
  const {top} = useSafeAreaInsets();
  return (
    <FlashMessageListView style={{paddingTop: top}}>
      {getMessages().map((it) => (
        <FlashMessageItem
          key={it.id}
          message={it}
          onDismiss={onRemoveMessage}
        />
      ))}
    </FlashMessageListView>
  );
});

const FlashMessageListView = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}));
