import {observer} from 'mobx-react-lite';
import React, {ForwardedRef, forwardRef} from 'react';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {AdaptiveActionModal} from '../components/organisms';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import FlashSvg from '../assets/svg/colored/yellowFlash.svg';
import ColorlessFlashSvg from '../assets/svg/colorless/quadFlash.svg';

export type PoolMinerDescriptionModalProps = {
  onModalClosed: () => void;
  onButtonPress: () => void;
};

export default observer(
  forwardRef(function PoolMinerDescriptionModal(
    {onModalClosed, onButtonPress}: PoolMinerDescriptionModalProps,
    modalRef: ForwardedRef<AdaptiveModalRef>,
  ) {
    const strings = useStrings();
    return (
      <AdaptiveActionModal
        ref={modalRef}
        onModalClosed={onModalClosed}
        IconSvg={TrashIcon}
        title={strings['modal.poolMinerDescription.title']}
        description={strings['modal.poolMinerDescription.description']}
        actions={[
          <AppButton
            Icon={ColorlessFlashSvg}
            onPress={onButtonPress}
            variant={ButtonVariant.Primary}>
            {strings['modal.poolMinerDescription.button']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const TrashIcon = sized(FlashSvg, 58);
