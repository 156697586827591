import React, {ForwardedRef, forwardRef, useRef, useState} from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
  StyleSheet,
  CursorValue,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../styling';
import {getCommissionerFontStyles} from './Typography';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {useRefComposer} from 'react-ref-composer';

export type InputProps = TextInputProps & {
  containerViewStyle?: StyleProp<ViewStyle>;
  hasError?: boolean;
  TextInputComponent?: React.ComponentType<
    TextInputProps & {ref?: ForwardedRef<TextInput>}
  >;
};

type FocusEvent = NativeSyntheticEvent<TextInputFocusEventData>;

export default observer<InputProps, TextInput>(
  forwardRef(function Input(props, ref) {
    const {
      style,
      containerViewStyle,
      onFocus,
      onBlur,
      hasError,
      multiline,
      TextInputComponent,
      placeholder,
      value,
      ...rest
    } = props;
    const composeRefs = useRefComposer();
    const inputRef = useRef<TextInput>(null);
    const [focused, setFocus] = useState(false);
    const animatedFocusFlag = useSharedValue(0);
    const styles = useStyles();
    const handleBlur = (e: FocusEvent) => {
      onBlur?.(e);
      animatedFocusFlag.value = 0;
      setFocus(false);
    };
    const handleFocus = (e: FocusEvent) => {
      onFocus?.(e);
      animatedFocusFlag.value = 1;
      setFocus(true);
    };
    const focus = () => inputRef.current?.focus();
    const upperLabel = useDerivedValue(() =>
      animatedFocusFlag.value === 1 || (value?.length ?? 0) > 0 ? 1 : 0,
    );
    const placeholderViewStyles = useAnimatedStyle(() => {
      const translateY = withTiming(
        interpolate(upperLabel.value, [0, 1], [10, 0]),
        {duration: 100},
      );
      const translateX = withTiming(
        interpolate(upperLabel.value, [0, 1], [0, 1]),
        {duration: 100},
      );
      return {
        transform: [{translateX}, {translateY}],
      };
    });
    const textStyles = useAnimatedStyle(() => {
      const fontSize = withTiming(
        interpolate(upperLabel.value, [0, 1], [16, 12]),
        {duration: 100},
      );
      return {fontSize};
    });
    const RenderedInput = TextInputComponent || TextInput;
    return (
      <View style={[styles.inputView, containerViewStyle]}>
        <View
          style={[
            styles.innerView,
            hasError && styles.inputError,
            focused && styles.inputActive,
          ]}>
          <TouchableWithoutFeedback
            containerStyle={styles.backdropTouchable}
            onPress={focus}
          />
          <RenderedInput
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={[styles.input, style, multiline && styles.multiline]}
            multiline={multiline}
            value={value}
            {...rest}
            ref={composeRefs(ref, inputRef)}
          />
        </View>
        <Animated.View style={[styles.placeholderView, placeholderViewStyles]}>
          <Animated.Text style={[styles.placeholder, textStyles]}>
            {placeholder}
          </Animated.Text>
        </Animated.View>
      </View>
    );
  }),
);

const useStyles = createStylesHook((theme) => ({
  inputView: {},
  backdropTouchable: {
    ...StyleSheet.absoluteFillObject,
    ...Platform.select({
      web: {
        cursor: 'text' as CursorValue,
      },
      default: {},
    }),
  },
  input: {
    zIndex: 2,
    paddingHorizontal: 16,
    fontSize: 16,
    lineHeight: 22,
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.select(theme.colors.primaryBlack, theme.colors.primaryWhite),
    ...getCommissionerFontStyles('400'),
    height: 25,
    ...Platform.select({
      web: {
        outline: 'none',
      },
      default: {},
    }),
  },
  innerView: {
    borderRadius: 8,
    paddingTop: 15,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    backgroundColor: theme.colors.uiMain,
    ...Platform.select({
      web: {
        cursor: 'text' as CursorValue,
      },
      default: {},
    }),
  },
  inputError: {
    borderColor: theme.colors.primaryError,
  },
  inputActive: {
    borderColor: theme.colors.primaryAttractive,
  },
  multiline: {
    paddingTop: 5,
    paddingBottom: 10,
    height: 100,
    textAlignVertical: 'top',
  },
  placeholderView: {
    position: 'absolute',
    left: 16,
    pointerEvents: 'none',
  },
  placeholder: {
    fontSize: 16,
    lineHeight: 20,
    color: theme.colors.primaryUIDirtyBlue,
    ...getCommissionerFontStyles('400'),
  },
}));
