export enum StateVisibleContent {
  Rate,
  Comment,
  RateUs,
  Thanks,
}

export type SharedProps = {
  dismiss: () => void;
  visibleContent: StateVisibleContent;
  goToComment: (rating: number) => void;
  goToRateUs: (rating: number) => void;
  goToThanks: () => void;
  selectedRating: number;
};
