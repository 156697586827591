import {Contract, utils} from '../Ethers';
import {MetamaskTokenSender} from './MetamaskTokenSender';
import {
  ErrorNormalizer,
  SimpleError,
  SIGNER_NOT_FOUND_ERROR,
} from './ErrorNormalizer';
import {Either, error, success} from '../fp';
import {MetamaskConnector} from './MetamaskConnector';

export default class MetamaskTokenSenderImpl implements MetamaskTokenSender {
  constructor(
    readonly _errorNormalizer: ErrorNormalizer,
    readonly _connector: {
      readonly signer: MetamaskConnector['signer'];
    },
  ) {}

  async sendNativeToken(
    recipient: string,
    amount: string,
  ): Promise<Either<void, SimpleError>> {
    if (!this._connector.signer) {
      return error(SIGNER_NOT_FOUND_ERROR);
    }
    try {
      const transaction = await this._connector.signer.sendTransaction({
        to: recipient,
        value: utils.parseEther(amount),
      });
      await transaction.wait();
      return success(undefined);
    } catch (raw) {
      return error(this._errorNormalizer.normalize(raw));
    }
  }

  async sendERC20Token(
    tokenAddress: string,
    recipient: string,
    amount: string,
    decimals: number,
    abi: any[],
  ): Promise<Either<void, SimpleError>> {
    if (!this._connector.signer) {
      return error(SIGNER_NOT_FOUND_ERROR);
    }
    try {
      const token = new Contract(tokenAddress, abi, this._connector.signer);
      const formattedAmount = utils.parseUnits(amount, decimals);
      const transaction = await token.transfer(recipient, formattedAmount);
      await transaction.wait();
      return success(undefined);
    } catch (raw) {
      return error(this._errorNormalizer.normalize(raw));
    }
  }
}
