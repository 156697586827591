import {Theme} from './Theme';
import {ScaledSize} from 'react-native';
import {Colors} from './Coloring';
import color, {UniversalColor} from '../../color';
import chroma, {Color} from 'chroma-js';
import {MediaQuery} from './Adaptive';

export default abstract class BaseThemeImpl implements Theme {
  protected constructor(
    private readonly _window: ScaledSize,
    private readonly _contentWidth: number,
  ) {}

  mediaQuery(query: MediaQuery, excludeAside = false) {
    const queryKeys = Object.keys(query).map(Number);
    let result = {};
    const screenWidth = excludeAside ? this._contentWidth : this._window.width;
    for (const q of queryKeys) {
      if (screenWidth >= q) {
        result = {...result, ...query[q]};
      }
    }
    return result;
  }

  abstract get colors(): Colors;

  chroma(this: Theme, _color: UniversalColor): Color {
    return color(_color);
  }

  mix(_back: UniversalColor, _front: UniversalColor) {
    const back = chroma(_back);
    const front = chroma(_front);
    const alpha = front.alpha();
    const r = alpha * front.get('rgb.r') + (1 - alpha) * back.get('rgb.r');
    const g = alpha * front.get('rgb.g') + (1 - alpha) * back.get('rgb.g');
    const b = alpha * front.get('rgb.b') + (1 - alpha) * back.get('rgb.b');
    return chroma.rgb(r, g, b);
  }

  select<T>(this: Theme, light: T, dark: T): T {
    return chroma(this.colors.uiMain).luminance() >= 0.5 ? light : dark;
  }
}
