import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native';
import {useRoot} from '../Root/hooks';
import {observable} from 'mobx';
import {sized} from '../Svg';
import Close from '../assets/svg/colorless/close.svg';
import Card from './Card';
import BackgroundMask from './BackgroundMask';

export default observer(function InteractiveTutorial() {
  const {interactiveTutorial} = useRoot();
  const theme = useTheme();
  const [isTranslatingBox] = useState(() => observable.box<boolean>(false));
  const startTranslate = useCallback(
    () => isTranslatingBox.set(true),
    [isTranslatingBox],
  );
  const completeTranslate = useCallback(
    () => isTranslatingBox.set(false),
    [isTranslatingBox],
  );
  const getTranslating = useCallback(
    () => isTranslatingBox.get(),
    [isTranslatingBox],
  );
  if (!interactiveTutorial.isShown) {
    return null;
  }

  const {
    accentElement,
    activeStep,
    next,
    prev,
    skip,
    finish,
    activeStepIndex,
    tutorialStack,
  } = interactiveTutorial;
  return (
    <RootView>
      <BackgroundMask getTranslating={getTranslating} />
      <Card
        count={tutorialStack.length}
        activeIndex={activeStepIndex}
        accentElement={accentElement}
        activeStepKey={activeStep.elementKey}
        onNext={next}
        onPrev={prev}
        onSkip={skip}
        onFinish={finish}
        onTranslateStart={startTranslate}
        onTranslateEnd={completeTranslate}
        getTranslating={getTranslating}
      />
      <View style={styles.closeButton}>
        <TouchableWithoutFeedback onPress={interactiveTutorial.finish}>
          <CloseIcon color={theme.colors.primaryActive} />
        </TouchableWithoutFeedback>
      </View>
    </RootView>
  );
});
const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 0,
    padding: 20,
    opacity: 0.9,
  },
});

const CloseIcon = sized(Close, 32);

const RootView = variance(View)(() => ({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
}));
