import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {expr} from 'mobx-utils';
import {RenameWorkerGroupModal} from '../../RenameWorkerGroupModal';
import {useErrorHandler} from '../../ExceptionHandler';

export type RenameWorkerGroupBindingProps =
  RootStackBindingProps<'RenameWorkerGroup'>;

export default observer(function RenameWorkerGroupBinding({
  route,
}: RenameWorkerGroupBindingProps) {
  const {connectedClient} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const close = useCallback(() => modalRef.current?.close(), []);
  const {dashboardStore} = useRoot();
  const groupId = route.params?.groupId;
  const group = expr(() =>
    groupId !== undefined ? dashboardStore.groups.get(groupId) : undefined,
  );
  const onCancel = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);
  const errorHandler = useErrorHandler();
  const onConfirm = useCallback(
    async (groupName: string) => {
      await close();
      if (groupId === undefined) {
        return;
      }
      const result = await connectedClient.call('rename_group', {
        group_id: groupId,
        name: groupName,
      });
      if (!result.success) {
        errorHandler.handle(result.left);
      }
      safelyGoBack();
    },
    [connectedClient, close, errorHandler, groupId, safelyGoBack],
  );
  const modalRef = useRef<AdaptiveModalRef>(null);
  return (
    <RenameWorkerGroupModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onConfirm={onConfirm}
      onCancel={onCancel}
      initialName={group?.name ?? '-'}
    />
  );
});
