import {observer} from 'mobx-react-lite';
import React from 'react';
import ChangeModeSection from '../../components/ChangeModeSection';
import {View, StyleSheet} from 'react-native';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import {SECTION_LIST_FOOTER_HEIGHT} from '../../constants';

export default observer(function SectionListFooter() {
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const getSectionListFooterHeight = useGetSectionListFooterHeight();
  const height = expr(() => getSectionListFooterHeight());
  return (
    <View style={[styles.root, {height}]}>
      <ChangeModeSection isLarge={isLarge} />
    </View>
  );
});

export function useGetSectionListFooterHeight() {
  return () => SECTION_LIST_FOOTER_HEIGHT;
}

const styles = StyleSheet.create({
  root: {
    paddingTop: 15,
    paddingBottom: 30,
  },
});
