import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {OrderId} from '../ApiStore/units/order';
import {useRoot} from '../Root/hooks';
import {useOpenLinkCallback} from '../Links';
import {ScreenFluidLoader} from '../components/atoms/CryptoLoader';
import {useStateEffect} from './useStateEffect';
import {useCheckPayment} from './useCheckPayment';
import OrderPaymentStateImpl from './OrderPaymentStateImpl';
import {FULFILLED, PENDING} from '../AsyncAtom';
import {PaymentScreen} from '../PaymentScreen';
import {isMobileWeb} from './IsMobileWeb';
import {expr} from 'mobx-utils';

export type PaymentScreenContainerProps = {
  orderId: OrderId;
  goToThanksForPurchase: () => void;
  goToPaymentConfirmPending: () => void;
};

export default observer(function PaymentScreenContainer({
  orderId,
  goToThanksForPurchase,
  goToPaymentConfirmPending,
}: PaymentScreenContainerProps) {
  const root = useRoot();
  const [state] = useState(() => new OrderPaymentStateImpl(orderId, root));

  const {
    configuration,
    web3Payment: {metamask},
  } = root;
  const openLink = useOpenLinkCallback();
  const handleInstallMetamaskPress = useCallback(
    () => openLink(configuration.values.downloadMetamaskUrl),
    [openLink, configuration],
  );
  useStateEffect(state);

  const checkPayment = useCheckPayment(
    state,
    goToThanksForPurchase,
    goToPaymentConfirmPending,
  );

  const payWithWalletVisible = expr(
    () => isMobileWeb() || metamask.connector.detected,
  );

  const payWithCtPayVisible = expr(
    () =>
      state.order?.status === FULFILLED &&
      state.order.result.ctPayUrl !== undefined,
  );

  const payWithMetamaskPossible = expr(
    () =>
      state.order?.status === FULFILLED &&
      state.order.result.metamask !== undefined,
  );

  if (!state.order?.status) {
    return null;
  }

  if (
    state.order.status === FULFILLED &&
    state.order.result.status === 'completed'
  ) {
    return null;
  }

  if (state.order.status === PENDING) {
    return <ScreenFluidLoader />;
  }

  if (state.order.status === FULFILLED) {
    return (
      <PaymentScreen
        onCtPayPress={state.payWithCtPay}
        onWalletPayPress={state.payWithMetamask}
        onCheckPaymentPress={checkPayment}
        onInstallMetamaskPress={handleInstallMetamaskPress}
        order={state.order.result}
        payWithMetamaskVisible={payWithWalletVisible}
        payWithMetamaskPossible={payWithMetamaskPossible}
        payWithCtPayPossible={payWithCtPayVisible}
      />
    );
  }

  return null;
});
