import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {LeaveScheduleUnsavedModal} from '../../LeaveScheduleUnsavedModal';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';

export type LeaveScheduleUnsavedBindingProps =
  RootStackBindingProps<'LeaveScheduleUnsaved'>;

export default observer(function LeaveScheduleUnsavedBinding(
  props: LeaveScheduleUnsavedBindingProps,
) {
  const {route} = props;
  const {ownerName, onSuccess} = route.params || {};
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = useCallback(() => modalRef.current?.close(), []);
  const onClose = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);
  const onConfirm = useCallback(async () => {
    await close();
    if (onSuccess !== undefined) {
      onSuccess();
    } else {
      safelyGoBack();
    }
  }, [close, onSuccess, safelyGoBack]);
  return (
    <LeaveScheduleUnsavedModal
      ref={modalRef}
      ownerName={ownerName}
      onModalClosed={safelyGoBack}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
});
