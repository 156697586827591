import {ReadonlyTextInputState, TextInputState} from '../forms';
import {action, computed, flow, observable, makeObservable} from 'mobx';
import {GetAccountBalanceResponse} from '../../universal/features/api/entity/GetAccountBalanceResponse';
import {
  Bitcoin,
  bitcoinToSatoshi,
  CommonError,
  CryptoFarmConnectedServerCalls,
  Satoshi,
} from '../ApiStore';
import {Auth} from '../Auth';
import {Translation} from '../Localization';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {FlashMessageService} from '../FlashMessageService';
import {ConnectedClient} from '../ContextClient';
import {SocketApiService} from '../../universal/features/api/socket/SocketApiService';
import {CancellablePromise} from '../CancellablePromise';
import {LocaleKeys} from '../translation/LocaleStrings';

export default class WithdrawState {
  public static readonly MIN_AMOUNT = 1e-8 as Bitcoin;
  public static readonly MAX_AMOUNT = 100 as Bitcoin;

  @observable private _isLoading = false;
  @observable private _isLoadedIn = false;
  @observable private _isRefreshing = false;
  @observable private _maxAmount?: Bitcoin;
  private readonly _amount = new TextInputState({
    processText: (text) => {
      const matches = text.match(/\s*(\d+)[.,](\d+)\s*/);
      if (matches === null || matches.length < 3) {
        return text.substr(0, 12);
      }
      const [, left, right] = matches;
      return `${left}.${right.substr(0, 8)}`.substr(0, 12);
    },
  });
  @observable.ref private _error?: WithdrawSubmissionError;

  constructor(
    private readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly socketApi: SocketApiService;
      readonly auth: Auth;
      readonly translation: Translation;
      readonly dashboardStore: DashboardStore;
      readonly flashMessages: FlashMessageService;
    },
  ) {
    makeObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  get isLoadedIn() {
    return this._isLoadedIn;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  get maxAmount() {
    return this._maxAmount;
  }

  get amount(): ReadonlyTextInputState {
    return this._amount;
  }

  get error() {
    return this._error;
  }

  @computed
  get errorText(): LocaleKeys | undefined {
    if (!this._error) {
      return undefined;
    }
    switch (this._error.reason) {
      case WithdrawSubmissionErrorReason.WrongAmount:
        return 'withdraw.correctNumberError';
      case WithdrawSubmissionErrorReason.Remote:
        if (this._error.raw.code === 1004) {
          return 'withdraw.subscribeError_2';
        }
        return 'withdraw.commonError_2';
    }
  }

  onSubmit = flow(function* (this: WithdrawState) {
    const amount = parseFloat(this._amount.value ?? '') as Bitcoin;
    if (
      isNaN(amount) ||
      amount < WithdrawState.MIN_AMOUNT ||
      amount > WithdrawState.MAX_AMOUNT ||
      (this.maxAmount && this.maxAmount < amount)
    ) {
      this._error = {
        kind: 'WithdrawSubmissionError',
        reason: WithdrawSubmissionErrorReason.WrongAmount,
      };
      return;
    }
    const satoshi = Math.round(bitcoinToSatoshi(amount)) as Satoshi;
    const withdraw_: WithdrawReturnType = yield this._root.connectedClient.call(
      'withdraw',
      {amount: satoshi},
    );
    if (!withdraw_.success) {
      const errorCode = withdraw_.left.code;
      if (errorCode !== 8006 && errorCode !== 1004) {
        const title = this._root.translation.templates[
          'error.occurredWithStatusCode'
        ]({
          code: withdraw_.left.code.toString(10),
          message: withdraw_.left.message,
        });
        this._root.flashMessages.showMessage({
          timeout: 7000,
          variant: 'danger',
          title,
        });
      }
      this._error = {
        kind: 'WithdrawSubmissionError',
        reason: WithdrawSubmissionErrorReason.Remote,
        raw: withdraw_.left,
      };
      return;
    }
    yield this._root.dashboardStore.updateBalance();
    this.reset();
    return withdraw_.right.amount;
  }).bind(this);

  private _flow?: CancellablePromise<any>;

  private _fetch = flow(function* (this: WithdrawState) {
    try {
      this._isLoading = true;
      const result: GetAccountBalanceResponse =
        yield this._root.socketApi.getAccountBalance();
      this._maxAmount = result.total;
    } catch (ignore) {}
  });

  fetch = async () => {
    this._flow?.cancel();
    try {
      this._flow = this._fetch();
      this._isLoadedIn = false;
      this._isLoading = true;
      await this._flow;
    } catch (ignore) {
    } finally {
      this._isLoading = false;
      this._isLoadedIn = true;
    }
  };

  async refresh() {
    this._flow = this._fetch();
    this._isRefreshing = true;
    try {
      await this._flow;
    } catch (ignore) {}
    this._isRefreshing = false;
  }

  @action.bound reset() {
    this._isLoading = false;
    this._maxAmount = undefined;
    this._amount.reset();
    this._error = undefined;
  }
  @action.bound resetError() {
    this._error = undefined;
  }
}

export enum WithdrawSubmissionErrorReason {
  WrongAmount,
  Remote,
}

export type WithdrawSubmissionError =
  | {
      readonly kind: 'WithdrawSubmissionError';
      readonly reason: WithdrawSubmissionErrorReason.WrongAmount;
    }
  | {
      readonly kind: 'WithdrawSubmissionError';
      readonly reason: WithdrawSubmissionErrorReason.Remote;
      readonly raw: CommonError;
    };

type WithdrawReturnType = ReturnType<
  CryptoFarmConnectedServerCalls['withdraw']
>;
