import {observable, makeObservable, runInAction} from 'mobx';
import {ErrorRepository} from '../ErrorRepository';
import {ProductsProvider, Product} from './Product';
import {ConnectedClient} from '../ContextClient';
import {error} from '../fp';
import {SERVER_ERROR} from '../Error';

export default class ProductsProviderImpl implements ProductsProvider {
  @observable private _products: Product[] | undefined;

  constructor(
    private readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    makeObservable(this);
  }

  get products() {
    return this._products;
  }

  async fetchProducts() {
    const res = await this._root.connectedClient.apply('get_products');

    if (!res.success) {
      return error(
        this._root.errorRepository.create({
          kind: SERVER_ERROR,
          raw: res.left,
        }),
      );
    }
    runInAction(() => (this._products = res.right.items));
  }
}
