import {
  DARK_SECOND_COLOR,
  INACTIVE_BACKGROUND_COLOR,
  LIGHT_SECOND_COLOR,
} from './constants';
import {useRoot} from '../../../Root/hooks';

export default function useColors(
  activeAccentColor: string,
  isActive: boolean,
) {
  const {
    appearance: {isDark},
  } = useRoot();
  return {
    accent: isActive ? activeAccentColor : INACTIVE_BACKGROUND_COLOR,
    second: isDark ? DARK_SECOND_COLOR : LIGHT_SECOND_COLOR,
  };
}
