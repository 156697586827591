import {Millisecond} from '../Time';
import {ChartViewScope} from './ChartView';
import dayjs from 'dayjs';

export default (
  __from: Millisecond | undefined,
  scope: ChartViewScope,
): {readonly from: Millisecond; readonly to: Millisecond} => {
  const _from = dayjs(
    Math.min(
      dayjs(__from).startOf('day').valueOf(),
      dayjs().subtract(1, scope).valueOf(),
    ),
  );
  const _to = _from.add(1, scope);
  const from = _from.toDate().getTime() as Millisecond;
  const to = _to.toDate().getTime() as Millisecond;
  return {from, to};
};
