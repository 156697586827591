export interface TutorialManager {
  readonly shouldOpenTutorialModal: TutorialType | undefined;
  readonly ready: boolean;
  unflag(): void;
}

export enum TutorialType {
  Interactive,
  Splash,
}
