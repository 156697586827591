import {WorkerUpdate} from '../ApiStore';
import {timezone} from 'expo-localization';
import {
  SlotStatus,
  WorkerType,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {Scheduler, WorkerCharacteristicsRecord} from './DemonstrationDatabase';
import {DEFAULT_HOST_INFO} from './constants';

export interface CreateWorkerUpdateParams {
  id: string;
  accountId: number;
  groupId: number;
  name: string;
  cores: number;
  schedulers: Scheduler[];
  characteristics: WorkerCharacteristicsRecord;
}

export default (params: CreateWorkerUpdateParams): WorkerUpdate => ({
  id: params.id,
  account_id: params.accountId,
  group_id: params.groupId,
  deleted: false,
  name: params.name,
  utc_offset: -new Date().getTimezoneOffset(),
  timezone,
  slot_status: SlotStatus.Active,
  default_settings: {speed: 50},
  managed: true,
  versions: null,
  host_info: {
    ...DEFAULT_HOST_INFO,
    timezone,
    utc_offset: -new Date().getTimezoneOffset(),
    cpu: {
      ...DEFAULT_HOST_INFO.cpu,
      brand: params.characteristics.name,
      cores: params.characteristics.cores,
      threads: params.characteristics.cores * 2,
    },
  },
  mining_options: {
    is_huge_pages_enabled: true,
    assembly: 'none',
    msr: '',
    optimal_threads: params.cores * 2,
  },
  schedulers: params.schedulers.map((_) => ({
    start_time: _.from,
    stop_time: _.to,
    settings: {..._.settings},
  })),
  worker_type: WorkerType.Regular,
  pool_miner_is_active: false,
  expired: false,
  pool_miner_config: null,
  start_time: null,
  end_time: null,
  pool_miner_id: null,
  is_free: true,
  subscription_id: null,
});
