import {Bound, Either} from '../fp';
import {CommonError} from '../ApiStore';
import {DefaultError} from '../JsonRpc';

export enum ActivatorStatus {
  Idle,
  NeedActivate,
  Dismissed,
}

export interface PoolMinerActivator {
  readonly activatorStatus: ActivatorStatus | undefined;
  readonly isVisible: boolean;
  dismiss: Bound<() => void, PoolMinerActivator>;
  confirm: () => Promise<Either<void, CommonError | DefaultError>>;
}
