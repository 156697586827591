import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStrings} from '../Root/hooks';
import DualGraphPanel, {DualGraphPanelProps} from './DualGraphPanel';
import useSatoshiUsdRate from './useSatoshiUsdRate';
import WorkerStatisticsPanelState from './WorkerStatisticsPanelState';
import {WorkerId} from '../ApiStore';
import {ChartViewScope} from '../GraphPanel';
import {Millisecond} from '../Time';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import GroupItem from './GroupItem';

export type WorkerStatisticsPanelProps = Partial<DualGraphPanelProps> & {
  workerId: WorkerId;
  state: WorkerStatisticsPanelState;
  onPick: (id?: string | number | undefined) => void;
  onFromChange: (from: Millisecond) => void;
  onScopeChange: (scope: ChartViewScope) => void;
  group: WorkerEntity[] | undefined;
};

export default observer(
  ({
    workerId,
    state,
    onPick,
    group,
    onScopeChange,
    onFromChange,
    ...rest
  }: WorkerStatisticsPanelProps) => {
    const strings = useStrings();

    const secondaryAxisRatio = useSatoshiUsdRate();

    const picker = (
      <>
        {group?.map((_, index) => (
          <GroupItem
            first={index === 0}
            key={_.id}
            id={_.id}
            name={_.name || ''}
            onPick={onPick}
            groupId={workerId}
          />
        ))}
      </>
    );

    return (
      <DualGraphPanel
        onFromChange={onFromChange}
        from={state.period.from}
        to={state.period.to}
        onScopeChange={onScopeChange}
        scope={state.scope}
        isLoading={state.isLoading}
        graph={state.graph}
        title={strings['statistics.miningAmountTable.title']}
        titleRight={picker}
        fractionDigits={0}
        primaryTitle="Satoshi"
        secondaryTitle="USD"
        secondaryAxisRatio={secondaryAxisRatio}
        secondaryFractionDigits={2}
        usdThreshold={0.05}
        {...rest}
      />
    );
  },
);
