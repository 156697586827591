import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {variance} from '../styling';
import {useRoot, useStrings} from '../Root/hooks';
import {Divider} from '../components/atoms';
import {Typography} from '../components';

export default observer(function LgNotificationListHeader() {
  const {
    appTemplateState: {isTablet},
  } = useRoot();
  const strings = useStrings();
  if (!isTablet) {
    return null;
  }
  return (
    <RootView>
      <Divider />
      <View style={styles.content}>
        <View style={[styles.tab, styles.typeTab]}>
          <Typography type="paragraph" size="large" weight="500">
            {strings['notifications.type']}
          </Typography>
        </View>
        <Divider vertical />
        <View style={[styles.tab, styles.contentTab]}>
          <Typography type="paragraph" size="large" weight="500">
            {strings['notifications.notification']}
          </Typography>
        </View>
        <Divider vertical />
        <View style={[styles.tab, styles.dateTab]}>
          <Typography type="paragraph" size="large" weight="500">
            {strings['notifications.date']}
          </Typography>
        </View>
      </View>
      <Divider />
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
  },
}));

const styles = StyleSheet.create({
  content: {
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 38,
  },
  tab: {
    paddingHorizontal: 15,
  },
  typeTab: {
    minWidth: 70,
  },
  contentTab: {
    flex: 1,
  },
  dateTab: {
    minWidth: 180,
    alignItems: 'center',
  },
});
