import React from 'react';
import {View} from 'react-native';
import MainListItem from './extra/MainListItem';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {FarmLogVariant} from '../Notifications';
import {variance} from '../styling';
import {
  LG_BREAKPOINT,
  useGetDimensions,
} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {SectionProps} from './types';

export default observer(({state, onCheck}: SectionProps) => {
  const strings = useStrings();
  const getDimension = useGetDimensions();
  const isLarge = expr(() => getDimension('lg'));
  return (
    <RootView>
      <ContainerView>
        <MainListItem
          value={state[FarmLogVariant.ShowAll].value}
          onValueChange={(value) => onCheck(FarmLogVariant.ShowAll, value)}
          separated={!isLarge}
          title={strings['notificationsSettings.showNotifications']}
        />
      </ContainerView>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        paddingBottom: 20,
        flex: 1,
      },
    }),
  },
}));

const ContainerView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
        marginRight: 20,
        borderWidth: 1,
        borderColor: theme.colors.uiAdditional1,
        borderRadius: 12,
        overflow: 'hidden',
      },
    }),
  },
}));
