import {makeObservable, observable, reaction, runInAction} from 'mobx';
import {Auth} from '../Auth';
import {NavigationContainer} from '../NavigationContainer';
import {define, INTRO_PARAMS} from '../persistence';
import {Service} from '../structure';
import {AuthFarmStateHelper} from '../AuthFarmStateHelper';
import {IntroVideoManager} from './IntroVideoManager';
import {Millisecond} from '../Time';
import dayjs from 'dayjs';

export enum IntroModalStatus {
  Preview,
  Video,
}

export type IntroVideoModalStatus = IntroModalStatus;

export default class IntroVideoManagerService
  implements IntroVideoManager, Service
{
  @observable
  private _canModalPreviewShown: boolean = false;
  @observable
  private _modalStatus: IntroVideoModalStatus = IntroModalStatus.Preview;
  @observable
  private _isBannerShown: boolean = false;

  constructor(
    private readonly _root: {
      readonly navigationContainer: NavigationContainer;
      readonly auth: Auth;
      readonly authFarmStateHelper: AuthFarmStateHelper;
    },
  ) {
    makeObservable(this);
  }
  private async _markDoneVideo() {
    const params = await getIntroInfo();
    await setIntroInfo({
      ...(params.success && params.right ? params.right : {}),
      video: (dayjs()
        .add(30, 'day')
        .add(new Date().getSeconds(), 'seconds')
        .unix() * 1000) as Millisecond,
    });
  }
  private async _markDoneBanner() {
    const params = await getIntroInfo();
    await setIntroInfo({
      ...(params.success && params.right ? params.right : {}),
      banner: (dayjs()
        .add(7, 'day')
        .add(new Date().getSeconds(), 'seconds')
        .unix() * 1000) as Millisecond,
    });
  }

  get isModalPreviewCouldBeShown() {
    return this._canModalPreviewShown;
  }
  get shouldOpenBanner() {
    return this._isBannerShown;
  }
  get currentModalStatus() {
    return this._modalStatus;
  }
  closeBanner() {
    runInAction(() => (this._isBannerShown = false));
  }
  showBanner() {
    runInAction(() => (this._isBannerShown = true));
  }
  closeBannerOnTime() {
    this.closeBanner();
    this._markDoneBanner();
  }
  updateCanModalPreviewShown = () => {
    runInAction(() => (this._canModalPreviewShown = false));
  };
  closeVideoOnTime() {
    this._markDoneVideo();
    this._canModalPreviewShown = false;
  }

  setModalStatus(status: IntroVideoModalStatus) {
    this._modalStatus = status;
  }
  private static _isAvailableNow(time: Millisecond) {
    return dayjs(time).isBefore(dayjs());
  }
  private _listenAccountChange() {
    return reaction(
      () => this._root.auth.state,
      async (authState, prevState, _) => {
        if (authState?.kind !== 'Connected') {
          return;
        }
        if (!this._root.authFarmStateHelper.isNewbie) {
          _.dispose();
          this.updateCanModalPreviewShown();
          this.closeBanner();
          return;
        }
        const params = await getIntroInfo();
        if (!params.success || params.right?.video) {
          this.updateCanModalPreviewShown();
        }
        if (!params.success || params.right?.banner) {
          this.closeBanner();
        }
        if (
          params.success &&
          (!params.right?.video ||
            IntroVideoManagerService._isAvailableNow(params.right.video))
        ) {
          setTimeout(() => {
            runInAction(() => (this._canModalPreviewShown = true));
          }, 10000);
        }
        if (
          params.success &&
          (!params.right?.banner ||
            IntroVideoManagerService._isAvailableNow(params.right.banner))
        ) {
          this.showBanner();
        }
      },
    );
  }

  subscribe() {
    return this._listenAccountChange();
  }
}

type IntroInfo = {
  video?: Millisecond;
  banner?: Millisecond;
};

export const [getIntroInfo, setIntroInfo] = define<IntroInfo>(INTRO_PARAMS);
