import React from 'react';
import {observer} from 'mobx-react-lite';
import ReactivateTabView from './ReactivateTabView';
import {useForcedContext} from '../context';
import PurchaseScreenContext from './context';
import {useRoot} from '../Root/hooks';

export default observer(function ReactivateTabBinding() {
  const {
    isLoading,
    goToAccessRecovery,
    state: {selectedReactivationTime, selectReactivationTime},
  } = useForcedContext(PurchaseScreenContext);
  const {proposalsState} = useRoot();
  return (
    <ReactivateTabView
      isLoading={isLoading}
      goToAccessRecovery={goToAccessRecovery}
      selectedTime={selectedReactivationTime}
      items={proposalsState.uniqActivatorTimeList}
      onSelectTime={selectReactivationTime}
    />
  );
});
