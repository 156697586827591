import React from 'react';
import {observer} from 'mobx-react-lite';
import {useGetDimensions} from '../WindowDimensions/useDimensions';
import {StyleSheet} from 'react-native';
import {RefreshControlProps, ScrollView, View} from 'react-native';
import {expr} from 'mobx-utils';

export type SubscriptionManagementScreenTemplateProps = {
  refreshControl?: React.ReactElement<RefreshControlProps>;
  renderTotalPrice: () => React.ReactNode;
  children: React.ReactNode;
};

export default observer(function SubscriptionManagementScreenTemplate(
  props: SubscriptionManagementScreenTemplateProps,
) {
  const getDimension = useGetDimensions();
  const isLg = expr(() => getDimension('lg'));
  if (isLg) {
    return <LargeTemplate {...props} />;
  }
  return <MobileTemplate {...props} />;
});

const LargeTemplate = observer(
  ({
    refreshControl,
    renderTotalPrice,
    children,
  }: SubscriptionManagementScreenTemplateProps) => (
    <ScrollView
      refreshControl={refreshControl}
      contentContainerStyle={styles.largeContainer}>
      {children}
      {renderTotalPrice()}
    </ScrollView>
  ),
);

const MobileTemplate = observer(
  ({
    refreshControl,
    renderTotalPrice,
    children,
  }: SubscriptionManagementScreenTemplateProps) => (
    <View style={styles.root}>
      <ScrollView refreshControl={refreshControl}>{children}</ScrollView>
      {renderTotalPrice()}
    </View>
  ),
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  largeContainer: {
    paddingHorizontal: 20,
  },
});
