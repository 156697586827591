import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Body, PressableOpacity} from '../../index';
import {variance} from '../../../styling';
import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import {PressableOpacityProps} from '../../PressableOpacity';
import YellowFlash from '../../../assets/svg/colored/yellowFlash.svg';
import RedGiftFlash from '../../../assets/svg/colored/redGiftFlash.svg';
import {sized} from '../../../Svg';
import {
  LG_BREAKPOINT,
  useGetDimensions,
} from '../../../WindowDimensions/useDimensions';
import {Divider} from '../../atoms';
import {expr} from 'mobx-utils';
import DoubleGiftSection from './DoubleGiftSection';
import {PurchaseTabsKind} from '../../../PurchaseScreen';

export type PurchaseRowViewProps = PressableOpacityProps & {
  disabled: boolean;
  selected: boolean;
  duration: string;
  priceText: string;
  helperText: string | undefined;
  discounts: number[];
  bordered?: boolean;
  isLast?: boolean;
  selectedTab?: PurchaseTabsKind;
};

export default observer(function PurchaseRowView(props: PurchaseRowViewProps) {
  const {
    disabled,
    selected,
    duration,
    helperText,
    priceText,
    discounts,
    selectedTab,
    bordered,
    ...rest
  } = props;
  const getDimension = useGetDimensions();
  const isLg = expr(() => getDimension('lg'));
  const isYearsGiftPlan = useMemo(
    () =>
      duration.includes('12') &&
      !disabled &&
      selectedTab === PurchaseTabsKind.Worker,
    [duration, disabled, selectedTab],
  );
  if (isLg) {
    return (
      <RootPressable
        selected={selected}
        unavailable={disabled}
        disabled={disabled || selected}
        bordered={bordered}
        activeOpacity={1}
        {...rest}>
        <LgRow>
          <RowLeft>
            {isYearsGiftPlan ? <RedGiftFlashIcon /> : <YellowFlashIcon />}
            <MonthText type="body" weight="500" selected={selected}>
              {duration}
            </MonthText>
          </RowLeft>
          {!disabled && <Checkbox selected={selected} />}
        </LgRow>
        <Divider />
        <LgRow>
          <View>
            <PriceText type="body" weight="600" selected={selected}>
              {priceText}
            </PriceText>
            {helperText !== undefined ? (
              <SuffixText type="body" weight="400">
                {helperText}
              </SuffixText>
            ) : null}
          </View>
          <View>
            {discounts.map((_, index) => (
              <PromoView
                key={index}
                offsetTop={index !== 0}
                secondary={index % 2 === 0}>
                <PromoText type="body" weight="600">
                  -{_}%
                </PromoText>
              </PromoView>
            ))}
          </View>
        </LgRow>
      </RootPressable>
    );
  }
  return (
    <>
      <RootPressable
        selected={selected}
        unavailable={disabled}
        disabled={disabled || selected}
        {...rest}
        activeOpacity={1}>
        <RowLeft>
          <FlashIconView>
            {isYearsGiftPlan ? <RedGiftFlashIcon /> : <YellowFlashIcon />}
          </FlashIconView>
          <MonthText numberOfLines={2} type="body" weight="500">
            {duration}
          </MonthText>
        </RowLeft>
        <RowRightView>
          <PriceText type="body" weight="600">
            {priceText}
          </PriceText>
          {helperText !== undefined ? (
            <SuffixText type="body" weight="400">
              {helperText}
            </SuffixText>
          ) : null}
        </RowRightView>
        {discounts.length > 0 && (
          <PromoListView>
            {discounts.map((_, index) => (
              <PromoView
                key={index}
                offsetTop={index !== 0}
                secondary={index % 2 === 0}>
                <PromoText type="body" weight="600">
                  -{_}%
                </PromoText>
              </PromoView>
            ))}
          </PromoListView>
        )}
        {!disabled && <Checkbox selected={selected} />}
      </RootPressable>
      {isYearsGiftPlan && <DoubleGiftSection />}
    </>
  );
});

export type CheckboxProps = ViewProps & {
  selected: boolean;
  dotStyle?: StyleProp<ViewStyle>;
};

export const Checkbox = observer(
  ({selected, style, dotStyle, ...rest}: CheckboxProps) => {
    return (
      <CheckboxView selected={selected} style={style} {...rest}>
        {selected && <Dot style={dotStyle} />}
      </CheckboxView>
    );
  },
);

const YellowFlashIcon = sized(YellowFlash, 24, 24);
const RedGiftFlashIcon = sized(RedGiftFlash, 24, 24);

const RootPressable = variance(PressableOpacity)((theme) => ({
  root: {
    height: 66,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 5,
    zIndex: 2,
    elevation: 2,
    backgroundColor: theme.colors.primaryWhite,
    borderRadius: 12,
    marginBottom: 8,
    borderWidth: 1,
    borderColor: theme.colors.primaryWhite,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
        height: 'auto',
        marginRight: 18,
        paddingHorizontal: 0,
        paddingVertical: 0,
        flexDirection: 'column',
        alignItems: undefined,
      },
    }),
  },
  unavailable: {
    backgroundColor: theme.chroma(theme.colors.primaryWhite).darken(0.4).hex(),
    borderColor: theme.chroma(theme.colors.primaryWhite).darken(0.4).hex(),
  },
  bordered: {
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
  selected: {
    backgroundColor: '#FFF8E0',
    borderColor: '#FFF8E0',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderColor: theme.colors.primaryBlack,
        backgroundColor: theme.colors.primaryBlack,
      },
    }),
  },
  isLast: {
    marginBottom: 0,
    marginRight: 0,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 8,
      },
    }),
  },
}));

const CheckboxView = variance(View)((theme) => ({
  root: {
    width: 14,
    height: 14,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.primaryWhite,
  },
  selected: {
    borderColor: theme.colors.primaryBlack,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.colors.primaryBlack,
        borderColor: theme.colors.primaryAttractive,
      },
    }),
  },
}));

const PromoView = variance(View)((theme) => ({
  root: {
    height: 20,
    width: 38,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.colors.primarySuccess,
  },
  offsetTop: {
    marginTop: 5,
  },
  secondary: {
    backgroundColor: theme.colors.primaryNew,
  },
}));

const PromoText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    fontSize: 12,
    lineHeight: 15,
  },
}));

const Dot = variance(View)((theme) => ({
  root: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.colors.primaryBlack,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.colors.primaryAttractive,
      },
    }),
  },
}));

const RowLeft = variance(View)(() => ({
  root: {
    flex: 2,
    flexShrink: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const FlashIconView = variance(View)(() => ({
  root: {
    width: 24,
  },
}));

const MonthText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    marginLeft: 10,
    fontSize: 16,
    lineHeight: 20,
  },
  selected: {
    color: theme.colors.primaryWhite,
  },
}));

const PriceText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    fontSize: 14,
    lineHeight: 18,
    marginBottom: 2,
  },
  selected: {
    color: theme.colors.primaryWhite,
  },
}));

const SuffixText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 12,
    lineHeight: 16,
  },
}));

const RowRightView = variance(View)(() => ({
  root: {
    alignItems: 'flex-end',
  },
}));

const PromoListView = variance(View)((theme) => ({
  root: {
    marginHorizontal: 6,
    width: 37,
    ...theme.mediaQuery({
      [425]: {
        marginHorizontal: 12,
        width: 45,
      },
    }),
  },
}));

const LgRow = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
