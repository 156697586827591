import React from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {StyleSheet, View} from 'react-native';
import {Typography} from '../components';
import {variance} from '../styling';
import {Divider} from '../components/atoms';
import {useGetDimensions} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import isPoolMiner from '../DashboardScreen/checkers/isPoolMiner';
import dayjs from 'dayjs';
import {useRoot, useStrings} from '../Root/hooks';

export type WorkerInfoProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function WorkerInfo({worker}: WorkerInfoProps) {
  const {
    translation: {localeTag},
  } = useRoot();
  const strings = useStrings();
  const isPool = expr(() => isPoolMiner(worker));
  const startTimeFormatted =
    worker.start_time &&
    dayjs(worker.start_time).locale(localeTag).format('DD MMM YYYY');
  const endTimeFormatted =
    worker.start_time &&
    dayjs(worker.end_time).locale(localeTag).format('DD MMM YYYY');
  const getDimension = useGetDimensions();
  const isLarge = expr(() => getDimension('lg'));
  return (
    <RootView>
      <Divider style={styles.horizontalDivider} />
      <ContentView large={isLarge}>
        <ItemView rightInset={isLarge}>
          <ItemTitle type="body" weight="500">
            {strings['workerDetails.workerId']}
          </ItemTitle>
          <ItemValue type="body" weight="500">
            {worker.id}
          </ItemValue>
        </ItemView>
        {isPool && startTimeFormatted !== null && (
          <ItemView toRight={isLarge} rightOffset={isLarge}>
            <ItemTitle type="body" weight="500">
              {strings['workerDetails.activated']}:
            </ItemTitle>
            <ItemValue type="body" weight="500">
              {startTimeFormatted}
            </ItemValue>
          </ItemView>
        )}
        {isPool && endTimeFormatted !== null && (
          <ItemView>
            <ItemTitle type="body" weight="500">
              {strings['workerDetails.expires']}:
            </ItemTitle>
            <ItemValue type="body" weight="500">
              {endTimeFormatted}
            </ItemValue>
          </ItemView>
        )}
      </ContentView>
      {isLarge && <Divider style={styles.horizontalDivider} />}
    </RootView>
  );
});

const styles = StyleSheet.create({
  horizontalDivider: {
    width: '100%',
  },
});

const RootView = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
  },
}));

const ContentView = variance(View)(() => ({
  root: {},
  large: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const ItemView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    paddingVertical: 12.5,
  },
  rightOffset: {
    marginRight: 150,
  },
  rightInset: {
    paddingRight: 10,
  },
  toRight: {
    marginLeft: 'auto',
  },
}));

const ItemTitle = variance(Typography)(() => ({
  root: {
    flexDirection: 'row',
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
    marginRight: 4,
  },
}));

const ItemValue = variance(Typography)((theme) => ({
  root: {
    flexDirection: 'row',
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 12,
    lineHeight: 15,
    letterSpacing: -0.2,
  },
}));
