import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {QuickStartListItemType, QuickStartListVariant} from './types';
import {createStylesHook, variance} from '../styling';
import {Pressable, View} from 'react-native';
import LowSvg from '../assets/svg/colored/lowIncome.svg';
import StandardIncomeSvg from '../assets/svg/colored/standartIncome.svg';
import MaxSvg from '../assets/svg/colored/maximumIncome.svg';
import {sized} from '../Svg';
import {Body} from '../components';
import {Checkbox} from '../components/organisms/purchase';
import {Divider} from '../components/atoms';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {useStrings} from '../Root/hooks';

export default observer(function QuickStartListItem(
  props: QuickStartListItemType & {
    selected?: boolean;
    onPress?: () => void;
    hideCheck?: boolean;
  },
) {
  const {
    info,
    onPress,
    hs,
    variant,
    price,
    label,
    selected,
    hideCheck,
    disabled,
    interval,
  } = props;
  const strings = useStrings();
  const styles = useStyles();
  const contentMap = useMemo(
    () => ({
      [QuickStartListVariant.Low]: {
        Icon: <LowIcon />,
        title: strings['quickStart.income.low'],
      },
      [QuickStartListVariant.Standard]: {
        Icon: <StandardIcon />,
        title: strings['quickStart.income.standart'],
      },
      [QuickStartListVariant.Maximum]: {
        Icon: <MaxIcon />,
        title: strings['quickStart.income.maximal'],
      },
    }),
    [strings],
  );
  const priceStr = useMemo(
    () => price + '/' + interval + ' ' + strings['common.shortMonth'],
    [interval, price, strings],
  );
  const words = contentMap[variant].title.split(' ');
  const firstWord = words[0];
  const restOfTheString = words.slice(1).join(' ');
  return (
    <Root disabled={disabled} selected={selected} onPress={onPress}>
      {!!label && (
        <Label orange={label === 'Recommended'}>
          <LabelText type="body" weight="500">
            {label === 'Recommended'
              ? strings['quickStart.label.recommended']
              : strings['quickStart.label.current']}
          </LabelText>
        </Label>
      )}
      <Inner>
        <InnerRow>
          {contentMap[variant].Icon}
          <View>
            <Name numberOfLines={2} type="body" weight="500">
              {firstWord}
            </Name>
            <Name type="body" weight="500">
              {restOfTheString}
            </Name>
          </View>
        </InnerRow>
        <InnerRow second toEnd={hideCheck}>
          <View>
            <PriceText type="body" weight="600">
              {priceStr}
            </PriceText>
            <SuffixText type="body">{hs} H/s</SuffixText>
          </View>
          {!hideCheck && <Check dotStyle={styles.dot} selected={!!selected} />}
        </InnerRow>
      </Inner>
      {!!info && !hideCheck && selected && (
        <>
          <Divider />
          <Info>
            <InfoText type="body">{info.name}</InfoText>
            <InfoText type="body">{info.hs} H/s</InfoText>
          </Info>
        </>
      )}
    </Root>
  );
});

const LowIcon = sized(LowSvg, 32);
const StandardIcon = sized(StandardIncomeSvg, 32);
const MaxIcon = sized(MaxSvg, 32);

const useStyles = createStylesHook((theme) => ({
  dot: {
    backgroundColor: theme.colors.primaryBlack,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.colors.primaryBlack,
      },
    }),
  },
}));

const Root = variance(Pressable)((theme) => ({
  root: {
    position: 'relative',
    marginBottom: 20,
    borderRadius: 12,
    backgroundColor: theme.colors.primaryWhite,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
  disabled: {
    backgroundColor: theme.chroma(theme.colors.primaryWhite).darken(0.4).hex(),
  },
  selected: {
    backgroundColor: '#FFF8E0',
  },
}));

const Check = variance(Checkbox)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.colors.primaryWhite,
        borderColor: theme.colors.primaryBlack,
      },
    }),
  },
}));

const Name = variance(Body)((theme) => ({
  root: {
    marginLeft: 12,
    fontSize: 13,
    lineHeight: 16,
    color: theme.colors.primaryBlack,
  },
}));

const Label = variance(View)((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    top: -8,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: theme.colors.primaryUIDirtyBlue,
    borderRadius: 4,
  },
  orange: {
    backgroundColor: theme.colors.primaryNew,
  },
}));

const LabelText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    fontSize: 10,
    lineHeight: 12,
    textTransform: 'uppercase',
  },
}));

const InnerRow = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  second: {
    maxWidth: '45%',
    width: '100%',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: '20%',
      },
    }),
  },

  toEnd: {
    justifyContent: 'flex-end',
  },
}));

const Inner = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const PriceText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    fontSize: 13,
    lineHeight: 16,
    marginBottom: 2,
    textAlign: 'right',
  },
}));

const SuffixText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 10,
    lineHeight: 12,
    textAlign: 'right',
  },
}));

const Info = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
}));
const InfoText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 10,
    lineHeight: 12,
  },
}));
