import {Translation} from '../Localization';
import {Error, ErrorHandler, OccurredErrorResult} from './ErrorHandler';
import {isGlobalError, isRpcError} from '../Error';
import {DELETE_FARM_ERROR} from '../Error/DeleteFarmError';

export default abstract class BaseErrorHandlerImpl implements ErrorHandler {
  protected constructor(readonly translation: Translation) {}

  protected abstract _onPriceChanged(error: Error): void;
  protected abstract _onWorkerNotManaged(error: Error): void;
  protected abstract _onOccurredError(
    error: Error,
    result: OccurredErrorResult,
  ): void;
  protected abstract _onGroupNameIsAlreadyInUse(error: Error): void;
  protected abstract _onMethodNotAllowedForYourSubscription(error: Error): void;

  private _handleOccurredError(error: Error) {
    const description = isRpcError(error)
      ? this.translation.templates['error.occurredWithStatusCode']({
          message: error.message,
          code: error.code,
        })
      : this.translation.templates['error.occurred']({});
    this._onOccurredError(error, {description: description});
  }

  handle(error: Error) {
    if (isGlobalError(error)) {
      switch (error.kind) {
        case DELETE_FARM_ERROR:
          return this._onOccurredError(error, {
            title: this.translation.strings['error.deleteMinersFirst.title'],
            description:
              this.translation.strings['error.deleteMinersFirst.description'],
          });
      }
    }

    if (isRpcError(error)) {
      switch (error.code) {
        case 1011:
          return this._onPriceChanged(error);
        case 3007:
          return this._onWorkerNotManaged(error);
        case 3008:
          return this._onGroupNameIsAlreadyInUse(error);
        case 5003:
          return this._onMethodNotAllowedForYourSubscription(error);
        case 5002:
          // Ignored
          break;
        default:
          return this._handleOccurredError(error);
      }
    }
    this._handleOccurredError(error);
  }
}
