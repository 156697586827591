export enum Representation {
  Auto,

  Modal,

  BottomSheet,
}

export enum ModalWidth {
  Auto,
  Small,
  Large,
  Medium,
}

export type ModalRef = {
  close: () => void;
};
