import {Colors} from './Coloring';
import staticColors from './staticColors';

const darkColors: Colors = {
  ...staticColors,
  backgroundBlackout: 'rgba(20, 23, 33, 0.9)',
  backgroundGround: '#141721',
  shadowMain: '#191D24',
  uiAdditional1: '#2D3040',
  uiAdditional2: '#414D65',
  uiAdditional3: '#343844',
  uiAdditional4: '#42485D',
  uiGradientDivider: [
    'rgba(130, 135, 143, 0)',
    'rgba(130, 135, 143, 0.8)',
    'rgba(130, 135, 143, 0)',
  ],
  uiGradientTemp: ['#FF3347', '#343844'],
  uiMain: '#1E222F',
  uiSecondary: '#DDDDDD',
};

export default darkColors;
