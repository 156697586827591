import BaseMinerProposalsStateImpl from './BaseMinerProposalsStateImpl';
import {
  ManagedProposalSubscription,
  Proposal,
  SubscriptionCandidateKind,
} from './ProposalsState';
import {InAppOffers, MinerSubscriptionOffer} from '../InAppOffersService';

export default class AndroidMinerProposalsStateImpl extends BaseMinerProposalsStateImpl {
  constructor(
    readonly _root: {
      readonly inAppOffers: InAppOffers;
    },
  ) {
    super(_root);
  }

  protected _getManagedProposalSubscription(
    proposal: Proposal<MinerSubscriptionOffer>,
  ): ManagedProposalSubscription<MinerSubscriptionOffer> | undefined {
    const purchasedIds = this._root.inAppOffers.purchasedIds;
    const kind = !proposal?.available
      ? SubscriptionCandidateKind.Unavailable
      : purchasedIds.has(proposal.offer.purchaseId)
      ? SubscriptionCandidateKind.CanBeManaged
      : SubscriptionCandidateKind.Available;
    return {
      ...proposal,
      kind,
    };
  }
}
