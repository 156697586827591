import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import ProposalTableView, {ProposalItem} from './ProposalTableView';
import {useForcedContext} from '../context';
import PurchaseScreenContext from './context';
import {useRoot, useStrings} from '../Root/hooks';
import {OfferId, OfferPurchaseType} from '../InAppOffersService';
import {Proposal} from '../ProposalsState';
import getMinerSlotPrice from './getMinerSlotPrice';

export default observer(function ActivatorSubscriptionTableBinding() {
  const {
    state: {
      selectActivatorPurchaseId,
      selectedActivatorProposal,
      selectedActivatorProposals,
      selectedPaymentMethod,
    },
  } = useForcedContext(PurchaseScreenContext);
  const {inAppOffers, proposalsState} = useRoot();
  const strings = useStrings();
  const items: TargetProposalItem[] = useMemo(() => {
    return proposalsState.uniqActivatorIntervalList.map((interval) => {
      const _ = selectedActivatorProposals?.find(
        (_) => _.offer.interval === interval,
      );
      const payment = _?.offer.payments.get(selectedPaymentMethod);
      if (!_?.available || !payment) {
        return {
          available: false,
          price: strings['common.notAvailable'],
          duration: interval,
          helper: '',
          discounts: [],
          selected: false,
          disabled: true,
          hashrate: undefined,
        };
      }
      const o = _.offer;
      const {discounts} = payment;
      const itemDiscounts = discounts?.map((d) => d.percent) ?? [];
      const selected = o.id === selectedActivatorProposal?.offer.id ?? false;
      return {
        offerId: o.offerId,
        available: true,
        duration: interval,
        helper: undefined,
        price: getMinerSlotPrice(payment, strings),
        disabled: checkIsBought(_),
        discounts: itemDiscounts,
        selected,
        hashrate: undefined,
      };
    });
  }, [
    proposalsState,
    selectedActivatorProposal,
    selectedActivatorProposals,
    selectedPaymentMethod,
    strings,
  ]);
  const handleItemPress = useCallback(
    (item: TargetProposalItem) => {
      if (item.available && item.offerId) {
        const candidate = inAppOffers.offerById.get(item.offerId);
        if (candidate) {
          selectActivatorPurchaseId(candidate.purchaseId);
        }
      }
    },
    [inAppOffers, selectActivatorPurchaseId],
  );
  return <ProposalTableView onItemPress={handleItemPress} items={items} />;
});

type TargetProposalItem = ProposalItem & {offerId?: OfferId};

const checkIsBought = (proposal?: Proposal) => {
  if (!proposal) {
    return false;
  }
  const {offer} = proposal;
  return (
    (offer.purchaseType === OfferPurchaseType.MinerSubscription ||
      offer.purchaseType === OfferPurchaseType.ActivatorSubscription) &&
    offer.bought
  );
};
