import React from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useForcedContext} from '../context';
import {WorkerDetailsContext} from './context';
import WorkerMenuHeader from '../components/organisms/worker/WorkerMenuHeader';

export type MenuHeaderProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function MenuHeader({worker}: MenuHeaderProps) {
  const {goToWorkerAction} = useForcedContext(WorkerDetailsContext);
  return (
    <WorkerMenuHeader
      mode="detail"
      onMenuPress={goToWorkerAction}
      worker={worker}
    />
  );
});
