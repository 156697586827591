import {WithdrawalStatus, Satoshi, satoshiToBitcoin} from '../ApiStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useRoot} from '../Root/hooks';
import {View} from 'react-native';
import dayjs from 'dayjs';
import {useStyles} from '../styling';
import {Millisecond} from '../utils/time';
import {Header, Paragraph} from '../components';
import {BREAKPOINT_PX} from './constants';

export type WithdrawalProps = {
  amount: Satoshi;
  status: WithdrawalStatus;
  createdAt: Millisecond;
  id: number;
};

export default observer((props: WithdrawalProps) => {
  const {amount, status, createdAt, id} = props;
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 16,
      height: 59,
      ...theme.mediaQuery({
        [BREAKPOINT_PX]: {
          height: 46,
        },
      }),
    },
    badgeView: {
      color: theme.colors.primarySuccess,
    },
    badgeViewDisabled: {
      color: theme.colors.primaryUIDirtyBlue,
    },
    badgeViewFailed: {
      color: theme.colors.primaryError,
    },

    info: {
      marginTop: 6,
      flexDirection: 'row',
      justifyContent: 'space-between',
      ...theme.mediaQuery({
        [BREAKPOINT_PX]: {
          flex: 6,
        },
      }),
    },
    infoText: {
      color: theme.colors.primaryUIDirtyBlue,
      ...theme.mediaQuery({
        [BREAKPOINT_PX]: {
          flex: 3,
          textAlign: 'center',
        },
      }),
    },
    triple: {
      ...theme.mediaQuery({
        [BREAKPOINT_PX]: {
          flex: 9,
          flexDirection: 'row',
          alignItems: 'center',
        },
      }),
    },
    title: {
      ...theme.mediaQuery({
        [BREAKPOINT_PX]: {
          flex: 3,
        },
      }),
    },
    status: {
      ...theme.mediaQuery({
        [BREAKPOINT_PX]: {
          flex: 3,
          textAlign: 'center',
        },
      }),
    },
  }));
  const {translation} = useRoot();
  const {strings, localeTag} = translation;
  return (
    <View>
      <View style={styles.root}>
        <View style={styles.triple}>
          <Header type="header" size="h3" style={styles.title} weight="400">
            {satoshiToBitcoin(amount).toFixed(8)} BTC
          </Header>
          <View style={styles.info}>
            <Paragraph style={styles.infoText} type="paragraph" size="tiny">
              {dayjs(createdAt).locale(localeTag).format('D MMMM, HH:mm')}
            </Paragraph>
            <Paragraph style={styles.infoText} type="paragraph" size="tiny">
              ID: {id}
            </Paragraph>
          </View>
        </View>
        {status === WithdrawalStatus.Processed ? (
          <Paragraph
            type="paragraph"
            style={[styles.status, styles.badgeView]}
            size="large">
            {strings['withdrawalHistory.processedStatus']}
          </Paragraph>
        ) : status === WithdrawalStatus.Failed ? (
          <Paragraph
            style={[styles.status, styles.badgeViewFailed]}
            type="paragraph"
            size="large">
            {strings['withdrawalHistory.failedStatus']}
          </Paragraph>
        ) : (
          <Paragraph
            style={[styles.status, styles.badgeViewDisabled]}
            type="paragraph"
            size="large">
            {strings['withdrawalHistory.waitingStatus']}
          </Paragraph>
        )}
      </View>
    </View>
  );
});
