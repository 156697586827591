import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {expr} from 'mobx-utils';
import Svg, {Defs, G, Mask, Path, Rect} from 'react-native-svg';
import {FRAME} from './constants';
import {
  CameraView,
  PermissionResponse,
  BarcodeScanningResult,
  BarcodeSettings,
} from 'expo-camera';
import {useThrottledCallback} from 'use-debounce';
import {nanoid} from 'nanoid';
import PositionHelperStatic from './PositionHelperStatic';
import NeedAccess from './NeedAccess';

export type CameraLayerProps = {
  onBarCodeScanned: (data: BarcodeScanningResult) => void;
  visibleCamera: boolean;
  requestPermission: () => void;
  cameraPermissions: PermissionResponse | undefined;
  isGranted: boolean;
  canAskAgain: boolean;
  getWidth: () => number;
  getHeight: () => number;
};

export default observer(function CameraLayer(props: CameraLayerProps) {
  const {
    onBarCodeScanned,
    visibleCamera,
    requestPermission,
    isGranted,
    canAskAgain,
    getWidth,
    getHeight,
  } = props;

  const handleBarCodeScanned = useCallback(
    (data: BarcodeScanningResult) => onBarCodeScanned(data),
    [onBarCodeScanned],
  );
  const handleBarCodeScannedThrottled = useThrottledCallback(
    handleBarCodeScanned,
    1000,
    {trailing: false},
  );

  return (
    <View style={styles.absoluteFill}>
      {visibleCamera && (
        <CameraView
          facing="back"
          barcodeScannerSettings={BAR_CODE_SCANNER_SETTINGS}
          ratio="16:9"
          onBarcodeScanned={handleBarCodeScannedThrottled}
          style={StyleSheet.absoluteFillObject}
        />
      )}
      <View style={StyleSheet.absoluteFillObject}>
        <FrameMask
          isGranted={isGranted}
          canAskAgain={canAskAgain}
          requestPermission={requestPermission}
          width={getWidth()}
          height={getHeight()}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  absoluteFill: {
    ...StyleSheet.absoluteFillObject,
  },
  needAccess: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: FRAME,
    height: FRAME,
  },
});

const BAR_CODE_SCANNER_SETTINGS: BarcodeSettings = {
    barcodeTypes: ['qr'],
};

export type FrameMaskProps = {
  width: number;
  height: number;
  requestPermission: () => void;
  isGranted: boolean;
  canAskAgain: boolean | undefined;
};

const FrameMask = observer(
  ({
    requestPermission,
    isGranted,
    canAskAgain,
    width,
    height,
  }: FrameMaskProps) => {
    const window = {width, height};
    const frameTop = expr(() =>
      PositionHelperStatic.getFrameTopPosition(window),
    );
    const frameLeft = expr(() =>
      PositionHelperStatic.getFrameLeftPosition(window),
    );
    const maskId = useRef(nanoid());
    const svg = (
      <Svg width="100%" height="100%">
        <Defs>
          <Mask
            id={maskId.current}
            x="0"
            y="0"
            width={window.width}
            height={window.height}>
            <Rect
              x="0"
              y="0"
              width={window.width}
              height={window.height}
              fill="#a1a1a1"
            />
            <Rect
              x={frameLeft}
              y={frameTop}
              width={FRAME}
              height={FRAME}
              fill="#000000"
              rx="25"
            />
          </Mask>
        </Defs>
        <Rect
          x="0"
          y="0"
          width={window.width}
          height={window.height}
          fill="#000000"
          mask={`url(#${maskId.current})`}
        />
        <G x={frameLeft} y={frameTop}>
          <Path
            d="M24 0H72.5C73.8807 0 75 1.11929 75 2.5C75 3.88071 73.8807 5 72.5 5H24C13.5066 5 5 13.5066 5 24V72.5C5 73.8807 3.88071 75 2.5 75C1.11929 75 0 73.8807 0 72.5V24C0 10.7452 10.7452 0 24 0Z"
            fill="#F7C61A"
          />
          <Path
            d="M300 24L300 72.5C300 73.8807 298.881 75 297.5 75C296.119 75 295 73.8807 295 72.5L295 24C295 13.5066 286.493 5 276 5L227.5 5C226.119 5 225 3.88071 225 2.5C225 1.11928 226.119 -3.22943e-06 227.5 -3.16908e-06L276 -1.04907e-06C289.255 -4.69686e-07 300 10.7452 300 24Z"
            fill="#F7C61A"
          />
          <Path
            d="M276 300L227.5 300C226.119 300 225 298.881 225 297.5C225 296.119 226.119 295 227.5 295L276 295C286.493 295 295 286.493 295 276L295 227.5C295 226.119 296.119 225 297.5 225C298.881 225 300 226.119 300 227.5L300 276C300 289.255 289.255 300 276 300Z"
            fill="#F7C61A"
          />
          <Path
            d="M2.86197e-07 276L8.64554e-07 227.5C8.81019e-07 226.119 1.11929 225 2.5 225C3.88071 225 5 226.119 5 227.5L5 276C5 286.493 13.5066 295 24 295L72.5 295C73.8807 295 75 296.119 75 297.5C75 298.881 73.8807 300 72.5 300L24 300C10.7452 300 1.28135e-07 289.255 2.86197e-07 276Z"
            fill="#F7C61A"
          />
        </G>
      </Svg>
    );
    const needAccess = (
      <View
        style={[
          styles.needAccess,
          {
            top: frameTop,
            left: frameLeft,
          },
        ]}>
        <NeedAccess canAsk={canAskAgain} onRequestPress={requestPermission} />
      </View>
    );
    return (
      <View
        style={{
          width: window.width,
          height: window.height,
        }}>
        {isGranted ? svg : needAccess}
      </View>
    );
  },
);
