import React, {useCallback} from 'react';
import {StyleProp, TextStyle, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {useStrings} from '../Root/hooks';
import {PurchaseRowView} from '../components/organisms/purchase';
import {Body} from '../components';
import {
  LG_BREAKPOINT,
  useGetDimensions,
} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {Hashrate, Interval} from '../ApiStore';
import {PurchaseTabsKind} from './PurchaseScreenState';

export type ProposalItem = {
  available: boolean;
  selected: boolean;
  disabled: boolean;
  duration: Interval;
  price: string;
  helper: string | undefined;
  discounts: number[];
  hashrate: Hashrate | undefined;
};

export type ProposalTableViewProps<I> = {
  items: I[];
  onItemPress: (item: I) => void;
  borderedItems?: boolean;
  selectedTab?: PurchaseTabsKind;
  titleStyle?: StyleProp<TextStyle>;
};

export default observer(function ProposalTableView(
  props: ProposalTableViewProps<ProposalItem>,
) {
  const {items, onItemPress, borderedItems, selectedTab} = props;
  const strings = useStrings();
  const getDimension = useGetDimensions();
  const isLg = expr(() => getDimension('lg'));
  const getDurationText = useCallback(
    (interval: Interval) => `${interval} ${strings['common.shortMonth']}`,
    [strings],
  );
  return (
    <RootView>
      {!isLg && (
        <TopView>
          <TopLabelText type="body" style={props.titleStyle} weight="500">
            {strings['common.period']}
          </TopLabelText>
        </TopView>
      )}
      {items.map((item, index) => (
        <PurchaseRowView
          selected={item.selected}
          disabled={item.disabled}
          key={index}
          selectedTab={selectedTab}
          isLast={index === items.length - 1}
          discounts={item.discounts}
          duration={getDurationText(item.duration as Interval)}
          priceText={item.price}
          helperText={item.helper}
          onPress={() => onItemPress(item)}
          bordered={borderedItems}
        />
      ))}
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    }),
  },
}));

const TopLabelText = variance(Body)((theme) => ({
  root: {
    marginBottom: 12,
    paddingLeft: 4,
    color: theme.colors.primaryBlack,
    fontSize: 16,
    lineHeight: 20,
  },
  percent: {
    paddingRight: 40,
    width: 63,
    textAlign: 'right',
  },
  price: {
    flex: 1,
    paddingRight: 20,
    textAlign: 'right',
  },
}));

const TopView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    paddingRight: 100,
  },
}));
