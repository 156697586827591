import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import ReloadSvg from '../assets/svg/colorless/reload.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {ButtonProps} from '../components/AppButton/AppButton';

export type RetryButtonProps = Omit<ButtonProps, 'children'> & {
  children?: string;
};

export default observer(function RetryButton(props: RetryButtonProps) {
  const strings = useStrings();
  return (
    <AppButton variant={ButtonVariant.Primary} Icon={ReloadIcon} {...props}>
      {props.children ?? strings['action.retry']}
    </AppButton>
  );
});

const ReloadIcon = sized(ReloadSvg, 20);
