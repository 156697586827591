import {InAppLoaderState} from './InAppLoaderState';
import {action, computed, observable, makeObservable} from 'mobx';
import {nanoid} from 'nanoid';

export default class InAppLoaderStateImpl implements InAppLoaderState {
  @observable private _uniqList: string[] = [];
  @observable private _text: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get text() {
    return this._text;
  }
  get isVisible() {
    return this._uniqList.length > 0;
  }

  addText = action((text: string) => {
    this._text = text;
  });

  deleteText = action(() => {
    this._text = '';
  });

  addLoading = action(() => {
    this._uniqList.push(nanoid());
  });

  deleteLoading = action(() => {
    this._uniqList.pop();
  });

  force = action(() => {
    this._uniqList = [];
  });
}
