import {observer} from 'mobx-react-lite';
import {ScreenCard} from '../components';
import {useStrings} from '../Root/hooks';
import React, {useMemo} from 'react';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {StyleSheet, View} from 'react-native';

export type AuthRequestScreenProps = {
  goToAuth: () => void;
};

export default observer((props: AuthRequestScreenProps) => {
  const {goToAuth} = props;
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <AppButton onPress={goToAuth} variant={ButtonVariant.Primary}>
        {strings['withdraw.authenticationRequired.authorize']}
      </AppButton>
    ),
    [goToAuth, strings],
  );
  return (
    <View style={styles.root}>
      <ScreenCard
        title={strings['withdraw.authenticationRequired.title']}
        description={strings['withdraw.authenticationRequired.description_2']}
        footer={footer}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
  },
});
