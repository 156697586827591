import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {Paragraph} from '../components';
import {variance} from '../styling';
import {useCalendarInfo} from './CalendarModalSettings';

export default observer(function CalendarModalDays() {
  const calendarBaseInfo = useCalendarInfo();
  return (
    <RootView>
      {calendarBaseInfo.shortDays.map((day, index) => (
        <CalendarDaysCell
          type="paragraph"
          size="large"
          weight="600"
          key={index}>
          {day}
        </CalendarDaysCell>
      ))}
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: 10,
  },
}));

const CalendarDaysCell = variance(Paragraph)(() => ({
  root: {
    flexBasis: `${100 / 7}%`,
    height: 44,
    textAlign: 'center',
    lineHeight: 44,
  },
}));
