import React, {PropsWithChildren} from 'react';
import {Pressable, View} from 'react-native';
import CheckSvg from '../assets/svg/colorless/check.svg';
import {sized} from '../Svg';

import {useStyles, useTheme} from '../styling';

export type CheckBoxProps = {
  checked: boolean;
  onChange?: (isChecked: boolean) => void;
};

export default ({
  checked,
  onChange,
  children,
}: PropsWithChildren<CheckBoxProps>) => {
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    checkbox: {
      width: 14,
      height: 14,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
      borderWidth: 1,
      borderColor: theme.colors.uiAdditional2,
    },
    checkboxChecked: {
      borderColor: theme.colors.primaryAttractive,
      backgroundColor: theme.colors.primaryAttractive,
    },
  }));
  const handleChange = () => onChange && onChange(checked);
  const theme = useTheme();
  const Component = onChange ? Pressable : View;
  return (
    <Component style={styles.root} onPress={handleChange}>
      <View style={[styles.checkbox, checked && styles.checkboxChecked]}>
        {checked && <CheckIcon color={theme.colors.primaryWhite} />}
      </View>
      {children}
    </Component>
  );
};

const CheckIcon = sized(CheckSvg, 9);
