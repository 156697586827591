import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {SectionWorkerList} from './SectionWorkerList';
import {SimpleWorkerList} from './SimpleWorkerList';
import {useRoot} from '../../Root/hooks';
import {Mode} from '../../DashboardMode';
import EmptyWorkerList from '../EmptyWorkerList';
import {StyleSheet, View} from 'react-native';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';
import {expr} from 'mobx-utils';
import {ScreenFluidLoader} from '../../components/atoms/CryptoLoader';
import RefreshControl from '../../components/RefreshControl';

export default observer(function WorkerList() {
  const {dashboardModeService, interactiveTutorial, dashboardStore} = useRoot();
  const {goToPickDemoMiner, goToPlan, goToAddWorker} =
    useForcedContext(DashboardContext);
  const isFullyEmpty = expr(
    () =>
      !dashboardStore.isLoading &&
      dashboardStore.workers.size === 0 &&
      [0, 1].includes(dashboardStore.groups.size),
  );
  const isFirstLoading = expr(
    () => dashboardStore.isLoading && dashboardStore.workers.size === 0,
  );
  const renderRefreshControl = useCallback(
    () =>
      interactiveTutorial.isShown ? undefined : (
        <RefreshControl
          refreshing={dashboardStore.isRefreshing}
          onRefresh={dashboardStore.refresh}
        />
      ),
    [dashboardStore, interactiveTutorial],
  );

  const renderList = useCallback(
    () =>
      dashboardModeService.mode === Mode.Simple ? (
        <SimpleWorkerList refreshControl={renderRefreshControl()} />
      ) : (
        <SectionWorkerList refreshControl={renderRefreshControl()} />
      ),
    [dashboardModeService, renderRefreshControl],
  );
  if (isFirstLoading) {
    return <ScreenFluidLoader />;
  }
  return isFullyEmpty ? (
    <EmptyWorkerList
      goToAddDemoMiner={goToPickDemoMiner}
      goToAddMiner={goToAddWorker}
      goToPlan={goToPlan}
      renderRefreshControl={renderRefreshControl}
    />
  ) : (
    <View style={styles.root}>{renderList()}</View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
