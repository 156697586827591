import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {AddWorkerAction, AddWorkerModal} from '../../AddWorkerModal';
import {useRoot} from '../../Root/hooks';
import {ApiMode} from '../../farmApi';
import {expr} from 'mobx-utils';

export type AddWorkerBindingProps = RootStackBindingProps<'AddWorker'>;

export default observer(function AddWorkerBinding({
  navigation,
}: AddWorkerBindingProps) {
  const {
    apiStore,
    quickStartOffer: {shouldAddMinerRedirectToQuickStart},
  } = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = () => modalRef.current?.close();
  const onAction = useCallback(
    async (action: AddWorkerAction) => {
      await close();
      safelyGoBack();
      switch (action) {
        case AddWorkerAction.AddWorker:
          return navigation.navigate('LinkWorker');
        case AddWorkerAction.AddDemoWorker:
          return navigation.navigate('LinkDemoWorker');
        case AddWorkerAction.AddPoolMiner:
          if (shouldAddMinerRedirectToQuickStart) {
            return navigation.navigate('QuickStart');
          }
          return navigation.navigate('Plan');
        case AddWorkerAction.ScanQR:
          return navigation.navigate('QRCodeScanner');
        case AddWorkerAction.Packet:
          return navigation.navigate('QuickStart');
        case AddWorkerAction.Products:
          return navigation.navigate('Products');
      }
    },
    [shouldAddMinerRedirectToQuickStart, navigation, safelyGoBack],
  );
  const isDemo = expr(() => apiStore.mode === ApiMode.Demo);
  return (
    <AddWorkerModal
      isDemo={isDemo}
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onAction={onAction}
    />
  );
});
