import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Pressable, TextProps, View} from 'react-native';
import {Typography} from '../components';
import {useStyles, useTheme, variance} from '../styling';
import CoinSvg from '../assets/svg/colored/coin.svg';
import SignInSvg from '../assets/svg/colorless/signIn.svg';
import {useRoot, useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type EmailLabelProps = TextProps & {
  email: string;
};

export default observer(function EmailLabel(props: EmailLabelProps) {
  const {email, ...rest} = props;
  const styles = useStyles((theme) => ({
    email: {
      color: theme.colors.primaryUIDirtyBlue,
      marginLeft: 5,
    },
  }));

  const {navigationContainer} = useRoot();

  const goToSignIn = useCallback(
    () => navigationContainer.ref?.navigate('Auth'),
    [navigationContainer],
  );

  const strings = useStrings();
  const theme = useTheme();

  return (
    <EmailLabelView>
      {email ? (
        <>
          <CoinSvg />
          <Typography
            type={'paragraph'}
            size={'regular'}
            style={styles.email}
            {...rest}>
            {email}
          </Typography>
        </>
      ) : (
        <Auth onPress={goToSignIn}>
          <AuthSvg color={theme.colors.uiAdditional4} />
          <Typography
            type={'paragraph'}
            size={'regular'}
            style={styles.email}
            {...rest}>
            {strings['menu.signUp']}
          </Typography>
        </Auth>
      )}
    </EmailLabelView>
  );
});

const AuthSvg = sized(SignInSvg, 18, 18);

const EmailLabelView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const Auth = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
