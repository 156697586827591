import {Either, error, success} from '../fp';
import {MessageContext} from '../Messaging';
import {LocalNotificationData} from './NotificationData';
import {Json} from '../Json';
import {
  HeadlessLocalNotifications,
  EventType,
  LocalNotificationContext,
  LocalNotificationId,
} from './HeadlessLocalNotifications';
import {Analytics} from '../Analytics';
import {Location} from '../Location';
import {HeadlessMessagingHelper} from '../Messaging';

export default abstract class BaseHeadlessLocalNotificationsImpl
  implements HeadlessLocalNotifications
{
  protected constructor(
    protected readonly _root: {
      readonly json: Json;
      readonly analytics: Analytics;
      readonly location: Location;
      readonly headlessMessagingHelper: HeadlessMessagingHelper;
    },
  ) {}

  protected async _wrapContext(
    context: MessageContext,
  ): Promise<Either<LocalNotificationData, unknown>> {
    if (context.message.type !== 'notification') {
      return error(new Error('Unknown message type'));
    }
    const contextString_ = this._root.json.stringify(context);
    if (!contextString_.success) {
      return error(new Error('Cannot stringify the context'));
    }
    return success({
      designator: 'local-notification-data',
      context: contextString_.right,
    });
  }

  abstract scheduleNotification(
    context: MessageContext,
  ): Promise<Either<void, unknown>>;

  abstract cancelNotification(
    id: LocalNotificationId,
  ): Promise<Either<void, unknown>>;

  async disposeNotification(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id: LocalNotificationId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    groupId: string,
  ): Promise<Either<void, unknown>> {
    return success();
  }

  abstract hideNotificationDrawer(): Promise<Either<void, unknown>>;

  async handle(
    context: LocalNotificationContext,
  ): Promise<Either<void, unknown>> {
    const {id, data, actionId, type} = context;
    const messageContext_ =
      await this._root.headlessMessagingHelper.exchangeNotificationDataForMessageContext(
        data,
      );
    if (!messageContext_.success) {
      return messageContext_;
    }
    const messageContext = messageContext_.right;
    const controller = this._root.analytics.createController();
    try {
      switch (type) {
        case EventType.Dismissed: {
          if (id !== undefined && messageContext.message.group) {
            await this.disposeNotification(id, messageContext.message.group.id);
          }
          controller.batch.report(messageContext.event_url, {
            event: 'close',
            meta: messageContext.meta,
            data: {},
          });
          break;
        }
        case EventType.Delivered: {
          const isInstant = messageContext.url === undefined;
          if (isInstant) {
            controller.batch.report(messageContext.event_url, {
              event: 'show',
              meta: messageContext.meta,
              data: {},
            });
          }
          break;
        }
        case EventType.Press: {
          const action = messageContext.message.actions?.find(
            (_) => _.action === actionId,
          );
          await this.hideNotificationDrawer();
          const url = action ? action.url : messageContext.message.url;
          if (url) {
            await this._root.location.open(url);
          }
          const meta = messageContext.meta;
          controller.batch.report(
            messageContext.event_url,
            action
              ? {event: 'action', meta, data: {action_name: action.action}}
              : {event: 'click', meta, data: {}},
          );
          if (id) {
            await this.cancelNotification(id);
          }
          break;
        }
        case EventType.Initial: {
          controller.batch.report(messageContext.event_url, {
            event: 'click',
            meta: messageContext.meta,
            data: {},
          });
          if (id) {
            await this.cancelNotification(id);
          }
          break;
        }
      }
      return success();
    } finally {
      await controller.flush();
    }
  }
}
