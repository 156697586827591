import React from 'react';
import {observer} from 'mobx-react-lite';
import {PromoBanner} from '../../components/organisms/banner/PromoBanner';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';
import {StyleSheet, View} from 'react-native';
import {variance} from '../../styling';
import {
  AFFILIATE_BANNER_HEIGHT,
  AffiliateBanner,
} from '../../components/organisms/banner/AffiliateBanner';
import {useRoot} from '../../Root/hooks';
import {BannerType} from '../../Dashboard';
import {AdvertBanner} from './AdvertBanner';
import ReactivateCard from './ReactivateCard';
import {BLUE_GRADIENT} from '../../components/molecules/ColoredBanner';

const CONTENT_HEIGHT = 176;

export default observer(function HeaderPromoBannerSection() {
  const {
    getAffiliateIsPending,
    safelyGoToPromoAffiliate,
    getAdvertMobileBanner,
    getAffiliateBannerVisible,
    closeAffiliateBanner,
    goToPoolActivator,
  } = useForcedContext(DashboardContext);
  const advertBanner = getAdvertMobileBanner();
  const height = useHeaderPromoBannerHeight();
  const {
    dashboardService: {bannersState},
  } = useRoot();
  return (
    <RootView style={{height}}>
      <AffiliateBanner
        visible={getAffiliateBannerVisible()}
        onClosed={closeAffiliateBanner}
      />
      <View style={styles.content}>
        {bannersState.targetBanner === BannerType.PoolMiner ? (
          <ReactivateCard
            goToPoolActivator={goToPoolActivator}
            style={styles.banner}
          />
        ) : (
          <>
            {advertBanner !== undefined ? (
              <AdvertBanner
                swipeable
                ad={advertBanner}
                style={styles.banner}
                containerStyle={styles.banner}
                childrenContainerStyle={styles.banner}
                background={BLUE_GRADIENT}
              />
            ) : (
              <PromoBanner
                style={styles.banner}
                getAffiliateIsPending={getAffiliateIsPending}
                safelyGoToPromoAffiliate={safelyGoToPromoAffiliate}
              />
            )}
          </>
        )}
      </View>
    </RootView>
  );
});

export const useHeaderPromoBannerHeight = () => {
  const {getAffiliateBannerVisible} = useForcedContext(DashboardContext);
  if (getAffiliateBannerVisible()) {
    return CONTENT_HEIGHT + AFFILIATE_BANNER_HEIGHT;
  }
  return CONTENT_HEIGHT;
};

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
    paddingVertical: 18,
    paddingHorizontal: 16,
  },
}));

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  banner: {
    flex: 1,
  },
});
