import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';

import {Header, PressableOpacity} from '../components';
import {useStrings} from '../Root/hooks';
import {useStyles} from '../styling';
import AnimatableFan from '../components/AnimatableFan';
import {AppButton} from '../components/AppButton';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export type OAuthSplashScreenProps = {
  onCancel: () => void;
};

export default observer(function OAuthSplashScreen({
  onCancel,
}: OAuthSplashScreenProps) {
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.uiMain,
    },
    content: {
      flex: 1,
      marginBottom: 35,
      justifyContent: 'center',
      alignItems: 'center',
    },
    description: {
      color: theme.colors.uiSecondary,
      textAlign: 'center',
    },
    gutter: {
      margin: 10,
    },
    button: {
      maxWidth: 345,
      width: '100%',
      alignSelf: 'center',
    },
  }));
  const strings = useStrings();
  const insets = useSafeAreaInsets();
  const paddingBottom = Math.max(insets.bottom, 16);
  return (
    <View style={[styles.root, {paddingBottom}]}>
      <View style={styles.content}>
        <AnimatableFan />
        <Header
          type="header"
          size="h4"
          style={[styles.gutter, styles.description]}>
          {strings['oauth.waitingForAuthorizationResponse']}
        </Header>
      </View>
      <PressableOpacity style={styles.button} onPress={onCancel}>
        <AppButton onPress={onCancel}>{strings['action.cancel']}</AppButton>
      </PressableOpacity>
    </View>
  );
});
