import {action, autorun, observable, makeObservable} from 'mobx';
import {
  DuplexTunnel,
  HubTunnel,
  increment,
  JsonRpcClient,
  JsonRpcServer,
  StructuredTunnel,
  Tunnel,
  TunnelKind,
} from '../JsonRpc';
import {Configuration, Debug} from '../Configuration';
import {
  CryptoFarmClientCalls,
  CryptoFarmClientNotifications,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from './CryptoFarm';
import RealBackend from './RealBackend';
import DemoBackend from '../LocalService/DemoBackend';
import {DemonstrationDatabase} from '../LocalService';
import {Translation} from '../Localization';
import {AppWindowState} from '../AppWindow';
import {Millisecond} from '../Time';
import {DeviceIdentification} from '../DeviceIdentification';
import {ApiStore} from './ApiStore';
import {ApiMode, Subscription} from '../farmApi';
import {FarmId, SlotCount} from './units';
import {Log} from '../Log';
import {ConnectionManager} from '../ConnectionManager';
import {DuplexTunnelLogBridgeService} from '../Log';
import {Http} from '../Http';
import {batchDisposers, Disposer, Service} from '../structure';

export default class ApiStoreService implements ApiStore, Service {
  @observable private _mode!: ApiMode;

  private readonly _ids = increment();
  private readonly _realBackend: RealBackend;
  private readonly _demoBackend: DemoBackend;
  private readonly _bridge: DuplexTunnelLogBridgeService;

  private readonly _entry = new DuplexTunnel(structuredTunnelFactory);
  private readonly _entryHub = new HubTunnel();
  private readonly _entryClient: JsonRpcClient<
    CryptoFarmServerCalls,
    CryptoFarmServerNotifications
  >;
  private readonly _entryServer = new JsonRpcServer<
    CryptoFarmClientCalls,
    CryptoFarmClientNotifications
  >(this._entry.bob);

  private _modeDisposer = () => {};

  constructor(
    private readonly _root: {
      readonly log: Log;
      readonly http: Http;
      readonly db: DemonstrationDatabase;
      readonly translation: Translation;
      readonly appWindowState: AppWindowState;
      readonly configuration: Configuration;
      readonly debug: Debug;
      readonly deviceIdentification: DeviceIdentification;
      readonly connectionManager: ConnectionManager;
    },
  ) {
    makeObservable(this);
    this._realBackend = new RealBackend(this._root);
    this._demoBackend = new DemoBackend(
      this._root,
      this._realBackend.tunnelState,
      this._realBackend.tunnel,
      this._ids,
    );
    this._bridge = new DuplexTunnelLogBridgeService(this._root);
    this._entryClient = new JsonRpcClient<
      CryptoFarmServerCalls,
      CryptoFarmServerNotifications
    >(
      this._entry.bob,
      this._ids,
      this._root.configuration.values.bffTimeout,
      this._root.connectionManager.connectionIdUpdates,
    );
    this.setMode(ApiMode.Real);
  }

  get tunnelState() {
    return this._realBackend.tunnelState;
  }

  get disconnect() {
    return this._realBackend.disconnect;
  }

  readonly reconnect = () => {
    return this._realBackend.reconnect();
  };

  get mode() {
    return this._mode;
  }

  @action.bound setMode(mode: ApiMode) {
    if (this._mode === mode) {
      return;
    }
    this._modeDisposer();
    if (mode === ApiMode.Real) {
      this._modeDisposer = batchDisposers(
        this._entryHub.connect(this._entry.alice),
        this._entryHub.connect(this._realBackend.tunnel),
      );
    } else {
      this._modeDisposer = batchDisposers(
        this._entryHub.connect(this._entry.alice),
        this._entryHub.connect(this._demoBackend.tunnel),
        this._demoBackend.subscribe(),
      );
    }
    this._mode = mode;
  }

  get client() {
    return this._entryClient;
  }

  get server() {
    return this._entryServer;
  }

  get incoming() {
    return this._realBackend.spy.incoming;
  }

  getDemoSubscription(): Subscription {
    const _ = this._demoBackend.getSubscription();
    return {
      accountId: _.accountId as FarmId,
      maxSlots: _.maxSlots as SlotCount,
      slots: _.slots as SlotCount,
      poolMiners: 0 as SlotCount,
      mode: ApiMode.Demo,
      android: true,
      ios: true,
      web: true,
      hasPurchases: false,
    };
  }

  setMiningInterval(updateInterval?: Millisecond) {
    return this._demoBackend.setUpdateInterval(updateInterval);
  }

  private _enableHistory() {
    return autorun(() => {
      const {logEnabled, demoLogEnabled} = this._root.debug;
      if (logEnabled) {
        if (demoLogEnabled && this._mode === ApiMode.Demo) {
          this._bridge.changeSource(
            this._demoBackend.spy.incoming,
            this._demoBackend.spy.outgoing,
          );
        } else {
          this._bridge.changeSource(
            this._realBackend.spy.incoming,
            this._realBackend.spy.outgoing,
          );
        }
      } else {
        this._bridge.unsubscribe();
      }
    });
  }

  subscribe() {
    return batchDisposers(
      this._realBackend.subscribe(),
      this._entryClient.subscribe(),
      this._entryServer.subscribe(),
      (() => this._modeDisposer()) as Disposer,
      this._enableHistory(),
      (() => this._bridge.unsubscribe()) as Disposer,
    );
  }
}

const structuredTunnelFactory = ({send, listen}: Tunnel): StructuredTunnel => ({
  tunnelKind: TunnelKind.Structured,
  send,
  listen,
});
