import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';
import ChevronLeftSvg from '../assets/svg/colorless/chevronLeft.svg';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {variance, useTheme} from '../styling';
import {Header, PressableOpacity} from '../components';
import {sized} from '../Svg';

export type CalendarModalMonthSwitcherProps = {
  date: string;
  onPrevMonth: () => void;
  onNextMonth: () => void;
};

export default observer(function CalendarModalMonthSwitcher({
  date,
  onPrevMonth,
  onNextMonth,
}: CalendarModalMonthSwitcherProps) {
  const theme = useTheme();
  return (
    <RootView>
      <Press onPress={onPrevMonth}>
        <ChevronLeftIcon color={theme.colors.primaryUIDirtyBlue} />
      </Press>
      <Header type="header" size="h4">
        {date}
      </Header>
      <Press inEnd onPress={onNextMonth}>
        <ChevronRightIcon color={theme.colors.primaryUIDirtyBlue} />
      </Press>
    </RootView>
  );
});

const ChevronRightIcon = sized(ChevronRightSvg, 6, 12);
const ChevronLeftIcon = sized(ChevronLeftSvg, 6, 12);

const RootView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Press = variance(PressableOpacity)(() => ({
  root: {
    flex: 1,
    width: 40,
    justifyContent: 'center',
    paddingLeft: 12,
  },
  inEnd: {
    alignItems: 'flex-end',
    paddingRight: 12,
  },
}));
