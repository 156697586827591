import {action, observable, reaction} from 'mobx';
import {Service} from '../structure';
import {InAppOffers, Offer, OfferPurchaseType} from '../InAppOffersService';
import {PurchaseId} from '../units';
import {
  CurrentActivator,
  CurrentActivatorSubscription,
} from './CurrentActivatorSubscription';
import {InAppPurchase} from '../InAppPurchase';

export default class CurrentActivatorSubscriptionService
  implements Service, CurrentActivatorSubscription
{
  @observable.ref private _current?: CurrentActivator;
  constructor(
    private readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchase: InAppPurchase;
    },
  ) {}

  get current() {
    return this._current;
  }

  private _setCurrent = action((current?: CurrentActivator) => {
    this._current = current;
  });

  private _findBoughtOffer(offers: Offer[], skus: ReadonlySet<PurchaseId>) {
    const activatorOffers = offers.flatMap((_) =>
      _.purchaseType === OfferPurchaseType.ActivatorSubscription ? [_] : [],
    );
    return activatorOffers.find((_) => skus.has(_.purchaseId));
  }

  private _searchWhenFulfilled() {
    return reaction(
      () => this._root.inAppOffers.isLoadedIn,
      async (isLoadedIn) => {
        if (isLoadedIn) {
          const offer = this._findBoughtOffer(
            this._root.inAppOffers.offers,
            this._root.inAppOffers.purchasedIds,
          );
          if (!offer) {
            return;
          }
          const history = await this._root.inAppPurchase.getPurchaseHistory();
          const candidate = history.find(
            (_) => _.productId === offer.purchaseId,
          );
          if (candidate) {
            this._setCurrent({
              offer,
              purchaseTokenAndroid: candidate.purchaseToken,
            });
          }
        }
      },
      {fireImmediately: true},
    );
  }

  subscribe() {
    return this._searchWhenFulfilled();
  }
}
