import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../styling';
import {View, ViewProps} from 'react-native';
import {Mono} from '../Typography';

export type BadgeCounterProps = ViewProps & {
  label: string | number | undefined;
};

export default observer(function BadgeCounter({
  label,
  ...rest
}: BadgeCounterProps) {
  if (label === undefined) {
    return null;
  }
  return (
    <CounterView {...rest}>
      <CounterText type="mono" weight="500">
        {label}
      </CounterText>
    </CounterView>
  );
});

const CounterView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryAttractive,
    height: 22,
    minWidth: 22,
    padding: 3,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 11,
  },
}));

const CounterText = variance(Mono)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    fontSize: 12,
    lineHeight: 14,
  },
}));
