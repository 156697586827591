import React from 'react';
import {observer} from 'mobx-react-lite';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {useRoot} from '../../Root/hooks';
import {DashboardBinding} from './DashboardBinding';
import WithdrawBindingSwitch from './WithdrawBindingSwitch';
import DebugBinding from './DebugBinding';
import {BottomTabParamList} from './BottomTabParamList';
import {MobileTabBar} from '../../components/organisms';
import useNotificationsCount from './useNotificationsCount';
import MockAddWorkerBinding from './MockAddWorkerBinding';
import useGoToAddWorker from './useGoToAddWorker';
import StatisticBinding from './StatisticBinding';
import NotificationsBinding from './NotificationsBinding';

const {Navigator, Screen} = createBottomTabNavigator<BottomTabParamList>();

export default observer(function BottomTab() {
  const {debug} = useRoot();
  const goToAddWorker = useGoToAddWorker();
  const tabBarBadge = useNotificationsCount();
  return (
    <Navigator
      initialRouteName="Dashboard"
      screenOptions={{
        headerShown: false,
      }}
      tabBar={(props) => (
        <MobileTabBar goToAddWorker={goToAddWorker} {...props} />
      )}>
      <Screen name="Dashboard" component={DashboardBinding} />
      <Screen name="Statistics" component={StatisticBinding} />
      <Screen name="MockAddWorker" component={MockAddWorkerBinding} />
      <Screen name="Withdraw" component={WithdrawBindingSwitch} />
      <Screen
        name="Notifications"
        component={NotificationsBinding}
        options={{tabBarBadge: tabBarBadge}}
      />
      {debug.debugEnabled && <Screen name="Debug" component={DebugBinding} />}
    </Navigator>
  );
});
