import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../styling';
import {Pressable, View} from 'react-native';
import {Paragraph} from '../../components';
import {useStrings} from '../../Root/hooks';
import {Second} from '../../Time';

export type ReactivationVariantTab = {
  items: Second[];
  selectedTime: Second | undefined;
  onSelectTime: (newTime: Second) => void;
};

export default observer(function ReactivationVariantTab({
  items,
  selectedTime,
  onSelectTime,
}: ReactivationVariantTab) {
  const strings = useStrings();
  return (
    <Container>
      {items.map((time) => {
        const isActive = time === selectedTime;
        return (
          <Tab
            key={time}
            onPress={() => onSelectTime(time)}
            active={time === selectedTime}>
            <TabText
              type="paragraph"
              size="large"
              weight="500"
              active={isActive}>
              {time / 3600}
              {strings['purchase.reactivation.hour']}
            </TabText>
          </Tab>
        );
      })}
    </Container>
  );
});

const Container = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    borderRadius: 8,
    marginBottom: 18,
    padding: 2,
    backgroundColor: theme.colors.primaryWhite,
  },
}));

const Tab = variance(Pressable)((theme) => ({
  root: {
    flex: 1,
    margin: 2,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  active: {
    backgroundColor: theme.colors.primaryActive,
  },
}));

const TabText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    textTransform: 'lowercase',
  },
  active: {
    color: theme.colors.primaryWhite,
  },
}));
