import {random} from 'lodash';
import {IMAGES, PercentPosition} from './constants';

export const rnrClamp = (
  value: number,
  lowerBound: number,
  upperBound: number,
) => {
  'worklet';
  return Math.min(Math.max(lowerBound, value), upperBound);
};

export const generateImageIdx = () => random(IMAGES.length - 1);
export const generateQuiz = () => {
  const target = {
    x: random(50, 90) as PercentPosition,
    y: random(0, 90) as PercentPosition,
  };
  const start = {
    x: random(20, 30) as PercentPosition,
    y: target.y,
  };
  return {
    start,
    target,
  };
};
