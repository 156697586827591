import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../../../styling';
import {useStrings} from '../../../../Root/hooks';
import WarningSvg from '../../../../assets/svg/colorless/warning.svg';
import {sized} from '../../../../Svg';
import {Paragraph} from '../../../index';
import {FETCH_OFFERS_NO_DATA_ERROR, FetchOffersError} from '../../../../Error';

export type ServerEmptyOrErrorCardProps = {
  error: FetchOffersError;
};

export default observer(function ServerEmptyOrErrorCard({
  error,
}: ServerEmptyOrErrorCardProps) {
  const strings = useStrings();
  const description = strings['subscription.serverEmptyOrError.description'];
  const theme = useTheme();
  const additionalText =
    error.type === FETCH_OFFERS_NO_DATA_ERROR ? 'No data.' : 'Fetch Error';
  return (
    <RootView>
      <WarningView>
        <WarningIcon color={theme.colors.primaryError} />
      </WarningView>
      <DescriptionText type="paragraph" size="large">
        {description} ({additionalText})
      </DescriptionText>
    </RootView>
  );
});

const WarningIcon = sized(WarningSvg, 60);

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryWhite,
    borderRadius: 6,
    padding: 16,
    marginBottom: 20,
  },
}));

const DescriptionText = variance(Paragraph)((theme) => ({
  root: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 17,
    color: theme.colors.primaryUIDirtyBlue,
  },
}));

const WarningView = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
  },
}));
