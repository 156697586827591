import {AdvertHelper} from './AdvertHelper';
import {Advert} from './Advert';
import {bind} from '../fp';
import {AdId} from '../ApiStore';
import {Locale} from '../Preferences';
import {Translation} from '../Localization';
import {ADVERT_SPLASH_DESKTOP_BREAKPOINT} from '../AdvertSplashScreen/AdvertSplashScreen';
import {WindowDimensionsState} from '../WindowDimensions';
import {computed, makeObservable} from 'mobx';
import {AdSpot} from '../ApiStore/entities/GetAds';

export default class AdvertHelperImpl implements AdvertHelper {
  constructor(
    private readonly _root: {
      readonly advert: Advert;
      readonly translation: Translation;
      readonly windowDimensionsState: WindowDimensionsState;
    },
  ) {
    makeObservable(this);
  }

  close = bind((id: AdId, spot: AdSpot, exclude: boolean) => {
    return this._root.advert.close(id, spot, exclude);
  }, this);

  get spotByBannerList() {
    return this._root.advert.spotByBannerList;
  }

  translate = bind(
    (dictionary: Partial<Record<Locale, string>>): string | undefined => {
      const localeTag = this._root.translation.localeTag;
      return dictionary[localeTag] || dictionary.en;
    },
  );

  get state() {
    return this._root.advert.state;
  }

  @computed
  get isDimensionsForShowMobileSplash() {
    return (
      this._root.windowDimensionsState.window.width <=
      ADVERT_SPLASH_DESKTOP_BREAKPOINT
    );
  }

  @computed
  get isDimensionsForShowDesktopSplash() {
    return !this.isDimensionsForShowMobileSplash;
  }
}
