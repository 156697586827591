import {Service} from '../structure';
import {Metamask} from './Metamask';
import MetamaskConnectorService from './MetamaskConnectorService';
import MetamaskTokenSenderImpl from './MetamaskTokenSenderImpl';
import ErrorNormalizerImpl from './ErrorNormalizerImpl';
import MetamaskPaymentImpl from './MetamaskPaymentImpl';

export default class MetamaskService implements Metamask, Service {
  private readonly _errorNormalizer = new ErrorNormalizerImpl();

  readonly connector = new MetamaskConnectorService(this._errorNormalizer);

  private readonly _tokenSender = new MetamaskTokenSenderImpl(
    this._errorNormalizer,
    this.connector,
  );

  readonly payment = new MetamaskPaymentImpl(this._tokenSender, this.connector);

  subscribe() {
    return this.connector.subscribe();
  }
}
