import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import {observer} from 'mobx-react-lite';
import GBottomSheet, {
  ANIMATION_CONFIGS,
  BottomSheetBackdropProps as GBottomSheetBackdropProps,
  BottomSheetHandleProps as GBottomSheetHandleProps,
  BottomSheetProps as GBottomSheetProps,
} from '@gorhom/bottom-sheet';
import {useStyles} from '../../../styling';
import {Pressable, TouchableWithoutFeedbackProps, View} from 'react-native';
import Animated, {
  Extrapolate,
  ReduceMotion,
  interpolate,
  useAnimatedStyle,
} from 'react-native-reanimated';
import {ModalRef} from './types';

const NEW_ANIMATION_CONFIGS = {
  ...ANIMATION_CONFIGS,
  reduceMotion: ReduceMotion.Never,
};

export type BottomSheetBackdropProps = GBottomSheetBackdropProps;
export type BottomSheetHandleProps = GBottomSheetHandleProps;
export type GorhomBottomSheetProps = GBottomSheetProps & {
  onBackdropPress: () => void;
};

export default observer(
  forwardRef(function GorhomBottomSheet(
    props: GorhomBottomSheetProps,
    ref: ForwardedRef<ModalRef>,
  ) {
    const {children, onBackdropPress, ...rest} = props;
    const styles = useStyles((theme) => ({
      background: {
        backgroundColor: theme.colors.uiMain,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
      },
    }));
    const modalRef = useRef<GBottomSheet>(null);
    useImperativeHandle(ref, () => ({
      open: () => modalRef.current?.collapse(),
      close: () => modalRef.current?.close(),
    }));
    const handleBackdropPress = useCallback(() => {
      onBackdropPress();
    }, [onBackdropPress]);
    const renderBackdropComponent = useCallback(
      (backdropProps: BottomSheetBackdropProps) => (
        <Backdrop {...backdropProps} onPress={handleBackdropPress} />
      ),
      [handleBackdropPress],
    );
    return (
      <GBottomSheet
        ref={modalRef}
        backgroundStyle={styles.background}
        backdropComponent={renderBackdropComponent}
        handleComponent={renderHandle}
        animationConfigs={NEW_ANIMATION_CONFIGS}
        {...rest}>
        {children}
      </GBottomSheet>
    );
  }),
);

type BackdropProps = TouchableWithoutFeedbackProps & BottomSheetBackdropProps;

const Backdrop = observer(({style, animatedIndex, ...rest}: BackdropProps) => {
  const styles = useStyles((theme) => ({
    root: {
      backgroundColor: theme.colors.backgroundBlackout,
    },
    pressable: {
      flex: 1,
    },
  }));
  const containerAnimatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      animatedIndex.value,
      [-1, 0],
      [0, 1],
      Extrapolate.CLAMP,
    ),
  }));
  return (
    <Animated.View style={[style, styles.root, containerAnimatedStyle]}>
      <Pressable {...rest} style={styles.pressable} />
    </Animated.View>
  );
});

const renderHandle: GorhomBottomSheetProps['handleComponent'] = (props) => (
  <View style={{position: 'absolute', top: 0, right: 0, left: 0}}>
    <Handle {...props} />
  </View>
);

const Handle = observer((props: BottomSheetHandleProps) => {
  const styles = useStyles((theme) => ({
    root: {
      height: 22,
      justifyContent: 'center',
      alignItems: 'center',
    },
    handle: {
      borderRadius: 6,
      width: 32,
      height: 5,
      backgroundColor: theme.colors.uiAdditional1,
    },
  }));
  return (
    <View {...props} style={[styles.root]}>
      <View style={styles.handle} />
    </View>
  );
});
