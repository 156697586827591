import React from 'react';
import {observer} from 'mobx-react-lite';
import Svg, {Defs, G, Image as SvgImage, Mask, Path} from 'react-native-svg';
import {Platform, StyleSheet, View} from 'react-native';
import Animated, {SharedValue, useAnimatedStyle} from 'react-native-reanimated';
import {IMAGES} from './constants';

export type MovingPuzzleProps = {
  imageIndex: number;
  canvasWidth: number;
  canvasHeight: number;
  currentX: SharedValue<number>;
  currentY: number;
  targetX: number;
  targetY: number;
};

export default observer(
  ({
    canvasWidth,
    canvasHeight,
    imageIndex,
    targetX,
    targetY,
    currentX,
    currentY,
  }: MovingPuzzleProps) => {
    const imageX = -targetX;
    const imageY = -targetY;
    const backgroundImageProps = {
      id: 'Background',
      x: imageX,
      y: imageY,
      href: IMAGES[imageIndex],
      width: canvasWidth,
      height: canvasHeight,
      preserveAspectRatio: 'xMidYMid slice',
    };
    const animatedStyles = useAnimatedStyle(() => ({
      transform: [{translateX: currentX.value}],
    }));
    return (
      <View style={styles.root}>
        <Animated.View
          style={[{position: 'absolute', top: currentY}, animatedStyles]}>
          <Svg width="58px" height="58px">
            <Defs>
              <Mask id="Mask">
                <Svg width="58" height="58" viewBox="0 0 58 58" fill="none">
                  <Path
                    d="M22.9302 0C27.3999 0 31.0233 3.62338 31.0233 8.09302C31.0233 8.90585 30.9006 9.68873 30.675 10.4271C30.4322 11.2223 30.9685 12.1395 31.8 12.1395H43.7026C44.8943 12.1395 45.8605 13.1057 45.8605 14.2974V26.2C45.8605 27.0314 46.7777 27.5678 47.5729 27.325C48.3113 27.0994 49.0942 26.9767 49.907 26.9767C54.3766 26.9767 58 30.6001 58 35.0698C58 39.5394 54.3766 43.1628 49.907 43.1628C49.0941 43.1628 48.3113 43.0401 47.5729 42.8146C46.7777 42.5717 45.8605 43.1081 45.8605 43.9395V55.8421C45.8605 57.0339 44.8943 58 43.7026 58H31.3726C30.4397 58 29.8892 56.8372 30.2808 55.9904C30.7571 54.9606 31.0233 53.814 31.0233 52.6046C31.0233 48.135 27.3999 44.5116 22.9302 44.5116C18.4606 44.5116 14.8372 48.135 14.8372 52.6046C14.8372 53.814 15.1034 54.9606 15.5797 55.9904C15.9713 56.8372 15.4207 58 14.4878 58H2.15789C0.966122 58 0 57.0339 0 55.8421V43.5122C0 42.5793 1.16284 42.0287 2.00956 42.4203C3.03938 42.8966 4.18603 43.1628 5.39535 43.1628C9.86499 43.1628 13.4884 39.5394 13.4884 35.0698C13.4884 30.6001 9.86499 26.9767 5.39535 26.9767C4.18603 26.9767 3.03938 27.2429 2.00955 27.7192C1.16284 28.1108 0 27.5603 0 26.6274V14.2974C0 13.1057 0.966122 12.1395 2.15789 12.1395H14.0605C14.8919 12.1395 15.4283 11.2223 15.1854 10.4271C14.9599 9.68873 14.8372 8.90585 14.8372 8.09302C14.8372 3.62338 18.4606 0 22.9302 0Z"
                    fill="#fff"
                  />
                </Svg>
              </Mask>
            </Defs>
            <G>
              {Platform.OS === 'web' ? (
                <image {...backgroundImageProps} mask="url(#Mask)" />
              ) : (
                <SvgImage {...backgroundImageProps} mask="url(#Mask)" />
              )}
              <Path
                d="M22.9302 1C26.8476 1 30.0233 4.17567 30.0233 8.09302C30.0233 8.80454 29.9159 9.48905 29.7186 10.135C29.5068 10.8285 29.6361 11.5567 29.9892 12.1156C30.3478 12.6832 30.9848 13.1395 31.8 13.1395H43.7026C44.3421 13.1395 44.8605 13.6579 44.8605 14.2974V26.2C44.8605 27.0152 45.3168 27.6522 45.8844 28.0108C46.4433 28.3639 47.1715 28.4932 47.865 28.2813C48.511 28.084 49.1955 27.9767 49.907 27.9767C53.8243 27.9767 57 31.1524 57 35.0698C57 38.9871 53.8243 42.1628 49.907 42.1628C49.1955 42.1628 48.511 42.0555 47.865 41.8582C47.1715 41.6463 46.4433 41.7757 45.8844 42.1287C45.3168 42.4873 44.8605 43.1243 44.8605 43.9395V55.8421C44.8605 56.4816 44.3421 57 43.7026 57H31.3726H31.3723C31.3648 57.0001 31.2945 57.0006 31.2181 56.853C31.1363 56.695 31.1384 56.5184 31.1884 56.4102C31.7242 55.2518 32.0233 53.9622 32.0233 52.6046C32.0233 47.5827 27.9522 43.5116 22.9302 43.5116C17.9083 43.5116 13.8372 47.5827 13.8372 52.6046C13.8372 53.9622 14.1363 55.2518 14.6721 56.4102C14.7221 56.5184 14.7242 56.695 14.6424 56.853C14.5648 57.0029 14.4934 57 14.4878 57H2.15789C1.51841 57 1 56.4816 1 55.8421V43.5122L0.999998 43.5118C0.999945 43.5043 0.999448 43.434 1.14702 43.3576C1.30499 43.2758 1.48158 43.2779 1.58979 43.3279C2.74822 43.8637 4.03784 44.1628 5.39535 44.1628C10.4173 44.1628 14.4884 40.0917 14.4884 35.0698C14.4884 30.0478 10.4173 25.9767 5.39535 25.9767C4.03784 25.9767 2.74821 26.2758 1.58979 26.8116C1.48158 26.8616 1.30499 26.8637 1.14702 26.7819C0.999448 26.7055 0.999945 26.6352 0.999998 26.6277L1 26.6274V14.2974C1 13.6579 1.51841 13.1395 2.15789 13.1395H14.0605C14.8757 13.1395 15.5127 12.6832 15.8712 12.1156C16.2243 11.5567 16.3537 10.8285 16.1418 10.135C15.9445 9.48905 15.8372 8.80454 15.8372 8.09302C15.8372 4.17567 19.0129 1 22.9302 1Z"
                fill="transparent"
                stroke="#fff"
                strokeWidth="2"
              />
            </G>
          </Svg>
        </Animated.View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
});
