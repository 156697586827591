import React, {useCallback, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {WithdrawalHistoryModal} from '../../WithdrawalHistoryModal';
import {useSafelyGoBack, useSafelyGoToAffiliate} from '../hooks';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from './index';
import {nanoid} from 'nanoid';
import {RootStackBindingProps} from './RootStackBindingProps';
import useHistoryRequest from '../../WithdrawalHistoryModal/useHistoryRequest';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';

export type WithdrawalHistoryBindingProps =
  RootStackBindingProps<'WithdrawalHistory'>;

export default observer((props: WithdrawalHistoryBindingProps) => {
  const {navigation, route} = props;
  const safelyGoBack = useSafelyGoBack();
  const request = useHistoryRequest();
  const goToAuthAndThenOpenAffiliate = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createWithdrawalHistoryState(nanoid()),
      }),
    [navigation],
  );
  const {safelyGoToAffiliate} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
    AffiliateVariant.AppAbout,
  );
  const {goToAffiliateToken} = route.params ?? {};
  useOpenAffiliateOnUniqueToken(goToAffiliateToken, safelyGoToAffiliate);
  return (
    <WithdrawalHistoryModal
      goBack={safelyGoBack}
      goToAffiliate={safelyGoToAffiliate}
      onModalClosed={safelyGoBack}
      request={request}
    />
  );
});

export const createWithdrawalHistoryState = (
  goToAffiliateToken?: string,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 1,
    routes: [
      {name: 'Root'},
      {name: 'WithdrawalHistory', params: {goToAffiliateToken}},
    ],
  };
};

export const useOpenAffiliateOnUniqueToken = (
  goToAffiliateToken: string | undefined,
  safelyGoToAffiliate: () => void,
) => {
  const safelyGoToAffiliateRef = useRef(safelyGoToAffiliate);
  safelyGoToAffiliateRef.current = safelyGoToAffiliate;
  useEffect(() => {
    if (goToAffiliateToken !== undefined) {
      safelyGoToAffiliateRef.current();
    }
  }, [goToAffiliateToken]);
};
