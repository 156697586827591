import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import RenewAccessScreen from '../../RenewAccessScreen/RenewAccessScreen';
import {useRoot} from '../../Root/hooks';
import {useGoToPoolMinerActivatedCallback, useSafelyGoBack} from '../hooks';
import {ScreenFluidLoader} from '../../components/atoms/CryptoLoader';
import {useRenewPoolMiner} from '../../PurchaseRequester';
import {ISODateString} from '../../Time';
import {Offer} from '../../InAppOffersService';
import {OrderId} from '../../ApiStore/units/order';
import RenewAccessScreenStateFactory from '../../RenewAccessScreen/RenewAccessScreenStateFactory';
import {useErrorHandler} from '../../ExceptionHandler';

export type RenewAccessBindingProps = RootStackBindingProps<'RenewAccess'>;

export default observer(function RenewAccessBinding(
  props: RenewAccessBindingProps,
) {
  const {route, navigation} = props;
  const root = useRoot();
  const {
    dashboardStore,
    inAppOffers: {error: purchaseStoreError},
    inAppLoaderState,
  } = root;
  const workerId = route.params?.workerId;
  const [renewState] = useState(() =>
    new RenewAccessScreenStateFactory(root, workerId).create(),
  );
  const worker = renewState.worker;
  const isLoading = renewState.isLoading ?? false;
  const safelyGoBack = useSafelyGoBack();
  const goToPurchase = useCallback(
    () => navigation.replace('Plan'),
    [navigation],
  );
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) =>
    navigation.navigate('PoolMinerActivated', params),
  );
  const goToCryptoPayment = useCallback(
    (id: OrderId) => navigation.navigate('Payment', {id}),
    [navigation],
  );
  const onSuccessPurchase = useCallback(
    (poolExpiredDate: ISODateString, poolHashrate: number) => {
      safelyGoBack();
      goToPoolMinerActivated(poolExpiredDate, poolHashrate);
    },
    [goToPoolMinerActivated, safelyGoBack],
  );
  useLayoutEffect(() => renewState.subscribe(), [renewState]);
  useEffect(() => {
    if (!worker && !dashboardStore.isLoading) {
      safelyGoBack();
    }
  }, [dashboardStore.isLoading, safelyGoBack, worker]);

  const errorHandler = useErrorHandler();
  const renew = useRenewPoolMiner({
    errorHandler,
  });
  const onPurchasePress = useCallback(
    async (offer: Offer) => {
      if (
        worker === undefined ||
        !worker.pool_miner_id ||
        worker.pool_miner_config === null
      ) {
        return;
      }
      inAppLoaderState.addLoading();
      await renew({
        poolMinerId: worker.pool_miner_id,
        goToPoolMinerActivated: onSuccessPurchase,
        offer,
        paymentMethod: renewState.selectedPaymentMethod,
        goToCryptoPayment,
      });
      inAppLoaderState.deleteLoading();
    },
    [
      renewState,
      worker,
      inAppLoaderState,
      renew,
      onSuccessPurchase,
      goToCryptoPayment,
    ],
  );

  if (isLoading) {
    return <ScreenFluidLoader />;
  }
  if (worker === undefined) {
    return null;
  }
  return (
    <RenewAccessScreen
      worker={worker}
      goToPurchase={goToPurchase}
      onPurchasePress={onPurchasePress}
      purchaseStoreError={purchaseStoreError}
      state={renewState}
    />
  );
});
