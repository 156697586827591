import {
  cacheDirectory,
  EncodingType,
  writeAsStringAsync,
} from 'expo-file-system';
import {shareAsync} from 'expo-sharing';

import type {FileSystem} from './FileSystem';

export default class IosFileSystemImpl implements FileSystem {
  async isGranted() {
    return true;
  }

  async save(
    content: string,
    filename: string,
    mimeType: string,
  ): Promise<void> {
    if (!cacheDirectory) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error('No cache directory available');
    }
    const uri = cacheDirectory + `/${filename}`;
    await writeAsStringAsync(uri, content, {encoding: EncodingType.UTF8});
    await shareAsync(uri, {mimeType});
  }
}
