import React, {useCallback, useMemo, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import CopySvg from '../../../../assets/svg/colorless/copy.svg';
import ShareSvg from '../../../../assets/svg/colorless/share.svg';
import FilledQuestionMarkSvg from '../../../../assets/svg/colorless/filledQuestionMark.svg';
import StatisticsGraphSvg from '../../../../assets/svg/colorless/statisticsPartial.svg';
import {expr} from 'mobx-utils';
import {useTheme, variance} from '../../../../styling';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {getCommissionerFontStyles, PressableOpacity} from '../../../index';
import LoaderIndicator from '../../../LoaderIndicator';
import {sized} from '../../../../Svg';
import Typography from '../../../Typography';
import {Divider} from '../../../atoms';
import Input from '../../../Input';
import {debounce} from 'lodash';

export type CopyPromoSectionProps = {
  code: string;
  safelyGoToPromoAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
};

type Target = 'title' | 'button';

export default observer(function CopyPromoSection({
  code,
  safelyGoToPromoAffiliate,
  getAffiliateIsPending,
}: CopyPromoSectionProps) {
  const theme = useTheme();
  const {
    share,
    flashMessages,
    clipboard,
    configuration: {values},
  } = useRoot();
  const link = values.sharedPromoLink.replace('{code}', code);
  const strings = useStrings();
  const shareCode = useMemo(
    () => debounce(() => share.shareMessage(link), 600),
    [link, share],
  );
  const copyCode = useCallback(async () => {
    clipboard.setString(link);
    flashMessages.showMessage({
      title: strings['action.discountCodeCopied'],
    });
  }, [clipboard, link, flashMessages, strings]);

  const targetRef = useRef<Target>();

  const onGoToAffiliate = useCallback(
    (target: Target) => {
      targetRef.current = target;
      safelyGoToPromoAffiliate();
    },
    [safelyGoToPromoAffiliate],
  );

  const isPending = expr(getAffiliateIsPending);
  const isTitlePending = isPending && targetRef.current === 'title';
  const isButtonPending = isPending && targetRef.current === 'button';
  return (
    <Root>
      <Header>
        <TitleText
          numberOfLines={1}
          type="header"
          weight="500"
          disabled={isTitlePending}>
          {strings['dashboard.promo.title']}
        </TitleText>
        <PressableOpacity
          disabled={isTitlePending}
          onPress={() => onGoToAffiliate('title')}
          style={styles.questionMark}>
          <QuestionMarkIcon
            color={theme
              .chroma(theme.colors.uiAdditional3)
              .alpha(isTitlePending ? 0.7 : 1)
              .hex()}
          />
        </PressableOpacity>
        {isTitlePending && <LoaderIndicator />}
        <StatisticsButtonView>
          {isButtonPending && <LoaderIndicator />}
          <PressableOpacity
            disabled={isButtonPending}
            onPress={() => onGoToAffiliate('button')}>
            <StatisticsButtonContent disabled={isButtonPending}>
              <StatisticsGraphIcon
                color={theme.colors.primaryAttractive}
                style={styles.statistics}
              />
              <StatisticsButtonText type="body" weight="500">
                {strings['dashboard.panel.getStatistics']}
              </StatisticsButtonText>
            </StatisticsButtonContent>
          </PressableOpacity>
        </StatisticsButtonView>
      </Header>
      <Divider />
      <Body>
        <Input
          containerViewStyle={styles.inputContainer}
          style={styles.input}
          editable={false}
          onFocus={(e) => e.preventDefault()}
          value={link}
          placeholder={strings['copyPromo.inputLabel']}
        />
        <ActionListView>
          {share.isSupported ? (
            <>
              <ActionButton onPress={shareCode}>
                <ShareIcon color={theme.colors.uiSecondary} />
              </ActionButton>
              <Divider vertical />
            </>
          ) : null}
          <ActionButton onPress={copyCode}>
            <CopyIcon color={theme.colors.uiSecondary} />
          </ActionButton>
        </ActionListView>
      </Body>
    </Root>
  );
});

const styles = StyleSheet.create({
  questionMark: {
    padding: 5,
  },
  statistics: {
    marginRight: 5,
  },
  desktopStatisticsButton: {
    marginLeft: 10,
  },
  inputContainer: {
    width: '100%',
  },
  input: {
    ...getCommissionerFontStyles('600'),
    height: 30,
  },
});

const QuestionMarkIcon = sized(FilledQuestionMarkSvg, 15);
const StatisticsGraphIcon = sized(StatisticsGraphSvg, 16);
const CopyIcon = sized(CopySvg, 16, 16);
const ShareIcon = sized(ShareSvg, 17, 18);

const Root = variance(View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.colors.uiMain,
    padding: 10,
    paddingVertical: 15,
    borderRadius: 12,
    paddingRight: 16,
    paddingLeft: 12,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
}));

const Header = variance(View)(() => ({
  root: {
    marginBottom: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Body = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 18,
  },
}));

const ActionListView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 1,
    bottom: 1,
    top: 1,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: theme.colors.uiMain,
  },
}));

const ActionButton = variance(PressableOpacity)(() => ({
  root: {
    width: 50,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const TitleText = variance(Typography)(() => ({
  root: {
    fontSize: 15,
    lineHeight: 20,
  },
  disabled: {
    opacity: 0.5,
  },
}));

const StatisticsButtonView = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
    flexDirection: 'row',
  },
}));

const StatisticsButtonContent = variance(View)(() => ({
  root: {
    paddingVertical: 3,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
}));

const StatisticsButtonText = variance(Typography)((theme) => ({
  root: {
    fontSize: 13,
    lineHeight: 15,
    textTransform: 'uppercase',
    color: theme.colors.primaryAttractive,
  },
}));
