import {
  IapPurchaseRequester,
  IapResponse,
  RequestParams,
  RequestPurchaseParams,
} from '../PurchaseRequester';
import {Either, error, success} from '../../fp';
import {when} from 'mobx';
import {ApiStore} from '../../ApiStore';
import {InAppPurchaseManager, PurchaseResult} from '../../InAppPurchaseManager';
import {InAppOffers, PurchaseKind} from '../../InAppOffersService';
import {ErrorRepository} from '../../ErrorRepository';
import {
  GlobalError,
  NETWORK_ERROR,
  UNKNOWN_ERROR,
  UnknownError,
} from '../../Error';
import {PendingPurchasesResolver} from '../../PendingPurchasesResolver';
import {PurchaseDiscount} from '../../PurchasePromoService';
import {GiftState} from '../../Gift/Gift';

export default abstract class BaseIapRequesterImpl
  implements IapPurchaseRequester
{
  protected constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly gift: GiftState;
    },
  ) {}

  protected get _code() {
    return this._root.purchaseDiscount.discount?.code;
  }

  private async _requestPurchase(
    params: RequestParams,
  ): Promise<PurchaseResult> {
    await when(() => this._root.inAppOffers.isLoadedIn);
    const offer = this._root.inAppOffers.offerById.get(params.offerId);
    if (!offer) {
      return error(
        this._root.errorRepository.create<UnknownError>({
          kind: UNKNOWN_ERROR,
        }),
      );
    }
    const gift = await this._root.gift.queryGift(offer.purchaseId);
    const body = {
      purchaseId: offer.purchaseId,
      gift,
      subscriptionOfferTokenAndroid: offer.subscriptionOfferTokenAndroid,
      purchaseTokenAndroid: params.purchaseTokenAndroid,
      poolMinerIdForRenew: params.poolMinerIdForRenew,
    };
    if (offer.kind === PurchaseKind.Subscription) {
      return this.requestSubscription(body);
    } else {
      return this.requestProduct(body);
    }
  }

  protected abstract requestSubscription(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult>;
  protected abstract requestProduct(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult>;

  async request(
    params: RequestParams,
  ): Promise<Either<IapResponse, GlobalError>> {
    await this._root.pendingPurchasesResolver.resolve();
    const requestPurchase_ = await this._requestPurchase(params);
    if (!requestPurchase_.success) {
      return error(
        this._root.errorRepository.create({
          kind: NETWORK_ERROR,
          raw: requestPurchase_.left,
        }),
      );
    }
    await this._root.pendingPurchasesResolver.resolve();
    return success(undefined);
  }
}
