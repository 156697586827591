import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
  Representation,
} from '../components/templates';
import React, {forwardRef} from 'react';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import useRepresentation from '../components/templates/AdaptiveModal/useRepresentation';
import {AdaptiveModalHeader} from '../components/organisms/modal/AdaptiveModalHeader';
import {View, StyleSheet} from 'react-native';
import {useTheme, variance} from '../styling';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import {sized} from '../Svg';
import ComputerSvg from '../assets/svg/twoTone/computer.svg';
import CopySvg from '../assets/svg/colorless/copy.svg';
import {PressableOpacity, Typography} from '../components';
import {useStrings} from '../Root/hooks';

export const ADAPTIVE_ACTION_MODAL_BREAKPOINT = 'sm';

export type AdaptiveActionModalProps = {
  onModalClosed: () => void;
  onGoToSitePress: () => void;
  onCopyPress: () => void;
  workerId?: string;
};

export default observer<AdaptiveActionModalProps, AdaptiveModalRef>(
  forwardRef(function AdaptiveActionModal(props, ref) {
    return (
      <AdaptiveModal
        ref={ref}
        adaptiveBreakpoint={ADAPTIVE_ACTION_MODAL_BREAKPOINT}
        enableContentAutoHeight
        modalWidth={ModalWidth.Small}
        onModalClosed={props.onModalClosed}>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  ({onGoToSitePress, onCopyPress, workerId}: AdaptiveActionModalProps) => {
    const paddingBottom = useSafePaddingBottom(16, 20);
    const representation = useRepresentation();
    const isModal = representation === Representation.Modal;
    const theme = useTheme();
    const strings = useStrings();
    return (
      <RootView style={{paddingBottom}} modal={isModal}>
        <View style={styles.headerView}>
          <AdaptiveModalHeader
            IconSvgModalRep={ComputerLargeIcon}
            IconSvg={ComputerIcon}
            title={strings['modal.copyInstall.title']}
            description={strings['modal.copyInstall.description']}
          />
        </View>
        <View style={styles.content}>
          <PressableOpacity onPress={onCopyPress}>
            <FakeInputView>
              <Typography style={styles.inputValue} type="body" weight="500">
                {workerId}
              </Typography>
              <CopyIcon style={styles.icon} color={theme.colors.uiSecondary} />
            </FakeInputView>
          </PressableOpacity>
        </View>
        <AppButton onPress={onGoToSitePress} variant={ButtonVariant.Primary}>
          {strings['action.goToSite']}
        </AppButton>
      </RootView>
    );
  },
);

const CopyIcon = sized(CopySvg, 14);

const ComputerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(ComputerSvg, 24, 20)} {...props} />
));

const ComputerLargeIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(ComputerSvg, 30, 25)} {...props} />
));

const styles = StyleSheet.create({
  content: {
    marginTop: 12,
    marginBottom: 64,
  },
  headerView: {
    marginBottom: 16,
  },
  inputValue: {
    fontSize: 23,
    lineHeight: 28,
    marginRight: 7,
  },
  icon: {
    marginTop: 2,
  },
});

const RootView = variance(View)(() => ({
  root: {
    padding: 16,
    paddingTop: 39,
  },
  modal: {
    padding: 20,
    paddingTop: 48,
  },
}));

const FakeInputView = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    borderRadius: 8,
    flexDirection: 'row',
    minWidth: 190,
    minHeight: 50,
    alignSelf: 'center',
  },
}));
