import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import ProductsScreen from '../../ProductsScreen/ProductsScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {useNavigationGetIsFocused} from '../hooks';
import {autorun} from 'mobx';

export type ProductsBindingProps = RootStackBindingProps<'Products'>;

export const ProductsBinding = observer(() => {
  const {productsService} = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused()) {
          productsService.fetchProducts();
        }
      }),
    [productsService, getIsFocused],
  );
  return <ProductsScreen products={productsService.products} />;
});
