import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import TutorialView from './TutorialView';
import {useRoot} from '../Root/hooks';
import useTutorialUrl from './useTutorialUrl';
import {ApiMode} from '../farmApi';
import {View, StyleSheet} from 'react-native';
import {TutorialProcessParams} from './useProcessWebMessageData';
import {AdInfoItem} from '../Advert';
import {ScreenFluidLoader} from '../components/atoms/CryptoLoader';
import Portal from '../Portal';
import {SafeAreaView} from 'react-native-safe-area-context';

export type TutorialModalProps = TutorialProcessParams & {
  visibleContent: boolean;
  banner: AdInfoItem | undefined;
};

export default observer(function TutorialModal({
  visibleContent,
  banner,
  ...rest
}: TutorialModalProps) {
  const {apiStore} = useRoot();
  const tutorialUrl = useTutorialUrl(
    (_) => (apiStore.mode === ApiMode.Real ? _.tutorialReal : _.tutorialDemo),
    banner,
  );
  return (
    <Portal>
      <RootView>
        <SafeAreaView style={styles.content}>
          <ContentView>
            {visibleContent ? (
              <TutorialView uri={tutorialUrl} {...rest} />
            ) : (
              <ScreenFluidLoader />
            )}
          </ContentView>
        </SafeAreaView>
      </RootView>
    </Portal>
  );
});

const styles = StyleSheet.create({
  content: {
    ...StyleSheet.absoluteFillObject,
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.uiMain,
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.colors.uiMain,
  },
}));
