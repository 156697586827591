import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import QuickStartScreenView, {
  QuickStartScreenViewProps,
} from '../QuickStartScreen/QuickStartScreenView';
import {AdaptiveModalHeaderProps} from '../components/organisms/modal/AdaptiveModalHeader';
import {AdaptiveModal, AdaptiveModalRef } from '../components/templates';
import {variance} from '../styling';
import {Pressable, View, StyleSheet} from 'react-native';
import {Paragraph} from '../components';
import {useStrings} from '../Root/hooks';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {ScrollView} from 'react-native-gesture-handler';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import {useScreenDimensions} from '../WindowDimensions';

export type QuickStartModalScreenProps = AdaptiveModalHeaderProps &
  ContentProps & {
    onModalClosed: () => void;
  };

export default observer<QuickStartModalScreenProps, AdaptiveModalRef>(
  forwardRef(function QuickStartModalScreen(props, ref) {
    const {onModalClosed, ...rest} = props;
    return (
      <AdaptiveModal
        onModalClosed={onModalClosed}
        enableContentAutoHeight
        modalContentStyle={styles.content}
        ref={ref}>
        <Content {...rest} />
      </AdaptiveModal>
    );
  }),
);

type ContentProps = QuickStartScreenViewProps & {
  isChecked: boolean;
  setIsCheck: (v: boolean) => void;
};

const Content = observer(({isChecked, setIsCheck, ...rest}: ContentProps) => {
  const strings = useStrings();
  const screen = useScreenDimensions();
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <ScrollView style={{height: screen.height}} contentContainerStyle={{paddingBottom}}>
      <QuickStartScreenView
        {...rest}
        showSkip
        containerStyle={styles.container}
        Check={
          <CheckRow onPress={() => setIsCheck(!isChecked)}>
            <Check isChecked={isChecked} />
            <CheckText type="paragraph" weight="500" size="regular">
              {strings['quickStart.hide']}
            </CheckText>
          </CheckRow>
        }
      />
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  content: {
    width: 900,
  },
  scroll: {
    height: 770,
  },
  container: {
    paddingBottom: 100,
  },
});

const CheckRow = variance(Pressable)((theme) => ({
  root: {
    flexDirection: 'row',
    marginTop: 25,
    paddingBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingBottom: 0,
      },
    }),
  },
}));

const CheckText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
  },
}));

const Check = variance(View)((theme) => ({
  root: {
    width: 14,
    height: 14,
    borderWidth: 1,
    borderColor: theme.colors.primaryUIDirtyBlue,
    marginRight: 12,
  },
  isChecked: {
    backgroundColor: theme.colors.primaryUIDirtyBlue,
  },
}));
