import {SubscriptionCandidateKind} from '../ProposalsState';
import {ActivatorSubscriptionOffer, PaymentMethod} from '../InAppOffersService';
import {SubscriptionOffer} from './TotalPriceView';

export function processActivatorSubscriptionOffer(
  offer: ActivatorSubscriptionOffer | undefined,
  method: PaymentMethod,
): SubscriptionOffer | undefined {
  if (!offer) {
    return undefined;
  }
  const payment = offer.payments.get(method);
  if (!payment) {
    return undefined;
  }
  const {
    currency,
    uiPrice,
    discounts,
    discountsDifferencePrice,
    uiDiscountsDifferencePrice,
  } = payment;
  return {
    discounts,
    discountsDifferencePrice,
    helperPrice: undefined,
    questionMarkVisible: false,
    uiDiscountsDifferencePrice,
    kind: SubscriptionCandidateKind.Available,
    currency: currency,
    interval: offer.interval,
    trial: offer.trial,
    uiPrice: uiPrice,
  };
}
