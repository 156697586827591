import {observer} from 'mobx-react-lite';
import Svg, {Defs, LinearGradient, Path, Stop, Ellipse} from 'react-native-svg';
import React, {useRef} from 'react';
import {nanoid} from 'nanoid';
import {IconProps} from './IconProps';
import useColors from './useColors';

export default observer(function WithdrawIcon({active, ...rest}: IconProps) {
  const id = useRef(nanoid());
  const {accent, second} = useColors(`url(#${id.current})`, active);
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <Path
        d="M0.5 10.6526C0.5 7.81958 2.78832 5.52295 5.61111 5.52295H18.3889C21.2117 5.52295 23.5 7.81958 23.5 10.6526V18.3471C23.5 21.1802 21.2117 23.4768 18.3889 23.4768H5.61111C2.78832 23.4768 0.5 21.1802 0.5 18.3471V10.6526Z"
        fill={accent}
      />
      <Path
        d="M15.7702 2.81199C14.4455 2.04438 13.3775 1.4255 12.4705 1.03474C11.5318 0.630294 10.6522 0.417654 9.7181 0.574991C9.41695 0.625716 9.12132 0.705217 8.83525 0.812409C7.94793 1.14489 7.29207 1.77046 6.68063 2.59177C6.54372 2.77567 6.40542 2.97452 6.26422 3.18806C5.93853 3.68058 5.77569 3.92684 5.86232 4.08542C5.94896 4.24399 6.27785 4.23956 6.93565 4.2307C6.96331 4.23033 6.99101 4.23014 7.01875 4.23014H7.87522H14.3838H17.4735C17.7727 4.23014 17.9222 4.23014 17.9421 4.1561C17.962 4.08206 17.8326 4.00707 17.5738 3.8571L15.7702 2.812L15.7702 2.81199Z"
        fill={second}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4984 10.5229C23.4995 10.566 23.5 10.6093 23.5 10.6526V18.3471C23.5 18.5198 23.4915 18.6904 23.4749 18.8587H17.6528C15.3593 18.8587 13.5 16.9927 13.5 14.6908C13.5 12.389 15.3593 10.5229 17.6528 10.5229H23.4984Z"
        fill={second}
      />
      <Ellipse
        cx="17.7778"
        cy="14.8054"
        rx="1.27778"
        ry="1.28242"
        fill={accent}
      />
      <Defs>
        <LinearGradient
          id={id.current}
          x1="-23.155"
          y1="12.4155"
          x2="1.1119"
          y2="41.7537"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F7C61A" />
          <Stop offset="1" stopColor="#F7931A" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
});
