import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {CreateNewFarmWarningModal} from '../../CreateNewFarmWarningModal';
import {AdaptiveModalRef} from '../../components/templates';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {useSafelyGoBack} from '../hooks';

export type CreateNewFarmWarningBindingProps =
  RootStackBindingProps<'CreateNewFarmWarning'>;

export default observer(function CreateNewFarmWarningBinding({
  route,
  navigation,
}: CreateNewFarmWarningBindingProps) {
  const {onSuccess} = route.params;
  const {auth} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const goBack = useCallback(() => navigation.goBack(), [navigation]);
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = useCallback(() => modalRef.current?.close(), []);
  const onConfirmPress = useCallback(async () => {
    await close();
    if (onSuccess !== undefined) {
      onSuccess();
    } else {
      safelyGoBack();
    }
    await auth.registerNewFarm();
  }, [auth, close, onSuccess, safelyGoBack]);
  return (
    <CreateNewFarmWarningModal
      ref={modalRef}
      onConfirmPress={onConfirmPress}
      onCancelPress={close}
      onModalClosed={goBack}
    />
  );
});
