import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {ThanksForPurchaseModal} from '../../ThanksForPurchaseModal';
import {useResetToDashboard, useSafelyGoBack} from '../hooks';
import {AdaptiveModalRef} from '../../components/templates';

export default observer(function ThanksForPurchaseBinding() {
  const resetToDashboard = useResetToDashboard();
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const goToDashboard = useCallback(async () => {
    await modalRef.current?.close();
    resetToDashboard();
  }, [resetToDashboard]);
  return (
    <ThanksForPurchaseModal
      ref={modalRef}
      goToDashboard={goToDashboard}
      onModalClosed={safelyGoBack}
    />
  );
});
