import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, Pressable, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useTheme, variance} from '../styling';
import PickHashrateSection from '../components/organisms/purchase/PickHashrateSection';
import {useStrings} from '../Root/hooks';
import {ScreenFluidLoader} from '../components/atoms/CryptoLoader';
import {LinearGradient} from 'expo-linear-gradient';
import {Header, Paragraph, Body} from '../components';
import WebBg from '../assets/svg/colored/webBg.svg';
import {
  LG_BREAKPOINT,
  useGetIsDimensions,
} from '../WindowDimensions/useDimensions';
import {GlobalError} from '../Error';
import PurchaseErrorCard from './PurchaseErrorView';
import {expr} from 'mobx-utils';
import MinerProductTableBinding from './MinerProductTableBinding';
import {Hashrate} from '../ApiStore';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';
import {DoubleProposal} from '../ProposalsState';
import {Gift} from '../InAppOffersService';

export type PoolMinerConfiguratorProps = {
  goToAccessRecovery: () => void;
  fetchOffersError?: GlobalError;
  goToConfiguration: () => void;
  isLoading: boolean;
  minerConfigs: MinerConfig[];
  selectedMinerConfig: MinerConfig | undefined;
  selectHashrate: (selectHashrate: Hashrate) => void;
  proposal: DoubleProposal | undefined;
  gift: Gift | undefined;
  isGiftLoading: boolean;
};

export default observer(function MinerTabView({
  goToAccessRecovery,
  goToConfiguration,
  fetchOffersError,
  isLoading,
  minerConfigs,
  selectedMinerConfig,
  proposal,
  selectHashrate,
  gift,
  isGiftLoading,
}: PoolMinerConfiguratorProps) {
  const strings = useStrings();
  const theme = useTheme();
  const getIsLarge = useGetIsDimensions('lg');
  const isLarge = expr(() => getIsLarge());
  const isTrialSubscription = useMemo(
    () =>
      proposal &&
      !proposal.product &&
      proposal.subscription &&
      proposal.subscription.offer.trial,
    [proposal],
  );
  if (isLoading) {
    return <ScreenFluidLoader />;
  }

  const subscriptionText = isTrialSubscription
    ? strings['purchase.poolMiners.trialSubtitle']
    : `${strings['purchase.poolMiners.subtitle']} ${
        Platform.OS !== 'web'
          ? strings['purchase.poolMiners.subtitle.subs']
          : ''
      }`;

  return (
    <RootView>
      <ContentGradient
        colors={[theme.colors.primaryAttractive, theme.colors.primaryNew]}>
        {fetchOffersError ? (
          <PurchaseErrorCard error={fetchOffersError} />
        ) : (
          <View>
            <HeaderView>
              <HeaderTopLeft>
                <TitleText type="header" size="h2">
                  {strings['purchase.poolMiners.title']}
                </TitleText>
                <SubTitleText type="body" weight="500">
                  {subscriptionText}
                </SubTitleText>
              </HeaderTopLeft>
              {isLarge && Platform.OS !== 'web' && (
                <RestoreButton onPress={goToAccessRecovery}>
                  <ButtonText default type="paragraph" weight="600">
                    {strings['subscription.restoreButton']}
                  </ButtonText>
                </RestoreButton>
              )}
            </HeaderView>
            <PickHashrateSection
              gift={gift}
              isGiftLoading={isGiftLoading}
              minerConfigs={minerConfigs}
              onChangeSlider={selectHashrate}
              selectedConfig={selectedMinerConfig}
              goToConfiguration={goToConfiguration}
            />
            <MinerProductTableContainerView>
              <MinerProductTableBinding />
            </MinerProductTableContainerView>
            {!isLarge && Platform.OS !== 'web' && (
              <TouchableOpacity onPress={goToAccessRecovery}>
                <ButtonText type="paragraph" weight="600">
                  {strings['subscription.restoreButton']}
                </ButtonText>
              </TouchableOpacity>
            )}
          </View>
        )}
        {isLarge && (
          <WebBgContainer>
            <WebBg />
          </WebBgContainer>
        )}
      </ContentGradient>
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {
    flex: 1,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    overflow: 'hidden',
  },
}));

const MinerProductTableContainerView = variance(View)((theme) => ({
  root: {
    marginTop: 18,
    paddingTop: 12,
    paddingBottom: 8,
    paddingHorizontal: 8,
    borderRadius: 16,
    backgroundColor: theme.chroma(theme.colors.primaryWhite).alpha(0.3).hex(),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingHorizontal: 0,
        borderRadius: 0,
        backgroundColor: undefined,
      },
    }),
  },
}));

const HeaderTopLeft = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

export const HeaderView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 18,
      },
    }),
  },
  zeroBottom: {
    marginBottom: 0,
  },
}));

export const ContentGradient = variance(LinearGradient)((theme) => ({
  root: {
    flex: 1,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    paddingHorizontal: 16,
    paddingTop: 24,
    paddingBottom: 42,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingTop: 16,
        overflow: 'hidden',
        paddingBottom: 16,
      },
    }),
  },
}));

export const WebBgContainer = variance(View)(() => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    zIndex: -1,
    elevation: -1,
    width: '100%',
  },
}));

export const RestoreButton = variance(Pressable)((theme) => ({
  root: {
    marginTop: 22,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.chroma(theme.colors.primaryWhite).alpha(0.3).hex(),
    backgroundColor: theme.chroma(theme.colors.primaryWhite).alpha(0.25).hex(),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 240,
        width: '100%',
        marginTop: 0,
        marginLeft: 35,
      },
    }),
  },
}));

export const ButtonText = variance(Paragraph)((theme) => ({
  root: {
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'center',
    paddingTop: 22,
    opacity: 0.6,
    color: theme.colors.primaryBlack,
  },
  default: {
    opacity: 1,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

export const TitleText = variance(Header)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
    textAlign: 'center',
    marginBottom: 6,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },
}));

export const SubTitleText = variance(Body)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.primaryBlack,
    opacity: 0.7,
    marginBottom: 22,
    fontSize: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
        marginBottom: 0,
        fontSize: 16,
      },
    }),
  },
  offsetBottom: {
    marginBottom: 12,
  },
}));
