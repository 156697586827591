import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {WorkerDetailsScreen} from '../../WorkerDetailsScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {WorkerId} from '../../ApiStore';
import {ScreenFluidLoader} from '../../components/atoms/CryptoLoader';
import NotFound from '../../WorkerDetailsScreen/NotFound';
import getIsSubscriptionWorker from '../../DashboardScreen/getters/getIsSubscriptionWorker';
import {Platform} from 'react-native';
import {useErrorHandler} from '../../ExceptionHandler';
import isRenewPossible from '../../DashboardScreen/checkers/isRenewPossible';
import {useOpenStoreManageSubscription} from '../../CurrentSubscription';
import {WorkerAction} from '../../WorkerActionModal';

export type WorkerDetailsBindingProps = RootStackBindingProps<'WorkerDetails'>;

export default observer(({navigation, route}: WorkerDetailsBindingProps) => {
  const {connectedClient, dashboardStore, proposalsState} = useRoot();
  const workerId = route.params?.workerId;
  const goToSchedule = useCallback(() => {
    workerId &&
      navigation.navigate('Schedule', {
        workerIds: [workerId],
      });
  }, [navigation, workerId]);
  const goToPoolActivator = useCallback(() => {
    navigation.navigate('PoolPuzzleConfirm');
  }, [navigation]);
  const goToStatistics = useCallback(() => {
    navigation.navigate('WorkerStatistics', {workerId});
  }, [navigation, workerId]);
  const goToManageSubscription = useOpenStoreManageSubscription();
  const goToRenew = useCallback(() => {
    workerId && navigation.navigate('RenewAccess', {workerId});
  }, [navigation, workerId]);
  const isLoading = dashboardStore.isLoading;
  const worker = workerId ? dashboardStore.getWorkerById(workerId) : undefined;
  const isSubscriptionWorker = expr(() =>
    worker ? getIsSubscriptionWorker(worker) : false,
  );
  const visibleManageButton = Platform.OS !== 'web' && isSubscriptionWorker;
  const visibleRenewButton = expr(() =>
    worker
      ? isRenewPossible(
          worker,
          proposalsState.minerProductProposalList.flatMap((_) =>
            _.available ? [_.offer] : [],
          ),
          proposalsState.minerSubscriptionProposalList.flatMap((_) =>
            _.available ? [_.offer] : [],
          ),
        )
      : false,
  );
  const goToWorkerAction = useCallback(
    () =>
      navigation.navigate('WorkerAction', {
        workerId,
        hiddenOptions: [WorkerAction.Detail],
      }),
    [navigation, workerId],
  );

  const goToDelete = useCallback(() => {
    navigation.navigate('DeleteWorkerConfirm', {
      workerId,
      withRenew: visibleRenewButton,
      forceNavigateRoot: true,
    });
  }, [navigation, workerId, visibleRenewButton]);

  const errorHandler = useErrorHandler();
  const onWorkerSpeedChanged = useCallback(
    async (speed: number) => {
      if (workerId === undefined) {
        return;
      }
      const result = await connectedClient.call('change_settings', {
        worker_id: workerId,
        settings: {speed},
      });
      if (!result.success) {
        errorHandler.handle(result.left);
      }
    },
    [connectedClient, errorHandler, workerId],
  );
  const goToPoolMinerSchedulerWarning = useCallback(
    (id: WorkerId) =>
      navigation.navigate('PoolMinerSchedulerWarning', {workerId: id}),
    [navigation],
  );
  if (isLoading) {
    return <ScreenFluidLoader />;
  }
  if (!worker) {
    return <NotFound />;
  }
  return (
    <WorkerDetailsScreen
      onWorkerSpeedChanged={onWorkerSpeedChanged}
      goToManageSubscription={goToManageSubscription}
      goToStatistics={goToStatistics}
      goToPoolActivator={goToPoolActivator}
      goToScheduler={goToSchedule}
      goToRenew={goToRenew}
      gotToDelete={goToDelete}
      visibleRenewButton={visibleRenewButton}
      isSubscriptionWorker={isSubscriptionWorker}
      visibleManageButton={visibleManageButton}
      worker={worker}
      goToWorkerAction={goToWorkerAction}
      goToPoolMinerSchedulerWarning={goToPoolMinerSchedulerWarning}
    />
  );
});
