import {CpuState} from '../../ApiStore';
import {sum} from 'lodash';
import {ReadonlyDeep} from 'type-fest';

export default function getAverageTemperature(
  state?: ReadonlyDeep<CpuState[]>,
) {
  if (!state?.length) {
    return 0;
  }
  return sum((state ?? []).map((_) => _.temperature)) / state.length;
}
