import {observer} from 'mobx-react-lite';
import {GraphPanel, GraphPanelProps} from '../GraphPanel';
import React, {useMemo} from 'react';
import {max} from 'lodash';

export type DualGraphPanelProps = GraphPanelProps & {
  usdThreshold: number;
};

export default observer(
  ({usdThreshold, graph, secondaryAxisRatio, ...rest}: DualGraphPanelProps) => {
    const {series} = graph ?? {};
    const maxSatoshi = useMemo(() => series && max(series), [series]);
    const secondaryAxisShown =
      maxSatoshi !== undefined &&
      secondaryAxisRatio !== undefined &&
      maxSatoshi * secondaryAxisRatio >= usdThreshold;

    return (
      <GraphPanel
        graph={graph}
        secondaryAxisShown={secondaryAxisShown}
        secondaryAxisRatio={secondaryAxisRatio}
        {...rest}
      />
    );
  },
);
