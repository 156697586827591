import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {PickLanguageModal} from '../../PickLanguageModal';
import {Locale} from '../../Preferences';
import {useRoot} from '../../Root/hooks';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';

export default observer(function PickLanguageBinding() {
  const {preferences} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = useCallback(() => modalRef.current?.close(), []);
  const onSubmit = useCallback(
    async (locale: Locale) => {
      await close();
      if (locale !== undefined) {
        preferences.setLocale(locale);
      }
      safelyGoBack();
    },
    [close, preferences, safelyGoBack],
  );
  return (
    <PickLanguageModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onSubmit={onSubmit}
    />
  );
});
