import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {EmptyFarmViewProps} from './EmptyFarmView';
import {FeatureRowViewProps} from './FeatureRowView';
import ContainerView from './ContainerView';
import IdLabel from './IdLabel';
import SlotsDescription from './SlotsDescription';
import {variance, useTheme} from '../styling';
import {GradientDivider} from '../components';
import {
  MD_BREAKPOINT,
  useGetDimensions,
} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';

export type SubscriptionViewProps = EmptyFarmViewProps &
  FeatureRowViewProps & {
    poolMiners: number;
    slots: number;
    label?: React.ReactNode;
    isLast?: boolean;
    isActive?: boolean;
    withLabel?: boolean;
    forceRow?: boolean;
  };

export default observer((props: SubscriptionViewProps) => {
  const {
    accountId,
    slots,
    label,
    withLabel,
    forceRow,
    isActive,
    poolMiners,
    ...rest
  } = props;
  const theme = useTheme();
  const getDimension = useGetDimensions();
  const isMd = expr(() => getDimension('md'));
  const miners = slots + poolMiners;
  if (isMd || forceRow) {
    return (
      <ContainerView vertical={forceRow} {...rest}>
        <IdLabel accountId={accountId} isMd={forceRow} isActive={isActive} />

        <LabelWrapper>
          {withLabel && (
            <GradientPadding>
              <GradientDivider
                colors={[
                  theme.colors.uiGradientDivider[0],
                  theme.colors.uiGradientDivider[1],
                  theme.colors.uiGradientDivider[2],
                ]}
                locations={[0, 0.5, 1]}
                start={[0, 1]}
                end={[1, 1]}
              />
            </GradientPadding>
          )}
          {label && withLabel && label}
          <GradientPadding vertical={forceRow}>
            <GradientDivider
              colors={[
                theme.colors.uiGradientDivider[0],
                theme.colors.uiGradientDivider[1],
                theme.colors.uiGradientDivider[2],
              ]}
              locations={[0, 0.5, 1]}
              start={[0, 1]}
              end={[1, 1]}
            />
          </GradientPadding>
        </LabelWrapper>

        <SlotsDescription isMd={forceRow} miners={miners} />
      </ContainerView>
    );
  }

  return (
    <ContainerView {...rest}>
      <Row>
        <IdLabel accountId={accountId} isMd />
        <GradientPadding vertical>
          <GradientDivider
            colors={[
              theme.colors.uiGradientDivider[0],
              theme.colors.uiGradientDivider[1],
              theme.colors.uiGradientDivider[2],
            ]}
            locations={[0, 0.5, 1]}
            start={[0, 1]}
            end={[1, 1]}
          />
        </GradientPadding>
        <SlotsDescription isMd miners={miners} />
      </Row>
      {withLabel && (
        <GradientPadding>
          <GradientDivider
            colors={[
              theme.colors.uiGradientDivider[0],
              theme.colors.uiGradientDivider[1],
              theme.colors.uiGradientDivider[2],
            ]}
            locations={[0, 0.5, 1]}
            start={[0, 1]}
            end={[1, 1]}
          />
        </GradientPadding>
      )}
      <LabelWrapper center>{label && withLabel && label}</LabelWrapper>
    </ContainerView>
  );
});

const Row = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  },
}));

const GradientPadding = variance(View)((theme) => ({
  root: {
    paddingTop: 15,
    paddingBottom: 10,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        width: 35,
        paddingTop: 0,
        paddingBottom: 0,
        marginStart: 'auto',
        marginEnd: 'auto',
        transform: [{rotate: '90deg'}],
      },
    }),
  },
  vertical: {
    width: 20,
    paddingTop: 0,
    paddingBottom: 0,
    marginStart: 'auto',
    marginEnd: 'auto',
    transform: [{rotate: '90deg'}],
    flexShrink: 0,
  },
}));

const LabelWrapper = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
  center: {
    justifyContent: 'center',
  },
}));
