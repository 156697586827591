import React, {forwardRef, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useBottomSheetDynamicSnapPoints} from '@gorhom/bottom-sheet';
import GorhomBottomSheet, {GorhomBottomSheetProps} from './GorhomBottomSheet';
import {StyleProp, View, ViewStyle, StyleSheet} from 'react-native';
import {ModalRef} from './types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export type BottomSheetSnapPointsProps =
  | {
      /**
       * Only works with bottom sheet representations
       */
      enableContentAutoHeight?: false;
      bottomSheetSnapPoints: GorhomBottomSheetProps['snapPoints'];
    }
  | {
      enableContentAutoHeight: true;
      bottomSheetSnapPoints?: undefined;
    };

export type PublicBottomSheetProps = BottomSheetSnapPointsProps &
  Pick<
    GorhomBottomSheetProps,
    'keyboardBlurBehavior' | 'enablePanDownToClose'
  > & {
    onBottomSheetChange?: (index: number) => void;
    bottomSheetStyle?: StyleProp<ViewStyle>;
    children: React.ReactNode;
  };

export type ProtectedBottomSheetProps = {
  onClosed: () => void;
  onShown: () => void;
  close: () => void;
};

export type BottomSheetProps = PublicBottomSheetProps &
  ProtectedBottomSheetProps;

export default observer(
  forwardRef<ModalRef, BottomSheetProps>(function BottomSheet(
    props,
    bottomSheetRef,
  ) {
    const {
      enableContentAutoHeight,
      bottomSheetStyle,
      onBottomSheetChange,
      enablePanDownToClose,
      keyboardBlurBehavior = 'restore',
      children,
      onClosed,
      onShown,
      close,
      bottomSheetSnapPoints,
    } = props;
    const insets = useSafeAreaInsets();
    const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], []);
    const {
      animatedHandleHeight,
      animatedSnapPoints,
      animatedContentHeight,
      handleContentLayout,
    } = useBottomSheetDynamicSnapPoints(initialSnapPoints);

    const _onBottomSheetChange = useCallback(
      (index: number) => {
        onBottomSheetChange?.(index);
        if (index === 0) {
          onShown();
        }
      },
      [onBottomSheetChange, onShown],
    );

    const autoHeightProps = useMemo(
      () =>
        enableContentAutoHeight
          ? {
              snapPoints:
                animatedSnapPoints as GorhomBottomSheetProps['snapPoints'],
              handleHeight: animatedHandleHeight,
              contentHeight: animatedContentHeight,
            }
          : {
              snapPoints: bottomSheetSnapPoints,
            },
      [
        animatedContentHeight,
        animatedHandleHeight,
        animatedSnapPoints,
        enableContentAutoHeight,
        bottomSheetSnapPoints,
      ],
    );
    return (
      <GorhomBottomSheet
        onBackdropPress={close}
        onClose={onClosed}
        onChange={_onBottomSheetChange}
        ref={bottomSheetRef}
        style={bottomSheetStyle}
        topInset={insets.top}
        enablePanDownToClose={enablePanDownToClose}
        index={0}
        keyboardBlurBehavior={keyboardBlurBehavior}
        {...autoHeightProps}>
        <View style={styles.grow} onLayout={handleContentLayout}>
          {children}
        </View>
      </GorhomBottomSheet>
    );
  }),
);

const styles = StyleSheet.create({
  grow: {
    flex: 1,
  },
});
