import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {PaymentScreenContainer} from '../../PaymentScreenContainer';

export default observer(function PaymentBinding({
  route,
  navigation,
}: RootStackBindingProps<'Payment'>) {
  const {id} = route.params;

  const goToThanksForPurchase = useCallback(() => {
    navigation.replace('Root', {screen: 'Dashboard'});
    navigation.navigate('ThanksForPurchase');
  }, [navigation]);
  const goToPaymentConfirmPending = useCallback(
    () => navigation.navigate('PaymentConfirmPending'),
    [navigation],
  );
  if (!id) {
    return null;
  }

  return (
    <PaymentScreenContainer
      orderId={id}
      goToThanksForPurchase={goToThanksForPurchase}
      goToPaymentConfirmPending={goToPaymentConfirmPending}
    />
  );
});
