import {Opaque} from 'type-fest';

export const PUZZLE_WIDTH = 58;
export const PUZZLE_HEIGHT = 58;
export const SLIDER_BUTTON_WIDTH = 60;

export type Position = {
  x: PercentPosition;
  y: PercentPosition;
};

export type Quiz = {
  start: Position;
  target: Position;
};

export const PERCENT_POSITION = Symbol();
export type PercentPosition = Opaque<number, typeof PERCENT_POSITION>;
export const IMAGES = [
  require('./images/01.jpg'),
  require('./images/02.jpg'),
  require('./images/03.jpg'),
  require('./images/04.jpg'),
  require('./images/05.jpg'),
  require('./images/06.jpg'),
  require('./images/07.jpg'),
  require('./images/08.jpg'),
  require('./images/09.jpg'),
  require('./images/10.jpg'),
  require('./images/11.jpg'),
  require('./images/12.jpg'),
  require('./images/13.jpg'),
  require('./images/14.jpg'),
  require('./images/15.jpg'),
  require('./images/16.jpg'),
  require('./images/17.jpg'),
  require('./images/18.jpg'),
  require('./images/19.jpg'),
  require('./images/20.jpg'),
];

export enum SliderStatus {
  Idle,
  Error,
  Confirming,
  Success,
}

export const PUZZLE_HEIGHT_RATIO = 0.55;
export const PERMISSIBLE_DEVIATION = 10;
