import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {AdaptiveModalHeader} from '../components/organisms/modal/AdaptiveModalHeader';
import {ComputerIcon, ComputerLargeIcon} from './LinkWorkerConfirmModal';
import SectionHeader from './SectionHeader';
import SectionDescription from './SectionDescription';
import MinerSvg from '../assets/svg/grayish/miner.svg';
import MinerDarkSvg from '../assets/svg/grayish/minerDark.svg';
import HelperBanner from './HelperBanner';
import Actions from './Actions';
import {GetWorkerByLinkKeyResult} from '../ApiStore';
import {getWorkerName} from '../DashboardScreen';
import getWorkerDescription from '../DashboardScreen/getters/getWorkerDescription';
import {AccountType} from '../Auth';
import {useRoot, useStrings} from '../Root/hooks';
import {AppButton, ButtonSize, ButtonVariant} from '../components/AppButton';

export type WorkerLinkInfoProps = {
  workerInfo: GetWorkerByLinkKeyResult;
  getIsDisabledLinkButtonBox: () => boolean;
  onSwitchFarmPress: () => void;
  onConfirmPress: () => void;
  onCancelPress: () => void;
  goToAuth: () => void;
};

export default observer(function WorkerLinkInfo(props: WorkerLinkInfoProps) {
  const {
    onSwitchFarmPress,
    onConfirmPress,
    goToAuth,
    onCancelPress,
    workerInfo,
    getIsDisabledLinkButtonBox,
  } = props;
  const {auth} = useRoot();
  const {email, accountType, accountId} = auth;
  const {worker} = workerInfo;
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <AdaptiveModalHeader
        style={styles.header}
        IconSvg={ComputerIcon}
        IconSvgModalRep={ComputerLargeIcon}
        title={strings['modal.workerLinkInfo.title']}
        description={strings['modal.workerLinkInfo.description']}
      />
      <SectionHeader
        left={strings['modal.workerLinkInfo.name']}
        right={getWorkerName(worker)}
      />
      <SectionDescription
        Icon={MinerSvg}
        IconDark={MinerDarkSvg}
        description={getWorkerDescription(worker)}
      />
      <SectionHeader
        left={strings['modal.workerLinkInfo.farmId']}
        right={accountId}
      />
      <FarmDescription
        goToAuth={goToAuth}
        email={email}
        accountType={accountType}
      />
      <HelperBanner />
      <Actions
        onCancelPress={onCancelPress}
        onConfirmPress={onConfirmPress}
        onSwitchFarmPress={onSwitchFarmPress}
        disabledLinkButton={getIsDisabledLinkButtonBox()}
      />
    </View>
  );
});

type FarmDescriptionProps = Partial<{
  accountType: AccountType;
  email: string;
  goToAuth: () => void;
}>;

const FarmDescription = observer(
  ({email, accountType, goToAuth}: FarmDescriptionProps) => {
    if (accountType === undefined) {
      return null;
    }
    const isPermanent = accountType === AccountType.Permanent;
    const strings = useStrings();
    const title = isPermanent
      ? email || ''
      : strings['modal.workerLinkInfo.notAuth'];
    return (
      <View style={styles.signInSection}>
        <SectionDescription
          Icon={MinerSvg}
          IconDark={MinerDarkSvg}
          description={title}
        />
        {!isPermanent && (
          <View style={styles.signInButtonView}>
            <AppButton
              variant={ButtonVariant.Primary}
              onPress={goToAuth}
              size={ButtonSize.Small}>
              {strings['action.in']}
            </AppButton>
          </View>
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    paddingTop: 37,
  },
  header: {
    marginBottom: 24,
  },
  signInSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  signInButtonView: {
    marginLeft: 'auto',
    paddingRight: 16,
  },
});
