import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {variance} from '../styling';
import {Body as TypoBody, Header as TypoHeader} from '../components';
import Header from './Header';
import {useStrings} from '../Root/hooks';
import {expr} from 'mobx-utils';
import PositionHelperStatic from './PositionHelperStatic';
import {useWindowDimensions} from '../WindowDimensions';
import {useVisibleTabletTemplate} from '../AppTemplateState';

export type ContentLayerProps = {
  goBack: () => void;
  visibleCamera: boolean;
};

export default observer(function ContentLayer({
  goBack,
  visibleCamera,
}: ContentLayerProps) {
  const window = useWindowDimensions();
  const strings = useStrings();
  const frameTop = expr(() => PositionHelperStatic.getFrameTopPosition(window));
  const frameBottom = expr(() =>
    PositionHelperStatic.getFrameBottomPosition(window),
  );
  const isVisible = useVisibleTabletTemplate();
  return (
    <View style={styles.contentLayer}>
      <View style={[styles.main, {bottom: window.height - frameTop}]}>
        {!isVisible && <Header goBack={goBack} />}
        <View style={styles.description}>
          <TitleText type="header" size="h3">
            {strings['qrCodeScanner.title']}
          </TitleText>
          {visibleCamera && (
            <HelperText type="body" weight="500">
              {strings['qrCode.helper']}
            </HelperText>
          )}
        </View>
      </View>
      <View style={[styles.footer, {top: frameBottom}]}>
        <BodyText type="body" offset>
          {strings['qrCodeScanner.paragraph.first']}
        </BodyText>
        <BodyText type="body">
          {strings['qrCodeScanner.paragraph.second']}
        </BodyText>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  contentLayer: {
    flex: 1,
    pointerEvents: 'box-none',
  },
  main: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  footer: {
    paddingHorizontal: 16,
    paddingTop: 22,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
  },
  description: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'flex-end',
    paddingHorizontal: 16,
    paddingBottom: 24,
    maxWidth: 500,
  },
  camera: {
    flex: 1,
  },
  contentScroll: {
    padding: 20,
  },
});

const TitleText = variance(TypoHeader)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    textAlign: 'center',
    marginBottom: 12,
    fontSize: 16,
    lineHeight: 26,
  },
}));

const BodyText = variance(TypoBody)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 16,
  },
  offset: {
    marginBottom: 12,
  },
}));

const HelperText = variance(TypoBody)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.colors.primaryAttractive,
  },
}));
