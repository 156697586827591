import {action, flow, observable, when, makeObservable} from 'mobx';
import {ReadonlyDeep} from 'type-fest';
import {bind, Either} from '../fp';
import {PageRequest} from './PageRequest';

export default class PageRequestImpl<Item> implements PageRequest<Item> {
  @observable.ref private _list?: Item[];
  @observable private _isLoading = false;
  @observable private _isRefreshing = false;
  @observable private _page = 0;

  constructor(
    private readonly _fetch: (
      page: number,
      size: number,
    ) => Promise<FetchResult<Item>>,
    public readonly size: number,
  ) {
    makeObservable(this);
  }

  get list() {
    return this._list as ReadonlyDeep<Item[]> | undefined;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  get page() {
    return this._page;
  }

  refresh = bind(
    flow(function* (this: PageRequestImpl<Item>) {
      this._isRefreshing = true;
      try {
        const size = this.size;
        const page = 0;
        const result: FetchResult<Item> = yield this._fetch(page, size);
        if (!result.success) {
          return;
        }
        this._list = result.right;
        this._page = getPageByList(this._list, size);
      } finally {
        this._isRefreshing = false;
      }
    }),
    this,
  );

  fetchNext = bind(
    flow(function* (this: PageRequestImpl<Item>) {
      yield when(() => !this._isLoading);
      this._isLoading = true;
      try {
        const size = this.size;
        const list = this._list ?? [];
        const itemsInTheLastPage = list.length % size;
        const shouldFetchNextPage = itemsInTheLastPage === 0;
        const page = this._page + (shouldFetchNextPage ? 1 : 0);
        const result: FetchResult<Item> = yield this._fetch(page, size);
        if (!result.success) {
          return;
        }
        this._list = [...list, ...result.right.slice(itemsInTheLastPage)];
        this._page = getPageByList(this._list, size);
      } finally {
        this._isLoading = false;
      }
    }),
    this,
  );

  @action reset() {
    this._list = undefined;
    this._page = 0;
  }
}

export type FetchResult<Item> = Either<Item[], unknown>;

const getPageByList = (list: any[], size: number) =>
  Math.max(Math.ceil(list.length / size) - 1, 0);
