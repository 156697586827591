import {useCallback} from 'react';
import {Alert} from 'react-native';
import {useRoot} from '../Root/hooks';

export default () => {
  const {logExporter, log, clipboard} = useRoot();
  const copyLog = useCallback(async () => {
    clipboard.setString(logExporter.getContent(log));
    Alert.alert(
      'Success',
      'The value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [clipboard, log, logExporter]);
  const saveLog = useCallback(async () => {
    try {
      await logExporter.save();
      Alert.alert(
        'Success',
        'The value is successfully saved to the file',
        [{style: 'default', text: 'OK'}],
        {cancelable: true},
      );
    } catch (ignore) {
      Alert.alert(
        'Error occurred',
        ignore?.toString(),
        [{style: 'default', text: 'OK'}],
        {cancelable: true},
      );
    }
  }, [logExporter]);
  const shareLog = useCallback(async () => {
    await logExporter.share();
  }, [logExporter]);
  return {copyLog, saveLog, shareLog};
};
