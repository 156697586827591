import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {ConnectWalletModal} from '../../ConnectWalletModal';
import {useSafelyGoBack} from '../hooks';

export enum WalletKind {
  Metamask,
  Coinbase,
  NCWallet,
}

export type Wallet = {
  id: WalletKind;
  name: string;
};

const availableWallets: Wallet[] = [
  {
    id: WalletKind.Metamask,
    name: 'Metamask',
  },
  {
    id: WalletKind.Coinbase,
    name: 'Coinbase',
  },
  {
    id: WalletKind.NCWallet,
    name: 'NC Wallet',
  },
];

export default observer(function ConnectWalletBinding() {
  const safelyGoBack = useSafelyGoBack();
  const handleConfirm = useCallback((selectedWallet: Wallet) => {
    console.log('Selected wallet', selectedWallet);
  }, []);

  return (
    <ConnectWalletModal
      availableWallets={availableWallets}
      onModalClosed={safelyGoBack}
      onConfirm={handleConfirm}
    />
  );
});
