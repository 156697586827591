import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import {v4 as uuidV4} from 'uuid';
import Svg, {Defs, Mask, Rect} from 'react-native-svg';
import {StyleSheet} from 'react-native';
import React, {useRef} from 'react';
import {useWindowDimensions} from '../WindowDimensions';

export type BackgroundMaskProps = {
  getTranslating: () => boolean;
};
export default observer(function BackgroundMask({
  getTranslating,
}: BackgroundMaskProps) {
  const {
    interactiveTutorial,
    appearance: {isDark},
    windowDimensionsState: {window},
    appTemplateState: {isTablet},
  } = useRoot();
  const {width, height} = useWindowDimensions();
  const {
    accentElement: element,
    activeStep,
    scrollToElementInProgress: goToElementInProgress,
  } = interactiveTutorial;
  const key = activeStep.elementKey;
  const maskId = useRef(uuidV4());
  let extraWidthX = 0;
  let extraHeightY = 0;
  const rx = 10;

  if (key === 'DASHBOARD_BALANCE') {
    extraWidthX = -5;
  }
  if (!isTablet) {
    if (key === 'TAB_BAR_ADD_WORKER') {
      extraHeightY = 10;
    } else if (key === 'TAB_BAR_STATISTICS') {
      extraWidthX = 10;
    } else if (key === 'TAB_BAR_NOTIFICATIONS') {
      extraWidthX = 10;
    }
  }

  const translating = getTranslating();
  return (
    <Svg
      style={StyleSheet.absoluteFillObject}
      width={window.width}
      height={window.height}>
      <Defs>
        <Mask id={maskId.current} x="0" y="0">
          <Rect
            x="0"
            y="0"
            width={width}
            height={height}
            fill={isDark ? '#a6a6a6' : '#666666'}
          />
          {!goToElementInProgress && !translating && element?.measure && (
            <Rect
              x={element.measure.pageX - extraWidthX / 2}
              y={element.measure.pageY - extraHeightY / 2}
              width={element.measure.width + extraWidthX}
              height={element.measure.height + extraHeightY}
              rx={rx}
              fill="#000"
            />
          )}
        </Mask>
      </Defs>
      <Rect
        width={window.width}
        height={window.height}
        fill="#000000"
        mask={`url(#${maskId.current})`}
      />
    </Svg>
  );
});
