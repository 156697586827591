import useWindowDimensions from './useWindowDimensions';
import {useCallback} from 'react';

/**
 * Link: https://getbootstrap.com/docs/4.1/layout/overview/
 */

// Small devices (landscape phones, 576px and up)
export const SM_BREAKPOINT = 576;
// Medium devices (tablets, 768px and up)
export const MD_BREAKPOINT = 768;
// Large devices (desktops, 992px and up)
export const LG_BREAKPOINT = 992;
// Extra large devices (large desktops, 1200px and up)
export const XL_BREAKPOINT = 1200;

export type LiteralBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export function useGetDimensions() {
  const {width} = useWindowDimensions();
  return useCallback(
    (breakpoint: LiteralBreakpoint) =>
      width >= translateBreakpointToPixels(breakpoint),
    [width],
  );
}

export function useGetIsDimensions(breakpoint: LiteralBreakpoint) {
  const {width} = useWindowDimensions();
  return useCallback(
    () => width >= translateBreakpointToPixels(breakpoint),
    [breakpoint, width],
  );
}

export function useGetIsPixels(_: number) {
  const {width} = useWindowDimensions();
  return useCallback(() => width >= _, [_, width]);
}

export function translateBreakpointToPixels(breakpoint: LiteralBreakpoint) {
  switch (breakpoint) {
    case 'sm':
      return SM_BREAKPOINT;
    case 'md':
      return MD_BREAKPOINT;
    case 'lg':
      return LG_BREAKPOINT;
    case 'xl':
      return XL_BREAKPOINT;
    case 'xs':
      return 0;
  }
}
