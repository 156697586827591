import {action, computed, observable, makeObservable} from 'mobx';
import {BannerType, DashboardBannersState} from './DashboardBannersState';
import {maxBy} from 'lodash';
import {Auth} from '../Auth';

export default class DashboardBannersStateImpl
  implements DashboardBannersState
{
  @observable private _banners = new Set<BannerType>();

  constructor(
    private readonly _root: {
      readonly auth: Auth;
    },
  ) {
    makeObservable(this);
  }

  private static BANNER_PRIORITY = new Map([
    [BannerType.Demo, 1],
    [BannerType.Upgrade, 3],
    [BannerType.Expired, 2],
    [BannerType.PoolMiner, 4],
  ]);

  private static getPriorityForBanner(banner: BannerType) {
    return this.BANNER_PRIORITY.get(banner) ?? 0;
  }

  @computed
  get hasShownBanner() {
    return this._banners.size > 0;
  }

  @action
  add(banner: BannerType) {
    this._banners.add(banner);
  }

  @action
  delete(banner: BannerType) {
    this._banners.delete(banner);
  }

  @computed
  get targetBanner() {
    const banners = [...this._banners.values()].map((_) => ({
      banner: _,
      priority: DashboardBannersStateImpl.getPriorityForBanner(_),
    }));
    return maxBy(banners, (_) => _.priority)?.banner;
  }

  reset() {
    this._banners.clear();
  }
}
