import {RefObject, useCallback, useState} from 'react';
import {observable, runInAction} from 'mobx';
import {LayoutChangeEvent, View} from 'react-native';
import {LayoutMeasureResult} from './LayoutMeasureResult';

export default function useMeasure(ref: RefObject<View>, measure = true) {
  const [resultBox] = useState(() => observable.box<LayoutMeasureResult>());
  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      runInAction(() =>
        resultBox.set({measure: false, ...event.nativeEvent.layout}),
      );
      if (measure) {
        ref.current?.measure(
          (
            x: number,
            y: number,
            width: number,
            height: number,
            pageX: number,
            pageY: number,
          ) => {
            runInAction(() =>
              resultBox.set({measure: true, x, y, width, height, pageX, pageY}),
            );
          },
        );
      }
    },
    [measure, ref, resultBox],
  );
  const getResult = useCallback(() => resultBox.get(), [resultBox]);
  return [getResult, onLayout] as const;
}
