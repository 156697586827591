import {useLayoutEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {CommonActions} from '@react-navigation/native';
import {DASHBOARD} from './constants';
import {AccountType} from '../../Auth';
import {Platform} from 'react-native';

export type CheckAuthBindingProps = RootStackBindingProps<'CheckAuth'>;

export default observer((props: CheckAuthBindingProps) => {
  const {navigation} = props;
  const {auth, navigationContainer} = useRoot();
  useLayoutEffect(() => {
    if (!navigationContainer.isConfigured) {
      return;
    }
    if (Platform.OS === 'web') {
      return navigation.dispatch(CommonActions.reset(DASHBOARD));
    }
    const isPermanent =
      auth.state?.kind === 'Connected' &&
      auth.state.accountType === AccountType.Permanent;
    if (isPermanent) {
      navigation.dispatch(CommonActions.reset(DASHBOARD));
    } else {
      navigation.replace('Auth');
    }
  }, [auth, navigation, navigationContainer.isConfigured]);
  return null;
});
