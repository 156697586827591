import {Platform} from 'react-native';
import Web3PaymentService from './Web3PaymentService';
import SubWeb3PaymentService from './SubWeb3PaymentService';
import {Web3Payment} from './Web3Payment';
import {Service} from '../structure';

export default class Web3PaymentFactory {
  create() {
    return Platform.select<Web3Payment & Service>({
      web: new Web3PaymentService(),
      default: new SubWeb3PaymentService(),
    });
  }
}
