import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {createStylesHook} from '../../../../styling';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import WorkerSpeedTemperatureSimple from '../../../../components/organisms/worker/WorkerSpeedTemperatureSimple';

export type SpeedTemperatureSimpleProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function SpeedTemperature({
  worker,
}: SpeedTemperatureSimpleProps) {
  const styles = useStyles();
  return (
    <View style={styles.root}>
      <WorkerSpeedTemperatureSimple worker={worker} />
    </View>
  );
});

const useStyles = createStylesHook(() => ({
  root: {
    paddingBottom: 5,
  },
}));
