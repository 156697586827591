import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {DeleteAccountConfirmModal} from '../../DeleteAccountConfirmModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import setFernetTokenAsync from '../../persistence/setFernetToken';
import {useErrorHandler} from '../../ExceptionHandler';
import {DELETE_FARM_ERROR} from '../../Error/DeleteFarmError';

export type DeleteWorkerConfirmBindingProps =
  RootStackBindingProps<'DeleteAccountConfirm'>;

export default observer(
  function DeleteAccountConfirmBinding({}: DeleteWorkerConfirmBindingProps) {
    const {connectedClient, auth} = useRoot();
    const safelyGoBack = useSafelyGoBack();
    const close = useCallback(() => modalRef.current?.close(), []);
    const errorHandler = useErrorHandler();
    const onCancel = useCallback(async () => {
      await close();
      safelyGoBack();
    }, [close, safelyGoBack]);
    const onConfirm = useCallback(async () => {
      await close();
      safelyGoBack();
      const result = await connectedClient.call('delete_farm');
      if (!result.success) {
        errorHandler.handle({
          kind: DELETE_FARM_ERROR,
          raw: result.left,
          description: result.left.message,
        });
        return;
      }
      await AsyncStorage.clear();
      await setFernetTokenAsync(undefined);
      auth.signOut();
    }, [auth, close, connectedClient, errorHandler, safelyGoBack]);
    const modalRef = useRef<AdaptiveModalRef>(null);
    return (
      <DeleteAccountConfirmModal
        ref={modalRef}
        onModalClosed={safelyGoBack}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  },
);
