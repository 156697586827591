import React, {useCallback, useMemo, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {FinishQuickStart} from '../../FinishQuickStart';
import {useGoToPoolMinerActivatedCallback, useSafelyGoBack} from '../hooks';
import {AdaptiveModalRef} from '../../components/templates';
import {useRoot} from '../../Root/hooks';
import {
  QuickStartListItemType,
  QuickStartListVariant,
} from '../../QuickStartScreen/types';
import {expr} from 'mobx-utils';
import {RootStackBindingProps} from './RootStackBindingProps';
import {MinerOffer, PaymentMethod} from '../../InAppOffersService';
import {AVAILABLE_VARIANTS} from '../../QuickStartContainer';
import usePurchasePoolMiner from '../../PurchaseRequester/usePurchasePoolMiner';
import {ISODateString} from '../../Time';
import {OrderId} from '../../ApiStore/units/order';
import {useErrorHandler} from '../../ExceptionHandler';

const PAYMENT_METHOD = PaymentMethod.InApp;

export default observer(function FinishQuickStartBinding({
  navigation,
  route,
}: RootStackBindingProps<'FinishQuickStart'>) {
  const safelyGoBack = useSafelyGoBack();
  const {quickStartOffer, auth, inAppLoaderState} = useRoot();
  const {selectedIndex = 0, recommendedIndex = 0} = route.params ?? {};
  const items: QuickStartListItemType[] = useMemo(() => {
    return quickStartOffer.offers.flatMap((_, index) => {
      const payment = _.payments.get(PAYMENT_METHOD);
      if (!payment) {
        return [];
      }
      const {price, currency} = payment;
      return [
        {
          variant: AVAILABLE_VARIANTS[index] ?? QuickStartListVariant.Low,
          hs: _.poolMinerConfig.hash_rate,
          price: `${price} ${currency}`,
          info: undefined,
          interval: _.interval,
          intervalType: _.interval_type,
          label: index === selectedIndex ? 'Current' : 'Recommended',
          disabled: _.bought,
        },
      ];
    });
  }, [quickStartOffer, selectedIndex]);
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) =>
    navigation.navigate('PoolMinerActivated', params),
  );
  const onSuccess = useCallback(
    (poolExpiredDate: ISODateString, poolHashrate: number) => {
      safelyGoBack();
      goToPoolMinerActivated(poolExpiredDate, poolHashrate);
    },
    [goToPoolMinerActivated, safelyGoBack],
  );
  const errorHandler = useErrorHandler();
  const purchase = usePurchasePoolMiner({errorHandler});
  const goToCryptoPayment = useCallback(
    (id: OrderId) => navigation.navigate('Payment', {id}),
    [navigation],
  );
  const buyOffer = useCallback(
    async (offer: MinerOffer) => {
      if (auth.accountId === undefined) {
        return;
      }
      inAppLoaderState.addLoading();
      await purchase({
        goToPoolMinerActivated: onSuccess,
        offer: offer,
        payment: PAYMENT_METHOD,
        goToCryptoPayment,
      });
      inAppLoaderState.deleteLoading();
    },
    [auth, goToCryptoPayment, inAppLoaderState, onSuccess, purchase],
  );
  const buySelected = useCallback(async () => {
    const selectedOffer = quickStartOffer.offers[selectedIndex];
    if (!selectedOffer) {
      return;
    }
    buyOffer(selectedOffer);
  }, [buyOffer, quickStartOffer, selectedIndex]);
  const buyProfitable = useCallback(() => {
    const profitableOffer = quickStartOffer.offers[recommendedIndex];
    if (!profitableOffer) {
      return;
    }
    buyOffer(profitableOffer);
  }, [buyOffer, recommendedIndex, quickStartOffer]);
  const selectedOffer = expr(() => items[selectedIndex]);
  const moreProfitableOffer = expr(() => items[recommendedIndex]);
  const modalRef = useRef<AdaptiveModalRef>(null);
  return (
    <FinishQuickStart
      onContinue={buySelected}
      selectedItem={selectedOffer}
      maxItem={moreProfitableOffer}
      onBuyProfitablePress={buyProfitable}
      onModalClosed={safelyGoBack}
      ref={modalRef}
      trial={quickStartOffer.trial}
    />
  );
});
