import {useCallback, useEffect, useRef, useState} from 'react';
import {autorun, observable} from 'mobx';
import {useRoot} from '../Root/hooks';
import {useMeasure} from '../ReactNativeUtil';
import {View} from 'react-native';
import {v4 as uuidV4} from 'uuid';
import {
  ELEMENT_MEASURE,
  FORCE_ELEMENT_MEASURE,
  StackElementKey,
} from './InteractiveTutorialState';

export default function useTutorialMeasure<
  R extends View = View,
>(tutorialViewKey: StackElementKey) {
  const {interactiveTutorial} = useRoot();
  const rootRef = useRef<R>(null);
  const [getResult, onLayout] = useMeasure(rootRef, true);
  const [forceBox] = useState(() => observable.box(uuidV4()));
  const getForceKey = useCallback(() => forceBox.get(), [forceBox]);
  useEffect(
    () =>
      interactiveTutorial.events.listen(FORCE_ELEMENT_MEASURE, (key) => {
        if (key === tutorialViewKey) {
          forceBox.set(uuidV4());
        }
      }),
    [tutorialViewKey, interactiveTutorial, forceBox, getResult],
  );
  useEffect(
    () =>
      autorun(() => {
        const measure = getResult();
        if (measure?.measure) {
          interactiveTutorial.events.send(ELEMENT_MEASURE, {
            key: tutorialViewKey,
            measure: measure,
          });
        }
      }),
    [forceBox, tutorialViewKey, getResult, interactiveTutorial],
  );
  return [rootRef, onLayout, getForceKey] as const;
}
