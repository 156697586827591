import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import SuccessComputerSvg from '../assets/svg/colorless/successComputer.svg';
import {sized} from '../Svg';
import {Header as TypoHeader, Body as TypoBody} from '../components';
import {useTheme, variance} from '../styling';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';

export type SuccessLinkProps = {
  onSuccessLinkPress: () => void;
};

export default observer(function SuccessLink({
  onSuccessLinkPress,
}: SuccessLinkProps) {
  const strings = useStrings();
  const theme = useTheme();
  return (
    <View style={styles.root}>
      <View style={styles.icon}>
        <SuccessComputerIcon color={theme.colors.uiSecondary} />
      </View>
      <TitleText type="header" size="h3">
        {strings['modal.linkWorkerConfirm.title']}
      </TitleText>
      <TypoText type="body" weight="500">
        {strings['modal.linkWorkerConfirm.description']}
      </TypoText>
      <View style={styles.footer}>
        <AppButton onPress={onSuccessLinkPress} variant={ButtonVariant.Primary}>
          {strings['action.done']}
        </AppButton>
      </View>
    </View>
  );
});

const SuccessComputerIcon = sized(SuccessComputerSvg, 64, 54);

const styles = StyleSheet.create({
  root: {
    paddingTop: 32,
    paddingHorizontal: 16,
  },
  icon: {
    alignItems: 'center',
    marginBottom: 13,
  },
  footer: {
    marginTop: 18,
  },
});

const TitleText = variance(TypoHeader)(() => ({
  root: {
    textAlign: 'center',
    marginBottom: 8,
  },
}));

const TypoText = variance(TypoBody)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.primaryUIDirtyBlue,
  },
}));
