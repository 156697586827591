import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import {variance} from '../../../../styling';
import WorkerCpusInfo from '../../../../components/organisms/worker/WorkerCpusInfo';
import WorkerDescription from '../../../../components/organisms/worker/WorkerDescription';
import useGetIsLarge from '../../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useRoot} from '../../../../Root/hooks';
import isRenewPossible from '../../../checkers/isRenewPossible';

export type ConfigurationProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function Configuration({worker}: ConfigurationProps) {
  const {goToPoolActivator, goToRenewAccess} =
    useForcedContext(DashboardContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const {proposalsState} = useRoot();
  const renewPossible = expr(() =>
    isRenewPossible(
      worker,
      proposalsState.minerProductProposalList.flatMap((_) =>
        _.available ? [_.offer] : [],
      ),
      proposalsState.minerSubscriptionProposalList.flatMap((_) =>
        _.available ? [_.offer] : [],
      ),
    ),
  );

  const onRenewPress = useCallback(() => {
    goToRenewAccess(worker.id);
  }, [goToRenewAccess, worker]);
  return (
    <RootView large={isLarge}>
      <DescriptionView>
        <WorkerDescription worker={worker} />
      </DescriptionView>
      <CpusView large={isLarge}>
        <WorkerCpusInfo
          renewButtonVisible={renewPossible}
          worker={worker}
          onActivePress={goToPoolActivator}
          onRenewPress={onRenewPress}
        />
      </CpusView>
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {},
  large: {},
}));

const DescriptionView = variance(View)(() => ({
  root: {
    paddingVertical: 12,
  },
}));

const CpusView = variance(View)(() => ({
  root: {},
  large: {
    minWidth: 350,
  },
}));
