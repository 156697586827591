import React, {ReactNode, useCallback, useMemo} from 'react';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {AuthStatus} from '../Auth';
import {expr} from 'mobx-utils';
import {ApiMode} from '../farmApi';
import {useTheme} from '../styling';
import Typography from './Typography';
import HelpSvg from '../assets/svg/colorless/helpCircle.svg';
import {TouchableOpacity} from 'react-native-gesture-handler';

interface BadgeProps {
  text: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  Icon?: ReactNode;
  onPress?: () => void;
}

export const Badge = observer((props: BadgeProps) => {
  const {text, style, Icon, onPress} = props;
  const theme = useTheme();

  const styles = useMemo(
    () =>
      ({
        container: {
          paddingVertical: 8,
          paddingHorizontal: 15,
          borderRadius: 6,
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: theme.colors.primaryError,
          width: 'auto',
        },
        text: {
          color: theme.colors.primaryWhite,
        },
      } as const),
    [theme],
  );

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <Typography type={'header'} size={'h6'} style={styles.text}>
        {text}
      </Typography>
      {Icon}
    </TouchableOpacity>
  );
});

export type ApiModeBadgeProps = Omit<BadgeProps, 'text'> & {
  hideInReal?: boolean;
};

const iconStyle = {marginLeft: 4};

export const ApiModeBadge = observer((props: ApiModeBadgeProps) => {
  const {hideInReal, ...rest} = props;
  const {apiStore, auth, navigationContainer} = useRoot();
  const strings = useStrings();
  const toSwitchDemo = useCallback(() => {
    navigationContainer.ref?.navigate('SwitchDemoMode');
  }, [navigationContainer]);
  const isReal = expr(
    () => apiStore.mode === ApiMode.Real || auth.status !== AuthStatus.Idle,
  );
  if (hideInReal && isReal) {
    return null;
  }
  let mode = isReal ? strings['mode.real'] : strings['mode.demo'];
  return (
    <Badge
      {...rest}
      text={mode}
      onPress={isReal ? undefined : toSwitchDemo}
      Icon={isReal ? undefined : <HelpSvg color="white" style={iconStyle} />}
    />
  );
});
