import React, {useCallback, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {PickScheduleWorkersModal} from '../../PickScheduleWorkersModal';
import {AdaptiveModalRef} from '../../components/templates';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useSafelyGoBack} from '../hooks';
import {useRoot} from '../../Root/hooks';
import ScheduleWorkersModalState from '../../PickScheduleWorkersModal/ScheduleWorkersModalState';

export type PickScheduleWorkersBindingProps =
  RootStackBindingProps<'PickScheduleWorkers'>;

export default observer(function PickScheduleWorkersBinding({
  route,
  navigation,
}: PickScheduleWorkersBindingProps) {
  const {
    nextParams,
    workerIds = [],
    selectedWorkerIds,
    pickedTimezone,
  } = route.params ?? {};
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);

  const closeModal = useCallback(() => modalRef.current?.close(), []);

  const root = useRoot();
  const [state] = useState(
    () => new ScheduleWorkersModalState(root, workerIds),
  );

  useEffect(() => state.subscribe(), [state]);

  useEffect(() => {
    if (selectedWorkerIds) {
      state.setSelectedWorkerIds(new Set(selectedWorkerIds));
    }

    if (pickedTimezone) {
      state.changeTimezoneOffset(pickedTimezone * 60);
    }
  }, [state, selectedWorkerIds, pickedTimezone]);

  const onWorkerSelect = useCallback(
    (id: string) => {
      if (state.selectedWorkerIds.has(id)) {
        state.unselectWorkerId(id);
      } else {
        state.selectWorkerId(id);
      }
    },
    [state],
  );

  const handleClose = useCallback(async () => {
    await closeModal();
    if (nextParams === undefined) {
      return safelyGoBack();
    }
    navigation.navigate('Schedule', {
      ...nextParams,
      selectedWorkerIds: [...state.selectedWorkerIds.values()],
    });
  }, [
    closeModal,
    navigation,
    nextParams,
    safelyGoBack,
    state.selectedWorkerIds,
  ]);

  return (
    <PickScheduleWorkersModal
      ref={modalRef}
      onModalClosed={handleClose}
      onClosePress={handleClose}
      state={state}
      onWorkerSelect={onWorkerSelect}
    />
  );
});
