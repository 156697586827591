import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {createStylesHook, useTheme, variance} from '../../../styling';
import RangeSlider, {RangeSliderProps} from './RangeSlider';
import Typography from '../../Typography';
import {RectButton} from 'react-native-gesture-handler';
import {Constants, SliderRef} from './Slider';
import {COLORS} from '../../../ScheduleScreen/constant';
import {mergeRefs} from '../../../ReactUtil';

export type PowerSliderProps = Omit<
  RangeSliderProps,
  'lineStyle' | 'dotStyle'
> & {
  disabledMinButton: boolean;
  disabledMaxButton: boolean;
  onReadonlyPress: () => void;
};

export default observer<PowerSliderProps, SliderRef>(
  forwardRef(function PowerSlider(
    {
      range = Constants.range,
      value = Constants.defaultValue,
      onValueChange,
      onValueChanged,
      disabled,
      disabledMinButton,
      disabledMaxButton,
      readonly,
      onReadonlyPress,
      ...rest
    },
    ref,
  ) {
    const startValue = range[0];
    const endValue = range[1];
    const [innerValue, setInnerValue] = useState(() => value);
    useEffect(() => {
      setInnerValue(value);
    }, [value]);
    const sliderRef = useRef<SliderRef>(null);
    const onPressOff = useCallback(() => {
      sliderRef.current?.setValue(startValue, true);
      onValueChanged?.(startValue);
    }, [onValueChanged, startValue]);
    const onPressMax = useCallback(() => {
      sliderRef.current?.setValue(endValue, true);
      onValueChanged?.(endValue);
    }, [endValue, onValueChanged]);
    const theme = useTheme();
    const backgroundColorStyle = useMemo(() => {
      const colorValue = Math.round(innerValue / 5) * 5;
      const color = COLORS.get(colorValue) ?? theme.colors.primaryNew;
      return {
        backgroundColor: disabled ? theme.colors.uiAdditional3 : color,
      };
    }, [disabled, innerValue, theme]);
    const onChange = useCallback(
      (newValue: number) => {
        setInnerValue(newValue);
        onValueChange?.(newValue);
      },
      [onValueChange],
    );
    const themedStyles = useThemedStyles();
    return (
      <View>
        <RangeSlider
          lineStyle={backgroundColorStyle}
          dotStyle={[backgroundColorStyle, themedStyles.dot]}
          value={innerValue}
          onValueChange={onChange}
          onValueChanged={onValueChanged}
          {...rest}
          ref={mergeRefs([sliderRef, ref])}
        />
        <View style={styles.actionList}>
          <TextButton enabled={!disabledMinButton} onPress={onPressOff}>
            <View accessible accessibilityRole="button">
              <OffText type="body" weight="500">
                Off
              </OffText>
            </View>
          </TextButton>
          <TextButton enabled={!disabledMaxButton} onPress={onPressMax}>
            <View accessible accessibilityRole="button">
              <MaxText type="body" weight="500">
                Max
              </MaxText>
            </View>
          </TextButton>
        </View>
        {readonly && (
          <TouchableOpacity
            activeOpacity={1}
            style={StyleSheet.absoluteFillObject}
            onPress={onReadonlyPress}
          />
        )}
      </View>
    );
  }),
);

const useThemedStyles = createStylesHook((theme) => ({
  dot: {
    borderColor: theme.colors.uiMain,
  },
}));

const styles = StyleSheet.create({
  actionList: {
    marginTop: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const TextButton = variance(RectButton)(() => ({
  root: {
    padding: 4,
    margin: -4,
    borderRadius: 3,
  },
}));

const OffText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiAdditional3,
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: -0.2,
  },
  disabled: {
    color: theme.colors.uiAdditional3,
  },
}));

const MaxText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: -0.2,
  },
  disabled: {
    color: theme.colors.uiAdditional3,
  },
}));
