import {Millisecond} from '../Time';
import {LocaleTag} from '../Localization/TranslationService';
import {Locale} from '../Preferences';
import humanizeDuration, {Unit} from 'humanize-duration';

export default (
  milliseconds: Millisecond,
  localeTag: LocaleTag,
  units: Unit[] = ['mo'],
) => {
  return humanizeDuration(milliseconds, {
    units: units,
    round: true,
    language: localeTag,
    fallbacks: [Locale.English],
  });
};
