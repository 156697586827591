import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {RootStackBindingProps} from './RootStackBindingProps';
import {TutorialModal} from '../../TutorialModal';
import {useSafelyGoBack} from '../hooks';
import {Url} from '../../units';
import {openLink, Target} from '../../Links';
import {FULFILLED, REJECTED} from '../../AsyncAtom';
import {expr} from 'mobx-utils';
import {useRoot} from '../../Root/hooks';
import {AdSpot} from '../../ApiStore/entities/GetAds';
import {first} from 'lodash';

export default observer(
  function TutorialBinding({}: RootStackBindingProps<'Tutorial'>) {
    const {advertHelper, windowDimensionsState, auth} = useRoot();
    const safelyGoBack = useSafelyGoBack();
    const onUrlPress = useCallback(
      (url: Url) => openLink(url, Target.Blank),
      [],
    );
    const visibleContent = expr(
      () =>
        advertHelper.state?.status === FULFILLED ||
        advertHelper.state?.status === REJECTED,
    );
    const isDesktop = expr(() => windowDimensionsState.window.width > 615);
    const banner = expr(() => {
      if (advertHelper.state?.status !== FULFILLED) {
        return undefined;
      }
      return isDesktop
        ? first(
            advertHelper.state.result.spotByBannerList.get(
              AdSpot.TutorialDesktop,
            ),
          )
        : first(
            advertHelper.state.result.spotByBannerList.get(
              AdSpot.TutorialMobile,
            ),
          );
    });
    const onAdvertPress = useCallback(async () => {
      if (!banner) {
        return;
      }
      const firstAction = first(banner.actions);
      if (!firstAction) {
        return;
      }
      if (firstAction.link) {
        await openLink(firstAction.link);
      }
      advertHelper.close(banner.id, banner.spot, true);
      safelyGoBack();
    }, [advertHelper, banner, safelyGoBack]);
    const visible = expr(() => auth.initialized && auth.isConnected);
    if (!visible) {
      return null;
    }
    return (
      <TutorialModal
        onClose={safelyGoBack}
        onComplete={safelyGoBack}
        onAdvertPress={onAdvertPress}
        onUrlPress={onUrlPress}
        visibleContent={visibleContent}
        banner={banner}
      />
    );
  },
);
