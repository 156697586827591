import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionModal} from '../components/organisms';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import {AdaptiveModalRef} from '../components/templates';

export type CreateNewFarmWarningModalProps = {
  onModalClosed: () => void;
  onCancelPress: () => void;
  onConfirmPress: () => void;
};

export default observer<CreateNewFarmWarningModalProps, AdaptiveModalRef>(
  forwardRef(function CreateNewFarmWarningModal(
    {onModalClosed, onCancelPress, onConfirmPress},
    ref,
  ) {
    const strings = useStrings();
    return (
      <AdaptiveActionModal
        ref={ref}
        title={strings['signUp.newRegister']}
        description={strings['signUp.newRegisterWarning']}
        onModalClosed={onModalClosed}
        actions={[
          <AppButton onPress={onCancelPress}>
            {strings['action.cancel']}
          </AppButton>,
          <AppButton variant={ButtonVariant.Primary} onPress={onConfirmPress}>
            {strings['action.confirm']}
          </AppButton>,
        ]}
      />
    );
  }),
);
