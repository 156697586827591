import {Auth} from '../Auth';
import {AsyncReturnType} from 'type-fest';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {PurchaseDiscount} from '../PurchasePromoService';
import {ConnectedClient} from '../ContextClient';
import {Configuration} from '../Configuration';
import OffersRequesterFactory from './OfferManager/OffersRequesterFactory';
import {ErrorRepository} from '../ErrorRepository';
import BaseInAppOffersService from './BaseInAppOffersService';

export default class MobileInAppOffersService extends BaseInAppOffersService {
  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    super(_root);
    this._requester = new OffersRequesterFactory(_root).create();
  }

  protected async _fetchPurchasedId() {
    const getAvailablePurchases_: AsyncReturnType<
      InAppPurchaseManager['getAvailablePurchases']
    > = await this._root.inAppPurchaseManager.getAvailablePurchases();
    if (!getAvailablePurchases_.success) {
      this._purchasedIds = new Set();
      this._isLoading = false;
      this._error = getAvailablePurchases_.left;
      return;
    }
    this._purchasedIds = new Set(
      getAvailablePurchases_.right.map((_) => _.purchaseId),
    );
  }
}
