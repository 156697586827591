import BasePurchaseScreenStateImpl from './BasePurchaseScreenStateImpl';
import {PaymentMethod} from '../InAppOffersService';
import {Auth} from '../Auth';
import {ProposalsState} from '../ProposalsState';
import {computed, makeObservable} from 'mobx';
import {CurrentSubscriptionOffer} from '../CurrentSubscriptionOfferService';
import StaticPurchaseScreenHelper from './StaticPurchaseScreenHelper';

export default class NativePurchaseScreenStateImpl extends BasePurchaseScreenStateImpl {
  readonly selectedPaymentMethod = PaymentMethod.InApp;

  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly proposalsState: ProposalsState;
      readonly currentSubscriptionOfferService: CurrentSubscriptionOffer;
    },
  ) {
    super(_root);
    makeObservable(this);
  }

  selectPaymentMethod(): void {
    throw new Error('Not implemented on this platform');
  }

  @computed
  get selectedDoubleProposal() {
    const {doubleProposalByIntervalByHashrate} = this._root.proposalsState;
    if (
      this.selectedHashrate === undefined ||
      doubleProposalByIntervalByHashrate === undefined
    ) {
      return undefined;
    }
    const candidate = doubleProposalByIntervalByHashrate
      .get(this.selectedHashrate)
      ?.get(this.selectedInterval);

    if (!candidate) {
      return undefined;
    }

    return StaticPurchaseScreenHelper.filterDoublePoolProposal(
      candidate,
      this._root.currentSubscriptionOfferService.currentSubscriptionOffer,
    );
  }
}
