import React, {useCallback} from 'react';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {observer} from 'mobx-react-lite';
import {NotificationsScreen} from '../../NotificationsScreen';
import {useRoot} from '../../Root/hooks';
import {useOpenLink} from '../../Links';

export type NotificationsBindingProps = BottomTabBindingProps<'Notifications'>;

export default observer(function NotificationsBinding({
  navigation,
}: NotificationsBindingProps) {
  const {
    configuration: {values},
  } = useRoot();
  const goToAddDemoMiner = useCallback(
    () => navigation.navigate('LinkDemoWorker'),
    [navigation],
  );
  const goToPlan = useCallback(() => navigation.navigate('Plan'), [navigation]);
  const goToScanQr = useCallback(
    () => navigation.navigate('QRCodeScanner'),
    [navigation],
  );
  const goToDownloadMiner = useOpenLink(values.downloadUrl);
  return (
    <NotificationsScreen
      goToAddDemoMiner={goToAddDemoMiner}
      goToPlan={goToPlan}
      goToScanQr={goToScanQr}
      goToDownloadMiner={goToDownloadMiner}
    />
  );
});
