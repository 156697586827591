import {observer} from 'mobx-react-lite';
import React, {forwardRef, useCallback} from 'react';
import {FlatListProps, Pressable, View, StyleSheet} from 'react-native';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {variance} from '../styling';
import YellowChipSvg from '../assets/svg/twoTone/yellowChip.svg';
import {Paragraph} from '../components';
import {FlatList} from 'react-native-gesture-handler';
import {Divider, TwoToneIcon} from '../components/atoms';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import {SvgProps} from 'react-native-svg';
import {sized} from '../Svg';
import {AdaptiveModalHeader} from '../components/organisms/modal/AdaptiveModalHeader';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';
import {Hashrate} from '../ApiStore';
import {formatNumberWithSpaces} from '../utils/formatNumberWithSpaces';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type PickConfigurationProps = {
  onModalClosed: () => void;
  onSelectPress: () => void;
  data: ListProps['data'];
  selectHashrate: (hashrate: Hashrate) => void;
  selectedHashrate: Hashrate | undefined;
};

export type ListProps = FlatListProps<MinerConfig>;

export default observer<PickConfigurationProps, AdaptiveModalRef>(
  forwardRef(function PickConfiguration(props, ref) {
    return (
      <AdaptiveModal
        ref={ref}
        onModalClosed={props.onModalClosed}
        modalWidth={ModalWidth.Small}
        enableContentAutoHeight>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  ({
    selectHashrate,
    data,
    selectedHashrate,
    onSelectPress,
  }: PickConfigurationProps) => {
    const strings = useStrings();
    const renderItem = useCallback(
      ({item}: {item: MinerConfig}) => {
        const selected = item.hashrate === selectedHashrate;
        return (
          <Item
            onPress={() => {
              selectHashrate(item.hashrate);
            }}>
            <TextTitle
              selected={selected}
              weight="500"
              type="paragraph"
              size="large">
              {item.name}
            </TextTitle>
            <HashrateView>
              <TextTitle
                numberOfLines={1}
                adjustsFontSizeToFit
                minimumFontScale={0.8}
                selected
                weight="500"
                type="paragraph"
                size="large">
                {formatNumberWithSpaces(item.hashrate)} H/s
              </TextTitle>
            </HashrateView>
          </Item>
        );
      },
      [selectHashrate, selectedHashrate],
    );
      const paddingBottom = useSafePaddingBottom(16);
    return (
      <RootView style={{paddingBottom}}>
        <AdaptiveModalHeader
          style={styles.header}
          IconSvg={YellowChipIcon}
          title={strings['pickPoolMinerConfig.title']}
          description={strings['pickPoolMinerConfig.desc']}
        />
        <Divider />
        <FlatList
          style={styles.list}
          data={data}
          ItemSeparatorComponent={Divider}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
        <AppButton onPress={onSelectPress} variant={ButtonVariant.Primary}>
          {strings['action.select']}
        </AppButton>
      </RootView>
    );
  },
);

const styles = StyleSheet.create({
  header: {
    marginBottom: 18,
  },
  list: {
    maxHeight: 305,
    marginBottom: 18,
  },
});

const keyExtractor: ListProps['keyExtractor'] = (item) => String(item.hashrate);

const YellowChipSized = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(YellowChipSvg, 32, 32)} {...props} />
));

const YellowChipIcon = variance(YellowChipSized)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Item = variance(Pressable)(() => ({
  root: {
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const TextTitle = variance(Paragraph)((theme) => ({
  root: {
    flexShrink: 1,
  },
  selected: {
    color: theme.colors.primaryAttractive,
  },
}));

const HashrateView = variance(View)(() => ({
  root: {
    alignItems: 'flex-end',
    width: 100,
  },
}));

const RootView = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 30,
  },
}));
