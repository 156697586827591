import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {PurchaseScreen} from '../../PurchaseScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {
  useGoToAppStore,
  useGoToPlayStore,
  useGoToPoolMinerActivatedCallback,
  useNavigationGetIsFocused,
  useResetToDashboard,
  useSafelyGoToAffiliate,
} from '../hooks';
import {nanoid} from 'nanoid';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from './index';
import {useOpenAffiliateOnUniqueToken} from './WithdrawalHistoryBinding';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';
import {useRoot, useStrings} from '../../Root/hooks';
import {autorun, reaction} from 'mobx';
import useCheckDiscount from '../../PurchaseScreen/hooks/useCheckDiscount';
import {Offer, OfferPurchaseType} from '../../InAppOffersService';
import {ScreenFluidLoader} from '../../components/atoms/CryptoLoader';
import usePurchasePoolActivator from '../../PurchaseRequester/usePurchasePoolActivator';
import {usePurchasePoolMiner} from '../../PurchaseRequester';
import {ISODateString} from '../../Time';
import {OrderId} from '../../ApiStore/units/order';
import {Platform} from 'react-native';
import {useErrorHandler} from '../../ExceptionHandler';
import {expr} from 'mobx-utils';
import {PurchaseId} from '../../units';

export type SubscriptionBindingProps = RootStackBindingProps<'Plan'>;

export default observer(({navigation, route}: SubscriptionBindingProps) => {
  const {
    inAppOffers,
    purchaseScreenState,
    purchaseDiscount,
    auth,
    authFarmStateHelper,
    inAppLoaderState,
    currentSubscriptionState: {subscription},
    appWindowState,
    proposalsState,
  } = useRoot();
  const strings = useStrings();

  const getIsFocused = useNavigationGetIsFocused();
  const goToAccessRecovery = useCallback(
    () => navigation.navigate('AccessRecovery'),
    [navigation],
  );
  const goToSubscriptionManager = useCallback(
    (_: PurchaseId) => navigation.navigate('SubscriptionManagement', {sku: _}),
    [navigation],
  );
  const goToConfiguration = useCallback(
    () => navigation.navigate('PickPurchaseConfiguration'),
    [navigation],
  );
  const goToLinkWorker = useCallback(
    () => navigation.navigate('LinkWorker'),
    [navigation],
  );
  const goToThanksForPurchase = useCallback(
    () => navigation.navigate('ThanksForPurchase'),
    [navigation],
  );
  const goToPlayStore = useGoToPlayStore();
  const goToAppStore = useGoToAppStore();

  const goToAuthAndThenOpenAffiliate = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createPoolPurchaseState(nanoid()),
        variant: 'promo',
      }),
    [navigation],
  );

  const resetToDashboard = useResetToDashboard();
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) =>
    navigation.navigate('PoolMinerActivated', params),
  );
  const goToCryptoPayment = useCallback(
    (id: OrderId) => navigation.navigate('Payment', {id}),
    [navigation],
  );
  const goToPoolMinerDescription = useCallback(
    () => navigation.navigate('PoolMinerDescription'),
    [navigation],
  );
  const onSuccessfullyBuyPoolMiner = useCallback(
    (poolExpiredDate: ISODateString, poolHashrate: number) => {
      resetToDashboard();
      goToPoolMinerActivated(poolExpiredDate, poolHashrate);
    },
    [goToPoolMinerActivated, resetToDashboard],
  );
  const onSuccessfullyBuyActivator = useCallback(() => {
    resetToDashboard();
    goToThanksForPurchase();
  }, [goToThanksForPurchase, resetToDashboard]);
  const {safelyGoToAffiliate, getAffiliateIsPending} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
    AffiliateVariant.Promo,
  );
  const {goToAffiliateToken, silence, initialTab} = route.params ?? {};

  const {poolMinersCount} = authFarmStateHelper;
  const visibleReactivationTab =
    (poolMinersCount ? poolMinersCount > 0 : false) && Platform.OS !== 'web';

  useOpenAffiliateOnUniqueToken(goToAffiliateToken, safelyGoToAffiliate);
  const {checkExpired} = useCheckDiscount();
  useEffect(
    () =>
      reaction(
        () => [auth.isConnected, getIsFocused()] as const,
        async ([isConnected, isFocused]) => {
          if (
            isConnected &&
            isFocused &&
            !inAppOffers.isLoading &&
            silence === undefined
          ) {
            if (checkExpired()) {
              purchaseDiscount.deleteDiscount();
            }
          }
        },
        {fireImmediately: true},
      ),
    [auth, checkExpired, getIsFocused, purchaseDiscount, inAppOffers, silence],
  );

  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused() && appWindowState.isFocused) {
          purchaseScreenState.init(initialTab);
        } else {
          purchaseScreenState.blur();
        }
      }),
    [
      appWindowState,
      auth,
      getIsFocused,
      inAppOffers,
      initialTab,
      purchaseScreenState,
    ],
  );

  const errorHandler = useErrorHandler();
  const purchasePoolActivator = usePurchasePoolActivator({errorHandler});
  const purchasePoolMiner = usePurchasePoolMiner({
    errorHandler,
  });
  const purchase = useCallback(
    async (offer: Offer) => {
      inAppLoaderState.addLoading();
      inAppLoaderState.addText(strings['inAppLoader.purchaseDescription']);
      switch (offer.purchaseType) {
        case OfferPurchaseType.MinerSubscription:
        case OfferPurchaseType.MinerProduct:
        case OfferPurchaseType.TrialMinerSubscription:
          await purchasePoolMiner({
            goToPoolMinerActivated: onSuccessfullyBuyPoolMiner,
            offer: offer,
            payment: purchaseScreenState.selectedPaymentMethod,
            goToCryptoPayment,
          });
          break;
        case OfferPurchaseType.ActivatorSubscription:
          await purchasePoolActivator({
            goToThanksForPurchase: onSuccessfullyBuyActivator,
            offer: offer,
          });
          break;
      }
      inAppLoaderState.deleteText();
      inAppLoaderState.deleteLoading();
    },
    [
      inAppLoaderState,
      strings,
      purchasePoolMiner,
      onSuccessfullyBuyPoolMiner,
      purchaseScreenState.selectedPaymentMethod,
      goToCryptoPayment,
      purchasePoolActivator,
      onSuccessfullyBuyActivator,
    ],
  );
  const selectedConfig = expr(() =>
    purchaseScreenState.selectedHashrate
      ? proposalsState.minerConfigByHashrate.get(
          purchaseScreenState.selectedHashrate,
        )
      : undefined,
  );

  if (inAppOffers.isLoading && !inAppOffers.isLoadedIn) {
    return <ScreenFluidLoader />;
  }
  return (
    <PurchaseScreen
      isLoading={inAppOffers.isLoading}
      isRefreshing={inAppOffers.isRefreshing}
      onRefresh={inAppOffers.refresh}
      subscription={subscription}
      state={purchaseScreenState}
      goToAccessRecovery={goToAccessRecovery}
      goToConfiguration={goToConfiguration}
      onPurchasePress={purchase}
      onWorkerQuestionPress={goToPoolMinerDescription}
      fetchOffersError={inAppOffers.error}
      onManagePress={goToSubscriptionManager}
      onImprovePress={goToSubscriptionManager}
      goToLinkWorker={goToLinkWorker}
      goToAppStore={goToAppStore}
      goToPlayStore={goToPlayStore}
      getAffiliateIsPending={getAffiliateIsPending}
      safelyGoToAffiliate={safelyGoToAffiliate}
      visibleReactivationTab={visibleReactivationTab}
      selectedConfig={selectedConfig}
    />
  );
});

export const createPoolPurchaseState = (
  goToAffiliateToken?: string,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 1,
    routes: [
      {
        name: 'Root',
        state: {
          index: 0,
          routes: [
            {
              name: 'Menu',
            },
          ],
        },
      },
      {
        name: 'Plan',
        params: {goToAffiliateToken},
      },
    ],
  };
};
