import detectEthereumProvider from '@metamask/detect-provider';
import {providers} from '../Ethers';
import {makeObservable, observable} from 'mobx';
import {Service} from '../structure';
import {MetamaskConnector, SwitchNetworkResult} from './MetamaskConnector';
import {error, success} from '../fp';
import {ErrorNormalizer, PROVIDER_NOT_FOUND_ERROR} from './ErrorNormalizer';
import {Network} from './Web3Payment';

export default class MetamaskConnectorService
  implements Service, MetamaskConnector
{
  @observable private _detected = false;
  @observable private _connected = false;
  private _provider?: providers.Web3Provider;
  private _signer?: providers.JsonRpcSigner;

  constructor(private readonly errorNormalizer: ErrorNormalizer) {
    makeObservable(this);
  }

  get detected() {
    return this._detected;
  }

  get connected() {
    return this._connected;
  }

  get provider() {
    return this._provider;
  }
  get signer() {
    return this._signer;
  }

  async connect(): Promise<SwitchNetworkResult> {
    try {
      const ethereum = await detectEthereumProvider();
      if (!ethereum) {
        return error(PROVIDER_NOT_FOUND_ERROR);
      }

      this._provider = new providers.Web3Provider(ethereum);
    } catch (raw) {
      return error(this.errorNormalizer.normalize(raw));
    }

    try {
      this._signer = this._provider.getSigner();
      // MetaMask requires requesting permission to connect users accounts
      await this._provider.send('eth_requestAccounts', []);
      this._connected = true;
      return success(undefined);
    } catch (raw) {
      return error(this.errorNormalizer.normalize(raw));
    }
  }

  async switchToNetwork(network: Network): Promise<SwitchNetworkResult> {
    const provider = this._provider;
    if (!provider) {
      return error(PROVIDER_NOT_FOUND_ERROR);
    }
    const currentNetwork = await provider.getNetwork();
    if (currentNetwork.chainId === Number(network.chainId)) {
      return success(undefined);
    }
    const result = await this._switchToNetwork(network);
    await this.connect();
    return result;
  }

  private async _switchToNetwork(
    network: Network,
  ): Promise<SwitchNetworkResult> {
    const provider = this._provider;
    if (!provider) {
      return error(PROVIDER_NOT_FOUND_ERROR);
    }
    const targetChainId = network.chainId;
    try {
      await provider.send('wallet_switchEthereumChain', [
        {chainId: targetChainId},
      ]);
      return success(undefined);
    } catch (raw) {
      const normalized = this.errorNormalizer.normalize(raw);
      if (normalized.code === 4902) {
        // continue
      } else {
        return error(normalized);
      }
    }

    try {
      const params = [network];
      await provider.send('wallet_addEthereumChain', params);
      await provider.send('wallet_switchEthereumChain', [
        {chainId: targetChainId},
      ]);
      return success(undefined);
    } catch (raw) {
      return error(this.errorNormalizer.normalize(raw));
    }
  }

  private async _detect() {
    const ethereum = await detectEthereumProvider();
    if (ethereum) {
      this._detected = true;
      this._provider = new providers.Web3Provider(ethereum);
    }
  }

  subscribe() {
    // noinspection JSIgnoredPromiseFromCall
    this._detect();
    return undefined;
  }
}
