import {observer} from 'mobx-react-lite';
import React, {PropsWithChildren, useCallback} from 'react';
import {Swipeable, SwipeableProps} from 'react-native-gesture-handler';
import {Animated} from 'react-native';
import {variance} from '../../styling';

export type SwipeableContainerProps = SwipeableProps & {
  RightAction: React.ReactElement;
};

export default observer(function SwipeableContainer(
  props: PropsWithChildren<SwipeableContainerProps>,
) {
  const {children, RightAction, ...swipeableProps} = props;

  const rightActionRenderer = useCallback(
    (progress: Animated.AnimatedInterpolation<number>) => {
      const translateX = progress.interpolate({
        inputRange: [0, 1],
        outputRange: [100, 0],
      });

      const opacity = progress.interpolate({
        inputRange: [-1, 1],
        outputRange: [0, 1],
      });

      return (
        <RightActionAnimatedView style={{transform: [{translateX}], opacity}}>
          {RightAction}
        </RightActionAnimatedView>
      );
    },
    [RightAction],
  );

  return (
    <Swipeable
      overshootLeft={false}
      overshootRight={false}
      renderRightActions={rightActionRenderer}
      {...swipeableProps}>
      {children}
    </Swipeable>
  );
});

const RightActionAnimatedView = variance(Animated.View)(() => ({
  root: {
    alignSelf: 'center',
    marginHorizontal: 10,
  },
}));
