import {HeadlessMessagingHelper} from './HeadlessMessagingHelper';
import {NotificationData} from '../LocalNotifications';
import {Either} from '../fp';
import {MessageContext} from './MessageContext';
import {Json} from '../Json';
import {HeadlessMessaging} from './HeadlessMessaging';

export default class HeadlessMessagingHelperImpl
  implements HeadlessMessagingHelper
{
  constructor(
    private readonly _root: {
      readonly json: Json;
      readonly headlessMessaging: HeadlessMessaging;
    },
  ) {}

  async exchangeNotificationDataForMessageContext(
    data: NotificationData,
  ): Promise<Either<MessageContext, unknown>> {
    if (data.designator === 'remote-notification-data') {
      const description_ = this._root.json.parse(data.description);
      if (!description_.success) {
        return description_;
      }
      return await this._root.headlessMessaging.fetchContext(
        description_.right,
      );
    } else {
      return this._root.json.parse(data.context);
    }
  }
}
