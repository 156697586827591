import {useRoot} from '../Root/hooks';
import useHandleErrorAndExtractPromo from './useHandleErrorAndExtractPromo';
import {useCallback} from 'react';
import {Offer, PaymentMethod} from '../InAppOffersService';
import {RequestParams} from './PurchaseRequester';
import {ErrorHandler} from '../ExceptionHandler';

export type HookPurchasePoolActivatorParams = {
  errorHandler: ErrorHandler;
};

export type PurchasePoolActivatorParams = {
  offer: Offer;
  goToThanksForPurchase(): void;
};

export default function usePurchasePoolActivator({
  errorHandler,
}: HookPurchasePoolActivatorParams) {
  const {purchaseRequester, currentActivatorSubscription} = useRoot();
  const processPurchaseResult = useHandleErrorAndExtractPromo({errorHandler});
  return useCallback(
    async (params: PurchasePoolActivatorParams) => {
      const {offer, goToThanksForPurchase} = params;
      const requestParams: RequestParams = {
        payment: PaymentMethod.InApp,
        offerId: offer.offerId,
        purchaseTokenAndroid: undefined,
        poolMinerIdForRenew: undefined,
      };
      if (currentActivatorSubscription.current) {
        requestParams.purchaseTokenAndroid =
          currentActivatorSubscription.current.purchaseTokenAndroid;
      }
      const response = await purchaseRequester.request(requestParams);
      const process_ = await processPurchaseResult(response);
      if (!process_.success) {
        return;
      }
      if (response.success) {
        goToThanksForPurchase();
      }
    },
    [currentActivatorSubscription, purchaseRequester, processPurchaseResult],
  );
}
