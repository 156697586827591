import React from 'react';
import MainListItem from './extra/MainListItem';
import InsideListItem from './extra/InsideListItem';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {FarmLogVariant} from '../Notifications';
import {useTheme} from '../styling';
import {Divider} from '../components/atoms';
import {useGetDimensions} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {SectionProps} from './types';
import {View} from 'react-native';

export default observer(({state, onCheck}: SectionProps) => {
  const strings = useStrings();
  const theme = useTheme();
  const getDimension = useGetDimensions();
  const isLarge = expr(() => getDimension('lg'));
  return (
    <View>
      <MainListItem
        separated
        value={state[FarmLogVariant.Subscription].value}
        onValueChange={(value) => onCheck(FarmLogVariant.Subscription, value)}
        title={strings['notificationsSettings.subscription']}
      />
      <InsideListItem
        dotColor={theme.colors.primaryActive}
        value={state[FarmLogVariant.SubscriptionChanged].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.SubscriptionChanged, value)
        }
        title={strings['notificationsSettings.subscriptionChanged']}
      />
      <InsideListItem
        dotColor={theme.colors.primarySuccess}
        value={state[FarmLogVariant.SubscriptionActive].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.SubscriptionActive, value)
        }
        title={strings['notificationsSettings.subscriptionActive']}
      />
      <InsideListItem
        dotColor={theme.colors.uiAdditional4}
        value={state[FarmLogVariant.SubscriptionInactive].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.SubscriptionInactive, value)
        }
        title={strings['notificationsSettings.subscriptionInactive']}
      />
      <InsideListItem
        dotColor={theme.colors.primaryNew}
        value={state[FarmLogVariant.PoolMinerActivation].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.PoolMinerActivation, value)
        }
        title={strings['notificationsSettings.poolMinerActivation']}
      />
      <InsideListItem
        dotColor={theme.colors.primarySuccess}
        value={state[FarmLogVariant.PoolMinerExpiration].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.PoolMinerExpiration, value)
        }
        title={strings['notificationsSettings.poolMinerExpiration']}
      />
      {!isLarge && <Divider />}
    </View>
  );
});
