import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {View, TextInput, Button} from 'react-native';
import {createStylesHook} from '../styling';
import {TextInputState} from '../forms';
import {useRoot} from '../Root/hooks';
import {KeyboardAvoidingView} from 'react-native-keyboard-controller';

export default observer(() => {
  const {configuration} = useRoot();
  const [environment] = useState(() => new TextInputState());
  const create = useCallback(async () => {
    const patch = JSON.parse(environment.value ?? '');
    await configuration.createEnvironment(patch);
  }, [configuration, environment]);
  const styles = useStyles();
  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={88}
      behavior="padding"
      style={styles.root}>
      <TextInput style={styles.input} multiline {...environment.handlers} />
      <View style={styles.row}>
        <View style={styles.gutter}>
          <Button title="CREATE" color="green" onPress={create} />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
  input: {
    flexGrow: 1,
    flexShrink: 1,
    padding: 8,
    margin: 0,
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.uiSecondary,
  },
  row: {
    flexDirection: 'row',
  },
  gutter: {
    flex: 1,
    margin: 16,
  },
}));
