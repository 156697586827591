import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {useRoot} from '../../Root/hooks';
import {LogoutConfirmModal} from '../../LogoutConfirmModal';
import {RootStackBindingProps} from './RootStackBindingProps';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import useResetToDashboard from '../hooks/useResetToDashboard';

export type SignOutModalBindingProps = RootStackBindingProps<'LogoutConfirm'>;

export default observer(function LogoutConfirmBinding({
  navigation,
}: SignOutModalBindingProps) {
  const {auth} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const close = useCallback(() => modalRef.current?.close(), []);
  const resetToDashboard = useResetToDashboard();
  const goToAccountSwitch = useCallback(async () => {
    await close();
    safelyGoBack();
    navigation.replace('AccountSwitch');
  }, [close, navigation, safelyGoBack]);
  const signOut = useCallback(async () => {
    await close();
    await auth.signOut();
    resetToDashboard();
  }, [auth, close, resetToDashboard]);
  const modalRef = useRef<AdaptiveModalRef>(null);
  return (
    <LogoutConfirmModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      goToAccountSwitch={goToAccountSwitch}
      signOut={signOut}
    />
  );
});
