import {action, makeObservable} from 'mobx';
import _ from 'lodash';

import NotificationSettingsStateImpl from '../Notifications/NotificationSettingsStateImpl';
import {FarmLogVariant} from '../Notifications';
import {CheckboxTree} from './CheckboxTree';
import {
  NormalizeNodeList,
  Notification,
} from '../Notifications/NotificationSettingsState';

export default class CheckboxTreeState implements CheckboxTree {
  constructor(private readonly _root: NotificationSettingsStateImpl) {
    makeObservable(this);
  }

  get state() {
    return this._root.state;
  }

  recursiveForeachDeepByNodeId = (
    id: FarmLogVariant,
    nodes: NormalizeNodeList,
    cb: (node: Notification) => void,
  ) => {
    const node = nodes[id];
    cb(node);
    const childrenIdList = node.childrenIdList;
    if (childrenIdList === undefined) {
      return nodes;
    }
    for (const _id of childrenIdList) {
      const childrenNode = nodes[_id];
      if (childrenNode !== undefined) {
        this.recursiveForeachDeepByNodeId(childrenNode.id, nodes, cb);
      }
    }
  };

  recursiveForeachParentByNodeId = (
    id: FarmLogVariant,
    nodes: NormalizeNodeList,
    cb: (node: Notification) => void,
  ) => {
    const parentId = nodes[id].parentId;
    if (parentId === undefined) {
      return;
    }
    const parentNode = nodes[parentId];
    if (parentNode !== undefined) {
      cb(parentNode);
      this.recursiveForeachParentByNodeId(parentNode.id, nodes, cb);
    }
  };

  recursiveChangeDeepNode = (
    id: FarmLogVariant,
    nodes: NormalizeNodeList,
    value: boolean,
  ): NormalizeNodeList => {
    const _nodes = _.cloneDeep(nodes);
    this.recursiveForeachDeepByNodeId(id, _nodes, (n) => {
      n.value = value;
    });
    return _nodes;
  };

  recursiveChangeParentNode = (
    id: FarmLogVariant,
    nodes: NormalizeNodeList,
    value: boolean,
  ): NormalizeNodeList => {
    const _nodes = _.cloneDeep(nodes);
    const node = _nodes[id];
    node.value = value;
    this.recursiveForeachParentByNodeId(id, _nodes, (n) => {
      if (value) {
        n.value = true;
      } else {
        const childrenIdList = n.childrenIdList;
        if (childrenIdList === undefined) {
          return;
        }
        let allChildrenAreSame = true;
        childrenIdList.forEach((ni) => {
          const childrenNode = nodes[ni];
          if (childrenNode.value) {
            allChildrenAreSame = false;
          }
        });
        if (allChildrenAreSame) {
          n.value = false;
        }
      }
    });
    return _nodes;
  };

  checkRecursiveDependency = (
    id: FarmLogVariant,
    value: Notification['value'],
  ) => {
    const node = this.state[id];
    this._root.replaceState(
      this.recursiveChangeDeepNode(node.id, this.state, value),
    );
    this._root.replaceState(
      this.recursiveChangeParentNode(node.id, this.state, value),
    );
  };

  @action onCheck = (id: FarmLogVariant, value: Notification['value']) => {
    this.checkRecursiveDependency(id, value);
    // noinspection JSIgnoredPromiseFromCall
    this._root.updateOnServer();
  };
}
