import React, {useCallback, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import AdvertSplashScreen from '../../AdvertSplashScreen';
import {useNavigationGetIsFocused, useSafelyGoBack} from '../hooks';
import {first} from 'lodash';
import {AdaptiveModalRef} from '../../components/templates';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {FULFILLED, PENDING} from '../../AsyncAtom';
import {AdSpot} from '../../ApiStore/entities/GetAds';
import {useTimer} from 'use-timer';
import {expr} from 'mobx-utils';
import {ADVERT_SPLASH_DESKTOP_BREAKPOINT} from '../../AdvertSplashScreen/AdvertSplashScreen';
import {AdInfoAction} from '../../Advert';
import {autorun} from 'mobx';
import {Url} from '../../units';

export default observer(function AdvertSplashBinding({
  navigation,
}: RootStackBindingProps<'AdvertSplash'>) {
  const {
    advertHelper: {state, close},
    windowDimensionsState: {window},
    location,
  } = useRoot();
  const isDesktop = expr(
    () => window.width >= ADVERT_SPLASH_DESKTOP_BREAKPOINT,
  );
  const mobileBanner =
    state?.status === FULFILLED
      ? first(state.result.spotByBannerList.get(AdSpot.SplashMobile))
      : undefined;
  const desktopBanner =
    state?.status === FULFILLED
      ? first(state.result.spotByBannerList.get(AdSpot.SplashDesktop))
      : undefined;
  const [banner] = useState(() => (isDesktop && desktopBanner) || mobileBanner);

  const safelyGoBack = useSafelyGoBack();
  const navigationGetIsFocused = useNavigationGetIsFocused();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const closeModal = () => modalRef.current?.close();
  const onClose = useCallback(
    async (exclude = true) => {
      if (!banner) {
        return;
      }
      await closeModal();
      await close(banner.id, banner.spot, exclude);
      safelyGoBack();
    },
    [banner, close, safelyGoBack],
  );
  const onCrossClose = useCallback(() => onClose(false), [onClose]);
  const onModalClosed = useCallback(async () => {
    if (!banner) {
      return;
    }
    await close(banner.id, banner.spot, false);
    safelyGoBack();
  }, [banner, close, safelyGoBack]);

  const onButtonActionPress = useCallback(
    (action: AdInfoAction) => {
      if (action.link) {
        location.open(action.link as Url);
      }
      onClose();
    },
    [location, onClose],
  );

  const {time, start} = useTimer({
    autostart: false,
    initialTime: 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: onClose,
  });

  useEffect(
    () =>
      autorun(() => {
        if (navigationGetIsFocused()) {
          if (state && state.status === FULFILLED && banner !== undefined) {
            start();
          } else if (
            state &&
            state.status !== PENDING &&
            banner === undefined
          ) {
            safelyGoBack();
          }
        }
      }),
    [banner, navigation, navigationGetIsFocused, safelyGoBack, start, state],
  );

  if (banner === undefined) {
    console.warn('Banner for splash spot not found');
    return null;
  }

  return (
    <AdvertSplashScreen
      seconds={time}
      ref={modalRef}
      onButtonActionPress={onButtonActionPress}
      onCrossClose={onCrossClose}
      onModalClosed={onModalClosed}
      ad={banner}
      isDesktop={isDesktop}
    />
  );
});
