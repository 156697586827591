import {
  Discount,
  GetTariffsResult,
  Plan,
  PlanType,
  PurchaseType,
  Tariff,
} from '../../ApiStore';
import {Either, error, success} from '../../fp';
import {PurchaseId} from '../../units';
import {
  BaseStorePurchase,
  InAppPurchaseManager,
} from '../../InAppPurchaseManager';
import {OffersRequester} from './OffersRequester';
import {
  ActivatorSubscriptionOffer,
  MinerProductOffer,
  MinerSubscriptionOffer,
  Offer,
  OfferPurchaseType,
  OfferType,
  PaymentMethod,
  PurchaseKind,
  QuickMinerSubscriptionOffer,
} from './OfferManager';
import {ConnectedClient} from '../../ContextClient';
import {Configuration} from '../../Configuration';
import BaseOffersRequesterImpl from './BaseOffersRequesterImpl';
import {PurchaseDiscount} from '../../PurchasePromoService';
import toFixedTrunc from '../../utils/toFixedTrunc';
import {first} from 'lodash';
import {
  FETCH_OFFERS_ERROR,
  FETCH_OFFERS_NO_DATA_ERROR,
  FETCH_OFFERS_SERVER_ERROR,
  FETCH_OFFERS_STORE_ERROR,
  FetchOffersError,
  GlobalError,
} from '../../Error';
import {ErrorRepository} from '../../ErrorRepository';

export default class OfferRequesterImpl
  extends BaseOffersRequesterImpl
  implements OffersRequester
{
  constructor(
    protected readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly configuration: Configuration;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    super(_root);
  }

  private static _getSkus(plans: Plan[]): PurchaseId[] {
    return plans.flatMap((_) => (_.id_on_platform ? [_.id_on_platform] : []));
  }

  private _createPaymentsMap(
    plan: Plan,
    offer: BaseStorePurchase,
    discounts: Discount[],
  ) {
    const price = Number(offer.price);
    const uiPrice = toFixedTrunc(price, 2);
    const currency = offer.currency;
    const {
      discountsDifferencePrice,
      uiDiscountsDifferencePrice,
      oldPrice,
      uiOldPrice,
      offerDiscounts,
    } = this._calculateDiscountPrices(price, discounts);
    const hashrate = plan.pool_miner_config?.hash_rate;
    const pricePerMonth = price / plan.interval;
    const uiPricePerMonth = toFixedTrunc(pricePerMonth, 2);
    const pricePerMonthPerHashrate = hashrate
      ? (pricePerMonth / hashrate) * 1000
      : undefined;
    const uiPricePerMonthPerHashrate = pricePerMonthPerHashrate
      ? toFixedTrunc(pricePerMonthPerHashrate, 2)
      : undefined;
    return new Map([
      [
        PaymentMethod.InApp,
        {
          price,
          uiPrice,
          currency,
          oldPrice,
          uiOldPrice,
          uiUsdPrice: undefined,
          pricePerMonth,
          uiPricePerMonth,
          pricePerMonthPerHashrate,
          uiPricePerMonthPerHashrate,
          discountsDifferencePrice,
          uiDiscountsDifferencePrice,
          discounts: offerDiscounts,
        },
      ],
    ]);
  }

  private async _getPoolProductOffers(
    tariffs: Tariff[],
    discounts: Discount[],
  ): Promise<Either<MinerProductOffer[], GlobalError>> {
    const tariff = tariffs.find((_) => _.name === 'PoolMiner');
    if (!tariff) {
      return success([]);
    }
    const plans = tariff.plans ?? [];
    const poolProductPlans = plans.flatMap((_) => {
      if (_.type === PurchaseType.Product && _.product === PlanType.PoolMiner) {
        return [_];
      }
      return [];
    });
    const poolPurchasedId = OfferRequesterImpl._getSkus(poolProductPlans);
    const getProductOffers_ = await this._root.inAppPurchaseManager.getProducts(
      poolPurchasedId,
    );
    if (!getProductOffers_.success) {
      return error(getProductOffers_.left);
    }
    const productOffersById = new Map(
      getProductOffers_.right.map((_) => [_.purchaseId, _]),
    );
    const poolOffers = poolProductPlans.flatMap<MinerProductOffer>((plan) => {
      const offer = productOffersById.get(plan.id_on_platform);
      if (!offer || !plan.pool_miner_config) {
        return [];
      }
      return [
        {
          offerId: this._generateOfferId(plan),
          id: plan.id,
          id_on_platform: plan.id_on_platform,
          payments: this._createPaymentsMap(plan, offer, discounts),
          tariff: tariff.name,
          kind: PurchaseKind.Product,
          purchaseId: plan.id_on_platform,
          poolMinerConfig: plan.pool_miner_config,
          priority: plan.priority,
          interval: plan.interval,
          interval_type: plan.interval_type,
          type: OfferType.Miner,
          purchaseType: OfferPurchaseType.MinerProduct,
          subscriptionOfferTokenAndroid: undefined,
        },
      ];
    });
    return success(poolOffers);
  }

  private async _getPoolSubscriptionOffers(
    tariffs: Tariff[],
    purchasedSkus: PurchaseId[],
    discounts: Discount[],
  ): Promise<Either<MinerSubscriptionOffer[], GlobalError>> {
    const tariff = tariffs.find((_) => _.name === 'BASIC');
    if (!tariff) {
      return success([]);
    }
    const plans = tariff?.plans ?? [];
    const slotPlans = plans.flatMap((_) => {
      if (_.type === PurchaseType.Subscription && _.pool_miner_config) {
        return [_];
      }
      return [];
    });

    const slotPurchasedId = OfferRequesterImpl._getSkus(slotPlans);
    const getSubscriptionOffers_ =
      await this._root.inAppPurchaseManager.getSubscriptions(slotPurchasedId);
    if (!getSubscriptionOffers_.success) {
      return error(getSubscriptionOffers_.left);
    }

    const slotOfferById = new Map(
      getSubscriptionOffers_.right.map((_) => [_.purchaseId, _]),
    );

    const offers = slotPlans.flatMap<MinerSubscriptionOffer>((plan) => {
      const offer = slotOfferById.get(plan.id_on_platform);
      if (!offer || !plan.pool_miner_config) {
        return [];
      }
      return [
        {
          offerId: this._generateOfferId(plan),
          id: plan.id,
          id_on_platform: plan.id_on_platform,
          payments: this._createPaymentsMap(plan, offer, discounts),
          tariff: tariff.name,
          kind: PurchaseKind.Subscription,
          purchaseId: plan.id_on_platform,
          poolMinerConfig: plan.pool_miner_config,
          priority: plan.priority,
          interval: plan.interval,
          interval_type: plan.interval_type,
          type: OfferType.Miner,
          trial: offer.trial,
          purchaseType: OfferPurchaseType.MinerSubscription,
          bought: purchasedSkus.includes(plan.id_on_platform),
          subscriptionOfferTokenAndroid: offer.subscriptionOfferTokenAndroid,
        },
      ];
    });

    return success(offers);
  }

  private async _getPoolActivatorSubscriptionOffer(
    tariffs: Tariff[],
    purchasedSkus: PurchaseId[],
    discounts: Discount[],
  ): Promise<Either<ActivatorSubscriptionOffer[], GlobalError>> {
    const tariff = tariffs.find((_) => _.name === 'Activator');
    if (!tariff) {
      return success([]);
    }
    const plans = tariff?.plans ?? [];
    const activatorPlans = plans.flatMap((_) => {
      if (
        _.type === PurchaseType.Subscription &&
        !_.pool_miner_config &&
        _.options?.activate_pm_after !== null
      ) {
        return [_];
      }
      return [];
    });

    const skus = OfferRequesterImpl._getSkus(activatorPlans);
    const getSubscriptionOffers_ =
      await this._root.inAppPurchaseManager.getSubscriptions(skus);
    if (!getSubscriptionOffers_.success) {
      return error(getSubscriptionOffers_.left);
    }

    const slotOfferById = new Map(
      getSubscriptionOffers_.right.map((_) => [_.purchaseId, _]),
    );

    const offers = activatorPlans.flatMap<ActivatorSubscriptionOffer>(
      (plan) => {
        const offer = slotOfferById.get(plan.id_on_platform);
        if (!offer || !plan.options?.activate_pm_after) {
          return [];
        }
        return [
          {
            offerId: this._generateOfferId(plan),
            id: plan.id,
            id_on_platform: plan.id_on_platform,
            payments: this._createPaymentsMap(plan, offer, discounts),
            tariff: tariff.name,
            kind: PurchaseKind.Subscription,
            purchaseId: plan.id_on_platform,
            priority: plan.priority,
            interval: plan.interval,
            interval_type: plan.interval_type,
            type: OfferType.Activator,
            purchaseType: OfferPurchaseType.ActivatorSubscription,
            options: {
              activate_pm_after: plan.options.activate_pm_after,
              free_pm: plan.options.free_pm,
            },
            bought: purchasedSkus.includes(plan.id_on_platform),
            trial: offer.trial,
            subscriptionOfferTokenAndroid: offer.subscriptionOfferTokenAndroid,
          },
        ];
      },
    );

    return success(offers);
  }

  private async _getQuickMinerSubscriptionOffers(
    tariffs: Tariff[],
    purchasedSkus: PurchaseId[],
  ): Promise<Either<QuickMinerSubscriptionOffer[], GlobalError>> {
    const tariff = tariffs.find((_) => _.name === 'Trial');
    if (!tariff) {
      return success([]);
    }
    const plans = tariff?.plans ?? [];
    const trialPlans = plans.flatMap((_) => {
      if (_.type === PurchaseType.Subscription && _.pool_miner_config) {
        return [_];
      }
      return [];
    });

    const skus = OfferRequesterImpl._getSkus(trialPlans);
    const getSubscriptions_ =
      await this._root.inAppPurchaseManager.getSubscriptions(skus);
    if (!getSubscriptions_.success) {
      return error(getSubscriptions_.left);
    }

    const slotOfferById = new Map(
      getSubscriptions_.right.map((_) => [_.purchaseId, _]),
    );

    const offers = trialPlans.flatMap<QuickMinerSubscriptionOffer>((plan) => {
      const offer = slotOfferById.get(plan.id_on_platform);
      if (!offer || !plan.pool_miner_config) {
        return [];
      }
      return [
        {
          offerId: this._generateOfferId(plan),
          id: plan.id,
          id_on_platform: plan.id_on_platform,
          payments: this._createPaymentsMap(plan, offer, []),
          tariff: tariff.name,
          kind: PurchaseKind.Subscription,
          purchaseId: plan.id_on_platform,
          currency: offer.currency,
          priority: plan.priority,
          interval: plan.interval,
          interval_type: plan.interval_type,
          type: OfferType.Miner,
          purchaseType: OfferPurchaseType.TrialMinerSubscription,
          poolMinerConfig: plan.pool_miner_config,
          bought: purchasedSkus.includes(plan.id_on_platform),
          trial: offer.trial,
          subscriptionOfferTokenAndroid: offer.subscriptionOfferTokenAndroid,
        },
      ];
    });

    return success(offers);
  }

  private async _processTariffs(
    {tariffs}: GetTariffsResult,
    discounts: Discount[],
    purchasedSkus: PurchaseId[],
  ): Promise<Either<Offer[], GlobalError>> {
    const firstActivatorsDiscount = first(discounts);
    const activatorDiscounts = firstActivatorsDiscount
      ? [firstActivatorsDiscount]
      : [];
    const functions = [
      () => this._getPoolSubscriptionOffers(tariffs, purchasedSkus, discounts),
      () => this._getPoolProductOffers(tariffs, discounts),
      () => this._getPoolActivatorSubscriptionOffer(tariffs, purchasedSkus, activatorDiscounts),
      () => this._getQuickMinerSubscriptionOffers(tariffs, purchasedSkus),
    ];

    const results = [];

    for (const func of functions) {
      const result = await func();
      results.push(result);
    }

    const response = results.map((_) => (_.success ? _.right : [])).flat();

    return success(response);
  }

  async fetch(
    purchasedSkus: PurchaseId[],
  ): Promise<Either<Offer[], GlobalError>> {
    const code = this._root.purchaseDiscount.discount?.code;
    const tariffs_ = await this._fetchTariffs({code});
    if (!tariffs_.success) {
      return error(
        this._root.errorRepository.create<FetchOffersError>({
          kind: FETCH_OFFERS_ERROR,
          type: FETCH_OFFERS_SERVER_ERROR,
          raw: tariffs_.left,
        }),
      );
    }
    const discounts = tariffs_.right.discounts;
    const prepareOffers_ = await this._processTariffs(
      tariffs_.right,
      discounts,
      purchasedSkus,
    );

    if (!prepareOffers_.success) {
      return error(
        this._root.errorRepository.create<FetchOffersError>({
          kind: FETCH_OFFERS_ERROR,
          type: FETCH_OFFERS_STORE_ERROR,
          raw: prepareOffers_.left,
        }),
      );
    }
    if (prepareOffers_.right.length === 0) {
      return error(
        this._root.errorRepository.create<FetchOffersError>({
          kind: FETCH_OFFERS_ERROR,
          type: FETCH_OFFERS_NO_DATA_ERROR,
        }),
      );
    }

    return success(prepareOffers_.right);
  }
}
