import {LocationSource} from './LocationSource';
import {batchDisposers, BusImpl, Disposer, Service} from '../structure';
import {Either, error, success} from '../fp';
import {Url} from '../units';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import {CachedConsumableImpl} from '../CachedConsumable';
import {action, makeAutoObservable, observable, when} from 'mobx';
import {PREFIXES} from '../LinkingOptionsProvider/constant';
import {AppLifecycle} from '../AppLifecycle';

export default class DynamicLinksLocationSourceService
  implements LocationSource, Service
{
  private readonly _initialLink = new CachedConsumableImpl(() =>
    dynamicLinks().getInitialLink(),
  );

  @observable _collectionInProgress = false;
  private _hackedInitialLink?: Url;

  constructor(
    private readonly _root: {
      readonly appLifecycle: AppLifecycle;
    },
  ) {
    makeAutoObservable(this);
  }

  async getInitial(): Promise<Either<Url, unknown>> {
    try {
      await when(() => !this._collectionInProgress);
      if (this._hackedInitialLink) {
        return success(this._hackedInitialLink);
      }

      const link = await this._initialLink.getCachedConsumable();
      if (link) {
        return success(link.url as Url);
      }
      return error(undefined);
    } catch (raw) {
      return error(raw);
    }
  }

  public readonly updates = new BusImpl<Url>();

  private _splitByPrefix(
    locator: Url,
  ): [prefix: Url, rest: string] | undefined {
    for (const prefix of PREFIXES) {
      if (locator.startsWith(prefix)) {
        return [prefix as Url, locator.substring(prefix.length)];
      }
    }
    return undefined;
  }

  private _dropBrokenDynamicLink(_: Url): Url | undefined {
    const split = this._splitByPrefix(_);
    if (!split) {
      return _;
    }
    const [, rest] = split;
    if (/^\/*google\/+link/i.test(rest)) {
      return undefined;
    }
    return _;
  }

  private _runTimer = action(() => {
    if (!this._root.appLifecycle.hasJustBeenInstalled) {
      return;
    }
    this._collectionInProgress = true;
    const id = setTimeout(() => (this._collectionInProgress = false), 3000);
    return (() => clearTimeout(id)) as Disposer;
  });

  private _runCollection() {
    return dynamicLinks().onLink((link) => {
      const url = this._dropBrokenDynamicLink(link.url as Url);
      if (!url) {
        return;
      }
      if (this._collectionInProgress) {
        this._hackedInitialLink = url;
      }
      return this.updates.send(url);
    }) as Disposer;
  }

  subscribe() {
    return batchDisposers(this._runTimer(), this._runCollection());
  }
}
