import React, {useEffect} from 'react';
import {RootStackBindingProps} from './RootStackBindingProps';
import {observer} from 'mobx-react-lite';
import {NotificationsSettingsScreen} from '../../NotificationsSettingsScreen';
import {
  useNavigationGetIsFocused,
  useNavigationGetIsTransitioning,
} from '../hooks';
import {useRoot} from '../../Root/hooks';
import {autorun} from 'mobx';
import {useErrorHandler} from '../../ExceptionHandler';

export type NotificationsSettingsBindingProps =
  RootStackBindingProps<'NotificationsSettings'>;

export default observer(function NotificationsSettingsBinding(
  props: NotificationsSettingsBindingProps,
) {
  const {navigation} = props;
  const {notificationSettingsState} = useRoot();
  const {fetchLogSettings, isLoading, isLoadedIn, state, tree, reset} =
    notificationSettingsState;
  const getIsTransitioning = useNavigationGetIsTransitioning(navigation);
  const getIsFocused = useNavigationGetIsFocused();
  const errorHandler = useErrorHandler();
  useEffect(
    () =>
      autorun(() => {
        if (getIsTransitioning() || !getIsFocused()) {
          return;
        }
        fetchLogSettings().then(
          (res) => !res.success && errorHandler.handle(res.left),
        );
      }),
    [fetchLogSettings, getIsTransitioning, getIsFocused, errorHandler],
  );
  useEffect(() => reset, [reset]);
  return (
    <NotificationsSettingsScreen
      isLoadedIn={isLoadedIn}
      isLoading={isLoading}
      state={state}
      tree={tree}
    />
  );
});
