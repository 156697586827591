import React, {useMemo} from 'react';
import {View} from 'react-native';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import {useOpenLink} from '../Links';
import {useRoot, useStrings} from '../Root/hooks';
import {useTheme} from '../styling';
import {observer} from 'mobx-react-lite';
import {useOpenStoreManageSubscription} from '../CurrentSubscription';

export type SubMenuListProps = {
  goToDeleteAccount: () => void;
};

export default observer(function SubMenuList({
  goToDeleteAccount,
}: SubMenuListProps) {
  const {rateApp, configuration} = useRoot();
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        menuContainer: {
          flex: 1,
          marginTop: -10,
          paddingLeft: 15,
          flexDirection: 'row',
          flexWrap: 'wrap',
          ...theme.mediaQuery({
            [768]: {
              marginTop: 0,
            },
          }),
        },
      } as const),
    [theme],
  );
  const strings = useStrings();
  const openStoreManageSubscription = useOpenStoreManageSubscription();
  const {
    values: {aboutUrl},
  } = configuration;
  const openTermsOfUse = useOpenLink(`${aboutUrl}/terms/`);
  const openPrivacyPolicy = useOpenLink(`${aboutUrl}/privacy/`);
  const openSupport = useOpenLink(`${aboutUrl}/feedback/`);
  return (
    <View style={styles.menuContainer}>
      <SecondaryMenuItem
        text={strings['menu.manageSubscription']}
        onPress={openStoreManageSubscription}
      />
      <SecondaryMenuItem
        text={strings['menu.termsOfUse']}
        onPress={openTermsOfUse}
      />
      <SecondaryMenuItem
        text={strings['menu.privacyPolicy']}
        onPress={openPrivacyPolicy}
      />
      <SecondaryMenuItem text={strings['menu.support']} onPress={openSupport} />
      <SecondaryMenuItem
        text={strings['menu.rate']}
        onPress={rateApp.openModal}
      />
      <SecondaryMenuItem
        text={strings['menu.removeAccount']}
        onPress={goToDeleteAccount}
      />
    </View>
  );
});
