import {observer} from 'mobx-react-lite';
import Svg, {Defs, LinearGradient, Path, Rect, Stop} from 'react-native-svg';
import React, {useRef} from 'react';
import {nanoid} from 'nanoid';
import {IconProps} from './IconProps';
import useColors from './useColors';

export default observer(function DashboardIcon({active, ...rest}: IconProps) {
  const id = useRef(nanoid());
  const {accent, second} = useColors(`url(#${id.current})`, active);
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56036 1.68426C10.5835 0.105244 13.4165 0.105247 15.4396 1.68426L21.3371 6.28708C22.7018 7.3522 23.5 8.98993 23.5 10.7248V17.878C23.5 20.9829 20.9916 23.5 17.8974 23.5H6.10256C3.00835 23.5 0.5 20.9829 0.5 17.878V10.7248C0.5 8.98993 1.29821 7.3522 2.66292 6.28708L8.56036 1.68426ZM7.64014 14.7645C7.5 15.1189 7.5 15.5656 7.5 16.459C7.5 17.3523 7.5 17.799 7.64014 18.1534C7.84344 18.6675 8.25044 19.0745 8.76452 19.2778C9.11891 19.4179 9.56559 19.4179 10.459 19.4179H12.7975C13.6908 19.4179 14.1375 19.4179 14.4919 19.2778C15.006 19.0745 15.413 18.6675 15.6163 18.1534C15.7564 17.799 15.7564 17.3523 15.7564 16.459C15.7564 15.5656 15.7564 15.1189 15.6163 14.7645C15.413 14.2504 15.006 13.8434 14.4919 13.6401C14.1375 13.5 13.6908 13.5 12.7975 13.5H10.459C9.56559 13.5 9.11891 13.5 8.76452 13.6401C8.25044 13.8434 7.84344 14.2504 7.64014 14.7645Z"
        fill={accent}
      />
      <Rect x="7.5" y="13.5" width="9" height="5.9179" rx="2" fill={second} />
      <Defs>
        <LinearGradient
          id={id.current}
          x1="-23.155"
          y1="9.3298"
          x2="8.44085"
          y2="39.1478"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F7C61A" />
          <Stop offset="1" stopColor="#F7931A" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
});
