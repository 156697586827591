import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../../../styling';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {View} from 'react-native';
import {Header} from '../../../Typography';
import {sized} from '../../../../Svg';
import StarPercentSvg from '../../../../assets/svg/colorless/starPercent.svg';
import DarkStarPercentSvg from '../../../../assets/svg/colored/darkStartPercent.svg';
import {LG_BREAKPOINT} from '../../../../WindowDimensions/useDimensions';

export type SavedMessageProps = {
  formattedSaveSum: string;
  currency: string;
};

export default observer(function SavedMessage({
  formattedSaveSum,
  currency,
}: SavedMessageProps) {
  const theme = useTheme();
  const {appearance} = useRoot();
  const strings = useStrings();
  return (
    <SaveMessageView>
      {appearance.isDark ? (
        <DarkStarPercentIcon />
      ) : (
        <StarPercentIcon color={theme.colors.uiSecondary} />
      )}
      <Label>
        <SaveMessageLabel type="header" size="h5" weight="500">
          {strings['purchase.economy']}:
        </SaveMessageLabel>
        <SaveMessageLabel red type="header" size="h5" weight="500">
          {formattedSaveSum} {currency}
        </SaveMessageLabel>
      </Label>
    </SaveMessageView>
  );
});

const StarPercentIcon = sized(StarPercentSvg, 20, 20);
const DarkStarPercentIcon = sized(DarkStarPercentSvg, 20, 20);
const SaveMessageView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 26,
        justifyContent: 'center',
      },
    }),
  },
}));

const Label = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  },
}));

const SaveMessageLabel = variance(Header)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    marginLeft: 3,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  },

  red: {
    color: theme.colors.primaryError,
  },
}));
