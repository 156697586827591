import React, {ReactNode} from 'react';
import {ScrollView, Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles, variance} from '../styling';
import {useTemplates} from '../Root/hooks';
import {PurchaseNotAvailable} from './PurchaseNotAvailable';
import {getCommissionerFontStyles, Header} from '../components';
import YellowFlashSvg from '../assets/svg/colored/yellowFlash.svg';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import RenewAccessScreenContext, {RenewAccessScreenProps} from './context';
import TotalPriceBinding from './TotalPriceBinding';
import MinerTableBinding from './MinerTableBinding';
import {sized} from '../Svg';
import Markdown from '../components/molecules/Markdown';

export default observer(function RenewAccessScreen(
  props: RenewAccessScreenProps,
) {
  const {worker, goToPurchase, purchaseStoreError} = props;
  const rules = {
    em: (key: number, children: ReactNode) => {
      return (
        <Text key={key} onPress={goToPurchase}>
          {children}
        </Text>
      );
    },
  };
  const styles = useStyles((theme) => ({
    paragraph: {
      ...getCommissionerFontStyles('500'),
      textAlign: 'center',
      color: theme.colors.primaryUIDirtyBlue,
      fontSize: 12,
      lineHeight: 16,
      marginTop: 0,
      marginBottom: 12,
      width: '100%',
    },
    textEm: {
      color: theme.colors.primaryActive,
      fontStyle: 'normal',
    },
  }));
  const templates = useTemplates();
  const text = templates['renewAccess.description']({
    hashrate: worker?.pool_miner_config?.hash_rate,
  });
  return (
    <RenewAccessScreenContext.Provider value={props}>
      {purchaseStoreError ? (
        <PurchaseNotAvailable />
      ) : (
        <RootScrollView>
          <ContentView>
            <HeaderView>
              <HeaderLeftView>
                <TopView>
                  <YellowFlashView>
                    <YellowFlashIcon />
                  </YellowFlashView>
                  <Header type="header" size="h4">
                    {worker.name}
                  </Header>
                </TopView>
                <Markdown
                  rules={rules}
                  styles={{
                    paragraph: styles.paragraph,
                    em: styles.textEm,
                  }}>
                  {text}
                </Markdown>
              </HeaderLeftView>
            </HeaderView>
            <MinerTableContainerView>
              <MinerTableBinding />
            </MinerTableContainerView>
          </ContentView>
          <TotalPriceContainerView>
            <TotalPriceBinding />
          </TotalPriceContainerView>
        </RootScrollView>
      )}
    </RenewAccessScreenContext.Provider>
  );
});

const YellowFlashIcon = sized(YellowFlashSvg, 24);

const RootScrollView = variance(ScrollView)(
  (theme) => ({
    root: {},
    container: {
      flexGrow: 1,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          flexGrow: undefined,
          paddingHorizontal: 16,
        },
      }),
    },
  }),
  (_, styles) => ({
    style: styles.root,
    contentContainerStyle: styles.container,
  }),
);

const ContentView = variance(View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.colors.uiMain,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
  },
}));

const HeaderView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    }),
  },
}));

const HeaderLeftView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 2,
        paddingHorizontal: 16,
      },
    }),
  },
}));

const MinerTableContainerView = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
}));

const TopView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
  },
}));

const TotalPriceContainerView = variance(View)((theme) => ({
  root: {
    marginTop: 'auto',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: undefined,
      },
    }),
  },
}));

const YellowFlashView = variance(View)(() => ({
  root: {
    marginHorizontal: 6,
  },
}));
