import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {PickDashboardGroupSortModal} from '../../PickDashboardGroupSortModal';
import {AdaptiveModalRef} from '../../components/templates';
import {useRoot} from '../../Root/hooks';
import {useSafelyGoBack} from '../hooks';
import {WorkerOrder} from '../../Dashboard';

export default observer(function PickDashboardSortBinding() {
  const safelyGoBack = useSafelyGoBack();
  const {dashboardService} = useRoot();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const closeModal = useCallback(() => modalRef.current?.close(), []);
  const onModalClosed = useCallback(() => safelyGoBack(), [safelyGoBack]);
  const onSubmit = useCallback(
    async (order: WorkerOrder | undefined) => {
      await closeModal();
      if (order === undefined) {
        safelyGoBack();
        return;
      }
      dashboardService.setOrder(order);
      safelyGoBack();
    },
    [closeModal, dashboardService, safelyGoBack],
  );
  return (
    <PickDashboardGroupSortModal
      ref={modalRef}
      onModalClosed={onModalClosed}
      onSubmit={onSubmit}
      initialValue={dashboardService.getOrder()}
    />
  );
});
