import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {getCommissionerFontStyles} from '../components';
import {StyleSheet, View} from 'react-native';
import {useStyles} from '../styling';
import WorkersList from './WorkersList';
import {useStrings} from '../Root/hooks';
import ScheduleWorkersModalState from './ScheduleWorkersModalState';
import {AppButton, ButtonVariant} from '../components/AppButton';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import Markdown from '../components/molecules/Markdown';

export type PickScheduleWorkersModalProps = {
  onModalClosed: () => void;
  onClosePress: () => void;
  state: ScheduleWorkersModalState;
  onWorkerSelect: (workerId: string) => void;
};

export default observer<PickScheduleWorkersModalProps, AdaptiveModalRef>(
  forwardRef(function PickScheduleWorkersModal(props, ref) {
    const {onModalClosed} = props;
    return (
      <AdaptiveModal
        ref={ref}
        onModalClosed={onModalClosed}
        modalContentStyle={styles.modalContent}
        bottomSheetSnapPoints={['90%']}
        modalWidth={ModalWidth.Small}>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  ({state, onClosePress, onWorkerSelect}: PickScheduleWorkersModalProps) => {
    const strings = useStrings();
    const applyText = strings['scheduler.applyText']
      .replace(
        '{numberOfSelected}',
        String([...state.selectedWorkerIds].length),
      )
      .replace('{totalAmount}', String(state.workers.length));
    const themedStyles = useStyles((theme) => ({
      bold: {
        ...getCommissionerFontStyles('700'),
        fontSize: 16,
      },
      paragraph: {
        ...getCommissionerFontStyles('400'),
        color: theme.colors.uiSecondary,
        letterSpacing: 0.04,
        fontSize: 16,
        marginTop: 0,
        marginBottom: 0,
      },
    }));
    const paddingBottom = useSafePaddingBottom(16);
    return (
      <View style={[styles.root, {paddingBottom}]}>
        <View style={styles.top}>
          <Markdown
            styles={{
              strong: themedStyles.bold,
              paragraph: themedStyles.paragraph,
            }}>
            {applyText}
          </Markdown>
        </View>
        <WorkersList
          workers={state.workers}
          userTimezoneMinutes={state.userTimezoneMinutes}
          selectedWorkerIdList={[...state.selectedWorkerIds]}
          onWorkerSelect={onWorkerSelect}
        />
        <View style={styles.footer}>
          <AppButton variant={ButtonVariant.Primary} onPress={onClosePress}>
            {strings['workerGroup.hideList']}
          </AppButton>
        </View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  modalContent: {
    flex: 1,
  },
  top: {
    paddingTop: 30,
    paddingBottom: 20,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  footer: {
    paddingTop: 30,
    paddingHorizontal: 16,
  },
});
