import {observer} from 'mobx-react-lite';
import React from 'react';
import {View, ViewProps} from 'react-native';
import {LogLevel, LogMessage} from '../ApiStore';
import {useStyles} from '../styling';
import BtcDarkSvg from '../assets/svg/colored/btcDark.svg';
import ChangeSvg from '../assets/svg/colored/change.svg';
import MinerSvg from '../assets/svg/colored/miner.svg';
import GreenStar from '../assets/svg/colored/greenStar.svg';
import ErrorSvg from '../assets/svg/colored/error.svg';
import CalendarDark from '../assets/svg/colored/calendarDark.svg';
import {sized} from '../Svg';
import {useRoot} from '../Root/hooks';

export type NotificationLevelCircleProps = ViewProps & {
  level: LogLevel;
  message: LogMessage;
};

export default observer((props: NotificationLevelCircleProps) => {
  const {level, message, style: style_, ...rest} = props;
  const {
    appTemplateState: {isTablet},
  } = useRoot();
  const styles = useStyles((theme) => ({
    root: {
      width: 36,
      height: 36,
      borderRadius: 36,
      backgroundColor: theme.colors.backgroundGround,
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      height: 36,
    },
    largeContainer: {
      flex: 1,
      paddingHorizontal: 15,
    },
    debugRoot: {backgroundColor: theme.colors.primaryAttractive},
    infoRoot: {backgroundColor: theme.colors.primaryActive},
    warningRoot: {backgroundColor: theme.colors.primaryNew},
    errorRoot: {backgroundColor: theme.colors.primaryError},
    criticalRoot: {backgroundColor: theme.colors.primaryError},
    green: {backgroundColor: '#67C76B'},
    gray: {backgroundColor: theme.colors.uiAdditional1},
  }));

  const style = [styles.container, isTablet && styles.largeContainer];

  if (message === 'subscription_active') {
    return (
      <View style={style} {...rest}>
        <View style={[styles.root, styles.green]}>
          <GreenStarIcon />
        </View>
      </View>
    );
  }

  if (message === 'subscription_inactive') {
    return (
      <View style={style} {...rest}>
        <View style={[styles.root, styles.infoRoot]}>
          <CalendarIcon />
        </View>
      </View>
    );
  }

  if (message === 'subscription_changed') {
    return (
      <View style={style} {...rest}>
        <View style={[styles.root, styles.gray]}>
          <ChangeIcon />
        </View>
      </View>
    );
  }

  return (
    <View style={style} {...rest}>
      <View
        style={[
          styles.root,
          level === LogLevel.Debug && styles.debugRoot,
          level === LogLevel.Info && styles.infoRoot,
          level === LogLevel.Warning && styles.warningRoot,
          level === LogLevel.Error && styles.errorRoot,
          level === LogLevel.Critical && styles.criticalRoot,
          style_,
        ]}>
        {message.includes('withdrawal') ? (
          <BtcDarkIcon />
        ) : message.includes('miner') ? (
          <MinerIcon />
        ) : (
          <ErrorIcon />
        )}
      </View>
    </View>
  );
});

const BtcDarkIcon = sized(BtcDarkSvg, 12, 20);
const ChangeIcon = sized(ChangeSvg, 24);
const MinerIcon = sized(MinerSvg, 24);
const GreenStarIcon = sized(GreenStar, 24);
const ErrorIcon = sized(ErrorSvg, 24);
const CalendarIcon = sized(CalendarDark, 24);
