import {ErrorNormalizer, SimpleError, UNKNOWN_ERROR} from './ErrorNormalizer';

export default class ErrorNormalizerImpl implements ErrorNormalizer {
  normalize(_: any): SimpleError {
    if (isMetamaskError(_)) {
      return {
        raw: _,
        message: _.message,
        code: _.code,
      };
    } else if (isEthersError(_)) {
      return {
        raw: _,
        message: _.error.message,
        code: _.error.code,
      };
    }
    return Object.assign({}, UNKNOWN_ERROR, {raw: _});
  }
}

const isMetamaskError = (_: any): _ is MetamaskError =>
  typeof _ === 'object' &&
  _ !== null &&
  typeof _.code === 'number' &&
  typeof _.message === 'string';

const isEthersError = (_: any): _ is EthersError =>
  typeof _ === 'object' &&
  _ !== null &&
  typeof _.error === 'object' &&
  _.error !== null &&
  typeof _.error.code === 'number' &&
  typeof _.error.message === 'string';

type MetamaskError = {
  code: number;
  message: string;
};
type EthersError = {
  error: {
    code: number;
    message: string;
  };
};
