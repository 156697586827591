import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useResetToDashboard, useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot, useStrings} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {
  DeleteRenewWorkerConfirmModal,
  DeleteWorkerConfirmModal,
} from '../../DeleteWorkerConfirmModal';
import {useErrorHandler} from '../../ExceptionHandler';

export type DeleteWorkerConfirmBindingProps =
  RootStackBindingProps<'DeleteWorkerConfirm'>;

export default observer(function DeleteWorkerConfirmBinding({
  navigation,
  route,
}: DeleteWorkerConfirmBindingProps) {
  const {dashboardStore, connectedClient, flashMessages} = useRoot();
  const workerId = route.params?.workerId;
  const withRenew = !!route.params?.withRenew;
  const forceNavigateRoot = !!route.params?.forceNavigateRoot;
  const worker = expr(() =>
    workerId !== undefined ? dashboardStore.workers.get(workerId) : undefined,
  );
  const safelyGoBack = useSafelyGoBack();
  const close = useCallback(() => modalRef.current?.close(), []);
  const onCancel = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);
  const errorHandler = useErrorHandler();
  const strings = useStrings();
  const resetToDashboard = useResetToDashboard();

  const onConfirm = useCallback(async () => {
    await close();
    forceNavigateRoot ? resetToDashboard() : safelyGoBack();
    if (!worker) {
      return;
    }

    const result = await connectedClient.call('delete_worker', {
      worker_id: worker.id,
    });

    if (!result.success) {
      errorHandler.handle(result.left);
      return;
    }

    flashMessages.showMessage({
      title: strings['action.workerHasBeenDeleted'],
      variant: 'danger',
    });
  }, [
    strings,
    worker,
    connectedClient,
    close,
    flashMessages,
    forceNavigateRoot,
    safelyGoBack,
    errorHandler,
    resetToDashboard,
  ]);
  const modalRef = useRef<AdaptiveModalRef>(null);

  const handleRenew = useCallback(async () => {
    if (!worker) {
      return null;
    }

    await close();
    navigation.replace('RenewAccess', {workerId: worker.id});
  }, [navigation, close, worker]);

  return withRenew ? (
    <DeleteRenewWorkerConfirmModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onRenew={handleRenew}
      onConfirm={onConfirm}
      onCancel={onCancel}
      worker={worker}
    />
  ) : (
    <DeleteWorkerConfirmModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onConfirm={onConfirm}
      onCancel={onCancel}
      worker={worker}
    />
  );
});
