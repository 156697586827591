import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import {NotAllowedModal} from '../NotAllowedModal';
import {BasicErrorModal} from '../BasicErrorModal';

export default observer(function ErrorModalProvider() {
  const {notAllowedModalState, basicErrorModalState} = useRoot();

  return (
    <>
      {notAllowedModalState.isVisible && (
        <NotAllowedModal
          description={notAllowedModalState.description}
          onClose={notAllowedModalState.reset}
        />
      )}
      {basicErrorModalState.isVisible && (
        <BasicErrorModal
          title={basicErrorModalState.title}
          description={basicErrorModalState.description}
          onClose={basicErrorModalState.reset}
        />
      )}
    </>
  );
});
