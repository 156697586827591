import {observer} from 'mobx-react-lite';
import React from 'react';
import ChangeModeSection from '../../components/ChangeModeSection';
import {variance} from '../../../styling';
import {View} from 'react-native';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';

export default observer(function SimpleListFooter() {
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  return (
    <RootView>
      <ChangeModeSection isLarge={isLarge} />
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {
    paddingTop: 15,
    paddingBottom: 30,
  },
}));
