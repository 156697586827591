import * as ExpoFileSystem from 'expo-file-system';
import {StorageAccessFramework} from 'expo-file-system';

import {FileSystem} from './FileSystem';

export default class AndroidFileSystemImpl implements FileSystem {
  async isGranted() {
    const permissions_ =
        await StorageAccessFramework.requestDirectoryPermissionsAsync();
    return permissions_.granted;
  }

  async save(
    content: string,
    filename: string,
    mimeType: string,
  ): Promise<void> {
    const permissions_ =
      await StorageAccessFramework.requestDirectoryPermissionsAsync();
    if (!permissions_.granted) {
      return;
    }
    const uri = await StorageAccessFramework.createFileAsync(
      permissions_.directoryUri,
      filename,
      mimeType,
    );
    await ExpoFileSystem.writeAsStringAsync(uri, content, {encoding: 'utf8'});
  }
}
