import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {PickScheduleTimezoneModal} from '../../PickScheduleTimezoneModal';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';

export type PickScheduleTimezoneBindingProps =
  RootStackBindingProps<'PickScheduleTimezone'> & {};

export default observer(function PickScheduleTimezoneBinding({
  navigation,
  route,
}: PickScheduleTimezoneBindingProps) {
  const nextParams = route.params?.nextParams;
  const modalRef = useRef<AdaptiveModalRef>(null);
  const safelyGoBack = useSafelyGoBack();

  const closeModal = useCallback(() => modalRef.current?.close(), []);

  const closeAndBack = useCallback(async () => {
    await closeModal();
    safelyGoBack();
  }, [closeModal, safelyGoBack]);

  const selectTimezone = useCallback(
    async (offset: number) => {
      await closeModal();
      if (nextParams === undefined) {
        return safelyGoBack();
      }
      navigation.navigate('Schedule', {
        ...nextParams,
        pickedTimezone: offset,
      });
    },
    [closeModal, navigation, nextParams, safelyGoBack],
  );

  return (
    <PickScheduleTimezoneModal
      ref={modalRef}
      onTimezoneSelect={selectTimezone}
      onModalClosed={safelyGoBack}
      onClosePress={closeAndBack}
    />
  );
});
