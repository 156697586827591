import {ScrollView, ViewProps} from 'react-native';
import React from 'react';
import {observer} from 'mobx-react-lite';

import {variance} from '../styling';

type TitleViewProps = ViewProps & {
  title?: string;
  titleRight?: React.ReactNode;
};

export default observer(({titleRight, style, ...rest}: TitleViewProps) => {
  return (
    <Container horizontal contentContainerStyle={style} {...rest}>
      {titleRight}
    </Container>
  );
});

const Container = variance(ScrollView)(() => ({
  root: {
    flexDirection: 'row',
  },
}));
