import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {variance} from '../../styling';
import {PressableOpacity, Tooltip, Typography} from '../../components';
import Switch from '../../components/Switch';
import {Mode} from '../../DashboardMode';
import {sized} from '../../Svg';
import HelpSvg from '../../assets/svg/colored/help.svg';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';
import {useStrings} from '../../Root/hooks';
import {useToggle} from '../../hooks';

export type ChangeModeSectionProps = {
  isLarge: boolean;
  isSmall?: boolean;
};

export default observer(function ChangeModeSection({
  isLarge,
  isSmall,
}: ChangeModeSectionProps) {
  const strings = useStrings();
  const [visibleTooltip, toggleVisible] = useToggle(false);
  const {changeDashboardMode, dashboardMode} =
    useForcedContext(DashboardContext);
  const onValueChange = (checked: boolean) =>
    changeDashboardMode(checked ? Mode.Regular : Mode.Simple);
  return (
    <Container small={isSmall}>
      <RootView large={isLarge} small={isSmall}>
        <InfoView small={isSmall}>
          <LabelText small={isSmall} type="body" weight="500">
            {strings['dashboard.proMode.label']}
          </LabelText>
          <HelpIconButton onPress={toggleVisible}>
            <HelpIcon />
          </HelpIconButton>
        </InfoView>
        <Switch
          value={dashboardMode === Mode.Regular}
          onValueChange={onValueChange}
        />
      </RootView>
      {visibleTooltip && (
        <Tooltip
          text={strings['dashboardSettings.proMode.description']}
          onPress={toggleVisible}
        />
      )}
    </Container>
  );
});

const HelpIcon = sized(HelpSvg, 20);

const Container = variance(View)(() => ({
  root: {},
  small: {
    marginLeft: 'auto',
    marginRight: 32,
    zIndex: 2,
    elevation: 2,
  },
}));

const RootView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    paddingVertical: 10,
    paddingHorizontal: 12,
    backgroundColor: theme.colors.uiMain,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  large: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderRadius: 12,
  },
  small: {
    borderRadius: 12,
    paddingVertical: 4,
    paddingLeft: 12,
    borderWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingRight: 4,
    height: 'auto',
  },
}));

const LabelText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
  },
  small: {
    fontSize: 16,
  },
}));

const HelpIconButton = variance(PressableOpacity)(() => ({
  root: {
    padding: 5,
    borderRadius: 10,
    marginLeft: 5,
    marginTop: 2,
  },
}));

const InfoView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  small: {
    marginRight: 24,
  },
}));
