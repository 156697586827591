import {observer} from 'mobx-react-lite';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useStrings} from '../../Root/hooks';
import {WorkerType} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {createStylesHook, useTheme} from '../../styling';
import minutesToHourMinutes from '../minutesToHourMinutes';
import {WorkerList} from '../types';
import {sized} from '../../Svg';
import CaretDownSvg from '../../assets/svg/colorless/caretDown.svg';
import {getCommissionerFontStyles, Typography} from '../../components';
import {LG_BREAKPOINT} from '../../WindowDimensions/useDimensions';
import Markdown from '../../components/molecules/Markdown';

export type ScheduleGroupWorkersListProps = {
  workers: WorkerList;
  selectedWorkerIdList: string[];
  onWorkerSelect: () => void;
  timezoneOffset: number;
};

export const WorkersList = observer(
  ({
    workers,
    timezoneOffset,
    selectedWorkerIdList,
    onWorkerSelect,
  }: ScheduleGroupWorkersListProps) => {
    const strings = useStrings();
    const styles = useStyles();
    const theme = useTheme();
    const workersTimezones = workers.map((worker) => {
      const differentTimezoneStr = (timezoneOffset - worker.utc_offset) / 60;
      const showTimezone =
        timezoneOffset !== worker.utc_offset &&
        worker.worker_type === WorkerType.Regular;
      return {
        timezone: worker.utc_offset / 60,
        timezoneStr: minutesToHourMinutes(worker.utc_offset),
        differentTimezoneStr:
          differentTimezoneStr < 0
            ? '+' + Math.abs(differentTimezoneStr)
            : '-' + Math.abs(differentTimezoneStr),
        showTimezone: showTimezone,
      };
    });
    const differentTimezoneCount = workersTimezones.filter(
      (wt) => wt.showTimezone,
    ).length;

    const showDifferentTimezone = differentTimezoneCount !== 0;

    const headerText =
      differentTimezoneCount > 1
        ? strings['scheduler.workersHaveADifferentTimezone'].replace(
            '{count}',
            String(differentTimezoneCount),
          )
        : `${differentTimezoneCount} ${strings['scheduler.workerHaveADifferentTimezone']}.`;

    const applyText = strings['scheduler.applyText']
      .replace('{numberOfSelected}', String(selectedWorkerIdList.length))
      .replace('{totalAmount}', String(workers.length));

    return (
      <View style={styles.root}>
        <View style={styles.content}>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.openButton}
            onPress={onWorkerSelect}>
            <Markdown
              styles={{
                strong: styles.bold,
                paragraph: styles.paragraph,
              }}>
              {applyText}
            </Markdown>
            <CaretDownIcon
              style={styles.closeIcon}
              color={theme.colors.primaryUIDirtyBlue}
            />
          </TouchableOpacity>
          <View style={styles.headerView}>
            {showDifferentTimezone && (
              <Typography
                type={'paragraph'}
                size={'large'}
                weight={'500'}
                style={styles.headerText}>
                {headerText}
              </Typography>
            )}
          </View>
        </View>
      </View>
    );
  },
);

const CaretDownIcon = sized(CaretDownSvg, 10);

const useStyles = createStylesHook((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
  },
  headerView: {
    marginVertical: 10,
  },
  headerText: {
    marginTop: 5,
    color: theme.colors.primaryError,
  },
  content: {},
  openButton: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 360,
      },
    }),
    paddingVertical: 10,
    paddingLeft: 18,
    paddingRight: 20,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.colors.uiAdditional2,
    backgroundColor: theme.colors.uiMain,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 18,
  },
  bold: {
    ...getCommissionerFontStyles('700'),
    fontSize: 14,
  },
  paragraph: {
    ...getCommissionerFontStyles('400'),
    color: theme.colors.uiSecondary,
    letterSpacing: 0.04,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
  },
}));
