import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import {
  LinkingOptions,
  NavigationContainer,
  ParamListBase,
} from '@react-navigation/native';
import {useRoot, useStrings, useTemplates} from '../Root/hooks';
import {RootStack} from './RootStack';
import {AuthStack} from './AuthStack';
import {CannonBinding} from '../CannonBinding';
import {NoConnectionContainer} from '../NoConnectionContainer';
import {useGetNavigationTheme} from './hooks';
import {DocumentTitleOptions} from '@react-navigation/native/lib/typescript/src/types';
import {PREFIXES} from '../LinkingOptionsProvider/constant';

export default observer(() => {
  const {auth, navigationContainerBinding, linkingOptionsProvider} = useRoot();
  const navigationTheme = useGetNavigationTheme();
  const templates = useTemplates();
  const strings = useStrings();
  const documentTitle: DocumentTitleOptions = useMemo(
    () => ({
      formatter: (options) =>
        options?.title
          ? templates['page.webTitle']({
              title: options?.title,
            })
          : strings['page.defaultWebTitle'],
    }),
    [strings, templates],
  );
  return (
    <View style={styles.root}>
      <NavigationContainer
        theme={navigationTheme}
        documentTitle={documentTitle}
        linking={linkingOptionsProvider.linkingOptions}
        {...navigationContainerBinding.props}>
        {auth.initialized && <RootStack />}
      </NavigationContainer>
      <View style={styles.layer}>
        <NavigationContainer
          theme={navigationTheme}
          documentTitle={documentTitle}
          linking={authLinkingOptions}
          independent>
          <AuthStack />
        </NavigationContainer>
      </View>
      <View style={styles.layer}>
        <NoConnectionContainer />
      </View>
      <View style={styles.layer}>
        <CannonBinding />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  layer: {
    backgroundColor: 'transparent',
    zIndex: 2,
    ...StyleSheet.absoluteFillObject,
    pointerEvents: 'box-none',
  },
});

const authLinkingOptions: LinkingOptions<ParamListBase> = {
  enabled: false,
  prefixes: PREFIXES,
};
