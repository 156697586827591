import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useStrings} from '../../Root/hooks';
import useWorkerStatuses from './useWorkerStatuses';

export default function useHugeText(worker: ReadonlyDeep<WorkerEntity>) {
  const {offline, expired, needActivate} = useWorkerStatuses(worker);
  const strings = useStrings();
  if (expired) {
    return strings['worker.expired'];
  } else if (needActivate) {
    return strings['worker.inactive'];
  } else if (offline) {
    return strings['worker.offline'];
  }
  return 'Huge Page';
}
