import React from 'react';
import {observer} from 'mobx-react-lite';
import {Pressable, View} from 'react-native';
import {Typography} from '../components';
import {variance} from '../styling';
import {Divider} from '../components/atoms';
import MetamaskSvg from '../assets/svg/colored/metamask.svg';
import CoinbaseSvg from '../assets/svg/colored/coinbase.svg';
import NCWalletSvg from '../assets/svg/colored/ncwallet.svg';
import {Wallet, WalletKind} from '../Navigation/RootStack/ConnectWalletBinding';

const matchWalletToIcon = (wallet: Wallet) => {
  switch (wallet.id) {
    case WalletKind.Metamask:
      return <MetamaskSvg />;
    case WalletKind.Coinbase:
      return <CoinbaseSvg />;
    case WalletKind.NCWallet:
      return <NCWalletSvg />;
  }
};

export type WalletListItemProps = {
  wallet: Wallet;
  isActive: boolean;
  onPress: () => void;
};

export default observer(function WalletListItem(props: WalletListItemProps) {
  const {wallet, isActive, onPress} = props;
  const walletIcon = matchWalletToIcon(wallet);

  return (
    <>
      <WalletItem onPress={onPress}>
        <WalletItemIcon>{walletIcon}</WalletItemIcon>
        <Typography type={'header'} size={'h4'}>
          {wallet.name}
        </Typography>
        <RadioCircle isActive={isActive} />
      </WalletItem>
      <Divider />
    </>
  );
});

const RadioCircle = observer(({isActive}: {isActive: boolean}) => {
  return (
    <RadioCircleView active={isActive}>
      <RadioCircleDot active={isActive} />
    </RadioCircleView>
  );
});

const WalletItem = variance(Pressable)(() => ({
  root: {
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const WalletItemIcon = variance(View)(() => ({
  root: {
    marginRight: 10,
  },
}));

const RadioCircleView = variance(View)((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 14,
    height: 14,
    marginLeft: 'auto',
    borderRadius: 14,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional2,
  },
  active: {
    borderColor: theme.colors.primaryAttractive,
  },
}));

const RadioCircleDot = variance(View)((theme) => ({
  root: {
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.colors.uiMain,
  },
  active: {
    backgroundColor: theme.colors.primaryAttractive,
  },
}));
