import React, {forwardRef, useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import FolderSvg from '../assets/svg/twoTone/folder.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {TwoToneIcon} from '../components/atoms';
import {SvgProps} from 'react-native-svg';
import {AdaptiveModalRef} from '../components/templates';
import {sized} from '../Svg';
import {AdaptiveInputModal} from '../components/organisms';
import {useStrings} from '../Root/hooks';
import TextInputState from '../forms/TextInputState';

export type RenameWorkerModalProps = {
  onModalClosed: () => void;
  onConfirm: (workerName: string) => void;
  onCancel: () => void;
  initialWorkerName?: string;
};

export default observer<RenameWorkerModalProps, AdaptiveModalRef>(
  forwardRef(function AddNewWorkerGroupModal(
    {onConfirm, onCancel, onModalClosed, initialWorkerName = ''},
    ref,
  ) {
    const strings = useStrings();
    const [state] = useState(
      () => new TextInputState({defaultValue: initialWorkerName}),
    );
    const getInputValue = useCallback(() => state.value ?? '', [state]);
    const handleSubmit = useCallback(() => {
      if (state.value) {
        onConfirm(state.value);
      }
    }, [onConfirm, state.value]);
    return (
      <AdaptiveInputModal
        ref={ref}
        title={strings['modal.renameWorker.title']}
        IconSvg={FolderIcon}
        IconSvgModalRep={FolderIconLarge}
        description={strings['modal.renameWorker.subtext']}
        onModalClosed={onModalClosed}
        placeholder={strings['modal.renameWorker.placeholder']}
        getInputValue={getInputValue}
        onChangeInputValue={state.setValue}
        actions={[
          <AppButton onPress={onCancel}>{strings['action.cancel']}</AppButton>,
          <AppButton
            disabled={state.value?.length === 0}
            onPress={handleSubmit}
            variant={ButtonVariant.Primary}>
            {strings['action.rename']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const FolderIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(FolderSvg, 25, 21)} {...props} />
));

const FolderIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(FolderSvg, 38, 31)} {...props} />
));
