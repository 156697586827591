import React from 'react';
import {View} from 'react-native';
import IconSvg from '../../assets/svg/colored/productsInfoIcon.svg';
import {sized} from '../../Svg';
import {useStyles, variance} from '../../styling';
import {LG_BREAKPOINT} from '../../WindowDimensions/useDimensions';
import {getCommissionerFontStyles, Header} from '../../components';
import {useStrings} from '../../Root/hooks';
import {observer} from 'mobx-react-lite';

const TopInfo = observer(() => {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'column',
      justifyContent: 'center',
      flexShrink: 0,
      paddingHorizontal: 20,
      alignItems: 'center',
      gap: 8,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 32,
        },
      }),
    },

    icon: {
      width: 60,
      height: 60,
      flexShrink: 0,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          width: 80,
          height: 80,
        },
      }),
    },
    title: {
      ...getCommissionerFontStyles('700'),
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          fontSize: 23,
        },
      }),
    },
    textWrapper: {
      alignItems: 'center',
      gap: 8,
      flex: 1,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          alignItems: 'flex-start',
        },
      }),
    },
  }));
  return (
    <View style={styles.root}>
      <Icon style={styles.icon} />
      <View style={styles.textWrapper}>
        <Header type="header" size="h2" weight="700">
          {strings['profitOffers.title']}
        </Header>
        <Desc type="header" size="h4" weight="400">
          {strings['profitOffers.desc']}
        </Desc>
      </View>
    </View>
  );
});
const Icon = sized(IconSvg, 60, 60);
const Desc = variance(Header)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
    fontSize: 14,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 16,
        textAlign: 'left',
      },
    }),
  },
}));
export default TopInfo;
