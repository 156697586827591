import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {useStyles} from '../styling';
import {IntroVideoProps} from './IntroVideoProps';

export default observer(function IntroVideo(props: IntroVideoProps) {
  const {uri, width} = props;
  const styles = useStyles(() => ({
    root: {
      width: width,
      aspectRatio: 16 / 9,
      maxWidth: '100%',
    },
    iframe: {
      maxWidth: '100%',
      borderWidth: 0,
      aspectRatio: 16 / 9,
    },
  }));

  return (
    <View style={styles.root}>
      <iframe style={styles.iframe} src={uri} />
    </View>
  );
});
