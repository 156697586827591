import React, {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, View} from 'react-native';
import Animated, {
  runOnJS,
  SharedValue,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import {createStylesHook, useTheme} from '../../styling';
import {PressableOpacity, Typography} from '../../components';
import {useStrings} from '../../Root/hooks';
import {useMeasure} from '../../ReactNativeUtil';
import {colored, sized} from '../../Svg';
import ChevronRightSvg from '../../assets/svg/colorless/chevronRight.svg';
import RefreshSvg from '../../assets/svg/colorless/refresh.svg';
import {PercentPosition, SLIDER_BUTTON_WIDTH} from '../constants';
import {rnrClamp} from '../PuzzleHelperStaticImpl';

export type SliderProps = {
  onRefreshPress: () => void;
  onEndSwipe: (translationX: PercentPosition) => void;
  sliderTranslateX: SharedValue<PercentPosition>;
};

export default observer(function Slider({
  onRefreshPress,
  onEndSwipe,
  sliderTranslateX,
}: SliderProps) {
  const styles = useStyles();
  const ref = useRef(null);
  const strings = useStrings();
  const [getResult, onLayout] = useMeasure(ref);
  const width = getResult()?.width ?? 0;
  const availableWidth = width - SLIDER_BUTTON_WIDTH;
  const gesture = Gesture.Pan()
    .onUpdate((e) => {
      const x = rnrClamp(e.translationX, 0, availableWidth);
      sliderTranslateX.value = ((x / availableWidth) * 100) as PercentPosition;
    })
    .onEnd((e, success) => {
      if (success) {
        const x = rnrClamp(e.translationX, 0, availableWidth);
        const res = ((x / availableWidth) * 100) as PercentPosition;
        runOnJS(onEndSwipe)(res);
      }
    });
  const buttonAnimatedStyles = useAnimatedStyle(() => {
    const x = (sliderTranslateX.value / 100) * availableWidth;
    const translateX = rnrClamp(x, 0, availableWidth);
    return {
      transform: [
        {
          translateX: translateX,
        },
      ],
    };
  });
  const contentAnimatedStyles = useAnimatedStyle(() => ({
    opacity: withSpring(sliderTranslateX.value === 0 ? 1 : 0),
  }));
  const theme = useTheme();
  return (
    <View style={styles.root} ref={ref} onLayout={onLayout}>
      <Animated.View style={[styles.content, contentAnimatedStyles]}>
        <View style={{pointerEvents: 'none'}}>
          <Typography
            adjustsFontSizeToFit
            numberOfLines={2}
            type="header"
            size="h5">
            {strings['puzzle.slider.default']}
          </Typography>
        </View>
        <PressableOpacity onPress={onRefreshPress} style={styles.reloadButton}>
          <RefreshIcon color={theme.colors.uiSecondary} />
        </PressableOpacity>
      </Animated.View>
      <View style={styles.gestureView}>
        <Animated.View style={buttonAnimatedStyles}>
          <GestureDetector gesture={gesture}>
            <View style={styles.swipeButton}>
              <View style={styles.arrowIcon}>
                <ArrowRightIcon />
              </View>
              <View style={styles.arrowIcon}>
                <ArrowRightIcon />
              </View>
              <View style={styles.arrowIcon}>
                <ArrowRightIcon />
              </View>
            </View>
          </GestureDetector>
        </Animated.View>
      </View>
    </View>
  );
});

const ArrowRightIcon = colored(sized(ChevronRightSvg, 6, 12), '#fff');
const RefreshIcon = sized(RefreshSvg, 15);

const useStyles = createStylesHook((theme) => ({
  root: {
    height: 44,
    backgroundColor: theme.colors.uiAdditional1,
    borderRadius: 8,
    overflow: 'hidden',
  },
  gestureView: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
    paddingLeft: SLIDER_BUTTON_WIDTH + 12,
  },
  swipeButton: {
    backgroundColor: theme.colors.primaryNew,
    width: SLIDER_BUTTON_WIDTH,
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
      default: {},
    }),
  },
  arrowIcon: {
    marginHorizontal: 1,
  },
  reloadButton: {
    width: 36,
    height: 36,
    borderRadius: 6,
    backgroundColor: theme.colors.uiMain,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
