import {observer} from 'mobx-react-lite';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import {CryptoLoader} from '../components/atoms/CryptoLoader';

export default observer(function Loader() {
  return (
    <View style={styles.root}>
      <CryptoLoader />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
