import {Unit} from 'humanize-duration';
import {Duration} from '../QuickStartService';

export default (type: Duration['interval_type']): Unit[] => {
  switch (type) {
    case 'day':
      return ['d'];
    case 'month':
      return ['mo'];
    case 'week':
      return ['w'];
    default:
      return [];
  }
};
