import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {Typography} from '../components';
import {StyleSheet, View} from 'react-native';
import TimezoneList from './TimezoneList';
import {timezones} from '../ScheduleScreen/constant';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type PickScheduleTimezoneModalProps = {
  onModalClosed: () => void;
  onClosePress: () => void;
  onTimezoneSelect: (offset: number) => void;
};

export default observer<PickScheduleTimezoneModalProps, AdaptiveModalRef>(
  forwardRef(function PickScheduleTimezoneModal(props, ref) {
    const {onModalClosed} = props;
    return (
      <AdaptiveModal
        modalWidth={ModalWidth.Small}
        ref={ref}
        adaptiveBreakpoint="sm"
        modalContentStyle={styles.modalContent}
        bottomSheetSnapPoints={['90%']}
        onModalClosed={onModalClosed}>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer((props: PickScheduleTimezoneModalProps) => {
  const {onClosePress, onTimezoneSelect} = props;
  const strings = useStrings();
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <View style={[styles.root, {paddingBottom}]}>
      <View style={styles.top}>
        <Typography type="header" size="h4">
          {strings['scheduler.timezone']}
        </Typography>
      </View>
      <TimezoneList timezones={timezones} onSelect={onTimezoneSelect} />
      <View style={styles.footer}>
        <AppButton variant={ButtonVariant.Primary} onPress={onClosePress}>
          {strings['workerGroup.hideList']}
        </AppButton>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  top: {
    paddingTop: 30,
    paddingBottom: 20,
    paddingHorizontal: 16,
  },
  modalContent: {
    flex: 1,
  },
  footer: {
    paddingTop: 30,
    paddingHorizontal: 16,
  },
});
