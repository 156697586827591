import type {FirebaseOptions} from '../WebFirebase/app';

export const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: 'AIzaSyAXkWIC8Ex03zw9y-ICHYKYGSNLYUq2O-8',
  authDomain: 'ct-farm.firebaseapp.com',
  projectId: 'ct-farm',
  storageBucket: 'ct-farm.appspot.com',
  messagingSenderId: '609720201684',
  appId: '1:609720201684:web:934074cb574e1238cb2641',
  measurementId: 'G-2G0Y68CFGR',
};

export const VAPID_KEY =
  'BHB5MeD5G__AdJw5WDX2qVjsPgMZtGSsNMQAyapJC-O9RkQMOT-M_b8i63t5nvaQ-BNKRy5DZrmBr6j1K8I9Tjo';
