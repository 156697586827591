import {SchedulerEntity} from '../../schedule/SchedulerEntity';
import {WorkerId, Settings, Hashrate} from '../../../../../../src/ApiStore';
import {ISODateString} from '../../../../../../src/Time';

export interface WorkerEntity {
  id: WorkerId;
  name: string | null;
  account_id: number;
  group_id: number;
  managed: boolean;
  timezone: string;
  utc_offset: number;
  default_settings: Settings;
  slot_status: SlotStatus;
  pool_miner_id: number | null;
  versions: WorkerVersions | null;
  host_info: HostInfo | null;
  mining_options: MiningOptions | null;
  schedulers: SchedulerEntity[] | null;
  worker_type: WorkerType;
  pool_miner_is_active: boolean | null;
  expired: boolean | null;
  pool_miner_config: PoolMinerConfig | null;
  start_time: ISODateString | null;
  end_time: ISODateString | null;
  is_free: boolean;
  subscription_id: number | null;
  order_id?: string | null;
}

export enum SlotStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum WorkerType {
  Regular = 'REGULAR',
  Pool = 'POOL',
}

export interface WorkerVersions {
  miner: string;
  backend: string;
  frontend: string;
  randomx: string;
}

export interface HostInfo {
  hostname: string; // 'MainServer'
  user_name: string; // 'noname'
  os_info: string; // 'Windows 10 (14393)'
  timezone: string; // 'Europe/Moscow'
  utc_offset: number; // 180
  autorun: boolean;
  memory: Memory;
  cpu: Cpu;
}

export interface PoolMinerConfig {
  name: string;
  desc: string;
  hash_rate: Hashrate;
  frequency: number;
  cores_count: number;
  threads_count: number;
}

export interface Memory {
  free: number;
  total: number;
}

export interface Cpu {
  brand: string; // 'AMD Ryzen 9 3950X 16-Core Processor'
  aes: boolean;
  avx: boolean;
  avx2: boolean;
  avx512f: boolean;
  bmi2: boolean;
  osxsave: boolean;
  pdpe1gb: boolean;
  sse2: boolean;
  ssse3: boolean;
  sse4_1: boolean;
  xop: boolean;
  popcnt: boolean;
  cat_l3: boolean;
  vm: boolean;
  arch: string; // 'x64'
  l2: number; // 14680064
  l3: number; // 16777216
  cores: number; // 0
  threads: number; // 28
  packages: number; // 0
  nodes: number; // 1
}

export interface MiningOptions {
  optimal_threads: number; // 28
  msr: string; // 'ryzen_17h'
  assembly: string; // 'ryzen'
  is_huge_pages_enabled: boolean;
}
