import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import {sized} from '../Svg';
import SandClockSvg from '../assets/svg/twoTone/sandClock.svg';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import {StyleSheet, View} from 'react-native';
import {getCommissionerFontStyles, Typography} from '../components';
import {createStylesHook} from '../styling';
import {useStrings} from '../Root/hooks';

export type PaymentConfirmPendingModalProps = {
  onModalClosed: () => void;
  onAction: () => void;
};

export default observer<PaymentConfirmPendingModalProps, AdaptiveModalRef>(
  forwardRef(function PaymentConfirmPendingModal(
    {onModalClosed, onAction},
    ref,
  ) {
    return (
      <AdaptiveModal
        modalWidth={ModalWidth.Small}
        onModalClosed={onModalClosed}
        ref={ref}
        enableContentAutoHeight>
        <ContentView onAction={onAction} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer((props: {onAction: () => void}) => {
  const themedStyles = useStyles();
  const paddingBottom = useSafePaddingBottom(16);
  const {onAction} = props;
  const strings = useStrings();

  return (
    <View style={[styles.content, {paddingBottom}]}>
      <View style={styles.top}>
        <Typography type="header" size="h4">
          {strings['modal.paymentConfirmPendingModal.title']}
        </Typography>
        <View style={styles.warningView}>
          <PaymentConfirmPendingIcon />
        </View>
      </View>
      <View style={styles.modalBody}>
        <Typography type="paragraph" style={themedStyles.description}>
          {strings['modal.paymentConfirmPendingModal.description']}
        </Typography>
      </View>
      <View style={styles.modalFooter}>
        <AppButton variant={ButtonVariant.Primary} onPress={onAction}>
          {strings['modal.paymentConfirmPendingModal.confirm']}
        </AppButton>
      </View>
    </View>
  );
});

export const PaymentConfirmPendingIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SandClockSvg, 102)} {...props} />
));

const useStyles = createStylesHook((theme) => ({
  description: {
    ...getCommissionerFontStyles('500'),
    fontSize: 16,
    lineHeight: 20,
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
  },
}));

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  top: {
    paddingTop: 30,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  warningView: {
    marginTop: 16,
  },
  modalBody: {
    paddingHorizontal: 30,
    alignItems: 'center',
    marginBottom: 60,
  },
  modalFooter: {
    paddingHorizontal: 16,
  },
});
