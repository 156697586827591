import {batchDisposers, Service} from '../structure';
import {computed, observable, reaction, makeObservable} from 'mobx';
import {Auth} from '../Auth';
import {InAppOffers, MinerSubscriptionOffer} from '../InAppOffersService';
import {maxBy} from 'lodash';
import {CurrentSubscriptionOffer} from './CurrentSubscriptionOffer';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';

export default class CurrentSubscriptionOfferService
  implements Service, CurrentSubscriptionOffer
{
  @observable private _isLoadedIn: boolean = false;
  @observable private _currentSubscriptionOffer?: MinerSubscriptionOffer;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly inAppOffers: InAppOffers;
      readonly dashboardStore: DashboardStore;
    },
  ) {
    makeObservable(this);
  }

  get isLoadedIn() {
    return this._isLoadedIn;
  }

  get currentSubscriptionOffer() {
    return this._currentSubscriptionOffer;
  }

  @computed
  get currentHashrate() {
    return this.currentSubscriptionOffer?.poolMinerConfig.hash_rate;
  }

  private _fetch() {
    this._isLoadedIn = false;
    const {purchasedIds, minerSubscriptionOffers} = this._root.inAppOffers;
    const boughtList = minerSubscriptionOffers.filter((_) =>
      purchasedIds?.has(_.purchaseId),
    );
    const workers = [...this._root.dashboardStore.workers.values()];
    const maxSubHashrate = maxBy(
      workers.filter((_) => !_.is_free && _.subscription_id !== null),
      (_) => _.pool_miner_config?.hash_rate,
    )?.pool_miner_config?.hash_rate;
    if (maxSubHashrate) {
      this._currentSubscriptionOffer = boughtList.find(
        (_) => _.poolMinerConfig.hash_rate === maxSubHashrate,
      );
    } else {
      this._currentSubscriptionOffer = undefined;
    }
    this._isLoadedIn = true;
  }

  private _runWhenAvailable() {
    return reaction(
      () => [
        this._root.auth.accountId,
        this._root.auth.isConnected,
        this._root.inAppOffers.isLoadedIn,
        this._root.dashboardStore.isLoading,
      ],
      ([, isConnected, isLoadedIn, dashboardIsLoading]) => {
        if (isConnected && isLoadedIn && !dashboardIsLoading) {
          this._fetch();
        }
      },
      {fireImmediately: true},
    );
  }

  subscribe() {
    return batchDisposers(this._runWhenAvailable());
  }
}
