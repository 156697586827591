import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import YellowFlashSvg from '../assets/svg/colored/yellowFlash.svg';
import {Body, Header, Paragraph} from '../components';
import PickHashrateSection from '../components/organisms/purchase/PickHashrateSection';
import {useRoot, useStrings} from '../Root/hooks';
import {variance} from '../styling';
import {sized} from '../Svg';
import {MinerSubscriptionOffer, PaymentMethod} from '../InAppOffersService';
import dayjs from 'dayjs';
import {Millisecond} from '../Time';
import SubscriptionManagementScreenTemplate from './SubscriptionManagementScreenTemplate';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import getHumanizeDuration from '../utils/getHumanizeDuration';
import TotalPriceView from '../PurchaseScreen/TotalPriceView';
import {Hashrate} from '../ApiStore';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';
import {processMinerSubscriptionProposal} from '../PurchaseScreen/processMinerSubscriptionProposal';
import {ManagedProposalSubscription} from '../ProposalsState';

export type SubscriptionManagementScreenProps = {
  goToConfiguration: () => void;
  onUpdatePress: () => void;
  onChangeSlider: (hashrate: Hashrate) => void;
  minerConfigs: MinerConfig[];
  selectedConfig: MinerConfig | undefined;
  selectedProposal:
    | ManagedProposalSubscription<MinerSubscriptionOffer>
    | undefined;
  currentSubscriptionOffer: MinerSubscriptionOffer | undefined;
};

export const PAYMENT_METHOD = PaymentMethod.InApp;

export default observer(function SubscriptionManagementScreen({
  goToConfiguration,
  onChangeSlider,
  onUpdatePress,
  currentSubscriptionOffer,
  minerConfigs,
  selectedConfig,
  selectedProposal,
}: SubscriptionManagementScreenProps) {
  const strings = useStrings();
  const {translation} = useRoot();
  const duration = currentSubscriptionOffer
    ? getHumanizeDuration(
        dayjs
          .duration(currentSubscriptionOffer.interval, 'month')
          .asMilliseconds() as Millisecond,
        translation.localeTag,
      )
    : null;
  const computedOffer = processMinerSubscriptionProposal(
    selectedProposal,
    PAYMENT_METHOD,
  );
  return (
    <SubscriptionManagementScreenTemplate
      renderTotalPrice={() =>
        computedOffer && (
          <TotalPriceView
            product={undefined}
            onMainOfferPurchasePress={() => {}}
            subscription={computedOffer}
            onAdditionalOfferPurchasePress={onUpdatePress}
            submitSubscriptionText={
              currentSubscriptionOffer
                ? strings['action.update']
                : strings['action.subscribe']
            }
          />
        )
      }>
      {currentSubscriptionOffer && (
        <TopView>
          <TopLeftView>
            <YellowFlashIconView>
              <YellowFlashIcon />
            </YellowFlashIconView>
            <View>
              <TopSubtitleText type="body" weight="500">
                {strings['purchaseSettings.speed']}
              </TopSubtitleText>
              <Header type="header" size="h4" weight="500">
                {currentSubscriptionOffer.poolMinerConfig.hash_rate} H/S
              </Header>
            </View>
          </TopLeftView>
          <View>
            <TopSubtitleText type="body" weight="500">
              {strings['purchaseSettings.term']}
            </TopSubtitleText>
            <Header type="header" size="h4" weight="500">
              {duration}
            </Header>
          </View>
        </TopView>
      )}
      <MainView>
        <TitleText type="header" size="h2">
          {strings['purchase.poolMiners.title']}
        </TitleText>
        <SubTitleText type="paragraph" size="large" weight="500">
          {' '}
          {strings['purchaseSettings.description']}
        </SubTitleText>
        <PickHashrateSection
          theme="dark"
          selectedConfig={selectedConfig}
          minerConfigs={minerConfigs}
          onChangeSlider={onChangeSlider}
          goToConfiguration={goToConfiguration}
        />
      </MainView>
    </SubscriptionManagementScreenTemplate>
  );
});

const YellowFlashIcon = sized(YellowFlashSvg, 24);

const MainView = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 16,
    paddingTop: 24,
    paddingBottom: 48,
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    backgroundColor: theme.colors.uiMain,
  },
}));

const TitleText = variance(Header)((theme) => ({
  root: {
    textAlign: 'center',
    marginBottom: 6,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },
}));

const SubTitleText = variance(Paragraph)((theme) => ({
  root: {
    paddingHorizontal: 16,
    textAlign: 'center',
    color: theme.colors.primaryUIDirtyBlue,
    marginBottom: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
        paddingHorizontal: 0,
        marginBottom: 0,
      },
    }),
  },
}));

const TopView = variance(View)((theme) => ({
  root: {
    padding: 16,
    backgroundColor: theme.colors.uiMain,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    marginBottom: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 24,
      },
    }),
  },
}));

const YellowFlashIconView = variance(View)(() => ({
  root: {
    marginRight: 9,
  },
}));

const TopLeftView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const TopSubtitleText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
  },
}));
