import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {variance} from '../../../../styling';
import PowerSlider from './PowerSlider';
import WorkerStatusBackground from '../../../../components/organisms/worker/WorkerStatusBackground';
import {StyleSheet, View, Text} from 'react-native';
import MenuHeader from './MenuHeader';
import SpeedTemperature from './SpeedTemperature';
import useGetIsLarge from '../../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {AppButton, ButtonVariant} from '../../../../components/AppButton';
import useWorkerStatuses from '../../../hooks/useWorkerStatuses';
import {useRoot, useStrings} from '../../../../Root/hooks';
import TrashSvg from '../../../../assets/svg/colorless/trash.svg';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';
import isRenewPossible from '../../../checkers/isRenewPossible';
import {sized} from '../../../../Svg';
import RenewSvg from '../../../../assets/svg/colorless/renewIcon.svg';
import AttentionSvg from '../../../../assets/svg/colored/attention.svg';
import dayjs from 'dayjs';
import {
  getCommissionerFontStyles,
  PressableOpacity,
} from '../../../../components';
import {Divider} from '../../../../components/atoms';
import isNeedToReactivate from '../../../../PoolMinerActivator/isNeedToReactivate';
import SwipeableContainer from '../../../../components/SwipeableContainer/SwipeableContainer';

export type SimpleWorkerSlotProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  index: number;
  isLast: boolean;
  goToPoolActivator: () => void;
};

const TrashIcon = sized(TrashSvg, 18);
const RenewIcon = sized(RenewSvg, 20, 20);
const AttentionIcon = sized(AttentionSvg, 24, 24);

const DividerComponent = () => {
  return (
    <View style={styles.dividerWrapper}>
      <Divider vertical style={styles.divider} />
    </View>
  );
};
type WorkerInfoProps = {
  startTimeFormatted: string | null;
  endTimeFormatted: string | null;
};

const WorkerInfo: React.FC<WorkerInfoProps> = ({
  startTimeFormatted,
  endTimeFormatted,
}) => {
  const strings = useStrings();

  return (
    <>
      <BoxItem>
        <ActivatedTitle>{strings['workerDetails.activated']}:</ActivatedTitle>
        <ActivatedText>{startTimeFormatted}</ActivatedText>
      </BoxItem>
      <DividerComponent />
      <BoxItem>
        <ExpiredTop>
          <AttentionIcon />
          <ActivatedTitle>{strings['worker.expired']}:</ActivatedTitle>
        </ExpiredTop>
        <ExpiredText>{endTimeFormatted}</ExpiredText>
      </BoxItem>
      <DividerComponent />
    </>
  );
};

type ActionButtonsProps = {
  onWorkerRemove: () => void;
  onRenewPress: () => void;
  visibleRemoveButton: boolean;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
  onWorkerRemove,
  onRenewPress,
  visibleRemoveButton,
}) => {
  const strings = useStrings();
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());

  return (
    <>
      {visibleRemoveButton && isLarge && (
        <>
          <AppButton
            Icon={TrashIcon}
            onPress={onWorkerRemove}
            style={styles.mdButton}>
            {strings['deleteWorker.button']}
          </AppButton>
          <DividerComponent />
        </>
      )}
      <AppButton
        onPress={onRenewPress}
        Icon={RenewIcon}
        style={styles.mdButton}
        variant={ButtonVariant.GreenGradient}>
        {strings['action.renew']}
      </AppButton>
    </>
  );
};

const SimpleSlot = observer(function SimpleSlot({
  worker,
  index,
  isLast,
  goToPoolActivator,
}: SimpleWorkerSlotProps) {
  const {goToRenewAccess} = useForcedContext(DashboardContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const strings = useStrings();
  const {needActivate, expired, offline} = useWorkerStatuses(worker);
  const needToReactivate = expr(() => isNeedToReactivate(worker));
  const {
    proposalsState,
    translation: {localeTag},
    navigationContainer,
  } = useRoot();

  const renewPossible = isRenewPossible(
    worker,
    proposalsState.minerProductProposalList.flatMap((_) =>
      _.available ? [_.offer] : [],
    ),
    proposalsState.minerSubscriptionProposalList.flatMap((_) =>
      _.available ? [_.offer] : [],
    ),
  );

  const onRenewPress = useCallback(() => {
    goToRenewAccess(worker.id);
  }, [goToRenewAccess, worker]);

  const onWorkerRemove = useCallback(() => {
    navigationContainer.ref?.navigate('DeleteWorkerConfirm', {
      workerId: worker.id,
      withRenew: renewPossible,
      forceNavigateRoot: true,
    });
  }, [worker, navigationContainer, renewPossible]);

  const startTimeFormatted =
    worker.start_time &&
    dayjs(worker.start_time).locale(localeTag).format('DD MMM YYYY');
  const endTimeFormatted =
    worker.end_time &&
    dayjs(worker.end_time).locale(localeTag).format('DD MMM YYYY');

  const isRenew = expired && renewPossible;
  const visibleRemoveButton = Boolean(offline || expired);

  return (
    <SwipeableContainer
      enabled={expired && !isLarge}
      RightAction={
        <SwipableDeleteAction onPress={onWorkerRemove}>
          <TrashIcon color="white" />
        </SwipableDeleteAction>
      }>
      <RootView large={isLarge} lastLarge={isLarge && isLast}>
        <WorkerStatusBackground worker={worker}>
          <MenuHeader
            onHeaderPress={needToReactivate ? goToPoolActivator : undefined}
            index={index}
            worker={worker}
          />
          <Content
            onPress={needToReactivate ? goToPoolActivator : undefined}
            last={isLast}
            onlyButton={!!isRenew}>
            {isRenew ? (
              <>
                <Box>
                  {isLarge && (
                    <WorkerInfo
                      startTimeFormatted={startTimeFormatted}
                      endTimeFormatted={endTimeFormatted}
                    />
                  )}
                  <ActionButtons
                    onWorkerRemove={onWorkerRemove}
                    onRenewPress={onRenewPress}
                    visibleRemoveButton={visibleRemoveButton}
                  />
                </Box>
              </>
            ) : needActivate ? (
              <AppButton
                onPress={goToPoolActivator}
                variant={ButtonVariant.Primary}>
                {strings['action.active']}
              </AppButton>
            ) : (
              <>
                <SpeedTemperature worker={worker} />
                <PowerSlider worker={worker} />
              </>
            )}
          </Content>
        </WorkerStatusBackground>
      </RootView>
    </SwipeableContainer>
  );
});

const SwipableDeleteAction = variance(PressableOpacity)((theme) => ({
  root: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 90,
    borderRadius: 12,
    backgroundColor: theme.colors.primaryError,
  },
}));

export default SimpleSlot;

const styles = StyleSheet.create({
  grow: {flex: 1},
  divider: {flex: 1, height: '100%'},
  dividerWrapper: {paddingHorizontal: 16, flexShrink: 0, height: '100%'},
  mdButton: {flexGrow: 1},
});

const RootView = variance(View)((theme) => ({
  root: {},
  large: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.uiAdditional1,
    borderRightWidth: 1,
    borderRightColor: theme.colors.uiAdditional1,
  },
  lastLarge: {
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.uiAdditional1,
    overflow: 'hidden',
  },
}));

const Box = variance(View)(() => ({
  root: {flexDirection: 'row', alignItems: 'center'},
}));
const BoxItem = variance(View)(() => ({root: {width: 174}}));
const ExpiredTop = variance(View)(() => ({
  root: {flexDirection: 'row', alignItems: 'center', gap: 6},
}));
const ActivatedTitle = variance(Text)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    ...getCommissionerFontStyles('500'),
    fontSize: 16,
  },
}));
const ExpiredText = variance(Text)((theme) => ({
  root: {
    color: theme.colors.primaryError,
    ...getCommissionerFontStyles('500'),
    fontSize: 16,
  },
}));
const ActivatedText = variance(Text)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    ...getCommissionerFontStyles('500'),
    fontSize: 16,
  },
}));
const ContentView = variance(View)((theme) => ({
  root: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.uiMain,
    paddingVertical: 10,
  },
  last: {borderBottomWidth: 0},
  onlyButton: {paddingVertical: 20, paddingHorizontal: 16},
}));
type ContentProps = {
  children: React.ReactNode;
  onPress?: () => void;
  last: boolean;
  onlyButton: boolean;
};

const Content: React.FC<ContentProps> = ({
  children,
  onPress,
  last,
  onlyButton,
}) => {
  if (onPress) {
    return (
      <TouchableWithoutFeedback
        style={styles.grow}
        containerStyle={styles.grow}
        disabled={!onPress}
        onPress={onPress}>
        <ContentView last={last} onlyButton={onlyButton}>
          {children}
        </ContentView>
      </TouchableWithoutFeedback>
    );
  } else {
    return (
      <View style={styles.grow}>
        <ContentView last={last} onlyButton={onlyButton}>
          {children}
        </ContentView>
      </View>
    );
  }
};
