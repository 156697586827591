import React, {useEffect, useState, useRef} from 'react';
import {Animated, View} from 'react-native';
import useInterval from '../../RateAppModal/useInterval';
import usePrevious from '../../RateAppModal/usePrevious';
import {useStyles} from '../../styling';
import {observer} from 'mobx-react-lite';

const COUNT = 4;

export const CryptoLoader = () => {
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const styles = useStyles(() => ({
    root: {
      flexDirection: 'row',
    },
  }));
  useInterval(() => {
    setActiveIndex(activeIndex > COUNT ? 1 : activeIndex + 1);
  }, 400);
  return (
    <View style={styles.root}>
      {new Array(COUNT).fill('').map((_, index) => (
        <Dot key={index} active={index + 1 === activeIndex} />
      ))}
    </View>
  );
};

interface DotProps {
  active: boolean;
}

const Dot = (props: DotProps) => {
  const prevActive = usePrevious(props.active);
  const scale = useRef(new Animated.Value(1));
  useEffect(() => {
    if (prevActive && !props.active) {
      scaleDown();
    }
    if (!prevActive && props.active) {
      scaleUp();
    }
  });
  const styles = useStyles(() => ({
    root: {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginHorizontal: 5,
    },
  }));

  const scaleDown = () => {
    Animated.timing(scale.current, {
      toValue: 1,
      duration: 400,
      useNativeDriver: false,
    }).start();
  };

  const scaleUp = () => {
    Animated.timing(scale.current, {
      toValue: 1.7,
      duration: 400,
      useNativeDriver: false,
    }).start();
  };

  const backgroundColor = scale.current.interpolate({
    inputRange: [1, 1.7],
    outputRange: ['rgb(142, 182, 248)', 'rgb(66, 134, 244)'],
  });

  return (
    <Animated.View
      style={[
        styles.root,
        {
          transform: [{scale: scale.current}],
          backgroundColor,
        },
      ]}
    />
  );
};

export const ScreenFluidLoader = observer(() => {
  const styles = useStyles(() => ({
    root: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));
  return (
    <View style={styles.root}>
      <CryptoLoader />
    </View>
  );
});
