import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {createStylesHook} from '../styling';
import ScheduleState from './ScheduleState';
import {timezones} from './constant';
import TimezonePicker from './TimezonePicker';

export type TimezoneProps = {
  state: ScheduleState;
  onTimezonePress: () => void;
};

export default observer((props: TimezoneProps) => {
  const {state, onTimezonePress} = props;
  const styles = useStyles();

  const items = useMemo(
    () =>
      timezones.map((timezone) => ({
        value: timezone.offset,
        label: timezone.name,
      })),
    [],
  );

  const selectedTimezone = useMemo(
    () =>
      items.find((it) => it.value === state.userTimezoneMinutes / 60)?.label!,
    [state.userTimezoneMinutes, items],
  );

  return (
    <View style={styles.root}>
      <TimezonePicker onPress={onTimezonePress} value={selectedTimezone} />
    </View>
  );
});

const useStyles = createStylesHook(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
