import {useMemo} from 'react';
import {WorkerOrder} from '../../Dashboard';
import {useRoot, useStrings} from '../../Root/hooks';

export default function useSortItems() {
  const strings = useStrings();
  const {
    dashboardService: {order},
  } = useRoot();
  const defaultSorter = useMemo(
    () => ({
      title: strings['groupFilter.default'],
      value: WorkerOrder.Default,
    }),
    [strings],
  );
  const items = useMemo(
    () => [
      defaultSorter,
      {
        title: strings['groupFilter.name'],
        value: WorkerOrder.Name,
      },
      {
        title: strings['groupFilter.miningSpeed'],
        value: WorkerOrder.AverageHashrate,
      },
      {
        title: strings['groupFilter.earnings'],
        value: WorkerOrder.MinedTotal,
      },
      {
        title: strings['groupFilter.status'],
        value: WorkerOrder.Status,
      },
    ],
    [defaultSorter, strings],
  );
  const selected = useMemo(
    () => items.find((_) => _.value === order) ?? defaultSorter,
    [defaultSorter, items, order],
  );
  return {items, selected};
}
