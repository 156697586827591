import React from 'react';
import {variance} from '../styling';
import {CryptotabFarmLogo} from './CryptotabFarmLogo';
import {Header} from './Typography';
import {StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import BadgeCounter from './atoms/BadgeCounter';

export type HeaderTitleProps = {
  title?: string;
  notificationsCount?: number;
};

export default observer(function HeaderTitle({
  title,
  notificationsCount,
}: HeaderTitleProps) {
  const hasCounter =
    notificationsCount !== undefined && notificationsCount !== 0;
  if (title) {
    return (
      <View style={styles.root}>
        <TitleText
          numberOfLines={1}
          adjustsFontSizeToFit
          offset={hasCounter}
          type="header"
          size="h2">
          {title}
        </TitleText>
        {hasCounter && <BadgeCounter label={notificationsCount} />}
      </View>
    );
  }
  return <CryptotabFarmLogo />;
});

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    overflow: 'visible',
    maxWidth: '100%',
  },
});

const TitleText = variance(Header)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    flexShrink: 1,
  },
  offset: {
    marginRight: 10,
  },
}));
