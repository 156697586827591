import {Colors} from './Coloring';
import staticColors from './staticColors';

const lightColors: Colors = {
  ...staticColors,
  backgroundBlackout: 'rgba(9, 24, 63, 0.7)',
  backgroundGround: '#FAFAFA',
  shadowMain: '#FFFFFF',
  uiAdditional1: '#ECEEF2',
  uiAdditional2: '#D0D5DE',
  uiAdditional3: '#C7CDD9',
  uiAdditional4: '#BEC0C8',
  uiGradientDivider: [
    'rgba(208, 213, 222, 0)',
    '#D0D5DE',
    'rgba(208, 213, 222, 0)',
  ],
  uiGradientTemp: ['#FF3347', '#C7CDD9'],
  uiMain: '#FFFFFF',
  uiSecondary: '#09183F',
};

export default lightColors;
