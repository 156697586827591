import React, {useCallback} from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import getClosestColor from './getClosestColor';
import {SelectedSpeed} from './types';
import {useStrings} from '../Root/hooks';
import SpeedToggleItem from './SpeedToggleItem';
import SpeedToggleSelect from './SpeedToggleSelect';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type SpeedToggleListProps = {
  selectedSpeed: SelectedSpeed;
  pickedCustomSpeed: number;
  onChangeSpeed: (speed: SelectedSpeed) => void;
  onSelectSpeedPress: () => void;
};

export default observer((props: SpeedToggleListProps) => {
  const {selectedSpeed, pickedCustomSpeed, onChangeSpeed, onSelectSpeedPress} =
    props;
  const strings = useStrings();
  const handleCustomTogglePress = useCallback(() => {
    return onChangeSpeed({speed: pickedCustomSpeed, custom: true});
  }, [onChangeSpeed, pickedCustomSpeed]);

  const {speed, custom} = selectedSpeed;
  return (
    <>
      <SpeedToggleList>
        <SpeedToggleItem
          label={'100%'}
          color={getClosestColor(100)}
          selected={speed === 100 && !custom}
          onPress={() => onChangeSpeed({speed: 100})}
        />

        <SpeedToggleItem
          label={'50%'}
          color={getClosestColor(50)}
          selected={speed === 50 && !custom}
          onPress={() => onChangeSpeed({speed: 50})}
        />

        <SpeedToggleSelect
          label={`${pickedCustomSpeed}%`}
          color={getClosestColor(pickedCustomSpeed)}
          selected={custom}
          onPress={handleCustomTogglePress}
          onSelect={onSelectSpeedPress}
        />

        <SpeedToggleItem
          last
          label={strings['scheduler.power.off']}
          color={getClosestColor(0)}
          keepUnselected
          selected={speed === 0 && !custom}
          onPress={() => onChangeSpeed({speed: 0})}
        />
      </SpeedToggleList>
    </>
  );
});

const SpeedToggleList = variance(View)((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: 25,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 0,
      },
    }),
  },
}));
