import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {variance} from '../styling';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';

export type FormActionsViewProps = {
  onWalletPayPress: () => void;
  onInstallMetamaskPress: () => void;
  onCheckPaymentPress: () => void;
  payWithMetamaskVisible: boolean;
  payWithMetamaskPossible: boolean;
  payWithCtPayPossible: boolean;
  onCtPayPress: () => void;
};

export default observer(function FormActionsView({
  onWalletPayPress,
  onInstallMetamaskPress,
  onCheckPaymentPress,
  payWithMetamaskVisible,
  payWithMetamaskPossible,
  payWithCtPayPossible,
  onCtPayPress,
}: FormActionsViewProps) {
  const strings = useStrings();
  return (
    <RootView>
      <StyledAppButton
        onPress={onCheckPaymentPress}
        variant={ButtonVariant.Primary}>
        {strings['payment.action.checkPayment']}
      </StyledAppButton>
      <GutterView>
        {payWithMetamaskPossible ? (
          payWithMetamaskVisible ? (
            <StyledAppButton onPress={onWalletPayPress}>
              {strings['payment.action.payWithMetamask']}
            </StyledAppButton>
          ) : (
            <StyledAppButton onPress={onInstallMetamaskPress}>
              {strings['payment.action.installMetamask']}
            </StyledAppButton>
          )
        ) : null}
        {payWithCtPayPossible ? (
          <StyledAppButton
            variant={ButtonVariant.Success}
            gutterTop={payWithMetamaskVisible}
            onPress={onCtPayPress}>
            {strings['payment.action.payWithCtPay']}
          </StyledAppButton>
        ) : null}
      </GutterView>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
      },
    }),
  },
}));

const GutterView = variance(View)((theme) => ({
  root: {
    marginTop: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginTop: 0,
      },
    }),
  },
}));

const StyledAppButton = variance(AppButton)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 300,
      },
    }),
  },
  gutterTop: {
    marginTop: 20,
  },
}));
