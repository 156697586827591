import {batchDisposers} from '../structure';
import {Proposal, ProposalsState} from '../ProposalsState';
import {action, computed, makeObservable, observable, reaction} from 'mobx';
import {MinerSubscriptionOffer} from '../InAppOffersService';
import {SubscriptionManagementScreenState} from './SubscriptionManagementScreenState';
import {Hashrate} from '../ApiStore';
import makeMinerConfigsByHashrate from '../ProposalsState/makeMinerConfigsByHashrate';
import {PurchaseId} from '../units';
import {CurrentSubscriptionOffer} from '../CurrentSubscriptionOfferService';
import {InAppPurchaseManager} from '../InAppPurchaseManager';

export default class SubscriptionManagementScreenStateImpl
  implements SubscriptionManagementScreenState
{
  @observable private _selectedHashrate?: Hashrate;
  @observable.ref private _proposals: Proposal<MinerSubscriptionOffer>[] = [];
  @observable private _configuredId?: PurchaseId;

  constructor(
    private readonly _root: {
      readonly proposalsState: ProposalsState;
      readonly currentSubscriptionOfferService: CurrentSubscriptionOffer;
      readonly inAppPurchaseManager: InAppPurchaseManager;
    },
  ) {
    makeObservable(this);
  }

  get proposals() {
    return this._proposals;
  }

  @computed
  get minerConfigByHashrate() {
    return makeMinerConfigsByHashrate(this.proposals);
  }

  get selectedHashrate() {
    return this._selectedHashrate;
  }

  @computed
  get selectedMinerConfig() {
    if (!this.selectedHashrate) {
      return undefined;
    }
    return this.minerConfigByHashrate.get(this.selectedHashrate);
  }

  @computed
  get configuredOffer() {
    if (!this._configuredId) {
      return undefined;
    }
    const proposal =
      this._root.proposalsState.minerSubscriptionProposalById?.get(
        this._configuredId,
      );
    if (!proposal) {
      return undefined;
    }
    return proposal.offer;
  }

  selectHashrate = action((hashrate: Hashrate) => {
    this._selectedHashrate = hashrate;
  });

  @computed
  get selectedProposal() {
    if (!this._selectedHashrate || !this._configuredId) {
      return undefined;
    }
    const interval = this.configuredOffer?.interval;
    if (!interval) {
      return undefined;
    }
    const double = this._root.proposalsState.doubleProposalByIntervalByHashrate
      .get(this._selectedHashrate)
      ?.get(interval);
    if (!double) {
      return undefined;
    }
    return double.subscription;
  }

  configure = action((_: PurchaseId) => {
    this._configuredId = _;
  });

  private _fillStoreAfterLoadPurchases() {
    return reaction(
      () =>
        [this._root.proposalsState.isLoadedIn, this.configuredOffer] as const,
      async ([isLoadedIn, configuredOffer]) => {
        if (!isLoadedIn || !configuredOffer) {
          return;
        }
        const offerHashrate = configuredOffer.poolMinerConfig.hash_rate;
        const offerInterval = configuredOffer.interval;
        this._proposals =
          this._root.proposalsState.minerSubscriptionProposalList.flatMap((_) =>
            _.available && _.offer.interval === offerInterval ? [_] : [],
          );
        this._selectedHashrate = offerHashrate;
      },
      {fireImmediately: true},
    );
  }

  subscribe() {
    return batchDisposers(this._fillStoreAfterLoadPurchases());
  }
}
