import {batchDisposers, Service} from '../structure';
import {bind} from '../fp';
import {minBy, sortBy} from 'lodash';
import {Auth} from '../Auth';
import {DoubleProposal, ProposalsState, SETTLED} from '../ProposalsState';
import {PurchaseId} from '../units';
import {PurchaseScreenState, PurchaseTabsKind} from './PurchaseScreenState';
import {Second} from '../Time';
import {MinerOffer, PaymentMethod} from '../InAppOffersService';
import {observable, computed, action, runInAction, reaction} from 'mobx';
import {Hashrate, Interval} from '../ApiStore';

export default abstract class BasePurchaseScreenStateImpl
  implements PurchaseScreenState, Service
{
  private static readonly DEFAULT_INTERVAL = 1 as Interval;

  @observable private _selectedTab: PurchaseTabsKind = PurchaseTabsKind.Worker;
  @observable private _selectedHashrate?: Hashrate;
  @observable private _selectedInterval: Interval =
    BasePurchaseScreenStateImpl.DEFAULT_INTERVAL as Interval;

  @observable private _selectedActivatorPurchaseId?: PurchaseId;
  @observable private _selectedReactivationTime?: Second;

  protected constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly proposalsState: ProposalsState;
    },
  ) {}

  abstract get selectedPaymentMethod(): PaymentMethod;
  abstract selectPaymentMethod(method: PaymentMethod): void;

  get selectedTab() {
    return this._selectedTab;
  }

  get selectedReactivationTime() {
    return this._selectedReactivationTime;
  }

  @computed
  get selectedActivatorProposals() {
    if (!this.selectedReactivationTime) {
      return undefined;
    }
    const {activatorProposalMapByTime} = this._root.proposalsState;
    return activatorProposalMapByTime.get(this.selectedReactivationTime);
  }

  get selectedHashrate() {
    return this._selectedHashrate;
  }

  get selectedInterval() {
    return this._selectedInterval;
  }

  selectHashrate = action((hashrate: Hashrate) => {
    this._selectedHashrate = hashrate;
  });

  selectInterval = action((interval: Interval) => {
    this._selectedInterval = interval;
  });

  abstract get selectedDoubleProposal(): DoubleProposal | undefined;

  @computed
  get selectedActivatorProposal() {
    if (!this._selectedActivatorPurchaseId) {
      return undefined;
    }
    const {activatorSubscriptionProposalById} = this._root.proposalsState;
    return activatorSubscriptionProposalById?.get(
      this._selectedActivatorPurchaseId,
    );
  }

  @computed
  get minerConfigs() {
    return this._root.proposalsState.minerConfigs;
  }

  selectReactivationTime = action((newTime: Second) => {
    this._selectedReactivationTime = newTime;
    const {activatorSubscriptionProposalList} = this._root.proposalsState;
    const proposals = activatorSubscriptionProposalList.filter(
      (_) =>
        !_.offer.bought &&
        _.available &&
        _.offer.options?.activate_pm_after === newTime,
    );
    const candidate = minBy(proposals, (_) => _.offer.priority);
    this._selectedActivatorPurchaseId = candidate?.offer.purchaseId;
  });

  selectTab = action((newTab: PurchaseTabsKind) => {
    this._selectedTab = newTab;
  });

  selectActivatorPurchaseId = bind((id: PurchaseId) => {
    const {activatorSubscriptionProposalById} = this._root.proposalsState;
    const candidate = activatorSubscriptionProposalById?.get(id);
    if (candidate?.available) {
      runInAction(() => (this._selectedActivatorPurchaseId = id));
    }
  }, this);

  private _selectDefaultData() {
    const {
      activatorSubscriptionProposalList,
      minerSubscriptionProposalList,
      minerProductProposalList,
      doubleProposalList,
    } = this._root.proposalsState;

    let minerOffers: MinerOffer[] = [];
    if (
      minerSubscriptionProposalList.length > 0 &&
      minerProductProposalList.length > 0
    ) {
      minerOffers = doubleProposalList.flatMap((_) =>
        _.product?.available && _.subscription?.available
          ? [_.subscription.offer, _.product.offer]
          : [],
      );
    } else if (minerProductProposalList.length > 0) {
      minerOffers = minerProductProposalList.flatMap((_) =>
        _.available ? [_.offer] : [],
      );
    } else if (minerSubscriptionProposalList.length > 0) {
      minerOffers = minerSubscriptionProposalList.flatMap((_) =>
        _.available ? [_.offer] : [],
      );
    }
    const activatorOffers = activatorSubscriptionProposalList.flatMap((_) =>
      _.available && !_.offer.bought ? [_.offer] : [],
    );
    const sortedOffers = sortBy(
      minerOffers,
      (o) => o.poolMinerConfig.hash_rate,
    );
    const priorityMinerOffer =
      minBy(sortedOffers, (o) => o.priority) || sortedOffers[0];
    if (priorityMinerOffer) {
      this._selectedHashrate = priorityMinerOffer.poolMinerConfig.hash_rate;
      this._selectedInterval = priorityMinerOffer.interval;
    }

    const priorityActivatorOffer = minBy(activatorOffers, (o) => o.priority);
    this._selectedReactivationTime =
      priorityActivatorOffer?.options?.activate_pm_after;
    this._selectedActivatorPurchaseId = priorityActivatorOffer?.purchaseId;
  }

  private _fetchOnProposalLoaded() {
    if (this._root.proposalsState.isLoadedIn) {
      this._selectDefaultData();
    }
    return this._root.proposalsState.events.listen(SETTLED, () => {
      this._selectDefaultData();
    });
  }

  private _resetOnChangeFarm() {
    return reaction(
      () => this._root.auth.accountId,
      () => {
        this.reset();
      },
    );
  }

  subscribe() {
    return batchDisposers(
      this._resetOnChangeFarm(),
      this._fetchOnProposalLoaded(),
    );
  }

  init(initialSelectedTab?: PurchaseTabsKind) {
    if (initialSelectedTab) {
      runInAction(() => (this._selectedTab = initialSelectedTab));
    }
  }

  blur = action(() => {
    this._selectedTab = PurchaseTabsKind.Worker;
  });

  reset() {
    this._selectedInterval = BasePurchaseScreenStateImpl.DEFAULT_INTERVAL;
    this._selectedHashrate = undefined;
    this._selectedActivatorPurchaseId = undefined;
  }
}
