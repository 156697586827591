import {batchDisposers, Service} from '../structure';
import MetamaskService from './MetamaskService';
import {Web3Payment} from './Web3Payment';
import PaymentUrlGeneratorImpl from './PaymentUrlGeneratorImpl';

export default class Web3PaymentService implements Web3Payment, Service {
  readonly metamask = new MetamaskService();
  readonly paymentUrlGenerator = new PaymentUrlGeneratorImpl();

  subscribe() {
    return batchDisposers(this.metamask.subscribe());
  }
}
