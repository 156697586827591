import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  CursorValue,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import {clamp} from 'lodash';
import {useMeasure} from '../../../ReactNativeUtil';
import {variance} from '../../../styling';
import {expr} from 'mobx-utils';

export type SliderRef = {
  setValue: (value: number, animated?: boolean) => void;
};

export type SliderProps<V extends number = number> = {
  value?: V;
  step?: number;
  range?: [V, V];
  disabled?: boolean;
  readonly?: boolean;
  onValueChanged?: (value: number) => void;
  onValueChange?: (value: number) => void;
  dotStyle?: StyleProp<ViewStyle>;
  lineStyle?: StyleProp<ViewStyle>;
};

export const Constants = {
  defaultValue: 0,
  circleSize: 18,
  gestureSize: 50,
  range: [0, 100] as [number, number],
  step: 1,
};

export default observer<SliderProps, SliderRef>(
  forwardRef(function Slider(props, ref) {
    const {
      disabled,
      readonly,
      range = Constants.range,
      onValueChanged,
      onValueChange,
      dotStyle,
      lineStyle,
    } = props;
    const step = expr(() =>
      Math.round(Math.max(props.step ?? Constants.step, Constants.step)),
    );
    const startValue = range[0];
    const endValue = range[1];
    const diapason = endValue - startValue;
    const throughValue = clamp(
      props.value ?? Constants.defaultValue,
      startValue,
      endValue,
    );
    const rootRef = useRef<View>(null);
    const [getResult, onLayout] = useMeasure(rootRef, false);
    const width = expr(() => getResult()?.width ?? 0);
    const isPressed = useSharedValue(false);
    const startX = useSharedValue(0);

    const sliderValue = useSharedValue(throughValue);
    const previousValue = useSharedValue(throughValue); // only for onUpdateSwipe condition

    const calculateValueFromOffset = useCallback(
      (offset: number) => {
        'worklet';
        const percent = width === 0 ? 0 : (offset / width) * 100;
        const valueFromDiapason = (diapason * percent) / 100;
        const result = Math.round(valueFromDiapason / step) * Math.round(step);
        return Math.min(Math.max(startValue, result), endValue);
      },
      [diapason, endValue, startValue, step, width],
    );
    const calculateOffsetFromValue = useCallback(
      (v: number) => {
        'worklet';
        const diff = diapason - v;
        return width - width * (diff / 100);
      },
      [diapason, width],
    );

    const setNewSliderValue = useCallback(
      (v: number, animated?: boolean) => {
        const newValue = clamp(v, startValue, endValue);
        if (animated) {
          sliderValue.value = withTiming(newValue);
        } else {
          sliderValue.value = newValue;
        }
        startX.value = calculateOffsetFromValue(newValue);
      },
      [startValue, endValue, startX, calculateOffsetFromValue, sliderValue],
    );

    const onChange = useCallback(() => {
      if (
        onValueChange !== undefined &&
        previousValue.value !== sliderValue.value
      ) {
        onValueChange(sliderValue.value);
        previousValue.value = sliderValue.value;
      }
    }, [onValueChange, previousValue, sliderValue]);
    const onChanged = useCallback(() => {
      if (onValueChanged !== undefined) {
        onValueChanged(sliderValue.value);
      }
    }, [onValueChanged, sliderValue]);

    const recalculateWithValue = useCallback(
      (value: number) => {
        const offset = calculateOffsetFromValue(value);
        const newValue = calculateValueFromOffset(offset);
        sliderValue.value = newValue;
        previousValue.value = newValue;
        startX.value = offset;
      },
      [
        calculateOffsetFromValue,
        calculateValueFromOffset,
        previousValue,
        sliderValue,
        startX,
      ],
    );

    const onUpdateSwipe = useCallback(
      (translationX: number) => {
        'worklet';
        const newOffset = translationX + startX.value;
        sliderValue.value = calculateValueFromOffset(newOffset);
        runOnJS(onChange)();
      },
      [startX, sliderValue, calculateValueFromOffset, onChange],
    );
    const onEndSwipe = useCallback(
      (translationX: number) => {
        'worklet';
        const newOffset = translationX + startX.value;
        startX.value = newOffset;
        sliderValue.value = calculateValueFromOffset(newOffset);
        runOnJS(onChanged)();
      },
      [calculateValueFromOffset, onChanged, sliderValue, startX],
    );
    const onBeginSwipe = useCallback(() => {
      'worklet';
      isPressed.value = true;
    }, [isPressed]);
    const onFinalizeSwipe = useCallback(() => {
      'worklet';
      isPressed.value = false;
    }, [isPressed]);
    const panGesture = Gesture.Pan()
      .onBegin(onBeginSwipe)
      .onUpdate((e) => onUpdateSwipe(e.translationX))
      .onEnd((e) => {
        onEndSwipe(e.translationX);
      })
      .onFinalize(onFinalizeSwipe);

    const onEndTap = useCallback(
      (x: number) => {
        'worklet';
        sliderValue.value = calculateValueFromOffset(x);
        runOnJS(onChange)();
        runOnJS(onChanged)();
      },
      [calculateValueFromOffset, onChange, onChanged, sliderValue],
    );
    const singleTapGesture = Gesture.Tap().onEnd((e, success) => {
      if (success) {
        onEndTap(e.x);
      }
    });
    useImperativeHandle(ref, () => ({
      setValue: setNewSliderValue,
    }));
    useEffect(() => {
      if (!isPressed.value && width !== 0) {
        recalculateWithValue(throughValue);
      }
    }, [isPressed, recalculateWithValue, throughValue, width]);
    const dotGestureAnimatedStyles = useAnimatedStyle(
      () => ({
        transform: [{translateX: calculateOffsetFromValue(sliderValue.value)}],
      }),
      [sliderValue, width],
    );
    const activeLineAnimatedStyles = useAnimatedStyle(
      () => ({
        transform: [
          {translateX: calculateOffsetFromValue(sliderValue.value) - width},
        ],
      }),
      [sliderValue, width],
    );
    const dotAnimatedStyles = useAnimatedStyle(
      () => ({
        transform: [{scale: withSpring(isPressed.value ? 1.2 : 1)}],
      }),
      [sliderValue, width],
    );
    return (
      <View>
        <View
          style={StyleSheet.absoluteFillObject}
          onLayout={onLayout}
          ref={rootRef}
        />
        <View style={styles.root}>
          <BackgroundLineView>
            <ActiveLineView style={[activeLineAnimatedStyles, lineStyle]} />
          </BackgroundLineView>
          {!readonly && !disabled && (
            <GestureDetector gesture={singleTapGesture}>
              <View style={styles.innerTapContainer} />
            </GestureDetector>
          )}
          <Animated.View style={[styles.dotGesture, dotGestureAnimatedStyles]}>
            <DotView
              style={[dotAnimatedStyles, dotStyle]}
              withShadow={!disabled}
            />
            {!readonly && !disabled && (
              <GestureDetector gesture={panGesture}>
                <View style={styles.innerDotContainer} />
              </GestureDetector>
            )}
          </Animated.View>
        </View>
      </View>
    );
  }),
);

const DotView = variance(Animated.View)((theme) => ({
  root: {
    borderWidth: 2,
    borderColor: theme.colors.primaryAttractive,
    position: 'absolute',
    top: Constants.gestureSize / 2 - Constants.circleSize / 2,
    left: Constants.gestureSize / 2 - Constants.circleSize / 2,
    borderRadius: Constants.circleSize / 2,
    backgroundColor: theme.colors.uiMain,
    width: Constants.circleSize,
    height: Constants.circleSize,
  },
  withShadow: {
    shadowColor: theme.colors.uiSecondary,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
}));

const BackgroundLineView = variance(Animated.View)((theme) => ({
  root: {
    position: 'absolute',
    top: Constants.circleSize / 2 - 2,
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
    backgroundColor: theme.colors.uiAdditional3,
    borderRadius: 5,
    overflow: 'hidden',
  },
}));

const ActiveLineView = variance(Animated.View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.colors.primaryAttractive,
    borderRadius: 5,
  },
}));

const styles = StyleSheet.create({
  root: {
    height: Constants.circleSize,
  },
  innerTapContainer: {
    width: '100%',
    height: Constants.circleSize,
  },
  innerDotContainer: {
    height: Constants.gestureSize,
    width: Constants.gestureSize,
    ...Platform.select({
      web: {
        cursor: 'grab' as CursorValue,
      },
      default: {},
    }),
  },
  dotGesture: {
    position: 'absolute',
    top: Constants.circleSize / 2 - Constants.gestureSize / 2,
    left: -Constants.gestureSize / 2,
  },
});
