import {LinkingOptions, ParamListBase} from '@react-navigation/native';
import {Platform} from 'react-native';

const PLATFORM_PREFIXES = Platform.select({
  android: ['https://cryptofarm.page.link'],
  default: ['https://ctfarm.page.link'],
});

export const PREFIXES: LinkingOptions<ParamListBase>['prefixes'] = [
  'https://ctfarm-landing.dvlt.net',
  'https://farm-mobile.dvlt.net',
  'http://farm-mobile.dvlt.net',
  'https://farm-mobile-pro.dvlt.net',
  'http://farm-mobile-pro.dvlt.net',
  'http://localhost:19006',
  'com.ctfarm://',
  ...PLATFORM_PREFIXES,
  'https://app.cryptotabfarm.com',
  'http://app.cryptotabfarm.com',
];
