import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import GroupHeaderMenu from '../../../components/organisms/group/GroupHeaderMenu';
import {useForcedContext} from '../../../context';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import {variance} from '../../../styling';
import {useRoot, useTemplates} from '../../../Root/hooks';
import {SimpleWorkerListContext} from './context';
import HeaderBannerConditionSection from '../../components/HeaderBannerConditionSection';
import HeaderBannerConditionLargeSection from '../../components/HeaderBannerConditionLargeSection';
import {useVisibleHeaderStatusBanner} from '../../components/HeaderStatusBanner';

const PADDING_TOP = 16;

export default observer(function SimpleListHeader() {
  const {workerCount, goToPickSort} = useForcedContext(SimpleWorkerListContext);
  const {dashboardService} = useRoot();
  const templates = useTemplates();
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const title = templates['dashboard.simpleWorkerList.header.label']({
    amount: workerCount,
  });
  const visible = useVisibleHeaderStatusBanner();
  return (
    <RootView withPaddingTop={visible}>
      <View>
        {isLarge ? (
          <View style={styles.farmInfoContainer}>
            <HeaderBannerConditionLargeSection />
          </View>
        ) : (
          <HeaderBannerConditionSection />
        )}

        <ContentView large={isLarge}>
          <GroupHeaderMenu
            title={title}
            onSortPress={goToPickSort}
            order={dashboardService.order}
          />
        </ContentView>
      </View>
    </RootView>
  );
});

const styles = StyleSheet.create({
  farmInfoContainer: {
    paddingBottom: 18,
  },
});

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
  },
  withPaddingTop: {
    paddingTop: PADDING_TOP,
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {},
  large: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    borderBottomWidth: 0,
    overflow: 'hidden',
  },
}));
