import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';

import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {PoolMinerDescriptionModal} from '../../PoolMinerDescriptionModal';

export default observer(function PoolMinerDescription() {
  const modalRef = useRef<AdaptiveModalRef>(null);
  const safelyGoBack = useSafelyGoBack();
  const goBack = useCallback(async () => {
    await modalRef.current?.close();
    safelyGoBack();
  }, [safelyGoBack]);
  return (
    <PoolMinerDescriptionModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onButtonPress={goBack}
    />
  );
});
