import {createNullableContext} from '../context';
import {Order} from '../PaymentScreenContainer/OrderPaymentState';

export type PaymentScreenProps = {
  order: Order;
  payWithMetamaskVisible: boolean;
  payWithMetamaskPossible: boolean;
  payWithCtPayPossible: boolean;
  onInstallMetamaskPress: () => void;
  onWalletPayPress: () => void;
  onCtPayPress: () => void;
  onCheckPaymentPress: () => void;
};

const PaymentScreenContext = createNullableContext<PaymentScreenProps>();

export default PaymentScreenContext;
