import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  WorkerEntity,
  WorkerType,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {variance, useTheme} from '../styling';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {CheckBox, Typography} from '../components';
import {timezones} from '../ScheduleScreen/constant';
import {expr} from 'mobx-utils';
import getWorkerDescription from '../DashboardScreen/getters/getWorkerDescription';

export type WorkersListItemProps = {
  worker: WorkerEntity;
  selected: boolean;
  isTimezoneDiffer: boolean;
  isDisabled: boolean;
  onSelect: (workerId: string) => void;
};

export default observer(function WorkersListItem(props: WorkersListItemProps) {
  const {worker, selected, isTimezoneDiffer, isDisabled, onSelect} = props;
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        workerName: {
          marginLeft: 10,
        },
        offset: {
          marginLeft: 'auto',
          color:
            worker.worker_type !== WorkerType.Pool
              ? isTimezoneDiffer
                ? theme.colors.primaryError
                : theme.colors.primaryUIDirtyBlue
              : theme.colors.primaryUIDirtyBlue,
        },
        spec: {
          color: theme.colors.primaryUIDirtyBlue,
        },
      }),
    [isTimezoneDiffer, theme, worker],
  );

  const utc = useMemo(
    () => timezones.find((it) => it.offset === worker.utc_offset / 60),
    [worker],
  );

  const specs = expr(() => getWorkerDescription(worker));

  return (
    <WorkerItemView onPress={() => onSelect(worker.id)} activeOpacity={0.8}>
      <WorkerItemTop>
        {!isDisabled && <CheckBox checked={selected} />}
        <Typography
          type="paragraph"
          size="large"
          weight="500"
          style={styles.workerName}>
          {worker.name ?? worker.host_info?.hostname}
        </Typography>
        <Typography
          type="paragraph"
          size="large"
          weight="500"
          style={styles.offset}>
          {utc?.name}
        </Typography>
      </WorkerItemTop>
      <WorkerSpecView>
        <Typography
          type="paragraph"
          size="tiny"
          weight="500"
          style={styles.spec}>
          {specs}
        </Typography>
      </WorkerSpecView>
    </WorkerItemView>
  );
});

const WorkerItemView = variance(TouchableOpacity)(() => ({
  root: {
    paddingVertical: 15,
  },
}));

const WorkerItemTop = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const WorkerSpecView = variance(View)(() => ({
  root: {
    marginTop: 5,
  },
}));
