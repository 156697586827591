import {Service} from '../structure';
import {MetamaskConnector, SwitchNetworkResult} from './MetamaskConnector';
import {NOT_IMPLEMENTED_ON_PLATFORM} from './ErrorNormalizer';

export default class SubMetamaskConnectorService
  implements Service, MetamaskConnector
{
  readonly detected = false;
  readonly connected = false;

  async connect(): Promise<SwitchNetworkResult> {
    throw NOT_IMPLEMENTED_ON_PLATFORM;
  }

  async switchToNetwork(): Promise<SwitchNetworkResult> {
    throw NOT_IMPLEMENTED_ON_PLATFORM;
  }

  subscribe() {}
}
