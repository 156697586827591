import {RouteProp} from '@react-navigation/core/lib/typescript/src/types';
import {StackNavigationProp} from '@react-navigation/stack';
import useGetMobileScreenOptions from './useGetMobileScreenOptions';
import {getFocusedRouteNameFromRoute} from '@react-navigation/native';
import {useCallback} from 'react';
import {AppForceParamList} from '../AppForceParamList';
import {RootParamList} from '../RootStack';
import {AppRouteName} from '../AppRouteName';
import {useRoot} from '../../Root/hooks';
import useGetTabletScreenOptions from './useGetTabletScreenOptions';

export type GetScreenOptionsHookParams = {
  route: RouteProp<RootParamList, keyof RootParamList>;
  navigation: StackNavigationProp<AppForceParamList>;
};

export function useGetCommonScreenOptions() {
  const getScreenOptions = useBaseScreenOptions();
  return useCallback(
    ({route, navigation}: GetScreenOptionsHookParams) => {
      const routeName = (route.name as AppRouteName) ?? 'Dashboard';
      return getScreenOptions({navigation, routeName, goBackPossible: true});
    },
    [getScreenOptions],
  );
}

export type GetTabBarScreenOptionsHookParams = {
  route: RouteProp<RootParamList, keyof RootParamList>;
  navigation: StackNavigationProp<AppForceParamList>;
};

export function useGetTabBarScreenOptions() {
  const getScreenOptions = useBaseScreenOptions();
  return useCallback(
    ({route, navigation}: GetTabBarScreenOptionsHookParams) => {
      const routeName =
        (getFocusedRouteNameFromRoute(route) as AppRouteName) ?? 'Dashboard';
      return getScreenOptions({navigation, routeName, goBackPossible: false});
    },
    [getScreenOptions],
  );
}

type BaseScreenOptionsHookParams = {
  routeName: AppRouteName;
  navigation: StackNavigationProp<AppForceParamList>;
  goBackPossible: boolean;
};

function useBaseScreenOptions() {
  const {
    appTemplateState: {isTablet},
  } = useRoot();
  const getMobileOptions = useGetMobileScreenOptions();
  const getTabletOptions = useGetTabletScreenOptions();
  return useCallback(
    ({routeName, navigation, goBackPossible}: BaseScreenOptionsHookParams) => {
      const options = isTablet
        ? getTabletOptions({navigation, routeName, goBackPossible})
        : getMobileOptions({
            navigation,
            routeName,
            goBackPossible,
          });
      return {
        ...options,
        animationEnabled: !isTablet,
        cardStyle: {
          flex: 1, // Fix infinite FlatList expansion on mobile web
        },
      };
    },
    [getMobileOptions, getTabletOptions, isTablet],
  );
}
