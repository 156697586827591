import React, {forwardRef, useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import FolderSvg from '../assets/svg/twoTone/folder.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {TwoToneIcon} from '../components/atoms';
import {SvgProps} from 'react-native-svg';
import {AdaptiveModalRef} from '../components/templates';
import {sized} from '../Svg';
import {AdaptiveInputModal} from '../components/organisms';
import {useStrings} from '../Root/hooks';
import {expr} from 'mobx-utils';
import TextInputState from '../forms/TextInputState';

export type BaseWorkerGroupNameModalProps = {
  onModalClosed: () => void;
  onConfirm: (groupName: string) => void;
  onCancel: () => void;
  title: string;
  description?: string;
  inputPlaceholder: string;
  buttonLabel: string;
  initialGroupName?: string;
};

export default observer<BaseWorkerGroupNameModalProps, AdaptiveModalRef>(
  forwardRef(function AddNewWorkerGroupModal(
    {
      onConfirm,
      onCancel,
      onModalClosed,
      title,
      description,
      inputPlaceholder,
      buttonLabel,
      initialGroupName = '',
    },
    ref,
  ) {
    const [state] = useState(
      () => new TextInputState({defaultValue: initialGroupName}),
    );
    const getInputValue = useCallback(() => state.value ?? '', [state]);
    const strings = useStrings();
    const handleSubmit = useCallback(
      () => onConfirm(getInputValue()),
      [getInputValue, onConfirm],
    );
    const isEmpty = expr(() => getInputValue().length === 0);
    return (
      <AdaptiveInputModal
        ref={ref}
        title={title}
        description={description}
        IconSvg={FolderIcon}
        IconSvgModalRep={FolderIconLarge}
        onModalClosed={onModalClosed}
        placeholder={inputPlaceholder}
        onChangeInputValue={state.setValue}
        getInputValue={getInputValue}
        actions={[
          <AppButton onPress={onCancel}>{strings['action.cancel']}</AppButton>,
          <AppButton
            disabled={isEmpty}
            onPress={handleSubmit}
            variant={ButtonVariant.Primary}>
            {buttonLabel}
          </AppButton>,
        ]}
      />
    );
  }),
);

const FolderIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(FolderSvg, 25, 21)} {...props} />
));

const FolderIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(FolderSvg, 38, 31)} {...props} />
));
