import {useStyles, useTheme} from '../../styling';
import {TextStyle, ViewStyle} from 'react-native';
import {ButtonProps} from './AppButton';

export enum ButtonSize {
  Default,
  Small,
}

export enum ButtonVariant {
  Primary,
  Success,
  Error,
  Default,
  Text,
  GreenGradient,
}

export type ButtonStyles = {
  root: ViewStyle | ViewStyle[];
  text: TextStyle | TextStyle[];
  icon: TextStyle | TextStyle[];
};
export const useGetGradientStyles = (variant: ButtonVariant) => {
  const theme = useTheme();
  switch (variant) {
    case ButtonVariant.Primary:
      return {
        getColor: (isHovered: boolean) => [
          !isHovered
            ? theme.colors.gradientBtnYellow[0]
            : theme.colors.uiSecondary,
          !isHovered
            ? theme.colors.gradientBtnYellow[1]
            : theme.colors.uiSecondary,
        ],
        start: {x: 0.5, y: 0},
        end: {x: 0.5, y: 1},
        hoveredTextColor: theme.colors.primaryWhite,
      };
    case ButtonVariant.GreenGradient:
      return {
        getColor: (isHovered: boolean) => [
          !isHovered
            ? theme.colors.gradientBtnGreen[0]
            : theme.colors.uiSecondary,
          !isHovered
            ? theme.colors.gradientBtnGreen[1]
            : theme.colors.uiSecondary,
        ],
        start: {x: 0, y: 0},
        end: {x: 1, y: 1},
        hoveredTextColor: theme.colors.uiMain,
      };
    default:
      return undefined;
  }
};
export const useButtonStyles = (props: ButtonProps): ButtonStyles => {
  const {variant, size, disabled, children} = props;
  const styles = useStyles((theme) => ({
    defaultRoot: {
      backgroundColor: theme.colors.uiAdditional1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingVertical: 13,
      paddingHorizontal: 15,
      borderRadius: 8,
    },
    smallRoot: {
      paddingVertical: 8,
    },
    defaultText: {
      color: theme.colors.uiSecondary,
      fontSize: 16,
      lineHeight: 17,
    },
    smallText: {
      fontSize: 12,
      lineHeight: 15,
    },
    defaultIcon: {
      marginRight: 5,
      color: theme.colors.uiSecondary,
    },
    noIconMargin: {
      marginRight: 0,
    },
    primaryRoot: {
      backgroundColor: theme.colors.primaryAttractive,
    },
    textRoot: {
      backgroundColor: 'transparent',
    },
    textText: {
      color: theme.colors.uiSecondary,
    },
    textIcon: {
      color: theme.colors.uiSecondary,
    },
    primaryText: {
      color: theme.colors.primaryBlack,
    },
    primaryIcon: {
      color: theme.colors.primaryBlack,
    },
    successRoot: {
      backgroundColor: theme.colors.primarySuccess,
    },
    successText: {
      color: theme.colors.primaryWhite,
    },
    successIcon: {
      color: theme.colors.primaryWhite,
    },
    errorRoot: {
      backgroundColor: theme.colors.primaryError,
    },
    errorText: {
      color: theme.colors.primaryWhite,
    },
    errorIcon: {
      color: theme.colors.primaryWhite,
    },
    disabledRoot: {
      opacity: 0.5,
    },
    greenGradientText: {
      color: theme.colors.primaryBlack,
    },
    greenGradientIcon: {
      color: theme.colors.primaryBlack,
    },
  }));
  let themedStyles;
  switch (variant) {
    case ButtonVariant.Primary:
      themedStyles = {
        root: styles.primaryRoot,
        text: styles.primaryText,
        icon: styles.primaryIcon,
      };
      break;
    case ButtonVariant.Success:
      themedStyles = {
        root: styles.successRoot,
        text: styles.successText,
        icon: styles.successIcon,
      };
      break;
    case ButtonVariant.Error:
      themedStyles = {
        root: styles.errorRoot,
        text: styles.errorText,
        icon: styles.errorIcon,
      };
      break;
    case ButtonVariant.Text:
      themedStyles = {
        root: styles.textRoot,
        text: styles.textText,
        icon: styles.textIcon,
      };
      break;
    case ButtonVariant.GreenGradient:
      themedStyles = {
        root: styles.defaultRoot,
        text: styles.greenGradientText,
        icon: styles.greenGradientIcon,
      };
  }
  let resultStyles;
  if (themedStyles) {
    resultStyles = {
      root: [styles.defaultRoot, themedStyles.root],
      text: [styles.defaultText, themedStyles.text],
      icon: [styles.defaultIcon, themedStyles.icon],
    };
  } else {
    resultStyles = {
      root: [styles.defaultRoot],
      text: [styles.defaultText],
      icon: [styles.defaultIcon],
    };
  }

  if (size === ButtonSize.Small) {
    resultStyles = {
      ...resultStyles,
      root: [...resultStyles.root, styles.smallRoot],
      text: [...resultStyles.text, styles.smallText],
    };
  }

  if (disabled) {
    resultStyles = {
      ...resultStyles,
      root: [...resultStyles.root, styles.disabledRoot],
    };
  }
  if (children === undefined) {
    resultStyles = {
      ...resultStyles,
      icon: [...resultStyles.icon, styles.noIconMargin],
    };
  }

  return resultStyles;
};
