import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {variance} from '../../../../styling';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import WorkerMiningInfo from '../../../../components/organisms/worker/WorkerMiningInfo';
import useGetIsLarge from '../../../hooks/useGetIsLarge';
import {Divider} from '../../../../components/atoms';
import {expr} from 'mobx-utils';

export type MiningInfoProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(({worker}: MiningInfoProps) => {
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  return (
    <RootView large={isLarge}>
      <ContentView large={isLarge}>
        <WorkerMiningInfo
          mode={isLarge ? 'vertical' : 'horizontal'}
          worker={worker}
        />
      </ContentView>
      {!isLarge && <Divider />}
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {},
  large: {
    width: 230,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    paddingBottom: 12,
  },
  large: {
    paddingBottom: 0,
  },
}));
