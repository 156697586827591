import React, {forwardRef, useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import {StyleSheet, View} from 'react-native';
import {getCommissionerFontStyles, Typography} from '../components';
import WalletSvg from '../assets/svg/colorless/wallet.svg';
import {createStylesHook, useTheme} from '../styling';
import {AppButton, ButtonVariant} from '../components/AppButton';
import WalletsList from './WalletsList';
import {Wallet} from '../Navigation/RootStack/ConnectWalletBinding';
import {useStrings} from '../Root/hooks';

export type ConnectWalletModalProps = {
  availableWallets: Wallet[];
  onModalClosed: () => void;
  onConfirm: (selectedWallet: Wallet) => void;
};

export default observer<ConnectWalletModalProps, AdaptiveModalRef>(
  forwardRef(function ConnectWalletModal({onModalClosed, ...rest}, ref) {
    return (
      <AdaptiveModal
        modalWidth={ModalWidth.Small}
        enableContentAutoHeight
        onModalClosed={onModalClosed}
        ref={ref}>
        <ContentView {...rest} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  (props: Omit<ConnectWalletModalProps, 'onModalClosed'>) => {
    const {availableWallets, onConfirm} = props;
    const theme = useTheme();
    const strings = useStrings();
    const themedStyles = useStyles();
    const paddingBottom = useSafePaddingBottom(16);
    const [selectedWallet, setSelectedWallet] = useState<Wallet>(
      availableWallets[0],
    );

    const handleWalletSelect = useCallback((wallet: Wallet) => {
      setSelectedWallet(wallet);
    }, []);

    const handleConfirm = useCallback(() => {
      onConfirm(selectedWallet);
    }, [onConfirm, selectedWallet]);

    return (
      <View style={[styles.content, {paddingBottom}]}>
        <View style={styles.icon}>
          <WalletSvg color={theme.colors.uiSecondary} />
        </View>
        <View style={styles.top}>
          <Typography type="header" size="h3">
            {strings['modal.connectWallet.title']}
          </Typography>
        </View>
        <View style={styles.modalBody}>
          <Typography type="header" size="h4" style={themedStyles.description}>
            {strings['modal.connectWallet.description']}
          </Typography>
          <WalletsList
            wallets={availableWallets}
            selected={selectedWallet}
            onPress={handleWalletSelect}
          />
        </View>
        <View style={styles.modalFooter}>
          <AppButton variant={ButtonVariant.Primary} onPress={handleConfirm}>
            {strings['modal.connectWallet.confirm']}
          </AppButton>
        </View>
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  description: {
    maxWidth: 230,
    marginLeft: 'auto',
    marginRight: 'auto',
    ...getCommissionerFontStyles('500'),
    fontSize: 12,
    lineHeight: 20,
    color: theme.colors.primaryUIDirtyBlue,
    textAlign: 'center',
  },
}));

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  icon: {
    paddingTop: 40,
    alignItems: 'center',
  },
  top: {
    paddingTop: 10,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  modalBody: {
    marginTop: 10,
    paddingHorizontal: 30,
    marginBottom: 15,
  },
  modalFooter: {
    paddingHorizontal: 16,
  },
});
