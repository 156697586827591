import React from 'react';
import {StyleSheet} from 'react-native';
import {observer} from 'mobx-react-lite';
import MiningInfo from './MiningInfo';
import PowerSlider from './PowerSlider';
import {View} from 'react-native';
import Configuration from './Configuration';
import MenuHeader from './MenuHeader';
import {useForcedContext} from '../context';
import {WorkerDetailsContext} from './context';
import {variance} from '../styling';
import WorkerStatusBackground from '../components/organisms/worker/WorkerStatusBackground';
import useGetIsLarge from '../DashboardScreen/hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import WorkerInfo from './WorkerInfo';
import ButtonList from './ButtonList';
import {ScrollView} from 'react-native-gesture-handler';

export default observer(function Content() {
  const {worker, gotToDelete} = useForcedContext(WorkerDetailsContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());

  return (
    <ScrollView
      contentContainerStyle={[
        styles.container,
        isLarge && styles.containerLarge,
      ]}>
      <ContentView large={isLarge}>
        <WorkerStatusBackground worker={worker}>
          <MenuHeader worker={worker} />
          <BodyView large={isLarge}>
            <MiningInfo worker={worker} />
            <PowerSlider worker={worker} />
            <Configuration worker={worker} />
          </BodyView>
        </WorkerStatusBackground>
        <WorkerInfo worker={worker} />
        <ButtonList removeWorker={gotToDelete} worker={worker} />
      </ContentView>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  containerLarge: {
    paddingHorizontal: 18,
  },
});

const ContentView = variance(View)((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: theme.colors.uiMain,
  },
  large: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.uiAdditional1,
    borderRightWidth: 1,
    borderRightColor: theme.colors.uiAdditional1,
    borderRadius: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.uiAdditional1,
  },
}));

const BodyView = variance(View)(() => ({
  root: {
    flexGrow: 1,
    paddingTop: 12,
    paddingBottom: 14,
  },
  large: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
