import React from 'react';
import {StyleProp, TextStyle, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';

import Switch, {SwitchComponentProps} from '../../components/Switch';

import {useStyles} from '../../styling';
import {Divider} from '../../components/atoms';
import {Header} from '../../components';

export type ListItemProps = SwitchComponentProps &
  ViewProps & {
    title: string;
    titleStyle?: StyleProp<TextStyle>;
    separated?: boolean;
    dotColor?: string;
  };

export default observer(function ListItem({
  title,
  titleStyle,
  style,
  separated,
  dotColor,
  ...rest
}: ListItemProps) {
  const styles = useStyles((theme) => ({
    root: {
      paddingHorizontal: 20,
    },
    contentView: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      paddingVertical: 15,
      borderBottomColor: theme.colors.uiAdditional1,
    },
    title: {
      flexShrink: 1,
      fontSize: 16,
      lineHeight: 20,
      color: theme.colors.uiSecondary,
    },
    textView: {
      flex: 1,
      marginRight: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
    dot: {
      width: 12,
      height: 12,
      borderRadius: 12,
      marginRight: 9,
    },
  }));
  return (
    <View style={[styles.root, style]}>
      <View style={styles.contentView}>
        <View style={styles.textView}>
          {dotColor && (
            <View style={[styles.dot, {backgroundColor: dotColor}]} />
          )}
          <Header
            type="header"
            size="h4"
            weight="500"
            style={[styles.title, titleStyle]}>
            {title}
          </Header>
        </View>
        <Switch {...rest} />
      </View>
      {separated && <Divider />}
    </View>
  );
});
