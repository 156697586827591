import {TouchableOpacity, View, ViewProps} from 'react-native';
import React, {PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../styling';
import {ROUTE_NAME_BY_KEY} from './constants';
import {StackElementKey} from '../../../InteractiveTutorial';
import useTutorialMeasure from '../../../InteractiveTutorial/useTutorialMeasure';
import {BottomTabNavigationOptions} from '@react-navigation/bottom-tabs';
import {AppRouteName} from '../../../Navigation/AppRouteName';

export type MobileTabButtonProps = {
  isFocused: boolean;
  options: BottomTabNavigationOptions;
  onPress: () => void;
  routeName: AppRouteName;
};

export default observer(function MobileTabButton(
  props: PropsWithChildren<MobileTabButtonProps>,
) {
  const {routeName} = props;
  const tutorialKey = ROUTE_NAME_BY_KEY.get(routeName);
  if (tutorialKey !== undefined) {
    return <TutorialTabButton tutorialKey={tutorialKey} {...props} />;
  }
  return <TabButton {...props} />;
});

type TutorialTabButtonProps = MobileTabButtonProps & {
  tutorialKey: StackElementKey;
};
const TutorialTabButton = observer(
  (props: PropsWithChildren<TutorialTabButtonProps>) => {
    const {tutorialKey, ...rest} = props;
    const [ref, onLayout, getForceKey] =
      useTutorialMeasure<View>(tutorialKey);
    return (
      <TabButton
        buttonRef={ref}
        onLayout={onLayout}
        key={getForceKey()}
        {...rest}
      />
    );
  },
);

type TabButtonProps = MobileTabButtonProps &
  ViewProps & {
    buttonRef?: React.RefObject<View>;
  };
const TabButton = observer((props: PropsWithChildren<TabButtonProps>) => {
  const {children, isFocused, options, onPress, buttonRef, ...rest} = props;
  return (
    <PressableTabButton
      ref={buttonRef}
      activeOpacity={0.9}
      accessibilityRole="button"
      accessibilityState={isFocused ? {selected: true} : {}}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      testID={options.tabBarTestID}
      onPress={onPress}
      {...rest}>
      {children}
    </PressableTabButton>
  );
});

const PressableTabButton = variance(TouchableOpacity)(() => ({
  root: {
    paddingHorizontal: 10,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
