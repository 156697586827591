import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';

import {View} from 'react-native';
import {useRoot, useStrings} from '../Root/hooks';
import {createStylesHook} from '../styling';
import ScheduleState from './ScheduleState';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {LG_BREAKPOINT, XL_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {useErrorHandler} from '../ExceptionHandler';

export type SchedulerFooterProps = {
  state: ScheduleState;
  leave: () => void;
};

export default observer(function SchedulerFooter({
  state,
  leave,
}: SchedulerFooterProps) {
  const strings = useStrings();
  const styles = useStyles();
  const {appTemplateState} = useRoot();
  const isLg = appTemplateState.isTablet;
  const errorHandler = useErrorHandler();
  const onSave = useCallback(async () => {
    const res = await state.save();
    if (res?.success) {
      errorHandler.handle(res.left);
    }
    leave();
  }, [errorHandler, leave, state]);

  return (
    <View style={styles.root}>
      {isLg && (
        <AppButton
          onPress={leave}
          variant={ButtonVariant.Default}
          style={styles.button}>
          {strings['action.cancel']}
        </AppButton>
      )}
      <AppButton
        disabled={state.numberOfChangedPoints === 0}
        variant={ButtonVariant.Primary}
        onPress={onSave}
        style={[styles.button, styles.last]}>
        {strings['action.save']}
      </AppButton>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      [XL_BREAKPOINT]: {
        justifyContent: 'flex-start',
      },
    }),
  },
  button: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        minWidth: 330,
      },
    }),
  },
  last: {
    ...theme.mediaQuery({
      [XL_BREAKPOINT]: {
        marginLeft: 15,
      },
    }),
  },
}));
