import getItemLayout from '../../getters/getItemLayout';
import {GroupSection, WorkerItem} from './formSections';
import {useGetSectionListHeaderHeight} from './SectionListHeader';
import {useGetDetailedSlotHeight} from './DetailSlot';
import {useGetSectionListFooterHeight} from './SectionListFooter';
import {useGetSectionGroupHeaderHeight} from './SectionGroupHeader';
import {useGetSectionGroupFooterHeight} from './SectionGroupFooter';

export default function useGetItemLayout() {
  const getDetailedSlotHeight = useGetDetailedSlotHeight();
  const getSectionGroupHeight = useGetSectionGroupHeaderHeight();
  const getSectionGroupFooterHeight = useGetSectionGroupFooterHeight();
  const getSectionListHeaderHeight = useGetSectionListHeaderHeight();
  const getSectionListFooterHeight = useGetSectionListFooterHeight();
  return getItemLayout<WorkerItem, GroupSection>({
    getItemHeight: () => getDetailedSlotHeight(),
    getSectionHeaderHeight: getSectionGroupHeight,
    getSectionFooterHeight: (sectionData) =>
      getSectionGroupFooterHeight(sectionData.isLast),
    listHeaderHeight: getSectionListHeaderHeight(),
    listFooterHeight: getSectionListFooterHeight(),
  });
}
