import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, ViewProps} from 'react-native';
import ColoredBanner, {
  ARROWS_ICON,
  DefaultButton,
  EMERALD_BACKGROUND,
  ShadowColor,
} from '../../molecules/ColoredBanner';
import {useStrings} from '../../../Root/hooks';

export type ReactivationBannerProps = ViewProps & {
  onButtonPress: () => void;
};

export default observer(function ReactivationBanner({
  onButtonPress,
  ...rest
}: ReactivationBannerProps) {
  const strings = useStrings();
  return (
    <ColoredBanner
      title={strings['reactivationBanner.text']}
      background={EMERALD_BACKGROUND}
      shadowColor={ShadowColor.Green}
      icon={ARROWS_ICON}
      textStyle={styles.text}
      actions={[
        <DefaultButton
          onPress={onButtonPress}
          title={strings['reactivationBanner.button']}
        />,
      ]}
      {...rest}
    />
  );
});

const styles = StyleSheet.create({
  text: {
    maxWidth: 320,
  },
});
