import {AdId, AdItem} from '../ApiStore';
import {Uri} from '../units';
import {StaticAdvertHelper} from '../Advert';
import {AdInfoItem} from '../Advert';

export default abstract class StaticAdvertCreatorHelper {
  private static _translateAdItemToAdvertItem(_: AdItem): AdInfoItem {
    const image = StaticAdvertHelper.getImage(_.image);
    const icon = StaticAdvertHelper.getImage(_.icon);
    return {
      id: _.id,
      spot: _.spot,
      type: _.type,
      size: _.size,
      title: _.title,
      body: _.body,
      image: image as Uri,
      icon: icon as Uri,
      link: _.link || undefined,
      options: {
        canClose: _.options.can_close ?? true,
        closeTime: _.options.close_time || undefined,
        force: _.options.force,
        canExclude: _.options.can_exclude,
      },
      actions: _.actions.map((action) => ({
        title: action.title,
        link: action.link || undefined,
        icon: action.icon,
      })),
    };
  }

  static process(_: AdItem[]) {
    const translatedItems = _.map(this._translateAdItemToAdvertItem);
    const map = new Map<AdId, AdInfoItem[]>();
    for (const item of translatedItems) {
      const old = map.get(item.id);
      if (old) {
        map.set(item.id, [...old, item]);
      } else {
        map.set(item.id, [item]);
      }
    }
    return map;
  }
}
