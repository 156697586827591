import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet} from 'react-native';
import QuickStartScreenView, {
  QuickStartScreenViewProps,
} from './QuickStartScreenView';
import {variance} from '../styling';
import {ScrollView} from 'react-native-gesture-handler';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export default observer(function QuickStartScreen(
  props: QuickStartScreenViewProps,
) {
  const insets = useSafeAreaInsets();
  return (
    <LgRoot contentContainerStyle={[styles.container, {paddingBottom: insets.bottom}]}>
      <QuickStartScreenView {...props} />
    </LgRoot>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

const LgRoot = variance(ScrollView)((theme) => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 16,
      },
    }),
  },
}));
