import React from 'react';
import {View, ViewProps} from 'react-native';
import {Paragraph, Header as TypoHeader} from './Typography';
import {observer} from 'mobx-react-lite';
import {useStyles, variance} from '../styling';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import AuthScreenImage from './AuthScreenImage/AuthScreenImage';

export type ScreenPanelProps = ViewProps & {
  title: string;
  description: string;
  footer: React.ReactNode;
};

export default observer((props: ScreenPanelProps) => {
  const {title, description, footer} = props;
  const styles = useStyles((theme) => ({
    title: {
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          fontSize: 32,
          lineHeight: 40,
        },
      }),
    },
    description: {
      textAlign: 'center',
      marginTop: 18,
      marginBottom: 24,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          fontSize: 16,
          lineHeight: 26,
        },
      }),
    },
  }));
  return (
    <RootView>
      <ContentView>
        <AuthScreenImage />
        <TypoHeader type="header" size="h3" weight="500">
          {title}
        </TypoHeader>
        <Paragraph
          style={styles.description}
          type="paragraph"
          size="large"
          weight="400">
          {description}
        </Paragraph>
      </ContentView>
      <FooterView>{footer}</FooterView>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    overflow: 'hidden',
    justifyContent: 'center',
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    alignItems: 'center',
    paddingHorizontal: 47,
  },
}));

const FooterView = variance(View)(() => ({
  root: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));
