import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Message} from '../FlashMessageService';
import FlashMessageAnimated from './FlashMessageAnimated';
import MessageView from './MessageView';

export type FlashMessageItemProps = {
  message: Message;
  onDismiss: (id: string) => void;
};

export default observer(function FlashMessageItem(
  props: FlashMessageItemProps,
) {
  const {message, onDismiss} = props;

  useEffect(() => {
    const timer = setTimeout(() => onDismiss(message.id), message.timeout);
    return () => clearTimeout(timer);
  }, [message, onDismiss]);

  const handleAnimatedEnd = useCallback(() => {
    onDismiss(message.id);
  }, [onDismiss, message]);

  return (
    <FlashMessageAnimated onGestureEnd={handleAnimatedEnd}>
      <MessageView message={message} onDismiss={onDismiss} />
    </FlashMessageAnimated>
  );
});
