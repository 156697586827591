import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {Pressable, Text, View} from 'react-native';
import {getCommissionerFontStyles} from '../components';
import DecorationSvg from '../assets/svg/colorless/tabsDecoration.svg';
import {useStrings} from '../Root/hooks';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {PurchaseTabsKind} from './PurchaseScreenState';
import {sized} from '../Svg';

export type PurchaseTabsProps = {
  selectedTab: PurchaseTabsKind;
  onChange: (newTab: PurchaseTabsKind) => void;
  reactivationBadgeVisible: boolean;
};

export default observer(function TabsView({
  selectedTab,
  onChange,
  reactivationBadgeVisible,
}: PurchaseTabsProps) {
  const theme = useTheme();
  const strings = useStrings();
  return (
    <RootView>
      <TabItemPressable
        active={selectedTab === PurchaseTabsKind.Worker}
        onPress={() => onChange(PurchaseTabsKind.Worker)}>
        <TabItemText active={selectedTab === PurchaseTabsKind.Worker}>
          {strings['purchase.poolMiners.active']}
        </TabItemText>
        <DecorationView>
          <SizedDecorationSvg color={theme.colors.primaryAttractive} />
        </DecorationView>
      </TabItemPressable>
      <TabItemPressable
        active={selectedTab === PurchaseTabsKind.Reactivate}
        onPress={() => onChange(PurchaseTabsKind.Reactivate)}>
        <DecorationView left>
          <SizedDecorationSvg color={theme.colors.primaryAttractive} />
        </DecorationView>
        <TabItemText active={selectedTab === PurchaseTabsKind.Reactivate}>
          {strings['purchase.reactivation']}
        </TabItemText>
        {reactivationBadgeVisible && (
          <TagPositionContainer>
            <TagView>
              <Tag>{strings['purchase.freeTrial']}</Tag>
            </TagView>
          </TagPositionContainer>
        )}
      </TabItemPressable>
    </RootView>
  );
});

const SizedDecorationSvg = sized(DecorationSvg, 23);

const TagPositionContainer = variance(View)(() => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -5,
    alignItems: 'center',
  },
}));

const TagView = variance(View)((theme) => ({
  root: {
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 8,
    backgroundColor: theme.colors.primarySuccess,
  },
}));

const Tag = variance(Text)((theme) => ({
  root: {
    ...getCommissionerFontStyles('500'),
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 12,
    color: theme.colors.primaryWhite,
  },
}));

const RootView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    backgroundColor: theme.colors.primaryNew,
    marginBottom: -20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 360,
      },
    }),
  },
}));

const TabItemPressable = variance(Pressable)((theme) => ({
  root: {
    paddingVertical: 13,
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 30,
  },
  active: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: theme.colors.primaryAttractive,
  },
}));

const TabItemText = variance(Text)((theme) => ({
  root: {
    ...getCommissionerFontStyles('600'),
    textAlign: 'center',
    color: theme.colors.primaryWhite,
    fontSize: 13,
    lineHeight: 18,
  },
  active: {
    color: theme.colors.primaryBlack,
    fontSize: 16,
    lineHeight: 20,
    fontWeight: '700',
  },
}));

const DecorationView = variance(View)(() => ({
  root: {
    position: 'absolute',
    bottom: 20,
    right: -23,
  },
  left: {
    right: 'auto',
    left: -23,
    transform: [{rotate: '-90deg'}],
  },
}));
