import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import AdvertSplashModalView, {
  AdvertSplashModalViewProps,
} from './AdvertSplashModalView';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  Representation,
} from '../components/templates';
import {useRoot} from '../Root/hooks';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export type AdvertSplashScreenProps = AdvertSplashModalViewProps & {
  onModalClosed: () => void;
};

export const ADVERT_SPLASH_DESKTOP_BREAKPOINT = MD_BREAKPOINT;

export default observer<AdvertSplashScreenProps, AdaptiveModalRef>(
  forwardRef(function AdvertSplashScreen({onModalClosed, ...rest}, ref) {
    const {windowDimensionsState} = useRoot();
    const width = windowDimensionsState.window.width - 20;
    return (
      <AdaptiveModal
        ref={ref}
        closeIconHidden
        enableContentAutoHeight
        modalContentStyle={{
          width: width,
          maxWidth: 600,
        }}
        representation={Representation.Modal}
        onModalClosed={onModalClosed}>
        <AdvertSplashModalView {...rest} />
      </AdaptiveModal>
    );
  }),
);
