import React, {useCallback, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {ScheduleScreen} from '../../ScheduleScreen';
import {useSafelyGoBack} from '../hooks';
import ScheduleState from '../../ScheduleScreen/ScheduleState';
import useBeforeRemoveWatcher from '../../ScheduleScreen/useBeforeRemoveWatcher';
import useScheduleUpdateMessage from '../../ScheduleScreen/useScheduleUpdateMessage';
import useUnsavedDataWatcher from '../../ScheduleScreen/useUnsavedDataWatcher';
import {useRoot} from '../../Root/hooks';

export type ScheduleBindingProps = RootStackBindingProps<'Schedule'>;

export default observer(function ScheduleBinding({
  route,
  navigation,
}: ScheduleBindingProps) {
  const {
    workerIds = [],
    selectedWorkerIds,
    pickedSpeed,
    pickedTimezone,
    ownerName,
  } = route.params ?? {};
  const safelyGoBack = useSafelyGoBack();
  const root = useRoot();
  const navigationRef = useRef(navigation);
  navigationRef.current = navigation;
  const [state] = useState(() => new ScheduleState(root, workerIds));

  const leave = useCallback(() => safelyGoBack(), [safelyGoBack]);

  useEffect(() => {
    if (pickedSpeed !== undefined) {
      state.changeSpeed({speed: pickedSpeed, custom: true});
    }

    if (pickedTimezone !== undefined) {
      state.changeTimezoneOffset(pickedTimezone * 60);
    }

    if (selectedWorkerIds) {
      state.setSelectedWorkerIds(new Set(selectedWorkerIds));
    }
  }, [state, pickedSpeed, pickedTimezone, selectedWorkerIds]);

  useEffect(() => state.subscribe(), [state]);

  const routeRef = useRef(route);
  routeRef.current = route;

  const goToThisScreen = useCallback(
    () => navigationRef.current?.navigate(routeRef.current),
    [],
  );

  const onSubmitLeaveScheduleUnsaved = useCallback(() => {
    goToThisScreen();
    state.reset();
    safelyGoBack();
  }, [goToThisScreen, safelyGoBack, state]);

  const goToLeaveScheduleUnsaved = useCallback(() => {
    navigation.navigate('LeaveScheduleUnsaved', {
      ownerName,
      onSuccess: onSubmitLeaveScheduleUnsaved,
    });
  }, [navigation, onSubmitLeaveScheduleUnsaved, ownerName]);

  useBeforeRemoveWatcher(state, goToLeaveScheduleUnsaved);
  useScheduleUpdateMessage(state.workers);
  useUnsavedDataWatcher(state);

  const selectScheduleSpeed = useCallback(() => {
    navigation.navigate('PickScheduleSpeed', {nextParams: route.params});
  }, [navigation, route.params]);

  const selectScheduleTimezone = useCallback(() => {
    navigation.navigate('PickScheduleTimezone', {nextParams: route.params});
  }, [navigation, route.params]);

  const selectScheduleWorkers = useCallback(() => {
    navigation.navigate('PickScheduleWorkers', {
      workerIds,
      selectedWorkerIds: selectedWorkerIds ?? workerIds,
      pickedTimezone,
      nextParams: route.params,
    });
  }, [navigation, workerIds, selectedWorkerIds, pickedTimezone, route.params]);

  return (
    <ScheduleScreen
      state={state}
      leave={leave}
      onSpeedSelect={selectScheduleSpeed}
      onTimezoneSelect={selectScheduleTimezone}
      onWorkersSelect={selectScheduleWorkers}
    />
  );
});
