import {useRoot} from '../Root/hooks';
import useHandleErrorAndExtractPromo from './useHandleErrorAndExtractPromo';
import {useCallback} from 'react';
import dayjs from 'dayjs';
import {ISODateString} from '../Time';
import {Offer, OfferType, PaymentMethod} from '../InAppOffersService';
import {OrderId} from '../ApiStore/units/order';
import {PaymentType} from './ContextPurchaseResponse';
import {ErrorHandler} from '../ExceptionHandler';
import {openLink} from '../Links';

export type HookRenewPoolMinerParams = {
  errorHandler: ErrorHandler;
};

export type RenewPoolMinerParams = {
  offer: Offer;
  paymentMethod: PaymentMethod;
  goToPoolMinerActivated(
    poolExpiredDate: ISODateString,
    poolHashrate: number,
  ): void;
  poolMinerId: number;
  goToCryptoPayment(id: OrderId): void;
};

export default function useRenewPoolMiner({
  errorHandler,
}: HookRenewPoolMinerParams) {
  const {
    purchaseRequester,
    translation: {localeTag},
  } = useRoot();
  const handle = useHandleErrorAndExtractPromo({errorHandler});
  return useCallback(
    async (params: RenewPoolMinerParams) => {
      const {
        offer,
        poolMinerId,
        goToPoolMinerActivated,
        paymentMethod,
        goToCryptoPayment,
      } = params;
      const requestParams = {
        payment: paymentMethod,
        offerId: offer.offerId,
        purchaseTokenAndroid: undefined,
        poolMinerIdForRenew: poolMinerId,
      };
      const response = await purchaseRequester.request(requestParams);
      const process_ = await handle(response);
      if (!process_.success) {
        return;
      }
      if (response.success && offer.type === OfferType.Miner) {
        switch (response.right.kind) {
          case PaymentType.InAppCompleted:
            const newExpireDate = dayjs()
              .locale(localeTag)
              .add(offer.interval, 'month')
              .toISOString() as ISODateString;
            goToPoolMinerActivated(
              newExpireDate,
              offer.poolMinerConfig.hash_rate,
            );
            break;
          case PaymentType.ExternalOrder:
            if (
              response.right.redirectUrl &&
              !response.right.metamaskIsPresented
            ) {
              await openLink(response.right.redirectUrl);
            } else {
              goToCryptoPayment(response.right.orderId);
            }
            break;
        }
      }
    },
    [purchaseRequester, handle, localeTag],
  );
}
