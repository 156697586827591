import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Message, MessageVariant} from '../FlashMessageService';
import {Typography} from '../components';
import {Theme, useStyles, useTheme, variance} from '../styling';
import {Pressable, View} from 'react-native';
import CheckSquareSvg from '../assets/svg/colorless/checkSquare.svg';
import WarningSvg from '../assets/svg/colorless/warning.svg';
import InfoSvg from '../assets/svg/colorless/info.svg';
import CloseSvg from '../assets/svg/colorless/close.svg';
import {sized} from '../Svg';

const CloseIcon = sized(CloseSvg, 10);

export type MessageProps = {
  message: Message;
  onDismiss: (id: string) => void;
};

export default observer(function MessageView(props: MessageProps) {
  const {message, onDismiss} = props;
  const {action, description} = message;

  const styles = useStyles((theme) => ({
    title: {
      color: theme.colors.primaryWhite,
    },
    actionText: {
      color: theme.colors.primaryWhite,
    },
    descriptionText: {
      marginTop: 2,
      fontSize: 12,
      lineHeight: 12,
      color: theme.colors.primaryWhite,
    },
    primary: {
      backgroundColor: theme.colors.primarySuccess,
    },
    success: {
      backgroundColor: theme.colors.primarySuccess,
    },
    danger: {
      backgroundColor: theme.colors.primaryError,
    },
    info: {
      backgroundColor: theme.colors.primaryActive,
    },
  }));
  const theme = useTheme();
  const {variant = 'primary'} = message;

  const handleClose = useCallback(() => {
    onDismiss(message.id);
  }, [onDismiss, message]);

  return (
    <MessageWrapper style={styles[variant]}>
      <MessageViewIcon>{renderIconByVariant(variant, theme)}</MessageViewIcon>
      <MessageViewBody>
        <Typography type={'header'} size={'h5'} style={styles.title}>
          {message.title}
        </Typography>
        {description && (
          <Typography
            type={'body'}
            weight={'500'}
            style={styles.descriptionText}>
            {description}
          </Typography>
        )}
        {action && (
          <MessageAction onPress={action.onPress}>
            <Typography
              type={'paragraph'}
              weight={'500'}
              size={'regular'}
              style={styles.actionText}>
              {action.title}
            </Typography>
          </MessageAction>
        )}
      </MessageViewBody>
      <CloseButton onPress={handleClose}>
        <CloseIcon color={theme.colors.primaryWhite} />
      </CloseButton>
    </MessageWrapper>
  );
});

function renderIconByVariant(variant: MessageVariant, theme: Theme) {
  switch (variant) {
    case 'primary':
    case 'success':
      return <CheckSquareSvg color={theme.colors.primarySuccess} />;
    case 'danger':
      return <WarningSvg color={theme.colors.primaryWhite} />;
    case 'info':
      return <InfoSvg color={theme.colors.primaryActive} />;
  }
}

const MessageWrapper = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 30,
    paddingLeft: 25,
    paddingRight: 15,
  },
}));

const MessageViewIcon = variance(View)(() => ({
  root: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 25,
  },
}));

const MessageViewBody = variance(View)(() => ({
  root: {
    flex: 1,
    alignItems: 'flex-start',
    marginRight: 22,
  },
}));

const CloseButton = variance(Pressable)((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    width: 28,
    height: 28,
    borderRadius: 28,
    backgroundColor: theme.chroma(theme.colors.uiMain).alpha(0.5).hex(),
  },
}));

const MessageAction = variance(Pressable)((theme) => ({
  root: {
    marginTop: 8,
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderRadius: 24,
    backgroundColor: theme.chroma(theme.colors.primaryWhite).alpha(0.3).hex(),
  },
}));
