import {DoubleProposal, Proposal} from '../ProposalsState';
import {ActivatorSubscriptionOffer, PaymentMethod} from '../InAppOffersService';
import {PurchaseId} from '../units';
import {Second} from '../Time';
import {Hashrate, Interval} from '../ApiStore';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';

export interface PurchaseScreenState {
  readonly selectedTab: PurchaseTabsKind;
  readonly selectedHashrate?: Hashrate;
  selectHashrate(hashrate: Hashrate): void;
  readonly selectedInterval: Interval;
  selectInterval(interval: Interval): void;
  readonly selectedDoubleProposal: DoubleProposal | undefined;

  readonly selectedActivatorProposal:
    | Proposal<ActivatorSubscriptionOffer>
    | undefined;
  readonly selectedActivatorProposals:
    | Proposal<ActivatorSubscriptionOffer>[]
    | undefined;

  readonly selectedReactivationTime: Second | undefined;
  readonly minerConfigs: MinerConfig[];

  selectReactivationTime: (newTime: Second) => void;

  selectActivatorPurchaseId: (id: PurchaseId) => void;

  selectedPaymentMethod: PaymentMethod;
  selectPaymentMethod(method: PaymentMethod): void;

  selectTab(newTab: PurchaseTabsKind): void;
  init(initialTab?: PurchaseTabsKind): void;
  blur(): void;
  reset(): void;
}

export enum PurchaseTabsKind {
  Worker,
  Reactivate,
}
