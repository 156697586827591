import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useCallback, useEffect} from 'react';
import {useRoot} from '../../Root/hooks';
import {useResetToDashboard} from '../hooks';

export const FARM_ACTIONS = ['add', 'addcode'];

export type FarmActionBindingProps = RootStackBindingProps<'FarmAction'> & {};

export default observer((props: FarmActionBindingProps) => {
  const {navigation, route} = props;
  const params = route.params;
  const {navigationContainer, appLifecycle} = useRoot();
  const resetToDashboard = useResetToDashboard();
  const goToPoolPlans = useCallback(() => {
    const state = navigation.getState();
    if (state) {
      const previousRoute = state.routes[state.routes.length - 2]; // Previous route
      if (previousRoute && previousRoute.name === 'Plan') {
        navigation.goBack();
        return;
      }
    }
    navigation.replace('Plan');
  }, [navigation]);
  useEffect(() => {
    if (navigationContainer.isConfigured) {
      if (!params.action || !FARM_ACTIONS.includes(params.action)) {
        return resetToDashboard();
      } else if (params.action === 'addcode' && appLifecycle.initialized) {
        if (appLifecycle.hasJustBeenInstalled) {
          return resetToDashboard();
        } else {
          return goToPoolPlans();
        }
      } else if (params.action === 'add') {
        return navigation.replace('LinkWorkerConfirm', {linkKey: params.key});
      }
    }
  }, [
    resetToDashboard,
    goToPoolPlans,
    navigationContainer.isConfigured,
    params,
    navigation,
    appLifecycle.initialized,
    appLifecycle.hasJustBeenInstalled,
  ]);

  return null;
});
