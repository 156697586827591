import React, {ForwardedRef, forwardRef, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
  Representation,
} from '../../templates';
import {StyleSheet, TextInputProps, View} from 'react-native';
import useSafePaddingBottom from '../../templates/AdaptiveModal/useSafePaddingBottom';
import {variance} from '../../../styling';
import useRepresentation from '../../templates/AdaptiveModal/useRepresentation';
import Input, {InputProps} from '../../Input';
import {expr} from 'mobx-utils';
import {BottomSheetTextInput} from '@gorhom/bottom-sheet';
import {TextInput} from 'react-native-gesture-handler';
import {
  AdaptiveModalHeader,
  AdaptiveModalHeaderProps,
} from './AdaptiveModalHeader';

export const ADAPTIVE_INPUT_MODAL_BREAKPOINT = 'sm';

export type AdaptiveInputModalProps = Omit<ContentViewProps, 'inputRef'> & {
  onModalClosed: () => void;
};

export default observer<AdaptiveInputModalProps, AdaptiveModalRef>(
  forwardRef(function AdaptiveInputModal(props, ref) {
    const inputRef = useRef<TextInput>(null);
    const onBottomSheetChange = (index: number) =>
      index === 0 && inputRef.current?.focus();
    return (
      <AdaptiveModal
        ref={ref}
        adaptiveBreakpoint={ADAPTIVE_INPUT_MODAL_BREAKPOINT}
        enableContentAutoHeight
        modalWidth={ModalWidth.Small}
        onModalClosed={props.onModalClosed}
        onBottomSheetChange={onBottomSheetChange}>
        <ContentView inputRef={inputRef} {...props} />
      </AdaptiveModal>
    );
  }),
);

type ContentViewProps = AdaptiveModalHeaderProps & {
  inputRef: ForwardedRef<TextInput>;
  getInputValue: () => string;
  onChangeInputValue: (value: string) => void;
  actions: React.ReactNode[];
  placeholder: string;
  children?: React.ReactNode;
};

const ContentView = observer(
  ({
    IconSvg,
    IconSvgModalRep,
    title,
    description,
    actions,
    placeholder,
    inputRef,
    children,
    getInputValue,
    onChangeInputValue,
  }: ContentViewProps) => {
    const paddingBottom = useSafePaddingBottom(16, 20);
    const representation = useRepresentation();
    const isModal = representation === Representation.Modal;
    return (
      <RootView style={{paddingBottom}} modal={isModal}>
        <AdaptiveModalHeader
          IconSvg={IconSvg}
          IconSvgModalRep={IconSvgModalRep}
          title={title}
          description={description}
        />
        {children}
        <InputView>
          <AdaptiveInput
            ref={inputRef}
            value={getInputValue()}
            onChangeText={onChangeInputValue}
            placeholder={placeholder}
          />
        </InputView>
        <ActionListView modal={isModal}>
          {actions.map((action, index) => (
            <View
              style={[index + 1 < actions.length && styles.actionOffset]}
              key={index}>
              {action}
            </View>
          ))}
        </ActionListView>
      </RootView>
    );
  },
);

const AdaptiveInput = observer<InputProps, TextInput>(
  forwardRef((props, ref) => {
    const representation = useRepresentation();
    const TextInputComponent: React.ComponentType<TextInputProps> = expr(() =>
      representation === Representation.BottomSheet
        ? BottomSheetTextInput
        : TextInput,
    );
    return (
      <Input TextInputComponent={TextInputComponent} {...props} ref={ref} />
    );
  }),
);

const RootView = variance(View)(() => ({
  root: {
    padding: 16,
    paddingTop: 39,
  },
  modal: {
    padding: 20,
    paddingTop: 48,
  },
}));

const InputView = variance(View)(() => ({
  root: {
    marginTop: 24,
  },
  modal: {
    marginTop: 36,
  },
}));

const ActionListView = variance(View)(() => ({
  root: {
    alignSelf: 'stretch',
    marginTop: 24,
  },
  modal: {
    marginTop: 36,
  },
}));

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    lineHeight: 22,
    textAlign: 'center',
    marginBottom: 18,
  },
  actionOffset: {
    marginBottom: 12,
  },
});
