import {Currency} from '../units';
import {CTC_ABI, USDT_ABI} from './abis';

export function getAbi(currency: Currency) {
  switch (currency) {
    case 'USDT':
      return USDT_ABI;
    case 'CTC':
      return CTC_ABI;
    default:
      return [];
  }
}
