import {bind, flatten} from 'lodash';
import {
  action,
  autorun,
  comparer,
  computed,
  observable,
  reaction,
  runInAction,
  makeObservable,
} from 'mobx';
import type {ReadonlyDeep} from 'type-fest';
import {ApiStore, Bitcoin} from '../../../../src/ApiStore';
import {ApiMode} from '../../../../src/farmApi';
import {Auth, AuthStatus} from '../../../../src/Auth';
import {request} from '../../../mobx/RequestStore';
import {WorkerEntity} from '../../../features/api/entity/dashboard/worker/WorkerEntity';
import {RatesPanelState} from '../../../../src/RatesPanel';
import {CurrentSubscriptionState} from '../../../../src/CurrentSubscription';
import {Op} from '../../../../src/Math';
import toFixedTrunc from '../../../../src/utils/toFixedTrunc';
import {ReadyState} from '../../../../src/Connection';
import {batchDisposers, Service} from '../../../../src/structure';
import {BannerType, DashboardService} from '../../../../src/Dashboard';
import {DashboardMode} from '../../../../src/DashboardMode';
import DashboardPromoImpl from './DashboardPromoImpl';
import {ConnectedClient} from '../../../../src/ContextClient';
import {SocketApiService} from '../../../features/api/socket/SocketApiService';

export class DashboardStore implements Service {
  private readonly _ratesPanel: RatesPanelState;
  @observable private _isLoadedIn = false;
  @observable private _isRefreshing = false;

  private readonly _dashboardPromo: DashboardPromoImpl;

  constructor(
    protected readonly _root: {
      readonly currentSubscriptionState: CurrentSubscriptionState;
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly socketApi: SocketApiService;
      readonly auth: Auth;
      readonly dashboardService: DashboardService;
      readonly dashboardModeService: DashboardMode;
    },
  ) {
    makeObservable(this);
    this._dashboardPromo = new DashboardPromoImpl(_root);
    this._ratesPanel = new RatesPanelState(this._root);
  }

  get dashboardPromo() {
    return this._dashboardPromo;
  }

  get ratesPanel() {
    return this._ratesPanel;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  get isLoadedIn() {
    return this._isLoadedIn;
  }

  fetch = async () => {
    const response = await Promise.all([
      (async () => {
        const _dashboard = await this.dashboardRequest.execute();
        const workerIds = flatten(
          _dashboard?.groups.map((it) => it.workers.map((_) => _.id)) || [],
        );
        if (workerIds.length > 0) {
          await this.balanceRequest.execute(workerIds);
        }
        return _dashboard;
      })(),
      this.currentRateRequest.execute(),
      this.ratesPanel.fetch(),
      this.accountBalance.execute(),
      this.getDemoConfigRequest.execute(),
      this._dashboardPromo.getPromos(),
    ]);
    runInAction(() => (this._isLoadedIn = true));
    return response;
  };

  refresh = bind(async () => {
    runInAction(() => (this._isRefreshing = true));
    await this.fetch();
    runInAction(() => (this._isRefreshing = false));
  }, this);

  @action async updateBalance() {
    if (this.isLoading) {
      return;
    }
    await Promise.all([
      (async () => {
        const workerIds = flatten(
          this.dashboardRequest.value?.groups.map((it) =>
            it.workers.map((_) => _.id),
          ) || [],
        );
        if (workerIds.length > 0) {
          await this.balanceRequest.execute(workerIds);
        }
      })(),
      this.accountBalance.execute(),
    ]);
  }

  dashboardRequest = request(() => this._root.socketApi.dashboard());

  currentRateRequest = request(() => {
    return this._root.socketApi.getCurrentRate();
  });

  accountBalance = request(() => {
    return this._root.socketApi.getAccountBalance();
  });

  @action decrementBalance(amount: Bitcoin) {
    const balance = this.accountBalance.value;
    if (balance) {
      balance.total = Op.subtract(balance.total, amount);
    }
  }

  balanceRequest = request((workerIds: string[]) => {
    return this._root.socketApi.balance(workerIds);
  });

  getDemoConfigRequest = request(async () => {
    if (this._root.apiStore.mode === ApiMode.Demo) {
      return this._root.socketApi.getDemoConfig();
    }
    return undefined;
  });

  @computed
  get isLoading() {
    return (
      this.dashboardRequest.isLoading ||
      this.balanceRequest.isLoading ||
      this.currentRateRequest.isLoading ||
      this.ratesPanel.isLoading ||
      this.accountBalance.isLoading ||
      this.getDemoConfigRequest.isLoading ||
      this._root.currentSubscriptionState.isLoading
    );
  }

  @computed
  get balance(): {approxUsd: string | null; btc: string | null} {
    const btcRate = this.currentRateRequest.value?.btc;
    const usdRate = this.currentRateRequest.value?.usd;
    if (!btcRate || !usdRate) {
      return {
        approxUsd: null,
        btc: null,
      };
    }
    const totalBalance = this.accountBalance.value?.total || 0;
    const btcUsdRate = usdRate / btcRate;
    const approxUsd = (btcUsdRate * totalBalance).toFixed(2);

    return {
      approxUsd: approxUsd,
      btc: toFixedTrunc(totalBalance, 8),
    };
  }

  @computed({keepAlive: true})
  get workers() {
    const {groups} = this.dashboardRequest.value ?? {};
    return new Map(
      groups?.flatMap((group) =>
        group.workers.map((worker) => [worker.id, worker] as const),
      ),
    );
  }

  @computed({keepAlive: true})
  get groups() {
    const {groups} = this.dashboardRequest.value ?? {};
    return new Map(groups?.map((_) => [_.id, _] as const));
  }

  getWorkerById(workerId: string): ReadonlyDeep<WorkerEntity> | undefined {
    return this.workers.get(workerId);
  }

  @computed get workerBalanceById(): ReadonlyMap<string, number> | undefined {
    const {balances} = this.balanceRequest.value ?? {};
    return balances
      ? new Map(balances.map((_) => [_.worker_id, _.amount]))
      : undefined;
  }

  getWorkerBalanceById = (workerId: string): number => {
    return this.workerBalanceById?.get(workerId) ?? 0;
  };

  private _fetchOnConnect() {
    return autorun(() => {
      if (this._root.auth.isConnected) {
        // noinspection JSIgnoredPromiseFromCall
        this.fetch();
      }
    });
  }

  fetchDemoConfigurations() {
    return reaction(
      () =>
        [
          this._root.apiStore.tunnelState.readyState,
          this._root.apiStore.mode,
          !!this.getDemoConfigRequest.value,
        ] as const,
      async ([readyState, mode, value]) => {
        if (readyState === ReadyState.Open && mode === ApiMode.Demo && !value) {
          await this.getDemoConfigRequest.execute();
        }
      },
      {equals: comparer.shallow},
    );
  }

  /**
   * @deprecated
   */
  private _showExpiredBannerOnChange = () =>
    autorun(() => {
      const {subscription} = this._root.currentSubscriptionState;
      if (this._root.auth.state?.kind !== 'Connected') {
        return;
      }
      if (
        this._root.apiStore.mode === ApiMode.Demo ||
        !subscription ||
        !subscription.hasPurchases
      ) {
        return;
      }
      const should = subscription.maxSlots < subscription.slots;

      if (should) {
        this._root.dashboardService.bannersState.add(BannerType.Expired);
      } else {
        this._root.dashboardService.bannersState.delete(BannerType.Expired);
      }
    });

  /**
   * @deprecated
   */
  private _showPlatformUpgradeBannerOnChange = () =>
    autorun(() => {
      const {subscription} = this._root.currentSubscriptionState;
      if (!subscription || !subscription.hasPurchases) {
        return;
      }
      const should = !this._root.currentSubscriptionState.isPlatformSupported;
      if (should) {
        this._root.dashboardService.bannersState.add(BannerType.Upgrade);
      } else {
        this._root.dashboardService.bannersState.delete(BannerType.Upgrade);
      }
    });

  private _showFullAccessBannerOnChange = () =>
    autorun(() => {
      const should =
        this._root.apiStore.mode === ApiMode.Demo &&
        this._root.auth.status === AuthStatus.Idle;
      if (should) {
        this._root.dashboardService.bannersState.add(BannerType.Demo);
      } else {
        this._root.dashboardService.bannersState.delete(BannerType.Demo);
      }
    });

  subscribe() {
    return batchDisposers(
      this._fetchOnConnect(),
      this.fetchDemoConfigurations(),
      this._showFullAccessBannerOnChange(),
      this._showExpiredBannerOnChange(),
      this._showPlatformUpgradeBannerOnChange(),
    );
  }

  @action reset() {
    this.dashboardRequest.reset();
    this.ratesPanel.reset();
    this.currentRateRequest.reset();
    this.accountBalance.reset();
    this.balanceRequest.reset();
    this.getDemoConfigRequest.reset();
  }
}
