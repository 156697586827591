import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {PickConfigurationModal} from '../../PickConfigurationModal';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {AdaptiveModalRef} from '../../components/templates';

export type PickConfigurationBindingProps =
  RootStackBindingProps<'PickPurchaseConfiguration'> & {};

export default observer(function PickSubManagementConfigurationBinding({
  navigation,
}: PickConfigurationBindingProps) {
  const {purchaseScreenState} = useRoot();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const goToPlanSilence = useCallback(
    () => navigation.navigate('Plan', {silence: true}),
    [navigation],
  );
  const closeAndGoBack = useCallback(async () => {
    await modalRef.current?.close();
    goToPlanSilence();
  }, [goToPlanSilence]);
  return (
    <PickConfigurationModal
      ref={modalRef}
      onSelectPress={closeAndGoBack}
      onModalClosed={closeAndGoBack}
      data={purchaseScreenState.minerConfigs}
      selectedHashrate={purchaseScreenState.selectedHashrate}
      selectHashrate={purchaseScreenState.selectHashrate}
    />
  );
});
