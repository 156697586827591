import React from 'react';
import {Platform, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../../../styling';
import {useStrings} from '../../../../Root/hooks';
import WarningSvg from '../../../../assets/svg/circled/warning.svg';
import {sized} from '../../../../Svg';
import useOpenStoreAuth from '../../../../PurchaseScreen/hooks/useOpenStoreAuth';
import {AppButton, ButtonVariant} from '../../../AppButton';
import {Paragraph} from '../../../index';

export default observer(function UnauthorizedStoreError() {
  const strings = useStrings();
  const description =
    Platform.OS === 'ios'
      ? strings['subscription.purchaseIsNotAvailable.description.ios']
      : strings['subscription.purchaseIsNotAvailable.description.android'];
  const openStore = useOpenStoreAuth();
  const theme = useTheme();
  return (
    <RootView>
      <WarningView>
        <WarningIcon color={theme.colors.primaryAttractive} />
      </WarningView>
      <DescriptionText type="paragraph" size="large">
        {description}
      </DescriptionText>
      {openStore && (
        <AppButton onPress={openStore} variant={ButtonVariant.Primary}>
          {strings['subscription.purchaseIsNotAvailable.button']}
        </AppButton>
      )}
    </RootView>
  );
});

const WarningIcon = sized(WarningSvg, 60);

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryWhite,
    borderRadius: 6,
    padding: 16,
  },
}));

const DescriptionText = variance(Paragraph)((theme) => ({
  root: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 17,
    color: theme.colors.primaryUIDirtyBlue,
  },
}));

const WarningView = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
  },
}));
