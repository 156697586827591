import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {PoolMinerSchedulerWarningModal} from '../../PoolMinerSchedulerWarningModal';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';

export type PoolMinerSchedulerWarningBindingProps =
  RootStackBindingProps<'PoolMinerSchedulerWarning'>;

export default observer(function PoolMinerSchedulerWarningBinding({
  route,
}: PoolMinerSchedulerWarningBindingProps) {
  const {socketApi} = useRoot();
  const workerId = route.params?.workerId;
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = useCallback(() => modalRef.current?.close(), []);
  const safelyGoBack = useSafelyGoBack();
  const onModalClosed = useCallback(() => safelyGoBack(), [safelyGoBack]);
  const onCancelPress = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);
  const onClearPress = useCallback(async () => {
    await close();
    safelyGoBack();
    if (workerId !== undefined) {
      await socketApi.deleteSchedule(workerId);
    }
  }, [close, safelyGoBack, workerId, socketApi]);
  return (
    <PoolMinerSchedulerWarningModal
      ref={modalRef}
      onModalClosed={onModalClosed}
      onCancelPress={onCancelPress}
      onClearPress={onClearPress}
    />
  );
});
