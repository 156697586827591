import footerConfig from './footerConfig';
import {Millisecond} from '../Time';
import {Url} from '../units';

export default {
  ...footerConfig,
  webAppId: 'com.ctfarm.web',
  webBundleId: 'com.ctfarm',
  webAppUrl: 'https://app.cryptotabfarm.com/',
  playStoreUrl:
    'https://play.google.com/store/apps/details?id=com.ctfarm' as Url,
  appStoreUrl:
    'https://apps.apple.com/app/apple-store/id1633191121?action=write-review' as Url,
  installationUrl: 'https://cryptotabfarm.com/installation/',
  instructionUrl: 'https://cryptotabfarm.com/miner/',
  playStoreSubscriptionsUrl:
    'https://play.google.com/store/account/subscriptions',
  appStoreSubscriptionsUrl: 'https://apps.apple.com/account/subscriptions',
  sharedPromoLink: 'https://cryptotabfarm.com/apply/{code}/' as Url,
  aboutUrl: 'https://cryptotabfarm.com',
  downloadUrl: 'https://cryptotabfarm.com/download',
  downloadFarmMinerUrl: 'https://cryptotabfarm.com/download/farm_miner_{id}',
  promoRegistrationLink: 'https://cryptotabfarm.com/go/{code}',
  tutorialIntro: 'https://cryptotabfarm.com/tutorial_intro/',
  tutorialDemo: 'https://cryptotabfarm.com/{lang}/tutorials_demo/',
  tutorialReal: 'https://cryptotabfarm.com/{lang}/tutorials_real/',
  aboutMinerUrl: 'https://cryptotabfarm.com/miner/',
  pcUrl: 'https://cryptotabfarm.com/pc/',
  goDiscount: 'https://cryptotabfarm.com/go/{discount}',
  downloadMetamaskUrl: 'https://metamask.io/download/' as Url,
  cryptoBrowserAppFarmAboutUrl:
    'https://cryptobrowser.site/app/farm/about' as Url,
  affiliateSuspendUrl: 'https://cryptobrowser.site/app/banned' as Url,
  affiliateVerifyUrl: 'https://cryptobrowser.site/app/email/verify' as Url,
  cryptotabTimeout: 10000 as Millisecond,
  bffTimeout: 15000 as Millisecond,
  cannonDuration: 5000 as Millisecond,
};
