import {action, computed, flow, observable, makeObservable} from 'mobx';
import {
  Appearance as RNAppearance,
  ColorSchemeName,
  Dimensions,
  ScaledSize,
} from 'react-native';
import {define, THEME_KIND} from '../persistence';
import {
  Appearance,
  PreferredThemeKind,
  SystemThemeKind,
  ThemeKind,
} from './Appearance';
import {batchDisposers, Disposer, Service} from '../structure';
import {lightColors, darkColors, ThemeImpl} from '../styling';
import {AsyncReturnType} from 'type-fest';
import {bind} from '../fp';
import {AppTemplateState} from '../AppTemplateState';
import {WindowDimensions} from '../WindowDimensions';

export default class AppearanceService implements Appearance, Service {
  private static _themeModeTransitionMap = new Map([
    [ThemeKind.Auto, ThemeKind.Light],
    [ThemeKind.Light, ThemeKind.Dark],
    [ThemeKind.Dark, ThemeKind.Auto],
  ]);

  constructor(
    private readonly _root: {
      readonly appTemplateState: AppTemplateState;
      readonly windowDimensions: WindowDimensions;
    },
  ) {
    makeObservable(this);
  }

  @observable
  private _systemThemeKind: SystemThemeKind = colorSchemeToThemeKind(
    RNAppearance.getColorScheme(),
  );
  @observable private _preferredThemeKind: PreferredThemeKind =
    ThemeKind.Unknown;

  get systemThemeKind() {
    return this._systemThemeKind;
  }

  get preferredThemeKind() {
    return this._preferredThemeKind;
  }

  @computed get actualThemeKind() {
    const themeKind =
      this._preferredThemeKind === ThemeKind.Auto
        ? this._systemThemeKind
        : this._preferredThemeKind;
    if (themeKind === ThemeKind.Unknown) {
      return ThemeKind.Light;
    }
    return themeKind;
  }

  @computed get isDark() {
    return this.actualThemeKind === ThemeKind.Dark;
  }

  @observable.ref private _theme = this._createTheme();

  private _createTheme(window = Dimensions.get('window')) {
    return this.actualThemeKind === ThemeKind.Light
      ? new ThemeImpl(lightColors, window, this._root.appTemplateState.content)
      : new ThemeImpl(darkColors, window, this._root.appTemplateState.content);
  }

  get theme() {
    return this._theme;
  }

  private _load = flow(function* (this: AppearanceService) {
    const _getThemeMode: AsyncReturnType<typeof getThemeMode> =
      yield getThemeMode();
    if (!_getThemeMode.success) {
      this._preferredThemeKind = ThemeKind.Unknown;
    } else if (_getThemeMode.right === null) {
      this._preferredThemeKind = ThemeKind.Auto;
    } else {
      this._preferredThemeKind = _getThemeMode.right;
    }
    this._theme = this._createTheme();
  });

  togglePreferredThemeKind = bind(
    flow(function* (this: AppearanceService) {
      const next =
        AppearanceService._themeModeTransitionMap.get(
          this._preferredThemeKind,
        ) ?? ThemeKind.Auto;
      this.setThemeMode(next);
    }),
    this,
  );

  setThemeMode = bind(
    flow(function* (this: AppearanceService, next: ThemeKind) {
      yield putThemeMode(next);
      this._preferredThemeKind = next;
      this._theme = this._createTheme();
    }),
    this,
  );

  private _initialize() {
    const loading = this._load();
    return (() => loading.cancel()) as Disposer;
  }

  private _listenToColorSchemeChanges() {
    let timer: ReturnType<typeof setInterval>;
    const callback: RNAppearance.AppearanceListener = ({colorScheme}) => {
      clearTimeout(timer);
      timer = setTimeout(
        action(() => {
          this._systemThemeKind = colorSchemeToThemeKind(colorScheme);
          this._theme = this._createTheme();
        }),
        500,
      );
    };
    const subscription = RNAppearance.addChangeListener(callback);
    return (() => subscription.remove()) as Disposer;
  }

  @action.bound private _onSizeChange(update: {window: ScaledSize}) {
    this._theme = this._createTheme(update.window);
  }

  private _listenToDimensionsChanges() {
    return this._root.windowDimensions.updates.listen(this._onSizeChange);
  }

  subscribe() {
    return batchDisposers(
      this._initialize(),
      this._listenToColorSchemeChanges(),
      this._listenToDimensionsChanges(),
    );
  }
}

const [getThemeMode, putThemeMode] = define<ThemeKind>(THEME_KIND);

const colorSchemeToThemeKind = (scheme: ColorSchemeName): SystemThemeKind => {
  switch (scheme) {
    case 'light':
      return ThemeKind.Light;
    case 'dark':
      return ThemeKind.Dark;
  }
  return ThemeKind.Unknown;
};
