import dayjs from 'dayjs';
import {LocaleTag} from '../Localization/TranslationService';
import {useMemo} from 'react';
import {useRoot} from '../Root/hooks';

const LOCALE_MONTH = 'MMMM';
const LOCALE_DAYS = 'ddd';
const LOCALE_SHORT_DAYS = 'dd';

const getDateLocale =
  (l: LocaleTag, formate: string, type: dayjs.UnitType) => (number: number) => {
    return dayjs().locale(l).set(type, number).format(formate);
  };

export const useDateFormatting = () => {
  const {translation} = useRoot();
  const {localeTag} = translation;

  return useMemo(
    () => ({
      formatLocaleMonth: getDateLocale(localeTag, LOCALE_MONTH, 'month'),
      formatLocaleDays: getDateLocale(localeTag, LOCALE_DAYS, 'days'),
      formatLocaleShortDays: getDateLocale(
        localeTag,
        LOCALE_SHORT_DAYS,
        'days',
      ),
    }),
    [localeTag],
  );
};
