import React from 'react';
import {View, Image} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {useStyles} from '../styling';
import {ScrollView} from 'react-native-gesture-handler';
import {Header, Paragraph} from '../components';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {
  MD_BREAKPOINT,
  useGetDimensions,
} from '../WindowDimensions/useDimensions';
import {Bitcoin} from '../ApiStore';
import {expr} from 'mobx-utils';

export type PaymentSuccessScreenProps = {
  amount: Bitcoin;
  goToAffiliate: () => void;
};

export default observer(
  ({amount, goToAffiliate}: PaymentSuccessScreenProps) => {
    const styles = useStyles((theme) => ({
      root: {
        flexGrow: 1,
        flexShrink: 1,
      },
      container: {
        paddingHorizontal: 16,
        ...theme.mediaQuery({
          [MD_BREAKPOINT]: {
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
          },
        }),
      },
      image: {
        width: 181,
        height: 160,
        marginTop: 26,
        marginBottom: 30,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      title: {
        textAlign: 'center',
        ...theme.mediaQuery({
          [MD_BREAKPOINT]: {
            marginTop: 30,
          },
        }),
      },
      description: {
        marginTop: 18,
        textAlign: 'center',
        marginBottom: 24,
        ...theme.mediaQuery({
          [MD_BREAKPOINT]: {
            marginBottom: 60,
          },
        }),
      },
      mobCard: {
        ...theme.mediaQuery({
          [MD_BREAKPOINT]: {
            maxWidth: 372,
            backgroundColor: theme.colors.uiMain,
            borderRadius: 12,
            shadowOffset: {
              width: 0,
              height: 0,
            },
            paddingHorizontal: 22,
            paddingBottom: 20,
            shadowOpacity: 0.15,
            shadowRadius: 4,

            elevation: 12,
            shadowColor: theme.colors.primaryUIDirtyBlue,
          },
        }),
      },
      backgroundImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
    }));
    const strings = useStrings();
    const getDimension = useGetDimensions();
    const isMd = expr(() => getDimension('md'));

    return (
      <View style={styles.root}>
        {isMd && (
          <Image
            source={require('../assets/fan_bg.png')}
            resizeMode={'cover'}
            style={styles.backgroundImage}
          />
        )}
        <ScrollView contentContainerStyle={styles.container}>
          <View style={styles.mobCard}>
            <Image
              resizeMode="contain"
              style={styles.image}
              source={require('../assets/paymentSuccess.png')}
            />
            <Header type="header" style={styles.title} size="h3" weight="500">
              {strings['paymentSuccess.title']}
            </Header>
            <Paragraph
              type="paragraph"
              weight="500"
              style={styles.description}
              size="large">
              {' '}
              {strings['paymentSuccess.description'].replace(
                '{amount}',
                amount.toFixed(8),
              )}
            </Paragraph>
            <AppButton variant={ButtonVariant.Primary} onPress={goToAffiliate}>
              {strings['paymentSuccess.openButton']}
            </AppButton>
          </View>
        </ScrollView>
      </View>
    );
  },
);
