import React, {useMemo} from 'react';
import {TouchableOpacity, TouchableOpacityProps} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {Paragraph} from '../components';

import {useTheme} from '../styling';

interface ISecondaryMenuItem extends TouchableOpacityProps {
  text: string;
  right?: boolean;
  Icon?: React.ComponentType<SvgProps>;
}

export const SecondaryMenuItem = ({
  text,
  style,
  Icon,
  right,
  ...rest
}: ISecondaryMenuItem) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        root: {
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 10,
          paddingRight: right ? 0 : 15,
          paddingLeft: right ? 15 : 0,
        },
        text: {
          color: theme.colors.primaryUIDirtyBlue,
        },
        icon: {
          marginRight: 4,
        },
      } as const),
    [theme, right],
  );

  return (
    <TouchableOpacity style={[styles.root, style]} {...rest}>
      {Icon && (
        <Icon color={theme.colors.primaryUIDirtyBlue} style={styles.icon} />
      )}
      <Paragraph type="paragraph" size="large" weight="500" style={styles.text}>
        {text}
      </Paragraph>
    </TouchableOpacity>
  );
};
