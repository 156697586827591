import React, {PropsWithChildren} from 'react';
import {View, StyleSheet} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';

export type AppTabTemplateProps = {
  renderMobileTabBar: () => React.ReactNode;
};

export default observer(function AppTabTemplate({
  children,
  renderMobileTabBar,
}: PropsWithChildren<AppTabTemplateProps>) {
  const {appTemplateState} = useRoot();
  const isLarge = appTemplateState.isTablet;
  return (
    <View style={styles.root}>
      <View style={styles.main}>
        <View style={styles.content}>
          <View style={styles.page}>{children}</View>
          {!isLarge && renderMobileTabBar()}
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  main: {
    flex: 1,
    flexDirection: 'row',
  },
  content: {
    flex: 1,
  },
  page: {
    flex: 1,
  },
});
