import {Interval, IntervalType} from '../ApiStore';

export enum QuickStartListVariant {
  Low,
  Standard,
  Maximum,
}

type QuickStartListTypeInfo = {
  name: string;
  hs: number;
};

export type QuickStartListLabel = 'Recommended' | 'Current';

export type QuickStartListItemType = {
  variant: QuickStartListVariant;
  hs: number;
  price: string;
  info: QuickStartListTypeInfo | undefined;
  label: QuickStartListLabel | undefined;
  disabled: boolean;
  interval: Interval;
  intervalType: IntervalType;
};
