import {Service} from '../structure';
import {Web3Payment} from './Web3Payment';
import PaymentUrlGeneratorImpl from './PaymentUrlGeneratorImpl';
import SubMetamaskService from './SubMetamaskService';

export default class SubWeb3PaymentService implements Web3Payment, Service {
  readonly metamask = new SubMetamaskService();
  readonly paymentUrlGenerator = new PaymentUrlGeneratorImpl();

  subscribe() {}
}
