import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import SubscriptionView, {SubscriptionViewProps} from './SubscriptionView';
import EmailLabel from './EmailLabel';

export interface PermanentSubscriptionViewProps extends SubscriptionViewProps {
  email: string;
}

export default observer((props: PermanentSubscriptionViewProps) => {
  const label = useMemo(() => {
    return <EmailLabel email={props.email} />;
  }, [props.email]);

  return <SubscriptionView label={label} withLabel {...props} />;
});
