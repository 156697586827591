import {observer} from 'mobx-react-lite';
import React from 'react';
import {Pressable} from 'react-native';
import {Paragraph} from '../components';
import {variance} from '../styling';

export type CalendarCellProps = {
  day: number;
  active: boolean;
  disabled: boolean;
  first: boolean;
  last: boolean;
  onPress: () => void;
};

export default observer(function CalendarCell({
  day,
  active,
  disabled,
  last,
  first,
  onPress,
}: CalendarCellProps) {
  return (
    <Item last={last} first={first} disabledStyle={disabled} onPress={onPress}>
      <ItemText
        type="paragraph"
        size="large"
        active={active}
        disabled={disabled}>
        {day}
      </ItemText>
    </Item>
  );
});

const Item = variance(Pressable)((theme) => ({
  root: {
    flexBasis: `${100 / 7}%`,
    height: 44,
  },

  disabledStyle: {
    backgroundColor: theme.colors.uiAdditional1,
    borderRadius: 0,
  },
  last: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },

  first: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
}));

const ItemText = variance(Paragraph)((theme) => ({
  root: {
    lineHeight: 44,
    textAlign: 'center',
    color: theme.colors.uiSecondary,
  },
  active: {
    fontWeight: '600',
    color: theme.colors.primaryAttractive,
  },

  disabled: {
    color: theme.colors.uiAdditional2,
  },
}));
