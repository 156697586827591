import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Linking, Platform, View} from 'react-native';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';

export type NeedAccessProps = {
  onRequestPress: () => void;
  canAsk: boolean | undefined;
};

export default observer(function NeedAccess({
  onRequestPress,
  canAsk,
}: NeedAccessProps) {
  const onSubmitPress = useCallback(() => {
    if (canAsk) {
      onRequestPress();
    } else {
      if (Platform.OS === 'ios') {
        // noinspection JSIgnoredPromiseFromCall
        Linking.openURL('app-settings:');
      } else if (Platform.OS === 'android') {
        // noinspection JSIgnoredPromiseFromCall
        Linking.openSettings();
      }
    }
  }, [canAsk, onRequestPress]);
  const strings = useStrings();
  const title = canAsk
    ? strings['qrCodeScanner.action.allow']
    : strings['qrCodeScanner.action.goToSettings'];
  return (
    <View>
      <View>
        <AppButton variant={ButtonVariant.Primary} onPress={onSubmitPress}>
          {title}
        </AppButton>
      </View>
    </View>
  );
});
