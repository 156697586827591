import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {useTheme, variance} from '../styling';
import {Body, getMonoFontStyles, Input, PressableOpacity} from '../components';
import CopySvg from '../assets/svg/colorless/copy.svg';
import {
  LG_BREAKPOINT,
  useGetIsDimensions,
} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import {colored, sized} from '../Svg';
import {SvgProps} from 'react-native-svg';
import {useStrings, useTemplates} from '../Root/hooks';

export type FormViewProps = {
  sumValue: string;
  currency: string;
  onCopySumPress: () => void;
  addressValue: string;
  onCopyAddressPress: () => void;
};

export default observer(function FormView({
  sumValue,
  currency,
  onCopySumPress,
  addressValue,
  onCopyAddressPress,
}: FormViewProps) {
  const strings = useStrings();
  const templates = useTemplates();
  const getIsLg = useGetIsDimensions('lg');
  const isLg = expr(() => getIsLg());
  return (
    <RootView>
      <GutterView>
        <View>
          <View>
            <StyledInput
              editable={false}
              placeholder={strings['payment.inputAmount.placeholder']}
              value={`${sumValue} ${currency}`}
            />
            <CopyIconView>
              <CopyIconTouchable onPress={onCopySumPress}>
                <CopyIcon />
              </CopyIconTouchable>
            </CopyIconView>
          </View>
          {isLg && (
            <InputHelperText type="body" weight="500">
              {strings['payment.inputAmount.helper']}
            </InputHelperText>
          )}
        </View>
      </GutterView>
      <View>
        <View>
          <StyledInput
            editable={false}
            placeholder={templates['payment.addressAmount.placeholder']({
              currency,
            })}
            value={addressValue}
          />
          <CopyIconView>
            <CopyIconTouchable onPress={onCopyAddressPress}>
              <CopyIcon />
            </CopyIconTouchable>
          </CopyIconView>
        </View>
        {isLg && (
          <InputHelperText type="body" weight="500">
            {templates['payment.addressAmount.helper']({
              currency,
            })}
          </InputHelperText>
        )}
      </View>
    </RootView>
  );
});

const CopyIcon = observer((props: SvgProps) => {
  const theme = useTheme();
  const Icon = colored(sized(CopySvg, 14), theme.colors.primaryUIDirtyBlue);
  return <Icon {...props} />;
});

const RootView = variance(View)(() => ({
  root: {},
}));

const CopyIconView = variance(View)(() => ({
  root: {
    position: 'absolute',
    right: 10,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
  },
}));

const CopyIconTouchable = variance(PressableOpacity)(() => ({
  root: {
    borderRadius: 8,
    padding: 10,
    margin: -5,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const GutterView = variance(View)((theme) => ({
  root: {
    marginBottom: 12,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 25,
      },
    }),
  },
}));

const StyledInput = variance(Input)(() => ({
  root: {
    ...getMonoFontStyles('400'),
    paddingRight: 35,
  },
}));

const InputHelperText = variance(Body)((theme) => ({
  root: {
    marginTop: 6,
    color: theme.colors.uiAdditional4,
    fontSize: 12,
    lineHeight: 16,
  },
}));
