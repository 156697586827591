import selectAllWorkersById from './selectAllWorkersById';
import {DemonstrationDatabase, WorkerRecord} from './DemonstrationDatabase';
import {SLOT_COUNT} from './constants';
import {action, observable, makeObservable} from 'mobx';
import {DashboardController, Subscription} from './DashboardController';
import {WorkerGroupEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {timezone} from 'expo-localization';
import {
  SlotStatus,
  WorkerType,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {Client, Server} from '../JsonRpc';
import {
  CryptoFarmClientCalls,
  CryptoFarmClientNotifications,
  FarmId,
  WorkerId,
} from '../ApiStore';
import diffCollections from './diffCollections';
import createWorkerUpdate from './createWorkerUpdate';
import {batchDisposers, Service} from '../structure';

export default class DashboardService implements DashboardController, Service {
  @observable private _accountId = 0 as FarmId;

  constructor(
    private readonly _root: {readonly db: DemonstrationDatabase},
    private readonly _reverseClient: Client<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
    private readonly _realServer: Server<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
  ) {
    makeObservable(this);
  }

  @action.bound connect(accountId: FarmId) {
    this._accountId = accountId;
  }

  getSubscription(): Subscription {
    const db = this._root.db.state;
    const allWorkerByIds = selectAllWorkersById(db.groups);
    return {
      accountId: this._accountId,
      maxSlots: SLOT_COUNT,
      slots: Object.values(allWorkerByIds).length,
    };
  }

  async getWorkers(): Promise<WorkerGroupEntity[]> {
    const db = this._root.db.state;
    return db.groups.map((group) => ({
      id: group.id,
      name: group.name,
      account_id: this._accountId,
      is_active: true,
      workers: Object.entries(group.workersById).map(([id, worker]) => {
        return {
          id: id as WorkerId,
          pool_miner_id: null,
          name: worker.name,
          is_active: true,
          utc_offset: -new Date().getTimezoneOffset(),
          timezone,
          slot_status: SlotStatus.Active,
          default_settings: {speed: 50},
          managed: true,
          account_id: this._accountId,
          group_id: group.id,
          versions: null,
          mining_options: {
            is_huge_pages_enabled: true,
            assembly: 'none',
            msr: '',
            optimal_threads: worker.characteristics.cores * 2,
          },
          host_info: {
            hostname: 'Demo Farm',
            autorun: true,
            memory: {free: 16000, total: 16000},
            os_info: 'NT',
            timezone,
            user_name: 'demo',
            utc_offset: -new Date().getTimezoneOffset(),
            cpu: {
              aes: true,
              arch: 'x86_64',
              avx: true,
              avx2: true,
              avx512f: true,
              bmi2: true,
              brand: worker.characteristics.name,
              cat_l3: true,
              cores: worker.characteristics.cores,
              l2: 1048576,
              l3: 8388608,
              nodes: 1,
              osxsave: true,
              packages: 0,
              pdpe1gb: true,
              popcnt: true,
              sse2: true,
              sse4_1: true,
              ssse3: true,
              threads: worker.characteristics.cores * 2,
              vm: true,
              xop: true,
            },
          },
          schedulers: worker.schedulers.map((_) => ({
            start_time: _.from,
            stop_time: _.to,
            settings: {..._.settings},
          })),
          worker_type: WorkerType.Regular,
          pool_miner_is_active: false,
          expired: false,
          pool_miner_config: null,
          start_time: null,
          end_time: null,
          is_free: true,
          subscription_id: null,
        };
      }),
    }));
  }

  private _routeThrough() {
    return this._realServer.call('account_info', async (params, response) => {
      const outcome_ = await this._reverseClient.call('account_info', params);
      if (!outcome_.success && outcome_.left.code === 32099) {
        return;
      }
      return response.respond(outcome_ as any);
    });
  }

  private _sendWorkerUpdate(id: string, worker: WorkerRecord, groupId: number) {
    this._reverseClient.call(
      'worker_update',
      createWorkerUpdate({
        id,
        accountId: 0,
        groupId,
        characteristics: worker.characteristics,
        name: worker.name,
        cores: worker.characteristics.cores,
        schedulers: worker.schedulers,
      }),
    );
  }

  private _sendWorkerDeletion(id: string) {
    // noinspection JSIgnoredPromiseFromCall
    this._reverseClient.call('worker_update', {deleted: true, id});
  }

  private _notifyOnSlotUpdate() {
    return this._root.db.afterUpdate(async (previous, current) => {
      const previousWorkersById = selectAllWorkersById(previous.groups);
      const workersById = selectAllWorkersById(current.groups);
      const [deletedWorkers, newWorkers] = diffCollections(
        previousWorkersById,
        workersById,
      );
      for (const id of Object.keys(deletedWorkers)) {
        this._sendWorkerDeletion(id);
      }
      for (const [id, worker] of Object.entries(newWorkers)) {
        this._sendWorkerUpdate(id, worker, worker.groupId);
      }
    });
  }

  subscribe() {
    return batchDisposers(this._routeThrough(), this._notifyOnSlotUpdate());
  }
}
