import {Server} from '../JsonRpc';
import {
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {success, error} from '../fp';
import {WorkerController} from './WorkerController';

export default (
  server: Server<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >,
  workers: WorkerController,
) =>
  batchDisposers(
    server.call('new_demo_worker', async (params, response) => {
      if (!params.configuration) {
        return response.respond(error({code: 1002, message: 'Parsing error'}));
      }
      await workers.create(params.configuration);
      return response.respond(success({worker_connection_id: '000000'}));
    }),
    server.call('set_worker_name', async (params, response) => {
      const now = new Date().getTime();
      await workers.update(params.worker_id, now, {name: params.name});
      return response.respond(success(null));
    }),
    server.call('change_settings', async (params, response) => {
      const now = new Date().getTime();
      await workers.update(params.worker_id, now, {settings: params.settings});
      return response.respond(success(null));
    }),
    server.call('delete_worker', async (params, response) => {
      await workers.delete(params.worker_id);
      return response.respond(success(null));
    }),
    server.call('setup_scheduler', async (params, response) => {
      const now = new Date().getTime();
      await workers.update(params.worker_id, now, {
        schedulers: params.schedulers.map((scheduler) => ({
          settings: scheduler.settings,
          from: scheduler.start_time,
          to: scheduler.stop_time,
        })),
      });
      return response.respond(success(null));
    }),
    server.call('delete_scheduler', async (params, response) => {
      const now = new Date().getTime();
      await workers.update(params.worker_id, now, {schedulers: []});
      return response.respond(success(null));
    }),
  );
