import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import {useRoot} from '../../../Root/hooks';
import {CryptoLoader} from '../../atoms/CryptoLoader';
import normalize from '../../../GraphPanel/normalize';
import asymptote from './asymptote';
import {useTheme} from '../../../styling';
import Svg, {G, Path} from 'react-native-svg';
import bezierPath from '../../../GraphPanel/bezierPath';

const WIDTH = 100;
const HEIGHT = 20;

export default observer(function BtcGraphRate() {
  const {
    dashboardStore: {ratesPanel: state},
  } = useRoot();
  const theme = useTheme();
  const {isLoading, graph} = state;
  let [minValue, maxValue, normalizedData] = normalize(graph?.series ?? []);
  if (minValue === maxValue) {
    normalizedData = asymptote(minValue, normalizedData.length);
  }
  if (isLoading) {
    return <CryptoLoader />;
  }
  const chart = (
    <View style={styles.chartView}>
      <Svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`} fill="none">
        <G>
          <Path
            d={bezierPath(WIDTH, HEIGHT, normalizedData)}
            stroke={theme.colors.primaryAttractive}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </G>
      </Svg>
    </View>
  );
  return (
    <View style={styles.root}>
      {graph && graph.series.length >= 3 ? chart : null}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chartView: {
    width: 100,
    height: 20,
    marginLeft: 25,
  },
});
