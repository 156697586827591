import {Service} from '../structure';
import {QuickStartOffer} from './QuickStartOffer';

export default class WebQuickStartOfferService
  implements QuickStartOffer, Service
{
  readonly isLoadedIn = true;
  readonly ready = true;
  readonly manuallyOpenQuickStartPossible = false;
  readonly shouldAddMinerRedirectToQuickStart = false;
  readonly shouldShowDashboardBanner = false;
  readonly shouldShownModal = false;
  readonly offers = [];
  readonly trial = null;
  readonly duration = undefined;

  async dismissModal() {
    throw new Error('Not implemented on this platform');
  }
  subscribe() {}
}
