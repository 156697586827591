import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, StyleSheet, View} from 'react-native';
import {useTheme, variance} from '../styling';
import {
  ContentGradient,
  ButtonText,
  RestoreButton,
  TitleText,
  SubTitleText,
  WebBgContainer,
  HeaderView,
} from './MinerTabView';
import {useStrings} from '../Root/hooks';
import {ScreenFluidLoader} from '../components/atoms/CryptoLoader';
import {Paragraph} from '../components';
import ReactivationVariantTabs from './components/ReactivationVariantTabs';
import WebBg from '../assets/svg/colored/webBg.svg';
import PurchaseErrorCard from './PurchaseErrorView';
import {GlobalError} from '../Error';
import {Second} from '../Time';
import {useGetIsDimensions} from '../WindowDimensions/useDimensions';
import {expr} from 'mobx-utils';
import ActivatorSubscriptionTableBinding from './ActivatorSubscriptionTableBinding';
import {TouchableOpacity} from 'react-native-gesture-handler';

export type ReactivateTabProps = {
  isLoading: boolean;
  goToAccessRecovery: () => void;
  fetchOffersError?: GlobalError;
  selectedTime: Second | undefined;
  items: Second[];
  onSelectTime: (newTime: Second) => void;
};

export default observer(function ReactivateTabView(props: ReactivateTabProps) {
  const {
    isLoading,
    goToAccessRecovery,
    fetchOffersError,
    selectedTime,
    items,
    onSelectTime,
  } = props;
  const theme = useTheme();
  const strings = useStrings();
  const getIsLarge = useGetIsDimensions('lg');
  const isLarge = expr(() => getIsLarge());
  if (isLoading) {
    return <ScreenFluidLoader />;
  }
  return (
    <ContentGradient
      colors={[theme.colors.primaryAttractive, theme.colors.primaryNew]}>
      {fetchOffersError ? (
        <PurchaseErrorCard error={fetchOffersError} />
      ) : (
        <View>
          <HeaderView zeroBottom>
            <View style={styles.container}>
              <TitleText type="header" size="h2">
                {strings['purchase.reactivation.title']}
              </TitleText>
              <SubTitleText offsetBottom type="body" weight="500">
                {strings['purchase.reactivation.text']}
              </SubTitleText>
            </View>
            {isLarge && Platform.OS !== 'web' && (
              <RestoreButton onPress={goToAccessRecovery}>
                <ButtonText default type="paragraph" weight="600">
                  {strings['subscription.restoreButton']}
                </ButtonText>
              </RestoreButton>
            )}
          </HeaderView>
          <Link large={isLarge} type="paragraph" size="large" weight="500">
            {strings['purchase.reactivation.try']}
          </Link>
          <ReactivationVariantTabs
            selectedTime={selectedTime}
            items={items}
            onSelectTime={onSelectTime}
          />
          <ActivatorSubscriptionTableBinding />
          {!isLarge && Platform.OS !== 'web' && (
            <TouchableOpacity onPress={goToAccessRecovery}>
              <ButtonText type="paragraph" weight="600">
                {strings['subscription.restoreButton']}
              </ButtonText>
            </TouchableOpacity>
          )}
        </View>
      )}
      {isLarge && (
        <WebBgContainer>
          <WebBg />
        </WebBgContainer>
      )}
    </ContentGradient>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const Link = variance(Paragraph)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.primaryActive,
    marginBottom: 22,
  },
  large: {
    textAlign: 'left',
    marginBottom: 18,
  },
}));
