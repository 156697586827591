import {observer} from 'mobx-react-lite';
import React from 'react';
import {Pressable, View} from 'react-native';
import {Paragraph} from '../components';
import {variance} from '../styling';

export type GroupItemProps = {
  id?: number | string;
  name: string;
  onPick: (id?: string | number) => void;
  groupId?: number | string;
  all?: boolean;
  first?: boolean;
};

export default observer(function GroupItem({
  all,
  id,
  name,
  groupId,
  onPick,
  first,
}: GroupItemProps) {
  return (
    <Container onPress={() => onPick(id)} all={all || first}>
      <Text weight="600" active={groupId === id} type="paragraph" size="large">
        {name}
      </Text>
      <Dot hide={groupId !== id} />
    </Container>
  );
});

const Container = variance(Pressable)(() => ({
  root: {
    marginLeft: 8,
    marginRight: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  all: {
    marginLeft: 0,
  },
}));

const Text = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
  },
  active: {
    color: theme.colors.uiSecondary,
  },
}));

const Dot = variance(View)((theme) => ({
  root: {
    width: 5,
    height: 5,
    borderRadius: 5,
    marginLeft: 5,
    backgroundColor: theme.colors.primaryAttractive,
  },
  hide: {
    backgroundColor: 'transparent',
    borderRadius: 5,
  },
}));
