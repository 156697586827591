import {observable, reaction, makeObservable, action, runInAction} from 'mobx';
import {batchDisposers, Service} from '../structure';
import {PushAdvert} from './PushAdvert';
import {Auth} from '../Auth';
import {AdId, ApiStore} from '../ApiStore';
import StaticAdvertCreatorHelper from './StaticAdvertCreatorHelper';
import {LocationSource} from '../Location';
import {Url} from '../units';
import {Platform} from 'react-native';

export default class PushAdvertService implements PushAdvert, Service {
  @observable private _state: PushAdvert['state'];
  @observable private _adToShow?: AdId;

  private static AD_ID_URL_PARAM = 'push_ad';

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly apiStore: ApiStore;
      readonly locationSource: LocationSource;
    },
  ) {
    makeObservable(this);
  }

  get state() {
    return this._state;
  }

  get adToShow() {
    return this._adToShow;
  }

  close = action(() => {
    this._adToShow = undefined;
  });

  onAdShown() {
    if (Platform.OS === 'web') {
      const url = new URL(window.location.href);
      url.searchParams.delete(PushAdvertService.AD_ID_URL_PARAM);
      const updatedUrl = url.toString();
      history.replaceState(null, '', updatedUrl);
    }
  }

  private _setState = action((state: PushAdvert['state']) => {
    this._state = state;
  });

  private async _fetch(_: AdId) {
    const ads_ = await this._root.apiStore.client.call('get_push_ads');

    if (!ads_.success) {
      this._setState(undefined);
      return;
    }
    const map = StaticAdvertCreatorHelper.process(ads_.right.items);

    if (map.has(_)) {
      runInAction(() => {
        this._setState(map);
        this._adToShow = _;
      });
    }
  }

  private _parseUrlSpots(_: Url): AdId | undefined {
    const params = new URL(_);
    return (
      (params.searchParams.get(PushAdvertService.AD_ID_URL_PARAM) as AdId) ||
      undefined
    );
  }

  private _fetchOnConnected() {
    return reaction(
      () => this._root.auth.isConnected,
      async (isConnected) => {
        if (isConnected) {
          // noinspection JSIgnoredPromiseFromCall
          const initial_ = await this._root.locationSource.getInitial();

          if (!initial_.success) {
            return;
          }
          const spot = this._parseUrlSpots(initial_.right);

          if (spot) {
            await this._fetch(spot);
          }
        }
      },
    );
  }

  private _listenUpdateLocationSource() {
    return this._root.locationSource.updates.listen(async (data) => {
      const spot = this._parseUrlSpots(data);
      if (spot) {
        await this._fetch(spot);
      }
    });
  }

  subscribe() {
    return batchDisposers(
      this._fetchOnConnected(),
      this._listenUpdateLocationSource(),
    );
  }
}
