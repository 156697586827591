import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';
import {useStrings, useRoot} from '../Root/hooks';
import PaymentLight from '../assets/svg/colored/paymentsLight.svg';
import PaymentDark from '../assets/svg/colored/paymentsDark.svg';
import {Typography} from '../components';
import {sized} from '../Svg';

export default observer(() => {
  const {appearance} = useRoot();
  const PaymentIcon = appearance.isDark ? PaymentDarkIcon : PaymentsLightIcon;
  const styles = useStyles(() => ({
    root: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      marginBottom: 12,
    },
    body: {
      marginTop: 21,
    },
  }));
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <PaymentIcon style={styles.icon} />
      <Typography type="body" weight="500" style={styles.body}>
        {strings['withdrawalHistory.empty']}
      </Typography>
    </View>
  );
});

const PaymentsLightIcon = sized(PaymentLight, 78, 84);
const PaymentDarkIcon = sized(PaymentDark, 78, 84);
