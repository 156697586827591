import {Url} from '../units';

export interface SpecialLocation {
  getOauthRedirect(): Url;
  parse(locator: Url): SpecialLocator;
}

export const UNKNOWN = Symbol();
export const OAUTH_REDIRECT = Symbol();
export const INTERNAL_AUTHENTICATION = Symbol();
export const EXTERNAL = Symbol();

export type Unknown = {
  kind: typeof UNKNOWN;
};

export type OauthRedirect = {
  kind: typeof OAUTH_REDIRECT;
};

export type InternalAuthentication = {
  kind: typeof INTERNAL_AUTHENTICATION;
  fernetToken: string;
};

export type External = {
  kind: typeof EXTERNAL;
};

export type SpecialLocator =
  | Unknown
  | OauthRedirect
  | InternalAuthentication
  | External;
