import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Swipeable} from 'react-native-gesture-handler';
import {View, Animated, Pressable, StyleProp, ViewStyle} from 'react-native';
import {useTheme, variance} from '../../styling';
import CloseSvg from '../../assets/svg/colorless/close.svg';
import {sized} from '../../Svg';

export type SwipeableBannerProps = {
  children: React.ReactNode;
  onDelete: () => void;
  enabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  childrenContainerStyle?: StyleProp<ViewStyle>;
};

export default observer(function SwipeableBanner({
  children,
  onDelete,
  enabled,
  containerStyle,
  childrenContainerStyle,
}: SwipeableBannerProps) {
  const theme = useTheme();

  const RightAction = useMemo(
    () => (progress: ReturnType<Animated.Value['interpolate']>) => {
      const trans = progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      });

      return (
        <BannerAction style={{opacity: trans}}>
          <BannerActionClose onPress={onDelete}>
            <ActionCloseSvg color={theme.colors.primaryUIDirtyBlue} />
          </BannerActionClose>
        </BannerAction>
      );
    },
    [theme.colors.primaryUIDirtyBlue, onDelete],
  );

  return (
    <Swipeable
      enabled={enabled}
      containerStyle={[{borderRadius: 12}, containerStyle]}
      childrenContainerStyle={[childrenContainerStyle]}
      renderRightActions={RightAction}
      overshootRight={false}
      onSwipeableLeftOpen={onDelete}
      overshootLeft={false}
      renderLeftActions={() => <View style={{width: '100%'}} />}>
      {children}
    </Swipeable>
  );
});

const BannerAction = variance(Animated.View)((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -5,
    width: 75,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: theme.colors.uiAdditional1,
  },
}));

const BannerActionClose = variance(Pressable)((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    borderRadius: 28,
    backgroundColor: theme.colors.uiMain,
  },
}));

const ActionCloseSvg = sized(CloseSvg, 10);
