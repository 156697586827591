import {GiftState} from './Gift';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {Gift} from '../InAppOffersService';
import {ConnectedClient} from '../ContextClient';
import {PurchaseId} from '../units';
import {PurchaseScreenState} from '../PurchaseScreen';
import {debounce} from 'lodash';
import {DoubleProposal} from '../ProposalsState';

export default class GiftImpl implements GiftState {
  @observable _gift: Map<PurchaseId, Gift | undefined> = new Map();
  @observable private _isLoading = false;
  @observable private _isThereGiftToFetch: boolean = false;

  constructor(
    protected readonly _root: {
      connectedClient: ConnectedClient;
      purchaseScreenState: PurchaseScreenState;
    },
  ) {
    makeObservable(this);
  }

  @computed
  get isLoading() {
    if (this._isThereGiftToFetch) {
      return true;
    }
    return this._isLoading;
  }

  @computed
  get poolGift() {
    const {selectedDoubleProposal} = this._root.purchaseScreenState;
    const proposal =
      selectedDoubleProposal && this._getSingleProposal(selectedDoubleProposal);
    return proposal ? this._gift.get(proposal.offer.purchaseId) : undefined;
  }

  @action.bound
  queryGift = async (sku: PurchaseId) => {
    const alreadyExist = this._gift.get(sku);
    if (alreadyExist) {
      runInAction(() => (this._isThereGiftToFetch = false));
      return alreadyExist;
    }
    runInAction(() => (this._isLoading = true));
    const gift = await this._root.connectedClient.call('get_gift', {sku});
    runInAction(() => (this._isThereGiftToFetch = false));
    if (gift.success) {
      runInAction(() => {
        this._gift.set(sku, gift.right);
        this._isLoading = false;
      });
      return gift.right;
    }
    runInAction(() => (this._isLoading = false));
  };

  private _debouncedGiftQuery = debounce(this.queryGift, 600);

  private _getSingleProposal(proposal: DoubleProposal) {
    return proposal?.product || proposal?.subscription;
  }

  init() {
    return reaction(
      () => this._root.purchaseScreenState.selectedDoubleProposal,
      (selected) => {
        const offer = selected && this._getSingleProposal(selected);
        if (offer) {
          const id = offer.offer.purchaseId;
          runInAction(() => (this._isThereGiftToFetch = true));
          this._debouncedGiftQuery(id);
        }
      },
      {fireImmediately: true},
    );
  }
}
