import React, {useCallback, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalRef} from '../../components/templates';
import {IntroVideoModal} from '../../IntroVideoModal';
import {useRoot} from '../../Root/hooks';
import {IntroModalStatus} from '../../IntroVideoManager/IntroVideoManagerService';
import {useNavigationGetIsFocused, useSafelyGoBack} from '../hooks';
import {useTimer} from 'use-timer';
import {autorun} from 'mobx';

export default observer(function IntroVideoBinding() {
  const {introVideoManager} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const navigationGetIsFocused = useNavigationGetIsFocused();
  const [notShownAgain, setNotShownAgain] = React.useState(false);
  const modalRef = useRef<AdaptiveModalRef>(null);

  const toggleNotShownAgain = () => {
    setNotShownAgain(!notShownAgain);
  };

  const closeVideoModal = useCallback(async () => {
    await modalRef.current?.close();
    safelyGoBack();
    introVideoManager.closeVideoOnTime();
  }, [safelyGoBack, introVideoManager]);

  const navigateToVideoModalWithFlag = useCallback(async () => {
    introVideoManager.updateCanModalPreviewShown();
    await modalRef.current?.close();
    if (notShownAgain) {
      introVideoManager.closeVideoOnTime();
    }

    introVideoManager.setModalStatus(IntroModalStatus.Video);
  }, [introVideoManager, notShownAgain]);

  const onClosedPreviewVideoWithFlag = useCallback(async () => {
    await modalRef.current?.close();
    introVideoManager.updateCanModalPreviewShown();
    if (notShownAgain) {
      introVideoManager.closeVideoOnTime();
    }
    safelyGoBack();
  }, [safelyGoBack, introVideoManager, notShownAgain]);

  const {time, start} = useTimer({
    autostart: false,
    initialTime: 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: onClosedPreviewVideoWithFlag,
  });

  useEffect(
    () =>
      autorun(() => {
        if (
          navigationGetIsFocused() &&
          introVideoManager.currentModalStatus === IntroModalStatus.Preview
        ) {
          start();
        }
      }),
    [safelyGoBack, start, navigationGetIsFocused, introVideoManager],
  );
  return (
    <IntroVideoModal
      showStatus={introVideoManager.currentModalStatus}
      closeVideoModal={closeVideoModal}
      navigateToVideoModalWithFlag={navigateToVideoModalWithFlag}
      onClosedPreviewVideoWithFlag={onClosedPreviewVideoWithFlag}
      seconds={time}
      notShownAgain={notShownAgain}
      toggleNotShownAgain={toggleNotShownAgain}
      onCrossClose={onClosedPreviewVideoWithFlag}
      ref={modalRef}
    />
  );
});
