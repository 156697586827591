import BaseMinerProposalsStateImpl from './BaseMinerProposalsStateImpl';
import {ManagedProposalSubscription} from './ProposalsState';
import {InAppOffers, MinerSubscriptionOffer} from '../InAppOffersService';

export default class SubMinerProposalsStateImpl extends BaseMinerProposalsStateImpl {
  constructor(
    readonly _root: {
      readonly inAppOffers: InAppOffers;
    },
  ) {
    super(_root);
  }

  protected _getManagedProposalSubscription():
    | ManagedProposalSubscription<MinerSubscriptionOffer>
    | undefined {
    return undefined;
  }
}
