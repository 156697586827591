import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import RenewSvg from '../../assets/svg/colorless/renew.svg';
import {AppButton, ButtonVariant} from '../../components/AppButton';
import {sized} from '../../Svg';
import {useStrings} from '../../Root/hooks';

export type ErrorViewProps = {
  onPress: () => void;
};

export default observer(function ErrorView({onPress}: ErrorViewProps) {
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <AppButton
        variant={ButtonVariant.Error}
        onPress={onPress}
        activeOpacity={0.8}
        style={styles.button}
        Icon={RenewIcon}
        rootStyle={styles.result}>
        {strings['puzzle.retry']}
      </AppButton>
    </View>
  );
});
const RenewIcon = sized(RenewSvg, 20);

const styles = StyleSheet.create({
  root: {
    height: 44,
  },
  button: {
    flex: 1,
  },
  result: {
    flex: 1,
  },
});
