import {FileSystem} from './FileSystem';

export default class WebFileSystemImpl implements FileSystem {
  async isGranted() {
    return true;
  }

  async save(
    content: string,
    filename: string,
    mimeType: string,
  ): Promise<void> {
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(new Blob([content], {type: mimeType}));
    a.click();
  }
}
