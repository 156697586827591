import {ScheduleStore} from '../ScheduleScreen/ScheduleStore';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {action, computed, observable, reaction, makeObservable} from 'mobx';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerId} from '../ApiStore';
import {batchDisposers} from '../structure';
import {USER_TIMEZONE_MINUTES} from '../ScheduleScreen/constant';
import {selectWorkersOnUpdate} from '../helpers/selectWorkersOnUpdate';

export default class ScheduleWorkersModalState {
  constructor(
    private readonly _root: {
      readonly scheduleStore: ScheduleStore;
      readonly dashboardStore: DashboardStore;
    },
    private readonly _workerIds: string[],
  ) {
    makeObservable(this);
  }

  @observable private _userTimezoneMinutes = USER_TIMEZONE_MINUTES;
  @observable private _selectedWorkerIds = new Set<WorkerId>();

  @computed
  get workers(): WorkerEntity[] {
    return this._workerIds.flatMap((workerId) => {
      const worker = this._root.dashboardStore.workers.get(workerId);
      return worker ? [worker] : [];
    });
  }

  get userTimezoneMinutes() {
    return this._userTimezoneMinutes;
  }

  get selectedWorkerIds() {
    return this._selectedWorkerIds;
  }

  setSelectedWorkerIds = action((workerIds: Set<WorkerId>) => {
    this._selectedWorkerIds = workerIds;
  });

  selectWorkerId = action((id: WorkerId) => {
    this._selectedWorkerIds.add(id);
  });

  unselectWorkerId = action((id: WorkerId) => {
    this._selectedWorkerIds.delete(id);
  });

  changeTimezoneOffset = action((newTimezoneOffset: number) => {
    this._userTimezoneMinutes = newTimezoneOffset;
  });

  private _selectWorkerOnUpdate = () =>
    reaction(
      () => this.workers.length,
      () => {
        this._selectedWorkerIds = selectWorkersOnUpdate(
          this.workers,
          this.userTimezoneMinutes,
        );
      },
      {fireImmediately: true},
    );

  subscribe = () => {
    return batchDisposers(this._selectWorkerOnUpdate());
  };
}
