import React, {useCallback} from 'react';
import {AppForceParamList} from '../AppForceParamList';
import ArrowLeftSvg from '../../assets/svg/colorless/arrowLeft.svg';
import {
  StackNavigationOptions,
  StackNavigationProp,
} from '@react-navigation/stack';
import {AppRouteName} from '../AppRouteName';
import useGetScreenSegments, {
  SpecificSegmentsKind,
} from './useGetRouteSegments';
import HeaderMenuUserInfo from '../../components/HeaderMenuUserInfo';
import HeaderTitle from '../../components/HeaderTitle';
import {useTheme} from '../../styling';
import {sized} from '../../Svg';
import HeaderActionButton from '../../components/HeaderActionButton';
import useSafelyGoBack from './useSafelyGoBack';
import {CryptotabFarmLogo} from '../../components/CryptotabFarmLogo';
import {Linking, Platform, Pressable} from 'react-native';
import {useRoot} from '../../Root/hooks';
import {ApiModeBadge} from '../../components';

export type GetScreenOptionsHookParams = {
  routeName: AppRouteName;
  navigation: StackNavigationProp<AppForceParamList>;
  goBackPossible: boolean;
};

export default function useGetMobileScreenOptions() {
  const getScreenSegments = useGetScreenSegments();
  const theme = useTheme();
  const {location} = useRoot();
  const {
    configuration: {values},
  } = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const handleLogoClick = useCallback(() => {
    Linking.openURL(values.overview);
  }, [values.overview]);
  const goToVerify = useCallback(() => {
    location.open(values.affiliateVerifyUrl);
  }, [values.affiliateVerifyUrl, location]);
  return useCallback(
    ({
      navigation,
      routeName,
      goBackPossible,
    }: GetScreenOptionsHookParams): StackNavigationOptions => {
      const segments = getScreenSegments({
        routeName,
        navigation,
      });
      const defaultOptions: StackNavigationOptions = {
        headerTitle: () => (
          <HeaderTitle
            title={segments.title}
            notificationsCount={segments.notificationsCount}
          />
        ),
        headerLeft: () =>
          goBackPossible && (
            <HeaderActionButton onPress={safelyGoBack}>
              <ArrowIcon color={theme.colors.uiSecondary} />
            </HeaderActionButton>
          ),
        headerBackTitleVisible: false,
        headerStyle: {
          backgroundColor: theme.colors.backgroundGround,
          borderBottomWidth: 0,
        },
        headerRightContainerStyle: {
          paddingHorizontal: 10,
        },
        headerLeftContainerStyle: {
          paddingLeft: 16,
        },
        headerTitleAlign: 'left',
        ...getAdditionalParams(routeName),
      };
      switch (segments.kind) {
        case SpecificSegmentsKind.Dashboard:
          return {
            ...defaultOptions,
            headerLeft: () => segments.settingsIcon,
            headerRight: () => segments.profileIcon,
          };
        case SpecificSegmentsKind.Withdraw:
        case SpecificSegmentsKind.PaymentSuccess:
          return {
            ...defaultOptions,
            headerRight: () => segments.withdrawHistoryIcon,
          };
        case SpecificSegmentsKind.Notifications:
          return {
            ...defaultOptions,
            headerRight: () => segments.settingsIcon,
          };
        case SpecificSegmentsKind.Plans:
          return {
            ...defaultOptions,
            headerRight: () => segments.settingsIcon,
          };
        case SpecificSegmentsKind.Menu:
          return {
            ...defaultOptions,
            headerTitle: () => (
              <HeaderMenuUserInfo
                onLoginPress={() => navigation.navigate('Auth')}
                onVerifyPress={goToVerify}
              />
            ),
            headerRight: () => segments.themeSwitcherIcon,
          };
        case SpecificSegmentsKind.Statistic:
          return {
            ...defaultOptions,
            headerRight: () => (
              <ApiModeBadge style={{marginRight: 6}} hideInReal />
            ),
          };
        case SpecificSegmentsKind.Auth:
          return {
            ...defaultOptions,
            headerTitle: () =>
              Platform.OS === 'web' ? (
                <Pressable onPress={handleLogoClick}>
                  <CryptotabFarmLogo />
                </Pressable>
              ) : (
                <CryptotabFarmLogo />
              ),
          };
      }
      return defaultOptions;
    },
    [getScreenSegments, safelyGoBack, theme, handleLogoClick, goToVerify],
  );
}

const ArrowIcon = sized(ArrowLeftSvg, 18, 16);

function getAdditionalParams(routeName: AppRouteName) {
  switch (routeName) {
    case 'WorkerDetails':
      return {
        gestureResponseDistance: 30,
      };
    case 'Plan':
      return {
        gestureResponseDistance: 20,
      };
    case 'CheckAuth':
      return {
        headerShown: false,
        animationEnabled: false,
        cardStyle: {backgroundColor: 'transparent'},
      };
    case 'OAuthSplash':
      return {headerShown: false};
    case 'DashboardSettings':
      return {
        gestureResponseDistance: 20,
      };
    case 'QRCodeScanner':
      return {
        gestureEnabled: false,
        headerShown: false,
      };
    case 'FarmAction':
      return {
        headerShown: false,
        animationEnabled: false,
        cardStyle: {backgroundColor: 'transparent'},
      };
    default:
      return {};
  }
}
