import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {variance} from '../../../../styling';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';
import WorkerPowerSlider from '../../../../components/organisms/worker/WorkerPowerSlider';
import {Divider} from '../../../../components/atoms';
import useGetIsLarge from '../../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';

export type PowerSliderProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export default observer(function PowerSlider({worker}: PowerSliderProps) {
  const {goToPoolMinerSchedulerWarning, onWorkerSpeedChanged} =
    useForcedContext(DashboardContext);
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  return (
    <RootView large={isLarge}>
      {isLarge && (
        <View>
          <Divider vertical style={styles.divider} />
        </View>
      )}
      <ContentView large={isLarge}>
        <WorkerPowerSlider
          visibleTitle
          onValueChanged={onWorkerSpeedChanged}
          worker={worker}
          goToPoolMinerSchedulerWarning={goToPoolMinerSchedulerWarning}
        />
      </ContentView>
      <View>
        <Divider vertical={isLarge} style={styles.divider} />
      </View>
    </RootView>
  );
});

const styles = StyleSheet.create({
  divider: {
    flex: 1,
    height: '100%',
  },
  verticalDivider: {},
});

const RootView = variance(View)(() => ({
  root: {},
  large: {
    flexDirection: 'row',
    flex: 5,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    flexGrow: 1,
    paddingVertical: 12,
  },
  large: {},
}));
