import React, {PropsWithChildren} from 'react';
import {Platform, Text, TextProps, TextStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';

export type TypographyWeight =
  | 'normal'
  | 'bold'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

export type TypographySize =
  | 'large'
  | 'medium'
  | 'regular'
  | 'small'
  | 'tiny'
  | 'micro';

export type HeaderTypographyProps = {
  type: 'header';
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  weight?: TypographyWeight;
};

export type BodyTypographyProps = {
  type: 'body';
  weight?: TypographyWeight;
};

export type ParagraphTypographyProps = {
  type: 'paragraph';
  weight?: TypographyWeight;
  size?: TypographySize;
} & {
  size?: 'large' | 'regular' | 'tiny';
};

export type MonoTypographyProps = {
  type: 'mono';
  weight?: TypographyWeight;
};

export type BaseTypographyProps =
  | HeaderTypographyProps
  | BodyTypographyProps
  | ParagraphTypographyProps
  | MonoTypographyProps;

export type TypographyProps = PropsWithChildren<
  BaseTypographyProps & TextProps
>;

export default observer(function Typography(props: TypographyProps) {
  switch (props.type) {
    case 'header':
      return <Header {...props} />;
    case 'body':
      return <Body {...props} />;
    case 'paragraph':
      return <Paragraph {...props} />;
    case 'mono':
      return <Mono {...props} />;
  }
  return null;
});

export const Header = observer(
  (props: PropsWithChildren<TextProps & HeaderTypographyProps>) => {
    const {size, weight = '700', children, style, ...rest} = props;
    const styles = useStyles((theme) => ({
      root: {
        fontFamily: fontByWeightCommissionerMap['700'],
        color: theme.colors.uiSecondary,
      },
      h1: {
        fontSize: 36,
        lineHeight: 40,
      },
      h2: {
        fontSize: 23,
        lineHeight: 28,
      },
      h3: {
        fontSize: 18,
        lineHeight: 22,
      },
      h4: {
        fontSize: 16,
        lineHeight: 20,
      },
      h5: {
        fontSize: 14,
        lineHeight: 20,
      },
      h6: {
        fontSize: 12,
        lineHeight: 15,
      },
    }));
    let sizeStyles;
    switch (size) {
      case 'h1':
        sizeStyles = styles.h1;
        break;
      case 'h2':
        sizeStyles = styles.h2;
        break;
      case 'h3':
        sizeStyles = styles.h3;
        break;
      case 'h4':
        sizeStyles = styles.h4;
        break;
      case 'h5':
        sizeStyles = styles.h5;
        break;
      case 'h6':
        sizeStyles = styles.h6;
        break;
    }
    return (
      <Text
        style={[
          styles.root,
          sizeStyles,
          getCommissionerFontStyles(weight),
          style,
        ]}
        {...rest}>
        {children}
      </Text>
    );
  },
);

export const Body = observer(
  (props: PropsWithChildren<TextProps & BodyTypographyProps>) => {
    const {children, style, weight = '400', ...rest} = props;
    const styles = useStyles((theme) => ({
      root: {
        color: theme.colors.uiSecondary,
        lineHeight: 17,
      },
    }));
    return (
      <Text
        style={[styles.root, getCommissionerFontStyles(weight), style]}
        {...rest}>
        {children}
      </Text>
    );
  },
);

export const Paragraph = observer(
  (props: PropsWithChildren<TextProps & ParagraphTypographyProps>) => {
    const {children, style, weight = '400', size = 'regular', ...rest} = props;
    const styles = useStyles((theme) => ({
      root: {
        color: theme.colors.uiSecondary,
        fontSize: 14,
        lineHeight: 14 * 1.3,
      },
      large: {
        fontSize: 13,
        lineHeight: 18,
      },
      regular: {
        fontSize: 12,
        lineHeight: 16,
      },
      tiny: {
        fontSize: 11,
        lineHeight: 16,
      },
    }));
    let sizeStyles;
    switch (size) {
      case 'large':
        sizeStyles = styles.large;
        break;
      case 'regular':
        sizeStyles = styles.regular;
        break;
      case 'tiny':
        sizeStyles = styles.tiny;
        break;
    }
    return (
      <Text
        style={[
          styles.root,
          sizeStyles,
          getCommissionerFontStyles(weight),
          style,
        ]}
        {...rest}>
        {children}
      </Text>
    );
  },
);

export const Mono = observer(
  (props: PropsWithChildren<TextProps & MonoTypographyProps>) => {
    const {children, style, weight = '400', ...rest} = props;
    const styles = useStyles((theme) => ({
      root: {
        color: theme.colors.uiSecondary,
        fontSize: 14,
        lineHeight: 14 * 1.3,
      },
    }));
    return (
      <Text style={[styles.root, getMonoFontStyles(weight), style]} {...rest}>
        {children}
      </Text>
    );
  },
);

export function getCommissionerFontStyles(weight: TypographyWeight) {
  return Platform.select<TextStyle>({
    default: {
      fontFamily: fontByWeightCommissionerMap[weight],
    },
    web: {
      fontFamily: 'Commissioner',
      fontWeight: weight,
    },
  });
}

export function getMonoFontStyles(weight: TypographyWeight) {
  return Platform.select<TextStyle>({
    default: {
      fontFamily: fontByWeightMonoMap[weight],
    },
    web: {
      fontFamily: 'JetBrains Mono',
      fontWeight: weight,
    },
  });
}

export const fontByWeightCommissionerMap: Record<TypographyWeight, string> = {
  '100': 'Commissioner-Thin',
  '200': 'Commissioner-ExtraThin',
  '300': 'Commissioner-Light',
  '400': 'Commissioner-Regular',
  normal: 'Commissioner-Regular',
  '500': 'Commissioner-Medium',
  '600': 'Commissioner-SemiBold',
  '700': 'Commissioner-Bold',
  bold: 'Commissioner-Bold',
  '800': 'Commissioner-ExtraBold',
  '900': 'Commissioner-Black',
} as const;

export const fontByWeightMonoMap: Record<TypographyWeight, string> = {
  '100': 'JetBrainsMono-Thin',
  '200': 'JetBrainsMono-ExtraThin',
  '300': 'JetBrainsMono-Light',
  '400': 'JetBrainsMono-Regular',
  normal: 'JetBrainsMono-Regular',
  '500': 'JetBrainsMono-Medium',
  '600': 'JetBrainsMono-SemiBold',
  '700': 'JetBrainsMono-Bold',
  bold: 'JetBrainsMono-Bold',
  '800': 'JetBrainsMono-ExtraBold',
  '900': 'JetBrainsMono-ExtraBold',
} as const;
