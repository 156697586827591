import {useIsFocused} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useRoot} from '../../Root/hooks';
import {StatisticsPanelState} from '../../StatisticsPanel';
import {StatisticsScreen} from '../../StatisticsScreen';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {ChartViewScope} from '../../GraphPanel';
import {useSafelyGoBack} from '../hooks';
import {Millisecond} from '../../Time';

export type StatisticBindingProps = BottomTabBindingProps<'Statistics'>;

export enum StatisticsTabKey {
  Mining,
  BTC,
}

export default observer(function StatisticBinding({
  navigation,
  route,
}: StatisticBindingProps) {
  const {groupId, activeTab = StatisticsTabKey.Mining} = route.params ?? {};
  const root = useRoot();
  const {dashboardStore} = root;
  const group = dashboardStore.dashboardRequest.value?.groups.find(
    (_) => _.id === groupId,
  );
  const safelyGoBack = useSafelyGoBack();
  const isFocused = useIsFocused();
  useLayoutEffect(() => {
    if (groupId !== undefined && group === undefined) {
      safelyGoBack();
    }
  }, [group, groupId, safelyGoBack]);
  const [state] = useState(() => new StatisticsPanelState(root));
  const onRefresh = useCallback(() => state.refresh(groupId), [state, groupId]);
  useEffect(() => {
    if (isFocused) {
      // noinspection JSIgnoredPromiseFromCall
      state.fetch(groupId);
    }
  }, [isFocused, state, groupId]);

  const onPick = useCallback(
    (id?: number | string) => {
      if (typeof id === 'number' || id === undefined) {
        navigation.setParams({groupId: id});
        return state.fetch(id);
      }
    },
    [navigation, state],
  );

  const goToPlan = useCallback(() => navigation.navigate('Plan'), [navigation]);
  const goToAddDemoMiner = useCallback(
    () => navigation.navigate('AddDemoWorker'),
    [navigation],
  );

  const onFromChange = useCallback(
    (from: Millisecond) => {
      state.onFromChange(from);
      return state.fetch(groupId);
    },
    [state, groupId],
  );
  const onScopeChange = useCallback(
    (scope: ChartViewScope) => {
      state.onScopeChange(scope);
      return state.fetch(groupId);
    },
    [state, groupId],
  );

  const btcInUsd =
    dashboardStore.currentRateRequest.value &&
    Math.round(
      dashboardStore.currentRateRequest.value?.usd /
        dashboardStore.currentRateRequest.value?.btc,
    );

  const handleTabChange = useCallback(
    (tab: StatisticsTabKey) => {
      navigation.setParams({activeTab: tab});
    },
    [navigation],
  );

  return (
    <StatisticsScreen
      activeTab={activeTab}
      onTabChange={handleTabChange}
      onRefresh={onRefresh}
      onPick={onPick}
      onFromChange={onFromChange}
      onScopeChange={onScopeChange}
      groupId={groupId}
      state={state}
      groups={dashboardStore.dashboardRequest.value?.groups}
      balance={dashboardStore.balance}
      btcInUsd={btcInUsd}
      ratesState={state.ratesPanel}
      goToPlan={goToPlan}
      goToAddDemoMiner={goToAddDemoMiner}
    />
  );
});

export type BalanceType = {
  approxUsd: string | null;
  btc: string | null;
};
