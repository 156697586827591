import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import GroupHeaderCount from '../../../components/organisms/group/GroupHeaderMenu';
import {useForcedContext} from '../../../context';
import {SectionWorkerListContext} from './context';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import {variance} from '../../../styling';
import {useRoot, useTemplates} from '../../../Root/hooks';
import HeaderBannerConditionSection, {
  useHeaderBannerConditionSectionHeight,
} from '../../components/HeaderBannerConditionSection';
import HeaderBannerConditionLargeSection, {
  useHeaderBannerConditionLargeSectionHeight,
} from '../../components/HeaderBannerConditionLargeSection';
import {useVisibleHeaderStatusBanner} from '../../components/HeaderStatusBanner';

const CONTENT_VIEW_HEIGHT = 51;
const PADDING_TOP = 16;
const FARM_INFO_PADDING_BOTTOM = 18;

export default observer(function SectionListHeader() {
  const {groupCount, goToAddGroup, goToPickSort} = useForcedContext(
    SectionWorkerListContext,
  );
  const {dashboardService} = useRoot();
  const templates = useTemplates();
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const title = templates['dashboard.sectionWorkerList.header.label']({
    amount: groupCount,
  });
  const getSectionWorkerListHeaderHeight = useGetSectionListHeaderHeight();
  const visibleHeaderStatus = useVisibleHeaderStatusBanner();
  const height = expr(() => getSectionWorkerListHeaderHeight());
  return (
    <View style={{height}}>
      <RootView withPaddingTop={visibleHeaderStatus}>
        {isLarge ? (
          <View style={styles.farmInfoContainer}>
            <HeaderBannerConditionLargeSection />
          </View>
        ) : (
          <HeaderBannerConditionSection />
        )}
        <ContentView large={isLarge}>
          <GroupHeaderCount
            title={title}
            onSortPress={goToPickSort}
            onPlusPress={goToAddGroup}
            order={dashboardService.order}
          />
        </ContentView>
      </RootView>
    </View>
  );
});

export function useGetSectionListHeaderHeight() {
  const getIsLarge = useGetIsLarge();
  const visibleHeaderStatus = useVisibleHeaderStatusBanner();
  const isLarge = getIsLarge();
  const headerBannerConditionSectionHeight =
    useHeaderBannerConditionSectionHeight();
  const headerBannerConditionLargeSectionHeight =
    useHeaderBannerConditionLargeSectionHeight();
  let height = 0;
  // farm info
  height += isLarge
    ? headerBannerConditionLargeSectionHeight + FARM_INFO_PADDING_BOTTOM
    : headerBannerConditionSectionHeight;
  //
  // header status banner (demo || pools)
  height += visibleHeaderStatus ? PADDING_TOP : 0;

  // main content height
  height += CONTENT_VIEW_HEIGHT; // ContentView

  return () => height;
}

const styles = StyleSheet.create({
  farmInfoContainer: {
    paddingBottom: FARM_INFO_PADDING_BOTTOM,
  },
});

const RootView = variance(View)(() => ({
  root: {},
  withPaddingTop: {
    paddingTop: PADDING_TOP,
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    height: CONTENT_VIEW_HEIGHT,
    backgroundColor: theme.colors.backgroundGround,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    borderBottomWidth: 0,
  },
  large: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden',
  },
}));
