import React, {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import BackgroundImage from './BackgroundImage';
import TargetPuzzle from './TargetPuzzle';
import MovingPuzzle from './MovingPuzzle';
import {useMeasure} from '../ReactNativeUtil';
import {
  PercentPosition,
  Position,
  PUZZLE_HEIGHT,
  PUZZLE_HEIGHT_RATIO,
  PUZZLE_WIDTH,
} from './constants';
import {SharedValue, useDerivedValue} from 'react-native-reanimated';
import {clamp} from 'lodash';
import {rnrClamp} from './PuzzleHelperStaticImpl';

export type PuzzleImageProps = {
  targetPosition: Position;
  startPosition: Position;
  imageIndex: number;
  sliderTranslateX: SharedValue<PercentPosition>;
};

export default observer(function PuzzleImage({
  targetPosition,
  startPosition,
  imageIndex,
  sliderTranslateX,
}: PuzzleImageProps) {
  const ref = useRef(null);
  const [getResult, onLayout] = useMeasure(ref);
  const canvasWidth = getResult()?.width ?? 0;
  const canvasHeight = canvasWidth * PUZZLE_HEIGHT_RATIO;
  return (
    <View ref={ref} onLayout={onLayout} style={styles.root}>
      <View
        style={{
          height: canvasHeight,
        }}>
        <BackgroundImage
          width={canvasWidth}
          height={canvasHeight}
          imageIndex={imageIndex}
        />
      </View>
      <Puzzles
        imageIndex={imageIndex}
        targetPosition={targetPosition}
        startPosition={startPosition}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        sliderTranslateX={sliderTranslateX}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    marginBottom: 24,
  },
});

type PuzzlesProps = {
  imageIndex: number;
  targetPosition: Position;
  startPosition: Position;
  canvasWidth: number;
  canvasHeight: number;
  sliderTranslateX: SharedValue<PercentPosition>;
};

// Elements are positioned by their bottom right corner.
const Puzzles = observer(
  ({
    targetPosition,
    startPosition,
    canvasWidth,
    canvasHeight,
    imageIndex,
    sliderTranslateX,
  }: PuzzlesProps) => {
    const targetPercentX = targetPosition.x;
    const targetPercentY = targetPosition.y;

    const currentX = useDerivedValue(() => {
      const xPercent = sliderTranslateX.value + startPosition.x;
      const x = (xPercent / 100) * canvasWidth - PUZZLE_WIDTH;
      return rnrClamp(x, 0, canvasWidth - PUZZLE_WIDTH);
    }, [canvasWidth, startPosition]);
    const currentY = clamp(
      (startPosition.y / 100) * canvasHeight - PUZZLE_HEIGHT,
      0,
      canvasHeight,
    );

    const targetX = (targetPercentX / 100) * canvasWidth - PUZZLE_WIDTH;
    const targetY = rnrClamp(
      (targetPercentY / 100) * canvasHeight - PUZZLE_HEIGHT,
      0,
      canvasHeight,
    );

    return (
      <View style={StyleSheet.absoluteFillObject}>
        <TargetPuzzle targetX={targetX} targetY={targetY} />
        <MovingPuzzle
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          imageIndex={imageIndex}
          targetX={targetX}
          targetY={targetY}
          currentX={currentX}
          currentY={currentY}
        />
      </View>
    );
  },
);
