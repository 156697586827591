import {observer} from 'mobx-react-lite';
import React from 'react';
import QrView from './QrView';
import {useForcedContext} from '../context';
import PaymentScreenContext from './context';

export default observer(function QrBinding() {
  const {
    order: {cryptoPaymentUrl, metamask},
  } = useForcedContext(PaymentScreenContext);
  if (!cryptoPaymentUrl) {
    return null;
  }
  return <QrView value={cryptoPaymentUrl} fee={metamask?.recommendedFee} />;
});
