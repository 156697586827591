import React from 'react';
import {observer} from 'mobx-react-lite';
import DemoCard from './DemoErrorCard';
import PoolMinersErrorCard from './PoolMinersErrorCard';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';
import {useRoot} from '../../Root/hooks';
import {BannerType} from '../../Dashboard';

export default observer(function HeaderStatusBanner() {
  const {
    dashboardService: {bannersState},
  } = useRoot();
  const {goToSwitchDemo, goToPoolActivator} =
    useForcedContext(DashboardContext);
  const hasSomeBanner = useVisibleHeaderStatusBanner();
  const targetBanner = bannersState.targetBanner;
  if (!hasSomeBanner) {
    return null;
  }
  if (targetBanner === BannerType.Demo) {
    return <DemoCard onPress={goToSwitchDemo} />;
  }
  if (targetBanner === BannerType.PoolMiner) {
    return <PoolMinersErrorCard onPress={goToPoolActivator} />;
  }
  return null;
});

export function useVisibleHeaderStatusBanner() {
  const {
    dashboardService: {bannersState},
  } = useRoot();
  return (
    bannersState.targetBanner !== undefined &&
    [BannerType.Demo, BannerType.PoolMiner].includes(bannersState.targetBanner)
  );
}
