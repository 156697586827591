import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../../styling';
import {getCommissionerFontStyles} from '../Typography';
import MarkdownComponent, {
  PartialStyleSheetRecord,
  mergeNamedStyles,
  OverriddenProps,
} from 'react-native-awesome-markdown';
import {useOpenLinkCallback} from '../../Links';

export type MarkdownProps = {
  children: string;
  styles?: PartialStyleSheetRecord;
  rules?: OverriddenProps['rules'];
};

export default observer(function Markdown({
  children,
  styles,
  rules,
}: MarkdownProps) {
  const openLink = useOpenLinkCallback();
  const themedStyles = useStyles((theme) => ({
    link: {
      ...getCommissionerFontStyles('500'),
      color: theme.colors.primaryActive,
      fontStyle: 'normal',
    },
    paragraph: {
      ...getCommissionerFontStyles('400'),
    },
  }));
  const _styles = useMemo(
    () => mergeNamedStyles<PartialStyleSheetRecord>(themedStyles, styles),
    [styles, themedStyles],
  );
  return (
    <MarkdownComponent
      text={children}
      onLinkPress={openLink}
      overridden={{
        globalFontSize: 14,
        styles: _styles,
        rules,
      }}
    />
  );
});
