import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {Typography} from '../components';
import {useStrings} from '../Root/hooks';
import ColorList from './ColorList';
import {COLORS} from '../ScheduleScreen/constant';
import {AppButton, ButtonVariant} from '../components/AppButton';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type PickScheduleSpeedModalProps = {
  onSelect: (speed: number) => void;
  onModalClosed: () => void;
  onClosePress: () => void;
};

export default observer<PickScheduleSpeedModalProps, AdaptiveModalRef>(
  forwardRef(function PickScheduleSpeedModal(props, ref) {
    const {onModalClosed} = props;
    return (
      <AdaptiveModal
        modalWidth={ModalWidth.Small}
        ref={ref}
        adaptiveBreakpoint="sm"
        modalContentStyle={styles.modalContent}
        bottomSheetSnapPoints={['90%']}
        onModalClosed={onModalClosed}>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  ({onSelect, onClosePress}: PickScheduleSpeedModalProps) => {
    const strings = useStrings();
    const paddingBottom = useSafePaddingBottom(16);
    return (
      <View style={[styles.root, {paddingBottom}]}>
        <View style={styles.header}>
          <Typography type="header" size="h4">
            {strings['scheduler.setMiningSpeed']}
          </Typography>
        </View>
        <ColorList colors={COLORS} onSelect={onSelect} />
        <View style={styles.footer}>
          <AppButton variant={ButtonVariant.Primary} onPress={onClosePress}>
            {strings['workerGroup.hideList']}
          </AppButton>
        </View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  header: {
    paddingTop: 30,
    paddingBottom: 20,
    paddingHorizontal: 16,
  },
  footer: {
    paddingTop: 30,
    paddingHorizontal: 16,
  },
  modalContent: {
    flex: 1,
  },
});
