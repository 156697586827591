export enum AppTemplateMode {
  Mobile,
  Tablet,
}

export interface AppTemplateState {
  readonly mode: AppTemplateMode;
  readonly isTablet: boolean;
  readonly expandedAllowed: boolean;
  readonly visibleAsideBar: boolean;
  readonly expandedAsideBar: boolean;
  readonly content: number;

  toggleAsideBar(): void;
}
