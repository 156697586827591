import {observer} from 'mobx-react-lite';
import React from 'react';
import {Pressable, View, Text} from 'react-native';
import {getCommissionerFontStyles} from '../components';
import {useTheme, variance} from '../styling';
import DecorationSVG from '../assets/svg/colorless/tabsDecoration.svg';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {StatisticsTabKey} from '../Navigation/BottomTab/StatisticBinding';

export type StatisticsTabProps = {
  activeTab: StatisticsTabKey;
  onTabPress: (value: StatisticsTabKey) => void;
};

export default observer(function StatisticsTab({
  activeTab,
  onTabPress,
}: StatisticsTabProps) {
  const theme = useTheme();
  return (
    <Container>
      <TabItem
        active={activeTab === StatisticsTabKey.Mining}
        onPress={() => onTabPress(StatisticsTabKey.Mining)}>
        <TabItemText active={activeTab === StatisticsTabKey.Mining}>
          Mining
        </TabItemText>
        <Decoration>
          <DecorationSVG color={theme.colors.uiMain} />
        </Decoration>
      </TabItem>
      <TabItem
        active={activeTab === StatisticsTabKey.BTC}
        onPress={() => onTabPress(StatisticsTabKey.BTC)}>
        <Decoration left>
          <DecorationSVG color={theme.colors.uiMain} />
        </Decoration>
        <TabItemText active={activeTab === StatisticsTabKey.BTC}>
          BTC
        </TabItemText>
      </TabItem>
    </Container>
  );
});

const Container = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiAdditional1,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flexDirection: 'row',
    alignItems: 'stretch',
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        maxWidth: 360,
      },
    }),
  },
}));

const TabItem = variance(Pressable)((theme) => ({
  root: {
    paddingVertical: 13,
    flex: 1,
    justifyContent: 'center',
  },
  active: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: theme.colors.uiMain,
  },
}));

const TabItemText = variance(Text)((theme) => ({
  root: {
    ...getCommissionerFontStyles('600'),
    textAlign: 'center',
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 13,
    lineHeight: 18,
  },
  active: {
    color: theme.colors.uiSecondary,
    fontSize: 16,
    lineHeight: 20,
    fontWeight: '700',
  },
}));

const Decoration = variance(View)(() => ({
  root: {
    position: 'absolute',
    bottom: 0,
    right: -23,
  },
  left: {
    right: 'auto',
    left: -23,
    transform: [{rotate: '-90deg'}],
  },
}));
