import {ConfigurationValues} from '../Configuration';
import {useRoot} from '../Root/hooks';
import {Platform} from 'react-native';
import {first} from 'lodash';
import {AdInfoItem} from '../Advert';
import {Url} from '../units';

export default (
  getUrl: (values: ConfigurationValues) => string,
  banner: AdInfoItem | undefined,
): Url => {
  const {configuration, appearance, advertHelper, translation} = useRoot();
  const url = getUrl(configuration.values);
  const localizedUrl = url.replace('{lang}', translation.localeTag);
  const themedUrl = appearance.isDark ? `${localizedUrl}black` : localizedUrl;
  const urlParams = new URLSearchParams();
  if (banner) {
    const {title, body, icon} = banner;
    const firstAction = first(banner.actions);
    urlParams.set('ads_title', advertHelper.translate(title) || '');
    urlParams.set('ads_body', advertHelper.translate(body) || '');
    if (icon) {
      urlParams.set('ads_img', icon);
    }
    if (firstAction && firstAction.link !== undefined) {
      urlParams.set('ads_url', firstAction.link);
      const translatedButtonTitle = advertHelper.translate(firstAction.title);
      if (translatedButtonTitle) {
        urlParams.set('ads_button', translatedButtonTitle);
      }
    }
  }
  urlParams.set('embed', 'true');
  urlParams.set('platform', Platform.OS);
  const query = urlParams.toString();
  return `${themedUrl}?${query}` as Url;
};
