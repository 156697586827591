import {observer} from 'mobx-react-lite';
import React, {ForwardedRef, useCallback, useState} from 'react';
import {ListRenderItem, StyleSheet, View} from 'react-native';
import {AdaptiveModal, AdaptiveModalRef, ModalWidth} from '../../templates';
import useSafePaddingBottom from '../../templates/AdaptiveModal/useSafePaddingBottom';
import {variance} from '../../../styling';
import Typography from '../../Typography';
import {AppButton, ButtonVariant} from '../../AppButton';
import {useStrings} from '../../../Root/hooks';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import ActiveCircleSvg from '../../../assets/svg/colored/activeCircle.svg';
import {AdaptiveFlatList} from '../../templates/AdaptiveModal';
import {
  AdaptiveModalHeader,
  AdaptiveModalHeaderProps,
} from './AdaptiveModalHeader';

const ADAPTIVE_BREAKPOINT = 'sm';

export type AdaptivePickerItem<V> = {
  key: React.Key;
  title: string;
  value: V;
};

export type AdaptivePickerModalProps<V> = AdaptiveModalHeaderProps & {
  onModalClosed: () => void;
  onSubmit: (value: V | undefined) => void;
  initialValue?: V;
  items: AdaptivePickerItem<V>[];
  modalRef: ForwardedRef<AdaptiveModalRef>;
};

export default observer(function AdaptivePickerModal<V>(
  props: AdaptivePickerModalProps<V>,
) {
  const {onModalClosed, onSubmit, initialValue, modalRef} = props;
  const [selectedValue, setSelectedValue] = useState<V | undefined>(
    initialValue,
  );
  const submit = () => onSubmit(selectedValue);
  return (
    <AdaptiveModal
      modalWidth={ModalWidth.Small}
      ref={modalRef}
      enableContentAutoHeight
      onModalClosed={onModalClosed}
      adaptiveBreakpoint={ADAPTIVE_BREAKPOINT}>
      <ContentView<V>
        {...props}
        onSelect={setSelectedValue}
        onSubmit={submit}
        selectedValue={selectedValue}
      />
    </AdaptiveModal>
  );
});

type ContentViewProps<V> = AdaptivePickerModalProps<V> & {
  onSelect: (value: V) => void;
  onSubmit: () => void;
  selectedValue: V | undefined;
};

function ContentView<V>({
  title,
  description,
  IconSvg,
  IconSvgModalRep,
  items,
  onSelect,
  onSubmit,
  selectedValue,
}: ContentViewProps<V>) {
  const strings = useStrings();
  const paddingBottom = useSafePaddingBottom(16, 20);
  const renderItem: ListRenderItem<AdaptivePickerItem<V>> = useCallback(
    ({item: _}) => {
      const selected = _.value === selectedValue;
      return (
        <TouchableWithoutFeedback onPress={() => onSelect(_.value)}>
          <View style={styles.listItem}>
            <ItemTitleText selected={selected} type="body" weight="500">
              {_.title}
            </ItemTitleText>
            {selected && <ActiveCircleSvg />}
          </View>
        </TouchableWithoutFeedback>
      );
    },
    [onSelect, selectedValue],
  );
  return (
    <View>
      <AdaptiveModalHeader
        title={title}
        description={description}
        IconSvg={IconSvg}
        IconSvgModalRep={IconSvgModalRep}
        style={styles.header}
      />
      <AdaptiveFlatList<AdaptivePickerItem<V>>
        bounces={false}
        contentContainerStyle={styles.container}
        data={items}
        keyExtractor={(item) => String(item.key)}
        renderItem={renderItem}
      />
      <View style={[styles.buttonView, {paddingBottom}]}>
        <AppButton variant={ButtonVariant.Primary} onPress={onSubmit}>
          {strings['action.select']}
        </AppButton>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  listItem: {
    minHeight: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 16,
  },
  buttonView: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  container: {
    paddingVertical: 5,
    paddingHorizontal: 16,
  },
  header: {
    padding: 16,
  },
});

const ItemTitleText = variance(Typography)((theme) => ({
  root: {
    fontSize: 14,
    lineHeight: 17,
  },
  selected: {
    color: theme.colors.primaryAttractive,
  },
}));
