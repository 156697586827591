import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalRef} from '../components/templates';
import {AdaptiveModalHeaderProps} from '../components/organisms/modal/AdaptiveModalHeader';
import {
  IntroModalStatus,
  IntroVideoModalStatus,
} from '../IntroVideoManager/IntroVideoManagerService';
import PreviewModal, {PreviewModalProps} from './PreviewModal';
import VideoModal, {VideoModalProps} from './VideoModal';

export type IntroVideoModalProps = AdaptiveModalHeaderProps &
  VideoModalProps &
  PreviewModalProps & {
    showStatus: IntroVideoModalStatus;
  };

export default observer<IntroVideoModalProps, AdaptiveModalRef>(
  forwardRef(function IntroVideoModal(props, ref) {
    const {
      showStatus,
      closeVideoModal,
      navigateToVideoModalWithFlag,
      onClosedPreviewVideoWithFlag,
      seconds,
      notShownAgain,
      toggleNotShownAgain,
      onCrossClose,
    } = props;

    if (showStatus === IntroModalStatus.Preview) {
      return (
        <PreviewModal
          ref={ref}
          onClosedPreviewVideoWithFlag={onClosedPreviewVideoWithFlag}
          navigateToVideoModalWithFlag={navigateToVideoModalWithFlag}
          seconds={seconds}
          notShownAgain={notShownAgain}
          toggleNotShownAgain={toggleNotShownAgain}
          onCrossClose={onCrossClose}
        />
      );
    } else if (showStatus === IntroModalStatus.Video) {
      return <VideoModal closeVideoModal={closeVideoModal} ref={ref} />;
    }
    return null;
  }),
);
