import {MetamaskTokenSender} from './MetamaskTokenSender';
import {MetamaskPayment} from './MetamaskPayment';
import {error} from '../fp';
import {MetamaskConnector} from './MetamaskConnector';
import {METAMASK_NOT_CONNECTED} from './ErrorNormalizer';
import {Network} from './Web3Payment';
import {Currency} from '../units';
import {getAbi} from './getAbiByCurrency';

export default class MetamaskPaymentImpl implements MetamaskPayment {
  constructor(
    private readonly _tokenSender: MetamaskTokenSender,
    private readonly _connector: MetamaskConnector,
  ) {}

  async sendToken(
    currency: Currency,
    network: Network,
    recipient: string,
    amount: string,
    decimals: number,
    contractAddress: string | undefined,
  ) {
    return this._connectedGuard(() =>
      this._switchToNetworkGuard(() => {
        if (contractAddress) {
          return this._tokenSender.sendERC20Token(
            contractAddress,
            recipient,
            amount,
            decimals,
            getAbi(currency),
          );
        }
        return this._tokenSender.sendNativeToken(recipient, amount);
      }, network),
    );
  }

  private async _connectedGuard<O extends Function>(op: O) {
    const {_connector} = this;
    if (!_connector.connected) {
      await _connector.connect();
    }
    if (_connector.connected) {
      return op();
    }
    return error(METAMASK_NOT_CONNECTED);
  }

  private async _switchToNetworkGuard<O extends Function>(
    op: O,
    network: Network,
  ) {
    const switchResult = await this._connector.switchToNetwork(network);
    if (!switchResult.success) {
      return switchResult;
    }
    return op();
  }
}
