import {MeasureResult} from '../ReactNativeUtil';
import {StackElementKey} from '../InteractiveTutorial';
import clamp from '../utils/clamp';
import {ARROW_HEIGHT, BOTTOM_TAB_KEYS} from './constants';
import useGetIsLarge from '../DashboardScreen/hooks/useGetIsLarge';
import {useWindowDimensions} from '../WindowDimensions';

export function useTopOffset(
  measure: MeasureResult | undefined,
  activeStepKey: StackElementKey,
  cardHeight: number,
) {
  const window = useWindowDimensions();
  const getIsLarge = useGetIsLarge();
  const isLarge = getIsLarge();

  if (!measure) {
    return {isTopAlignArrow: true, offset: 100};
  }
  if (BOTTOM_TAB_KEYS.includes(activeStepKey) && !isLarge) {
    return {isTopAlignArrow: false, offset: measure.pageY - cardHeight - 80};
  }

  const {pageY, height} = measure;

  const pageYContentBottom = pageY + height;

  const isTopAlignArrow = pageY + height / 2 > window.height / 2;

  const result = isTopAlignArrow
    ? clamp(
        pageY - cardHeight - ARROW_HEIGHT - 20,
        0,
        window.height - cardHeight,
      )
    : clamp(
        pageYContentBottom + ARROW_HEIGHT + 20,
        0,
        window.height - cardHeight,
      );

  return {offset: result, isTopAlignArrow} as const;
}
