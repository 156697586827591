import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef, useState} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {LinkDemoWorkerModal} from '../../LinkDemoWorkerModal';
import {useRoot} from '../../Root/hooks';
import {ApiMode} from '../../farmApi';
import {expr} from 'mobx-utils';
import {isNil} from 'lodash';
import {useErrorHandler} from '../../ExceptionHandler';
import {DemoConfig} from '../../../universal/features/api/entity/GetDemoConfigResponse';
import {RootStackBindingProps} from './RootStackBindingProps';

export default observer(function LinkDemoMinerBinding({
  navigation,
}: RootStackBindingProps<'LinkDemoWorker'>) {
  const {
    dashboardStore,
    apiStore,
    connectedClient,
    apiStore: {mode},
  } = useRoot();
  const errorHandler = useErrorHandler();
  const isDemo = expr(() => mode === ApiMode.Demo);
  const safelyGoBack = useSafelyGoBack();
  const items = expr(
    () => dashboardStore.getDemoConfigRequest.value?.configs ?? [],
  );
  const [selectedConfigIndex, setSelectedConfigIndex] = useState<number>();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = () => modalRef.current?.close();
  const onSubmit = useCallback(async () => {
    await close();
    const configuration = !isNil(selectedConfigIndex)
      ? items[selectedConfigIndex]
      : undefined;
    if (configuration !== undefined && apiStore.mode === ApiMode.Demo) {
      const res = await connectedClient.apply('new_demo_worker', {
        configuration,
      });
      if (!res.success) {
        errorHandler.handle(res.left);
      } else {
        navigation.navigate('Root', {screen: 'Dashboard'});
      }
    }
    safelyGoBack();
  }, [
    apiStore,
    connectedClient,
    errorHandler,
    items,
    navigation,
    safelyGoBack,
    selectedConfigIndex,
  ]);
  const onSelectConfig = useCallback(
    (config: DemoConfig, index: number) => setSelectedConfigIndex(index),
    [],
  );
  return (
    <LinkDemoWorkerModal
      selectedConfigIndex={selectedConfigIndex}
      onSelectConfig={onSelectConfig}
      items={items}
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onSubmit={onSubmit}
      isDemo={isDemo}
    />
  );
});
