import React, {forwardRef, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ActionList} from '../components/organisms';
import {AdaptiveModalRef} from '../components/templates';
import {sized} from '../Svg';
import DesktopSvg from '../assets/svg/colorless/desktop.svg';
import ServerSvg from '../assets/svg/colorless/server.svg';
import GetBtsSvg from '../assets/svg/colorless/getBts.svg';
import LightningSvg from '../assets/svg/colored/energy.svg';
import QrSvg from '../assets/svg/colorless/qr.svg';
import BoxSvg from '../assets/svg/colorless/box.svg';
import {useRoot, useStrings} from '../Root/hooks';
import AddWorkerOptionListModal from './AddWorkerOptionListModal';

export enum AddWorkerAction {
  AddWorker,
  AddDemoWorker,
  AddPoolMiner,
  ScanQR,
  Packet,
  Products,
}

export type AddWorkerModalProps = {
  onModalClosed: () => void;
  onAction: (action: AddWorkerAction) => void;
  isDemo: boolean;
};

export default observer<AddWorkerModalProps, AdaptiveModalRef>(
  forwardRef(function AddWorkerModal({onAction, isDemo, ...rest}, ref) {
    const strings = useStrings();
    const {quickStartOffer} = useRoot();
    const actionList = useMemo(() => {
      const list: ActionList = [
        {
          title: strings['modal.addWorker.actions.addPoolMiner'],
          text: strings['modal.addWorker.action.description'],
          onPress: () => onAction(AddWorkerAction.AddPoolMiner),
          Icon: LightningIcon,
        },
        {
          title: strings['modal.addWorker.actions.addRealWorker'],
          onPress: () => onAction(AddWorkerAction.AddWorker),
          Icon: DesktopIcon,
        },
        {
          title: strings['modal.addWorker.actions.scanQr'],
          onPress: () => onAction(AddWorkerAction.ScanQR),
          Icon: QrIcon,
        },
      ];
      if (quickStartOffer.manuallyOpenQuickStartPossible) {
        list.push({
          title: strings['modal.addWorker.actions.offer'],
          onPress: () => onAction(AddWorkerAction.Packet),
          Icon: BoxIcon,
          label: 'new',
        });
      }
      if (isDemo) {
        list.splice(1, 0, {
          title: strings['modal.addWorker.actions.addDemoWorker'],
          text: strings['modal.addWorker.actions.demo.description'],
          onPress: () => onAction(AddWorkerAction.AddDemoWorker),
          Icon: ServerIcon,
        });
      }
      list.push({
        title: strings['modal.getMoreBtc.action.title'],
        text: strings['modal.getMoreBtc.action.desc'],
        onPress: () => onAction(AddWorkerAction.Products),
        Icon: GetBtsSvgIcon,
      });
      return list;
    }, [isDemo, onAction, quickStartOffer, strings]);
    return (
      <AddWorkerOptionListModal actionList={actionList} {...rest} ref={ref} />
    );
  }),
);

export const GetBtsSvgIcon = sized(GetBtsSvg, 14, 12);
export const DesktopIcon = sized(DesktopSvg, 14, 12);
export const ServerIcon = sized(ServerSvg, 18, 18);
export const LightningIcon = sized(LightningSvg, 10, 16);
export const QrIcon = sized(QrSvg, 16);
export const BoxIcon = sized(BoxSvg, 18);
