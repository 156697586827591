import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStrings} from '../Root/hooks';
import DualGraphPanel, {DualGraphPanelProps} from './DualGraphPanel';
import useSatoshiUsdRate from './useSatoshiUsdRate';
import StatisticsPanelStateImpl from './StatisticsPanelStateImpl';
import {Millisecond} from '../Time';
import {View} from 'react-native';
import {ChartViewScope} from '../GraphPanel';
import GroupItem from './GroupItem';
import Typography from '../components/Typography';
import {variance} from '../styling';
import {WorkerGroupEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {BalanceType} from '../Navigation/BottomTab/StatisticBinding';

export type StatisticsPanelProps = Partial<DualGraphPanelProps> & {
  groupId?: number;
  state: StatisticsPanelStateImpl;
  onPick: (id?: string | number) => void;
  onFromChange: (from: Millisecond) => void;
  onScopeChange: (scope: ChartViewScope) => void;
  groups?: WorkerGroupEntity[];
  balance: BalanceType;
};

export default observer(
  ({
    groupId,
    state,
    onPick,
    onFromChange,
    onScopeChange,
    groups,
    balance,
    ...rest
  }: StatisticsPanelProps) => {
    const strings = useStrings();
    const secondaryAxisRatio = useSatoshiUsdRate();
    const picker = (
      <>
        <GroupItem
          groupId={groupId}
          name={strings['statistics.miningAmountTable.filterDefault']}
          id={undefined}
          onPick={onPick}
          all
        />
        {groups?.map((_) => (
          <GroupItem
            key={_.id}
            groupId={groupId}
            id={_.id}
            name={_.name}
            onPick={onPick}
          />
        ))}
      </>
    );

    const card = (
      <View>
        <Balance type="mono" weight="700">
          {balance.btc} BTC
        </Balance>
        <Approx type="paragraph" weight="500">
          {strings['common.approxShort']} ${balance.approxUsd} USD
        </Approx>
      </View>
    );

    return (
      <DualGraphPanel
        onFromChange={onFromChange}
        from={state.period.from}
        to={state.period.to}
        filterRight={card}
        onScopeChange={onScopeChange}
        scope={state.scope}
        isLoading={state.isLoading && !state.isLoadedIn}
        graph={state.graph}
        title={strings['statistics.miningAmountTable.title']}
        titleRight={picker}
        fractionDigits={0}
        primaryTitle="Satoshi"
        secondaryTitle="USD"
        secondaryAxisRatio={secondaryAxisRatio}
        secondaryFractionDigits={2}
        usdThreshold={0.05}
        {...rest}
      />
    );
  },
);

export const Approx = variance(Typography)((theme) => ({
  root: {
    marginTop: 2,
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 11,
  },
}));

export const Balance = variance(Typography)(() => ({
  root: {
    fontSize: 20,
    lineHeight: 24,
  },
}));
