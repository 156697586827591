import {Millisecond as OpaqueMillisecond} from '../Time';

/**
 * @deprecated
 */
export type Millisecond = number;
/**
 * @deprecated
 */
export type Second = number;
export type Day = number;

export const MILLISECOND = 1 as OpaqueMillisecond;
export const SECOND = (1000 * MILLISECOND) as OpaqueMillisecond;
export const MINUTE = (60 * SECOND) as OpaqueMillisecond;
export const HOUR = (60 * MINUTE) as OpaqueMillisecond;
export const DAY = (24 * HOUR) as OpaqueMillisecond;
