import React, {forwardRef, PropsWithChildren, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View, ViewProps} from 'react-native';
import {AdaptiveModalHeaderProps} from '../components/organisms/modal/AdaptiveModalHeader';
import {
  Action,
  ADAPTIVE_ACTION_LIST_MODAL_BREAKPOINT,
} from '../components/organisms';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
} from '../components/templates';
import {variance} from '../styling';
import {useGetIsDimensions} from '../WindowDimensions/useDimensions';
import {FixedLengthArray} from 'type-fest';
import {ActionOption} from '../components/organisms/modal/OptionActionListModal';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';

export type AddWorkerOptionListModalProps = AdaptiveModalHeaderProps & {
  onModalClosed: () => void;
  actionList: Action[];
};

type SpecialItems = FixedLengthArray<Action, 5>;
const BREAKPOINT = ADAPTIVE_ACTION_LIST_MODAL_BREAKPOINT;
const SPECIAL_LIST_HEIGHT = 250;
const SPECIAL_ITEMS_COUNT = 5;

export default observer<AddWorkerOptionListModalProps, AdaptiveModalRef>(
  forwardRef(function AddWorkerOptionListModal(props, ref) {
    const {actionList, onModalClosed} = props;

    const getIsLarge = useGetIsDimensions(BREAKPOINT);

    const renderItem = useCallback((action: Action, index: number) => {
      return <ActionOption key={index} action={action} style={{flexGrow: 1}} />;
    }, []);

    const renderPureList = useCallback(
      (actions: Action[]) =>
        actions.map((action, index) => (
          <PureItemAdapter key={index}>
            {renderItem(action, index)}
          </PureItemAdapter>
        )),
      [renderItem],
    );

    const renderSpecialGrid = useCallback(
      (actions: SpecialItems) => {
        return (
          <View style={{height: SPECIAL_LIST_HEIGHT}}>
            <Column>
              <Row>
                <ItemGridAdapter>{renderItem(actions[0], 0)}</ItemGridAdapter>
              </Row>
              <Row flex={2}>
                <Column>
                  <Row flex={2}>
                    <ItemGridAdapter>
                      {renderItem(actions[1], 1)}
                    </ItemGridAdapter>
                  </Row>
                  <Row>
                    <ItemGridAdapter>
                      {renderItem(actions[2], 2)}
                    </ItemGridAdapter>
                  </Row>
                </Column>
                <Column>
                  <Row>
                    <ItemGridAdapter>
                      {renderItem(actions[3], 3)}
                    </ItemGridAdapter>
                  </Row>
                  <Row flex={2}>
                    <ItemGridAdapter>
                      {renderItem(actions[4], 4)}
                    </ItemGridAdapter>
                  </Row>
                </Column>
              </Row>
            </Column>
          </View>
        );
      },
      [renderItem],
    );

    const renderGridTemplate = useCallback(
      (actions: Action[]) => {
        const specialItems = actions.slice(0, SPECIAL_ITEMS_COUNT);
        if (isSpecialItems(specialItems)) {
          const restItems = actions.slice(SPECIAL_ITEMS_COUNT);
          return (
            <>
              {renderSpecialGrid(specialItems)}
              {renderPureList(restItems)}
            </>
          );
        }
        return renderPureList(actions);
      },
      [renderPureList, renderSpecialGrid],
    );

    const renderList = useCallback(() => {
      if (getIsLarge()) {
        return renderGridTemplate(actionList);
      }
      return renderPureList(actionList);
    }, [actionList, getIsLarge, renderGridTemplate, renderPureList]);

    const modalWidth =
      actionList.length >= SPECIAL_ITEMS_COUNT
        ? ModalWidth.Medium
        : ModalWidth.Small;

    return (
      <AdaptiveModal
        ref={ref}
        adaptiveBreakpoint={BREAKPOINT}
        modalWidth={modalWidth}
        onModalClosed={onModalClosed}
        modalContentStyle={styles.modal}
        enableContentAutoHeight>
        <Content>{renderList()}</Content>
      </AdaptiveModal>
    );
  }),
);

const Content = observer((props: ViewProps) => {
  const paddingBottom = useSafePaddingBottom(16);
  return <ContentView style={{paddingBottom}} {...props} />;
});

const isSpecialItems = (array: unknown): array is SpecialItems =>
  Array.isArray(array) && array.length === SPECIAL_ITEMS_COUNT;

type ColumnProps = PropsWithChildren<{flex?: number}>;
const Column = ({flex = 1, children}: ColumnProps) => {
  return <View style={{flex, flexDirection: 'column'}}>{children}</View>;
};

type RowProps = PropsWithChildren<{flex?: number}>;
const Row = ({flex = 1, children}: RowProps) => {
  return <View style={{flex, flexDirection: 'row'}}>{children}</View>;
};

const styles = StyleSheet.create({
  actionIcon: {
    marginRight: 12,
    width: 18,
    height: 18,
  },
  modal: {
    borderRadius: 12,
    paddingTop: 36,
  },
});

const ContentView = variance(View)(() => ({
  root: {
    padding: 16,
    borderRadius: 12,
  },
}));

const ItemGridAdapter = variance(View)(() => ({
  root: {
    flex: 1,
    padding: 4,
  },
}));

const PureItemAdapter = variance(View)(() => ({
  root: {
    padding: 4,
  },
}));
