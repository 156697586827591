import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {DashboardSettingsScreen} from '../../DashboardSettingsScreen';
import {fromSecond, Second, toSecond} from '../../Time';
import {Mode} from '../../DashboardMode';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';

export type DashboardSettingsBindingProps =
  RootStackBindingProps<'DashboardSettings'> & {};

export default observer(function DashboardSettingsBinding({
  navigation,
}: DashboardSettingsBindingProps) {
  const {dashboardModeService, workerStateRegistry} = useRoot();
  const getPeriod = useCallback(
    () => Math.floor(toSecond(workerStateRegistry.period)),
    [workerStateRegistry],
  );
  const onSlidingComplete = useCallback(
    (period: number) =>
      workerStateRegistry.setPeriod(fromSecond(period as Second)),
    [workerStateRegistry],
  );
  const onModeChange = useCallback(
    (isSimple: boolean) => {
      dashboardModeService.changeMode(isSimple ? Mode.Simple : Mode.Regular);
    },
    [dashboardModeService],
  );
  const goToPlan = useCallback(() => navigation.navigate('Plan'), [navigation]);
  return (
    <DashboardSettingsScreen
      getPeriod={getPeriod}
      onSlidingComplete={onSlidingComplete}
      onModeChange={onModeChange}
      mode={dashboardModeService.mode}
      goToPlan={goToPlan}
    />
  );
});
