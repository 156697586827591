import {useRoot, useStrings} from '../Root/hooks';
import {useCallback} from 'react';
import {useErrorHandler} from '../ExceptionHandler';

export default function useCopyInstallMinerUrl() {
  const {
    connectedClient,
    flashMessages,
    configuration: {values},
    clipboard,
  } = useRoot();
  const errorHandler = useErrorHandler();
  const strings = useStrings();
  const copyLink = useCallback(async () => {
    const result = await connectedClient.call('new_worker');
    if (!result.success) {
      errorHandler.handle(result.left);
      return;
    }
    const {worker_connection_id} = result.right;
    clipboard.setString(
      values.downloadFarmMinerUrl.replace('{id}', worker_connection_id),
    );
    flashMessages.showMessage({
      title: strings['action.theValueHasBeenCopiedToClipboard'],
      variant: 'success',
    });
  }, [
    connectedClient,
    clipboard,
    values,
    flashMessages,
    strings,
    errorHandler,
  ]);
  return {copyLink};
}
