import {CryptoResponse, IapResponse} from './PurchaseRequester';

export enum PaymentType {
  ExternalOrder,
  InAppCompleted,
}

export type ContextPurchaseResponse =
  | ({
      kind: PaymentType.ExternalOrder;
    } & CryptoResponse)
  | ({
      kind: PaymentType.InAppCompleted;
    } & IapResponse);
