import {Client, Server} from '../JsonRpc';
import {
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {Millisecond} from '../Time';

export default (
  server: Server<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >,
  realClient: Client<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >,
) => {
  const apply = (
    method: keyof (CryptoFarmServerCalls & CryptoFarmConnectedServerCalls),
  ) =>
    server.call(method, async (params, response, next, id) => {
      return response.respond(
        (await realClient.apply(method as any, params, {
          timeout: Infinity as Millisecond,
          id,
        })) as any,
      );
    });
  return batchDisposers(
    apply('get_ads'),
    apply('get_push_ads'),
    apply('fcm_registration'),
    apply('register'),
    apply('delete_farm'),
    apply('auth'),
    apply('set_utm'),
    apply('link_worker'),
    apply('get_worker_by_link_key'),
    apply('get_tariffs'),
    apply('get_products'),
    apply('create_order'),
    apply('get_order'),
    apply('verify_promo_code'),
    apply('find_promo_codes'),
    apply('verify_receipt'),
    apply('appstore_purchases'),
    apply('purchase_meta_create'),
    apply('google_play_purchases'),
    apply('get_current_rate'),
    apply('get_btc_usd_rate'),
    apply('oauth'),
    apply('auth_by_google_play_purchases'),
    apply('auth_by_app_store_purchases'),
    apply('set_lang'),
    apply('get_demo_config'),
    apply('connect'),
    apply('withdraw'),
    apply('get_withdrawals'),
    apply('get_withdrawal'),
    apply('get_farm_token'),
    apply('event'),
    apply('rating'),
    apply('raise_error'),
    apply('new_worker'),
    apply('get_gift'),
  );
};
