import React from 'react';
import {observer} from 'mobx-react-lite';
import MinerTabView from './MinerTabView';
import PurchaseScreenContext from './context';
import {useForcedContext} from '../context';
import {useRoot} from '../Root/hooks';

export default observer(function MinerTabBinding() {
  const {
    isLoading,
    goToAccessRecovery,
    state,
    fetchOffersError,
    goToConfiguration,
    selectedConfig,
  } = useForcedContext(PurchaseScreenContext);
  const {gift} = useRoot();

  return (
    <MinerTabView
      isLoading={isLoading}
      proposal={state.selectedDoubleProposal}
      goToAccessRecovery={goToAccessRecovery}
      fetchOffersError={fetchOffersError}
      goToConfiguration={goToConfiguration}
      minerConfigs={state.minerConfigs}
      selectedMinerConfig={selectedConfig}
      selectHashrate={state.selectHashrate}
      gift={gift.poolGift}
      isGiftLoading={gift.isLoading}
    />
  );
});
