import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, Text, View} from 'react-native';
import {sized} from '../Svg';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {useStyles, variance} from '../styling';
import {useStrings} from '../Root/hooks';
import SignInSvg from '../assets/svg/colorless/signIn.svg';
import useOpenStoreAuth from '../PurchaseScreen/hooks/useOpenStoreAuth';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {getCommissionerFontStyles} from '../components';

export const PurchaseNotAvailable = observer(() => {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    body: {
      paddingVertical: 30,
      paddingHorizontal: 15,
      alignItems: 'center',
    },
    footer: {
      padding: 15,
    },
    text: {
      ...getCommissionerFontStyles('400'),
      color: theme.colors.uiSecondary,
      fontSize: 14,
      lineHeight: 22,
      textAlign: 'center',
    },
    icon: {
      marginBottom: 20,
    },
  }));
  const description =
    Platform.OS === 'ios'
      ? strings['subscription.purchaseIsNotAvailable.description.ios']
      : strings['subscription.purchaseIsNotAvailable.description.android'];
  const openStore = useOpenStoreAuth();
  return (
    <RootView>
      <View style={styles.body}>
        <WarningIcon style={styles.icon} />
        <Text style={styles.text}>{description}</Text>
      </View>
      <View style={styles.footer}>
        <AppButton
          Icon={SignInIcon}
          variant={ButtonVariant.Primary}
          onPress={openStore}>
          {strings['subscription.purchaseIsNotAvailable.button']}
        </AppButton>
      </View>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderRadius: 24,
  },
}));

const WarningIcon = sized(WarningSvg, 60);
const SignInIcon = sized(SignInSvg, 18);
