import React from 'react';
import {TouchableOpacityProps, View} from 'react-native';

import {useTheme, variance} from '../styling';
import {Paragraph, PressableOpacity} from '../components';
import LoaderIndicator from '../components/LoaderIndicator';
import {SvgProps} from 'react-native-svg';

export type PrimaryMenuItemProps = TouchableOpacityProps & {
  Icon: React.ComponentType<SvgProps>;
  text: string;
  loading?: boolean;
  disabled?: boolean;
};

export const PrimaryMenuItem = ({
  text,
  loading,
  disabled,
  Icon,
  ...rest
}: PrimaryMenuItemProps) => {
  const theme = useTheme();
  return (
    <PressableOpacity style={{width: '33.3%'}} {...rest} disabled={disabled}>
      <Item disabled={disabled}>
        <IconContainer>
          <Icon color={theme.colors.uiSecondary} />
        </IconContainer>
        <ItemText type="paragraph" size="large" weight="500">
          {text}
        </ItemText>
        {loading && (
          <LoaderView>
            <LoaderIndicator />
          </LoaderView>
        )}
      </Item>
    </PressableOpacity>
  );
};

const Item = variance(View)((theme) => ({
  root: {
    height: 100,
    paddingHorizontal: 14,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 1.5,
    backgroundColor: theme.colors.uiMain,
    ...theme.mediaQuery({
      [768]: {
        margin: 9,
        borderRadius: 12,
      },
    }),
  },
  disabled: {
    opacity: theme.select(0.4, 0.6),
  },
}));

const IconContainer = variance(View)(() => ({
  root: {
    height: 30,
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 7,
  },
}));

const LoaderView = variance(View)(() => ({
  root: {
    marginLeft: 5,
  },
}));

const ItemText = variance(Paragraph)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    textAlign: 'center',
    flexDirection: 'row',
  },
}));
