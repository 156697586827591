import {observer} from 'mobx-react-lite';
import React from 'react';
import {sized} from '../Svg';
import {useTheme, variance} from '../styling';
import {Header} from '../components';
import {StyleSheet, View} from 'react-native';
import MinerSvg from '../assets/svg/grayish/miner.svg';
import MinerDarkSvg from '../assets/svg/grayish/minerDark.svg';
import {useRoot, useStrings} from '../Root/hooks';
import {AppButton, ButtonVariant} from '../components/AppButton';
import QuadFlash from '../assets/svg/colorless/quadFlash.svg';
import PlusCircle from '../assets/svg/circled/circlePlus.svg';
import {ApiMode} from '../farmApi';

export type EmptyWorkerListDesktopProps = {
  goToAddDemoMiner: () => void;
  goToPlan: () => void;
  goToAddMiner: () => void;
};

export default observer(function EmptyWorkerListDesktop({
  goToAddDemoMiner,
  goToAddMiner,
  goToPlan,
}: EmptyWorkerListDesktopProps) {
  const theme = useTheme();
  const {apiStore} = useRoot();
  const isReal = apiStore.mode === ApiMode.Real;
  const Icon = theme.select(MinerIcon, MinerDarkIcon);
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <View style={styles.info}>
        <Icon />
        <TitleText type="header" size="h3" weight="500">
          {strings['dashboard.emptyList.title']}
        </TitleText>
        <View style={styles.btns}>
          {isReal ? (
            <AppButton
              Icon={PlusCircle}
              style={styles.button}
              onPress={goToAddMiner}>
              {strings['dashboard.emptyList.addMinerButton']}
            </AppButton>
          ) : (
            <AppButton
              Icon={PlusCircle}
              style={styles.button}
              onPress={goToAddDemoMiner}>
              {strings['dashboard.emptyList.addDemoMinerButton']}
            </AppButton>
          )}
          <View style={styles.offset} />
          <AppButton
            style={styles.button}
            Icon={QuadFlash}
            onPress={goToPlan}
            variant={ButtonVariant.Primary}>
            {strings['dashboard.emptyList.goToTariffsButton']}
          </AppButton>
        </View>
      </View>
    </View>
  );
});

const MinerIcon = sized(MinerSvg, 81);
const MinerDarkIcon = sized(MinerDarkSvg, 81);

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
  },
  bannerList: {
    height: 160,
  },
  btns: {
    flexDirection: 'row',
    maxWidth: 668,
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    justifyContent: 'center',
  },
  offset: {
    width: 12,
    flexShrink: 0,
  },
  button: {
    width: '100%',
    flexShrink: 1,
  },
  banner: {
    flex: 1,
  },
  farmInfoContainer: {
    paddingBottom: 18,
  },
  info: {
    paddingHorizontal: 20,
    paddingVertical: 30,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const TitleText = variance(Header)(() => ({
  root: {
    marginTop: 12,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 22,
    marginBottom: 42,
  },
}));
