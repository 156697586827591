import React, {PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Directions,
  Gesture,
  GestureDetector,
} from 'react-native-gesture-handler';
import {runOnJS} from 'react-native-reanimated';
import {View} from 'react-native';

export type FlashMessageAnimatedProps = {
  onGestureEnd: () => void;
};

export default observer(function FlashMessageAnimated(
  props: PropsWithChildren<FlashMessageAnimatedProps>,
) {
  const {onGestureEnd} = props;
  const flingGesture = Gesture.Fling()
    .direction(Directions.UP)
    .onEnd((_, success) => {
      if (success) {
        runOnJS(onGestureEnd);
      }
    });
  return (
    <GestureDetector gesture={flingGesture}>
      <View>{props.children}</View>
    </GestureDetector>
  );
});

export const EXITING_DURATION = 500;
