import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import DisconnectSvg from '../assets/svg/colorless/disconnect.svg';
import {createStylesHook} from '../styling';
import {Typography} from '../components';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export interface ErrorPanelProps {
  title?: string;
  code?: number;
  description?: string;
  footer?: React.ReactNode;
  visibleIcon?: boolean;
}

export default observer(
  ({title, code, description, footer, visibleIcon = true}: ErrorPanelProps) => {
    const styles = useStyles();
    return (
      <View style={styles.root}>
        <View style={styles.content}>
          <View style={styles.body}>
            {visibleIcon && <DisconnectSvg style={styles.icon} />}
            {title !== undefined && (
              <Typography size="h2" type="header" style={styles.title}>
                {title}
              </Typography>
            )}
            {(code !== undefined || description !== undefined) && (
              <Typography size="large" type="paragraph" style={styles.bodyText}>
                {code !== undefined && (
                  <Typography
                    size="large"
                    type="paragraph"
                    style={styles.bodyErrorText}>
                    {code}
                  </Typography>
                )}
                {code !== undefined && description !== undefined && ' '}
                {description}
              </Typography>
            )}
          </View>
          <View style={styles.footer}>{footer}</View>
        </View>
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
  },
  content: {
    flex: 1,
    borderRadius: 4,
    backgroundColor: theme.colors.backgroundGround,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        flex: undefined,
        width: 480,
        maxWidth: '100%',
        borderRadius: 12,
        borderWidth: 1,
        borderColor: theme.colors.uiAdditional1,
        backgroundColor: theme.colors.uiMain,
        overflow: 'hidden',
      },
    }),
  },
  body: {
    marginTop: 'auto',
    backgroundColor: theme.colors.backgroundGround,
    alignItems: 'center',
    paddingVertical: 40,
    paddingHorizontal: 30,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        backgroundColor: theme.colors.uiMain,
      },
    }),
  },
  icon: {
    marginBottom: 20,
    color: theme.colors.uiSecondary,
  },
  title: {
    textAlign: 'center',
    marginBottom: 20,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        fontSize: 32,
        lineHeight: 40,
      },
    }),
  },
  bodyText: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 17,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        fontSize: 16,
        lineHeight: 26,
      },
    }),
  },
  bodyErrorText: {
    color: theme.colors.primaryError,
  },
  footer: {
    marginTop: 'auto',
    padding: 16,
  },
}));
