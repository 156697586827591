import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionModal} from '../components/organisms';
import TrashSvg from '../assets/svg/twoTone/trash.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {TwoToneIcon} from '../components/atoms';
import {SvgProps} from 'react-native-svg';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings, useTemplates} from '../Root/hooks';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import getWorkerName from '../DashboardScreen/getters/getWorkerName';
import {sized} from '../Svg';

export type DeleteWorkerGroupConfirmModalProps = {
  onModalClosed: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  worker: ReadonlyDeep<WorkerEntity> | undefined;
};

export default observer<DeleteWorkerGroupConfirmModalProps, AdaptiveModalRef>(
  forwardRef(function DeleteWorkerConfirmModal(
    {onConfirm, onCancel, onModalClosed, worker},
    ref,
  ) {
    const strings = useStrings();
    const templates = useTemplates();
    const description = `${templates['modal.removeWorker.descriptionWithName']({
      name: worker ? getWorkerName(worker) : '—',
    })}`;
    return (
      <AdaptiveActionModal
        ref={ref}
        onModalClosed={onModalClosed}
        IconSvg={TrashIcon}
        IconSvgModalRep={TrashIconLarge}
        title={strings['modal.removeWorker.title']}
        description={description}
        actions={[
          <AppButton onPress={onCancel}>{strings['action.cancel']}</AppButton>,
          <AppButton
            onPress={worker ? onConfirm : onCancel}
            variant={ButtonVariant.Primary}>
            {strings['action.delete']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const TrashIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(TrashSvg, 22, 28)} {...props} />
));

const TrashIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(TrashSvg, 33, 42)} {...props} />
));
