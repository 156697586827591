import {observer} from 'mobx-react-lite';
import Svg, {Defs, LinearGradient, Path, Stop} from 'react-native-svg';
import React, {useRef} from 'react';
import {nanoid} from 'nanoid';
import {IconProps} from './IconProps';
import useColors from './useColors';

export default observer(function StatisticsIcon({active, ...rest}: IconProps) {
  const id = useRef(nanoid());
  const {accent, second} = useColors(`url(#${id.current})`, active);
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <Path
        d="M0.5 10.7222C0.5 5.90342 0.5 3.49402 1.99701 1.99701C3.49402 0.5 5.90342 0.5 10.7222 0.5H13.2778C18.0966 0.5 20.506 0.5 22.003 1.99701C23.5 3.49402 23.5 5.90342 23.5 10.7222V13.2778C23.5 18.0966 23.5 20.506 22.003 22.003C20.506 23.5 18.0966 23.5 13.2778 23.5H10.7222C5.90342 23.5 3.49402 23.5 1.99701 22.003C0.5 20.506 0.5 18.0966 0.5 13.2778V10.7222Z"
        fill={accent}
      />
      <Path
        d="M19.5139 8.77778C19.5139 9.48347 18.9418 10.0556 18.2361 10.0556C17.5304 10.0556 16.9583 9.48347 16.9583 8.77778C16.9583 8.07208 17.5304 7.5 18.2361 7.5C18.9418 7.5 19.5139 8.07208 19.5139 8.77778Z"
        fill={second}
      />
      <Path
        d="M17.864 10.6583C17.1882 10.5254 16.6399 10.0372 16.4215 9.39635L13.6587 12.5538L11.0232 10.3575C10.3571 9.80238 9.36967 9.88016 8.79865 10.5327L4.73713 15.1745C4.3886 15.5728 4.42896 16.1782 4.82728 16.5268C5.2256 16.8753 5.83104 16.8349 6.17957 16.4366L10.0357 12.0296L12.6713 14.2258C13.3374 14.781 14.3248 14.7032 14.8958 14.0506L17.864 10.6583Z"
        fill={second}
      />
      <Defs>
        <LinearGradient
          id={id.current}
          x1="-23.155"
          y1="9.3298"
          x2="8.44085"
          y2="39.1478"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F7C61A" />
          <Stop offset="1" stopColor="#F7931A" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
});
