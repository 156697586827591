import {CurrentSubscriptionState} from './CurrentSubscriptionState';
import {computed, makeObservable} from 'mobx';
import {ApiMode} from '../farmApi';
import {ApiStore} from '../ApiStore';
import {Auth, AuthStatus} from '../Auth';
import {Platform} from 'react-native';

export default class CurrentSubscriptionStateImpl
  implements CurrentSubscriptionState
{
  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly auth: Auth;
    },
  ) {
    makeObservable(this);
  }

  get isLoading() {
    return this._root.auth.status !== AuthStatus.Idle;
  }

  @computed get subscription() {
    if (this._root.apiStore.mode === ApiMode.Real) {
      const {state} = this._root.auth;
      return state && state.kind === 'Connected'
        ? state.subscriptionMap.get(state.accountId)
        : undefined;
    }
    return this._root.apiStore.getDemoSubscription();
  }

  @computed get isPlatformSupported() {
    switch (Platform.OS) {
      case 'android':
        return this.subscription?.android;
      case 'ios':
        return this.subscription?.ios;
      case 'web':
        return this.subscription?.web;
      default:
        return false;
    }
  }
}
