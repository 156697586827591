import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {Header as TypoHeader, Body as TypoBody} from '../components';
import {variance} from '../styling';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {useStrings} from '../Root/hooks';
import {CommonError} from '../ApiStore';
import {DefaultError} from '../JsonRpc';

export type ErrorLinkProps = {
  onConfirmPress: () => void;
  onSupportPress: () => void;
  error: CommonError | DefaultError | undefined;
};

export default observer(function ErrorLink({
  onConfirmPress,
  onSupportPress,
  error,
}: ErrorLinkProps) {
  const strings = useStrings();
  const base = error ? `Code: ${error.code}` : strings['error.occurred'];
  let description;
  switch (error?.code) {
    case 2002:
      description = `${strings['modal.linkErrorModal.notFound']} ${base}`;
      break;
    case 2004:
      description = `${strings['modal.linkErrorModal.noSlots']} ${base}`;
      break;
    case 1000:
      description = `${strings['modal.linkErrorModal.unexpected']} ${base}`;
      break;
    default:
      description = base;
  }
  return (
    <View style={styles.root}>
      <TitleText type="header" size="h3">
        {description}
      </TitleText>
      <TypoText type="body" weight="500">
        {strings['modal.linkErrorModal.title']}
      </TypoText>
      <View style={styles.footer}>
        <SupportView>
          <AppButton onPress={onSupportPress}>
            {strings['action.support']}
          </AppButton>
        </SupportView>
        <ConfirmView>
          <AppButton onPress={onConfirmPress} variant={ButtonVariant.Primary}>
            {strings['action.done']}
          </AppButton>
        </ConfirmView>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    paddingTop: 32,
    paddingHorizontal: 16,
  },
  footer: {
    marginTop: 18,
    flexDirection: 'row',
  },
});

const TitleText = variance(TypoHeader)(() => ({
  root: {
    textAlign: 'center',
    marginBottom: 8,
  },
}));

const TypoText = variance(TypoBody)((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.colors.primaryUIDirtyBlue,
  },
}));

const SupportView = variance(View)(() => ({
  root: {
    marginRight: 6,
    flex: 1,
  },
}));

const ConfirmView = variance(View)(() => ({
  root: {
    marginLeft: 6,
    flex: 1,
  },
}));
