import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionModal} from '../components/organisms';
import TrashSvg from '../assets/svg/twoTone/trash.svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {TwoToneIcon} from '../components/atoms';
import {SvgProps} from 'react-native-svg';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings, useTemplates} from '../Root/hooks';
import {ReadonlyDeep} from 'type-fest';
import {WorkerGroupEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';

export type DeleteWorkerGroupConfirmModalProps = {
  onModalClosed: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  group: ReadonlyDeep<WorkerGroupEntity> | undefined;
};

export default observer<DeleteWorkerGroupConfirmModalProps, AdaptiveModalRef>(
  forwardRef(function DeleteWorkerGroupConfirmModal(
    {onConfirm, onCancel, onModalClosed, group},
    ref,
  ) {
    const strings = useStrings();
    const templates = useTemplates();
    const description = templates['modal.removeWorkerGroup.description']({
      name: group?.name || '—',
    });
    return (
      <AdaptiveActionModal
        ref={ref}
        onModalClosed={onModalClosed}
        IconSvg={TrashIcon}
        title={strings['modal.removeWorkerGroup.title']}
        description={description}
        actions={[
          <AppButton onPress={onCancel}>{strings['action.cancel']}</AppButton>,
          <AppButton
            onPress={group ? onConfirm : onCancel}
            variant={ButtonVariant.Primary}>
            {strings['action.delete']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const TrashIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={TrashSvg} {...props} />
));
