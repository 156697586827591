import React, {ForwardedRef, forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {RefreshControl, RefreshControlProps} from 'react-native';

import {useTheme} from '../styling';

export default observer(
  forwardRef(
    (props: RefreshControlProps, ref: ForwardedRef<RefreshControl>) => {
      const theme = useTheme();
      return (
        <RefreshControl
          ref={ref}
          tintColor={theme.colors.uiSecondary}
          {...props}
        />
      );
    },
  ),
);
