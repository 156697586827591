import React, {PropsWithChildren} from 'react';
import {View, StyleSheet} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useVisibleTabletTemplate} from '../../AppTemplateState';

export type AppTemplateProps = {
  renderAsideBar: () => React.ReactNode;
};

export default observer(function AppTemplate(
  props: PropsWithChildren<AppTemplateProps>,
) {
  const {children} = props;
  return (
    <View style={styles.root}>
      <View style={styles.main}>
        <AsideBar {...props} />
        <View style={styles.content}>
          <View style={styles.page}>{children}</View>
        </View>
      </View>
    </View>
  );
});

const AsideBar = observer(({renderAsideBar}: AppTemplateProps) => {
  const isVisible = useVisibleTabletTemplate();
  if (!isVisible) {
    return null;
  }
  return <View style={styles.aside}>{renderAsideBar()}</View>;
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  main: {
    flex: 1,
    flexDirection: 'row',
  },
  aside: {
    zIndex: 2, // !important
  },
  content: {
    flex: 1,
    zIndex: 1, // !important
  },
  header: {
    zIndex: 2,
  },
  page: {
    flex: 1,
  },
});
