import {
  ManagedProposalSubscription,
  SubscriptionCandidateKind,
} from '../ProposalsState';
import {MinerSubscriptionOffer, PaymentMethod} from '../InAppOffersService';
import {SubscriptionOffer} from './TotalPriceView';

export function processMinerSubscriptionProposal(
  candidate: ManagedProposalSubscription<MinerSubscriptionOffer> | undefined,
  method: PaymentMethod,
): SubscriptionOffer | undefined {
  if (!candidate) {
    return undefined;
  }
  if (candidate.kind === SubscriptionCandidateKind.Available) {
    const payment = candidate.offer.payments.get(method);
    if (!payment) {
      return undefined;
    }
    const {
      currency,
      uiPrice,
      discountsDifferencePrice,
      uiDiscountsDifferencePrice,
      discounts,
    } = payment;
    return {
      discountsDifferencePrice: discountsDifferencePrice,
      uiDiscountsDifferencePrice: uiDiscountsDifferencePrice,
      discounts: discounts ?? [],
      helperPrice: undefined,
      questionMarkVisible: false,
      kind: SubscriptionCandidateKind.Available,
      currency: currency,
      interval: candidate.offer.interval,
      uiPrice: uiPrice,
      trial: candidate.offer.trial,
    };
  }
  return {kind: candidate.kind, purchaseId: candidate.offer.purchaseId};
}
