import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';

import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {PriceChangedModal} from '../../PriceChangedModal';
import {useRoot} from '../../Root/hooks';

export default observer(function PriceChangedBinding() {
  const {inAppOffers} = useRoot();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const safelyGoBack = useSafelyGoBack();
  const handleSubmit = useCallback(async () => {
    inAppOffers.fetch(false);
    await modalRef.current?.close();
    safelyGoBack();
  }, [inAppOffers, safelyGoBack]);
  return (
    <PriceChangedModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onButtonPress={handleSubmit}
    />
  );
});
