import React, {useMemo} from 'react';
import {NoConnectionScreenProps} from './NoConnectionScreen';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import ErrorPanel from './ErrorPanel';
import RootView from './RootView';
import layout from './layout';
import RetryButton from './RetryButton';
import {AppButton} from '../components/AppButton';

export type ServiceUnavailableScreenProps = {
  onRequestSupport: () => void;
} & NoConnectionScreenProps;

export default observer(function ServiceUnavailableScreen(
  props: ServiceUnavailableScreenProps,
) {
  const {onRequestSupport, onRetry, onSwitchEnvironment} = props;
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <>
        {onSwitchEnvironment && (
          <AppButton onPress={onSwitchEnvironment} style={layout.buttonGutter}>
            Switch environment
          </AppButton>
        )}
        <RetryButton onPress={onRetry} style={layout.buttonGutter} />
        <AppButton onPress={onRequestSupport}>
          {strings['action.support']}
        </AppButton>
      </>
    ),
    [onSwitchEnvironment, onRetry, strings, onRequestSupport],
  );
  return (
    <RootView style={layout.root} contentContainerStyle={layout.container}>
      <ErrorPanel
        title={strings['error.serviceUnavailable.title']}
        description={strings['error.serviceUnavailable.description']}
        footer={footer}
      />
    </RootView>
  );
});
