import React, {useCallback} from 'react';
import {RootStackBindingProps} from './RootStackBindingProps';
import {observer} from 'mobx-react-lite';
import {nanoid} from 'nanoid';
import {useSafelyGoToAffiliate} from '../hooks';
import {createWithdrawalHistoryState} from './WithdrawalHistoryBinding';
import {PaymentSuccessScreen} from '../../PaymentSuccessScreen';
import {Bitcoin} from '../../ApiStore';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';

export type PaymentSuccessBindingProps =
  RootStackBindingProps<'PaymentSuccess'>;

export default observer(function PaymentSuccessBinding(
  props: PaymentSuccessBindingProps,
) {
  const {navigation, route} = props;
  const amount = route.params?.amount ?? (0 as Bitcoin);
  const goToAuthAndThenOpenAffiliate = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createWithdrawalHistoryState(nanoid()),
      }),
    [navigation],
  );
  const {safelyGoToAffiliate} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
    AffiliateVariant.AppAbout,
  );
  return (
    <PaymentSuccessScreen amount={amount} goToAffiliate={safelyGoToAffiliate} />
  );
});
