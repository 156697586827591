import {AppRouteName} from '../../../Navigation/AppRouteName';
import {StackElementKey} from '../../../InteractiveTutorial';

export const ROUTE_NAME_BY_KEY = new Map<AppRouteName, StackElementKey>([
  ['Statistics', 'TAB_BAR_STATISTICS'],
  ['MockAddWorker', 'TAB_BAR_ADD_WORKER'],
  ['Notifications', 'TAB_BAR_NOTIFICATIONS'],
]);

export const INACTIVE_BACKGROUND_COLOR = '#BEC0C8';
export const LIGHT_SECOND_COLOR = '#09183F';
export const DARK_SECOND_COLOR = '#393F50';
