import React, {useCallback, useEffect, useRef} from 'react';

import {Keyboard} from 'react-native';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionModal} from '../components/organisms';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {AppButton} from '../components/AppButton';
import {AdaptiveModalRef} from '../components/templates';

export type BasicErrorModalProps = {
  title: string | undefined;
  description: string | undefined;
  onClose: () => void;
};

export default observer((props: BasicErrorModalProps) => {
  const strings = useStrings();
  const {title = strings['modal.tryagain.title'], description, onClose} = props;
  const modalRef = useRef<AdaptiveModalRef>(null);

  useEffect(() => {
    Keyboard.dismiss();
  }, []);
  const onDismiss = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <AdaptiveActionModal
      ref={modalRef}
      onModalClosed={onDismiss}
      IconSvg={WarningSvg}
      title={title}
      description={description}
      actions={[
        <AppButton onPress={onDismiss}>{strings['action.ok']}</AppButton>,
      ]}
    />
  );
});
