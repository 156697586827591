import {observer} from 'mobx-react-lite';
import React, {forwardRef} from 'react';
import {View} from 'react-native';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  Representation,
} from '../components/templates';
import {useRoot} from '../Root/hooks';
import {INTRO_VIDEO} from './const';
import IntroVideo from './IntroVideo';

export type VideoModalProps = {
  closeVideoModal: () => void;
};

export default observer<VideoModalProps, AdaptiveModalRef>(
  forwardRef(function VideoModal(props, ref) {
    const {closeVideoModal} = props;
    const {windowDimensionsState} = useRoot();
    const contentWidth = windowDimensionsState.window.width - 20;

    return (
      <AdaptiveModal
        enableContentAutoHeight
        closeIconHidden
        modalContentStyle={{
          width: contentWidth,
          maxWidth: 600,
          backgroundColor: 'transparent',
        }}
        ref={ref}
        onModalClosed={closeVideoModal}
        representation={Representation.Modal}>
        <View>
          <IntroVideo uri={INTRO_VIDEO} width={contentWidth} />
        </View>
      </AdaptiveModal>
    );
  }),
);
