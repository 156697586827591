import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {PuzzleActivatorModal} from '../../PuzzleActivatorModal';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {PurchaseTabsKind} from '../../PurchaseScreen';
import {useErrorHandler} from '../../ExceptionHandler';
import {Platform} from 'react-native';

export type PoolPuzzleConfirmBindingProps =
  RootStackBindingProps<'PoolPuzzleConfirm'>;

export default observer(function PoolPuzzleConfirmBinding({
  navigation,
}: PoolPuzzleConfirmBindingProps) {
  const {poolMinerActivator, authFarmStateHelper} = useRoot();
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = useCallback(() => modalRef.current?.close(), []);
  const errorHandler = useErrorHandler();
  const onSuccess = useCallback(async () => {
    await close();
    const confirm_ = await poolMinerActivator.confirm();
    if (!confirm_.success) {
      errorHandler.handle(confirm_.left);
    }
    safelyGoBack();
  }, [close, errorHandler, poolMinerActivator, safelyGoBack]);
  const onModalClosed = useCallback(async () => {
    await close();
    poolMinerActivator.dismiss();
    safelyGoBack();
  }, [close, poolMinerActivator, safelyGoBack]);
  const onEnd = useCallback(async () => {
    await close();
    safelyGoBack();
  }, [close, safelyGoBack]);
  const onBannerPress = useCallback(async () => {
    await close();
    safelyGoBack();
    navigation.navigate('Plan', {initialTab: PurchaseTabsKind.Reactivate});
  }, [safelyGoBack, close, navigation]);
  const {poolMinersCount} = authFarmStateHelper;
  const visibleActivatorBanner =
    poolMinersCount && Platform.OS !== 'web' ? poolMinersCount > 0 : false;
  return (
    <PuzzleActivatorModal
      ref={modalRef}
      onModalClosed={onModalClosed}
      onSuccess={onSuccess}
      onEnd={onEnd}
      onBannerPress={onBannerPress}
      visibleActivatorBanner={visibleActivatorBanner}
    />
  );
});
