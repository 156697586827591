import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme} from '../styling';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';

export interface ContainerViewProps extends ViewProps {
  children?: React.ReactNode;
  contentStyle?: StyleProp<ViewStyle>;
  isLast?: boolean;
  vertical?: boolean;
}

export default observer((props: ContainerViewProps) => {
  const {contentStyle, children, style, isLast, vertical, ...rest} = props;
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        root: {
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: theme.colors.uiAdditional1,
          backgroundColor: theme.colors.uiMain,
          flexDirection: 'row',
          overflow: 'hidden',
          ...theme.mediaQuery({
            [MD_BREAKPOINT]: {
              borderBottomWidth: isLast ? 0 : 1,
            },
          }),
        },
        content: {
          paddingVertical: 10,
          paddingHorizontal: 15,
          flexGrow: 1,
          flexShrink: 1,
          ...theme.mediaQuery({
            [MD_BREAKPOINT]: {
              paddingVertical: 20,
              flexDirection: 'row',
              alignItems: 'center',
            },
          }),
        },
        vertical: {
          paddingVertical: 20,
          flexDirection: 'row',
          alignItems: 'center',
          flexGrow: 1,
          flexShrink: 1,
        },
      } as const),
    [theme, isLast],
  );
  return (
    <View style={[styles.root, style]} {...rest}>
      <View style={[styles.content, contentStyle, vertical && styles.vertical]}>
        {children}
      </View>
    </View>
  );
});
