import React from 'react';
import MainListItem from './extra/MainListItem';
import InsideListItem from './extra/InsideListItem';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {FarmLogVariant} from '../Notifications';
import {Divider} from '../components/atoms';
import {useTheme} from '../styling';
import {expr} from 'mobx-utils';
import {useGetDimensions} from '../WindowDimensions/useDimensions';
import {SectionProps} from './types';
import {View} from 'react-native';

export default observer(({state, onCheck}: SectionProps) => {
  const strings = useStrings();
  const theme = useTheme();
  const getDimension = useGetDimensions();
  const isLarge = expr(() => getDimension('lg'));
  return (
    <View>
      <MainListItem
        separated
        value={state[FarmLogVariant.Mining].value}
        onValueChange={(value) => onCheck(FarmLogVariant.Mining, value)}
        title={strings['notificationsSettings.mining']}
      />
      <InsideListItem
        dotColor={theme.colors.uiAdditional4}
        value={state[FarmLogVariant.MiningPaused].value}
        onValueChange={(value) => onCheck(FarmLogVariant.MiningPaused, value)}
        title={strings['notificationsSettings.miningPaused']}
      />
      <InsideListItem
        dotColor={theme.colors.primaryActive}
        value={state[FarmLogVariant.MiningRestarted].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.MiningRestarted, value)
        }
        title={strings['notificationsSettings.miningRestarted']}
      />
      <InsideListItem
        dotColor={theme.colors.primaryAttractive}
        value={state[FarmLogVariant.NewMiner].value}
        onValueChange={(value) => onCheck(FarmLogVariant.NewMiner, value)}
        title={strings['notificationsSettings.newMinerAdded']}
      />
      <InsideListItem
        dotColor={theme.colors.primarySuccess}
        value={state[FarmLogVariant.MinerOnline].value}
        onValueChange={(value) => onCheck(FarmLogVariant.MinerOnline, value)}
        title={strings['notificationsSettings.minerIsOnline']}
      />
      <InsideListItem
        dotColor={theme.colors.uiSecondary}
        value={state[FarmLogVariant.MinerOffline].value}
        onValueChange={(value) => onCheck(FarmLogVariant.MinerOffline, value)}
        title={strings['notificationsSettings.minerIsOffline']}
      />
      <InsideListItem
        dotColor={theme.colors.primaryError}
        value={state[FarmLogVariant.MinerOverheated].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.MinerOverheated, value)
        }
        title={strings['notificationsSettings.minerIsOverheated']}
      />
      <InsideListItem
        dotColor={theme.colors.primaryNew}
        value={state[FarmLogVariant.Confirmation].value}
        onValueChange={(value) => onCheck(FarmLogVariant.Confirmation, value)}
        title={strings['notificationsSettings.confirmation']}
      />
      {!isLarge && <Divider />}
    </View>
  );
});
