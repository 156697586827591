import {useCallback} from 'react';
import {OrderPaymentState} from './OrderPaymentState';

export function useCheckPayment(
  state: OrderPaymentState,
  goToThanksForPurchase: () => void,
  goToPaymentConfirmPending: () => void,
) {
  return useCallback(
    () =>
      state.checkIsCompleted().then((res) => {
        if (res) {
          goToThanksForPurchase();
        } else {
          goToPaymentConfirmPending();
        }
      }),
    [goToPaymentConfirmPending, goToThanksForPurchase, state],
  );
}
