import {NavigationState} from '@react-navigation/native';
import {AppRouteName} from '../Navigation/AppRouteName';

export default function getActiveRouteNameByNavigationState(
  state?: NavigationState,
): AppRouteName | undefined {
  if (
    !state?.routes ||
    state.routes.length === 0 ||
    state.index === undefined
  ) {
    return undefined;
  }
  const next = state.routes[state.index];
  if (!next.state) {
    return next.name as AppRouteName;
  }
  return getActiveRouteNameByNavigationState(next.state as NavigationState);
}
