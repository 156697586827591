import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useForcedContext} from '../context';
import {useRoot, useStrings} from '../Root/hooks';
import {OfferId, OfferPurchaseType} from '../InAppOffersService';
import {Proposal} from '../ProposalsState';
import RenewAccessScreenContext from './context';
import ProposalTableView, {
  ProposalItem,
} from '../PurchaseScreen/ProposalTableView';
import getMinerSlotPrice from '../PurchaseScreen/getMinerSlotPrice';
import {Interval} from '../ApiStore';
import {LocaleDict} from '../translation/LocaleStrings';
import {createStylesHook} from '../styling';

export default observer(function MinerProductTableBinding() {
  const {
    state: {
      selectedProposal,
      selectProposalByInterval,
      selectedPaymentMethod,
      proposalTable,
    },
  } = useForcedContext(RenewAccessScreenContext);

  const {inAppOffers, proposalsState} = useRoot();
  const strings = useStrings();
  const styles = useStyles();
  const items: TargetProposalItem[] = useMemo(() => {
    return proposalsState.uniqIntervalList.map((interval) => {
      const double = proposalTable?.get(interval);
      const proposal = double?.product || double?.subscription;
      const offer = proposal?.offer;
      const payment = offer?.payments.get(selectedPaymentMethod);

      const isAlreadySubscribed =
        proposal?.offer.purchaseType === OfferPurchaseType.MinerSubscription &&
        proposal.offer.bought;
      if (!offer || !proposal.available || isAlreadySubscribed || !payment) {
        return createUnavailableItem(interval, strings);
      }

      const {currency, uiPricePerMonthPerHashrate, discounts} = payment;
      const itemDiscounts = discounts?.map((d) => d.percent) ?? [];
      const offerHashrate = proposal.offer.poolMinerConfig.hash_rate;
      const offerInterval = proposal.offer.interval;
      const selected =
        offerHashrate === selectedProposal?.hashrate &&
        offerInterval === selectedProposal.interval;
      return createProposalItem({
        offerId: offer.offerId,
        available: true,
        duration: interval,
        helper: `${uiPricePerMonthPerHashrate} ${currency} ${strings['common.per']} 1000 H/s`,
        price: getMinerSlotPrice(payment, strings),
        disabled: checkIsBought(proposal),
        discounts: itemDiscounts,
        selected,
        hashrate: undefined,
      });
    });
  }, [
    proposalTable,
    selectedPaymentMethod,
    strings,
    selectedProposal,
    proposalsState.uniqIntervalList,
  ]);
  const handleItemPress = useCallback(
    (item: TargetProposalItem) => {
      if (item.available && item.offerId) {
        const candidate = inAppOffers.offerById.get(item.offerId);
        if (candidate) {
          selectProposalByInterval(candidate.interval);
        }
      }
    },
    [inAppOffers, selectProposalByInterval],
  );

  return (
    <ProposalTableView
      borderedItems
      onItemPress={handleItemPress}
      items={items}
      titleStyle={styles.title}
    />
  );
});
const useStyles = createStylesHook((theme) => ({
  title: {color: theme.colors.uiSecondary},
}));
function createUnavailableItem(
  interval: Interval,
  strings: LocaleDict,
): TargetProposalItem {
  return {
    available: false,
    price: strings['common.notAvailable'],
    duration: interval,
    helper: undefined,
    discounts: [],
    selected: false,
    disabled: true,
    hashrate: undefined,
  };
}

function createProposalItem(item: TargetProposalItem): TargetProposalItem {
  return {
    ...item,
    hashrate: undefined,
  };
}

const checkIsBought = (proposal?: Proposal) => {
  if (!proposal) {
    return false;
  }
  const {offer} = proposal;
  return (
    (offer.purchaseType === OfferPurchaseType.MinerSubscription ||
      offer.purchaseType === OfferPurchaseType.ActivatorSubscription) &&
    offer.bought
  );
};

type TargetProposalItem = ProposalItem & {offerId?: OfferId};
