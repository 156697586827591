import React, {forwardRef, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalRef} from '../components/templates';
import {DemoConfig} from '../../universal/features/api/entity/GetDemoConfigResponse';
import {useStrings} from '../Root/hooks';
import {SvgProps} from 'react-native-svg';
import {Divider, TwoToneIcon} from '../components/atoms';
import {sized} from '../Svg';
import GearSvg from '../assets/svg/twoTone/gear.svg';
import {AdaptiveFlatListPicker} from '../components/organisms';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {ListRenderItem, StyleSheet} from 'react-native';
import {variance} from '../styling';
import {PressableOpacity} from '../components';
import Typography from '../components/Typography';

export type LinkWorkerModalProps = {
  onSubmit: () => void;
  onModalClosed: () => void;
  items: DemoConfig[];
  selectedConfigIndex: number | undefined;
  onSelectConfig: (config: DemoConfig, index: number) => void;
  isDemo: boolean;
};

export default observer<LinkWorkerModalProps, AdaptiveModalRef>(
  forwardRef(function LinkWorkerModal(
    {onSubmit, onModalClosed, items, selectedConfigIndex, onSelectConfig},
    ref,
  ) {
    const strings = useStrings();
    const renderItem: ListRenderItem<DemoConfig> = useCallback(
      ({item, index}) => {
        const isSelected = selectedConfigIndex === index;
        return (
          <ItemRootView
            activeOpacity={0.8}
            onPress={() =>
              isSelected ? onSubmit() : onSelectConfig(item, index)
            }>
            <ItemText selected={isSelected} type="body" weight="500">
              {item.name}
            </ItemText>
          </ItemRootView>
        );
      },
      [onSelectConfig, onSubmit, selectedConfigIndex],
    );
    return (
      <AdaptiveFlatListPicker<DemoConfig>
        modalRef={ref}
        onModalClosed={onModalClosed}
        IconSvg={GearIcon}
        IconSvgModalRep={GearIconLarge}
        title={strings['modal.setUpWorkerDemo.title']}
        description={strings['modal.setUpWorkerDemo.description']}
        ItemSeparatorComponent={Divider}
        contentContainerStyle={styles.container}
        data={items}
        renderItem={renderItem}
        modalContentStyle={styles.modal}
        bottomSheetSnapPoints={['90%']}
        actions={[
          <AppButton
            disabled={selectedConfigIndex === undefined}
            onPress={onSubmit}
            variant={ButtonVariant.Primary}>
            {strings['worker.setUp']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const ItemRootView = variance(PressableOpacity)(() => ({
  root: {
    minHeight: 46,
    paddingVertical: 5,
    justifyContent: 'center',
  },
}));

const ItemText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
  },
  selected: {
    color: theme.colors.primaryAttractive,
  },
}));

const styles = StyleSheet.create({
  modeBadge: {
    position: 'absolute',
  },
  emptyRoot: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    flex: 1,
    maxHeight: 900,
  },
  container: {
    flexGrow: 1,
  },
});

const GearIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(GearSvg, 25)} {...props} />
));

const GearIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(GearSvg, 30)} {...props} />
));
