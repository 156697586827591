import {observer} from 'mobx-react-lite';
import React, {forwardRef} from 'react';
import {DeleteWorkerGroupConfirmModalProps} from './DeleteWorkerConfirmModal';
import {AdaptiveModalRef} from '../components/templates';
import {useRoot, useStrings, useTemplates} from '../Root/hooks';
import {AdaptiveActionModal} from '../components/organisms';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {sized} from '../Svg';
import FlashSvg from '../assets/svg/twoTone/flash.svg';
import TrashSvg from '../assets/svg/colorless/trash.svg';
import RenewSvg from '../assets/svg/colorless/renewIcon.svg';
import dayjs from 'dayjs';

export type DeleteRenewWorkerConfirmModalProps =
  DeleteWorkerGroupConfirmModalProps & {
    onRenew: () => void;
  };

export default observer<DeleteRenewWorkerConfirmModalProps, AdaptiveModalRef>(
  forwardRef(function DeleteWorkerConfirmModal(
    {onConfirm, onRenew, onCancel, onModalClosed, worker},
    ref,
  ) {
    const {
      translation: {localeTag},
    } = useRoot();
    const strings = useStrings();
    const templates = useTemplates();

    const endTimeFormatted =
      worker?.end_time &&
      dayjs(worker.end_time).locale(localeTag).format('DD MMM YYYY');

    const description = `${templates['modal.removeWorker.renew.description']({
      date: endTimeFormatted ?? '-',
    })}`;

    return (
      <AdaptiveActionModal
        ref={ref}
        onModalClosed={onModalClosed}
        IconSvg={FlashIcon}
        IconSvgModalRep={FlashIcon}
        title={strings['modal.removeWorker.renew.title']}
        description={description}
        actions={[
          <AppButton
            onPress={onRenew}
            variant={ButtonVariant.GreenGradient}
            Icon={RenewSvgIcon}>
            {strings['action.renew']}
          </AppButton>,
          <AppButton onPress={worker ? onConfirm : onCancel} Icon={TrashIcon}>
            {strings['deleteWorker.button']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const FlashIcon = sized(FlashSvg, 58, 58);
const RenewSvgIcon = sized(RenewSvg, 20, 20);
const TrashIcon = sized(TrashSvg, 18);
