import {useRoot} from '../Root/hooks';
import {useCallback} from 'react';
import {ApiMode} from '../farmApi';
import {useErrorHandler} from '../ExceptionHandler';

export type UseGetNewWorkerIdResult = {
  getNewWorkerId: () => Promise<
    {mode: ApiMode; workerConnectionId: string} | undefined
  >;
};

export default function useGetNewWorkerId(): UseGetNewWorkerIdResult {
  const errorHandler = useErrorHandler();
  const {connectedClient} = useRoot();
  const getNewWorkerId = useCallback(async () => {
    const result = await connectedClient.call('new_worker');
    if (!result.success) {
      errorHandler.handle(result.left);
      return;
    }
    const {worker_connection_id} = result.right;
    return {
      mode: ApiMode.Real,
      workerConnectionId: worker_connection_id,
    };
  }, [connectedClient, errorHandler]);
  return {getNewWorkerId};
}
