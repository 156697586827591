import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../Root/hooks';
import {useTheme} from '../styling';
import sleep from '../utils/sleep';
import {TouchableWithoutFeedback, View, StyleSheet} from 'react-native';
import {LoaderWrapper} from './LoaderWrapper';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {colored, sized} from '../Svg';
import StarSvg from '../assets/svg/colorless/roundedStar.svg';
import LikeSvg from '../assets/svg/colorless/like.svg';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import {AdaptiveModalHeader} from '../components/organisms/modal/AdaptiveModalHeader';

export type RateAppProps = {
  goToComment: (rating: number) => void;
  goToRateUs: (rating: number) => void;
  dismiss: () => void;
};

export default observer(function RateApp({
  goToComment,
  goToRateUs,
  dismiss,
}: RateAppProps) {
  const {rateApp} = useRoot();
  const strings = useStrings();
  const theme = useTheme();
  const [selectedStars, setSelectedStars] = useState<number>(0);
  const remindLater = () => {
    dismiss();
    // noinspection JSIgnoredPromiseFromCall
    rateApp.remindLater();
  };
  const skipReview = () => {
    dismiss();
    // noinspection JSIgnoredPromiseFromCall
    rateApp.skipReview();
  };
  const onSelectStar = async (stars: number) => {
    setSelectedStars(stars);
    await Promise.all([sleep(1000), rateApp.sendEvent(stars)]);
    if (stars < 4) {
      goToComment(stars);
    } else {
      goToRateUs(stars);
    }
  };
  const Like = colored(LikeIcon, theme.colors.uiSecondary);
  const visibleLoader = selectedStars > 0;
  const paddingBottom = useSafePaddingBottom(16);
  return (
    <View style={[styles.root, {paddingBottom}]}>
      <AdaptiveModalHeader
        iconViewStyle={styles.likeView}
        IconSvg={Like}
        title={strings['modal.rate.title']}
        description={strings['modal.rate.subTitle']}
      />
      <View>
        <View style={styles.starListContainerView}>
          <View style={styles.starListView}>
            {new Array(5).fill('').map((_, i) => (
              <View style={styles.starContainerView} key={i}>
                <TouchableWithoutFeedback
                  disabled={selectedStars !== 0}
                  onPress={() => onSelectStar(i + 1)}>
                  <View style={styles.starView}>
                    <StarIcon
                      color={
                        i < selectedStars
                          ? theme.colors.primaryAttractive
                          : theme.colors.uiAdditional1
                      }
                    />
                  </View>
                </TouchableWithoutFeedback>
              </View>
            ))}
          </View>
        </View>
        <LoaderWrapper loading={visibleLoader} style={styles.footerView}>
          <View>
            <AppButton
              variant={ButtonVariant.Primary}
              onPress={skipReview}
              style={styles.skipButton}>
              {strings['action.skip']}
            </AppButton>
            <AppButton onPress={remindLater}>
              {strings['action.remindMeLater']}
            </AppButton>
          </View>
        </LoaderWrapper>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
    paddingTop: 32,
  },
  likeView: {
    marginBottom: 12,
  },
  starListContainerView: {
    alignItems: 'center',
    marginBottom: 35,
  },
  starListView: {
    maxWidth: 330,
    height: 100,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginHorizontal: -7,
  },
  starContainerView: {
    marginHorizontal: 7,
  },
  starView: {
    padding: 5,
  },
  skipButton: {
    marginBottom: 15,
  },
  footerView: {
    position: 'relative',
  },
});

const StarIcon = sized(StarSvg, 45);
const LikeIcon = sized(LikeSvg, 27);
