import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, StyleProp, View, ViewStyle} from 'react-native';
import Typography from '../../Typography';
import {SvgProps} from 'react-native-svg';
import {Divider, TwoToneIcon} from '../../atoms';
import {sized} from '../../../Svg';
import SpeedometerSvg from '../../../assets/svg/twoTone/speedometer.svg';
import BtcSvg from '../../../assets/svg/twoTone/btc.svg';
import MoreSvg from '../../../assets/svg/colorless/more.svg';
import FarmSvg from '../../../assets/svg/colored/farm.svg';
import {useTheme, variance} from '../../../styling';
import PressableOpacity from '../../PressableOpacity';
import ChevronDownSvg from '../../../assets/svg/colorless/chevronDown.svg';
import ChevronUpSvg from '../../../assets/svg/colorless/chevronUp.svg';
import {expr} from 'mobx-utils';
import toFixedTrunc from '../../../utils/toFixedTrunc';

export type GroupHeaderProps = {
  groupName: string;
  onMenuPress: () => void;
  onCollapsePress: () => void;
  opened: boolean;
  mode: 'simple' | 'large';
  onlineWorkerCount: number;
  totalWorkerCount: number;
  btcValue: number;
  speedValue: number;
  style?: StyleProp<ViewStyle>;
  visibleOpenButton: boolean;
};

export default observer(function GroupHeader({
  groupName,
  onMenuPress,
  onCollapsePress,
  opened,
  mode,
  onlineWorkerCount,
  totalWorkerCount,
  btcValue,
  speedValue,
  style,
  visibleOpenButton,
}: GroupHeaderProps) {
  const theme = useTheme();
  const isLarge = expr(() => mode === 'large');
  const formattedBtc = expr(() => toFixedTrunc(btcValue, 8));
  const formattedSpeed = speedValue.toFixed(0);
  return (
    <RootView style={style}>
      <LeftView>
        <View>
          <MoreButtonContent>
            <MoreButton onPress={onMenuPress}>
              <MoreIcon color={theme.colors.uiSecondary} />
            </MoreButton>
          </MoreButtonContent>
        </View>
        <LeftContentView>
          <FarmIconView>
            <FarmIcon />
          </FarmIconView>
          <View>
            <NameText type="body" weight="500">
              {groupName}
            </NameText>
            <CommonSlotsText type="body" weight="500">
              <OnlineSlotsText type="body" weight="500">
                {onlineWorkerCount}
              </OnlineSlotsText>{' '}
              / {totalWorkerCount}
            </CommonSlotsText>
          </View>
        </LeftContentView>
      </LeftView>
      <RightView rightGutter={!visibleOpenButton}>
        <RightValuesView large={isLarge}>
          <SpeedRowView>
            <SpeedometerIconView>
              <SpeedometerIcon />
            </SpeedometerIconView>
            <SpeedometerCommonText type="body" weight="500">
              <SpeedometerText type="body" weight="500">
                {formattedSpeed}
              </SpeedometerText>{' '}
              H/s
            </SpeedometerCommonText>
          </SpeedRowView>
          {isLarge && <RightValuesDivider vertical />}
          <BtcRowView>
            {isLarge && (
              <BtcIconView>
                <BtcIcon />
              </BtcIconView>
            )}
            <BtcText type="body" weight="500">
              {formattedBtc} BTC
            </BtcText>
          </BtcRowView>
        </RightValuesView>
        {visibleOpenButton && (
          <OpenButton onPress={onCollapsePress}>
            {opened ? (
              <ChevronUpIcon color={theme.colors.uiSecondary} />
            ) : (
              <ChevronDownIcon color={theme.colors.primaryUIDirtyBlue} />
            )}
          </OpenButton>
        )}
      </RightView>
    </RootView>
  );
});
const ChevronUpIcon = sized(ChevronUpSvg, 12, 10);
const ChevronDownIcon = sized(ChevronDownSvg, 12, 10);
const FarmIcon = sized(FarmSvg, 18);
const MoreIcon = sized(MoreSvg, 6, 12);

const SpeedometerIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(SpeedometerSvg, 22, 17)} {...props} />
));

const BtcIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(BtcSvg, 23)} {...props} />
));

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    height: 52,
    paddingVertical: 5,
    paddingRight: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const LeftView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const LeftContentView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 30,
  },
}));

const FarmIconView = variance(View)(() => ({
  root: {
    marginHorizontal: 13,
  },
}));

const RightView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightGutter: {
    paddingRight: 12,
  },
}));

const RightValuesView = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  large: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const BtcRowView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const RightValuesDivider = variance(Divider)(() => ({
  root: {
    marginHorizontal: 12,
  },
}));

const SpeedRowView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const BtcIconView = variance(View)(() => ({
  root: {
    marginRight: 3.5,
  },
}));
const OpenButton = variance(PressableOpacity)(() => ({
  root: {
    padding: 10,
    marginLeft: 12,
    borderRadius: 8,
  },
}));

const SpeedometerIconView = variance(View)(() => ({
  root: {
    marginRight: 3.5,
  },
}));

const BtcText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 13,
    lineHeight: 16,
    letterSpacing: -0.2,
    marginLeft: 3,
  },
}));

const SpeedometerCommonText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 13,
    lineHeight: 16,
    letterSpacing: -0.2,
    minWidth: 78,
    textAlign: 'right',
  },
}));

const SpeedometerText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    marginLeft: 3,
  },
}));

const NameText = variance(Typography)((theme) => ({
  root: {
    fontSize: 13,
    lineHeight: 14,
    color: theme.colors.uiSecondary,
    marginBottom: 2,
  },
}));

const CommonSlotsText = variance(Typography)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 15,
    color: theme.colors.primaryUIDirtyBlue,
    letterSpacing: -0.2,
  },
}));

const OnlineSlotsText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
  },
}));

const MoreButton = variance(PressableOpacity)(() => ({
  root: {
    height: 42,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const MoreButtonContent = variance(View)((theme) => ({
  root: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: theme.select(
      theme.colors.uiMain,
      theme.colors.uiAdditional1,
    ),
    ...Platform.select({
      web: {
        boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.1)',
      },
      default: {
        shadowColor: theme.colors.uiSecondary,
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
      },
    }),
  },
}));
