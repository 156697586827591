import {utils} from '../Ethers';
import {GenerateParams, PaymentUrlGenerator} from './PaymentUrlGenerator';

export default class PaymentUrlGeneratorImpl implements PaymentUrlGenerator {
  generate({protocol, address, amount, decimals}: GenerateParams) {
    const urlParams = new URLSearchParams();
    if (protocol === 'ethereum') {
      const units = utils.parseUnits(amount.toString(), decimals);
      urlParams.set('value', units.toString());
    } else {
      urlParams.set('amount', amount);
    }
    if (protocol) {
      return `${protocol}:${address}?${urlParams.toString()}`;
    }
    return `${address}?${urlParams.toString()}`;
  }
}
