import React from 'react';
import {FlatListProps, StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {WithdrawalEntity} from '../ApiStore';
import Withdrawal from './Withdrawal';
import ListEmpty from './ListEmpty';
import ListHeader from './ListHeader';
import {PageRequestImpl} from '../PageRequest';
import {
  AdaptiveFlatList,
  Representation,
} from '../components/templates/AdaptiveModal';
import {expr} from 'mobx-utils';
import Footer from './Footer';
import {Divider} from '../components/atoms';
import {useWindowDimensions} from '../WindowDimensions';
import useRepresentation from '../components/templates/AdaptiveModal/useRepresentation';
import {CryptoLoader} from '../components/atoms/CryptoLoader';

export type WithdrawalHistoryContentViewProps = {
  request: PageRequestImpl<WithdrawalEntity>;
  goToAffiliate: () => void;
};

export default observer(function HistoryItemList(
  props: WithdrawalHistoryContentViewProps,
) {
  const {goToAffiliate, request} = props;
  const {height} = useWindowDimensions();
  const isFirstLoaded = expr(() => request.list !== undefined);
  const hasContent = expr(() => (request.list?.length ?? 0) > 0);
  const defaultHeight = expr(() => Math.max(height * 0.3, 400));
  const representation = useRepresentation();
  const bounces = expr(
    () => representation === Representation.BottomSheet && hasContent,
  );
  return (
    <View
      style={[{height: defaultHeight}, hasContent && {height: height * 0.8}]}>
      <ListHeader />
      {isFirstLoaded ? (
        <AdaptiveFlatList
          bounces={bounces}
          style={styles.grow}
          keyboardShouldPersistTaps="handled"
          ListEmptyComponent={ListEmpty}
          ItemSeparatorComponent={Divider}
          contentContainerStyle={[styles.container]}
          data={request.list}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          refreshing={request.isRefreshing}
          onRefresh={request.refresh}
          onEndReached={request.fetchNext}
          removeClippedSubviews
        />
      ) : (
        <View style={[styles.loader, styles.grow]}>
          <CryptoLoader />
        </View>
      )}
      <Footer goToAffiliate={goToAffiliate} bordered={hasContent} />
    </View>
  );
});

const styles = StyleSheet.create({
  grow: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
  },
  loader: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type ListProps = FlatListProps<WithdrawalEntity>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = (item) =>
  String(item.id);

const renderItem: ListProps['renderItem'] = ({item}) => (
  <Withdrawal
    id={item.account_id}
    amount={item.amount}
    status={item.status}
    createdAt={new Date(item.created_at).getTime()}
  />
);
