import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../styling';
import AppRoutes from './AppRoutes';
import ExpandButton from './ExpandButton';
import AppButton from './AppButton';
import BottomButtons from './BottomButtons';
import {AppRouteName} from '../../../Navigation/AppRouteName';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useRoot} from '../../../Root/hooks';
import {ScrollView, View, StyleSheet} from 'react-native';
import {AppForceParamList} from '../../../Navigation/AppForceParamList';
import {NavigationHelpers} from '@react-navigation/native';

export type AsideBarProps = {
  navigation: NavigationHelpers<AppForceParamList>;
  activeRouteName: AppRouteName | undefined;
};

export const ASIDE_BAR_WIDTH = 90;
export const ASIDE_BAR_EXPANDED_WIDTH = 235;

export default observer(function AsideBar({
  navigation,
  activeRouteName,
}: AsideBarProps) {
  const {
    appTemplateState: {expandedAsideBar, toggleAsideBar, expandedAllowed},
  } = useRoot();
  const onLogoPress = useCallback(
    () =>
      navigation.navigate('Root', {
        screen: 'Dashboard',
      }),
    [navigation],
  );
  const goToLogout = useCallback(
    () => navigation.navigate('LogoutConfirm'),
    [navigation],
  );
  const goToAuth = useCallback(() => navigation.navigate('Auth'), [navigation]);
  const dashboardDisabled = activeRouteName === 'Dashboard';
  const insets = useSafeAreaInsets();
  return (
    <View style={styles.root}>
      <ContentScrollView
        bounces={false}
        contentContainerStyle={styles.container}
        expandedRoot={expandedAsideBar}
        style={{paddingTop: insets.top, paddingBottom: insets.bottom}}>
        <AppButton
          isExpanded={expandedAsideBar}
          onPress={onLogoPress}
          disabled={dashboardDisabled}
        />
        <AppRoutes
          navigation={navigation}
          isExpanded={expandedAsideBar}
          focusedRoute={activeRouteName}
        />
        <BottomButtons
          isExpanded={expandedAsideBar}
          goToLogout={goToLogout}
          goToAuth={goToAuth}
        />
      </ContentScrollView>
      {expandedAllowed && (
        <ExpandButton isExpanded={expandedAsideBar} toggle={toggleAsideBar} />
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
  },
});

const ContentScrollView = variance(ScrollView)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    flex: 1,
    width: ASIDE_BAR_WIDTH,
  },
  expandedRoot: {
    width: ASIDE_BAR_EXPANDED_WIDTH,
  },
}));
