import {Theme} from '@react-navigation/native/lib/typescript/src/types';

import {useRoot} from '../../Root/hooks';
import {DefaultTheme} from '@react-navigation/native';
import {useTheme} from '../../styling';

export default (): Theme => {
  const theme = useTheme();
  const {
    appearance: {isDark},
  } = useRoot();
  const colors = theme.colors;
  return {
    ...DefaultTheme,
    dark: isDark,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.primaryNew,
      card: colors.backgroundGround,
      background: colors.backgroundGround,
      border: colors.backgroundGround,
      text: colors.uiSecondary,
    },
  };
};
