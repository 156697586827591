import React, {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import Typography from '../../Typography';
import {useStrings} from '../../../Root/hooks';
import {useStyles, useTheme, variance} from '../../../styling';
import Svg, {Defs, LinearGradient, Path, Stop} from 'react-native-svg';
import {nanoid} from 'nanoid';
import {useWorkerState} from '../../../WorkerStateRegistry';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {expr} from 'mobx-utils';
import {maxBy, minBy} from 'lodash';
import useWorkerStatuses from '../../../DashboardScreen/hooks/useWorkerStatuses';
import {AppButton, ButtonSize, ButtonVariant} from '../../AppButton';
import useGetIsLarge from '../../../DashboardScreen/hooks/useGetIsLarge';
import RenewSvg from '../../../assets/svg/colorless/renewIcon.svg';
import {sized} from '../../../Svg';

export type WorkerCpusInfoProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  renewButtonVisible: boolean;
  onRenewPress: () => void;
  onActivePress: () => void;
};

export default observer(function WorkerCpusInfo({
  worker,
  renewButtonVisible,
  onRenewPress,
  onActivePress,
}: WorkerCpusInfoProps) {
  const strings = useStrings();
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());

  const state = useWorkerState(worker.id);
  const {offline, needActivate, expired, inactive} = useWorkerStatuses(worker);
  const minCore = expr(() => minBy(state?.cpu_states, (_) => _.temperature));
  const maxCore = expr(() => maxBy(state?.cpu_states, (_) => _.temperature));
  const isGrey = expr(() => needActivate || expired || inactive);
  const content =
    expired && renewButtonVisible ? (
      <AppButton
        onPress={onRenewPress}
        style={!isLarge && styles.mdButton}
        Icon={RenewSvgIcon}
        variant={ButtonVariant.GreenGradient}>
        {strings['action.renew']}
      </AppButton>
    ) : needActivate ? (
      <AppButton
        size={ButtonSize.Small}
        onPress={onActivePress}
        style={!isLarge && styles.mdButton}
        variant={ButtonVariant.Primary}>
        {strings['action.active']}
      </AppButton>
    ) : (
      <>
        <TitleText type="body" weight="500" disabled={isGrey}>
          {strings['worker.coresTableTitle']}
        </TitleText>
        <TemperatureSection
          disabled={offline}
          first={minCore?.temperature}
          second={maxCore?.temperature}
          firstError={minCore?.hot || false}
          secondError={maxCore?.hot || false}
        />
      </>
    );
  return <View style={styles.root}>{content}</View>;
});
export const RenewSvgIcon = sized(RenewSvg, 20, 20);
type TemperatureSectionProps = {
  disabled: boolean;
  first: number | undefined;
  second: number | undefined;
  firstError: boolean;
  secondError: boolean;
};

const TemperatureSection = observer(
  ({
    disabled,
    first,
    second,
    firstError,
    secondError,
  }: TemperatureSectionProps) => {
    return (
      <TemperatureSectionView>
        <LabelText
          disabled={disabled}
          offsetRight
          error={firstError}
          type="body"
          weight="500">
          Min
        </LabelText>
        <TemperatureRange
          disabled={disabled}
          first={first}
          firstError={firstError}
          second={second}
          secondError={secondError}
        />
        <LabelText
          disabled={disabled}
          offsetLeft
          error={secondError}
          type="body"
          weight="500">
          Max
        </LabelText>
      </TemperatureSectionView>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  mdButton: {
    width: '100%',
  },
});

const TitleText = variance(Typography)((theme) => ({
  root: {
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: -0.2,
    marginRight: 14,
  },
  disabled: {
    color: theme.colors.uiAdditional4,
  },
}));

const TemperatureSectionView = variance(View)((theme) => ({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundGround,
    borderRadius: 8,
    padding: 6,
  },
}));

const LabelText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.uiSecondary,
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: -0.2,
    textTransform: 'uppercase',
  },
  error: {
    color: theme.colors.primaryError,
  },
  offsetLeft: {
    marginLeft: 4,
  },
  offsetRight: {
    marginRight: 4,
  },
  disabled: {
    color: theme.colors.uiAdditional4,
  },
}));

export type TemperatureRangeProps = {
  disabled: boolean;
  first: number | undefined;
  second: number | undefined;
  firstError: boolean;
  secondError: boolean;
};

const TemperatureRange = observer(
  ({
    disabled,
    first,
    firstError,
    second,
    secondError,
  }: TemperatureRangeProps) => {
    const styles_ = useStyles(() => ({
      root: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
      },
      transition: {
        flex: 1,
        marginHorizontal: -2,
      },
    }));
    return (
      <View style={styles_.root}>
        <View>
          <TemperatureValue
            disabled={disabled}
            value={first}
            error={firstError}
          />
        </View>
        <View style={styles_.transition}>
          <TemperatureTransitionSvg
            disabled={disabled}
            firstError={firstError}
            secondError={secondError}
          />
        </View>
        <View>
          <TemperatureValue
            disabled={disabled}
            value={second}
            error={secondError}
          />
        </View>
      </View>
    );
  },
);

type TemperatureTransitionSvgProps = {
  disabled: boolean;
  firstError: boolean;
  secondError: boolean;
};

const TemperatureTransitionSvg = observer(
  ({disabled, firstError, secondError}: TemperatureTransitionSvgProps) => {
    const id = useRef(nanoid());
    const theme = useTheme();
    const firstColor = disabled
      ? theme.colors.uiAdditional3
      : firstError && !disabled
      ? theme.colors.primaryError
      : theme.colors.uiAdditional3;
    const secondColor = disabled
      ? theme.colors.uiAdditional3
      : secondError
      ? theme.colors.primaryError
      : theme.colors.uiAdditional3;
    return (
      <Svg
        width="100%"
        height="2"
        viewBox="0 0 62 2"
        fill="none"
        preserveAspectRatio="none">
        <Path
          d="M0 1.99676e-05L62 0V2H0.00418724L0 1.99676e-05Z"
          fill={`url(#${id.current})`}
        />
        <Defs>
          <LinearGradient
            id={id.current}
            x1="61.9909"
            y1="2.00129"
            x2="6.37145e-06"
            y2="1.72063"
            gradientUnits="userSpaceOnUse">
            <Stop stopColor={secondColor} />
            <Stop offset="1" stopColor={firstColor} />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  },
);

type TemperatureValueProps = {
  disabled: boolean;
  value: number | undefined;
  error?: boolean;
};

const TemperatureValue = observer(
  ({disabled, value, error}: TemperatureValueProps) => {
    const styles_ = useStyles(() => ({
      root: {},
      content: {
        ...StyleSheet.absoluteFillObject,
        paddingHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center',
      },
      text: {
        marginLeft: 2,
        fontSize: 15,
        lineHeight: 18,
        letterSpacing: -0.4,
      },
    }));
    const theme = useTheme();
    const showedValue = disabled ? 0 : value?.toFixed(0) ?? '-';
    const textColor = disabled
      ? theme.colors.uiAdditional3
      : error
      ? theme.colors.primaryError
      : theme.colors.uiSecondary;
    const borderColor =
      error && !disabled
        ? theme.colors.primaryError
        : theme.colors.uiAdditional3;
    return (
      <View style={styles_.root}>
        <Svg width="60" height="22" viewBox="0 0 60 22" fill="none">
          <Path
            d="M59.883 10.6839L50.7667 0.265472C50.6102 0.0862363 50.3213 0 50.0084 0L9.99152 0C9.67862 0 9.38969 0.0862363 9.23324 0.265472L0.116958 10.696C-0.0390418 10.8752 -0.0390418 11.0897 0.116958 11.2689L9.23324 21.7108C9.38969 21.8901 9.67862 22 9.99152 22H50.0083C50.3212 22 50.6102 21.8871 50.7666 21.7078L59.8829 11.2642C60.039 11.085 60.039 10.8632 59.883 10.6839ZM49.5032 20.8421H10.4968L1.88617 11.0001L10.4968 1.1579H49.5031L58.1137 11.0001L49.5032 20.8421Z"
            fill={borderColor}
          />
        </Svg>
        <View style={styles_.content}>
          <Typography
            style={[styles_.text, {color: textColor}]}
            type="body"
            weight="500">
            {showedValue}°
          </Typography>
        </View>
      </View>
    );
  },
);
