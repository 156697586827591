import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';

import BtcSvg from '../../assets/svg/colored/btc.svg';
import WithdrawalCornerSvg from '../../assets/svg/colorless/withdraw-corner.svg';
import {useRoot} from '../../Root/hooks';
import {variance} from '../../styling';
import {Body, Mono} from '../Typography';
import {sized} from '../../Svg';
import {AppButton, ButtonSize, ButtonVariant} from '../AppButton';
import {ApiModeBadge} from '../Badge';
import {ApiMode} from '../../farmApi';
import {StackElementKey} from '../../InteractiveTutorial';
import useTutorialMeasure from '../../InteractiveTutorial/useTutorialMeasure';

export type BalanceInformationProps = {
  onWithdrawPress: () => void;
};

const TUTORIAL_KEY: StackElementKey = 'DASHBOARD_BALANCE';

export default observer(function BalanceInformation({
  onWithdrawPress,
}: BalanceInformationProps) {
  const {dashboardStore, apiStore} = useRoot();
  const [ref, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);
  return (
    <View onLayout={onLayout} key={getForceKey()} ref={ref}>
      <RootView>
        <BtcIcon />
        <ContentView>
          <BalanceText type="mono" weight="700">
            {dashboardStore.balance.btc}
          </BalanceText>
          <UsdBalanceText type="body" weight="500">
            ${dashboardStore.balance.approxUsd}
          </UsdBalanceText>
        </ContentView>
        {apiStore.mode === ApiMode.Real ? (
          <AppButton
            Icon={WithdrawalCornerSvg}
            onPress={onWithdrawPress}
            variant={ButtonVariant.Primary}
            size={ButtonSize.Small}
          />
        ) : (
          <ApiModeBadge />
        )}
      </RootView>
    </View>
  );
});

const BtcIcon = sized(BtcSvg, 32);

const RootView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: theme.colors.uiMain,
    height: 64,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    paddingHorizontal: 12,
    flex: 1,
  },
}));

const BalanceText = variance(Mono)((theme) => ({
  root: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: -0.5,
    color: theme.colors.uiSecondary,
  },
}));

const UsdBalanceText = variance(Body)((theme) => ({
  root: {
    fontSize: 13,
    lineHeight: 15,
    letterSpacing: -0.5,
    color: theme.colors.primaryUIDirtyBlue,
  },
}));
