import {Service} from '../structure';
import {Metamask} from './Metamask';
import SubMetamaskConnectorService from './SubMetamaskConnectorService';
import SubMetamaskPaymentImpl from './SubMetamaskPaymentImpl';

export default class SubMetamaskService implements Metamask, Service {
  readonly connector = new SubMetamaskConnectorService();
  readonly payment = new SubMetamaskPaymentImpl();

  subscribe() {}
}
