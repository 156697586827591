import {observer} from 'mobx-react-lite';
import React from 'react';
import ColoredBanner, {
  ShadowColor,
  DefaultButton,
  YELLOW2_BACKGROUND,
  ColoredBannerProps,
  FAN_ICON,
} from '../../molecules/ColoredBanner';
import {ViewProps} from 'react-native';
import DownloadSvg from '../../../assets/svg/colorless/download.svg';
import {sized} from '../../../Svg';
import {useStrings} from '../../../Root/hooks';
import Markdown from '../../molecules/Markdown';
import {useStyles} from '../../../styling';

export type DownloadMinerBannerProps = ViewProps &
  Partial<ColoredBannerProps> & {
    onDownloadButtonPress: () => void;
  };

export default observer(function DownloadMinerBanner({
  onDownloadButtonPress,
  ...rest
}: DownloadMinerBannerProps) {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    text: {
      color: theme.colors.primaryBlack,
      fontSize: 13,
      lineHeight: 17,
      letterSpacing: -0.2,
      opacity: 0.8,
      marginTop: 0,
      marginBottom: 0,
      maxWidth: 330,
      textAlign: 'left',
    },
  }));
  return (
    <ColoredBanner
      shadowColor={ShadowColor.Green}
      title={strings['banner.downloadMiner.title']}
      text={() => (
        <Markdown styles={{paragraph: styles.text}}>
          {strings['banner.downloadMiner.description']}
        </Markdown>
      )}
      descriptionVisible={(layout) => (layout ? layout.width > 365 : false)}
      icon={FAN_ICON}
      iconProps={{
        style: {
          transform: [{translateX: 10}],
        },
      }}
      background={YELLOW2_BACKGROUND}
      actions={[
        <DefaultButton
          Icon={DownloadIcon}
          onPress={onDownloadButtonPress}
          title={strings['banner.downloadMiner.downloadButton']}
        />,
      ]}
      {...rest}
    />
  );
});

const DownloadIcon = sized(DownloadSvg, 14, 10);
