import {observer} from 'mobx-react-lite';
import React from 'react';
import {PurchaseTabsKind} from './PurchaseScreenState';
import {View} from 'react-native';
import MinerTabBinding from './MinerTabBinding';
import ReactivateTabBinding from './ReactivateTabBinding';
import {useForcedContext} from '../context';
import PurchaseScreenContext from './context';

export default observer(function TabContentSwitcherBinding() {
  const {
    state: {selectedTab},
  } = useForcedContext(PurchaseScreenContext);
  return (
    <View>
      {selectedTab === PurchaseTabsKind.Worker ? (
        <MinerTabBinding />
      ) : selectedTab === PurchaseTabsKind.Reactivate ? (
        <ReactivateTabBinding />
      ) : null}
    </View>
  );
});
