import {Locale} from '../Preferences';

import DeFlag from '../assets/svg/flags/de.svg';
import EsFlag from '../assets/svg/flags/es.svg';
import FrFlag from '../assets/svg/flags/fr.svg';
import GbFlag from '../assets/svg/flags/gb.svg';
import ItFlag from '../assets/svg/flags/it.svg';
import BrFlag from '../assets/svg/flags/br.svg';
import RuFlag from '../assets/svg/flags/ru.svg';
import {useMemo} from 'react';

export default function useLanguage() {
  return useMemo(
    () => [
      {
        id: Locale.English,
        title: 'English',
        icon: GbFlag,
      },
      {
        id: Locale.French,
        title: 'French',
        icon: FrFlag,
      },
      {
        id: Locale.German,
        title: 'German',
        icon: DeFlag,
      },
      {
        id: Locale.Italian,
        title: 'Italian',
        icon: ItFlag,
      },
      {
        id: Locale.PortugueseBrazil,
        title: 'Portuguese (Brazil)',
        icon: BrFlag,
      },
      {
        id: Locale.Russian,
        title: 'Russian',
        icon: RuFlag,
      },
      {
        id: Locale.Spanish,
        title: 'Spanish',
        icon: EsFlag,
      },
    ],
    [],
  );
}
