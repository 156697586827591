import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {PaymentConfirmPendingModal} from '../../PaymentConfirmPendingModal';
import {useResetToDashboard, useSafelyGoBack} from '../hooks';
import {AdaptiveModalRef} from '../../components/templates';

export default observer(function PaymentConfirmPendingBinding() {
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const resetToDashboard = useResetToDashboard();

  const handleAction = useCallback(async () => {
    await modalRef.current?.close();
    resetToDashboard();
  }, [resetToDashboard]);

  return (
    <PaymentConfirmPendingModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onAction={handleAction}
    />
  );
});
