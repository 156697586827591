import {observer} from 'mobx-react-lite';
import React from 'react';
import {RatesPanel, RatesPanelState} from '../RatesPanel';
import {StatisticsPanel, StatisticsPanelState} from '../StatisticsPanel';
import RefreshControl from '../components/RefreshControl';
import {ScrollView, View} from 'react-native';
import {ChartViewScope} from '../GraphPanel';
import {Millisecond} from '../Time';
import StatisticsTab from './StatisticsTab';
import {createStylesHook} from '../styling';
import {MD_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {
  BalanceType,
  StatisticsTabKey,
} from '../Navigation/BottomTab/StatisticBinding';
import {WorkerGroupEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import BottomStatisticBannerContainer from './BottomStatisticBannerContainer';

export type StatisticsScreenProps = {
  activeTab: StatisticsTabKey;
  onTabChange: (t: StatisticsTabKey) => void;
  onRefresh: () => void;
  state: StatisticsPanelState;
  groupId?: number;
  onPick: (id?: string | number) => void;
  onFromChange: (from: Millisecond) => void;
  onScopeChange: (scope: ChartViewScope) => void;
  groups?: WorkerGroupEntity[];
  balance: BalanceType;
  btcInUsd: number | undefined;
  ratesState: RatesPanelState;
  goToPlan: () => void;
  goToAddDemoMiner: () => void;
};

const LARGE_BREAKPOINT = 'lg';

export default observer(
  ({
    activeTab,
    onTabChange,
    onRefresh,
    state,
    groups,
    balance,
    groupId,
    onPick,
    onFromChange,
    onScopeChange,
    btcInUsd,
    ratesState,
    goToPlan,
    goToAddDemoMiner,
  }: StatisticsScreenProps) => {
    const styles = useStyles();
    // Use ScrollView from RN, see https://github.com/software-mansion/react-native-gesture-handler/issues/1067
    return (
      <View style={{flex: 1}}>
        <ScrollView
          contentContainerStyle={styles.root}
          refreshControl={
            <RefreshControl
              refreshing={state.isRefreshing}
              onRefresh={onRefresh}
            />
          }>
          <StatisticsTab onTabPress={onTabChange} activeTab={activeTab} />
          {activeTab === StatisticsTabKey.Mining && (
            <StatisticsPanel
              groups={groups}
              balance={balance}
              groupId={groupId}
              state={state}
              onPick={onPick}
              onFromChange={onFromChange}
              onScopeChange={onScopeChange}
            />
          )}
          {activeTab === StatisticsTabKey.BTC && (
            <RatesPanel ratesState={ratesState} btcInUsd={btcInUsd} />
          )}
          <BottomStatisticBannerContainer
            goToPlan={goToPlan}
            goToAddDemoMiner={goToAddDemoMiner}
            largeBreakpoint={LARGE_BREAKPOINT}
          />
        </ScrollView>
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    paddingBottom: 20,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        margin: 20,
        paddingBottom: 40,
      },
    }),
  },
}));
