import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalRef} from '../components/templates';
import {useStrings} from '../Root/hooks';
import {BaseWorkerGroupNameModal} from '../BaseWorkerGroupNameModal';

export type AddNewWorkerGroupModalProps = {
  onModalClosed: () => void;
  onConfirm: (groupName: string) => void;
  onCancel: () => void;
};

export default observer<AddNewWorkerGroupModalProps, AdaptiveModalRef>(
  forwardRef(function AddNewWorkerGroupModal(
    {onConfirm, onCancel, onModalClosed},
    ref,
  ) {
    const strings = useStrings();
    return (
      <BaseWorkerGroupNameModal
        ref={ref}
        onModalClosed={onModalClosed}
        onConfirm={onConfirm}
        onCancel={onCancel}
        title={strings['modal.addMiningGroup.title']}
        description={strings['modal.addMiningGroup.subtext']}
        inputPlaceholder={strings['modal.addMiningGroup.placeholder']}
        buttonLabel={strings['action.add']}
      />
    );
  }),
);
