import {observer} from 'mobx-react-lite';
import {ScreenCard} from '../components';
import {useStrings} from '../Root/hooks';
import React, {useMemo} from 'react';
import {View, StyleSheet} from 'react-native';
import {AppButton, ButtonVariant} from '../components/AppButton';

export type SubscriptionRequestScreenProps = {
  goToPlans: () => void;
};

export default observer((props: SubscriptionRequestScreenProps) => {
  const {goToPlans} = props;
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <AppButton onPress={goToPlans} variant={ButtonVariant.Primary}>
        {strings['withdraw.subscriptionRequired.buy']}
      </AppButton>
    ),
    [goToPlans, strings],
  );
  return (
    <View style={styles.root}>
      <ScreenCard
        title={strings['withdraw.subscriptionRequired.title']}
        description={strings['withdraw.subscriptionRequired.description_2']}
        footer={footer}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
  },
});
