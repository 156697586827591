import {Platform} from 'react-native';
import AndroidMinerProposalsStateImpl from './AndroidMinerProposalsStateImpl';
import {InAppOffers} from '../InAppOffersService';
import IosMinerProposalsStateImpl from './IosMinerProposalsStateImpl';
import {CurrentSubscriptionOffer} from '../CurrentSubscriptionOfferService';
import SubMinerProposalsStateImpl from './SubMinerProposalsStateImpl';
import {MinerProposalsState} from './MinerProposalsState';

export default class MinerProposalsStateFactory {
  constructor(
    readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly currentSubscriptionOfferService: CurrentSubscriptionOffer;
    },
  ) {}

  create() {
    return Platform.select<MinerProposalsState>({
      android: new AndroidMinerProposalsStateImpl(this._root),
      ios: new IosMinerProposalsStateImpl(this._root),
      default: new SubMinerProposalsStateImpl(this._root),
    });
  }
}
