import {ReadonlyDeep} from 'type-fest';
import {WorkerGroupEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {SectionListData} from 'react-native';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerStateRegistry} from '../../../WorkerStateRegistry';
import {byAverageHashrate, byMinedTotal, byName, byStatus} from '../sort';
import {GroupId} from '../../../ApiStore';
import {WorkerOrder} from '../../../Dashboard';

export type WorkerItem = {
  index: number;
  isLast: boolean;
  worker: ReadonlyDeep<WorkerEntity>;
  key: string;
};

export type GroupSection = {
  isEmpty: boolean;
  group: ReadonlyDeep<WorkerGroupEntity>;
  isDefaultGroup: boolean;
  dataToken: string;
  index: number;
  isLast: boolean;
  collapsed: boolean;
};

export default function formSections(
  groups: readonly ReadonlyDeep<WorkerGroupEntity>[] | undefined,
  getOrder: () => WorkerOrder,
  getCollapsedGroups: () => GroupId[],
  balance: (workerId: string) => number,
  registry: WorkerStateRegistry,
): readonly SectionListData<WorkerItem, GroupSection>[] {
  if (!groups) {
    return [];
  }
  const sortedGroups = groups.slice();
  sortedGroups.sort((a, b) => a.id - b.id);
  const workerOrder = getOrder();
  const collapsedGroups = getCollapsedGroups();
  return sortedGroups.map((group, groupIndex) => {
    const isDefaultGroup = group.id === 0;
    const workerItems = group.workers.map(
      (worker, workerIndex): WorkerItem => ({
        worker,
        key: worker.id,
        index: workerIndex,
        isLast: workerIndex === group.workers.length - 1,
      }),
    );
    const visibleWorkerItems = collapsedGroups.includes(group.id)
      ? []
      : workerItems;
    switch (workerOrder) {
      case WorkerOrder.AverageHashrate:
        workerItems.sort((a, b) =>
          byAverageHashrate(a.worker, b.worker, registry),
        );
        break;
      case WorkerOrder.MinedTotal:
        workerItems.sort((a, b) => byMinedTotal(a.worker, b.worker, balance));
        break;
      case WorkerOrder.Name:
        workerItems.sort((a, b) => byName(a.worker, b.worker));
        break;
      case WorkerOrder.Status:
        workerItems.sort((a, b) => byStatus(a.worker, b.worker, registry));
        break;
    }
    return {
      index: groupIndex,
      isLast: groupIndex === sortedGroups.length - 1,
      isEmpty: workerItems.length === 0,
      collapsed: collapsedGroups.includes(group.id),
      data: visibleWorkerItems,
      key: String(group.id),
      group,
      isDefaultGroup,
      dataToken: visibleWorkerItems.flatMap((_) => [_.key]).join(' '),
    };
  });
}
