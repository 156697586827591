export interface ErrorNormalizer {
  normalize(_: any): SimpleError;
}

export type SimpleError = {
  code: number;
  message: string;
  raw: unknown;
};

export enum ErrorCode {
  Unknown = -1,
  ProviderNotFound = -2,
  SignerNotFound = -3,
  MetamaskNotConnected = -4,
  NetworkNotFoundError = -5,
  NotImplemented = -5,
}

export const METAMASK_NOT_CONNECTED = {
  code: ErrorCode.MetamaskNotConnected,
  message: 'Metamask not connected',
};
export const UNKNOWN_ERROR = {
  code: ErrorCode.Unknown,
  message: 'Unknown error',
  raw: null,
};
export const PROVIDER_NOT_FOUND_ERROR = {
  code: ErrorCode.ProviderNotFound,
  message: 'Provider not found',
  raw: null,
};
export const NOT_IMPLEMENTED_ON_PLATFORM = {
  code: ErrorCode.NotImplemented,
  message: 'Not implemented on this platform',
  raw: null,
};
export const SIGNER_NOT_FOUND_ERROR = {
  code: ErrorCode.SignerNotFound,
  message: 'Signer not found',
  raw: null,
};
export const NETWORK_NOT_FOUND_ERROR = {
  code: ErrorCode.NetworkNotFoundError,
  message: 'Network not found error',
  raw: null,
};
