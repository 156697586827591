import {reaction} from 'mobx';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DeviceInfo from 'react-native-device-info';
import {
  RATE_US_IS_ALREADY_RATE,
  RATE_US_QUANTITY_LAUNCH,
  RATE_US_SKIP_VERSION,
} from '../persistence';
import {Auth} from '../Auth';
import {
  ApiStore,
  MAKEREVIEW_TAG,
  RatingParams,
  REVIEWDONE_TAG,
} from '../ApiStore';
import {NavigationContainer} from '../NavigationContainer';
import {batchDisposers, Service} from '../structure';
import {RateApp} from './RateApp';
import {ConnectedClient} from '../ContextClient';

export default class RateAppService implements RateApp, Service {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly navigationContainer: NavigationContainer;
    },
  ) {}

  setRatingReviewed = async () => {
    await AsyncStorage.setItem(RATE_US_IS_ALREADY_RATE, 'true');
  };

  openModal = () => {
    this._root.navigationContainer.ref?.navigate('Rate');
  };

  skipReview = async () => {
    return AsyncStorage.setItem(RATE_US_SKIP_VERSION, DeviceInfo.getVersion());
  };

  remindLater = async () => {
    await AsyncStorage.removeItem(RATE_US_QUANTITY_LAUNCH);
  };

  sendEvent = async (rating: number) => {
    await this._root.apiStore.client.call('event', {
      type: 'rating',
      meta: {rating},
    });
  };

  sendRating = async (params: RatingParams) => {
    await this._root.connectedClient.call('rating', params);
  };

  private async _checkIfNecessary() {
    const authState = this._root.auth.state;
    if (
      authState?.kind === 'Connected' &&
      authState.tags.has(MAKEREVIEW_TAG) &&
      !authState.tags.has(REVIEWDONE_TAG)
    ) {
      return true;
    }
    const isAlreadyRate = await AsyncStorage.getItem(RATE_US_IS_ALREADY_RATE);
    if (isAlreadyRate !== null) {
      return false;
    }
    const skipVersion = await AsyncStorage.getItem(RATE_US_SKIP_VERSION);
    if (skipVersion !== null) {
      return false;
    }
    const quantityLaunch = await AsyncStorage.getItem(RATE_US_QUANTITY_LAUNCH);
    return Number(quantityLaunch) > 9;
  }

  private static async _actualize() {
    const isAlreadyRate = await AsyncStorage.getItem(RATE_US_IS_ALREADY_RATE);
    if (isAlreadyRate !== null) {
      return;
    }
    const skipVersion = await AsyncStorage.getItem(RATE_US_SKIP_VERSION);
    if (skipVersion === null) {
      return;
    }
    const version = DeviceInfo.getVersion();
    if (version !== skipVersion) {
      await AsyncStorage.removeItem(RATE_US_SKIP_VERSION);
      await AsyncStorage.removeItem(RATE_US_QUANTITY_LAUNCH);
    }
  }

  private static async _incrementLaunches() {
    const quantityLaunch = await AsyncStorage.getItem(RATE_US_QUANTITY_LAUNCH);
    const newQuantityLaunch = quantityLaunch ?? 1;
    await AsyncStorage.setItem(
      RATE_US_QUANTITY_LAUNCH,
      String(newQuantityLaunch),
    );
  }

  private static _initialize() {
    // noinspection JSIgnoredPromiseFromCall
    RateAppService._actualize();
    // noinspection JSIgnoredPromiseFromCall
    RateAppService._incrementLaunches();
    return undefined;
  }

  private async _openIfAllowed() {
    if (await this._checkIfNecessary()) {
      this.openModal();
    }
  }

  private _shownOnThisConnect = false;

  private _runWhenReady() {
    return reaction(
      () =>
        [
          this._root.auth.isConnected,
          this._root.navigationContainer.currentRouteName,
        ] as const,
      async ([isConnected, currentRoute]) => {
        if (isConnected) {
          if (currentRoute === 'Home' && !this._shownOnThisConnect) {
            this._shownOnThisConnect = true;
            await this._openIfAllowed();
          }
        } else {
          this._shownOnThisConnect = false;
        }
      },
      {fireImmediately: true},
    );
  }

  subscribe() {
    return batchDisposers(RateAppService._initialize(), this._runWhenReady());
  }
}
