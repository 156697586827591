import {observer} from 'mobx-react-lite';
import Svg, {Circle, Defs, LinearGradient, Path, Stop} from 'react-native-svg';
import React, {useRef} from 'react';
import {nanoid} from 'nanoid';
import {IconProps} from './IconProps';

export default observer(function PlusIcon(props: IconProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {active, ...rest} = props;
  const id = useRef(nanoid());
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...rest}>
      <Circle cx="20" cy="20" r="20" fill={`url(#${id.current})`} />
      <Path
        d="M19 19V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V19H26C26.5523 19 27 19.4477 27 20C27 20.5523 26.5523 21 26 21H21V26C21 26.5523 20.5523 27 20 27C19.4477 27 19 26.5523 19 26V21H14C13.4477 21 13 20.5523 13 20C13 19.4477 13.4477 19 14 19H19Z"
        fill="#09183F"
      />
      <Defs>
        <LinearGradient
          id={id.current}
          x1="-41.1392"
          y1="15.3562"
          x2="13.8102"
          y2="67.2135"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F7C61A" />
          <Stop offset="1" stopColor="#F7931A" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
});
