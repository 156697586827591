import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {CopyDiscountLinkModal} from '../../CopyDiscountLinkModal';
import {useRoot, useStrings} from '../../Root/hooks';
import {last} from 'lodash';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';

export default observer(function CopyDiscountLinkBinding() {
  const strings = useStrings();
  const {
    dashboardStore,
    configuration: {values},
    share,
    flashMessages,
    clipboard,
  } = useRoot();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const safelyGoBack = useSafelyGoBack();
  const promos = dashboardStore.dashboardPromo;
  const lastPromo = last(promos.promos);
  const code = lastPromo?.code;
  const promoUrl = values.promoRegistrationLink.replace('{code}', code || '-');
  const generateMessage = useCallback(() => {
    let message = `${strings['dashboard.statisticsDiscount.desc']}\n`;
    message += `${strings['dashboard.statisticsDiscount.link']} ${promoUrl} \n`;
    return message;
  }, [promoUrl, strings]);
  const onSharePress = useCallback(async () => {
    if (code !== undefined) {
      await share.shareMessage(code);
    }
  }, [code, share]);
  const onCopyPress = useCallback(async () => {
    clipboard.setString(generateMessage());
    flashMessages.showMessage({
      title: strings['action.discountLinkCopied'],
      variant: 'success',
    });
  }, [clipboard, flashMessages, generateMessage, strings]);
  return (
    <CopyDiscountLinkModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onCopyPress={onCopyPress}
      onSharePress={onSharePress}
      url={promoUrl}
    />
  );
});
