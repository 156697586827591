import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {QR} from 'qr-svg';

export type QrProps = {
  text: string;
};

export default observer(function Qr({text}: QrProps) {
  const qr = useMemo(() => QR(text), [text]);

  return <img src={'data:image/svg+xml;utf8,' + qr} />;
});
