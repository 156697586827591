import {observer} from 'mobx-react-lite';
import Svg, {Defs, LinearGradient, Path, Stop} from 'react-native-svg';
import React, {useRef} from 'react';
import {nanoid} from 'nanoid';
import {IconProps} from './IconProps';
import useColors from './useColors';

export default observer(function RingIcon({active, ...rest}: IconProps) {
  const id = useRef(nanoid());
  const {accent, second} = useColors(`url(#${id.current})`, active);
  return (
    <Svg width="20" height="24" viewBox="0 0 20 24" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7963 18.8181C16.2955 18.8181 19.3022 17.9819 19.5926 14.6255C19.5926 11.2715 17.4964 11.4872 17.4964 7.37188C17.4964 4.15739 14.4584 0.5 9.7963 0.5C5.13415 0.5 2.09623 4.15739 2.09623 7.37188C2.09623 11.4872 0 11.2715 0 14.6255C0.291529 17.9946 3.29821 18.8181 9.7963 18.8181Z"
        fill={accent}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0627 21.136C6.20629 20.7534 6.57161 20.5 6.97967 20.5H12.4513C12.8596 20.5 13.225 20.7536 13.3685 21.1365C13.5119 21.5194 13.4033 21.9513 13.0958 22.2203C11.1968 23.8822 8.25433 23.9037 6.33433 22.2196C6.02732 21.9503 5.91911 21.5186 6.0627 21.136Z"
        fill={second}
      />
      <Defs>
        <LinearGradient
          id={id.current}
          x1="-20.1506"
          y1="7.53239"
          x2="5.0547"
          y2="32.9744"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F7C61A" />
          <Stop offset="1" stopColor="#F7931A" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
});
