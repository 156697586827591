import {observer} from 'mobx-react-lite';
import React from 'react';
import {useForcedContext} from '../context';
import PaymentScreenContext from './context';
import FormActionsView from './FormActionsView';

export default observer(function FormActionsBinding() {
  const {
    onWalletPayPress,
    onCheckPaymentPress,
    payWithMetamaskVisible,
    payWithMetamaskPossible,
    onInstallMetamaskPress,
    payWithCtPayPossible,
    onCtPayPress,
  } = useForcedContext(PaymentScreenContext);
  return (
    <FormActionsView
      onWalletPayPress={onWalletPayPress}
      onInstallMetamaskPress={onInstallMetamaskPress}
      onCheckPaymentPress={onCheckPaymentPress}
      payWithMetamaskVisible={payWithMetamaskVisible}
      payWithMetamaskPossible={payWithMetamaskPossible}
      payWithCtPayPossible={payWithCtPayPossible}
      onCtPayPress={onCtPayPress}
    />
  );
});
