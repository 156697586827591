import {observer} from 'mobx-react-lite';
import React, {forwardRef} from 'react';
import AdaptiveModal, {
  AdaptiveModalRef,
} from '../components/templates/AdaptiveModal/AdaptiveModal';
import CalendarModalView, {CalendarModalViewProps} from './CalendarModalView';

export type CalendarModalScreenProps = CalendarModalViewProps & {
  onModalClosed: () => void;
};

export default observer<CalendarModalScreenProps, AdaptiveModalRef>(
  forwardRef(function CalendarModalScreen(
    {value, onSubmit, onModalClosed, close},
    ref,
  ) {
    return (
      <AdaptiveModal
        ref={ref}
        onModalClosed={onModalClosed}
        adaptiveBreakpoint="sm"
        enableContentAutoHeight>
        <CalendarModalView close={close} value={value} onSubmit={onSubmit} />
      </AdaptiveModal>
    );
  }),
);
