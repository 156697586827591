import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {CreateNewFarmWarningModal} from '../../CreateNewFarmWarningModal';
import {AdaptiveModalRef} from '../../components/templates';
import {AuthStackBindingProps} from './AuthStackBindingProps';
import {useRoot} from '../../Root/hooks';

export type CreateNewFarmWarningBindingProps =
  AuthStackBindingProps<'CreateNewFarmWarning'>;

export default observer(function CreateNewFarmWarningBinding({
  navigation,
}: CreateNewFarmWarningBindingProps) {
  const {auth} = useRoot();
  const goBack = useCallback(() => navigation.goBack(), [navigation]);
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = useCallback(() => modalRef.current?.close(), []);
  const onConfirmPress = useCallback(async () => {
    await close();
    goBack();
    await auth.registerNewFarm();
  }, [auth, close, goBack]);
  return (
    <CreateNewFarmWarningModal
      ref={modalRef}
      onConfirmPress={onConfirmPress}
      onCancelPress={close}
      onModalClosed={goBack}
    />
  );
});
