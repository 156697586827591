import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    padding: 20,
  },
  buttonGutter: {
    marginBottom: 15,
  },
  buttonGutterTop: {
    marginTop: 15,
  },
});
