import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet, Image} from 'react-native';
import {variance} from '../styling';
import {IMAGES} from './constants';

export type BackgroundImageProps = {
  imageIndex: number;
  width: number;
  height: number;
};

export default observer(({width, height, imageIndex}: BackgroundImageProps) => {
  const image = IMAGES[imageIndex];
  return (
    <RootView>
      <Image style={{width, height}} source={image} resizeMode="cover" />
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: 8.63,
    overflow: 'hidden',
    backgroundColor: theme.colors.uiAdditional1,
  },
}));
