import {useRoot} from '../Root/hooks';
import useHandleErrorAndExtractPromo from './useHandleErrorAndExtractPromo';
import {useCallback} from 'react';
import dayjs from 'dayjs';
import {ISODateString} from '../Time';
import {MinerOffer, PaymentMethod} from '../InAppOffersService';
import {PaymentType} from './ContextPurchaseResponse';
import {OrderId} from '../ApiStore/units/order';
import {ErrorHandler} from '../ExceptionHandler';
import {openLink} from '../Links';
import {BannerType} from '../Dashboard';

export type HookPurchasePoolMinerParams = {
  errorHandler: ErrorHandler;
};

export type PurchasePoolMinerParams = {
  offer: MinerOffer;
  purchaseTokenAndroid?: string;
  payment: PaymentMethod;
  goToPoolMinerActivated(
    poolExpiredDate: ISODateString,
    poolHashrate: number,
  ): void;
  goToCryptoPayment(id: OrderId): void;
};

export default function usePurchasePoolMiner({
  errorHandler,
}: HookPurchasePoolMinerParams) {
  const {
    dashboardService,
    purchaseRequester,
    translation: {localeTag},
    dashboardService: {bannersState},
  } = useRoot();
  const handle = useHandleErrorAndExtractPromo({errorHandler});
  return useCallback(
    async (params: PurchasePoolMinerParams) => {
      const {offer, payment, goToPoolMinerActivated, goToCryptoPayment} =
        params;
      const requestParams = {
        offerId: offer.offerId,
        purchaseTokenAndroid: params.purchaseTokenAndroid,
        poolMinerIdForRenew: undefined,
        payment,
      };
      const response = await purchaseRequester.request(requestParams);
      const process_ = await handle(response);
      if (!process_.success) {
        return;
      }
      if (response.success) {
        switch (response.right.kind) {
          case PaymentType.InAppCompleted:
            dashboardService.bannersState.delete(BannerType.PoolMiner);
            const newExpireDate = dayjs()
              .locale(localeTag)
              .add(offer.interval, 'month')
              .toISOString() as ISODateString;
            bannersState.delete(BannerType.PoolMiner);
            goToPoolMinerActivated(
              newExpireDate,
              offer.poolMinerConfig.hash_rate,
            );
            break;
          case PaymentType.ExternalOrder:
            if (
              response.right.redirectUrl &&
              !response.right.metamaskIsPresented
            ) {
              await openLink(response.right.redirectUrl);
            } else {
              goToCryptoPayment(response.right.orderId);
            }
            break;
        }
      }
    },
    [
      bannersState,
      purchaseRequester,
      handle,
      dashboardService.bannersState,
      localeTag,
    ],
  );
}
