import {observer} from 'mobx-react-lite';
import {GraphPanel} from '../GraphPanel';
import React from 'react';
import {useStrings} from '../Root/hooks';
import {GraphPanelProps} from '../GraphPanel';
import {View} from 'react-native';
import {Balance} from '../StatisticsPanel/StatisticsPanel';
import {Paragraph} from '../components';
import RatesPanelState from './RatesPanelState';

export type RatesPanelProps = Partial<GraphPanelProps> & {
  btcInUsd: number | undefined;
  ratesState: RatesPanelState;
};

export default observer((props: RatesPanelProps) => {
  const strings = useStrings();

  const card = (
    <View>
      <Balance type="mono" weight="700">
        ${props.btcInUsd}
      </Balance>
    </View>
  );

  const title = (
    <Paragraph type="paragraph" size="large" weight="600">
      BTC/USD
    </Paragraph>
  );

  return (
    <GraphPanel
      onFromChange={props.ratesState.onFromChange}
      from={props.ratesState.period.from}
      to={props.ratesState.period.to}
      titleRight={title}
      filterRight={card}
      onScopeChange={props.ratesState.onScopeChange}
      scope={props.ratesState.scope}
      isLoading={props.ratesState.isLoading}
      graph={props.ratesState.graph}
      title={strings['common.btcUsdPrice']}
      fractionDigits={0}
      {...props}
    />
  );
});
