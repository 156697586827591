import React, {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import Svg, {Defs, LinearGradient, Stop, Line} from 'react-native-svg';
import {nanoid} from 'nanoid';
import {useRoot} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {StyleProp, ViewStyle, StyleSheet, View} from 'react-native';
import useMeasure from '../../ReactNativeUtil/useMeasure';

export type DividerProps = {
  vertical?: boolean;
  opacity?: number;
  style?: StyleProp<ViewStyle>;
};

export default observer(function Divider(props: DividerProps) {
  const {opacity = 1, vertical, style} = props;
  const {appearance} = useRoot();
  const id = useRef(nanoid());
  const stopColor = expr(() => (appearance.isDark ? '#82878F' : '#D0D5DE'));
  const _opacity = expr(() =>
    Math.max((appearance.isDark ? 0.6 : 0.7) - (1 - opacity), 0),
  );
  const viewRef = useRef(null);
  const [getResult, onLayout] = useMeasure(viewRef, false);

  const viewRef2 = useRef(null);
  const [getResult2, onLayout2] = useMeasure(viewRef2, false);
  if (vertical) {
    const result = getResult();
    const y = result?.height;
    return (
      <View
        ref={viewRef}
        onLayout={onLayout}
        style={[styles.root, styles.verticalRoot, style]}>
        {result && y !== 0 && (
          <Svg
            width="2"
            height="100%"
            viewBox={`0 0 2 ${y}`}
            fill="none"
            preserveAspectRatio="none">
            <Line
              opacity={_opacity}
              strokeWidth="1"
              x1="0"
              y1="0"
              x2="0"
              y2={y}
              stroke={`url(#${id.current})`}
            />
            <Defs>
              <LinearGradient
                id={id.current}
                y1="0"
                y2="100%"
                gradientUnits="userSpaceOnUse">
                <Stop stopColor={stopColor} stopOpacity="0" />
                <Stop offset="0.5" stopColor={stopColor} />
                <Stop offset="1" stopColor={stopColor} stopOpacity="0" />
              </LinearGradient>
            </Defs>
          </Svg>
        )}
      </View>
    );
  }
  const result = getResult2();
  const x = result?.width;
  return (
    <View
      ref={viewRef2}
      onLayout={onLayout2}
      style={[styles.root, styles.horizontalRoot, style]}>
      {result && x !== 0 && (
        <Svg
          width="100%"
          height="2"
          viewBox={`0 0 ${x} 2`}
          fill="none"
          preserveAspectRatio="none">
          <Line
            opacity={_opacity}
            strokeWidth="1"
            x1="0"
            y1="0"
            x2={x}
            y2="0"
            stroke={`url(#${id.current})`}
          />
          <Defs>
            <LinearGradient
              id={id.current}
              x1={x}
              y1="2"
              x2="0"
              y2="2"
              gradientUnits="userSpaceOnUse">
              <Stop stopColor={stopColor} stopOpacity="0" />
              <Stop offset="0.5" stopColor={stopColor} />
              <Stop offset="1" stopColor={stopColor} stopOpacity="0" />
            </LinearGradient>
          </Defs>
        </Svg>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
  },
  verticalRoot: {
    width: 2,
  },
  horizontalRoot: {
    height: 2,
  },
});
