import {expr} from 'mobx-utils';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';
import {
  MinerProductOffer,
  MinerSubscriptionOffer,
} from '../../InAppOffersService';
import {Platform} from 'react-native';
import getIsSubscriptionWorker from '../getters/getIsSubscriptionWorker';
import isPoolMiner from './isPoolMiner';

function hasMatchingHashrate(
  workerHashrate: number | undefined,
  hashrateConfigList: number[],
) {
  return workerHashrate ? hashrateConfigList.includes(workerHashrate) : false;
}

export default function isRenewPossible(
  worker: ReadonlyDeep<WorkerEntity>,
  offersProducts: MinerProductOffer[],
  offersSubscriptions: MinerSubscriptionOffer[],
) {
  const offers =
    Platform.OS === 'android' && worker.subscription_id
      ? offersSubscriptions
      : offersProducts;
  const workerHashrate = worker?.pool_miner_config?.hash_rate;
  const hashrateConfigList = expr(() =>
    offers.map((offer) => offer.poolMinerConfig.hash_rate),
  );

  const canRenew = Boolean(
    isPoolMiner(worker) &&
      hasMatchingHashrate(workerHashrate, hashrateConfigList),
  );

  if (Platform.OS === 'android') {
    return worker.subscription_id
      ? canRenew && getIsSubscriptionWorker(worker)
      : canRenew && !worker.is_free;
  }
  return canRenew && !getIsSubscriptionWorker(worker); // не подписка и не бесплатная
}
