import React from 'react';
import {
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import {SvgProps} from 'react-native-svg';

import {useTheme, variance} from '../styling';

export type CirclePlatformProps = {
  Icon: React.ComponentType<SvgProps>;
  disabled?: boolean;
  scale?: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  android?: boolean;
  ios?: boolean;
};

export const CirclePlatform = (props: CirclePlatformProps) => {
  const {Icon, style, scale = 1, disabled, onPress, android, ios} = props;
  const size = 32 * scale;
  const theme = useTheme();
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <RootView
        disabled={disabled}
        ios={ios}
        android={android}
        style={[
          style,
          {
            width: size,
            height: size,
            borderRadius: size / 2,
          },
        ]}>
        <Icon
          width={14 * scale}
          height={16 * scale}
          color={
            disabled
              ? theme.colors.primaryUIDirtyBlue
              : theme.colors.primaryWhite
          }
        />
      </RootView>
    </TouchableWithoutFeedback>
  );
};

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme
      .chroma(theme.colors.primarySuccess)
      .brighten(0.1)
      .hex(),
    justifyContent: 'center',
    alignItems: 'center',
  },
  ios: {
    backgroundColor: theme.colors.uiSecondary,
  },
  android: {
    backgroundColor: theme.colors.primarySuccess,
  },
  disabled: {
    backgroundColor: theme.colors.uiAdditional1,
  },
}));
