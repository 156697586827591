import {observer} from 'mobx-react-lite';
import {
  AdaptiveModal,
  AdaptiveModalRef,
  ModalWidth,
  Representation,
} from '../components/templates';
import React, {forwardRef} from 'react';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import useRepresentation from '../components/templates/AdaptiveModal/useRepresentation';
import {AdaptiveModalHeader} from '../components/organisms/modal/AdaptiveModalHeader';
import {View, StyleSheet} from 'react-native';
import {useTheme, variance} from '../styling';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {SvgProps} from 'react-native-svg';
import {TwoToneIcon} from '../components/atoms';
import {sized} from '../Svg';
import DiscountSvg from '../assets/svg/twoTone/discount.svg';
import CopySvg from '../assets/svg/colorless/copy.svg';
import {Typography} from '../components';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useRoot, useStrings} from '../Root/hooks';
import AdaptiveModalActions from '../components/organisms/modal/AdaptiveModalActions';

export const ADAPTIVE_ACTION_MODAL_BREAKPOINT = 'sm';

export type CopyDiscountLinkModalProps = {
  onModalClosed: () => void;
  onCopyPress: () => void;
  onSharePress: () => void;
  url: string;
};

export default observer<CopyDiscountLinkModalProps, AdaptiveModalRef>(
  forwardRef(function CopyDiscountLinkModal(props, ref) {
    return (
      <AdaptiveModal
        ref={ref}
        adaptiveBreakpoint={ADAPTIVE_ACTION_MODAL_BREAKPOINT}
        enableContentAutoHeight
        modalWidth={ModalWidth.Small}
        onModalClosed={props.onModalClosed}>
        <ContentView {...props} />
      </AdaptiveModal>
    );
  }),
);

const ContentView = observer(
  ({onCopyPress, onSharePress, url}: CopyDiscountLinkModalProps) => {
    const {share} = useRoot();
    const paddingBottom = useSafePaddingBottom(16, 20);
    const representation = useRepresentation();
    const isModal = representation === Representation.Modal;
    const theme = useTheme();
    const strings = useStrings();
    return (
      <RootView style={{paddingBottom}} modal={isModal}>
        <View style={styles.headerView}>
          <AdaptiveModalHeader
            IconSvgModalRep={DiscountLargeIcon}
            IconSvg={DiscountIcon}
            title="Personal discount link"
            description="Send your personal link with a discount code to your friends. Get a bonus from every purchase they make."
          />
        </View>
        <View style={styles.content}>
          <TouchableOpacity
            style={styles.touchable}
            onPress={onCopyPress}
            activeOpacity={0.8}>
            <FakeInputView>
              <Typography
                adjustsFontSizeToFit
                minimumFontScale={0.8}
                numberOfLines={1}
                style={styles.inputValue}
                type="body"
                weight="500">
                {url}
              </Typography>
              <CopyIcon style={styles.icon} color={theme.colors.uiSecondary} />
            </FakeInputView>
          </TouchableOpacity>
        </View>
        <AdaptiveModalActions
          actions={[
            <AppButton onPress={onCopyPress} variant={ButtonVariant.Primary}>
              {strings['action.copy']}
            </AppButton>,
            share.isSupported && (
              <AppButton onPress={onSharePress}>
                {strings['action.share']}
              </AppButton>
            ),
          ]}
        />
      </RootView>
    );
  },
);

const CopyIcon = sized(CopySvg, 14);

const DiscountIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(DiscountSvg, 30)} {...props} />
));

const DiscountLargeIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(DiscountSvg, 40)} {...props} />
));

const styles = StyleSheet.create({
  content: {
    marginTop: 12,
    marginBottom: 32,
  },
  headerView: {
    marginBottom: 16,
  },
  touchable: {
    flexGrow: 1,
  },
  inputValue: {
    fontSize: 16,
    lineHeight: 20,
    marginRight: 7,
    flex: 1,
  },
  icon: {
    marginTop: 2,
  },
});

const RootView = variance(View)(() => ({
  root: {
    padding: 16,
    paddingTop: 39,
  },
  modal: {
    padding: 20,
    paddingTop: 48,
  },
}));

const FakeInputView = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional1,
    borderRadius: 8,
    flexDirection: 'row',
    minWidth: 190,
    minHeight: 50,
  },
}));
