import {useEffect} from 'react';
import {comparer, reaction} from 'mobx';
import {useRoot, useStrings} from '../Root/hooks';
import {useIsFocused} from '@react-navigation/native';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';

export default function useScheduleUpdateMessage(
  workers: ReadonlyDeep<WorkerEntity[]>,
) {
  const {scheduleStore, flashMessages} = useRoot();
  const isFocused = useIsFocused();
  const strings = useStrings();
  useEffect(
    () =>
      reaction(
        () => Array.from(workers).map((_) => _.schedulers),
        () => {
          if (!scheduleStore.shallNotifyAboutUpdate) {
            scheduleStore.restoreNotifications();
            return;
          }
          if (isFocused) {
            flashMessages.showMessage({
              title: strings['scheduler.update'],
              variant: 'info',
            });
          }
        },
        {equals: comparer.structural},
      ),
    [flashMessages, isFocused, scheduleStore, strings, workers],
  );
}
