import {observer} from 'mobx-react-lite';
import React from 'react';
import ColoredBanner, {
  ShadowColor,
  DefaultButton,
  DARK_GREEN_BACKGROUND,
  CHIP_ICON,
} from '../../molecules/ColoredBanner';
import {ViewProps, StyleSheet} from 'react-native';
import {useStrings} from '../../../Root/hooks';

export type TryDemoMinerBannerProps = ViewProps & {
  onButtonPress: () => void;
};

export default observer(function TryDemoMinerBanner({
  onButtonPress,
  ...rest
}: TryDemoMinerBannerProps) {
  const strings = useStrings();
  return (
    <ColoredBanner
      shadowColor={ShadowColor.Green}
      title={strings['banner.tryDemoMiner.title']}
      text={strings['banner.tryDemoMiner.description']}
      textStyle={styles.text}
      descriptionVisible={(layout) => (layout ? layout.width > 365 : false)}
      icon={CHIP_ICON}
      iconProps={{
        style: {
          transform: [{translateX: 20}, {translateY: -5}],
        },
      }}
      background={DARK_GREEN_BACKGROUND}
      actions={[
        <DefaultButton
          onPress={onButtonPress}
          title={strings['banner.tryDemoMiner.createMinerButton']}
        />,
      ]}
      {...rest}
    />
  );
});

const styles = StyleSheet.create({
  text: {
    maxWidth: 260,
  },
});
