// https://stackoverflow.com/questions/4187146/truncate-number-to-two-decimal-places-without-rounding
export default (n: number, c: number): string => {
  const x = toFixed(n);

  // From here on the code is the same than the original answer
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (c <= 0) {
    return v[0];
  }
  let f = v[1] || '';
  if (f.length > c) {
    return `${v[0]}.${f.substr(0, c)}`;
  }
  while (f.length < c) {
    f += '0';
  }
  return `${v[0]}.${f}`;
};

function toFixed(x: number) {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1], 10);
    if (e) {
      x *= Math.pow(10, e - 1);
      return '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1], 10);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      return x.toString() + new Array(e + 1).join('0');
    }
  }
  return x;
}
