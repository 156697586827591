import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import React from 'react';
import useGetIsLarge from '../../hooks/useGetIsLarge';
import {expr} from 'mobx-utils';
import {View} from 'react-native';
import {variance} from '../../../styling';
import {GroupSection} from './formSections';
import {SECTION_LIST_SECTION_FOOTER_HEIGHT} from '../../constants';

export type SectionGroupFooterProps = {
  groupSection: ReadonlyDeep<GroupSection>;
};

export default observer(function SectionGroupFooter(
  props: SectionGroupFooterProps,
) {
  const {groupSection} = props;
  const {isLast} = groupSection;
  const getIsLarge = useGetIsLarge();
  const isLarge = expr(() => getIsLarge());
  const getSectionGroupFooterHeight = useGetSectionGroupFooterHeight();
  const height = expr(() => getSectionGroupFooterHeight(isLast));
  if (isLast) {
    return null;
  }
  return <RootView style={{height}} large={isLarge} />;
});

export function useGetSectionGroupFooterHeight() {
  return (isLast: boolean) => (isLast ? 0 : SECTION_LIST_SECTION_FOOTER_HEIGHT);
}

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.colors.backgroundGround,
  },
  large: {
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.uiAdditional1,
    borderRightWidth: 1,
    borderRightColor: theme.colors.uiAdditional1,
  },
}));
