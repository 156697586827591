import {observer} from 'mobx-react-lite';
import React, {forwardRef} from 'react';
import {SvgProps} from 'react-native-svg';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {TwoToneIcon} from '../components/atoms';
import {AdaptiveActionModal} from '../components/organisms';
import {AdaptiveModalRef} from '../components/templates';
import {sized} from '../Svg';
import DoorSvg from '../assets/svg/colorless/door.svg';
import {useStrings} from '../Root/hooks';

export type DisconnectWalletModalProps = {
  onModalClosed: () => void;
  onCancel: () => void;
  onDisconnectWallet: () => void;
};

export default observer<DisconnectWalletModalProps, AdaptiveModalRef>(
  forwardRef(function DisconnectWalletModal(props, ref) {
    const strings = useStrings();
    const {onDisconnectWallet, onCancel, onModalClosed} = props;
    return (
      <AdaptiveActionModal
        ref={ref}
        IconSvg={DoorIcon}
        IconSvgModalRep={DoorIconLarge}
        onModalClosed={onModalClosed}
        title={strings['modal.logoutWallet.title']}
        description={strings['modal.logoutWallet.description']}
        actions={[
          <AppButton
            variant={ButtonVariant.Primary}
            onPress={onDisconnectWallet}>
            {strings['modal.logoutWallet.logoutButton']}
          </AppButton>,
          <AppButton variant={ButtonVariant.Default} onPress={onCancel}>
            {strings['modal.logoutWallet.cancelButton']}
          </AppButton>,
        ]}
      />
    );
  }),
);

const DoorIcon = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(DoorSvg, 22, 28)} {...props} />
));

const DoorIconLarge = observer((props: SvgProps) => (
  <TwoToneIcon IconSvg={sized(DoorSvg, 30, 36)} {...props} />
));
