import {createNullableContext} from '../context';
import {PurchaseScreenState} from './PurchaseScreenState';
import {Offer} from '../InAppOffersService';
import {Subscription} from '../farmApi';
import {GlobalError} from '../Error';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';
import {PurchaseId} from '../units';

export type PurchaseScreenProps = {
  goToAccessRecovery: () => void;
  goToConfiguration: () => void;
  state: PurchaseScreenState;
  onWorkerQuestionPress: () => void;
  onPurchasePress: (offer: Offer) => void;
  onImprovePress: (_: PurchaseId) => void;
  onManagePress: (_: PurchaseId) => void;
  subscription: Subscription | undefined;
  isLoading: boolean;
  isRefreshing: boolean;
  onRefresh: () => void;
  fetchOffersError: GlobalError | undefined;
  visibleReactivationTab: boolean;
  goToPlayStore: () => void;
  goToAppStore: () => void;
  goToLinkWorker: () => void;
  safelyGoToAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
  selectedConfig: MinerConfig | undefined;
};

const PurchaseScreenContext = createNullableContext<PurchaseScreenProps>();

export default PurchaseScreenContext;
