import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {Body as TypoBody} from '../components';
import {useTheme, variance} from '../styling';

export type SectionDescriptionProps = {
  Icon: React.ComponentType<SvgProps>;
  IconDark: React.ComponentType<SvgProps>;
  description: string;
};

export default observer(function SectionDescription({
  Icon,
  IconDark,
  description,
}: SectionDescriptionProps) {
  const theme = useTheme();
  const DescIcon = theme.select(Icon, IconDark);
  return (
    <View style={styles.root}>
      <View style={styles.icon}>
        <DescIcon />
      </View>
      <View style={styles.content}>
        <BodyText type="body" weight="500">
          {description}
        </BodyText>
      </View>
    </View>
  );
});

const BodyText = variance(TypoBody)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    letterSpacing: -0.2,
    fontSize: 13,
    lineHeight: 16,
  },
}));

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
    paddingTop: 14,
    paddingBottom: 18,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    marginRight: 12.5,
  },
  content: {
    flex: 1,
  },
});
