import React from 'react';
import {observer} from 'mobx-react-lite';
import {createStackNavigator} from '@react-navigation/stack';
import {AuthParamList} from './AuthParamList';
import {useRoot} from '../../Root/hooks';
import {AuthenticationErrorReason, AuthorizationErrorReason} from '../../Auth';
import ForcedAuthBinding from './ForcedAuthBinding';
import OAuthSplashBinding from './OAuthSplashBinding';
import ForcedAccessRecoveryBinding from './ForcedAccessRecoveryBinding';
import {NoConnectionBinding} from '../../NoConnectionContainer';
import UpdateRequiredBinding from './UpdateRequiredBinding';
import ForcedAccountSwitchBinding from './ForcedAccountSwitchBinding';
import useGetMobileScreenOptions from '../hooks/useGetMobileScreenOptions';
import CreateNewFarmWarningBinding from './CreateNewFarmWarningBinding';

const {Navigator, Screen, Group} = createStackNavigator<AuthParamList>();

export default observer(() => {
  const {auth} = useRoot();
  const getOptions = useGetMobileScreenOptions();
  let screens: React.ReactNode = null;
  if (
    auth.state?.kind === 'AuthenticationFailed' &&
    auth.state?.reason === AuthenticationErrorReason.OAuthNeeded
  ) {
    screens = (
      <>
        <Screen name="Auth" component={ForcedAuthBinding} />
        <Screen
          name="OAuthSplash"
          options={{headerShown: false}}
          component={OAuthSplashBinding}
        />
      </>
    );
  } else if (
    auth.state?.kind === 'AuthenticationFailed' &&
    auth.state?.reason === AuthenticationErrorReason.AccountSwitchRequired
  ) {
    screens = (
      <>
        <Screen name="AccessRecovery" component={ForcedAccessRecoveryBinding} />
        <Screen name="Auth" component={ForcedAuthBinding} />
        <Screen
          name="OAuthSplash"
          options={{headerShown: false}}
          component={OAuthSplashBinding}
        />
      </>
    );
  } else if (
    (auth.state?.kind === 'AuthenticationFailed' &&
      auth.state?.reason === AuthenticationErrorReason.UpdateRequired) ||
    (auth.state?.kind === 'AuthorizationFailed' &&
      auth.state?.reason === AuthorizationErrorReason.UpdateRequired)
  ) {
    screens = (
      <Screen
        name="Error"
        component={UpdateRequiredBinding}
        options={hiddenHeaderOptions}
      />
    );
  } else if (
    (auth.state?.kind === 'Authorized' ||
      auth.state?.kind === 'ConnectionFailed') &&
    auth.state?.accountIds.size > 1
  ) {
    screens = (
      <Screen name="AccountSwitch" component={ForcedAccountSwitchBinding} />
    );
  } else if (
    auth.state?.kind === 'AuthenticationFailed' ||
    auth.state?.kind === 'AuthorizationFailed' ||
    auth.state?.kind === 'ConnectionFailed'
  ) {
    screens = (
      <Screen
        name="Error"
        component={NoConnectionBinding}
        options={hiddenHeaderOptions}
      />
    );
  }
  return (
    screens && (
      <Navigator
        screenOptions={({route, navigation}) =>
          getOptions({routeName: route.name, navigation, goBackPossible: false})
        }>
        {screens}
        <Group
          screenOptions={{
            headerShown: false,
            animationEnabled: false,
            cardStyle: {backgroundColor: 'transparent'},
            cardOverlayEnabled: false,
            gestureEnabled: false,
            presentation: 'transparentModal',
          }}>
          <Screen
            name="CreateNewFarmWarning"
            component={CreateNewFarmWarningBinding}
          />
        </Group>
      </Navigator>
    )
  );
});

const hiddenHeaderOptions = {headerShown: false} as const;
