import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';

import {PooMinerActivatedModal} from '../../PooMinerActivatedModal';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {ISODateString} from '../../Time';
import {useRoot} from '../../Root/hooks';
import dayjs from 'dayjs';

export default observer(function PoolMinerActivatedBinding({
  navigation,
  route,
}: RootStackBindingProps<'PoolMinerActivated'>) {
  const modalRef = useRef<AdaptiveModalRef>(null);
  const {poolHashrate, poolExpiredDate} = route.params ?? {};
  const {
    purchaseDiscount: {discount},
  } = useRoot();
  const discountExpiredDate =
    discount && discount.expirationDate
      ? (dayjs(discount.expirationDate).format() as ISODateString)
      : undefined;
  const safelyGoBack = useSafelyGoBack();
  const goBack = useCallback(async () => {
    await modalRef.current?.close();
    safelyGoBack();
    navigation.navigate('Plan');
  }, [navigation, safelyGoBack]);
  return (
    <PooMinerActivatedModal
      poolExpiredDate={poolExpiredDate}
      discountExpiredDate={discountExpiredDate}
      poolHashrate={poolHashrate}
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onButtonPress={goBack}
      hasDiscount={!!discount}
    />
  );
});
