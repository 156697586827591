import React from 'react';
import {StyleSheet} from 'react-native';
import {observer} from 'mobx-react-lite';
import TopInfo from './components/TopInfo';
import ListCards from './components/ListCards';
import {ScrollView} from 'react-native-gesture-handler';
import {variance} from '../styling';
import {LG_BREAKPOINT} from '../WindowDimensions/useDimensions';
import {Product} from '../Products/Product';

export type ProductsScreenProps = {
  products: Product[] | undefined;
};

export default observer(function ProductsScreen(props: ProductsScreenProps) {
  const {products} = props;
  return (
    <Container contentContainerStyle={styles.container}>
      <TopInfo />
      <ListCards data={products} />
    </Container>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingVertical: 24,
  },
});

const Container = variance(ScrollView)((theme) => ({
  root: {
    backgroundColor: theme.colors.uiMain,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginHorizontal: 18,
        borderWidth: 1,
        borderColor: theme.colors.uiAdditional1,
      },
    }),
  },
}));
