import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import Slider, {SliderProps} from './Slider';
import ErrorView from './ErrorView';
import ConfirmingView from './ConfirmingView';
import {SharedValue} from 'react-native-reanimated';
import {PercentPosition, SliderStatus} from '../constants';
import SuccessView from './SuccessView';

export type SliderViewProps = SliderProps & {
  sliderStatus: SliderStatus;
  sliderTranslateX: SharedValue<PercentPosition>;
};

export default observer(function SliderView({
  onRefreshPress,
  onEndSwipe,
  sliderStatus,
  sliderTranslateX,
}: SliderViewProps) {
  return (
    <View>
      {sliderStatus === SliderStatus.Idle ? (
        <Slider
          onEndSwipe={onEndSwipe}
          onRefreshPress={onRefreshPress}
          sliderTranslateX={sliderTranslateX}
        />
      ) : sliderStatus === SliderStatus.Error ? (
        <ErrorView onPress={onRefreshPress} />
      ) : sliderStatus === SliderStatus.Confirming ? (
        <ConfirmingView />
      ) : sliderStatus === SliderStatus.Success ? (
        <SuccessView />
      ) : null}
    </View>
  );
});
