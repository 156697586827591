import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import useSafePaddingBottom from '../components/templates/AdaptiveModal/useSafePaddingBottom';
import WorkerLinkInfo from './WorkerLinkInfo';
import Loader from './Loader';
import SuccessLink from './SuccessLink';
import {GetWorkerInfoResult, LinkResult} from './types';
import ErrorLink from './ErrorLink';

export type ContentViewProps = {
  getIsLoading: () => boolean;
  getLinkResult: () => LinkResult | undefined;
  getWorkerInfo: () => GetWorkerInfoResult | undefined;
  onSwitchFarmPress: () => void;
  onConfirmPress: () => void;
  onCancelPress: () => void;
  onSuccessLinkPress: () => void;
  onErrorLinkPress: () => void;
  onSupportPress: () => void;
  getIsDisabledLinkButtonBox: () => boolean;
  goToAuth: () => void;
};

export default observer(function ContentView(props: ContentViewProps) {
  const {
    getIsLoading,
    getLinkResult,
    onSuccessLinkPress,
    onErrorLinkPress,
    getWorkerInfo,
    onSwitchFarmPress,
    onConfirmPress,
    onCancelPress,
    onSupportPress,
    goToAuth,
    getIsDisabledLinkButtonBox,
  } = props;
  const paddingBottom = useSafePaddingBottom(16);
  let content;
  const linkResult = getLinkResult();
  const workerInfo = getWorkerInfo();
  if (getIsLoading() || workerInfo === undefined) {
    content = <Loader />;
  } else if (linkResult?.success) {
    content = <SuccessLink onSuccessLinkPress={onSuccessLinkPress} />;
  } else if (!workerInfo.success) {
    content = (
      <ErrorLink
        error={workerInfo.left}
        onSupportPress={onSupportPress}
        onConfirmPress={onErrorLinkPress}
      />
    );
  } else if (workerInfo.right !== undefined) {
    content = (
      <WorkerLinkInfo
        onSwitchFarmPress={onSwitchFarmPress}
        onConfirmPress={onConfirmPress}
        onCancelPress={onCancelPress}
        workerInfo={workerInfo.right}
        goToAuth={goToAuth}
        getIsDisabledLinkButtonBox={getIsDisabledLinkButtonBox}
      />
    );
  } else if (!linkResult?.success) {
    content = (
      <ErrorLink
        error={linkResult?.left}
        onSupportPress={onSupportPress}
        onConfirmPress={onErrorLinkPress}
      />
    );
  } else {
    content = null;
  }
  return <View style={{paddingBottom}}>{content}</View>;
});
