import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import RootView from './RootView';
import ErrorPanel from './ErrorPanel';
import layout from './layout';
import RetryButton from './RetryButton';
import {AppButton} from '../components/AppButton';

export type NoConnectionScreenProps = {
  onRetry: () => void;
  onSwitchEnvironment: (() => void) | undefined;
  closeApp: () => void;
};

export default observer(function NoConnectionScreen(
  props: NoConnectionScreenProps,
) {
  const {onRetry, onSwitchEnvironment, closeApp} = props;
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <>
        {onSwitchEnvironment && (
          <AppButton onPress={onSwitchEnvironment} style={layout.buttonGutter}>
            Switch environment
          </AppButton>
        )}
        <RetryButton onPress={onRetry} />
        <AppButton onPress={closeApp} style={layout.buttonGutterTop}>
          {strings['action.closeApp']}
        </AppButton>
      </>
    ),
    [strings, closeApp, onRetry, onSwitchEnvironment],
  );
  return (
    <RootView style={layout.root} contentContainerStyle={layout.container}>
      <ErrorPanel
        title={strings['error.noConnection.title']}
        description={strings['error.noConnection.description']}
        footer={footer}
      />
    </RootView>
  );
});
