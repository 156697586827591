import {PurchaseId} from '../units';
import {Proposal} from './ProposalsState';
import {ActivatorSubscriptionOffer, InAppOffers} from '../InAppOffersService';
import {computed, makeObservable, observable} from 'mobx';
import {Second} from '../Time';
import {sortBy, uniq} from 'lodash';
import {ActivatorProposalsState} from './ActivatorProposalsState';

export default class ActivatorProposalsStateImpl
  implements ActivatorProposalsState
{
  @observable.ref private _activatorSubscriptionProposalById?: ReadonlyMap<
    PurchaseId,
    Proposal<ActivatorSubscriptionOffer>
  >;
  constructor(
    private readonly _root: {
      readonly inAppOffers: InAppOffers;
    },
  ) {
    makeObservable(this);
  }

  get activatorSubscriptionProposalById() {
    return this._activatorSubscriptionProposalById;
  }

  @computed
  get activatorSubscriptionProposalList() {
    return [...(this.activatorSubscriptionProposalById?.values() ?? [])];
  }

  @computed
  get uniqActivatorTimeList() {
    return [...(this.activatorProposalMapByTime.keys() ?? [])];
  }

  @computed
  get uniqActivatorIntervalList() {
    return sortBy(
      uniq(this.activatorSubscriptionProposalList.map((_) => _.offer.interval)),
    );
  }

  @computed
  get activatorProposalMapByTime(): ReadonlyMap<
    Second,
    Proposal<ActivatorSubscriptionOffer>[]
  > {
    const uniqSecondList = uniq(
      this.activatorSubscriptionProposalList.flatMap<Second>((_) =>
        _.available &&
        _.offer.options &&
        _.offer.options?.activate_pm_after !== null
          ? [_.offer.options.activate_pm_after]
          : [],
      ),
    );
    const sortedSecondList = sortBy(uniqSecondList);
    return new Map(
      sortedSecondList.map((_) => {
        // Proposals for every time
        const proposals = this.activatorSubscriptionProposalList.filter(
          (proposal) => proposal.offer.options?.activate_pm_after === _,
        );
        const filtered = sortBy(
          proposals,
          (proposal) => proposal.offer.interval,
        );
        const sorted = sortBy(filtered, (proposal) => proposal.offer.interval);
        return [_, sorted];
      }),
    );
  }

  prepare() {
    const offers = this._root.inAppOffers.activatorSubscriptionOffers;
    const availableList = offers.map((offer) => {
      return {
        offer: offer,
        available: true,
      };
    });

    this._activatorSubscriptionProposalById = new Map(
      availableList.map((_) => [_.offer.purchaseId, _]),
    );
  }

  reset() {
    this._activatorSubscriptionProposalById = undefined;
  }
}
