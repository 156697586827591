import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import ProposalTableView, {ProposalItem} from './ProposalTableView';
import {useForcedContext} from '../context';
import PurchaseScreenContext from './context';
import {useRoot, useStrings} from '../Root/hooks';
import getMinerSlotPrice from './getMinerSlotPrice';
import StaticPurchaseScreenHelper from './StaticPurchaseScreenHelper';

export default observer(function MinerProductTableBinding() {
  const {
    state: {
      selectHashrate,
      selectInterval,
      selectedPaymentMethod,
      selectedHashrate,
      selectedInterval,
      selectedTab,
    },
  } = useForcedContext(PurchaseScreenContext);
  const {proposalsState, currentSubscriptionOfferService} = useRoot();
  const strings = useStrings();
  const items = useMemo(() => {
    if (!selectedHashrate) {
      return [];
    }
    const map =
      proposalsState.doubleProposalByIntervalByHashrate.get(selectedHashrate);
    if (!map) {
      return [];
    }
    return proposalsState.uniqIntervalList.map((interval) => {
      const double = map.get(interval);
      const doubleProposal = double
        ? StaticPurchaseScreenHelper.filterDoublePoolProposal(
            double,
            currentSubscriptionOfferService.currentSubscriptionOffer,
          )
        : null;
      const proposal = doubleProposal?.product || doubleProposal?.subscription;
      const payment = proposal?.offer.payments.get(selectedPaymentMethod);
      if (!proposal?.available || !payment) {
        return {
          available: false,
          price: strings['common.notAvailable'],
          duration: interval,
          helper: '',
          discounts: [],
          selected: false,
          disabled: true,
          hashrate: undefined,
        };
      }
      const {currency, uiPricePerMonthPerHashrate, discounts} = payment;
      const itemDiscounts = discounts?.map((d) => d.percent) ?? [];
      const offerHashrate = proposal.offer.poolMinerConfig.hash_rate;
      const offerInterval = proposal.offer.interval;
      const selected =
        offerHashrate === selectedHashrate &&
        offerInterval === selectedInterval;
      return {
        available: true,
        duration: interval,
        helper: `${uiPricePerMonthPerHashrate} ${currency} ${strings['common.per']} 1000 H/s`,
        price: getMinerSlotPrice(payment, strings),
        disabled: false,
        discounts: itemDiscounts,
        selected,
        hashrate: offerHashrate,
      };
    });
  }, [
    currentSubscriptionOfferService,
    proposalsState,
    selectedHashrate,
    selectedInterval,
    selectedPaymentMethod,
    strings,
  ]);
  const handleItemPress = useCallback(
    (item: ProposalItem) => {
      if (item.available && item.hashrate) {
        selectHashrate(item.hashrate);
        selectInterval(item.duration);
      }
    },
    [selectHashrate, selectInterval],
  );
  return (
    <ProposalTableView
      selectedTab={selectedTab}
      onItemPress={handleItemPress}
      items={items}
    />
  );
});
