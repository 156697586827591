import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {AccountType} from '../Auth';
import {Pressable, View, StyleSheet} from 'react-native';
import Typography from './Typography';
import {useTheme, variance} from '../styling';

import OpenEyeSvg from '../assets/svg/colorless/openEye.svg';
import CloseEyeSvg from '../assets/svg/colorless/closeEye.svg';
import {AppButton, ButtonSize, ButtonVariant} from './AppButton';
import {useRoot, useStrings} from '../Root/hooks';
import {sized} from '../Svg';

export type HeaderMenuUserInfoProps = {
  onLoginPress: () => void;
  onVerifyPress: () => void;
};

export default observer(function HeaderMenuUserInfo(
  props: HeaderMenuUserInfoProps,
) {
  const {auth} = useRoot();
  const {accountType, accountId, email} = auth;
  const {onLoginPress, onVerifyPress} = props;
  const [isEmailShow, setIsEmailShow] = useState(false);
  const strings = useStrings();

  const formattedEmail = useMemo(() => {
    if (isEmailShow) {
      return email;
    }

    return email?.replace(/\w+@+\w+/gi, email[0] + '***@***');
  }, [email, isEmailShow]);

  const theme = useTheme();

  if (accountType === AccountType.Temporary) {
    return (
      <AppButton
        variant={ButtonVariant.Primary}
        size={ButtonSize.Small}
        onPress={onLoginPress}>
        {strings['dashboard.farmInfo.signIn']}
      </AppButton>
    );
  }

  return (
    <View>
      <View style={styles.userEmail}>
        <Typography type="header" size="h5">
          {email ? (
            formattedEmail
          ) : (
            <Pressable onPress={onVerifyPress}>
              <EmailText type="body">{strings['menu.verifyEmail']}</EmailText>
            </Pressable>
          )}
        </Typography>
        {email ? (
          <Pressable onPress={() => setIsEmailShow((prev) => !prev)}>
            {isEmailShow ? (
              <View style={styles.icon}>
                <CloseEyeIcon color={theme.colors.primaryUIDirtyBlue} />
              </View>
            ) : (
              <View style={styles.icon}>
                <OpenEyeIcon color={theme.colors.primaryUIDirtyBlue} />
              </View>
            )}
          </Pressable>
        ) : null}
      </View>
      <IdText type="paragraph" weight="500">
        ID: {accountId}
      </IdText>
    </View>
  );
});

const OpenEyeIcon = sized(OpenEyeSvg, 16);
const CloseEyeIcon = sized(CloseEyeSvg, 16);

const styles = StyleSheet.create({
  loginButtonRoot: {
    alignSelf: 'flex-start',
  },
  icon: {
    marginLeft: 5,
  },
  userEmail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const IdText = variance(Typography)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
  },
}));

const EmailText = variance(Typography)(() => ({
  root: {
    fontSize: 14,
  },
}));
