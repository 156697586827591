import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View, ViewProps} from 'react-native';
import CopyPromoSection from './CopyPromoSection';
import {last} from 'lodash';
import {useRoot} from '../../../../Root/hooks';
import {CryptoLoader} from '../../../atoms/CryptoLoader';
import {useStyles} from '../../../../styling';
import PoolMiningBanner from '../PromoMiningBanner';

export type PromoBannerProps = ViewProps & {
  safelyGoToPromoAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
};

export default observer(function PromoBanner({
  safelyGoToPromoAffiliate,
  getAffiliateIsPending,
  style,
}: PromoBannerProps) {
  const {dashboardStore} = useRoot();
  const promos = dashboardStore.dashboardPromo;
  const lastPromo = last(promos.promos);
  const _renderSection = useCallback(() => {
    if (lastPromo) {
      return (
        <CopyPromoSection
          code={lastPromo.code}
          safelyGoToPromoAffiliate={safelyGoToPromoAffiliate}
          getAffiliateIsPending={getAffiliateIsPending}
        />
      );
    }
    return (
      <PoolMiningBanner
        style={styles.banner}
        onButtonPress={safelyGoToPromoAffiliate}
        buttonDisabled={getAffiliateIsPending()}
      />
    );
  }, [getAffiliateIsPending, lastPromo, safelyGoToPromoAffiliate]);
  return (
    <View style={style}>
      {promos.isLoading ? <Loader /> : _renderSection()}
    </View>
  );
});

const styles = StyleSheet.create({
  banner: {
    flex: 1,
  },
});

const Loader = observer(() => {
  const themedStyles = useStyles((theme) => ({
    container: {
      flex: 1,
    },
    root: {
      borderRadius: 2,
      flex: 1,
      backgroundColor: theme.colors.backgroundGround,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));
  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.root}>
        <CryptoLoader />
      </View>
    </View>
  );
});
