import {FRAME, QR_CODE_SECTION_RATIO} from './constants';

type Window = {
  width: number;
  height: number;
};

export default abstract class PositionHelperStatic {
  static getFrameTopPosition(window: Window) {
    return ((window.height - FRAME) / 2) * QR_CODE_SECTION_RATIO;
  }

  static getFrameBottomPosition(window: Window) {
    return PositionHelperStatic.getFrameTopPosition(window) + FRAME;
  }

  static getFrameLeftPosition(window: Window) {
    return (window.width - FRAME) / 2;
  }
}
