import {batchDisposers, Service} from '../structure';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {computed, observable} from 'mobx';
import {
  DoubleProposal,
  ProposalByInterval,
  ProposalsState,
  SETTLED,
} from '../ProposalsState';
import {InAppOffers, PaymentMethod} from '../InAppOffersService';
import {bind} from '../fp';
import {RenewAccessScreenState} from './RenewAccessScreenState';
import {Interval} from '../ApiStore';

export default abstract class BaseRenewAccessScreenStateImpl
  implements RenewAccessScreenState, Service
{
  @observable private _selectedProposal?: DoubleProposal;
  @observable proposalTable?: ProposalByInterval<DoubleProposal>;

  protected constructor(
    protected readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly proposalsState: ProposalsState;
      readonly inAppOffers: InAppOffers;
    },
    readonly workerId: string,
  ) {}
  abstract selectPaymentMethod(method: PaymentMethod): void;
  abstract get selectedPaymentMethod(): PaymentMethod;

  @computed
  get worker() {
    return this._root.dashboardStore.workers.get(this.workerId);
  }

  @computed
  get selectedProposal() {
    return this._selectedProposal;
  }

  get uniqProductIntervalList() {
    return this._root.proposalsState.uniqProductIntervalList;
  }

  @computed
  get isLoading() {
    return (
      this._root.dashboardStore.isLoading || this._root.inAppOffers.isLoading
    );
  }

  selectProposalByInterval = bind((interval: Interval) => {
    const doubleProposal = this.proposalTable?.get(interval);
    this._selectedProposal = doubleProposal;
  }, this);

  private _selectDefaultPlan() {
    const hashrate = this.worker?.pool_miner_config?.hash_rate;
    if (this.worker && hashrate !== undefined) {
      const proposalByInterval =
        this._root.proposalsState.doubleProposalByIntervalByHashrate.get(
          hashrate,
        );

      if (proposalByInterval === undefined) {
        return;
      }
      this.proposalTable = proposalByInterval;
      const availableList = [...proposalByInterval.values()].flatMap((_) => _);
      const firstAvailable = availableList.find((_) => {
        // exclude bought subscriptions
        if (!_.product && _.subscription && _.subscription.offer.bought) {
          return undefined;
        }
        return _;
      });
      if (firstAvailable) {
        this._selectedProposal = firstAvailable;
      }
    }
  }

  private _selectDefaultPlanOnLoaded() {
    if (this._root.proposalsState.isLoadedIn) {
      this._selectDefaultPlan();
    }
    return this._root.proposalsState.events.listen(SETTLED, () => {
      this._selectDefaultPlan();
    });
  }

  subscribe() {
    return batchDisposers(this._selectDefaultPlanOnLoaded());
  }
}
