import {action, computed, makeObservable, observable} from 'mobx';
import BasePurchaseScreenStateImpl from './BasePurchaseScreenStateImpl';
import {PaymentMethod} from '../InAppOffersService';
import {Auth} from '../Auth';
import {ProposalsState} from '../ProposalsState';

export default class WebPurchaseScreenStateImpl extends BasePurchaseScreenStateImpl {
  @observable _selectedPaymentMethod: PaymentMethod = PaymentMethod.Card;

  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly proposalsState: ProposalsState;
    },
  ) {
    super(_root);
    makeObservable(this);
  }

  selectPaymentMethod = action((method: PaymentMethod) => {
    this._selectedPaymentMethod = method;
  });

  get selectedPaymentMethod(): PaymentMethod {
    return this._selectedPaymentMethod;
  }

  @computed
  get selectedDoubleProposal() {
    const {doubleProposalByIntervalByHashrate} = this._root.proposalsState;
    if (
      this.selectedHashrate === undefined ||
      doubleProposalByIntervalByHashrate === undefined
    ) {
      return undefined;
    }
    return doubleProposalByIntervalByHashrate
      .get(this.selectedHashrate)
      ?.get(this.selectedInterval);
  }
}
