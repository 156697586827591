import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useForcedContext} from '../context';
import PaymentScreenContext from './context';
import FormView from './FormView';
import {useRoot, useStrings} from '../Root/hooks';

export default observer(function FormBinding() {
  const {
    order: {toAddress, amount, currency},
  } = useForcedContext(PaymentScreenContext);
  const {clipboard, flashMessages} = useRoot();
  const strings = useStrings();
  const copy = useCallback(
    (value: string) => clipboard.setString(value),
    [clipboard],
  );
  const showMessage = () => {
    flashMessages.showMessage({
      title: strings['action.theValueHasBeenCopiedToClipboard'],
      variant: 'success',
    });
  };
  const handleSumCopyPress = () => {
    copy(amount);
    showMessage();
  };
  const handleAddressCopyPress = () => {
    copy(toAddress);
    showMessage();
  };
  return (
    <FormView
      sumValue={amount}
      currency={currency}
      onCopySumPress={handleSumCopyPress}
      addressValue={toAddress}
      onCopyAddressPress={handleAddressCopyPress}
    />
  );
});
