import {AppRouteName} from '../../../Navigation/AppRouteName';
import DashboardIcon from './DashboardIcon';
import StatisticsIcon from './StatisticsIcon';
import WithdrawIcon from './WithdrawIcon';
import RingIcon from './RingIcon';
import DebugIcon from './DebugIcon';
import PlusIcon from './PlusIcon';

export default function getTabIcon(routeName: AppRouteName) {
  switch (routeName) {
    case 'Dashboard':
      return DashboardIcon;
    case 'Statistics':
      return StatisticsIcon;
    case 'MockAddWorker':
    case 'AddWorker':
      return PlusIcon;
    case 'Withdraw':
      return WithdrawIcon;
    case 'Notifications':
      return RingIcon;
    case 'Debug':
      return DebugIcon;
    default:
      return null;
  }
}
