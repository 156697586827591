export enum BannerType {
  Demo,
  Upgrade,
  Expired,
  PoolMiner,
}
export interface DashboardBannersState {
  readonly add: (banner: BannerType) => void;
  readonly delete: (banner: BannerType) => void;
  readonly targetBanner?: BannerType;
  readonly hasShownBanner: boolean;
  readonly reset: () => void;
}
