import React, {forwardRef} from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {AdaptiveActionModal} from '../components/organisms';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {AdaptiveModalRef} from '../components/templates';
import {useRoot, useStrings, useTemplates} from '../Root/hooks';
import {sized} from '../Svg';
import FlashSvg from '../assets/svg/colored/yellowFlash.svg';
import PlusSvg from '../assets/svg/colorless/plus_circled.svg';
import {createStylesHook, variance} from '../styling';
import Markdown from '../components/molecules/Markdown';
import {getCommissionerFontStyles} from '../components';
import {ISODateString} from '../Time';
import {useTimer} from 'use-timer';
import dayjs from 'dayjs';

export type PoolMinerActivatedModalProps = {
  onModalClosed: () => void;
  poolExpiredDate: ISODateString | undefined;
  poolHashrate: number | undefined;
  discountExpiredDate?: ISODateString;
  onButtonPress: () => void;
  hasDiscount?: boolean;
};

export default observer<PoolMinerActivatedModalProps, AdaptiveModalRef>(
  forwardRef(function PoolMinerActivatedModal(props, ref) {
    const {onModalClosed, onButtonPress, hasDiscount} = props;
    const styles = useStyles();
    const strings = useStrings();
    return (
      <AdaptiveActionModal
        ref={ref}
        title={strings['poolMinerActivatedModal.title']}
        titleStyle={styles.title}
        description={() => <Description {...props} />}
        IconSvg={FlashIcon}
        onModalClosed={onModalClosed}
        actions={[
          <AppButton
            Icon={PlusIcon}
            variant={ButtonVariant.Primary}
            onPress={onButtonPress}>
            {hasDiscount
              ? strings['poolMinerActivatedModal.button_discount']
              : strings['poolMinerActivatedModal.button']}
          </AppButton>,
        ]}>
        {props.discountExpiredDate !== undefined && <Message {...props} />}
      </AdaptiveActionModal>
    );
  }),
);

const Description = observer(
  ({poolHashrate, poolExpiredDate}: PoolMinerActivatedModalProps) => {
    const {
      translation: {localeTag},
    } = useRoot();
    const styles = useStyles();
    const templates = useTemplates();
    const content = templates['poolMinerActivatedModal.content']({
      poolHashrate: poolHashrate,
      expirationDate: dayjs(poolExpiredDate)
        .locale(localeTag)
        .format('DD.MM.YYYY, HH:mm'),
    });
    return (
      <MarkdownView>
        <Markdown styles={{paragraph: styles.paragraph}}>{content}</Markdown>
      </MarkdownView>
    );
  },
);

const Message = observer(
  ({discountExpiredDate}: PoolMinerActivatedModalProps) => {
    const {time} = useTimer({
      autostart: true,
      initialTime: dayjs(discountExpiredDate).utc().diff(dayjs(), 'seconds'),
      endTime: 0,
      timerType: 'DECREMENTAL',
    });
    const templates = useTemplates();
    const readableTime = dayjs.duration(time, 'seconds').format('HH:mm:ss');
    const styles = useStyles();
    const message = templates['poolMinerActivatedModal.discount']({
      time: readableTime,
    });
    return (
      <MessageView>
        <MarkdownView>
          <Markdown
            styles={{paragraph: styles.paragraph, strong: styles.strong}}>
            {message}
          </Markdown>
        </MarkdownView>
      </MessageView>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  title: {
    fontSize: 23,
    lineHeight: 28,
  },
  strong: {
    fontWeight: '600',
    ...getCommissionerFontStyles('600'),
  },
  paragraph: {
    textAlign: 'center',
    color: theme.colors.uiSecondary,
    marginTop: 0,
    marginBottom: 0,
  },
  textGroup: {
    textAlign: 'center',
  },
}));

const PlusIcon = sized(PlusSvg, 15);

const FlashIcon = sized(FlashSvg, 58);

const MessageView = variance(View)((theme) => ({
  root: {
    marginTop: 18,
    padding: 12,
    paddingTop: 10,
    borderWidth: 1,
    borderColor: theme.colors.primaryAttractive,
    borderRadius: 8,
    backgroundColor: theme
      .chroma(theme.colors.primaryAttractive)
      .alpha(0.3)
      .hex(),
  },
}));

const MarkdownView = variance(View)(() => ({
  root: {
    alignItems: 'center',
  },
}));
