import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {WorkersList} from './WorkersList';
import {View} from 'react-native';
import {getWorkerName} from '../../DashboardScreen';
import {useStrings, useTemplates} from '../../Root/hooks';
import ScheduleState from '../ScheduleState';
import {useStyles, useTheme} from '../../styling';
import {WorkerType} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {
  getCommissionerFontStyles,
  GradientDivider,
  Typography,
} from '../../components';
import {LG_BREAKPOINT} from '../../WindowDimensions/useDimensions';
import Markdown from '../../components/molecules/Markdown';

export type SchedulerHeaderProps = {
  state: ScheduleState;
  onWorkersSelectPress: () => void;
};

export default observer(function SchedulerHeader(props: SchedulerHeaderProps) {
  const {state} = props;
  const styles = useStyles((theme) => ({
    root: {
      backgroundColor: theme.colors.backgroundGround,
    },
  }));
  return (
    <View style={styles.root}>
      {state.isSingleGroup ? (
        <SingleWorkerGroupHeader {...props} />
      ) : (
        <WorkerGroupHeader {...props} />
      )}
      {state.hasPoolMiner && <SchedulerForPoolMinerBanner {...props} />}
    </View>
  );
});

const WorkerGroupHeader = observer(
  ({state, onWorkersSelectPress}: SchedulerHeaderProps) => {
    const {workers, userTimezoneMinutes, selectedWorkerIds} = state;

    const styles = useStyles((theme) => ({
      root: {
        paddingTop: 18,
        paddingBottom: 0,
        paddingHorizontal: 16,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        backgroundColor: theme.colors.uiMain,
        zIndex: 2,
        ...theme.mediaQuery({
          [LG_BREAKPOINT]: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        }),
      },
    }));

    return (
      <View style={styles.root}>
        <WorkersList
          timezoneOffset={userTimezoneMinutes}
          workers={workers}
          selectedWorkerIdList={[...selectedWorkerIds]}
          onWorkerSelect={onWorkersSelectPress}
        />
      </View>
    );
  },
);

const SingleWorkerGroupHeader = observer(({state}: SchedulerHeaderProps) => {
  const {workers, userTimezoneMinutes} = state;
  const worker = workers[0];
  const templates = useTemplates();
  const styles = useStyles((theme) => ({
    root: {
      paddingTop: 18,
      paddingBottom: 5,
      paddingHorizontal: 16,
      backgroundColor: theme.colors.uiMain,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      }),
    },
    title: {
      marginBottom: 15,
    },
    timezoneText: {
      marginTop: 5,
      color: theme.colors.primaryError,
    },
  }));
  const theme = useTheme();
  const timezoneDiff = (userTimezoneMinutes - worker.utc_offset) / 60;
  const visibleTimezoneMessage =
    worker.utc_offset !== userTimezoneMinutes &&
    worker.worker_type === WorkerType.Regular;
  const differentTimezoneStr =
    timezoneDiff < 0
      ? '+' + Math.abs(timezoneDiff)
      : '-' + Math.abs(timezoneDiff);
  const timezoneText = templates['scheduler.differentTimezone']({
    timezone: differentTimezoneStr,
  });

  const workerName = useMemo(() => getWorkerName(worker), [worker]);

  return (
    <View style={styles.root}>
      <Typography
        type={'header'}
        size={'h4'}
        weight={'500'}
        style={styles.title}>
        {workerName}
      </Typography>
      {visibleTimezoneMessage && (
        <Typography
          type={'paragraph'}
          weight={'500'}
          size={'large'}
          style={styles.timezoneText}>
          {timezoneText}
        </Typography>
      )}
      <GradientDivider
        colors={[
          theme.colors.uiGradientDivider[0],
          theme.colors.uiGradientDivider[1],
          theme.colors.uiGradientDivider[2],
        ]}
        locations={[0, 0.5, 1]}
        start={[0, 1]}
        end={[1, 1]}
      />
    </View>
  );
});

const SchedulerForPoolMinerBanner = observer(
  ({state}: SchedulerHeaderProps) => {
    const {isSingleGroup} = state;
    const styles = useStyles((theme) => ({
      root: {
        padding: 12,
        backgroundColor: theme.colors.uiMain,
      },
      card: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: theme.colors.primaryAttractive,
        backgroundColor: theme
          .chroma(theme.colors.primaryAttractive)
          .alpha(0.15)
          .hex(),
        borderRadius: 4,
        ...theme.mediaQuery({
          [LG_BREAKPOINT]: {
            alignSelf: 'flex-start',
          },
        }),
      },
      paragraph: {
        ...getCommissionerFontStyles('400'),
        color: theme.colors.uiSecondary,
        fontSize: 14,
        lineHeight: 18,
        letterSpacing: 0.037,
        marginTop: 0,
        marginBottom: 0,
      },
    }));
    const strings = useStrings();
    const text = isSingleGroup
      ? strings['scheduler.poolMinerBanner.singleGroup']
      : strings['scheduler.poolMinerBanner.group'];
    if (isSingleGroup === null) {
      return null;
    }
    return (
      <View style={styles.root}>
        <View style={styles.card}>
          <Markdown
            styles={{
              paragraph: styles.paragraph,
            }}>
            {text}
          </Markdown>
        </View>
      </View>
    );
  },
);
