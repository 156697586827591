import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet} from 'react-native';
import ColoredBanner, {
  ColoredBannerProps,
  ShadowColor,
  DefaultButton,
  GREEN_BACKGROUND,
  MONEY_ICON,
} from '../../molecules/ColoredBanner';
import DiscountSvg from '../../../assets/svg/colored/discount.svg';
import {useStrings} from '../../../Root/hooks';

export type PoolMiningBannerProps = Partial<ColoredBannerProps> & {
  onButtonPress: () => void;
  buttonDisabled?: boolean;
};

export default observer(function PoolMiningBanner({
  onButtonPress,
  buttonDisabled = false,
  ...rest
}: PoolMiningBannerProps) {
  const strings = useStrings();
  return (
    <ColoredBanner
      shadowColor={ShadowColor.Green}
      title={strings['greenBanner.title']}
      text={strings['greenBanner.description']}
      textStyle={styles.text}
      descriptionVisible={(layout) => (layout ? layout.width > 325 : false)}
      icon={MONEY_ICON}
      iconProps={{
        style: {
          transform: [{scale: 1.2}],
        },
      }}
      background={GREEN_BACKGROUND}
      actions={[
        <DefaultButton
          onPress={onButtonPress}
          title={strings['greenBanner.btnText']}
          Icon={DiscountSvg}
          disabled={buttonDisabled}
        />,
      ]}
      {...rest}
    />
  );
});

const styles = StyleSheet.create({
  text: {
    maxWidth: 270,
  },
});
