import {PurchaseId} from '../units';
import {Duration} from 'dayjs/plugin/duration';
import {CommonError, RestoreResult} from '../ApiStore';
import {Either} from '../fp';
import {ReadyState} from '../Connection';
import {Router} from '../structure';
import {
  Purchase as OriginalInAppPurchase,
  ProductPurchase,
  SubscriptionPurchase,
} from 'react-native-iap';
import {GlobalError} from '../Error';
import {DefaultError} from '../JsonRpc';
import {RequestPurchaseParams} from '../InAppPurchase';
import {Trial} from '../InAppOffersService';

export const PURCHASE_RESULT = Symbol();

export interface InAppPurchaseManager {
  readonly iapReadyState: ReadyState;
  reconnect(): Promise<void>;
  getAvailablePurchases(): Promise<Either<Purchase[], GlobalError>>;
  getOriginalAvailablePurchases(): Promise<
    Either<(OriginalInAppPurchase | SubscriptionPurchase)[], GlobalError>
  >;
  getSubscriptions(
    purchaseIds: PurchaseId[],
  ): Promise<Either<SubscriptionStorePurchase[], GlobalError>>;
  getProducts(
    purchaseIds: PurchaseId[],
  ): Promise<Either<ProductStorePurchase[], GlobalError>>;
  requestSubscription(params: RequestPurchaseParams): Promise<PurchaseResult>;
  requestProduct(params: RequestPurchaseParams): Promise<PurchaseResult>;
  authByPurchaseHistory(): Promise<
    Either<RestoreResult[], CommonError | DefaultError | GlobalError>
  >;
  readonly events: Router<{
    [PURCHASE_RESULT]: PurchaseResult;
  }>;
}

export type OriginalPurchase = ProductPurchase & SubscriptionPurchase;

export type Purchase = {
  purchaseId: PurchaseId;
};

export type UnacknowledgedPurchase = OriginalPurchase & {
  isAcknowledgedAndroid: false;
  signatureAndroid: string;
};

export type AcknowledgedProduct = OriginalPurchase & {
  isAcknowledgedAndroid: true;
  purchaseToken: string;
  autoRenewingAndroid: undefined;
  signatureAndroid: string;
};

export type BaseStorePurchase = {
  purchaseId: PurchaseId;
  price: number;
  currency: string;
};

export type SubscriptionStorePurchase = BaseStorePurchase & {
  period?: Duration;
  trial: Trial | null;
  subscriptionOfferTokenAndroid: string | undefined;
};

export type ProductStorePurchase = BaseStorePurchase & {
  title: string;
  description: string;
};

export type PurchaseResult = Either<OriginalInAppPurchase, GlobalError>;
