import {PointHourUnit} from './types';

export default function pointHourUnitListToMinutes(
  pointHourUnitList: PointHourUnit[],
): number[] {
  let minuteList: number[] = [];
  pointHourUnitList.forEach((point) => {
    minuteList = [...minuteList, ...Array(point.length).fill(point.speed)];
  });
  return minuteList;
}
