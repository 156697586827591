import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {QRCodeScannerScreen} from '../../QRCodeScannerScreen';
import {
  BarcodeScanningResult,
  PermissionStatus,
  PermissionResponse,
  useCameraPermissions,
} from 'expo-camera';
import {
  useNavigationGetIsFocused,
  useNavigationGetIsTransitioning,
  useSafelyGoBack,
} from '../hooks';
import {reaction} from 'mobx';
import {APP_WINDOW_ACTIVE} from '../../AppWindow';
import {useRoot} from '../../Root/hooks';

export type QRCodeScannerBindingProps =
  RootStackBindingProps<'QRCodeScanner'> & {};

export default observer((props: QRCodeScannerBindingProps) => {
  const {navigation} = props;
  const safelyGoBack = useSafelyGoBack();
  const getIsTransitioning = useNavigationGetIsTransitioning(navigation);
  const getIsFocused = useNavigationGetIsFocused();
  const {appWindow} = useRoot();
  const [cameraPermissions, setCameraPermissions] =
    useState<PermissionResponse>();
  const [, requestCameraPermissionsAsync, getCameraPermissionsAsync]  = useCameraPermissions();
  const requestPermission = useCallback(async () => {
    try {
      const response = await requestCameraPermissionsAsync();
      setCameraPermissions(response);
    } catch (ignore) {}
  }, [requestCameraPermissionsAsync]);
  useEffect(
    () =>
      reaction(
        () => getIsFocused(),
        async (isFocused) => {
          if (isFocused) {
            try {
              const response = await getCameraPermissionsAsync();
              setCameraPermissions(response);
              if (
                response.canAskAgain &&
                response.status === PermissionStatus.UNDETERMINED
              ) {
                await requestPermission();
              }
            } catch (ignore) {}
          }
        },
        {fireImmediately: true},
      ),
    [getCameraPermissionsAsync, getIsFocused, requestPermission],
  );
  useEffect(
    () =>
      appWindow.updates.listen(APP_WINDOW_ACTIVE, async () => {
        try {
          const response = await getCameraPermissionsAsync();
          setCameraPermissions(response);
        } catch (ignore) {}
      }),
    [getCameraPermissionsAsync, appWindow],
  );

  const onBarCodeScanned = useCallback(
    (scanningResult: BarcodeScanningResult) => {
      const url = scanningResult.data;
      const key = url.split('?key=')[1];
      if (key) {
        navigation.navigate('LinkWorkerConfirm', {linkKey: key});
      }
    },
    [navigation],
  );
  return (
    <QRCodeScannerScreen
      goBack={safelyGoBack}
      getIsTransitioning={getIsTransitioning}
      onBarCodeScanned={onBarCodeScanned}
      cameraPermissions={cameraPermissions}
      requestPermission={requestPermission}
    />
  );
});
