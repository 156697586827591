import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {AppButton, ButtonVariant} from '../components/AppButton';
import {sized} from '../Svg';
import ChangeSvg from '../assets/svg/colorless/change.svg';
import {variance} from '../styling';
import {useStrings} from '../Root/hooks';

export type ActionsProps = {
  onSwitchFarmPress: () => void;
  onConfirmPress: () => void;
  onCancelPress: () => void;
  disabledLinkButton: boolean;
};

export default observer(function Actions({
  onConfirmPress,
  onCancelPress,
  onSwitchFarmPress,
  disabledLinkButton,
}: ActionsProps) {
  const strings = useStrings();
  return (
    <RootView>
      <ChangeView>
        <AppButton onPress={onSwitchFarmPress} Icon={ChangeIcon}>
          {strings['action.switchFarm']}
        </AppButton>
      </ChangeView>
      <ActionsView>
        <CancelView>
          <AppButton onPress={onCancelPress}>
            {strings['action.cancel']}
          </AppButton>
        </CancelView>
        <AddView>
          <AppButton
            disabled={disabledLinkButton}
            onPress={onConfirmPress}
            variant={ButtonVariant.Primary}>
            {strings['action.add']}
          </AppButton>
        </AddView>
      </ActionsView>
    </RootView>
  );
});

const ChangeIcon = sized(ChangeSvg, 17);

const RootView = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingTop: 12,
  },
}));

const ChangeView = variance(View)(() => ({
  root: {
    marginBottom: 12,
  },
}));

const ActionsView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const CancelView = variance(View)(() => ({
  root: {
    marginRight: 6,
    flex: 1,
  },
}));

const AddView = variance(View)(() => ({
  root: {
    marginLeft: 6,
    flex: 1,
  },
}));
