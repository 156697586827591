import React from 'react';
import {Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';
import {getCommissionerFontStyles} from '../components';

export interface DefaultEnvironmentItemProps {
  selected: boolean;
  url: string;
  onPress: () => void;
  right?: React.ReactNode;
}

export default observer((props: DefaultEnvironmentItemProps) => {
  const {selected, url, onPress, right} = props;
  const styles = useStyles((theme) => ({
    root: {
      marginEnd: 16,
      flexDirection: 'row',
      alignItems: 'center',
    },
    url: {
      flex: 1,
      padding: 16,
      ...getCommissionerFontStyles('400'),
      color: theme.colors.uiSecondary,
    },
    urlSelected: {
      ...getCommissionerFontStyles('700'),
      color: theme.colors.primaryActive,
    },
  }));
  return (
    <View style={styles.root}>
      <Text
        style={[styles.url, selected && styles.urlSelected]}
        onPress={onPress}
        numberOfLines={1}
        ellipsizeMode="tail">
        {url}
      </Text>
      {right}
    </View>
  );
});
