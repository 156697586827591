import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import LottieView from 'lottie-react-native';
import {Gift} from '../../InAppOffersService';
import {useStrings} from '../../Root/hooks';
import {sized} from '../../Svg';
import {useTheme, variance} from '../../styling';
import {Body} from '../../components';
import GiftSvg from '../../assets/svg/colorless/gift-rounded.svg';
import GiftDoubleSvg from '../../assets/svg/colorless/gift-double.svg';

export type PurchaseGiftSectionProps = {
  gift: Gift | undefined;
  isLoading: boolean;
};

export const ROOT_HEIGHT = 50;

export default observer(function PurchaseGiftSection({
  gift,
  isLoading,
}: PurchaseGiftSectionProps) {
  const strings = useStrings();
  const theme = useTheme();
  const {name, isDouble} = useMemo(
    () => ({
      name: gift?.pool_miner_config?.name || gift?.plan?.name,
      isDouble: gift?.type === 'double',
    }),
    [gift],
  );
  if (isLoading) {
    return (
      <LoaderContentView>
        <LottieWrapperView>
          <LottieView
            style={styles.lottie}
            webStyle={styles.lottie}
            autoPlay
            loop
            source={require('../../assets/lottie/roll_dice.json')}
          />
        </LottieWrapperView>
        <BaseContentText type="body">
          {strings['purchase.gift.fetching']}
        </BaseContentText>
      </LoaderContentView>
    );
  }
  if (!name) {
    return (
      <GiftIsNotAvailableView>
        <BaseContentText type="body">
          {strings['purchase.gift.notAvailable']}
        </BaseContentText>
      </GiftIsNotAvailableView>
    );
  }
  return (
    <ContentView>
      <View>
        <Label type="body" weight="500">
          {strings['purchase.gift']}
        </Label>
        <Name type="body" weight="500" ellipsizeMode="tail" numberOfLines={1}>
          {name}
        </Name>
      </View>
      {isDouble ? (
        <GiftDoubleIcon color={theme.colors.primaryError} />
      ) : (
        <GiftIcon color={theme.colors.primarySuccess} />
      )}
    </ContentView>
  );
});

const GiftIcon = sized(GiftSvg, 24, 24);
const GiftDoubleIcon = sized(GiftDoubleSvg, 24, 24);

const styles = StyleSheet.create({
  lottie: {
    width: 32,
    height: 42,
  },
});

const BaseContentView = variance(View)(() => ({
  root: {
    height: ROOT_HEIGHT,
    paddingVertical: 7,
    paddingHorizontal: 12,
    borderRadius: 8,
    flexDirection: 'row',
  },
}));

const ContentView = variance(BaseContentView)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryWhite,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const LoaderContentView = variance(BaseContentView)((theme) => ({
  root: {
    backgroundColor: theme.colors.primaryWhite,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const GiftIsNotAvailableView = variance(BaseContentView)((theme) => ({
  root: {
    backgroundColor: theme.chroma(theme.colors.primaryWhite).darken(0.1).hex(),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LottieWrapperView = variance(View)(() => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
  },
}));

const BaseContentText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryBlack,
  },
}));

const Label = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 12,
    lineHeight: 15,
  },
}));

const Name = variance(Body)((theme) => ({
  root: {
    fontSize: 16,
    lineHeight: 20,
    color: theme.colors.primaryBlack,
  },
}));
