import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {
  DimensionValue,
  Platform,
  Text,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';

import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';
import {Slider} from '@miblanchard/react-native-slider';
import {createStylesHook, useTheme} from '../styling';
import {sized} from '../Svg';
import {getCommissionerFontStyles, Header} from './Typography';

export type UnitSliderProps = ViewProps & {
  label?: string;
  unit?: string;
  minimumValue: number;
  maximumValue: number;
  step?: number;
  getValue?: () => number;
  onValueChange?: (value: number) => void;
  onSlidingComplete?: (value: number) => void;
  UnitIcon?: React.ComponentType<any>;
  scaling?: ScalingValue;
  helperText?: string | null;
  pointer?: number;
};

export enum ScalingValue {
  One = 1,
  Thousand = 1000,
}

/**
 * @deprecated
 */
export default observer((props: UnitSliderProps) => {
  const {label, unit, UnitIcon, style, helperText, ...rest} = props;
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={[styles.root, style]} {...rest}>
      {label && (
        <View style={styles.labelRow}>
          <View style={styles.labelMarker}>
            <ChevronRightIcon color={theme.colors.primaryNew} />
          </View>
          <Text style={styles.labelText}>{label}</Text>
        </View>
      )}
      <View style={styles.valueRow}>
        <View style={styles.valueView}>
          <ValueBinding {...props} />
        </View>
        <View style={styles.unitView}>
          {UnitIcon && <UnitIcon />}
          <Header type="header" size="h4" weight="500">
            {unit}
          </Header>
        </View>
        {helperText ? (
          <View style={styles.helperView}>
            <Text style={styles.helperText}>{helperText}</Text>
          </View>
        ) : null}
      </View>
      <SliderBinding {...props} />
      <PointerBinding {...props} />
    </View>
  );
});

const ChevronRightIcon = sized(ChevronRightSvg, 13);

const PointerBinding = observer((props: UnitSliderProps) => {
  const {pointer, minimumValue, maximumValue} = props;
  const styles = useStyles();
  if (
    pointer === undefined ||
    maximumValue === undefined ||
    minimumValue === undefined
  ) {
    return null;
  }
  const left = ((pointer - minimumValue) / (maximumValue - minimumValue)) * 100;
  return (
    <View style={styles.triangleView}>
      <View
        style={[
          styles.triangle,
          {
            left: (left + '%') as DimensionValue,
          },
        ]}
      />
    </View>
  );
});

const SliderBinding = observer((props: UnitSliderProps) => {
  const {
    getValue,
    minimumValue,
    maximumValue,
    step,
    onValueChange,
    onSlidingComplete,
  } = props;
  const theme = useTheme();
  const styles = useStyles();
  const value = getValue?.();
  const handleChange = useCallback(
    (_: number | number[]) => {
      if (Array.isArray(_)) {
        onValueChange?.(_[0]);
      }
    },
    [onValueChange],
  );
  const handleSlidingComplete = useCallback(
    (_: number | number[]) => {
      if (Array.isArray(_)) {
        onSlidingComplete?.(_[0]);
      }
    },
    [onSlidingComplete],
  );
  return (
    <Slider
      minimumTrackTintColor={theme.colors.primaryAttractive}
      maximumTrackTintColor={theme.colors.uiAdditional3}
      thumbTintColor={theme.colors.uiMain}
      thumbStyle={styles.thumb}
      trackStyle={styles.track}
      containerStyle={styles.sliderContainer}
      value={value}
      minimumValue={minimumValue}
      maximumValue={maximumValue}
      step={step}
      onValueChange={handleChange}
      onSlidingComplete={handleSlidingComplete}
    />
  );
});

const ValueBinding = observer((props: UnitSliderProps) => {
  const {getValue} = props;
  const realValue = getValue?.();
  if (realValue !== undefined) {
    let value;
    let unit = '';
    switch (props.scaling) {
      case ScalingValue.Thousand:
        if (realValue > 1000) {
          value = realValue / 1000;
          unit = 'k';
        } else {
          value = realValue;
        }
        break;
      case ScalingValue.One:
      default:
        value = realValue;
    }
    return (
      <Header type="header" size="h4" weight="500">
        {value?.toFixed(0)}
        {unit}
      </Header>
    );
  }
  return null;
});

const useStyles = createStylesHook((theme) => ({
  root: {},
  labelRow: {
    flexDirection: 'row',
  },
  labelMarker: {
    alignSelf: 'center',
    marginStart: -0.5,
    marginEnd: 9.5,
  },
  labelText: {
    flexGrow: 1,
    flexShrink: 1,
    ...getCommissionerFontStyles('400'),
    fontSize: 16,
    lineHeight: 22,
    color: theme.colors.uiSecondary,
  },
  track: {
    height: 6,
  },
  sliderContainer: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      } as ViewStyle,
      default: {},
    }),
  },
  thumb: {
    width: 18,
    height: 18,
    borderWidth: 2,
    borderColor: theme.colors.primaryAttractive,
  },
  valueView: {
    flexBasis: '50%',
    borderRightWidth: 1,
    borderRightColor: theme.colors.uiAdditional2,
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  valueRow: {
    flexDirection: 'row',
    alignItems: 'stretch',
    maxWidth: 120,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.colors.uiAdditional2,
  },
  unitView: {
    flexBasis: '50%',
    backgroundColor: theme.colors.uiAdditional1,
    flexShrink: 1,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  helperView: {},
  helperText: {
    ...getCommissionerFontStyles('400'),
    color: theme.colors.primaryUIDirtyBlue,
    fontSize: 10,
    lineHeight: 11,
  },
  triangleView: {
    marginBottom: 5,
    marginTop: -5,
  },
  triangle: {
    width: 0,
    transform: [{translateX: -5}],
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 5,
    borderBottomWidth: 8,
    borderLeftWidth: 5,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: theme.colors.uiAdditional1,
    borderLeftColor: 'transparent',
  },
}));
