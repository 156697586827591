import React from 'react';
import {observer} from 'mobx-react-lite';
import RateAppContent from './RateApp';
import CommentContent from './CommentContentView';
import RateUsRedirectContent from './RateUsRedirectContentView';
import ThanksContent from './ThanksContent';
import {StateVisibleContent} from './types';
import {useForcedContext} from '../context';
import {RateAppModalizeContext} from './context';
import useRepresentation from '../components/templates/AdaptiveModal/useRepresentation';
import {Representation} from '../components/templates';
import {BottomSheetTextInput} from '@gorhom/bottom-sheet';
import {expr} from 'mobx-utils';

export default observer(function MainContentView() {
  const {
    dismiss,
    visibleContent,
    goToRateUs,
    goToComment,
    goToThanks,
    selectedRating,
  } = useForcedContext(RateAppModalizeContext);
  const representation = useRepresentation();
  const TextInputComponent = expr(() =>
    representation === Representation.BottomSheet
      ? BottomSheetTextInput
      : undefined,
  );
  switch (visibleContent) {
    case StateVisibleContent.Rate:
      return (
        <RateAppContent
          dismiss={dismiss}
          goToComment={goToComment}
          goToRateUs={goToRateUs}
        />
      );
    case StateVisibleContent.Comment:
      return (
        <CommentContent
          onClose={dismiss}
          rating={selectedRating}
          goToThanks={goToThanks}
          TextInputComponent={TextInputComponent}
        />
      );
    case StateVisibleContent.RateUs:
      return (
        <RateUsRedirectContent onClose={dismiss} rating={selectedRating} />
      );
    case StateVisibleContent.Thanks:
      return <ThanksContent onSubmit={dismiss} />;
    default:
      console.warn('visibleContent is null');
      return null;
  }
});
