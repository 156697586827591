import {Url} from '../units';

export default {
  overview: 'https://cryptotabfarm.com/',
  faq: 'https://cryptotabfarm.com/faq/',
  cryptotab: 'https://cryptobrowser.site/cryptotab/',
  affiliate: 'https://cryptobrowser.site/affiliate/',
  terms: 'https://cryptotabfarm.com/terms/',
  termsAffiliate: 'https://cryptotabfarm.com/terms/affiliate',
  privacy: 'https://cryptotabfarm.com/privacy/',
  cookie: 'https://cryptotabfarm.com/cookie/',
  advertise:
    'https://docs.google.com/forms/d/e/1FAIpQLSdl5hATDzld10NzUeKvUtzauFfnh14cLE4X9rNVTVOfS-Ts5A/viewform',
  cryptotabBrowser: 'https://cryptobrowser.site/',
  orangepie: 'https://orangepie.biz/en/',
  cryptotabStart: 'https://start.cryptobrowser.site/',
  ctVpn: 'https://ct-vpn.com/',
  feedback: 'https://cryptotabfarm.com/feedback',
  mail: 'contactus@cryptotabfarm.com',
  playStoreWebUrl:
    'https://play.google.com/store/apps/details?id=com.ctfarm&referrer=utm_source%3Dspro%26utm_medium%3Dwebdash' as Url,

  facebook: 'https://www.facebook.com/CryptoTabFamilyOfficial',
  telegram: 'https://t.me/CryptoTabChannel',
  twitter: 'https://twitter.com/CryptoTabnet',
  instagram: 'https://www.instagram.com/officialcryptotab/',
  youtube: 'https://www.youtube.com/channel/UCHeEoD-Vag9EyZfZ7G8MByA',
  vkontakte: 'https://vk.com/public162654141',
};
