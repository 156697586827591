import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {timezones as timezonesMap} from '../ScheduleScreen/constant';
import {FlatListProps, ListRenderItem, StyleSheet} from 'react-native';
import TimezoneListItem from './TimezoneListItem';
import {Divider} from '../components/atoms';
import {AdaptiveFlatList} from '../components/templates/AdaptiveModal';

export type TimezoneListProps = {
  timezones: typeof timezonesMap;
  onSelect: (offset: number) => void;
};

type Item = {name: string; offset: number};

const keyExtractor: ListProps['keyExtractor'] = (item) =>
  item.offset.toString();
type ListProps = FlatListProps<Item>;

export default observer(function TimezoneList(props: TimezoneListProps) {
  const {timezones, onSelect} = props;

  const renderItem: ListRenderItem<Item> = useCallback(
    ({item}) => <TimezoneListItem timezone={item} onSelect={onSelect} />,
    [onSelect],
  );

  return (
    <AdaptiveFlatList
      data={timezones}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={Divider}
      contentContainerStyle={styles.container}
    />
  );
});

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
});
