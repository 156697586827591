import React from 'react';
import {observer} from 'mobx-react-lite';
import TabsView from './TabsView';
import {useRoot} from '../Root/hooks';
import {useForcedContext} from '../context';
import PurchaseScreenContext from './context';

export default observer(function Tabs() {
  const {currentActivatorSubscription} = useRoot();
  const {state} = useForcedContext(PurchaseScreenContext);
  return (
    <TabsView
      onChange={state.selectTab}
      selectedTab={state.selectedTab}
      reactivationBadgeVisible={
        currentActivatorSubscription.current === undefined
      }
    />
  );
});
