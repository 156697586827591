import {View, ViewProps} from 'react-native';
import React, {FC} from 'react';
import {SvgProps} from 'react-native-svg';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';
import {Typography} from '../components';

export interface MarkedLabelProps extends ViewProps {
  Mark: FC<SvgProps>;
  label: string;
}

export default observer((props: MarkedLabelProps) => {
  const {Mark, label, style, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    label: {
      flexShrink: 1,
      color: theme.colors.primaryUIDirtyBlue,
      marginLeft: 5,
      fontSize: 12,
      lineHeight: 20,
      letterSpacing: 0.0371429,
    },
  }));
  return (
    <View style={[styles.root, style]} {...rest}>
      <Mark />
      <Typography type={'paragraph'} size={'regular'} style={styles.label}>
        {label}
      </Typography>
    </View>
  );
});
