import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {sized} from '../Svg';
import CaretDownSvg from '../assets/svg/colorless/caretDown.svg';
import {Typography} from '../components';
import {UniversalColor} from '../color';

export type SpeedToggleSelectProps = {
  label: string;
  color?: string;
  selected?: boolean;
  keepUnselected?: boolean;
  onPress: () => void;
  onSelect: () => void;
};

export default observer(function SpeedToggleSelect(
  props: SpeedToggleSelectProps,
) {
  const {
    label,
    color,
    selected,
    keepUnselected = false,
    onPress,
    onSelect,
  } = props;
  const theme = useTheme();

  const selectedStyles = useMemo(() => {
    const selectedBackground = selected ? color : 'transparent';
    const selectedBorderColor = selected
      ? color
      : theme
          .chroma(color as UniversalColor)
          .alpha(0.35)
          .hex();
    const labelColor = selected ? theme.colors.primaryWhite : color;

    return StyleSheet.create({
      root: {
        borderColor: keepUnselected
          ? theme.colors.uiAdditional1
          : selectedBorderColor,
        backgroundColor: keepUnselected
          ? theme.colors.uiAdditional1
          : selectedBackground,
      },
      label: {
        color: keepUnselected ? theme.colors.primaryWhite : labelColor,
      },
    });
  }, [theme, selected, color, keepUnselected]);

  return (
    <SpeedToggleSelectView style={selectedStyles.root}>
      <SpeedToggleSelectLabel onPress={onPress}>
        <Typography
          type={'paragraph'}
          weight={'500'}
          style={selectedStyles.label}>
          {label}
        </Typography>
      </SpeedToggleSelectLabel>
      <CaretDownPressable onPress={onSelect}>
        <CaretDownIcon color={theme.colors.primaryUIDirtyBlue} />
      </CaretDownPressable>
    </SpeedToggleSelectView>
  );
});

const CaretDownIcon = sized(CaretDownSvg, 10);

const SpeedToggleSelectView = variance(View)((theme) => ({
  root: {
    flexBasis: 100,
    minHeight: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: theme.chroma(theme.colors.uiAdditional1).alpha(0.75).hex(),
    overflow: 'hidden',
  },
}));

const SpeedToggleSelectLabel = variance(TouchableOpacity)(() => ({
  root: {
    flex: 1,
    alignItems: 'center',
  },
}));

const CaretDownPressable = variance(TouchableOpacity)((theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    borderRadius: 20,
    backgroundColor: theme.colors.uiAdditional1,
  },
}));
