import {batchDisposers, Disposer, Service} from '../structure';
import {Auth} from '../Auth';
import {observable, reaction, runInAction, makeObservable} from 'mobx';
import {FarmId, GroupId} from '../ApiStore';
import {DASHBOARD_SORT, define} from '../persistence';
import {Dashboard, WorkerOrder} from './Dashboard';
import {DashboardBannersState} from './DashboardBannersState';
import DashboardBannersStateImpl from './DashboardBannersStateImpl';
import {bind} from '../fp';

export default class DashboardStateService implements Dashboard, Service {
  @observable private _order: WorkerOrder = WorkerOrder.Default;
  @observable private _collapsedGroups: GroupId[] = [];

  private readonly _bannersState: DashboardBannersState;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
    },
  ) {
    makeObservable(this);
    this._bannersState = new DashboardBannersStateImpl(_root);
  }

  get bannersState(): DashboardBannersState {
    return this._bannersState;
  }

  get order(): WorkerOrder {
    return this._order;
  }
  get collapsedGroups(): GroupId[] {
    return this._collapsedGroups;
  }

  getOrder = bind(() => {
    return this._order;
  });

  getCollapsedGroups = bind(() => {
    return this._collapsedGroups;
  });

  setOrder = bind((order: WorkerOrder) => {
    runInAction(() => (this._order = order));
    // noinspection JSIgnoredPromiseFromCall
    this._persist();
  });

  private _toggleCollapseGroup(group: GroupId) {
    if (this.collapsedGroups.includes(group)) {
      this.expandGroup(group);
    } else {
      this.collapseGroup(group);
    }
  }
  toggleCollapseGroup = (group: GroupId) => this._toggleCollapseGroup(group);

  collapseGroup = bind((group: GroupId) => {
    runInAction(() => this._collapsedGroups.push(group));
    // noinspection JSIgnoredPromiseFromCall
    this._persist();
  });

  expandGroup = bind((group: GroupId) => {
    runInAction(() => {
      const index = this._collapsedGroups.indexOf(group);
      if (index > -1) {
        this._collapsedGroups.splice(index, 1);
      }
    });
    // noinspection JSIgnoredPromiseFromCall
    this._persist();
  });

  private async _persist() {
    if (this._root.auth.accountId !== undefined) {
      await setSortingParams({
        farmId: this._root.auth.accountId,
        order: this._order,
        collapsedGroups: this._collapsedGroups,
      });
    }
  }

  private _restoreSortWhenFarmChange() {
    return reaction(
      () => this._root.auth.accountId,
      async () => {
        const response = await getDashboardParams();
        if (response.success && response.right) {
          const order = response.right.order;
          const collapsedGroups = response.right.collapsedGroups ?? [];
          runInAction(() => {
            this._order = order;
            this._collapsedGroups = collapsedGroups;
          });
        }
      },
    );
  }

  private _resetOnChangeFarm() {
    return reaction(
      () => this._root.auth.accountId,
      () => {
        this.bannersState.reset();
      },
    );
  }

  subscribe(): Disposer | void {
    return batchDisposers(
      this._restoreSortWhenFarmChange(),
      this._resetOnChangeFarm(),
    );
  }
}

export type ParamsRecord = {
  farmId: FarmId;
  order: WorkerOrder;
  collapsedGroups: GroupId[];
};

const [getDashboardParams, setSortingParams] =
  define<ParamsRecord>(DASHBOARD_SORT);

export {getDashboardParams, setSortingParams};
