import React, {forwardRef, useCallback, useImperativeHandle} from 'react';
import {observer} from 'mobx-react-lite';
import Switch from '../Switch';
import {useRoot} from '../../Root/hooks';
import {useTheme} from '../../styling';
import {ThemeKind} from '../../Appearance';
import SunSvg from '../../assets/svg/colored/sun.svg';
import MoonSvg from '../../assets/svg/colored/moon.svg';
import {sized} from '../../Svg';
import {SwitchProps} from 'react-native-switch';

export type ThemeSwitchRef = {
  toggle: () => void;
};

export default observer<SwitchProps, ThemeSwitchRef>(
  forwardRef(function ThemeSwitch(props, ref) {
    const {
      appearance: {isDark, setThemeMode},
    } = useRoot();
    const theme = useTheme();
    const toggle = useCallback(() => {
      isDark ? setThemeMode(ThemeKind.Light) : setThemeMode(ThemeKind.Dark);
    }, [isDark, setThemeMode]);
    useImperativeHandle(ref, () => ({
      toggle: toggle,
    }));
    const renderText = () => (isDark ? <MoonIcon /> : <SunIcon />);
    const backgroundColor = theme.colors.uiAdditional4;
    const color = theme.colors.uiMain;
    return (
      <Switch
        value={isDark}
        onValueChange={toggle}
        backgroundInactive={backgroundColor}
        backgroundActive={backgroundColor}
        circleActiveColor={color}
        circleInActiveColor={color}
        renderInsideCircle={renderText}
        {...props}
      />
    );
  }),
);

const SunIcon = sized(SunSvg, 13);
const MoonIcon = sized(MoonSvg, 12);
