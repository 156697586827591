import React from 'react';
import {observer} from 'mobx-react-lite';
import {Typography} from '../../components';
import {createStylesHook} from '../../styling';
import {useStrings} from '../../Root/hooks';
import {View} from 'react-native';
import LoaderIndicator from '../../components/LoaderIndicator';

export default observer(function ConfirmingView() {
  const styles = useStyles();
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <Typography type="header" size="h5" style={styles.text}>
        {strings['puzzle.slider.confirming']}
      </Typography>
      <LoaderIndicator />
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.primarySuccess,
    borderRadius: 8,
  },
  text: {
    color: theme.colors.primaryWhite,
    marginRight: 10,
  },
}));
