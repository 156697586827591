import {observer} from 'mobx-react-lite';
import React, {PropsWithChildren} from 'react';
import {View, ViewProps} from 'react-native';
import {
  LiteralBreakpoint,
  useGetIsDimensions,
} from '../../WindowDimensions/useDimensions';
import {variance} from '../../styling';
import {expr} from 'mobx-utils';

export type BannerListProps = ViewProps & {
  largeBreakpoint?: LiteralBreakpoint;
};

export default observer(function BannerList({
  children,
  largeBreakpoint = 'lg',
  ...rest
}: PropsWithChildren<BannerListProps>) {
  const list = React.Children.toArray(children);
  const getIsDimensions = useGetIsDimensions(largeBreakpoint);
  const isLarge = expr(() => getIsDimensions());
  return (
    <BannerListView large={isLarge} {...rest}>
      {list.map((child, index) => {
        const isLast = index !== list.length - 1;
        return (
          <BannerContainerView
            large={isLarge}
            key={index}
            offset={isLast}
            largeOffset={isLarge && isLast}>
            {child}
          </BannerContainerView>
        );
      })}
    </BannerListView>
  );
});

const BannerListView = variance(View)(() => ({
  root: {
    width: '100%',
  },
  large: {
    flexDirection: 'row',
  },
}));

const BannerContainerView = variance(View)(() => ({
  root: {},
  large: {
    flex: 1,
  },
  offset: {
    marginBottom: 16,
  },
  largeOffset: {
    marginRight: 16,
    marginBottom: 0,
  },
}));
