import React from 'react';
import {observer} from 'mobx-react-lite';
import {Paragraph} from './Typography';
import {variance} from '../styling';
import {StyleProp, View, ViewStyle} from 'react-native';
import PressableOpacity, {PressableOpacityProps} from './PressableOpacity';

export type TooltipProps = PressableOpacityProps & {
  text: string;
  containerStyle?: StyleProp<ViewStyle>;
};

export default observer(function Tooltip({
  text,
  style,
  containerStyle,
  ...rest
}: TooltipProps) {
  return (
    <TooltipRootView style={style}>
      <PressableOpacity activeOpacity={1} {...rest}>
        <TooltipView style={containerStyle}>
          <Paragraph type="paragraph">{text}</Paragraph>
        </TooltipView>
      </PressableOpacity>
    </TooltipRootView>
  );
});

const TooltipRootView = variance(View)(() => ({
  root: {
    position: 'absolute',
  },
}));

const TooltipView = variance(View)((theme) => ({
  root: {
    width: 300,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.uiMain,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.colors.uiAdditional2,
    paddingVertical: 3,
    paddingHorizontal: 7,
    color: theme.colors.uiMain,
    elevation: 2,
    zIndex: 2,
  },
}));
