import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import AffiliateBannerContainer from './AffiliateBannerContainer';
import {
  Animated,
  View,
  StyleSheet,
  Image,
  Pressable,
  Platform,
} from 'react-native';
import ChevronRightSvg from '../../../../assets/svg/colorless/chevronRight.svg';
import CloseSvg from '../../../../assets/svg/colorless/close.svg';
import BGSVG from '../../../../assets/banners/backgrounds/golden.svg';
import {useTheme, variance} from '../../../../styling';
import {AFF_COINS_ICON} from '../../../molecules/ColoredBanner';
import {Body} from '../../../Typography';
import {sized} from '../../../../Svg';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {
  LG_BREAKPOINT,
  useGetIsDimensions,
} from '../../../../WindowDimensions/useDimensions';
import {useOpenLink} from '../../../../Links';
import {useRoot, useStrings} from '../../../../Root/hooks';

export const AFFILIATE_BANNER_HEIGHT = 72;

export type AffiliateBannerProps = {
  onClosed: () => void;
  visible: boolean;
};

export default observer(function AffiliateBanner({
  visible,
  onClosed,
}: AffiliateBannerProps) {
  const {
    configuration: {values},
  } = useRoot();
  const strings = useStrings();
  const isLg = useGetIsDimensions('lg');
  const theme = useTheme();
  const hideBanner = () => onClosed();
  const openUrl = useOpenLink(values.cryptoBrowserAppFarmAboutUrl);
  const RightAction = useMemo(
    () => (progress: Animated.AnimatedInterpolation<number>) => {
      const trans = progress.interpolate({
        inputRange: [-1, 1],
        outputRange: [0, 1],
      });
      return (
        <Hide style={{opacity: trans}}>
          <TouchableOpacity
            onPress={onClosed}
            hitSlop={{top: 5, right: 5, left: 5, bottom: 5}}>
            <CrossIcon color={theme.colors.uiSecondary} />
          </TouchableOpacity>
        </Hide>
      );
    },
    [onClosed, theme],
  );

  if (!visible) {
    return null;
  }

  return (
    <View style={{height: AFFILIATE_BANNER_HEIGHT}}>
      <RootView>
        <ContentView>
          <AffiliateBannerContainer rightSwipe={RightAction}>
            <BgContainer>
              <Bg />
            </BgContainer>
            <Content>
              <Icon source={AFF_COINS_ICON} resizeMode="contain" />
              <TextContainer onPress={openUrl}>
                <ColorText type="body" weight="700" large={isLg()}>
                  {strings['affiliateBanner.title']}
                </ColorText>
                {isLg() && (
                  <ColorText type="body" largeDescription>
                    {strings['affiliateBanner.description']}
                  </ColorText>
                )}
                <ArrowIcon color={theme.colors.primaryWhite} />
              </TextContainer>
              {isLg() && (
                <CrossContainer onPress={hideBanner}>
                  <CrossIcon color={theme.colors.primaryWhite} />
                </CrossContainer>
              )}
            </Content>
          </AffiliateBannerContainer>
        </ContentView>
      </RootView>
    </View>
  );
});

const ArrowIcon = sized(ChevronRightSvg, 6, 9);
const CrossIcon = sized(CloseSvg, 9, 9);

const RootView = variance(View)(() => ({
  root: {
    marginBottom: 18,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    borderRadius: 8,
    ...Platform.select({
      ios: {
        shadowColor: '#FFD131',
        shadowOffset: {
          width: 0,
          height: 6,
        },
        shadowOpacity: 0.4,
        shadowRadius: 8,
      },
      web: {
        boxShadow: '0px 18px 24px -8px rgba(255, 209, 49, 0.3)',
      },
    }),
  },
}));

const Hide = variance(Animated.View)((theme) => ({
  root: {
    background: theme.colors.uiAdditional1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: 60,
    height: 46,
    marginTop: 8,
    paddingLeft: 10,
    paddingRight: 18,
    marginLeft: -18,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
}));

const CrossContainer = variance(TouchableOpacity)(() => ({
  root: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 'auto',
    marginRight: 16,
  },
}));

const Icon = variance(Image)(() => ({
  root: {
    width: 80,
    height: 54,
    marginHorizontal: 24,
    flexShrink: 0,
  },
}));

const ColorText = variance(Body)((theme) => ({
  root: {
    color: theme.colors.primaryWhite,
    fontSize: 14,
    lineHeight: 20,
  },
  large: {
    fontSize: 16,
  },
  largeDescription: {
    fontSize: 13,
    lineHeight: 16,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginRight: -8,
      },
    }),
  },
}));

const Content = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const TextContainer = variance(Pressable)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 'auto',
    gap: 20,
    paddingVertical: 11,
    marginTop: 8,
    flex: 1,
    justifyContent: 'space-between',
    paddingRight: 16,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: undefined,
        paddingRight: 0,
        justifyContent: 'flex-start',
      },
    }),
  },
}));

const BgContainer = variance(View)(() => ({
  root: {
    ...StyleSheet.absoluteFillObject,
    top: 8,
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

const Bg = variance(BGSVG)(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));
