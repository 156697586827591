import {observer} from 'mobx-react-lite';
import React, {useCallback, useRef} from 'react';
import {PickConfigurationModal} from '../../PickConfigurationModal';
import {useRoot} from '../../Root/hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {AdaptiveModalRef} from '../../components/templates';
import {expr} from 'mobx-utils';

export type PickConfigurationBindingProps =
  RootStackBindingProps<'PickSubManagementConfiguration'> & {};

export default observer(function PickConfigurationBinding({
  navigation,
}: PickConfigurationBindingProps) {
  const {subscriptionManagementScreenState} = useRoot();
  const {minerConfigByHashrate, selectedHashrate, selectHashrate} =
    subscriptionManagementScreenState;
  const modalRef = useRef<AdaptiveModalRef>(null);
  const goToManagement = useCallback(() => navigation.goBack(), [navigation]);
  const closeAndGoBack = useCallback(async () => {
    await modalRef.current?.close();
    goToManagement();
  }, [goToManagement]);
  const data = expr(() =>
    minerConfigByHashrate ? [...minerConfigByHashrate?.values()] : [],
  );
  return (
    <PickConfigurationModal
      ref={modalRef}
      onSelectPress={closeAndGoBack}
      onModalClosed={closeAndGoBack}
      data={data}
      selectHashrate={selectHashrate}
      selectedHashrate={selectedHashrate}
    />
  );
});
